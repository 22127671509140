// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { ActivePosting } from "./ActivePosting";
import { Feedback } from "./Feedback";
import { PostingModel } from "../../../../../Models/PostingModel";
import { PageLoader } from "../../../../Parts/General/PageLoader";
import qs from "query-string";
import { PostingHelper } from "../../../../../Helpers/PostingHelper";
import {
  nowAsFormatedString,
  compareChangedDatePrecise,
  getNowAsDbString,
} from "../../../../../Helpers/DateTimeHelper";
import PostingsHeader from "./PostingsHeader";
import { ChatHelper } from "../../../../../Helpers/ChatHelper";
import { MessageNetworksModel } from "../../../../../Models/MessageNetworksModel";
import { getCurrentUser } from "../../../../../Helpers/SessionHelper";
import { ChatDrawer } from "./ChatDrawer";
import { getLanguage } from "../../../../../Helpers/LanguageHelper";
import { StorageHelper } from "../../../../../Helpers/StorageHelper";
import { RequestModel } from "../../../../../Models/RequestModel";
import { PastPostingModel } from "../../../../../Models/PastPostingModel";
import { UserHelper } from "../../../../../Helpers/UserHelper";
import { FavoriteProfileModel } from "../../../../../Models/FavoriteProfileModel";
import { OvertimeRequestModel } from "../../../../../Models/OvertimeRequestModel";
import { PostState } from "./PostingHelper";
import { RequestHelper } from "../../../../../Helpers/RequestHelper";
import { SnackbarManager } from "../../../../../Helpers/SnackbarManager/SnackbarManager";
import { RatingModel } from "../../../../../Models/RatingModel";

interface IProps extends RouteComponentProps { }
interface IState {
  postState: PostState;
  posting: PostingModel | null;
  chatOpen: boolean;
  messageNetworkId: string;
  unreadMessageCount: number;
  requests: RequestModel[];
  favourites: FavoriteProfileModel[];
  overtimeRequests: OvertimeRequestModel[];
  isDirty: boolean;
  ratings: RatingModel[];
}

export default class Posting extends Component<IProps, IState> {
  postingsHelper = new PostingHelper();
  chatHelper = new ChatHelper();
  storageHelper = new StorageHelper();
  userHelper = new UserHelper();
  requestHelper = new RequestHelper();

  activePostingRef = React.createRef<ActivePosting>();
  feedbackRef = React.createRef<Feedback>();

  constructor(props: IProps) {
    super(props);

    let posting = null;
    //@ts-ignore
    if (
      this.props.history.location.state &&
      this.props.history.location.state.activePosting
    ) {
      //@ts-ignore
      posting = this.props.history.location.state.activePosting;
    }

    this.state = {
      postState: PostState.loading,
      posting,
      chatOpen: false,
      messageNetworkId: "",
      unreadMessageCount: 0,
      requests: [],
      favourites: [],
      overtimeRequests: [],
      isDirty: false,
      ratings: [],
    };

    this.archiveJob = this.archiveJob.bind(this);
    this.changeIsDirty = this.changeIsDirty.bind(this);
    this.postingApplicantsUpdated = this.postingApplicantsUpdated.bind(this);
  }

  async componentDidMount() {
    const { history } = this.props;
    let posting: PostingModel | null = this.state.posting;
    let requests: RequestModel[] = [];
    let favourites: FavoriteProfileModel[] = [];
    let overtimeRequests: OvertimeRequestModel[];
    let ratings: RatingModel[] = [];
    let pastPostings: PastPostingModel | undefined;
    let user = getCurrentUser();
    let adminGranted = (user && user.AdminGranted) || false;

    if (history.location.state) {
      //@ts-ignore
      posting = history.location.state.activePosting;
      //@ts-ignore
      pastPostings = this.props.location.state.historyObjects;
    }

    // If we don't have the needed values, try getting them from the server
    if (posting == null || pastPostings == null) {
      let id = qs.parse(this.props.location.search).id;
      if (typeof id != "string" || id === "") {
        this.props.history.push("/company/postings");

        return;
      }
      posting = await this.postingsHelper.getPosting(id);
    }

    // If posting is still null, go back to postings
    if (posting == null) return this.props.history.push("/company/postings");

    // Set the postState depending on whether the job is supposed to be archived or not
    let postState: PostState;
    if (posting.StartAtLocal >= nowAsFormatedString()) {
      postState = PostState.activePosting;
    } else {
      let olderThan30 = compareChangedDatePrecise(
        posting.EndAtLocal,
        nowAsFormatedString(),
        -30,
        "days"
      );
      if (posting.ArchivedByCompany || olderThan30 === "past") {
        postState = PostState.archivedPosting;
      } else {
        postState = PostState.pastPosting;
      }
    }

    let getArchived = postState === PostState.archivedPosting;
    pastPostings = await this.postingsHelper.getPastPostings(
      0,
      getArchived,
      adminGranted
    );

    if (pastPostings) {
      for (const request of pastPostings.Requests) {
        if (request.Posting && request.Posting.Id === posting.Id) {
          requests.push(request);
        }
      }
    }

    if (pastPostings?.Ratings) {
      ratings = pastPostings.Ratings.filter(
        (x) =>
          x.GivenToCompany === false &&
          requests.findIndex((y) => y.Id === x.RequestId) !== -1
      );
    }

    favourites = await this.userHelper.getFavorites();
    if (
      postState === PostState.pastPosting ||
      postState === PostState.archivedPosting
    ) {
      overtimeRequests = await this.requestHelper.getOvertimeRequests();

      // Find only overtimeRequests for the current Posting
      overtimeRequests = overtimeRequests.filter((x) =>
        requests.find((y) => y.Id === x.RequestId)
      );
    }

    await new Promise((accept) =>
      this.setState(
        { postState, posting, requests, ratings, favourites, overtimeRequests },
        accept
      )
    );
    this.prepareChat();

    // clears out the location settings so we will have to load from api next time
    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: this.props.location.search,
      state: {},
    });
  }

  preparePage = (getNewPosting?: boolean) => {
    if (
      this.state.postState === PostState.activePosting &&
      this.activePostingRef.current != null
    ) {
      this.activePostingRef.current.preparePage(getNewPosting);
    } else if (
      (this.state.postState === PostState.pastPosting ||
        this.state.postState === PostState.archivedPosting) &&
      this.feedbackRef.current != null
    ) {
      this.feedbackRef.current.initializePage();
    }
  };

  async prepareChat() {
    let messageNetworks: MessageNetworksModel[] =
      await this.chatHelper.getAllMessageNetworksAndMessages();

    let user = getCurrentUser();

    if (this.state.posting && messageNetworks && messageNetworks.length !== 0) {
      messageNetworks = messageNetworks.filter(
        (x) => x.PostingId === this.state.posting!.Id
      );
      if (messageNetworks.length === 0 || user == null) return;
      let unreadMessageCount = 0;

      for (const messageNetwork of messageNetworks) {
        let login = messageNetwork.Logins.find((x) => x.Id === user!.Id);
        if (
          login?.MessageNetworkMembers != null &&
          messageNetwork.LastMessageDate != null &&
          ((login?.MessageNetworkMembers.LastSeenDate != null &&
            login?.MessageNetworkMembers.LastSeenDate <
            messageNetwork.LastMessageDate) ||
            login?.MessageNetworkMembers.LastSeenDate == null)
        ) {
          unreadMessageCount = 1;
        }
      }
      this.setState({
        messageNetworkId: messageNetworks[0].Id,
        unreadMessageCount,
      });
    }
  }

  toggleChat = (shouldOpen: boolean) => {
    this.storageHelper.sendEvent("toggleIntercom", shouldOpen);
    this.setState({ chatOpen: shouldOpen });
    this.prepareChat();
  };

  changeIsDirty(val?: boolean) {
    this.setState({ isDirty: val != null ? val : true });
  }

  postingApplicantsUpdated() {
    let posting = this.state.posting;
    if (posting != null) {
      posting.DateForLastCompanyActionUTC = getNowAsDbString();
      this.setState({ posting });
    }
  }

  async archiveJob() {
    let posting = this.state.posting;

    if (posting) {
      this.props.history.push("/company/postings");
      let success = this.postingsHelper.archivePosting(posting.Id);
      if (success) {
        SnackbarManager.Instance.addSuccess(
          getLanguage(342, "Posting archived")
        );
      } else {
        SnackbarManager.Instance.addWarning(
          getLanguage(343, "Posting could not be archived")
        );
      }
    }
  }
  render() {
    let { postState, posting } = this.state;
    if (posting == null && postState !== PostState.loading) {
      postState = PostState.error;
    }
    let header = (
      <PostingsHeader
        isEditing={this.state.isDirty}
        archiveJob={this.archiveJob}
        postState={this.state.postState}
        preparePage={this.preparePage}
        posting={posting as PostingModel}
        unreadMessages={this.state.unreadMessageCount}
        toggleChat={this.toggleChat}
        {...this.props}
      />
    );

    let chatDrawer: JSX.Element | undefined = undefined;
    if (this.state.posting) {
      chatDrawer = (
        <ChatDrawer
          posting={this.state.posting}
          messageNetworkId={this.state.messageNetworkId}
          toggleChat={this.toggleChat}
          drawerOpen={this.state.chatOpen}
        />
      );
    }

    let content: JSX.Element | null = null;
    // Route to the correct component based on the postState
    switch (postState) {
      case PostState.loading:
        return <PageLoader releaseNavbar />;
      case PostState.activePosting:
        content = (
          <ActivePosting
            ref={this.activePostingRef}
            posting={posting as PostingModel}
            postingApplicantsUpdated={() => this.postingApplicantsUpdated()}
            favourites={this.state.favourites}
            {...this.props}
          />
        );
        break;
      case PostState.archivedPosting:
      case PostState.pastPosting:
        content = (
          <Feedback
            ref={this.feedbackRef}
            posting={posting as PostingModel}
            overtimeRequests={this.state.overtimeRequests}
            favourites={this.state.favourites}
            isDirty={this.state.isDirty}
            requests={this.state.requests}
            ratings={this.state.ratings}
            changeIsDirty={this.changeIsDirty}
            {...this.props}
          />
        );
        break;
      case PostState.error:
      default:
        return <p>error</p>; //FIXME: Sean handle this error please
    }

    return (
      <div>
        {header}
        {content}
        {chatDrawer}
      </div>
    );
  }
}
