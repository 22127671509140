import { FormControlLabel, Checkbox } from "@material-ui/core";
import { shortWeekDaysArray } from "../../Utils";
import React from "react";

type Props = {
    selectedWeekdays: string[];
    onChange: (next: string[]) => void;
}

export function WeekdaysSelect({ selectedWeekdays, onChange }: Props) {
    const handleChange = (day: string) => {
        const existingElIndex = selectedWeekdays.findIndex(x => x === day);
        if (existingElIndex === -1) {
            onChange([...selectedWeekdays, day]);
        }
        else {
            selectedWeekdays.splice(existingElIndex, 1);
            onChange([...selectedWeekdays]);
        }
    }

    return <div>
        {shortWeekDaysArray.map((weekday) => {
            return < FormControlLabel
                control={
                    <Checkbox
                        color="primary"
                        checked={selectedWeekdays.some(x => x === weekday)}
                        onChange={() => handleChange(weekday)}
                    />
                }
                label={weekday}
            />
        })}
    </div>
}