import React, { Component } from "react";
import { Button, TextField, Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions, InputAdornment } from "@material-ui/core";
import "./Language.scss";
import Grid from "@material-ui/core/Grid";
import { LanguageHelper } from '../../../../../Helpers/LanguageHelper';
import { StorageHelper } from "../../../../../Helpers/StorageHelper";
import { SavingSnackbar } from "../../../../Parts/General/SavingSnackbar";
import { PageLoader } from "../../../../Parts/General/PageLoader";
import { hasClearanceLevel, Clearances } from "../../../../../Helpers/SessionHelper";
import { englishLanguageId } from "../../../../../Helpers/Cfg";
import { SnackbarManager } from "../../../../../Helpers/SnackbarManager/SnackbarManager";

interface IProps {

}

interface IState {
  localLanguage: any;
  globalLanguageStrings: any[];
  localLanguageStrings: any[];
  sortedStrings: iLanguageString[];
  changedStrings: { stringCodeToChange: number, stringContentToChange: string, languageId: string, developerNote: string }[];
  edit: boolean;
  showPageLoader: boolean;
  missingCount: number;
  openGlobalEdit: boolean;
  activeCountry: string;
  globalStringToEdit: any;
  newGlobalString: string;
  newDeveloperNote: string;
  hidden: string;
  canEditGlobal: string;
  canEditLocal: string;
  searchQuery: string;
  backupSortedStrings: any[];
}

interface iLanguageString {
  globalLanguageId: string,
  globalContent: string,
  globalCode: number,
  localLanguageId: string,
  localContent: string,
  localCode: number,
  match: string,
  developerNote: string
}


export class LanguageView extends Component<IProps, IState> {
  languageHelper = new LanguageHelper();
  storageHelper = new StorageHelper();

  self = this;
  runOnce = true;
  countryActive = '';

  constructor(props: IProps) {
    super(props);


    this.state = {
      localLanguage: null,
      globalLanguageStrings: [],
      localLanguageStrings: [],
      sortedStrings: [],
      changedStrings: [],
      edit: false,
      showPageLoader: false,
      missingCount: 0,
      openGlobalEdit: false,
      activeCountry: '',
      globalStringToEdit: '',
      newGlobalString: '',
      newDeveloperNote: '',
      hidden: 'hidden',
      canEditGlobal: 'hidden',
      canEditLocal: 'editLocked',
      searchQuery: '',
      backupSortedStrings: [],
    };

    this.getGlobalLanguageStrings = this.getGlobalLanguageStrings.bind(this);
    this.getLanguageStringsForLocalLanguage = this.getLanguageStringsForLocalLanguage.bind(this);
    this.getAvailableLanguages = this.getAvailableLanguages.bind(this);
    this.saveNewStrings = this.saveNewStrings.bind(this);
    this.resetStrings = this.resetStrings.bind(this);
    this.searchChanged = this.searchChanged.bind(this);
  }

  async componentDidMount() {
    await this.getAvailableLanguages();
    let editGlobal = hasClearanceLevel(Clearances.EditGlobalLanguage);
    let editLocal = hasClearanceLevel(Clearances.EditLocalLanguage);
    if (editGlobal)
      this.setState({ canEditGlobal: 'editGlobal' })
    else
      this.setState({ canEditGlobal: 'hidden' })

    if (editLocal)
      this.setState({ canEditLocal: 'localLanguage' })
    else
      this.setState({ canEditLocal: 'localLanguage editLocked' })
  }

  async componentDidUpdate() {
    let country = await this.storageHelper.getAtlasCountryFromLocal();
    let localLang;
    if (country && country != this.state.activeCountry) {
      this.setState({ activeCountry: country })
      localLang = await this.languageHelper.getLocalLanguagesForCountry(country, true);
      if (!localLang || this.state.localLanguage != localLang[0]) {
        this.setState({ showPageLoader: true })
        await this.getAvailableLanguages();
        this.setState({ showPageLoader: false })
      }
    }
  }



  async sortStrings() {
    let missing = 0;
    let sorted: iLanguageString[] = [];
    let gStrings = this.state.globalLanguageStrings;
    let lStrings = this.state.localLanguageStrings;
    let lCodes = [];
    // let emptyLocalStrings = [];

    for (let u = 0; u < lStrings.length; u++) {
      lCodes.push(lStrings[u].Code)
      // emptyLocalStrings.push(lStrings[u].Contents)
    }

    for (let i = 0; i < gStrings.length; i++) {
      let sortedObject: iLanguageString | null = null;
      let existsIndex = lCodes.indexOf(gStrings[i].Code)
      // if (lCodes.indexOf(gStrings[i].Code) > -1 && ) {
      if (existsIndex != -1 && lStrings[existsIndex].Contents != '') {
        //In the array!
        for (let j = 0; j < lStrings.length; j++) {
          if (gStrings[i].Code == lStrings[j].Code) {
            let globalCountStart;
            let globalCountEnd;
            let localCountStart;
            let localCountEnd;

            let global = gStrings[i].Contents;
            let local = lStrings[j].Contents;
            for (var u = globalCountStart = 0; u < global.length; globalCountStart += +('{' === global[u++]));
            for (var k = localCountStart = 0; k < local.length; localCountStart += +('{' === local[k++]));
            for (var u = globalCountEnd = 0; u < global.length; globalCountEnd += +('}' === global[u++]));
            for (var k = localCountEnd = 0; k < local.length; localCountEnd += +('}' === local[k++]));

            let matches = "";
            if (globalCountStart != localCountStart || globalCountEnd != localCountEnd) {
              matches = "error";
            }

            sortedObject = {
              globalLanguageId: gStrings[i].LanguageId,
              globalContent: gStrings[i].Contents,
              developerNote: gStrings[i].DeveloperNote,
              globalCode: gStrings[i].Code,
              localLanguageId: lStrings[j].LanguageId,
              localContent: lStrings[j].Contents,
              localCode: lStrings[j].Code,
              match: matches
            }
          }

        }
        if (sortedObject != null) {
          sorted.push(sortedObject);
        }
      } else {
        //Not in the array
        sortedObject = {
          globalLanguageId: gStrings[i].LanguageId,
          globalContent: gStrings[i].Contents,
          developerNote: gStrings[i].DeveloperNote,
          globalCode: gStrings[i].Code,
          localLanguageId: this.state.localLanguage?.LanguageId,
          localContent: '',
          localCode: -1,
          match: "error",
        }
        missing++;
        sorted.push(sortedObject);
      }
    }
    if (missing === 0)
      this.setState({ hidden: "hidden" })
    else
      this.setState({ hidden: '' })
    this.setState({ sortedStrings: sorted, missingCount: missing, backupSortedStrings: sorted });
  }


  async getAvailableLanguages() {
    let country = await this.storageHelper.getAtlasCountryFromLocal();
    let localLang;
    if (country) {
      localLang = await this.languageHelper.getLocalLanguagesForCountry(country, true);
      if (localLang) {
        await this.getGlobalLanguageStrings();
        await this.setState({ localLanguage: localLang[0] });
        await this.getLanguageStringsForLocalLanguage();
        await this.sortStrings();
      }
    }
  }

  async getGlobalLanguageStrings() {
    let globalStrings = await this.languageHelper.getLanguageStringsForLanguage(englishLanguageId, true); //English
    if (globalStrings)
      this.setState({ globalLanguageStrings: globalStrings })
  }

  async getLanguageStringsForLocalLanguage() {
    if (this.state.localLanguage == null) {
      this.setState({ localLanguageStrings: [] })
      return
    }
    let localStrings = await this.languageHelper.getLanguageStringsForLanguage(this.state.localLanguage.LanguageId, true);
    if (localStrings) {
      this.setState({ localLanguageStrings: localStrings })
    }
  }

  async changeStrings(index: number, content: string, developerNote: string) {
    let strings = this.state.changedStrings;
    let stringCodeToChange = this.state.sortedStrings[index].globalCode;

    let existingIndex = strings.findIndex(x => x.stringCodeToChange === stringCodeToChange);
    let newString = {
      languageId: this.state.localLanguage.LanguageId,
      stringCodeToChange,
      stringContentToChange: content,
      developerNote
    }
    if (existingIndex === -1) {
      strings.push(newString);
    } else {
      strings[existingIndex] = newString;
    }
    await this.setState({ changedStrings: strings, edit: true })
  }


  async saveNewStrings(stringContentToChange: string | null, developerNote: string | null) {
    if (!stringContentToChange && this.state.changedStrings && this.state.changedStrings.length > 0) {
      let success = await this.languageHelper.saveStrings(this.state.changedStrings)
      if (success) {
        await this.getAvailableLanguages();
        SnackbarManager.Instance.addSuccess('Changes saved');
        await this.setState({ changedStrings: [], edit: false, })
      } else {
        SnackbarManager.Instance.addError('Changes could not be saved');
      }
    } else {
      let stringCodeToChange = this.state.globalStringToEdit.globalCode;
      let languageId = this.state.globalStringToEdit.globalLanguageId;
      let changingString = { stringCodeToChange, stringContentToChange, languageId, developerNote }
      this.setState({ openGlobalEdit: false })
      let newStrings = [changingString]
      let success = await this.languageHelper.saveStrings(newStrings);
      if (success) {
        await this.getAvailableLanguages();
        SnackbarManager.Instance.addSuccess('Changes saved');

        this.setState({ changedStrings: [] })
      } else {
        SnackbarManager.Instance.addError('Changes could not be saved');

        this.setState(() => ({ changedStrings: [] }))
      }
    }
  }


  async resetStrings() {
    await this.setState({ changedStrings: [], edit: false, openGlobalEdit: false })
    await this.getAvailableLanguages();
  }

  openEditGlobal(row: any) {
    if (row) {
      this.setState({ openGlobalEdit: true, globalStringToEdit: row, newGlobalString: row.globalContent, newDeveloperNote: row.developerNote });
    }
    else {
      let newGlobal = {
        globalContent: "",
        globalLanguageId: englishLanguageId, //English.
      }
      this.setState({ openGlobalEdit: true, globalStringToEdit: newGlobal });
    }
  };

  async changeGlobalString(newString: any) {
    this.setState({ newGlobalString: newString })
  }

  async changeDeveloperNote(newString: any) {
    this.setState({ newDeveloperNote: newString })
  }



  async searchChanged(event: any) {
    let query = this.state.searchQuery.toLocaleLowerCase();
    query = event.target.value.toLocaleLowerCase();

    let languageRows = this.state.backupSortedStrings;
    let sortedSearchRows = [];

    if (query != "") {
      for (let i = 0; i < languageRows.length; i++) {
        let checkRow = languageRows[i].localContent.toLocaleLowerCase();
        if (checkRow.includes(query)) {
          sortedSearchRows.push(languageRows[i])
        }
      }
      this.setState({ sortedStrings: sortedSearchRows })
    } else {
      this.setState({ sortedStrings: this.state.backupSortedStrings })
    }
  }

  // async checkForMatch(row: any){
  //   if (row)
  //     return ""
  //   return "error"

  // }


  render() {
    let savingSnackBar = <SavingSnackbar open={this.state.edit} resetClicked={this.resetStrings} saveClicked={() => this.saveNewStrings(null, null)} />

    return (
      <div className="language">
        <div className="AdminPageHeader">
          <div className="AdminPageTitle">Language database</div>
          <div className="AdminPageHeader_options">
            <div className="inputGroup">
              <Button
                className="white inputGroup_item"
                variant="contained"
                size="medium"
              >
                <span className="btn-icon">
                  <i className="fab fa-google" />
                </span>
                Translate
              </Button>
              <TextField
                className="inputGroup_item"
                onChange={event => this.searchChanged(event)}
                type="text"
                variant="outlined"
                margin="dense"
                style={{ margin: 0 }}
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className="inputAdornment">
                      <i className="fas fa-search" />
                    </InputAdornment>
                  )
                }}
              />
            </div>
          </div>
        </div>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="space-between"
          alignItems="flex-start"
        >
          <Grid item
            container
            direction="row"
            justify="space-between"
            alignItems="stretch" xs={6}>
            <Grid item>
              <h2>Global - English</h2>
            </Grid>
            <Grid item>
              <Button className={this.state.canEditGlobal} variant="contained" size="small" onClick={() => this.openEditGlobal(null)}>
                Add new
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <h2>Local - {(this.state.localLanguage ? this.state.localLanguage.KnownLanguage.Title : '')}</h2>
            <div className={this.state.hidden} id="untranslatedStrings">{this.state.missingCount} missing translations</div>
          </Grid>
        </Grid>
        {/* This is the language strings */}
        {this.state.sortedStrings.map((row, index) => (
          <Grid
            container
            spacing={3}
            direction="row"
            justify="flex-start"
            alignItems="stretch"
            className="translationPair"
            key={index}
          >

            <Grid item xs={6} >
              <div className="global">
                <p className="placeholder margin-0">{row.globalCode}.</p>
                <div className={this.state.canEditGlobal} onClick={(e: any) => this.openEditGlobal(row)}>
                  <i className="far fa-edit"></i>
                </div>
                <p className="no-margin-top">{row.globalContent}</p>
                <p className="no-margin-top developerNote">{row.developerNote ? "Developer note: " + row.developerNote : ""}</p>
              </div>
            </Grid>

            <Grid item xs={6} className={row.match}>
              <textarea className={this.state.canEditLocal} value={row.localContent} onChange={(event) => {
                let strings = this.state.sortedStrings;
                let rowIndex = -1;
                for (let i = 0; i < strings.length; i++) {
                  const str = strings[i];
                  if (str.globalCode === row.globalCode) {
                    rowIndex = i;
                  }
                }

                if (strings[rowIndex].localContent != event.target.value) {
                  //Show save snackbar
                  this.changeStrings(rowIndex, event.target.value, '');
                }
                strings[rowIndex].localContent = event.target.value;
                this.setState({ sortedStrings: strings });
              }}>

              </textarea>

            </Grid>

          </Grid>
        ))}
        {savingSnackBar}
        {/* language strings ends here */}

        <Dialog
          open={this.state.openGlobalEdit}
          onClose={this.resetStrings}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Translate</DialogTitle>
          <DialogContent>
            <DialogContentText>
              If you edit this text it could have site-wide consequences on all other languages. Please make sure, you know what you are doing. If in doubt, create a new global string!
            </DialogContentText>
            <TextField
              autoFocus
              variant="outlined"
              margin="dense"
              id="string_ID"
              multiline
              rowsMax="4"
              label="Global translation"
              type="text"
              fullWidth
              defaultValue={this.state.globalStringToEdit.globalContent}
              onChange={(event) => this.changeGlobalString(event.target.value)}
            ></TextField>
            <TextField
              variant="outlined"
              margin="dense"
              id="developer_note"
              multiline
              label="Developer note"
              fullWidth
              defaultValue={this.state.globalStringToEdit.developerNote}
              onChange={(event) => this.changeDeveloperNote(event.target.value)}
            ></TextField>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.resetStrings} color="primary">
              Cancel
            </Button>
            <Button onClick={() => this.saveNewStrings(this.state.newGlobalString, this.state.newDeveloperNote)} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
        {this.state.showPageLoader ? <PageLoader /> : null}
      </div>
    );
  }
}
