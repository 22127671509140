import React, { Component } from "react";
import { Dialog, DialogTitle, DialogContent, FormGroup, FormControlLabel, Checkbox, DialogActions, Button } from "@material-ui/core";
import { CountryHelper } from "../../../../../../../Helpers/CountryHelper";
import { UserHelper } from "../../../../../../../Helpers/UserHelper";
import {
  LanguageHelper,
  getLanguage
} from "../../../../../../../Helpers/LanguageHelper";
import { CompetenceQuestionModel } from "../../../../../../../Models/CompetenceQuestionModel";
import { StorageHelper } from "../../../../../../../Helpers/StorageHelper";
import { SkillModel } from "../../../../../../../Models/SkillModel";
import { CompetenceSelectionsModel } from "../../../../../../../Models/CompetenceSelectionsModel";
import { LoginModel } from "../../../../../../../Models/LoginModel";
import { getCurrentUser } from "../../../../../../../Helpers/SessionHelper";
import "../FreelancerSteps.scss";
import { ProfileSkillsModel } from "../../../../../../../Models/ProfileSkillsModel";
import { SnackbarManager } from "../../../../../../../Helpers/SnackbarManager/SnackbarManager";

interface IProps {
  creatingUser: boolean;
  updateCompetenceQuestions: (competenceQuestions: CompetenceQuestionModel[][]) => void;
  updateSkills: (skills: SkillModel[]) => void;
  reset: boolean;
  initialStartup: (component: 'competences' | 'skills', data: any) => void;
  reFresh?: boolean;


}

interface IState {
  userSkills: SkillModel[];
  activeSkillId: string;
  competenceQuestions: CompetenceQuestionModel[][];
  competenceDialogQuestions: CompetenceQuestionModel[];
  activatedCompetences: CompetenceQuestionModel[];
  CompetenceSelections: CompetenceSelectionsModel[];
  oldCompetenceQuestions: CompetenceQuestionModel[][];
  openDialog: boolean;
  user: LoginModel;
  resetValue: boolean;
  oldSkills: SkillModel[];
  profileSkills: ProfileSkillsModel[];
}



export class JobType extends Component<IProps, IState> {
  countryHelper = new CountryHelper();
  userHelper = new UserHelper();
  languageHelper = new LanguageHelper();
  storageHelper = new StorageHelper();

  constructor(props: IProps) {
    super(props);

    this.state = {
      resetValue: this.props.reset,
      userSkills: [],
      activeSkillId: '',
      competenceQuestions: [],
      competenceDialogQuestions: [],
      activatedCompetences: [],
      CompetenceSelections: [],
      oldCompetenceQuestions: [],
      openDialog: false,
      user: new LoginModel({}),
      oldSkills: [],
      profileSkills: [],

    };

    this.changeActivationStatusOfSkill = this.changeActivationStatusOfSkill.bind(this)
    this.addCompetence = this.addCompetence.bind(this)
    this.closeCompetenceDialog = this.closeCompetenceDialog.bind(this)
    this.showCompetenceQuestions = this.showCompetenceQuestions.bind(this)
    this.changeActivationStatusOfCompetenceQuestion = this.changeActivationStatusOfCompetenceQuestion.bind(this)
    this.prepareForm = this.prepareForm.bind(this)

  }



  async componentDidUpdate() {
    if (this.props.reset !== this.state.resetValue) {
      await this.setState({ resetValue: this.props.reset })
      await this.prepareForm();
    }
  }



  async componentDidMount() {
    await this.prepareForm();
  }


  async prepareForm() {
    let user: LoginModel = getCurrentUser()!;

    if (!user || !user.Freelancer) {
      SnackbarManager.Instance.addError(getLanguage(469, 'Unknown error'));
      return;
    }
    await this.setState({ user: user, oldSkills: [] })

    let skills = this.storageHelper.getSkillsFromLocal();
    let competenceQuestions = this.storageHelper.getCompetenceQuestionsFromLocal();

    if (skills == null || competenceQuestions == null) return;

    for (const compQ of competenceQuestions) {
      compQ.Activated = false;
    }


    let profileSkills: ProfileSkillsModel[] = user.Freelancer.ProfileSkills;


    let oldSkills: SkillModel[] = [];

    for (const skill of skills) {
      skill.Activated = profileSkills.find(x => x.SkillId === skill.Id) != null ? true : false;
      oldSkills.push(new SkillModel(skill));
    }


    let usersCompetences = user.Freelancer.CompetenceSelections;


    if (usersCompetences) {
      for (const compQuestions of competenceQuestions) {
        for (const usersComps of usersCompetences) {
          if (usersComps.CompetenceQuestionId === compQuestions.Id) {
            compQuestions.Activated = true;
            for (const iterator of skills) {
              if (iterator.Id === compQuestions.SkillId)
                iterator.Activated = true;
            }
          }
        }
      }
      await this.setState({ CompetenceSelections: usersCompetences })
    }


    let waiterComps = [];
    let runnerComps = [];
    let barStaff = [];
    let kitchenAssis = [];
    //Adding the questions to each skill.
    for (const newComps of competenceQuestions) {
      if (newComps.SkillId === '0ed20374-a89b-494b-abb3-327e373d1bef') {
        waiterComps.push(newComps)
      } else if (newComps.SkillId === '0ed20374-a89b-494b-abb3-327e373d3bef') {
        runnerComps.push(newComps)
      }
      else if (newComps.SkillId === '0febf171-b20b-4e20-b011-e5e0a0c1fd63') {
        barStaff.push(newComps)
      }
      else if (newComps.SkillId === '3ab3b55a-d3fd-4086-8f0d-0058e28555dd') {
        kitchenAssis.push(newComps)
      }
    }

    waiterComps.sort((a, b) => a.SortingKey > b.SortingKey ? 1 : -1);
    runnerComps.sort((a, b) => a.SortingKey > b.SortingKey ? 1 : -1);
    barStaff.sort((a, b) => a.SortingKey > b.SortingKey ? 1 : -1);
    kitchenAssis.sort((a, b) => a.SortingKey > b.SortingKey ? 1 : -1);

    let newActivatedCompetencesSorted = [];
    newActivatedCompetencesSorted.push(waiterComps, runnerComps, barStaff, kitchenAssis);

    let oldCompetenceQuestionsSetup = newActivatedCompetencesSorted;



    await this.setState({
      profileSkills: profileSkills,
      userSkills: skills,
      oldSkills,
      competenceQuestions: newActivatedCompetencesSorted,
      oldCompetenceQuestions: oldCompetenceQuestionsSetup
    })

    this.props.initialStartup('competences', this.state.competenceQuestions)
    this.props.initialStartup('skills', this.state.userSkills)
  }





  async changeActivationStatusOfSkill(skillId: string) {
    let skills = this.state.userSkills;
    let competenceQuestions = this.state.competenceQuestions;

    for (const skill of skills) {
      if (skill.Id === skillId) {
        skill.Activated = skill.Activated ? false : true;
        if (skill.Activated === true) {
          await this.showCompetenceQuestions(skillId)
        } else {
          for (const comp of competenceQuestions) {
            for (const questions of comp) {
              if (questions.SkillId === skillId) {
                questions.Activated = false;
              }
            }
          }
        }
        await this.setState({ activeSkillId: skillId })
        if (skillId !== 'a31a0a23-367b-45f4-8c90-0c707c37c3b1' && skillId !== '87d5f12f-eee0-4308-a47a-aed82146647f') {
          await this.setState({ openDialog: skill.Activated })
        }
      }
    }


    await this.setState({ competenceQuestions: competenceQuestions, userSkills: skills })
    this.props.updateSkills(this.state.userSkills);
    this.props.updateCompetenceQuestions(competenceQuestions);

  }

  async addCompetence() {
    await this.setState({ openDialog: false })
    let competenceQuestions = this.state.competenceQuestions;


    let updatedCompetences = this.state.competenceDialogQuestions;

    for (const compQuestions of competenceQuestions) {
      for (const compQInnerArray of compQuestions) {
        for (const comp of updatedCompetences) {
          if (comp.Id === compQInnerArray.Id) {
            compQInnerArray.Activated = comp.Activated;
          }
        }
      }
    }

    let oldSkills = [];
    for (const i of this.state.userSkills) {
      oldSkills.push(new SkillModel(i))
    }
    await this.setState({ competenceQuestions: competenceQuestions, oldSkills })
    this.props.updateCompetenceQuestions(competenceQuestions);
    this.props.updateSkills(this.state.userSkills);

  }






  async showCompetenceQuestions(skillId: string) {
    let competenceQuestions = this.state.competenceQuestions;

    let skills = this.state.userSkills;
    let questionsToShow: CompetenceQuestionModel[] = [];
    for (const skill of skills) {
      if (skill.Id === skillId) {
        for (const firstArray of competenceQuestions) {
          for (const object of firstArray) {
            if (object.SkillId === skill.Id) {
              questionsToShow.push(object)
            }
          }
        }
      }
    }

    questionsToShow.sort((a, b) => a.SortingKey > b.SortingKey ? 1 : -1);
    if (skillId === '87d5f12f-eee0-4308-a47a-aed82146647f' || skillId === 'a31a0a23-367b-45f4-8c90-0c707c37c3b1') {
      return await this.setState({ competenceDialogQuestions: questionsToShow, userSkills: skills });
    }
    await this.setState({ competenceDialogQuestions: questionsToShow, userSkills: skills, openDialog: true })
  }


  async changeActivationStatusOfCompetenceQuestion(competenceQuestionId: string) {
    let competenceDialogQuestions = this.state.competenceDialogQuestions;
    let competenceQuestions = this.state.competenceQuestions;


    for (const compQ of competenceDialogQuestions) {
      if (compQ.Id === competenceQuestionId) {
        compQ.Activated = compQ.Activated ? false : true;
        for (const compQArray of competenceQuestions) {
          for (const compQuestion of compQArray) {
            if (compQ.LanguageStringCode === compQuestion.LanguageStringCode) {
              compQuestion.Activated = compQ.Activated
            }
          }
        }

        await this.setState({ competenceDialogQuestions: competenceDialogQuestions })
      }
    }
  }


  async closeCompetenceDialog() {
    await this.setState({ openDialog: false })
    let compQuestionArray: CompetenceQuestionModel[][] = this.state.competenceQuestions;
    let oldCompQuestionsArray: CompetenceQuestionModel[][] = this.state.oldCompetenceQuestions;
    let skills = this.state.userSkills;


    for (const questions of compQuestionArray) {
      for (const oldQuestions of oldCompQuestionsArray) {
        for (const question of questions) {
          for (const oldQuestion of oldQuestions) {
            if (question.Id === oldQuestion.Id) {
              question.Activated = oldQuestion.Activated;
            }
          }
        }
      }
    }


    for (const skill of skills) {
      if (skill.Id === 'a31a0a23-367b-45f4-8c90-0c707c37c3b1' || skill.Id === '87d5f12f-eee0-4308-a47a-aed82146647f') {
        continue;
      }
      let skillShouldStayActivated = false;
      for (const compQuestionsArray of compQuestionArray) {
        for (const compQuestion of compQuestionsArray) {
          if (skill.Id === compQuestion.SkillId) {
            if (compQuestion.Activated) {
              skillShouldStayActivated = true
            }
          }
        }
      }
      if (skillShouldStayActivated) {
        skill.Activated = true;
      } else {
        skill.Activated = false;
      }
    }

    await this.setState({ competenceQuestions: compQuestionArray, activeSkillId: '', userSkills: this.state.oldSkills })
  }





  render() {
    return (
      <div>
        <FormGroup>
          {this.state.userSkills.map((row, catIndex) => {
            let arr = this.state.competenceQuestions[catIndex];
            if (!arr) return (
              <div key={catIndex}>
                <FormControlLabel
                  control={<Checkbox checked={row.Activated} value={row.Id} color="primary" onClick={(e: any) => this.changeActivationStatusOfSkill(e.target.value)} />}
                  label={getLanguage(row.getLanguageCode(1, false))}
                />
              </div>);
            return (<div key={catIndex}>
              <FormControlLabel
                control={<Checkbox checked={row.Activated} value={row.Id} color="primary" onClick={(e: any) => this.changeActivationStatusOfSkill(e.target.value)} />}
                label={getLanguage(row.getLanguageCode(1, false))}
              />
              <div className="individualCompetences">
                {arr.map((nestedRow: CompetenceQuestionModel, index: any) =>
                  <div key={index} className={nestedRow.Activated ? "competences comp_has" : "competences comp_hasNot"}>
                    {getLanguage(nestedRow.LanguageStringCode, 'CompetenceQuestion')}
                  </div>
                )}
              </div>
            </div>
            )
          })}
        </FormGroup>





        <Dialog
          open={this.state.openDialog}
          aria-labelledby="form-dialog-title"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">Add an experience</DialogTitle>
          <DialogContent>
            <FormGroup>
              {this.state.competenceDialogQuestions.map((row, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox checked={row.Activated}
                      value={row.Id}
                      color="primary"
                      onClick={(e: any) => this.changeActivationStatusOfCompetenceQuestion(e.target.value)}
                    />}
                  label={getLanguage(row.LanguageStringCode, 'competenceQuestion')}
                />
              ))}
            </FormGroup>
          </DialogContent>
          <DialogActions className="dialogActions">
            <Button onClick={() => this.closeCompetenceDialog()}>Cancel</Button>
            <Button
              onClick={() => this.addCompetence()}
              color="primary"
              className="button-gradient"
            >
              Accept
                        </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
