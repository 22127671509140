import { SkillModel } from "./SkillModel";

export class ProfileSkillsModel {
    FreelancerProfileId: string;
    Id: string;
    SalaryPerHour: number;
    Skill: SkillModel | null;
    SkillId: string;

    constructor(val: Partial<ProfileSkillsModel>) {
        this.FreelancerProfileId = val.FreelancerProfileId || '';
        this.Id = val.Id || '';
        this.SalaryPerHour = val.SalaryPerHour || 0;
        this.Skill = val.Skill || null;
        this.SkillId = val.SkillId || ';'

    }
}