import React, { useState, useEffect } from "react";
import { TextField, MenuItem, Checkbox, FormControlLabel, Button, FormControl, InputLabel, OutlinedInput } from "@material-ui/core";
import { AdminUserModel } from "../../../../../Models/AdminUserModel";
import { RouteComponentProps } from "react-router";
import { CMSMetaModel } from '../../../../../Models/CMSMetaModel';
import { AdminUserHelper } from "../../../../../Helpers/AdminUserHelper";
import { getCurrentAdminUser } from '../../../../../Helpers/SessionHelper';
import { StorageHelper } from "../../../../../Helpers/StorageHelper";
import { CountryHelper } from "../../../../../Helpers/CountryHelper";
import { LanguageHelper } from "../../../../../Helpers/LanguageHelper";

import { Select } from '@material-ui/core'
import { englishLanguageId } from "../../../../../Helpers/Cfg";
import { SnackbarManager } from "../../../../../Helpers/SnackbarManager/SnackbarManager";
import { CMSHelper, CMSTypes } from "../../../../../Helpers/CMSHelper";
import { CoursePartnerModel } from "../../../../../Models/CoursePartnerModel";
import { CourseOrganizerHelper } from "../../../../../Helpers/CourseOrganizerHelper";

interface IProps extends RouteComponentProps { }

export function CmsMetaView(props: IProps) {
  const cmsHelper = new CMSHelper();
  const adminUserHelper = new AdminUserHelper();
  const storageHelper = new StorageHelper();
  const countryHelper = new CountryHelper();
  const languageHelper = new LanguageHelper();
  const coursePartnerHelper = new CourseOrganizerHelper();

  const [cmsType, setCmsType] = useState<CMSTypes>(-1);
  const [pageDescription, setPageDescription] = useState('');
  const [title, setTitle] = useState('');
  const [inOverview, setInOverview] = useState(true);
  const [possibleAuthors, setPossibleAuthors] = useState<AdminUserModel[]>([]);
  const [possiblePartners, setPossiblePartners] = useState<CoursePartnerModel[]>([]);
  const [author, setAuthor] = useState('');
  const [coursePartner, setCoursePartner] = useState('');
  const [authorQuery, setAuthorQuery] = useState('');
  const [countryActive, setCountryActive] = useState('');
  const [languagesAvailable, setLanguagesAvailable] = useState<any[]>([]);
  const [languageActive, setLanguageActive] = useState('');
  const [savingPost, setSavingPost] = useState(false);


  useEffect(() => {
    window.addEventListener('localStorageUpdated', getCountryAndLanguages);
    getCountryAndLanguages();
    if (props.location.pathname.includes('blogmeta')) {
      setCmsType(CMSTypes.Blog);
      getAuthors();
    } else if (props.location.pathname.includes('coursemeta')) {
      setCmsType(CMSTypes.Course);
      getCoursePartners();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  async function saveMeta() {

    if (title.length < 1) {
      SnackbarManager.Instance.addError('Title required');
      return;
    }
    if (pageDescription.length < 1) {
      SnackbarManager.Instance.addError('Description required');
      return;
    }
    if (cmsType === CMSTypes.Blog && !author) {
      SnackbarManager.Instance.addError('Author required');
      return;
    } else if (cmsType === CMSTypes.Course && !coursePartner) {
      SnackbarManager.Instance.addError('Course partner required');
      return;
    }
    // TODO: Require the other elements of the form

    setSavingPost(true);


    let countryActive = storageHelper.getAtlasCountryFromLocal();
    if (!languageActive || !countryActive) {
      SnackbarManager.Instance.addError('Fix required fields');
      return;
    }

    let meta = new CMSMetaModel({
      Description: pageDescription,
      Title: title,
      Type: cmsType,
      IncludeInOverview: inOverview,
      AuthorId: author,
      CoursePartnerId: coursePartner,
      LanguageActive: languageActive,
      CountryId: countryActive,
    });
    let newMeta = await cmsHelper.saveMeta(meta);
    if (newMeta === false) {
      SnackbarManager.Instance.addError('Failed, it\'s likely that you don\'t have the required permissions');
      setSavingPost(false);
      return;
    }

    newMeta.AuthorId = author;
    return props.history.push('/admin/cmsedit?q=' + newMeta.Id);
  }

  async function getCountryAndLanguages() {
    let languages = [];
    let activeCountry = await storageHelper.getAtlasCountryFromLocal();
    if (activeCountry) {
      let country = await countryHelper.getCountry(activeCountry);
      let languageObjects = await languageHelper.getLocalLanguagesForCountry(activeCountry, true);
      for (const l of languageObjects) {
        let lang = await languageHelper.getLanguageFromKnownLanguages(l.LanguageId)
        for (const i of lang) {
          languages.push(i)
        }
      }
      let globalLang = await languageHelper.getLanguageFromKnownLanguages(englishLanguageId) //English which everyone should have access to.
      if (globalLang && globalLang.length === 1)
        languages.push(globalLang[0])
      if (country && languages && languages.length > 0) {
        let newActive = languages[languages.length - 1].Id;
        if (languageActive) //FIXME: This is a hack added because this was being reset
          newActive = languageActive;
        setCountryActive(country.CountryName);
        setLanguagesAvailable(languages);
        setLanguageActive(newActive);
      }
    }
  }

  async function selectNewLanguage(event: any) {
    setLanguageActive(event.target.value);
    await storageHelper.setLanguageActiveInLocal(languageActive);
  }




  async function getAuthors() {
    let admins = await adminUserHelper.getAll();
    if (admins) {
      let user = getCurrentAdminUser();
      if (user !== null) {
        let index = admins.findIndex(x => x.Email === user!.Email);

        setPossibleAuthors(admins);
        setAuthor(admins[index].Id);
        return;
      }
    }
    SnackbarManager.Instance.addError('Could not get authors');
  }

  async function getCoursePartners() {
    let coursePartners = await coursePartnerHelper.getOrganizers(true);
    setPossiblePartners(coursePartners);
  }


  function renderAuthor() {
    if (cmsType === CMSTypes.Course) return null;
    return (
      <FormControl fullWidth variant="outlined">
        <InputLabel shrink htmlFor="author-label-placeholder">
          Author
            </InputLabel>
        <Select
          value={author}
          onChange={event => setAuthor(event.target.value as string)}
          input={
            <OutlinedInput
              labelWidth={50}
              name="age"
              id="outlined-author-simple"
            />
          }
        >
          {possibleAuthors.map(x => { return <MenuItem key={x.Id} value={x.Id}>{x.Name}</MenuItem> })}
        </Select>
      </FormControl>
    )
  }

  function getCoursePartnerTitle(coursePartner: CoursePartnerModel) {
    let title = coursePartner.Name;

    if (coursePartner.CourseMetaId) {
      title += ' - has course';
      if (coursePartner.CourseMeta) {
        title += ': "' + coursePartner.CourseMeta.Title + '"';
      }

    }

    return title;
  }

  function renderCoursePartner() {
    if (cmsType === CMSTypes.Blog) return null;
    return (
      <FormControl fullWidth variant="outlined">
        <InputLabel shrink htmlFor="author-label-placeholder">
          Course partner
        </InputLabel>
        <Select
          value={coursePartner}
          onChange={event => setCoursePartner(event.target.value as string)}
          input={
            <OutlinedInput
              labelWidth={50}
              name="age"
              id="outlined-author-simple"
            />
          }
        >
          {possiblePartners.map(x => {
            return <MenuItem key={x.Id} value={x.Id}>{getCoursePartnerTitle(x)}</MenuItem>
          })}
        </Select>
      </FormControl>
    )
  }

  return (
    <div>
      <div className="AdminPageHeader">
        <div className="AdminPageTitle">Add new meta data</div>
      </div>

      <div className="paper padding-40 center maxW-600 margin-v-24 radius-6">
        <h2>Information</h2>
        <TextField
          type="text"
          label="Title"
          variant="outlined"
          fullWidth
          required
          onChange={(event) => setTitle(event.target.value)}
          margin="dense"
          InputLabelProps={{
            shrink: true
          }}
        />
        <div className="margin-v-24">
          {renderAuthor()}
          {renderCoursePartner()}
        </div>
        <TextField
          type="text"
          label="Country"
          variant="outlined"
          fullWidth
          value={countryActive}
          disabled={true}
          margin="dense"
          InputLabelProps={{
            shrink: true
          }}
        />
        <div className="margin-v-24">
          <FormControl fullWidth variant="outlined">
            <InputLabel shrink htmlFor="age-label-placeholder">
              Language
          </InputLabel>
            <Select
              value={languageActive}
              onChange={selectNewLanguage}
              input={
                <OutlinedInput
                  labelWidth={75}
                  name="language"
                  id="outlined-language-simple"
                />
              }
            >
              {languagesAvailable.map(x => { return <MenuItem key={x.Id} value={x.Id}>{x.Title}</MenuItem> })}
            </Select>
          </FormControl>
        </div>

        <TextField
          type="text"
          label="Page description/text snippet"
          multiline
          rows="5"
          variant="outlined"
          fullWidth
          margin="dense"
          InputLabelProps={{
            shrink: true
          }}
          onChange={(event: any) => setPageDescription(event.target.value)}
        />

        <FormControlLabel
          control={<Checkbox defaultChecked color="primary" onChange={() => setInOverview(!inOverview)} />}
          label="Include in overview"
        />
        <div className="block text-right">
          <Button disabled={savingPost} variant="contained" onClick={saveMeta} className={savingPost ? '' : "success"}>
            Create page
            </Button>
        </div>
      </div>
    </div>
  );

}
