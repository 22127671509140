import { AxiosRequestConfig } from 'axios'
import { PostingTemplateModel } from "../Models/PostingTemplateModel";
import { StorageHelper } from './StorageHelper';
import { CallManager } from './CallManager/CallManager';

export class PostingTemplateHelper {
  storageHelper = new StorageHelper();

  async getPostingTemplates() {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: 'GET',
        url: '/api/postingtemplate',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          authToken
        },
      }

      let response = await CallManager.Instance.makeCall(config, false, false);
      if (response.data != null) {
        return response.data as PostingTemplateModel[];
      }
      return false;
    } catch (error: any) {
      return false;
    }
  }

  async savePostingTemplate(template: PostingTemplateModel) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/postingtemplate',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          authToken
        },
        data: template
      }
      let response = await CallManager.Instance.makeCall(config, false, false);
      return response.data;
    } catch (error: any) {
      return false;
    }
  }

  async updatePostingTemplate(data: PostingTemplateModel) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: 'PUT',
        url: '/api/postingtemplate/' + data.Id,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          authToken
        },
        data
      }

      let response = await CallManager.Instance.makeCall(config, false, false);

      if (response.statusText.toUpperCase() === "OK") {
        return true;
      }
      return false;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  async deletePostingTemplate(id: string) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: 'DELETE',
        url: '/api/postingtemplate/' + id,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          authToken
        },
      }

      let response = await CallManager.Instance.makeCall(config, false, false);
      return response.data;
    } catch (error: any) {
      console.log('got an error', error);
      return false;
    }
  }
}