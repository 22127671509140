import React, { Component } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import { getLanguage } from '../../../../../../Helpers/LanguageHelper';
import { ICompanyFeedback } from '../PostingHelper';
import { RequestModel } from '../../../../../../Models/RequestModel';

interface IProps {
  open: boolean;
  feedbackObject: ICompanyFeedback | null;
  permaHire: () => void;
  handleClose: () => void;
}
interface IState { }

export class PermahireDialogue extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    let request: RequestModel | undefined = undefined;
    if (this.props.feedbackObject) {
      request = this.props.feedbackObject.request;
    }


    return (<Dialog
      open={this.props.open}
      onClose={this.props.handleClose}
      aria-labelledby="form-dialog-title"
      className="dialog"
    >
      <DialogTitle id="form-dialog-title">{getLanguage(584, "Confirm that you have permanently hired this chabber?")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {/* TODO: This should be in the languge database */}
          Confirm that{" "}
          {request &&
            request.FreelancerProfile &&
            request.FreelancerProfile.Login
            ? request.FreelancerProfile.Login.Firstnames +
            " " +
            request.FreelancerProfile.Login.Lastname
            : ""}{" "}
          has been hired by you.
        </DialogContentText>
      </DialogContent>
      <DialogActions className="dialogActions">
        <Button onClick={this.props.handleClose}>{getLanguage(242, "Cancel")}</Button>
        <Button onClick={this.props.permaHire} color="primary">
          {getLanguage(583, "OK")}
        </Button>
      </DialogActions>
    </Dialog>)
  }
}