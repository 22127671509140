import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import {
  Button,
  TextField
} from "@material-ui/core";
import { AdminUserHelper } from "../../../../Helpers/AdminUserHelper";
import { UserHelper } from "../../../../Helpers/UserHelper";
import "./Login.scss";
import { getLanguage } from "../../../../Helpers/LanguageHelper";
//@ts-ignore
import { isEmail } from "validator";

interface IProps extends RouteComponentProps { }
interface IState {
  currentState:
  | "forgottenForm"
  | "resettingPassword"
  | "resetSuccess"
  email: string;
}
export class ForgotPassword extends Component<IProps, IState> {
  adminUserHelper = new AdminUserHelper();
  userHelper = new UserHelper();

  constructor(props: IProps) {
    super(props);

    this.state = {
      currentState: "forgottenForm",
      email: ""
    };

    this.handleForgottenSubmit = this.handleForgottenSubmit.bind(this);
  }

  async handleForgottenSubmit(e: any) {
    e.preventDefault();

    if (typeof this.state.email !== 'string' || isEmail(this.state.email) === false) {
      return;
    }

    await this.setState({ currentState: "resettingPassword" });
    await this.userHelper.chabberForgotPassword(this.state.email);
    await this.setState({ currentState: "resetSuccess" });
  }

  getForgottenForm() {
    return (
      <div className="padding-32">
        <div className="margin-v-24">
          <h1>{getLanguage(607, "Reset password")}</h1>
        </div>

        <form>
          <TextField
            type="text"
            label="Your email address"
            variant="outlined"
            fullWidth
            margin="normal"
            value={this.state.email}
            onChange={(event: any) =>
              this.setState({ email: event.target.value })
            }
          />
          <div className="margin-v-40 text-right">
            <div className="inputGroup">
              <Button
                className="inputGroup_item"
                onClick={() => this.props.history.push("/login")}
              >
                {getLanguage(218, "Back")}
              </Button>
              <Button
                className="button-gradient inputGroup_item"
                variant="contained"
                type="submit"
                onClick={this.handleForgottenSubmit}
              >
                <span className="btn-icon">
                  <i className="fas fa-unlock-alt" />
                </span>
                {getLanguage(607, "Reset password")}
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }

  sendingPasswordMail() {
    return (
      <div className="padding-40">
        <i className="far fa-envelope-open fa-2x"></i>
        <h2>{getLanguage(615, "Sending reset email")}</h2>
        <div>
        </div>
      </div>
    );
  }

  resetEmailSent() {
    return (
      <div className="padding-40">
        <i className="far fa-envelope fa-2x"></i>
        <h2>{getLanguage(616, "Email has been sent")}</h2>
        <p>
          {getLanguage(617, "Please check your email and click the link to reset your password.")}
        </p>
      </div>
    );
  }

  render() {
    let content;
    switch (this.state.currentState) {
      case "forgottenForm":
        content = this.getForgottenForm();
        break;
      case "resettingPassword":
        content = this.sendingPasswordMail();
        break;
      case "resetSuccess":
        content = this.resetEmailSent();
        break;
      default:
        content = this.getForgottenForm();
        break;
    }

    return (
      <div>
        <div className="backgroundGradient vh-100">
          <div className="text-center">
            <div className="loginPaper">{content}</div>
          </div>
        </div>
      </div>
    );
  }
}
