import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { Grid, Button } from "@material-ui/core";
import { FreelancerStepBasic } from "../Freelancer/FreelancerSettings/Parts/FreelancerStepBasic";
import { getLanguage } from "../../../../Helpers/LanguageHelper";
import { FreelancerStepResume } from "./FreelancerSettings/Parts/FreelancerStepResume";
import { FreelancerStepBank } from "./FreelancerSettings/Parts/FreelancerStepBank";
import { UserHelper } from "../../../../Helpers/UserHelper";
import { getCurrentUser } from "../../../../Helpers/SessionHelper";
import { LoginModel } from "../../../../Models/LoginModel";
import { FreelancerStepVerification } from "./FreelancerSettings/Parts/FreelancerStepVerification";
import { VerificationDocumentsStatus } from "../../../../Models/VerificationDocumentStatus";






interface IProps extends RouteComponentProps {

}

interface IState {
  step: number;
  editing: boolean;
  verificationDocumentStatus: VerificationDocumentsStatus | null;
  user: LoginModel | null;
}

export class CreateUser extends Component<IProps, IState> {
  userHelper = new UserHelper();


  constructor(props: IProps) {
    super(props);

    this.state = {
      step: 1,
      editing: false,
      verificationDocumentStatus: null,
      user: null,




    }

    this.goToProfile = this.goToProfile.bind(this)
    this.stepResume = this.stepResume.bind(this)
    this.stepBasic = this.stepBasic.bind(this)
    this.stepBank = this.stepBank.bind(this)
    this.saveStep = this.saveStep.bind(this)
    this.stepVerificationDocuments = this.stepVerificationDocuments.bind(this)
    this.checkVerificationStatus = this.checkVerificationStatus.bind(this)
  }


  async componentDidMount() {
    let user = getCurrentUser();
    if (user) {
      this.setState({ user })
    }
    if (user && user.Freelancer) {
      let verificationDocumentStatus: VerificationDocumentsStatus | boolean = await this.userHelper.getVerificationDocumentStatus(
        user.Freelancer.CountryId,
        user.Id,
        user.Freelancer.Id
      );
      if (verificationDocumentStatus && typeof verificationDocumentStatus != 'boolean') {
        this.setState({ verificationDocumentStatus })

      }
    }
  }



  scrollToTopFunction() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }


  async saveStep(stepOneCompleted: boolean | null) {
    let nextStep = this.state.step + 1
    if (stepOneCompleted) {
      if (nextStep === 4) {
        await this.checkVerificationStatus();
      }
      if (nextStep === 5) {
        this.props.history.push('/freelancer/profile')
      } else {
        await this.setState({ step: nextStep })
      }
    }
    this.scrollToTopFunction();
  }

  async goToProfile() {
    this.props.history.push('/freelancer/profile')
  }




  checkVerificationStatus() {
    if (this.state.user && this.state.user.Freelancer) {
      if (!this.state.verificationDocumentStatus || typeof this.state.verificationDocumentStatus == 'boolean') {
        return this.goToProfile();
      } else {
        let documentsRejected: boolean = false;
        if (this.state.verificationDocumentStatus.WorkPermit !== 0) {
          documentsRejected = true;
        }
        if (this.state.verificationDocumentStatus.WorkPermitBack !== 0) {
          documentsRejected = true;
        }
        if (this.state.verificationDocumentStatus.PictureId !== 0) {
          documentsRejected = true;
        }
        if (this.state.verificationDocumentStatus.NationalID !== 0) {
          documentsRejected = true;
        }

        if (!documentsRejected) {
          return this.goToProfile();
        }

      }
    } return;
  }



  stepBasic() {
    return (
      <div>
        <div className="backgroundGradient">
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="stretch"
            spacing={0}
          >
            <Grid item xs={12} md={5} className="backgroundWhite relative">
              <div className="padding-32">
                <h1>{getLanguage(777, "Finish your profile")}</h1>
                <Grid container justify="space-between">
                  <Grid item>
                    <h1>{getLanguage(55, "basic")}</h1>
                  </Grid>
                </Grid>
                <FreelancerStepBasic creatingUser={true} saveStep={this.saveStep} />
              </div>
            </Grid>
            <Grid item className="vh-100" />
          </Grid>
        </div>
      </div>
    )
  }

  stepResume() {
    return (
      <div>
        <div className="backgroundGradient">
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="stretch"
            spacing={0}
          >
            <Grid item xs={12} md={5} className="backgroundWhite relative">
              <div className="padding-32">
                <Grid container item justify="space-between">
                  <Grid item>
                    <h2>{getLanguage(56, "resume")}</h2>
                  </Grid>
                  <Grid item>
                    <Button variant="text" onClick={this.goToProfile}>{getLanguage(778, "Skip")}</Button>
                  </Grid>
                </Grid>
                <FreelancerStepResume creatingUser={true} saveStep={this.saveStep} />
              </div>
            </Grid>
            <Grid item className="" />
          </Grid>
        </div>
      </div>
    )
  }

  stepBank() {
    return (
      <div>
        <div className="backgroundGradient">
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="stretch"
            spacing={0}
            className="vh-100"
          >
            <Grid item xs={12} md={5} className="backgroundWhite relative">
              <div className="padding-32">
                <h1>{getLanguage(777, "Finish your profile")}</h1>
                <Grid container justify="space-between">
                  <Grid item>
                    <h2>{getLanguage(49, "step bank")}</h2>
                  </Grid>
                  <Grid item>
                    <Button variant="text" onClick={this.goToProfile}>{getLanguage(778, "Skip")}</Button>
                  </Grid>
                </Grid>
                <FreelancerStepBank creatingUser={true} saveStep={this.saveStep} />

              </div>
            </Grid>
            <Grid item className="" />
          </Grid>
        </div>
      </div>
    )
  }


  stepVerificationDocuments() {
    return (
      <div>
        <div className="backgroundGradient">
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="stretch"
            spacing={0}
          >
            <Grid item xs={12} md={5} className="backgroundWhite relative">
              <div className="padding-32">
                <h1>{getLanguage(779, "Verification")}</h1>
                <Grid container justify="space-between">
                  <Grid item>
                    <h1>{getLanguage(55, "basic")}</h1>
                  </Grid>
                  <Grid item>
                    <Button variant="text" onClick={this.goToProfile}>{getLanguage(778, "Skip")}</Button>
                  </Grid>
                </Grid>
                <FreelancerStepVerification
                  creatingUser={true}
                  saveStep={this.saveStep}
                  history={this.props.history}
                  location={this.props.location}
                  match={this.props.match}
                  verificationDocumentStatus={this.state.verificationDocumentStatus}
                />
              </div>
            </Grid>
            <Grid item className="vh-100" />
          </Grid>
        </div>
      </div>
    )
  }




  render() {
    let step;

    switch (this.state.step) {
      case 1:
        step = this.stepBasic()
        break;
      case 2:
        step = this.stepResume()
        break;
      case 3:
        step = this.stepBank()
        break;
      case 4:
        step = this.stepVerificationDocuments()
        break;
    }

    return (
      <div>
        {step}
      </div>
    );
  }
}
