

export class DateFromStringModel {
    year: string;
    month: string;
    day: string;
    time: string;

    constructor(val: Partial<DateFromStringModel>) {
        this.year = val.year || '';
        this.month = val.month || '';
        this.day = val.day || '';
        this.time = val.time || '';
    }
}