import React, { Component } from "react";
import { TextField, MenuItem, Button } from "@material-ui/core";
import { LanguageHelper, getLanguage } from "../../../../../../Helpers/LanguageHelper";
import { StorageHelper, facadeLocalNames } from "../../../../../../Helpers/StorageHelper";
import { SavingSnackbar } from "../../../../../Parts/General/SavingSnackbar";
import { CompanyHelper } from "../../../../../../Helpers/CompanyHelper";
import { PageLoader } from "../../../../../Parts/General/PageLoader";
import { UserHelper } from "../../../../../../Helpers/UserHelper";
import { getCurrentUser, setUserData } from "../../../../../../Helpers/SessionHelper";
import { LoginModel } from "../../../../../../Models/LoginModel";
import { englishLanguageId } from "../../../../../../Helpers/Cfg";
import { CompanyModel } from "../../../../../../Models/CompanyModel";

import { getPhoneCountryCode } from "../../../../../../Helpers/GenericHelper";
import { CountryModel } from "../../../../../../Models/CountryModel";
import { CountryHelper } from "../../../../../../Helpers/CountryHelper";
import { PhoneField } from "../../../../../Parts/General/PhoneField";
import { SnackbarManager } from "../../../../../../Helpers/SnackbarManager/SnackbarManager";

interface IProps {
  creatingUser?: boolean;
  saveStep?: ((stepBasicComplete: boolean) => void);
}

interface IState {
  companyName: string;
  firstnames: string;
  lastname: string;
  editing: boolean;
  email: string;
  Vat: string;
  address: string;
  city: string;
  ZipCode: string;
  phoneNumber: string;
  mobileNumber: string;
  languages: any[];
  activeLanguage: string;
  company: CompanyModel | null;
  showPageLoader: boolean;
  loginObj: LoginModel | null;
  mobilePhoneError: boolean;
  emailError: boolean;
  addressError: boolean;
  zipCodeError: boolean;
  cityError: boolean;
  CompanyNameError: boolean;
  firstnamesError: boolean;
  lastnameError: boolean;
  phoneError: boolean;

  countries: CountryModel[];
  activeCountryId: string;

}


export class CompanyStepBasic extends Component<IProps, IState> {
  storageHelper = new StorageHelper();
  languageHelper = new LanguageHelper();
  companyHelper = new CompanyHelper();
  userHelper = new UserHelper();
  countryHelper = new CountryHelper();

  phoneRef = React.createRef<PhoneField>();
  mobileRef = React.createRef<PhoneField>();


  constructor(props: IProps) {
    super(props);

    this.state = {
      companyName: '',
      firstnames: '',
      lastname: '',
      email: '',
      Vat: '',
      address: '',
      editing: false,
      city: '',
      ZipCode: '',
      phoneNumber: '',
      mobileNumber: '',
      languages: [],
      activeLanguage: '',
      company: null,
      showPageLoader: false,
      loginObj: null,
      mobilePhoneError: false,
      emailError: false,
      addressError: false,
      zipCodeError: false,
      cityError: false,
      CompanyNameError: false,
      firstnamesError: false,
      lastnameError: false,
      phoneError: false,
      countries: [],
      activeCountryId: '',
    }

    this.changeLanguage = this.changeLanguage.bind(this)
    this.fillForm = this.fillForm.bind(this)
    this.resetForm = this.resetForm.bind(this)
    this.saveChanges = this.saveChanges.bind(this)
  }

  async componentDidMount() {
    await this.fillForm();
  }

  async fillForm() {
    await this.setState({ showPageLoader: true })

    let user: LoginModel | null = getCurrentUser();
    if (user == null || user.Company == null) return;


    let companyFromDB: CompanyModel = await this.companyHelper.getCompanyById(user.Company.Id)
    user.Company = companyFromDB;

    if (!user.Company) return;

    let countryId = user.Company.CountryId
    let activeCountry: CountryModel | undefined = undefined;

    let countries = this.storageHelper.getCountriesFromLocal();
    activeCountry = countries.find(x => x.Id === countryId) || countries[0];

    await this.setState({
      loginObj: user,
      company: companyFromDB,
      activeLanguage: user.PrimaryLanguage,
      countries: countries || [],
      activeCountryId: activeCountry.Id
    })
    let address = "";
    if (companyFromDB && companyFromDB.AddressLine1) {
      address = address.concat(companyFromDB.AddressLine1)
      if (companyFromDB.AddressLine2 && companyFromDB.AddressLine2 !== "") {
        address = address.concat(" ")
        address = address.concat(companyFromDB.AddressLine2)
      }
    }

    let localLang = await this.languageHelper.getLocalLanguagesForCountry(companyFromDB.CountryId, false);
    if (localLang) {
      let languages = [];
      for (const lang of localLang) {
        let knownLanguage = await this.languageHelper.getLanguageFromKnownLanguages(lang.LanguageId)
        languages.push(knownLanguage[0])
      }
      let globalLang = await this.languageHelper.getLanguageFromKnownLanguages(englishLanguageId) //English - Global
      languages.push(globalLang[0]);
      this.setState({ languages: languages })
    }

    await this.setState({
      companyName: companyFromDB.CompanyName, email: user.Email, Vat: companyFromDB.Vat || '', address: address, city: companyFromDB.City,
      ZipCode: companyFromDB.ZipCode, phoneNumber: companyFromDB.Phone, mobileNumber: companyFromDB.MobilePhone,
      firstnames: companyFromDB!.Login!.Firstnames, lastname: companyFromDB!.Login!.Lastname
    })
    await this.setState({ showPageLoader: false })
  }

  async changeLanguage(event: any) {
    await this.setState({ activeLanguage: event.target.value, editing: true })
  }

  async saveChanges() {
    await this.setState({ showPageLoader: true })
    let user: LoginModel | null = this.state.loginObj;

    if (!user || !this.state.company) return;

    let loginObject: LoginModel = new LoginModel({});

    loginObject.Firstnames = this.state.firstnames
    loginObject.Lastname = this.state.lastname

    let company: CompanyModel = new CompanyModel({});
    company = this.state.company;
    company.CompanyName = this.state.companyName;
    company.Vat = this.state.Vat;
    company.AddressLine1 = this.state.address;
    company.ZipCode = this.state.ZipCode;
    company.City = this.state.city;
    if (this.phoneRef.current) {
      company.Phone = this.phoneRef.current.getRawPhoneInput();
    }
    if (this.mobileRef.current) {
      company.MobilePhone = this.mobileRef.current.getRawPhoneInput();
    }
    company.CountryId = this.state.company.CountryId;

    user.Company = company;
    user.Company.Login = loginObject;

    let success: any = false;

    let stepBasicComplete: number[] = []
    if (!user.Company.IsPrivate) {
      stepBasicComplete = await this.userHelper.verifyStepBasicComplete(user)
    }

    if (stepBasicComplete.length > 0) {
      let failString = '';
      for (const fail of stepBasicComplete) {
        failString = failString.concat(getLanguage(fail, "step basic fail info"))
      }
      switch (stepBasicComplete[0]) {
        case 72:
          await this.setState({ lastnameError: true })
          break;
        case 101:
          await this.setState({ CompanyNameError: true })
          break;
        case 73:
          await this.setState({ emailError: true })
          break;
        case 75:
          await this.setState({ mobilePhoneError: true })
          break;
        case 664:
          await this.setState({ phoneError: true })
          break;
        case 77:
          await this.setState({ addressError: true })
          break;
        case 78:
          await this.setState({ zipCodeError: true })
          break;
        case 79:
          await this.setState({ cityError: true })
          break;
      }
      SnackbarManager.Instance.addWarning(failString);
      await this.setState({
        showPageLoader: false,
        editing: false
      })
      return;
    } else {
      this.setState({ CompanyNameError: false, cityError: false, emailError: false, addressError: false, mobilePhoneError: false, phoneError: false, zipCodeError: false, lastnameError: false })


      if (user.PrimaryLanguage !== this.state.activeLanguage) {
        let userHelper = new UserHelper();
        let updatedLanguage = await userHelper.updatePrimaryLanguage(this.state.activeLanguage);

        if (updatedLanguage) {
          user.PrimaryLanguage = this.state.activeLanguage;
          this.storageHelper.updateFacadeLocalization(facadeLocalNames.language, this.state.activeLanguage);
          await this.languageHelper.cacheFacadeLanguageStrings(this.state.activeLanguage);
          setUserData(user);
          SnackbarManager.Instance.addSuccess(getLanguage(697, 'Changes saved'));
          await this.setState({ editing: false, loginObj: user });
        } else {
          SnackbarManager.Instance.addError(getLanguage(698, 'Changes could not be saved'));
          await this.setState({ editing: false });
        }
      }

      success = await this.companyHelper.updateCompany(company);

      if (success) {
        let oldUser = getCurrentUser();
        if (oldUser?.Email !== this.state.email) {
          let updateEmailSuccess = await this.userHelper.updateEmail(this.state.email)

          if (updateEmailSuccess.status === 409) {
            SnackbarManager.Instance.addWarning(getLanguage(618, "Email address is already in use"));
            success = false
          } else if (updateEmailSuccess.status !== 200) {
            SnackbarManager.Instance.addWarning(getLanguage(469, "Unknown error"));
            success = false
          } else {
            if (user && user?.Company?.Login) {
              user.Email = this.state.email;
              user.Company.Login.Email = user.Email
            }
          }
        }
      }
    }


    if (success && success.Login) {
      user.vmStepBasicDone = true;
      setUserData(user);
      if (this.props.creatingUser && this.props.saveStep) {
        return this.props.saveStep(true);
      }
      this.forceUpdate();
      this.storageHelper.sendEvent('userUpdated')
      if (this.props.creatingUser && this.props.saveStep) {
        return this.props.saveStep(true);
      }


      await this.fillForm();
      SnackbarManager.Instance.addSuccess(getLanguage(697, 'Changes saved'));
      await this.setState({ editing: false, showPageLoader: false });

    } else {
      SnackbarManager.Instance.addError(getLanguage(698, 'Changes could not be saved'));
      this.setState({ editing: false, showPageLoader: false });
      await this.fillForm();
    }
  }



  async resetForm() {
    await this.setState({ showPageLoader: true })
    await this.setState({ editing: false })
    await this.fillForm();
    SnackbarManager.Instance.addSuccess(getLanguage(722, 'reset'));
    await this.setState({ showPageLoader: false })

  }


  creatingUser() {
    if (this.props.creatingUser) {

      return (
        <div>
          <div className="text-right margin-v-32">
            <Button className="button-gradient" onClick={() => this.saveChanges()}>Next</Button>
          </div>
        </div>
      )
    }
    return (
      <div />
    )
  }


  render() {
    let savingSnackBar: any;

    if (this.state.editing === true && !this.props.creatingUser) {
      savingSnackBar = <SavingSnackbar open={this.state.editing} resetClicked={this.resetForm} saveClicked={this.saveChanges} />
    }

    let nextButton = this.creatingUser();

    let user: LoginModel | null = getCurrentUser();
    if (!user || !user.Company || !user!.Company!.IsPrivate) {
      return (
        <div>
          <TextField
            type="text"
            label={getLanguage(101, "Company name")}
            variant="outlined"
            fullWidth
            margin="normal"
            error={this.state.CompanyNameError}
            value={this.state.companyName}
            onChange={(event) => this.setState({ companyName: event.target.value, editing: true })}

          />
          <TextField
            type="text"
            label={getLanguage(71, "First name")}
            variant="outlined"
            fullWidth
            margin="normal"
            error={this.state.firstnamesError}
            value={this.state.firstnames}
            onChange={event =>
              this.setState({ firstnames: event.target.value, editing: true })
            }
          />
          <TextField
            type="text"
            label={getLanguage(72, "Last name")}
            variant="outlined"
            fullWidth
            margin="normal"
            error={this.state.lastnameError}
            value={this.state.lastname}
            onChange={event =>
              this.setState({ lastname: event.target.value, editing: true })
            }
          />
          <TextField
            type="email"
            label={getLanguage(73, "Email")}
            variant="outlined"
            fullWidth
            margin="normal"
            error={this.state.emailError}
            value={this.state.email}
            onChange={(event) => this.setState({ email: event.target.value, editing: true })}
          />
          <TextField
            type="text"
            label={getLanguage(102, "VAT number")}
            variant="outlined"
            fullWidth
            margin="normal"
            value={this.state.Vat}
            onChange={(event) => this.setState({ Vat: event.target.value, editing: true })}
          />
          <TextField
            type="text"
            label={getLanguage(77, "Address")}
            variant="outlined"
            fullWidth
            margin="normal"
            error={this.state.addressError}
            value={this.state.address}
            onChange={(event) => this.setState({ address: event.target.value, editing: true })}
          />
          <TextField
            type="text"
            label={getLanguage(78, "Zip code")}
            variant="outlined"
            fullWidth
            margin="normal"
            error={this.state.zipCodeError}
            value={this.state.ZipCode}
            onChange={(event) => this.setState({ ZipCode: event.target.value, editing: true })}
          />
          <TextField
            type="text"
            label={getLanguage(79, "City")}
            variant="outlined"
            fullWidth
            margin="normal"
            error={this.state.cityError}
            value={this.state.city}
            onChange={(event) => this.setState({ city: event.target.value, editing: true })}
          />
          <TextField
            id="outlined-language"
            select
            label={getLanguage(81, "Language")}
            fullWidth
            margin="normal"
            variant="outlined"
            value={this.state.activeLanguage}
            onChange={(event) => this.changeLanguage(event)}
          >
            {this.state.languages.map((row, index) => (
              <MenuItem key={index} value={row.Id} >{row.LanguageCode ? getLanguage(row.LanguageCode, "Language name") : ""}</MenuItem>
            ))}
          </TextField>

          <div className="phoneInput">
            <label id="phoneInputLabel">{getLanguage(103, "Phone number")}</label>
            <PhoneField
              ref={this.phoneRef}
              countryCode={getPhoneCountryCode(this.state.countries, this.state.activeCountryId, this.storageHelper)}
              value={this.state.phoneNumber}
              onChange={(val) => this.setState({ phoneNumber: val, editing: true })}
              onFocus={(val) => this.setState({ phoneNumber: val })}
            />
          </div>

          <div className="phoneInput">
            <label id="phoneInputLabel">{getLanguage(75, "Mobile number")}</label>
            <PhoneField
              ref={this.mobileRef}
              countryCode={getPhoneCountryCode(this.state.countries, this.state.activeCountryId, this.storageHelper)}
              value={this.state.mobileNumber}
              onChange={(val) => this.setState({ mobileNumber: val, editing: true })}
              onFocus={(val) => this.setState({ mobileNumber: val })}
            />
          </div>
          {savingSnackBar}
          {nextButton}
          {this.state.showPageLoader ? <PageLoader /> : null}
        </div>
      );
    } else {
      return (
        <div>
          <TextField
            type="text"
            label={getLanguage(71, "First name")}
            variant="outlined"
            fullWidth
            margin="normal"
            error={this.state.firstnamesError}
            value={this.state.firstnames}
            onChange={event =>
              this.setState({ firstnames: event.target.value, editing: true })
            }
          />
          <TextField
            type="text"
            label={getLanguage(72, "Last name")}
            variant="outlined"
            fullWidth
            margin="normal"
            error={this.state.lastnameError}
            value={this.state.lastname}
            onChange={event =>
              this.setState({ lastname: event.target.value, editing: true })
            }
          />
          <TextField
            type="email"
            label={getLanguage(73, "Email")}
            variant="outlined"
            fullWidth
            margin="normal"
            error={this.state.emailError}
            value={this.state.email}
            onChange={(event) => this.setState({ email: event.target.value, editing: true })}
          />
          <TextField
            id="outlined-language"
            select
            label={getLanguage(81, "Language")}
            fullWidth
            margin="normal"
            variant="outlined"
            value={this.state.activeLanguage}
            onChange={(event) => this.changeLanguage(event)}
          >
            {this.state.languages.map((row, index) => (
              <MenuItem key={index} value={row.Id} >{row.LanguageCode ? getLanguage(row.LanguageCode, "Language name") : ""}</MenuItem>
            ))}
          </TextField>
          <div className="phoneInput">
            <label id="phoneInputLabel">{getLanguage(75, "Mobile number")}</label>
            <PhoneField
              ref={this.mobileRef}
              countryCode={getPhoneCountryCode(this.state.countries, this.state.activeCountryId, this.storageHelper)}
              value={this.state.mobileNumber}
              onChange={(val) => this.setState({ mobileNumber: val, editing: true })}
              onFocus={(val) => this.setState({ mobileNumber: val })}
            />
          </div>
          {savingSnackBar}
          {nextButton}
          {this.state.showPageLoader ? <PageLoader /> : null}
        </div>
      );
    }
  }
}
