import { CourseModel } from "./CourseModel";
import { CourseAttendanceModel } from "./CourseAttendanceModel";

export class CourseDateModel {
  Id: string;
  CourseId: string;
  Course: CourseModel | null;
  Date: string;
  StartAtUTC: string;
  EndAtUTC: string;
  CourseAttendances: CourseAttendanceModel[];


  constructor(val: Partial<CourseDateModel>) {
    this.Id = val.Id || '';
    this.CourseId = val.CourseId || '';
    this.Course = val.Course || null;
    this.Date = val.Date || '';
    this.StartAtUTC = val.StartAtUTC || '';
    this.EndAtUTC = val.EndAtUTC || '';
    this.CourseAttendances = val.CourseAttendances || [];
  }
}