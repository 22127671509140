import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import "./PostingCard.scss";
import { PostingModel } from "../../../../Models/PostingModel";
import { getDateFormatAccordingToLanguage, getLanguage } from "../../../../Helpers/LanguageHelper";
import { getCurrentUser } from "../../../../Helpers/SessionHelper";
import { StorageHelper } from "../../../../Helpers/StorageHelper";
import { getCurrencyFormat } from "../../../../Helpers/LocalizationHelper";
import { LoginModel } from "../../../../Models/LoginModel";
import { ProfilePictureViewer } from "../../../Parts/General/ProfilePictureViewer";
import { FavoriteProfileModel } from "../../../../Models/FavoriteProfileModel";
import { resolvePostingSalaryPerHour } from "../../../../Modules/CollectiveAgreement/Utils";


interface IProps {
  posting: PostingModel;
  favourites?: FavoriteProfileModel[];
}
interface IState {
  coverImage: string;
  user: LoginModel | null;
}
export class PostingCard extends Component<IProps, IState> {
  storageHelper = new StorageHelper();

  constructor(props: IProps) {
    super(props);
    this.state = {
      coverImage: '',
      user: null,

    }
    this.getCoverPath = this.getCoverPath.bind(this);
    this.getJobDistanceFromChabber = this.getJobDistanceFromChabber.bind(this)
  }

  componentDidMount() {
    let user = getCurrentUser();
    if (user)
      this.setState({ user })
    this.getCoverPath();
  }

  getCoverPath() {
    if (this.props.posting.Company == null) return;
    let coverImage = this.props.posting.Company.CoverImagePath.replace('Assets', 'img').replace('assets', 'img');
    this.setState({ coverImage });
  }

  companyName() {
    if (this.props.posting.Company) {
      return this.props.posting.Company.CompanyName;
    }
    return 'Missing name';
  }

  jobTitle() {
    let currentUser = getCurrentUser();
    let skill = this.storageHelper.getSkillsFromLocal()!.find(x => x.Id === this.props.posting.SkillId);
    let languageCode = skill!.getLanguageCode(this.props.posting.AmountNeeded, currentUser ? currentUser.LoginType === 1 : true);
    return this.props.posting.AmountNeeded + ' ' + getLanguage(languageCode);
  }

  getJobTime() {
    return this.props.posting.StartAtTime + ' - ' + this.props.posting.EndAtTime;
  }

  getJobDate() {
    if (this.props.posting && this.props.posting.StartAtLocal) {
      return getDateFormatAccordingToLanguage(this.props.posting.StartAtLocal, true, true);
    }
    return '';
  }

  hourlySalary() {
    if (this.props.posting && this.props.posting.Currency) {
      let val = resolvePostingSalaryPerHour(this.props.posting) / 100;

      let salaryString = getCurrencyFormat(this.props.posting.Currency, true, val);
      return salaryString;
    } return;
  }

  getJobDistanceFromChabber() {
    let jobDistance: string = getLanguage(437, '( {{distance}} km)');

    if (this.props.posting
      && (this.props.posting.DistanceFromUserInKm || this.props.posting.DistanceFromUserInKm != null && this.props.posting.DistanceFromUserInKm >= 0)) {
      jobDistance = jobDistance.replace('{{distance}}', this.props.posting.DistanceFromUserInKm.toString())
    } else {
      jobDistance = '';
    }

    return jobDistance;
  }

  favouriteBadge() {
    if (this.props.favourites == null || this.props.favourites.length < 1) return;

    let fave = this.props.favourites.find(x => x.CompanyProfileId === this.props.posting.CompanyId);
    if (fave?.ApprovalStatus === 1)
      return <div className="favouriteIndicator"><i className="fas fa-heart" /></div>;
    return null;
  }


  render() {
    return (
      <div className="postingCardWrapper">
        {this.state.user ? <div className="salaryIndicator"><i className="fas fa-coins fa-fw"></i> {this.hourlySalary()}</div> : <div />}
        <div className="PostingCard_cover" style={{ backgroundImage: 'url(' + this.state.coverImage + ')' }} />
        <div className="postingCard_content">
          <Grid container spacing={1}>
            <Grid item sm={4} xs={3}>
              <div className="outer">
                <div className="inner">
                  <ProfilePictureViewer
                    login={this.props.posting.Company!.Login}
                    company={this.props.posting.Company}
                    freelancer={null}
                    customClass="ap_image"
                    altText=""
                  ></ProfilePictureViewer>
                </div>
              </div>
            </Grid>
            <Grid item xs container>
              <Grid item xs container direction="column" spacing={1}>
                <Grid item xs>
                  <div className="companyName">{this.companyName()}</div>
                  <div className="freelancersNeeded">{this.jobTitle()}</div>
                </Grid>
                {this.favouriteBadge()}
                <Grid item container>
                  <Grid item xs={6}><i className="fas fa-calendar fa-fw"></i> {this.getJobDate()}</Grid>
                  <Grid item xs={6}><i className="fas fa-clock fa-fw"></i> {this.getJobTime()}</Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        {/* <div>{this.getJobDistanceFromChabber()}</div> */}
      </div>
    );
  }

}