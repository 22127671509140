import React, { Component } from "react";
import { BrowserInfo, BotInfo, NodeInfo } from "detect-browser";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Grid
} from "@material-ui/core";

interface IProps {
  browser: BrowserInfo | BotInfo | NodeInfo | null;
}
interface IState { }
export class UnsupportedBrowser extends Component<IProps, IState> {
  render() {
    return (
      <div>
        <div className="padding-40 maxW-600 text-center">
          <h1>Unsupported browser</h1>
          <p>
            You're using an outdated and unsupported browser
          </p>
          <br />
          <p className="text-center padding-bottom-40">
            Please either upgrade your current browser or download a more modern browser. Choose from the versions below.
          </p>
          <div className="margin-v-40">
            <Grid container justify="center" spacing={4}>
              <Grid item>
                <a href="https://www.google.com/chrome/">
                  <div className="paper radius-12 padding-16">
                    <div>
                      <i className="fab fa-chrome fa-4x"></i>
                    </div>
                    <h2 className="inline-block margin-0">Chrome</h2>
                    <div>
                      <Button href="https://www.google.com/chrome/">
                        Download
                      </Button>
                    </div>
                  </div>
                </a>
              </Grid>
              <Grid item>
                <a href="https://www.mozilla.org/firefox/new/">
                  <div className="paper radius-12 padding-16">
                    <div>
                      <i className="fab fa-firefox fa-4x"></i>
                    </div>
                    <h2 className="inline-block margin-0">Firefox</h2>
                    <div>
                      <Button href="https://www.mozilla.org/firefox/new/">
                        Download
                      </Button>
                    </div>
                  </div>
                </a>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}
