import React, { Component } from "react";
import { TextField, MenuItem, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { CountryHelper } from "../../../../../../../Helpers/CountryHelper";
import { UserHelper } from "../../../../../../../Helpers/UserHelper";
import {
  LanguageHelper, getLanguage
} from "../../../../../../../Helpers/LanguageHelper";
import { StorageHelper } from "../../../../../../../Helpers/StorageHelper";
import uuid from "uuid";
import { LoginModel } from "../../../../../../../Models/LoginModel";
import { getCurrentUser } from "../../../../../../../Helpers/SessionHelper";
import { ExperienceModel } from "../../../../../../../Models/ExperienceModel";
import { ExperienceCategoryModel } from "../../../../../../../Models/ExperienceCategoryModel";
import { experienceToDateString } from "../../../../../../../Helpers/DateTimeHelper";
import { SnackbarManager } from "../../../../../../../Helpers/SnackbarManager/SnackbarManager";



interface IProps {
  creatingUser: boolean;
  updateWorkExperience: (experiences: ExperienceModel[]) => void;
  reset: boolean;
  initialStartup: (component: 'experience', data: any) => void;
  validationProblem: {
    type: string;
    index: number;
  } | null;
}

interface IState {
  newCompanyName: string;
  newExperienceCategoryId: string;
  newFromMonth: number;
  newFromYear: number;
  newToMonth: number;
  newFromMonthAsString: string;
  newToMonthAsString: string;
  newToYear: number | string;
  newJobDescription: string;
  experienceCategories: ExperienceCategoryModel[];
  openWorkExperienceId: string;
  newJobTitle: string;
  experiences: ExperienceModel[];
  months: string[];
  years: (string | number)[];
  openDialog: boolean;
  user: LoginModel;
  addWorkDisabled: boolean;
  otherJobType: string;
  disableToMonth: boolean;
}





export class Experience extends Component<IProps, IState> {
  countryHelper = new CountryHelper();
  userHelper = new UserHelper();
  languageHelper = new LanguageHelper();
  storageHelper = new StorageHelper();

  constructor(props: IProps) {
    super(props);

    this.state = {
      newCompanyName: '',
      newExperienceCategoryId: '',
      newFromMonth: 0,
      newFromYear: 0,
      newToMonth: 0,
      newToYear: 0,
      newJobDescription: '',
      experienceCategories: [],
      openWorkExperienceId: '',
      newJobTitle: '',
      experiences: [],
      months: [],
      years: [],
      openDialog: false,
      user: new LoginModel({}),
      addWorkDisabled: true,
      otherJobType: 'hidden',
      newFromMonthAsString: '',
      newToMonthAsString: '',
      disableToMonth: false,


    };

    this.newToYear = this.newToYear.bind(this)

  }

  async UNSAFE_componentWillReceiveProps(nextprops: IProps) { //TODO: This is entirely wrong way of doing this.
    if (nextprops.reset)
      await this.prepareForm();
    if (nextprops.validationProblem && nextprops.validationProblem.type === 'experience') {
      let ex = this.state.experiences;
      for (let i = 0; i < ex.length; i++) {
        if (i === nextprops.validationProblem!.index) {
          ex[i].error = true;
        } else {
          ex[i].error = false;
        }

      }
      await this.setState({ experiences: ex })
    }
  }

  async componentDidMount() {
    await this.prepareForm();

  }



  async prepareForm() {
    let user: LoginModel | null = await getCurrentUser();

    if (!user || !user.Freelancer) {
      SnackbarManager.Instance.addError(getLanguage(469, 'Unknown error'));
      return;
    }

    await this.setState({ user: user })


    let experienceCategories = this.storageHelper.getExperienceCategoriesFromLocal();

    if (experienceCategories == null) return;

    experienceCategories = experienceCategories.sort((a, b) => (a.SortBy > b.SortBy) ? 1 : -1);

    let months = this.languageHelper.getMonthsByLanguage(false);

    await this.setState({ months })


    let experiencesToPrep: ExperienceModel[] = user.Freelancer.Experiences.map(x => {
      return ({
        CompanyName: x.CompanyName,
        Description: x.Description,
        ExperienceCategoryId: x.ExperienceCategoryId,
        FreelancerProfileId: x.FreelancerProfileId,
        FromMonth: x.FromMonth,
        FromYear: x.FromYear,
        Id: x.Id,
        JobTitle: x.JobTitle,
        ToMonth: x.ToMonth,
        ToYear: x.ToYear
      } as ExperienceModel)
    });




    let preppedExperiences: ExperienceModel[] = [];
    for (const i of experiencesToPrep) {
      let toYear = i.ToYear;
      let toMonth = i.ToMonth;
      if (typeof toYear !== 'number') {
        toMonth = -1;
        toYear = 0
      }

      let ex: ExperienceModel = {
        Id: i.Id,
        CompanyName: i.CompanyName,
        Description: i.Description,
        ExperienceCategoryId: i.ExperienceCategoryId,
        FreelancerProfileId: i.FreelancerProfileId,
        FromMonth: i.FromMonth,
        FromMonthAsString: await this.convertNumberToMonth(i.FromMonth),
        FromYear: i.FromYear,
        ToMonth: toMonth,
        ToMonthAsString: await this.convertNumberToMonth(toMonth),
        ToYear: toYear,
        JobTitle: i.JobTitle,
        error: false,
        DescriptionNonLocalised: i.DescriptionNonLocalised
      }
      preppedExperiences.push(ex)
    }




    let currentYear = new Date().getFullYear();
    let year = [];
    for (let i = currentYear; i >= 2000; i--) {
      year.push(i.toString())
    }
    year.unshift(getLanguage(318, "Still employed"))
    await this.setState({
      years: year,
      experienceCategories: experienceCategories,
      experiences: preppedExperiences,
      user: user,
    })

    this.props.initialStartup('experience', this.state.experiences)


  }


  returnCategoryName(catId: string) {
    let cats = this.state.experienceCategories;
    let experiences = this.state.experiences;
    for (const c of cats) {
      if (catId === '75121c82-e2d7-478a-97f8-e4c90f23e547') {
        for (const ex of experiences) {
          if (ex.ExperienceCategoryId === catId) {
            return ex.JobTitle
          }
        }
      }
      if (catId === c.Id)
        return (getLanguage(c.LanguageCode, 'experience category title'))
    }
  }



  openJobExperienceDialog(experienceId: string) {
    if (experienceId === '') return this.setState({ openDialog: true });

    let existingExperiences = this.state.experiences;
    let experience = existingExperiences.find(x => x.Id === experienceId);
    if (experience == null) return;

    this.newToYear(experience.ToYear)
    let jobTitle: string = experience.JobTitle && experience.JobTitle !== '' ? experience.JobTitle : '';
    if (jobTitle && jobTitle !== '') {
      this.changeExperienceCategory(experience.ExperienceCategoryId);
    }
    let toYear = (experience.ToYear === 0 ? getLanguage(318, "Still employed") : experience.ToYear);
    this.setState({
      openWorkExperienceId: experienceId,
      newCompanyName: experience.CompanyName ? experience.CompanyName : '',
      newFromMonth: experience.FromMonth ? experience.FromMonth : 0,
      newFromMonthAsString: this.convertNumberToMonth(experience.FromMonth),
      newFromYear: experience.FromYear ? experience.FromYear : 0,
      newToMonth: experience.ToMonth ? experience.ToMonth : 0,
      newToMonthAsString: this.convertNumberToMonth(experience.ToMonth),
      newToYear: toYear,
      newJobTitle: jobTitle,
      newJobDescription: experience.Description ? experience.Description : '',
      newExperienceCategoryId: experience.ExperienceCategoryId,
      openDialog: true,
    })
  }


  convertMonthToNumber(month: string) {
    return this.state.months.indexOf(month);
  }



  convertNumberToMonth(number: number) {
    if (number === -1) return '';
    return this.state.months[number];
  }




  async addNewWorkExperience() {
    if (!this.state.newExperienceCategoryId || this.state.newExperienceCategoryId === '' ||
      !this.state.newFromMonthAsString || this.state.newFromMonthAsString === '' ||
      !this.state.newFromYear || this.state.newFromYear === -1 ||
      !this.state.newToYear ||
      !this.state.newCompanyName || this.state.newCompanyName === '' ||
      !this.state.newJobDescription || this.state.newJobDescription === '') {
      SnackbarManager.Instance.addError(getLanguage(665, 'You have to fill out all fields'));
      return;
    }
    if (this.state.newToYear !== getLanguage(318, "Still employed") && (!this.state.newToMonthAsString || this.state.newToMonthAsString === '')) {
      SnackbarManager.Instance.addError(getLanguage(665, 'You have to fill out all fields'));
      return;
    }
    if (this.state.newExperienceCategoryId === '75121c82-e2d7-478a-97f8-e4c90f23e547') {
      if (!this.state.newJobTitle || this.state.newJobTitle === '') {
        SnackbarManager.Instance.addError(getLanguage(665, 'You have to fill out all fields'));
        return;
      }
    }

    if (!this.state.user || !this.state.user.Freelancer) {
      SnackbarManager.Instance.addError(getLanguage(469, 'Unknown error from experience'));
      return;
    }

    let experiencesArray = this.state.experiences;

    let id = uuid();
    let toYear = this.state.newToYear;
    let toMonth = this.state.newToMonth;
    let toMonthAsString = this.state.newToMonthAsString;
    let stillEmployedString = getLanguage(318, "Still employed")
    if (this.state.newToYear === stillEmployedString) {
      toMonth = -1;
      toMonthAsString = ''
      toYear = 0
    } else {
      toMonth = this.convertMonthToNumber(this.state.newToMonthAsString)
      toYear = +this.state.newToYear
    }


    let experience: ExperienceModel = {
      Id: id,
      ExperienceCategoryId: this.state.newExperienceCategoryId,
      CompanyName: this.state.newCompanyName,
      FromMonth: this.convertMonthToNumber(this.state.newFromMonthAsString),
      FromMonthAsString: this.state.newFromMonthAsString,
      FromYear: this.state.newFromYear,
      ToMonth: toMonth,
      ToMonthAsString: toMonthAsString,
      ToYear: toYear,
      Description: this.state.newJobDescription,
      JobTitle: this.state.newJobTitle,
      FreelancerProfileId: this.state.user.Freelancer.Id,
      error: false,
      DescriptionNonLocalised: '',
    }

    if (this.state.openWorkExperienceId) {
      for (const ex of experiencesArray) {
        if (this.state.openWorkExperienceId === ex.Id) {
          experience = ex;
          experience.ExperienceCategoryId = this.state.newExperienceCategoryId;
          experience.CompanyName = this.state.newCompanyName;
          experience.FromMonth = this.convertMonthToNumber(this.state.newFromMonthAsString);
          experience.FromMonthAsString = this.state.newFromMonthAsString;
          experience.FromYear = this.state.newFromYear;
          experience.ToMonth = toMonth;
          experience.ToMonthAsString = await this.convertNumberToMonth(toMonth);
          experience.ToYear = toYear;
          experience.Description = this.state.newJobDescription;
          experience.JobTitle = this.state.newJobTitle;
          experience.FreelancerProfileId = this.state.user.Freelancer.Id;
        }
      }
    } else {
      experiencesArray.push(experience)
    }

    await this.setState({
      experiences: experiencesArray,
      openDialog: false,
      newCompanyName: '',
      newFromMonth: -1,
      newFromYear: -1,
      newToMonth: -1,
      newToYear: -1,
      newJobDescription: '',
      newExperienceCategoryId: '',
      openWorkExperienceId: '',
    })

    this.props.updateWorkExperience(experiencesArray)

  }




  async closeWorkDialog() {
    await this.setState({
      openDialog: false,
      newCompanyName: '',
      newFromMonth: -1,
      newFromMonthAsString: '',
      newFromYear: -1,
      newToMonth: -1,
      newToMonthAsString: '',
      newToYear: -1,
      newJobDescription: '',
      newExperienceCategoryId: '',
      openWorkExperienceId: '',
      otherJobType: 'hidden',
      newJobTitle: ''
    })
  }

  async deleteJobExperience() {
    if (this.state.openWorkExperienceId !== '') {
      let allExperiences = await this.state.experiences;
      for (let i = 0; i < allExperiences.length; i++) {

        if (allExperiences[i].Id === this.state.openWorkExperienceId)
          allExperiences.splice(i, 1)
      }
      await this.setState({
        experiences: allExperiences,
        newCompanyName: '',
        newFromMonth: -1,
        newFromYear: -1,
        newToMonth: -1,
        newToYear: -1,
        newJobDescription: '',
        newExperienceCategoryId: '',
        openWorkExperienceId: ''
      })
      await this.setState({ openDialog: false })
      this.props.updateWorkExperience(this.state.experiences)
    }
  }

  changeExperienceCategory(newCategory: string) {
    if (newCategory === '75121c82-e2d7-478a-97f8-e4c90f23e547') {
      this.setState({ otherJobType: '', newExperienceCategoryId: newCategory })
    } else {
      this.setState({ otherJobType: 'hidden', newJobTitle: '', newExperienceCategoryId: newCategory })
    }
  }


  newToYear(newToYear: string | number) {
    let stillEmployedString = getLanguage(318, "Still employed");
    if (newToYear !== null && (newToYear === stillEmployedString || newToYear === 0)) {
      this.setState({ disableToMonth: true, newToYear })
    } else {
      this.setState({ disableToMonth: false, newToYear })
    }
  }



  render() {
    return (
      <div>
        {this.state.experiences.map((row, index) => (
          <div
            key={index}
            className={'freelancerResume_item' + (row.error ? ' error' : '')} >
            <div className="freelancerResume_edit" onClick={() => this.openJobExperienceDialog(row.Id)}>
              <i className="fas fa-edit fa-lg" />
            </div>
            <div key={index + 1} className="freelancerResume_item_title">{this.returnCategoryName(row.ExperienceCategoryId)} - {row.CompanyName}</div>
            <div className="freelancerResume_item_date">
              {experienceToDateString(row, false)}
            </div>
            <div className="freelancerResume_item_content">{row.Description}</div>
          </div>
        ))}

        <div className="text-center">
          <Button
            className="default"
            variant="text"
            size="medium"
            onClick={() => this.openJobExperienceDialog('')}
          >
            <span className="btn-icon">
              <i className="fas fa-plus" />
            </span>
            {getLanguage(319, "Add item")}
          </Button>
        </div>




        <Dialog
          open={this.state.openDialog}
          aria-labelledby="form-dialog-title"
          fullWidth
          className="dialog"
        >
          <DialogTitle id="form-dialog-title">
            <Grid container justify="space-between" alignItems="center">
              <Grid item> {getLanguage(320, "Add work experience")}</Grid>
              <Grid item>
                <Button
                  className="icon inputGroup_item"
                  variant="text"
                  size="medium"
                  onClick={() => this.deleteJobExperience()}
                >
                  <span>
                    <i className="fas fa-trash-alt"> </i>
                  </span>
                </Button>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item sm={8}>
                <TextField
                  autoFocus
                  variant="outlined"
                  margin="dense"
                  id="name"
                  label={getLanguage(101, "Company name")}
                  type="text"
                  fullWidth
                  value={this.state.newCompanyName}
                  onChange={(event) => this.setState({ newCompanyName: event.target.value })}
                />
              </Grid>
              <Grid item sm={4}>
                <TextField
                  id="outlined-gender"
                  select
                  label={getLanguage(199, "Job type")}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  value={this.state.newExperienceCategoryId}
                  onChange={(event) => this.changeExperienceCategory(event.target.value)}
                >
                  {this.state.experienceCategories.map((row, index) => (
                    <MenuItem value={row.Id} key={index}>{getLanguage(row.LanguageCode, 'ExperienceCategory')}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item sm={12} className={this.state.otherJobType}>
                <TextField
                  autoFocus
                  variant="outlined"
                  margin="none"
                  id="otherTitle"
                  label={getLanguage(325, "Other title")}
                  type="text"
                  fullWidth
                  value={this.state.newJobTitle}
                  onChange={(event) => this.setState({ newJobTitle: event.target.value })}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} justify="space-between">
              <Grid container spacing={1} item md={6}>
                <Grid item sm={7}>
                  <TextField
                    select
                    variant="outlined"
                    margin="dense"
                    id="month"
                    label={getLanguage(323, "From month")}
                    fullWidth
                    value={this.state.newFromMonthAsString}
                    onChange={(event) => this.setState({ newFromMonthAsString: event.target.value })}
                  >
                    {this.state.months.map((row, index) => (
                      <MenuItem value={row} key={index}>{row}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item sm={5}>
                  <TextField
                    variant="outlined"
                    select
                    margin="dense"
                    id="fromYear"
                    label={getLanguage(321, "From year")}
                    type="text"
                    fullWidth
                    value={this.state.newFromYear}
                    onChange={(event) => this.setState({
                      newFromYear: +event.target.value,
                      newToYear: event.target.value > this.state.newToYear ? event.target.value : this.state.newToYear
                    })}
                  >
                    {this.state.years.map((row, index) => (
                      <MenuItem value={row} key={index}
                        classes={{
                          root: 'fromListValues'
                        }}
                      >{row}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid container spacing={1} item md={6}>
                <Grid item sm={7}>
                  <TextField
                    disabled={this.state.disableToMonth}
                    select
                    variant="outlined"
                    margin="dense"
                    id="month"
                    label={getLanguage(324, "To month")}
                    type="text"
                    fullWidth
                    value={this.state.newToMonthAsString}
                    onChange={(event) => this.setState({ newToMonthAsString: event.target.value })}
                  >
                    {this.state.months.map((row, index) => (
                      <MenuItem value={row} key={index}>{row}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item sm={5}>
                  <TextField
                    select
                    variant="outlined"
                    margin="dense"
                    id="year"
                    label={getLanguage(322, "To year")}
                    type="text"
                    fullWidth
                    value={this.state.newToYear}
                    // onChange={(event) => this.setState({ newToYear: event.target.value })}
                    onChange={(event) => this.newToYear(event.target.value)}

                  >
                    {this.state.years.map((row, index) => (
                      <MenuItem value={row} key={index} disabled={row < this.state.newFromYear}>{row}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
            <TextField
              type="text"
              label={getLanguage(70, "Description")}
              multiline
              rows="5"
              variant="outlined"
              fullWidth
              margin="dense"
              value={this.state.newJobDescription}
              onChange={(event) => this.setState({ newJobDescription: event.target.value })}
            />
          </DialogContent>
          <DialogActions className="dialogActions">
            <Button onClick={() => this.closeWorkDialog()}>{getLanguage(242, "Cancel")}</Button>
            <Button
              onClick={() => this.addNewWorkExperience()}
              color="primary"
              className="button-gradient"
            >
              {getLanguage(319, "Add item")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
