import React, { Component } from "react";
import { Postings } from "./Postings";
import { RouteComponentProps } from "react-router";
interface IProps extends RouteComponentProps { }
interface IState { }
export class Archive extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }
  render() {
    return (
      <Postings archive={true} {...this.props}></Postings>
    );
  }
}
