import React, { Component } from "react";
import {
  TextField,
  Button,
  Dialog,
  Slide,
  Grid,
  DialogContent
} from "@material-ui/core";
import "./CompanySteps.scss";
import { getLanguage } from "../../../../../../Helpers/LanguageHelper";
import { LoginModel } from "../../../../../../Models/LoginModel";
import {
  setUserData,
  getCurrentUser
} from "../../../../../../Helpers/SessionHelper";
import { SavingSnackbar } from "../../../../../Parts/General/SavingSnackbar";
import { ImageHelper, fileToDataUrl } from "../../../../../../Helpers/ImageHelper";
import { CompanyHelper } from "../../../../../../Helpers/CompanyHelper";
import { StorageHelper } from "../../../../../../Helpers/StorageHelper";
import { ImageCrop } from "../../../../../Parts/General/ImageCrop";
import { PageLoader } from "../../../../../Parts/General/PageLoader";
import { CompanyModel } from "../../../../../../Models/CompanyModel";
import { SnackbarManager } from "../../../../../../Helpers/SnackbarManager/SnackbarManager";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} children={<>{props.children}</>} />;
});


interface IProps {
  creatingUser?: boolean;
  saveStep?: ((stepBasicComplete: boolean) => void);
}

interface IState {
  user: LoginModel;
  cropOpen: boolean;
  editing: boolean;
  profilePicture: string;
  newDescription: string;
  showPageLoader: boolean;
  imageToCrop: string;
  coverSelectOpen: boolean;
  thumbnails: { id: string, url: string, FileName: string }[];
  coverImage: string;
  showPageLoaderForImageCrop: boolean;
}

export class CompanyStepProfile extends Component<IProps, IState> {
  imageHelper = new ImageHelper();
  fileInput: any;
  coverUpload: any;
  companyHelper = new CompanyHelper();
  storageHelper = new StorageHelper();

  constructor(props: IProps) {
    super(props);

    this.state = {
      user: new LoginModel({}),
      cropOpen: false,
      editing: false,
      profilePicture: "",
      newDescription: "",
      showPageLoader: false,
      imageToCrop: '',
      coverSelectOpen: false,
      thumbnails: [],
      coverImage: '',
      showPageLoaderForImageCrop: false,

    }

    this.saveImage = this.saveImage.bind(this)
    this.prepareForm = this.prepareForm.bind(this)
    this.fetchImage = this.fetchImage.bind(this)
    this.openCropping = this.openCropping.bind(this)
    this.closeCropping = this.closeCropping.bind(this)
    this.saveCompanyProfile = this.saveCompanyProfile.bind(this)
    this.saveCoverImage = this.saveCoverImage.bind(this)
    this.resetForm = this.resetForm.bind(this)

    this.fetchData();
  }

  fetchData = async () => {
    let defaultCoverImages = await this.companyHelper.fetchCoverImages();
    let thumbnails: { id: string, url: string; FileName: string; }[] = [];
    for (let index = 0; index < defaultCoverImages.length; index++) {
      let imgData = await import('../../../../../../assets/img/DefaultCoverImages/Thumbnails/' + defaultCoverImages[index].FileName);
      thumbnails.push({ id: defaultCoverImages[index].Id, url: imgData.default, FileName: defaultCoverImages[index].FileName });
    }

    let user = getCurrentUser();
    if (!user || !user.Company) {
      SnackbarManager.Instance.addError(getLanguage(710, 'Could not load data'));
      return;
    }

    let userCover: { id: string, url: string; FileName: string; } | undefined = undefined;
    let coverImage: string = '';

    if (user.CoverPictureUrl && user.CoverPictureUrl !== '') {
      coverImage = user.getCoverImageUrl();
    } else {
      let defaultCoverId = user.Company.DefaultCoverImageId;
      if (defaultCoverId) {
        userCover = thumbnails.find(x => x.id === defaultCoverId);
        if (userCover) {
          coverImage = (await import('../../../../../../assets/img/DefaultCoverImages/' + userCover.FileName)).default;
        }
      }
    }
    this.setState({ thumbnails, coverImage });
  }


  async UNSAFE_componentWillMount() {
    await this.prepareForm();
  }


  async prepareForm() {

    await this.setState({ showPageLoader: true });
    let user: LoginModel = getCurrentUser()!;
    if (user && user.Company) {
      await this.setState({
        user: user,
        newDescription: user.Company.Description
      });
    }

    await this.setState({
      profilePicture: user.getProfileImageUrl(),
      showPageLoader: false
    });
  }

  async fetchImage(event: any) {
    const file: File = Array.from(event.target.files)[0] as File;

    if (file && file instanceof Blob) {
      var imageReg = /[\/.](gif|jpg|jpeg|tiff|png)$/i;
      if (imageReg.test(file.type)) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.openCropping(e.target.result);
        };
        reader.readAsDataURL(event.target.files[0]);
      } else {
        SnackbarManager.Instance.addError(getLanguage(487, 'please select an image file'));
      }
    } else {
      SnackbarManager.Instance.addError(getLanguage(487, 'please select an image file'));
    }
    event.target.value = null;
  }

  async openCropping(image: string) {
    let self = this;
    var image2 = new Image();
    image2.src = image;
    image2.onload = function () {
      if (image2.naturalWidth >= 200 || image2.naturalHeight >= 200) {
        self.setState({ cropOpen: true, imageToCrop: image });
      } else {
        SnackbarManager.Instance.addError(getLanguage(488, 'Minimum size for images are 200px width x 200px height'));
        self.setState({
          cropOpen: false,
          imageToCrop: "",
        });
      }
    };
  }

  async saveImage(image: string) {
    await this.setState({ showPageLoaderForImageCrop: true });
    let imageSaved: false | string = await this.companyHelper.saveCompanyLogo(image);
    if (imageSaved) {
      let updatedUser: LoginModel = this.state.user;
      updatedUser.ProfilePictureUrl = imageSaved;
      setUserData(updatedUser);
      this.storageHelper.sendEvent('userUpdated')
      SnackbarManager.Instance.addSuccess(getLanguage(716, 'Image saved'));
      await this.setState({
        user: updatedUser,
        showPageLoaderForImageCrop: false,
        profilePicture: image
      });
    } else {
      SnackbarManager.Instance.addWarning(getLanguage(698, 'Changes not saved'));
    }
  }

  async closeCropping(image: string) {
    if (image != null) {
      await this.saveImage(image);
      await this.setState({ cropOpen: false });
    }
    await this.setState({ cropOpen: false, showPageLoaderForImageCrop: false });
  }

  async saveCompanyProfile() {
    await this.setState({ showPageLoader: true, editing: false })
    let user = this.state.user;

    if (!user || !user.Company) {
      SnackbarManager.Instance.addError(getLanguage(698, 'Could not saved changes'));
      return await this.setState({ showPageLoader: false });
    }


    let updatedCompany: CompanyModel = user.Company;

    if (updatedCompany) {
      updatedCompany.Description = this.state.newDescription;
    }

    let success = await this.companyHelper.updateCompany(updatedCompany);


    if (success) {
      SnackbarManager.Instance.addSuccess(getLanguage(697, 'Changes saved'));
      setUserData(user)
      this.storageHelper.sendEvent('userUpdated')
    } else {
      SnackbarManager.Instance.addError(getLanguage(698, 'Could not save changes'));
    }
    await this.setState({ showPageLoader: false })
    if (this.props.creatingUser && this.props.saveStep) {
      return this.props.saveStep(true)
    }
  }

  async resetForm() {
    this.prepareForm();
    await this.setState({ editing: false });
  }

  async saveCoverImage(event: any) {
    this.setState({ editing: false })
    const file: File = Array.from(event.target.files)[0] as File;

    let image = await fileToDataUrl(file);
    if (image == null) {
      SnackbarManager.Instance.addError(getLanguage(469, 'unknown error'));
      return;
    }
    await this.setState({ coverImage: image });
    let result = await this.companyHelper.saveNewCoverImage(image);

    if (result) {
      let user = getCurrentUser() as LoginModel;
      user.CoverPictureUrl = result;
      user.Company!.DefaultCoverImageId = '';
      setUserData(user);

      SnackbarManager.Instance.addSuccess(getLanguage(716, ' Image saved'));

    } else {
      SnackbarManager.Instance.addError(getLanguage(717, 'Could not save image'));
      this.setState({ coverImage: '' });
    }
  }

  async selectCoverImage(img: { id: string, FileName: string; url: string }) {
    let fulsize = await import('../../../../../../assets/img/DefaultCoverImages/' + img.FileName);
    await this.setState({ coverImage: fulsize.default, coverSelectOpen: false });
    let result = await this.companyHelper.saveDefaultCoverImage(img.id);

    if (result) {
      SnackbarManager.Instance.addSuccess(getLanguage(716, 'image saved'));

      let user = getCurrentUser();
      if (user && user.Company) {
        user.Company.DefaultCoverImageId = img.id;
        user.CoverPictureId = '';
        user.CoverPictureUrl = '';
        setUserData(user);
      }
    } else {
      SnackbarManager.Instance.addError(getLanguage(717, 'could not save image'));
      this.setState({
        coverImage: '',
      });
    }
  }


  creatingUser() {
    if (this.props.creatingUser) {
      return (
        <div>
          <div className="text-right margin-v-32">
            <Button className="button-gradient" onClick={() => this.saveCompanyProfile()}>Done</Button>
          </div>
        </div>
      )
    }
    return (
      <div />
    )
  }





  render() {
    let savingSnackBar: any;

    if (this.state.editing === true) {
      savingSnackBar = (
        <SavingSnackbar
          open={this.state.editing && this.props.creatingUser !== true}
          resetClicked={this.resetForm}
          saveClicked={this.saveCompanyProfile}
        />
      );
    }

    let nextButton = this.creatingUser();

    return (
      <div className="companyStepProfile">
        <TextField
          type="text"
          label={getLanguage(110, "Company description")}
          multiline
          rows="5"
          variant="outlined"
          fullWidth
          margin="dense"
          value={this.state.newDescription}
          onChange={(event) => this.setState({ newDescription: event.target.value, editing: true })}
        />
        <h2>{getLanguage(111, "Your logo")}</h2>
        <div className="text-center">
          <div id="companyProfilePicture">
            <img src={this.state.profilePicture} alt="" />
          </div>
          <div className="inputGroup">
            <Button
              className="default icon inputGroup_item"
              variant="text"
              size="medium"
            >
              <span>
                <i className="fas fa-trash-alt"> </i>
              </span>
            </Button>
            <Button
              className="default inputGroup_item"
              onClick={() => this.fileInput.click()}
            >
              {getLanguage(113, "Edit")}
            </Button>
          </div>
        </div>
        <h2>{getLanguage(112, "Cover image")}</h2>
        <div className="text-center">
          <div id="companyCoverPicture">
            <img src={this.state.coverImage} alt="coverimage" />
          </div>
          <div className="inputGroup">
            <Button
              className="default inputGroup_item"
              variant="text"
              size="medium"
              onClick={() => this.setState({ coverSelectOpen: true })}
            >
              <span className="btn-icon">
                <i className="fas fa-images" />
              </span>
              {getLanguage(115, "Choose from gallery")}
            </Button>
            <Button
              className="default inputGroup_item"
              variant="text"
              onClick={() => this.coverUpload.click()}
              size="medium"
            >
              <span className="btn-icon">
                <i className="fas fa-upload" />
              </span>
              {getLanguage(116, "Upload your own")}
            </Button>
          </div>
        </div>

        <ImageCrop
          open={this.state.cropOpen}
          close={this.closeCropping}
          image={this.state.imageToCrop}
          cropMode={1}
          viewMode={0}
          zoomable={true}
        />
        <input
          accept="image/*"
          style={{ display: "none" }}
          type="file"
          onChange={this.fetchImage.bind(this)}
          ref={fileInput => (this.fileInput = fileInput)}
        />

        <input
          accept="image/*"
          style={{ display: "none" }}
          type="file"
          onChange={this.saveCoverImage.bind(this)}
          ref={coverUpload => (this.coverUpload = coverUpload)}
        />

        {savingSnackBar}
        {this.state.showPageLoaderForImageCrop ? <PageLoader /> : null}

        <Dialog
          open={this.state.coverSelectOpen}
          onClose={() => this.setState({ coverSelectOpen: false })}
          aria-labelledby="form-dialog-title"
          fullScreen
          // @ts-ignore
          TransitionComponent={Transition}
          classes={{ paperFullScreen: "paperFullScreen" }}
        >
          <DialogContent>
            <div className="dialogHeader padding-24">
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item>
                  <h2 className="no-margin">
                    <i className="far fa-images" /> Select cover photo
                </h2>
                </Grid>
                <Grid item>
                  <div className="inputGroup">
                    <Button
                      className=" inputGroup_item"
                      onClick={() => this.setState({ coverSelectOpen: false })}
                    >
                      Cancel
                  </Button>
                    <Button
                      className="success inputGroup_item"
                      onClick={() => this.setState({ coverSelectOpen: false })}

                    >
                      Save
                  </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="imageGallery padding-40">
              <Grid
                container
                spacing={5}
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                {this.state.thumbnails.map(x => {
                  return (
                    <Grid key={x.id} item xs={3}>
                      <div onClick={() => this.selectCoverImage(x)} className="imageGallery_thumbnail">
                        <img src={x.url} alt="thumb" />
                      </div>
                    </Grid>
                  )
                })}
              </Grid>
            </div>
          </DialogContent>
        </Dialog>
        {nextButton}
        {this.state.showPageLoader ? <PageLoader releaseNavbar={true} /> : null}

      </div>
    );
  }
}
