import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { Button } from "@material-ui/core";
import { SideBarOpenSingleton } from "../../../Helpers/SideBarOpenSingleton";

interface IProps {}
interface IState {}
export class MobileSidebarTrigger extends Component<IProps, IState> {
  sidebarToggle() {
    SideBarOpenSingleton.Instance.toggleSideBar()
    let event = new Event("toggleSidebar");
    window.dispatchEvent(event);


  }

  render() {
    return (      
      <div id="menuToggle" className="inline-block" onClick={this.sidebarToggle}>
        <i className="fas fa-stream fa-fw fa-lg" />
      </div> 
    );
  }
}
