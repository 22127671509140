import { Button, Tooltip } from "@material-ui/core";
import { LanguageHelper, getLanguage } from "../../../Helpers/LanguageHelper";
import React, { Component } from "react";




export function translateOrNot(stringLocalized: string | null, stringNonLocalized: string | null, self: any) {
    let translatedString: string | JSX.Element = '';
    if (stringLocalized) {
        if (stringNonLocalized) {
            if (self.state.showTranslation === true) {
                translatedString =
                    <span>
                        <p> {stringLocalized}</p>
                    </span>
            } else {
                translatedString = <span>
                    <p> {stringNonLocalized}</p>
                </span>
            }
        } else {
            translatedString = stringLocalized;
        }
    }
    return translatedString;
}