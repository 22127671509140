import React, { Component } from "react";
import "./Home.scss";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Button
} from "@material-ui/core";
import { CountrySelector } from "../../Parts/General/CountrySelector";
import { RouteComponentProps } from "react-router";
import { RegionSelector } from "../../Parts/General/RegionSelector";
import { SnackbarManager } from "../../../Helpers/SnackbarManager/SnackbarManager";

interface IProps extends RouteComponentProps { }

interface IState {
}

export class Home extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {};
    this.props.history.push({ state: { accessGranted: true } });
    this.checkAccessState = this.checkAccessState.bind(this);
    // this.getMinimumAllowedAppBuildNumber();
  }

  checkAccessState() {
    //FIXME: the snackbar check is simply to ensure there's no infinite loop, get a better way of doing this
    //@ts-ignore
    if (this.props.location.state && !this.props.location.state.accessGranted) {
      SnackbarManager.Instance.addError('You do not have the nessessary access level');
    }
  }

  render() {
    this.checkAccessState();

    return (
      <div className="dashboard">
        <div className="AdminPageHeader">
          <div className="AdminPageTitle">Dashboard</div>
          <div className="AdminPageHeader_options">
            <div className="inputGroup">
              <CountrySelector className="inputGroup_item" />
              <RegionSelector className="inputGroup_item" />

              <Button
                className="white inputGroup_item"
                variant="contained"

              >
                <span className="btn-icon">
                  <i className="fas fa-magic" />
                </span>
                Customize
              </Button>
            </div>
          </div>
        </div>
        <h2>My pins</h2>
        <div className="adminTable">
          <Table className="table">
            <TableHead className="tableHead">
              <TableRow className="tableRow">
                <TableCell>Status</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Needed</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Salary</TableCell>
                <TableCell>Applicants</TableCell>
                <TableCell>Confirmed</TableCell>
                <TableCell className="tableOptions" />
              </TableRow>
            </TableHead>
            <TableBody className="tableBody">
              <TableRow className="tableRow" hover={true}>
                <TableCell className="tableCell" align="center">
                  <i className="far fa-check-circle success" />
                </TableCell>
                <TableCell className="tableCell">Mon. 24/12/2019</TableCell>
                <TableCell className="tableCell">2 waiters</TableCell>
                <TableCell className="tableCell">Ronnie's Diner</TableCell>
                <TableCell className="tableCell">125 kr/t</TableCell>
                <TableCell className="tableCell">10</TableCell>
                <TableCell className="tableCell">34</TableCell>
                <TableCell className="tableCell tableOptions">
                  <i className="fas fa-ellipsis-v" />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>

        <Grid
          container
          spacing={2}
          justify="space-between"
          alignItems="stretch"
        >
          <Grid item xs={3}>
            <div className="paper padding-24 radius-6">
              <h2>Total chabbers</h2>
              <div className="cardNumber">11.268</div>
              <div className="cardNumber_secondary">+121 this week</div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="paper padding-24 radius-6">
              <h2>Total companies</h2>
              <div className="cardNumber">507</div>
              <div className="cardNumber_secondary">+15 this week</div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="paper padding-24 radius-6">
              <h2>Total jobs</h2>
              <div className="cardNumber">7963</div>
              <div className="cardNumber_secondary">+183 this week</div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="paper padding-24 radius-6">
              <h2>Chabbers on duty</h2>
              <div className="cardNumber">5</div>
              <div className="cardNumber_secondary">34 today</div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}
