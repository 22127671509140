import React, { Component } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import { getLanguage } from '../../../../../../Helpers/LanguageHelper';
import { ICompanyFeedback } from '../PostingHelper';
import { RequestModel } from '../../../../../../Models/RequestModel';
import { FreelancerModel } from '../../../../../../Models/FreelancerModel';

interface IProps {
  open: boolean;
  feedbackObject: ICompanyFeedback | null;
  handleClose: () => void;
  submit: () => void;
}
interface IState { }

export class NoShowDialogue extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  getMessage() {
    let request: RequestModel | undefined = undefined;
    if (this.props.feedbackObject && this.props.feedbackObject.request) {
      request = this.props.feedbackObject.request;
    }
    if (!request) return 'error';

    let freelancer = request.Freelancer || request.FreelancerProfile as FreelancerModel;
    let name = freelancer.Login ? freelancer.Login.Firstnames + " " + freelancer.Login.Lastname : "";

    return getLanguage(683, 'noshow message').replace('{{name}}', name);
  }




  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        className="dialog"
      >

        <DialogTitle id="form-dialog-title">{getLanguage(579, "Register no-show?")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.getMessage()}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialogActions">
          <Button onClick={this.props.handleClose}>{getLanguage(242, "Cancel")}</Button>
          <Button onClick={this.props.submit} color="primary">
            {getLanguage(580, "Confirm no-show")}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}