import React, { Component, ReactNodeArray, ReactNode } from 'react';
import { Snackbar, Button, Slide, SnackbarContent, CircularProgress } from '@material-ui/core';
import { getLanguage } from '../../../Helpers/LanguageHelper';


interface IProps {
  open: boolean;
  saveClicked: () => void;
  resetClicked?: () => void;
  message?: string;
  saveButtonText?: string;

}

interface IState {
  transition: any;
  buttonsDisabled: boolean;
  saveButtonLoading: boolean;
  resetButtonLoading: boolean;
}

export class SavingSnackbar extends Component<IProps, IState> {

  constructor(props: any) {
    super(props);
    this.saveClicked = this.saveClicked.bind(this);
    this.resetClicked = this.resetClicked.bind(this);
    this.state = {
      transition: this.TransitionRight,
      buttonsDisabled: false,
      saveButtonLoading: false,
      resetButtonLoading: false,

    }
  }




  TransitionRight(props: any) {
    return <Slide {...props} direction="left" />;

  }
  saveClicked() {
    this.setState({ buttonsDisabled: true, saveButtonLoading: true })
    this.props.saveClicked();
  }

  resetClicked() {
    this.setState({ buttonsDisabled: true, resetButtonLoading: true })
    if (this.props.resetClicked) {
      this.props.resetClicked();
    }
  }

  componentDidUpdate() {
    if (!this.props.open && this.state.buttonsDisabled) {
      this.setState({ buttonsDisabled: false, resetButtonLoading: false, saveButtonLoading: false })
    }
  }


  render() {



    let buttons: JSX.Element[] = [<Button disabled={this.state.buttonsDisabled} className="success inputGroup_item" key='cancel' variant="contained" size="medium" onClick={this.saveClicked}>
      {/* Save */}
      {this.props.saveButtonText ? this.props.saveButtonText : getLanguage(663, "Save")}
      {this.state.saveButtonLoading && <CircularProgress size={20} className="buttonLoader" />}
    </Button>];

    if (this.props.resetClicked) {
      buttons.push(
        <Button disabled={this.state.buttonsDisabled} className="default icon inputGroup_item" key='reset' variant="contained" size="medium" onClick={this.resetClicked}>
          {this.state.resetButtonLoading && <CircularProgress size={20} className="buttonLoader" />}
          <span><i className="fas fa-undo-alt"> </i></span>

        </Button>);
    }

    let btnsDiv = <div className="inputGroup">{buttons}</div>


    return (
      <Snackbar

        key='0'
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={this.props.open}
        // autoHideDuration={6000}
        TransitionComponent={this.state.transition}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        className="snackbar snackbar_save"

      >
        <SnackbarContent classes={{ root: 'snackbarContent', action: 'snackbarActions', message: 'snackbarMessage' }} message={this.props.message} action={btnsDiv}></SnackbarContent>
      </Snackbar>);
  }
}

