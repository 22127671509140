import React, { Component } from "react";
import UnsubImg from "../../../assets/img/illustrations/unsubscribe.png";
import { getLanguage } from "../../../Helpers/LanguageHelper";
import { Button } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { getCurrentUser } from "../../../Helpers/SessionHelper";
import moment from "moment";
import { UserHelper } from "../../../Helpers/UserHelper";

interface IProps { }
interface IState {
}

export class UnsubscribeViaMail extends Component<IProps, IState> {
    userHelper = new UserHelper();

    componentDidMount() {
        this.submitChanges();
    }

    submitChanges = async () => {
        //@ts-ignore
        let response = await this.userHelper.unsubViaMail(this.props.location.search.replace("?id=", ""), { DisableNotificationsUntil: moment().add(24, 'M').toDate(), });

        console.log(response);
    }

    render() {
        return (
            <div className="emptyState_wrapper fullView">
                <div className="emptyState_content">
                    <img className="noDrag" src={UnsubImg} alt="" />
                    <h1>{getLanguage(844, "You have unsubscribed")}</h1>
                    <p className="infoParagraph">
                        {getLanguage(845, "Log in to your profile to manage all your notifications settings.")}
                    </p>
                    <br />
                    <NavLink to="/login">
                        <Button>{getLanguage(611, "Go to login")}</Button>
                    </NavLink>
                </div>
            </div>
        )
    }
}