import React, { Component } from "react";
import { TextField, MenuItem } from "@material-ui/core";
//@ts-ignore
import { isEmail, isLength } from "validator";
import { CountryModel } from "../../../../Models/CountryModel";
import { getLanguage } from "../../../../Helpers/LanguageHelper";
// @ts-ignore
import PhoneInput from 'react-phone-input-2'
import { getPhoneCountryCode } from '../../../../Helpers/GenericHelper';
import { StorageHelper } from '../../../../Helpers/StorageHelper';
import { CountryHelper } from '../../../../Helpers/CountryHelper';
import { KnownLanguageModel } from '../../../../Models/KnownLanguageModel';
import { LanguagesAvailableByCountryModel } from '../../../../Models/LanguagesAvailableByCountryModel';
import { englishLanguageId } from '../../../../Helpers/Cfg';
import { PhoneField } from "../../../Parts/General/PhoneField";

interface IProps {
  possibleCountries: CountryModel[];
  changeField: (fieldname: string, value: any) => void;
  formsData: any; //FIXME: This should not be type any
}
interface IState {
  errors: {
    email: boolean;
    password: boolean;
    companyName: boolean;
    [key: string]: boolean;
  };
  countries: CountryModel[];
  allLanguages: KnownLanguageModel[];
  languagesAvailableByCountry: LanguagesAvailableByCountryModel[];
  availableLanguages: KnownLanguageModel[];
}
export class RegisterPrivate extends Component<IProps, IState> {
  storageHelper = new StorageHelper();
  countryHelper = new CountryHelper();
  phoneRef = React.createRef<PhoneInput>();

  constructor(props: IProps) {
    super(props);
    let localData = this.storageHelper.getFacadeLocalization();
    let allLanguages: KnownLanguageModel[] = [];
    let languagesAvailableByCountry: LanguagesAvailableByCountryModel[] = [];
    let countries: CountryModel[] = [];
    if (localData != null) {
      allLanguages = localData.knownLanguages;
      languagesAvailableByCountry = localData.languagesAvailableByCountry;
      countries = localData.countries;
    }

    this.state = {
      errors: {
        email: false,
        password: false,
        companyName: false,
        phone: false
      },
      countries: countries,
      allLanguages,
      availableLanguages: [],
      languagesAvailableByCountry
    };
    this.setFieldErrors = this.setFieldErrors.bind(this);
  }

  async setFieldErrors(name: string, state: boolean) {
    let errs = this.state.errors;
    errs[name] = state;
    await this.setState({ errors: errs });
  }

  public getRawPhoneNumber() {
    return this.phoneRef!.current!.getRawPhoneInput();
  }

  async changeCountry(countryId: string) {
    // Get the languages for the current country and update them in the dropdown. Also get language strings from API

    let availableLanguageIds = this.state.languagesAvailableByCountry
      .filter(x => x.CountryId === countryId)
      .map(x => x.LanguageId);
    availableLanguageIds.push(englishLanguageId);
    let availableLanguages = this.state.allLanguages.filter(x =>
      availableLanguageIds.find(y => y === x.Id)
    );

    await this.setState({ availableLanguages });
    this.props.changeField("selectedCountry", countryId);
  }

  render() {
    return (
      <div>
        <div>
          <h1>{getLanguage(576, "Register as private")}</h1>
        </div>

        <TextField
          id="outlined-country"
          select
          label={getLanguage(80, "country")}
          fullWidth
          margin="normal"
          variant="outlined"
          value={this.props.formsData.selectedCountry || ""}
          onChange={e => this.changeCountry(e.target.value)}
        >
          {this.props.possibleCountries.map(x => (
            <MenuItem value={x.Id} key={x.Id}>
              {x.LanguageCode
                ? getLanguage(x.LanguageCode, "Country name")
                : ""}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          id="outlined-language"
          select
          label={getLanguage(81, "Primary language")}
          fullWidth
          margin="normal"
          variant="outlined"
          error={this.state.errors.languageError}
          value={this.props.formsData.PrimaryLanguage}
          onChange={e =>
            this.props.changeField("PrimaryLanguage", e.target.value)
          }
        >
          {this.state.availableLanguages.map((row, index) => (
            <MenuItem value={row.Id} key={index}>
              {row.LanguageCode
                ? getLanguage(row.LanguageCode, "Language name")
                : ""}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          type="text"
          label={getLanguage(71, "First name")}
          variant="outlined"
          fullWidth
          margin="normal"
          error={this.state.errors.firstNames}
          value={this.props.formsData.firstNames || ""}
          onBlur={e =>
            this.setFieldErrors("firstNames", !isLength(e.target.value, { min: 1, max: 128 }))
          }
          onFocus={() => this.setFieldErrors("firstNames", false)}
          onChange={e => this.props.changeField("firstNames", e.target.value)}
        />

        <TextField
          type="text"
          label={getLanguage(72, "Last name")}
          variant="outlined"
          error={this.state.errors.lastName}
          onBlur={e =>
            this.setFieldErrors("lastName", !isLength(e.target.value, { min: 1, max: 128 }))
          }
          onFocus={() => this.setFieldErrors("lastName", false)}
          fullWidth
          margin="normal"
          value={this.props.formsData.lastName || ""}
          onChange={e => this.props.changeField("lastName", e.target.value)}
        />

        <div className="phoneInput">
          <label id="phoneInputLabel">{getLanguage(75, "Mobile number")}</label>
          <PhoneField
            ref={this.phoneRef}
            countryCode={getPhoneCountryCode(
              this.state.countries,
              this.props.formsData.selectedCountry,
              this.storageHelper
            )}
            value={this.props.formsData.phone}
            onChange={val => this.props.changeField("phone", val)}
            onFocus={val => this.props.changeField("phone", val)}
          />
        </div>

        <TextField
          type="email"
          label={getLanguage(73, "Email")}
          variant="outlined"
          error={this.state.errors.email}
          onBlur={e => this.setFieldErrors("email", !isEmail(e.target.value))}
          onFocus={() => this.setFieldErrors("email", false)}
          fullWidth
          margin="normal"
          value={this.props.formsData.email || ""}
          onChange={e => this.props.changeField("email", e.target.value)}
        />
        <TextField
          type="password"
          label={getLanguage(170, "Password")}
          variant="outlined"
          fullWidth
          margin="normal"
          error={this.state.errors.password}
          onBlur={e =>
            this.setFieldErrors("password", !isLength(e.target.value, { min: 6, max: 32 }))
          }
          onFocus={() => this.setFieldErrors("password", false)}
          value={this.props.formsData.password || ""}
          onChange={e => this.props.changeField("password", e.target.value)}
        />
      </div>
    );
  }
}
