import React, { useState, useEffect } from 'react';
import Tabs from "../../../Parts/General/Tabs";
import TabContent from "../../../Parts/General/TabContent";
import { Grid, Checkbox, FormControlLabel, Button } from '@material-ui/core';
import './Registration.scss'
import { CourseHelper } from '../../../../Helpers/CourseHelper';
import { CourseRegistrantHelper } from '../../../../Helpers/CourseRegistrantHelper';
import qs from 'query-string';
import { RouteComponentProps } from 'react-router';
import { CourseModel } from '../../../../Models/CourseModel';
import { CourseRegistrationModel } from '../../../../Models/CourseRegistrationModel';
import { CourseAttendanceModel } from '../../../../Models/CourseAttendanceModel';
import { configureCloudinaryUrl } from '../../../../Helpers/ImageHelper';

interface IProps extends RouteComponentProps { }

interface IParticipation {
  Id: string;
  state: participationState
}

type participationState = 'unchecked' | 'registering' | 'unregistering' | 'failedregister' | 'failedunregister' | 'checked';

export function CourseAttendance(props: IProps) {
  const courseHelper = new CourseHelper();
  let courseRegistrantHelper = new CourseRegistrantHelper();
  const [course, setCourse] = useState<CourseModel | null>(null);
  const [pageState, setPageState] = useState<'loading' | 'ready' | 'empty'>('loading');
  const [selectedTab, setSelectedTab] = useState(0);
  const [participation, setParticipation] = useState<IParticipation[][]>([]);
  const [changingAll, setChangingAll] = useState(false);



  useEffect(() => {
    async function preparePage() {
      let id = qs.parse(props.location.search).id;
      if (!id) {
        setPageState('empty');
        return;
      }
      if (Array.isArray(id)) id = id[0];

      let localCourse = await courseHelper.getCourseById(id)

      if (localCourse) {
        setPageState('ready');

        localCourse = new CourseModel(localCourse);
        localCourse.Registrations = localCourse.Registrations.sort((a, b) => {
          return a.Freelancer!.Login!.Firstnames + a.Freelancer!.Login!.Lastname
            < b.Freelancer!.Login!.Firstnames + b.Freelancer!.Login!.Lastname ?
            -1 : 1
        })

        setCourse(localCourse);
        let participantArr = [];
        for (let index = 0; index < localCourse.CourseDates.length; index++) {
          let arr: IParticipation[] = localCourse.Registrations.map(x => {
            let state: participationState = 'unchecked';

            if (x.CourseAttendances.findIndex(y => localCourse != null && y.CourseDateId === localCourse.CourseDates[index].Id) !== -1) {
              state = 'checked'
            }
            let participant: IParticipation = {
              Id: x.Id,
              state
            }
            return participant;
          });


          participantArr.push(arr);
        }

        //Add last row for graduations
        let arr: IParticipation[] = localCourse.Registrations.map(x => {
          let state: participationState = x.Graduated ? 'checked' : 'unchecked';

          let participant: IParticipation = {
            Id: x.Id,
            state
          }
          return participant;
        })
        participantArr.push(arr);
        setParticipation(participantArr);
      } else {
        setPageState('empty');
      }
    };
    preparePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  function changeApplicantState(tabIndex: number, registrant: CourseRegistrationModel, state: participationState) {
    let arr = participation.map(x => x);
    let index = arr[tabIndex].findIndex(x => x.Id === registrant.Id);
    arr[tabIndex][index].state = state;
    setParticipation(arr);
  }

  async function changeAttendance(tabIndex: number, registrant: CourseRegistrationModel, action: 'register' | 'unregister') {
    if (!course) {
      let fail: participationState = action === 'register' ? 'failedregister' : 'failedunregister';
      changeApplicantState(selectedTab, registrant, fail);
      return;
    }
    let saving: participationState = action === 'register' ? 'registering' : 'unregistering';
    changeApplicantState(selectedTab, registrant, saving);
    if (action === 'register') {
      await registerAttendance(tabIndex, registrant, course, course.CourseDates[tabIndex].Id);
    } else {
      await unregisterAttendance(tabIndex, registrant, course, course.CourseDates[tabIndex].Id);
    }
  }

  async function changeGraduation(registrant: CourseRegistrationModel, action: 'graduate' | 'unGraduate') {
    if (!course) {
      let fail: participationState = action === 'graduate' ? 'failedregister' : 'failedunregister';
      changeApplicantState(selectedTab, registrant, fail);
      return;
    }
    let saving: participationState = action === 'graduate' ? 'registering' : 'unregistering';
    changeApplicantState(selectedTab, registrant, saving);
    let tabIndex = selectedTab;

    if (action === 'graduate') {
      await registerGraduation(tabIndex, registrant, course);
    } else {
      await unregisterGraduation(tabIndex, registrant, course);
    }
  }

  async function registerAttendance(tabIndex: number, registrant: CourseRegistrationModel, course: CourseModel, courseDateId: string) {
    let attendance = await courseRegistrantHelper.registerAttendance(registrant.Id, course.Id, courseDateId);
    if (!attendance) {
      changeApplicantState(tabIndex, registrant, 'failedregister');
      return;
    }
    changeApplicantState(selectedTab, registrant, 'checked');


    let newCourse = new CourseModel(course);
    let index = newCourse.Registrations.findIndex(x => x.Id === registrant.Id);
    newCourse.Registrations[index].CourseAttendances.push(new CourseAttendanceModel(attendance));
    setCourse(newCourse);
  }

  async function unregisterAttendance(tabIndex: number, registrant: CourseRegistrationModel, course: CourseModel, courseDateId: string) {
    let attendance = registrant.CourseAttendances.find(x => x.CourseDateId === courseDateId);
    if (attendance == null) {
      changeApplicantState(tabIndex, registrant, 'failedunregister');
      return;
    }

    let result = await courseRegistrantHelper.unregisterAttendance(registrant.Id, course.Id, courseDateId, attendance.Id);

    if (result) {
      changeApplicantState(tabIndex, registrant, 'unchecked');

      let newCourse = new CourseModel(course);
      let index = newCourse.Registrations.findIndex(x => x.Id === registrant.Id);
      let attendanceIndex = newCourse.Registrations[index].CourseAttendances.findIndex(x => x.CourseDateId === courseDateId);
      newCourse.Registrations[index].CourseAttendances.splice(attendanceIndex, 1);
      setCourse(newCourse);
    } else {
      changeApplicantState(tabIndex, registrant, 'failedunregister');
    }
  }


  async function registerGraduation(tabIndex: number, registrant: CourseRegistrationModel, course: CourseModel) {
    let success = await courseRegistrantHelper.registerGraduation(registrant.Id, course.Id);
    if (success) {
      registrant.Graduated = true;

      changeApplicantState(tabIndex, registrant, 'checked');
    } else {
      changeApplicantState(tabIndex, registrant, 'failedregister');
    }
  }

  async function unregisterGraduation(tabIndex: number, registrant: CourseRegistrationModel, course: CourseModel) {
    let success = await courseRegistrantHelper.unregisterGraduation(registrant.Id, course.Id);
    if (success) {
      changeApplicantState(tabIndex, registrant, 'unchecked');
    } else {
      changeApplicantState(tabIndex, registrant, 'failedunregister');
    }
  }



  async function handleAllClicked(tabIndex: number, checkedState: 'checked' | 'unchecked' | 'loading') {
    if (!course) return;
    setChangingAll(true);
    let allActions: Promise<void>[] = [];
    for (const registrant of course.Registrations) {
      let registrationData = participation[selectedTab].find(x => x.Id === registrant.Id);
      if (!registrationData) return;
      if (checkedState === 'unchecked' && registrationData.state === 'unchecked') {
        allActions.push(changeAttendance(tabIndex, registrant, 'register'));
      } else if (checkedState === 'checked' && registrationData.state === 'checked') {
        allActions.push(changeAttendance(tabIndex, registrant, 'unregister'));
      }
    }
    await Promise.all(allActions);

    setChangingAll(false);
  }

  function renderBody() {
    switch (pageState) {
      case 'loading':
        return renderLoading();
      case 'ready':
        return renderForm();
      case 'empty':
        return renderEmpty();
    }
  }

  function renderLoading() {
    return <h1>Loading</h1>
  }

  function renderForm() {
    if (!course) {
      return renderEmpty();
    }
    return (
      <div className="courseAttenance margin-top-24">
        <div className="paper radius-12 padding-24 maxW-800 text-center">
          <h1 className="text-left">{course.Title} | {course.CourseDate} | {course.Registrations.length} participants</h1>
          <Tabs changeValue={index => setSelectedTab(index)} value={selectedTab}>
            {course.CourseDates.map(date => {
              return <TabContent key={date.Id} title={date.Date}>
                < div className="courseAttendance_content" >
                  <div className="courseAttendance_selectAll">
                    <Grid container justify="flex-end">
                      <Grid item>

                        {renderSelectAll(selectedTab)}

                      </Grid>
                    </Grid>
                  </div>
                  {renderParticipants('registration')}
                </div>
              </TabContent>
            })}
            <TabContent key="Graduation" title="Graduation">
              {renderGraduationBody()}
            </TabContent>
          </Tabs>
        </div>
      </div>
    )
  }

  function renderGraduationBody() {
    return renderParticipants('graduation');
  }

  function renderParticipants(tabType: 'registration' | 'graduation') {
    if (!course) return null;
    return course.Registrations.map(registrant => {
      if (!registrant.Freelancer || !registrant.Freelancer.Login) return null;
      if (participation[selectedTab] == null) {
        participation[selectedTab] = [];
      }
      let checkbox;
      switch (tabType) {
        case 'registration':
          checkbox = renderCheckbox(registrant);
          break;
        case 'graduation':
          checkbox = renderGraduationButton(registrant);
          break;
      }

      return (
        <div key={registrant.Id} className="courseAttendance_item">
          <Grid container justify="space-between">
            <Grid item>
              <div className="courseAttendance_image">
                <img src={configureCloudinaryUrl(registrant.Freelancer.Login.ProfilePictureUrl, ['w_300'])} alt="freelancer" />
              </div>
              <div className="inline-block text-left">
                <h2 className="margin-0">
                  {registrant.Freelancer.Login.Firstnames + ' ' + registrant.Freelancer.Login.Lastname}
                </h2>
                <p className="margin-0">{registrant.Freelancer.MobilePhone} | {registrant.Freelancer.Login.Email}</p>
              </div>
            </Grid>
            <Grid item>
              {checkbox}
            </Grid>
          </Grid>
        </div>
      )
    })
  }

  function renderCheckbox(registrant: CourseRegistrationModel) {
    let registrationData = participation[selectedTab].find(x => x.Id === registrant.Id);
    if (registrationData == null) return <p>error</p>

    switch (registrationData.state) {
      case 'unchecked':
      case 'checked':
        return (
          <Checkbox
            color="primary"
            checked={registrationData.state === 'checked'}
            onChange={(e) => {
              let tabIndex = selectedTab;
              if (registrationData!.state === 'checked') {
                changeAttendance(tabIndex, registrant, 'unregister');
              } else {
                changeAttendance(tabIndex, registrant, 'register');
              }
            }}
          />
        )
      case 'registering':
      case 'unregistering':
        return <i className='fas fa-circle-notch fa-spin warning'></i>
      case 'failedregister':
      case 'failedunregister':
        return (
          <Button
            onClick={() => {
              if (!registrationData) return <p>error</p>;
              let tabIndex = selectedTab;
              if (registrationData.state === 'failedregister') {
                changeAttendance(tabIndex, registrant, 'register');
              } else if (registrationData.state === 'failedunregister') {
                changeAttendance(tabIndex, registrant, 'unregister');
              }
            }}
          >
            <i className="fas fa-exclamation-triangle warning"></i>
            saving failed, try again..
          </Button>
        )
    }
  }

  function renderGraduationButton(registrant: CourseRegistrationModel) {
    let registrationData = participation[selectedTab].find(x => x.Id === registrant.Id);
    if (registrationData == null) return <p>error</p>

    switch (registrationData.state) {
      case 'unchecked':
      case 'checked':
        return (
          <Button
            onClick={() => {
              if (registrationData?.state === 'checked') {
                changeGraduation(registrant, 'unGraduate');
              } else {
                changeGraduation(registrant, 'graduate');
              }
            }}
          >
            {registrationData.state === 'checked' ?
              'Completed' :
              'Mark as completed'
            }
          </Button>
        )
      case 'registering':
      case 'unregistering':
        return <i className='fas fa-circle-notch fa-spin warning'></i>
      case 'failedregister':
      case 'failedunregister':
        return (
          <Button
            onClick={() => {
              if (!registrationData) return <p>error</p>;
              if (registrationData.state === 'failedregister') {
                changeGraduation(registrant, 'graduate');
              } else if (registrationData.state === 'failedunregister') {
                changeGraduation(registrant, 'unGraduate');
              }
            }}
          >
            <i className="fas fa-exclamation-triangle warning"></i>
            saving failed, try again..
          </Button>
        )
    }
  }

  function renderSelectAll(tabIndex: number) {
    let checkedState: 'unchecked' | 'loading' | 'checked' = 'unchecked';

    if (participation[tabIndex] == null) {
      checkedState = 'unchecked';
    } else if (participation[tabIndex].findIndex(x => x.state !== 'checked') === -1) {
      checkedState = 'checked';
    } else if (changingAll && participation[tabIndex].findIndex(x => x.state === 'registering' || x.state === 'unregistering') !== -1) {
      checkedState = 'loading';
    }



    switch (checkedState) {
      case 'unchecked':
      case 'checked':
        return (
          <FormControlLabel
            value="start"
            control={<Checkbox color="primary" />}
            label="Select all"
            labelPlacement="start"
            checked={checkedState === 'checked'}
            onClick={() => {
              handleAllClicked(tabIndex, checkedState);
            }}
          />
        )
      // if it has been clicked and jobs are still loading it's a spinner
      case 'loading':
        return <i className='fas fa-circle-notch fa-spin warning'></i>
    }
  }

  function renderEmpty() {
    return <h1>Empty</h1>
  }
  return renderBody();
}