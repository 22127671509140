import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Grid, TextField, MenuItem } from '@material-ui/core';
import { CourseHelper } from '../../../../Helpers/CourseHelper';
import { CourseOrganizerHelper } from '../../../../Helpers/CourseOrganizerHelper';
import qs from 'query-string';
import { CoursePartnerModel } from '../../../../Models/CoursePartnerModel';
import { SavingSnackbar } from '../../../Parts/General/SavingSnackbar';
import { LimitedTextArea } from '../../../Parts/General/LimitedTextArea';
import { SnackbarManager } from '../../../../Helpers/SnackbarManager/SnackbarManager';
import { StorageHelper } from '../../../../Helpers/StorageHelper';
import { CountryModel } from '../../../../Models/CountryModel';
import { isEmail } from "validator";

interface IProps extends RouteComponentProps { }

export function Organizer(props: IProps) {
  let courseHelper = new CourseHelper();
  let courseOrganizerHelper = new CourseOrganizerHelper();
  let storageHelper = new StorageHelper();

  const [existingOrganizer, setExistingOrganizer] = useState<CoursePartnerModel | null>(null);
  const [editedOrganizer, setEditedOrganizer] = useState(new CoursePartnerModel({}));
  const [isDirty, setIsDirty] = useState(false);
  const [countries, setCountries] = useState<CountryModel[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: { active: boolean } }>({})

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.search]);

  async function fetchData() {
    setCountries(storageHelper.getCountriesFromLocal());

    let id = qs.parse(props.location.search).id;
    if (typeof id !== 'string') return

    let organizer = await courseOrganizerHelper.getOrganizerById(id);
    setExistingOrganizer(organizer);
    setEditedOrganizer(new CoursePartnerModel(organizer));
  }

  function resetForm() {
    setEditedOrganizer(new CoursePartnerModel(existingOrganizer));
    setIsDirty(false);
  }

  async function saveOrganizer() {
    let allFieldValues: { name: string, value: string | number }[] = [];
    for (const key in editedOrganizer) {
      if (editedOrganizer.hasOwnProperty(key) && key !== 'Id' && key !== 'CourseMetaId' && key !== 'CourseMeta') {
        allFieldValues.push({ name: key, value: editedOrganizer[key] })
      }
    }
    let hasErrors = validateFields(allFieldValues);

    if (hasErrors) {
      SnackbarManager.Instance.addWarning('Fix missing fields');
      setIsDirty(false);
      return;
    }

    let response = null;
    if (existingOrganizer == null) {
      response = await courseOrganizerHelper.saveOrganizer(editedOrganizer);
    } else {
      response = await courseOrganizerHelper.updateOrganizer(editedOrganizer);
    }

    if (response) {
      setIsDirty(false);
      SnackbarManager.Instance.addSuccess('Organizer created');
      props.history.push('/admin/courses', { tab: 1, Id: response });
    } else {
      SnackbarManager.Instance.addError('Save failed');
    }
  }

  function changeValue(name: string, value: string, maxLength?: number | undefined) {
    if (maxLength && value.length > maxLength) return;
    setEditedOrganizer(prevState => { return { ...prevState, [name]: value } });
    setIsDirty(true);
  }

  function validateField(name: string, value: string | number, clearField?: true) {
    validateFields([{ name, value }], clearField);
  }

  function validateFields(arr: { name: string, value: string | number }[], clearField?: true) {
    let localErrors = Object.create(errors);
    let hasErrors = false;
    for (const { name, value } of arr) {
      switch (name) {
        case 'Email':
          if (clearField) {
            localErrors[name] = { active: false };
          }
          else if (!value || typeof value !== 'string' || !isEmail(value)) {
            localErrors[name] = { active: true };
            hasErrors = true;

          } else {
            localErrors[name] = { active: false };
          }
          break;
        default:
          if (clearField) {
            localErrors[name] = { active: false };
          }
          else if (!value && value !== 0) {
            localErrors[name] = { active: true };
            hasErrors = true;
          } else {
            localErrors[name] = { active: false };
          }
          break;
      }
    }

    setErrors(localErrors);
    return hasErrors;
  }

  return (
    <div>
      <SavingSnackbar open={isDirty} resetClicked={resetForm} saveClicked={saveOrganizer} />
      <div className="AdminPageHeader">
        <div className="AdminPageTitle">
          {/* Title */}
          {existingOrganizer != null ? existingOrganizer.Name : 'New Organizer'}
        </div>
        <div className="AdminPageHeader_options">
          <input
            className="inputGroup_item"
            type="text"
            placeholder="Search..."
          />
        </div>
      </div>
      <div className="paper padding-24 border-12">
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              type="text"
              label="Organizer name"
              variant="outlined"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true
              }}
              value={editedOrganizer.Name}
              onChange={e => changeValue('Name', e.target.value)}
              onBlur={e => validateField('Name', e.target.value)}
              onFocus={_ => validateField('Name', '', true)}
              error={errors['Name'] && errors['Name'].active}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              type="number"
              label="Organizer cut (%)"
              variant="outlined"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true
              }}
              value={editedOrganizer.OrganizerCut}
              onChange={e => changeValue('OrganizerCut', e.target.value)}
              onBlur={e => validateField('OrganizerCut', e.target.value)}
              onFocus={_ => validateField('OrganizerCut', '', true)}
              error={errors['OrganizerCut'] && errors['OrganizerCut'].active}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              type="number"
              label="Default course price incl. VAT"
              variant="outlined"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true
              }}
              value={editedOrganizer.DefaultCoursePriceInclVat}
              onChange={e => changeValue('DefaultCoursePriceInclVat', e.target.value)}
              onBlur={e => validateField('DefaultCoursePriceInclVat', e.target.value)}
              onFocus={_ => validateField('DefaultCoursePriceInclVat', '', true)}
              error={errors['DefaultCoursePriceInclVat'] && errors['DefaultCoursePriceInclVat'].active}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              type="number"
              label="Hours as pay"
              variant="outlined"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true
              }}
              value={editedOrganizer.HoursAsPay}
              onChange={e => changeValue('HoursAsPay', e.target.value)}
              onBlur={e => validateField('HoursAsPay', e.target.value)}
              onFocus={_ => validateField('HoursAsPay', '', true)}
              error={errors['HoursAsPay'] && errors['HoursAsPay'].active}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Country"
              variant="outlined"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true
              }}
              value={editedOrganizer.CountryId}
              onChange={e => changeValue('CountryId', e.target.value)}
              select
              onBlur={e => validateField('CountryId', e.target.value)}
              onFocus={_ => validateField('CountryId', '', true)}
              error={errors['CountryId'] && errors['CountryId'].active}
            >
              {countries.map(x =>
                <MenuItem key={x.Id} value={x.Id} >{x.CountryName}</MenuItem>
              )
              }
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              type="Email"
              label="Email"
              variant="outlined"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true
              }}
              value={editedOrganizer.Email}
              onChange={e => changeValue('Email', e.target.value)}
              onBlur={e => validateField('Email', e.target.value)}
              onFocus={_ => validateField('Email', '', true)}
              error={errors['Email'] && errors['Email'].active}
            />
          </Grid>
        </Grid>



        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LimitedTextArea
              label="Default course terms"
              value={editedOrganizer.DefaultCourseTerms}
              rows="4"
              limit={500}
              onChange={e => changeValue('DefaultCourseTerms', e.target.value, 500)}
              onBlur={e => validateField('DefaultCourseTerms', e.target.value)}
              onFocus={e => validateField('DefaultCourseTerms', '', true)}
              error={errors['DefaultCourseTerms'] && errors['DefaultCourseTerms'].active}
            />
          </Grid>
        </Grid>
      </div>
    </div>)
}