import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { Button } from "@material-ui/core";
import { getCurrentUser } from "../../../../Helpers/SessionHelper";
import { VerificationDocumentsStatus } from "../../../../Models/VerificationDocumentStatus";
import { UserHelper } from "../../../../Helpers/UserHelper";
import { getLanguage } from "../../../../Helpers/LanguageHelper";

interface IProps extends RouteComponentProps { }

interface IState {
  verificationDocumentStatus: VerificationDocumentsStatus | null;
}

export class VerificationStatus extends Component<IProps, IState> {
  userHelper = new UserHelper();
  constructor(props: IProps) {
    super(props);

    this.state = {
      verificationDocumentStatus: null
    };

    this.workPermit = this.workPermit.bind(this);
    this.pictureId = this.pictureId.bind(this);
    this.nationalId = this.nationalId.bind(this);
    this.preparePage = this.preparePage.bind(this)
  }

  async componentDidMount() {
    await this.preparePage();

  }

  async preparePage() {
    let user = getCurrentUser();

    if (user && user.Freelancer) {
      let verificationDocumentStatus:
        | VerificationDocumentsStatus
        | boolean = await this.userHelper.getVerificationDocumentStatus(
          user.Freelancer.CountryId,
          user.Id,
          user.Freelancer.Id
        );
      if (
        verificationDocumentStatus &&
        typeof verificationDocumentStatus != "boolean"
      ) {
        this.setState({ verificationDocumentStatus });
      }
    }
  }

  workPermit() {
    if (this.state.verificationDocumentStatus) {
      switch (this.state.verificationDocumentStatus.WorkPermit) {
        case 1:
          return (
            <div className="margin-top-8">
              {getLanguage(605, "Work permit not uploaded yet")}
              <div className="margin-left-32 inline-block">
                <Button
                  size="small"
                  variant="text"
                  onClick={() =>
                    this.props.history.push("/freelancer/settings/verification")
                  }
                >
                  {getLanguage(606, "Fix it")}
                </Button>
              </div>
            </div>
          );
        case 2:
          return (
            <div className="margin-top-8">
              <span>
                <i className="fas fa-spinner fa-fw fa-spin"></i> {getLanguage(592, "Work permit uploaded and pending")}
              </span>
            </div>
          );
        case 3:
          return (
            <div className="margin-top-8">
              <span>
                <i className="fas fa-check-circle fa-fw success"></i> {getLanguage(593, "Work permit approved")}
              </span>
            </div>
          );
        case 4:
          return (
            <div className="margin-top-8">
              <span>
                <i className="fas fa-minus-circle fa-fw danger"></i> {getLanguage(594, "Work permit declined")}
              </span>
              <div className="margin-left-32 inline-block">
                <Button
                  size="small"
                  variant="text"
                  onClick={() =>
                    this.props.history.push("/freelancer/settings/verification")
                  }
                >
                  {getLanguage(591, "Retry")}
                </Button>
              </div>
            </div>
          );
        default:
          break;
      }
    }
  }

  workPermitBack() {
    if (this.state.verificationDocumentStatus) {
      switch (this.state.verificationDocumentStatus.WorkPermitBack) {
        case 1:
          return (
            <div className="margin-top-8">
              {getLanguage(860, "Residence card (back) not uploaded yet")}
              <div className="margin-left-32 inline-block">
                <Button
                  size="small"
                  variant="text"
                  onClick={() =>
                    this.props.history.push("/freelancer/settings/verification")
                  }
                >
                  {getLanguage(606, "Fix it")}
                </Button>
              </div>
            </div>
          );
        case 2:
          return (
            <div className="margin-top-8">
              <span>
                <i className="fas fa-spinner fa-fw fa-spin"></i> {getLanguage(861, "Residence card (back) uploaded and pending")}
              </span>
            </div>
          );
        case 3:
          return (
            <div className="margin-top-8">
              <span>
                <i className="fas fa-check-circle fa-fw success"></i> {getLanguage(862, "Residence card (back) approved")}
              </span>
            </div>
          );
        case 4:
          return (
            <div className="margin-top-8">
              <span>
                <i className="fas fa-minus-circle fa-fw danger"></i> {getLanguage(863, "Residence card (back) declined")}
              </span>
              <div className="margin-left-32 inline-block">
                <Button
                  size="small"
                  variant="text"
                  onClick={() =>
                    this.props.history.push("/freelancer/settings/verification")
                  }
                >
                  {getLanguage(591, "Retry")}
                </Button>
              </div>
            </div>
          );
        default:
          break;
      }
    }
  }

  pictureId() {
    if (this.state.verificationDocumentStatus) {
      switch (this.state.verificationDocumentStatus.PictureId) {
        case 1:
          return (
            <div className="margin-top-8">
              {getLanguage(604, "Picture ID not uploaded yet")}
              <div className="margin-left-32 inline-block">
                <Button
                  size="small"
                  variant="text"
                  onClick={() =>
                    this.props.history.push("/freelancer/settings/verification")
                  }
                >
                  {getLanguage(606, "Fix it")}
                </Button>
              </div>
            </div>
          );
        case 2:
          return (
            <div className="margin-top-8">
              <span>
                <i className="fas fa-spinner fa-fw fa-spin"></i> {getLanguage(596, "Picture ID uploaded and pending")}
              </span>
            </div>
          );
        case 3:
          return (
            <div className="margin-top-8">
              <span>
                <i className="fas fa-check-circle fa-fw success"></i> {getLanguage(597, "Picture ID approved")}
              </span>
            </div>
          );
        case 4:
          return (
            <div className="margin-top-8">
              <span>
                <i className="fas fa-minus-circle fa-fw danger"></i> {getLanguage(598, "Picture ID declined")}
              </span>
              <div className="margin-left-32 inline-block">
                <Button
                  size="small"
                  variant="text"
                  onClick={() =>
                    this.props.history.push("/freelancer/settings/verification")
                  }
                >
                  {getLanguage(591, "Retry")}
                </Button>
              </div>
            </div>
          );
        default:
          break;
      }
    }
  }

  nationalId() {
    if (this.state.verificationDocumentStatus) {
      switch (this.state.verificationDocumentStatus.NationalID) {
        case 1:
          return (
            <div className="margin-top-8">
              {getLanguage(603, "National ID not uploaded yet")}
              <div className="margin-left-32 inline-block">
                <Button
                  size="small"
                  variant="text"
                  onClick={() =>
                    this.props.history.push("/freelancer/settings/verification")
                  }
                >
                  {getLanguage(606, "Fix it")}
                </Button>
              </div>
            </div>
          );
        case 2:
          return (
            <div className="margin-top-8">
              <span>
                <i className="fas fa-spinner fa-fw fa-spin"></i> {getLanguage(600, "National ID uploaded and pending")}
              </span>
            </div>
          );
        case 3:
          return (
            <div className="margin-top-8">
              <span> <i className="fas fa-check-circle fa-fw success"></i> {getLanguage(601, "National ID approved")} </span>
            </div>
          );
        case 4:
          return (
            <div className="margin-top-8">
              <span><i className="fas fa-minus-circle fa-fw danger"></i> {getLanguage(602, "National ID declined")} </span>
              <div className="margin-left-32 inline-block">
                <Button
                  size="small"
                  variant="text"
                  onClick={() =>
                    this.props.history.push("/freelancer/settings/verification")
                  }
                >
                  {getLanguage(591, "Retry")}
                </Button>
              </div>
            </div>
          );
        default:
          break;
      }
    }
  }

  render() {

    let documents = null;

    if (this.state.verificationDocumentStatus) {
      if (
        (this.state.verificationDocumentStatus.WorkPermit == 0 ||
          this.state.verificationDocumentStatus.WorkPermit == 3) &&
        (this.state.verificationDocumentStatus.WorkPermitBack == 0 ||
          this.state.verificationDocumentStatus.WorkPermitBack == 3) &&
        (this.state.verificationDocumentStatus.PictureId == 0 ||
          this.state.verificationDocumentStatus.PictureId == 3) &&
        (this.state.verificationDocumentStatus.NationalID == 0 ||
          this.state.verificationDocumentStatus.NationalID == 3)
      ) {
        documents = null;
      } else {
        documents = <div className="paper radius-12 padding-24 margin-v-16 inline-block">
          {this.workPermit()}
          {this.workPermitBack()}
          {this.pictureId()}
          {this.nationalId()}
        </div>
      }
    }


    return (
      documents
    );
  }
}
