// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Grid, Button, Hidden, Drawer } from "@material-ui/core";
import Logo from "../../../../assets/img/chabberLogo@2x.png";
import { getLanguage } from "../../../../Helpers/LanguageHelper";
import { KnownLanguageModel } from "../../../../Models/KnownLanguageModel";
import { StorageHelper } from "../../../../Helpers/StorageHelper";
import { LocalDataModel } from "../../../../Models/LocalDataModel";
import './Header.scss';
import { CountryAndLanguage } from "../Shared/CountryAndLanguage";


interface IProps { }

interface IState {
  openCountryAndLanguageMenu: boolean;
  facadeData: LocalDataModel | null;
  knownLanguages: KnownLanguageModel[];
  drawerOpen: boolean;
}

export class Header extends Component<IProps, IState> {
  storageHelper = new StorageHelper();

  constructor(props: IProps) {
    super(props);

    this.state = {
      openCountryAndLanguageMenu: false,
      knownLanguages: [],
      facadeData: null,
      drawerOpen: false,
    }
  }


  async componentDidMount() {
    await this.storageHelper.initializeCaches(false);
    await this.prepareData();

    window.addEventListener('facadeLanguageEvent', this.prepareData);
    if (!this.state.facadeData
      || !this.state.facadeData.country.Id
      || this.state.facadeData.country.Id === ''
      || !this.state.facadeData.language) {

      this.setState({ openCountryAndLanguageMenu: true });
    }
  }

  prepareData = async () => {
    let facadeData: LocalDataModel | null = new StorageHelper().getFacadeLocalization();
    let knownLanguages: KnownLanguageModel[] = [];
    if (facadeData) {
      knownLanguages = facadeData.knownLanguages;
    }
    await this.setState({ knownLanguages, facadeData })
  }

  getFlagUrl() {
    return '/img/flags/' + this.getCountryCode().toLocaleLowerCase() + '.svg';
  }

  getCountryCode() {
    if (!this.state.facadeData?.country) {
      return '';
    }
    return this.state.facadeData.country.TwoCharacterCountryCode.toUpperCase();
  }

  render() {
    return (
      <div className="Header">
        <Hidden only="xs">
          <div className="mainHeader">
            <div className="container">
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <NavLink to="/">
                    <img className="headerLogo" src={Logo} alt="Chabber logo" />
                  </NavLink>
                </Grid>
                <Grid item>
                  <div className="inputGroup">
                    <div className="inputGroup_item inline-block">
                      <NavLink to="/lookingforwork">
                        <Button disableRipple>{getLanguage(205, "Looking for work")}</Button>
                      </NavLink>
                    </div>
                    <div className="inputGroup_item inline-block">
                      <NavLink to="/aboutus">
                        <Button disableRipple>{getLanguage(384, "About us")}</Button>
                      </NavLink>
                    </div>
                    {/* <div className="inputGroup_item inline-block">
                    <NavLink to="/pricing">
                      <Button disableRipple>{getLanguage(385,"Pricing")}</Button>
                    </NavLink>
                  </div>  */}
                    <div className="inputGroup_item inline-block">
                      <NavLink to="/login">
                        <Button disableRipple>{getLanguage(386, "Log in")}</Button>
                      </NavLink>
                    </div>
                    <div className="inputGroup_item inline-block">
                      <NavLink to="/register">
                        <Button className="button-gradient" color="primary">
                          {getLanguage(387, "Sign up")}
                        </Button>
                      </NavLink>
                    </div>
                    <div className="inputGroup_item inline-block">
                      <Button id="countryAndLanguageButton" onClick={() => this.setState({ openCountryAndLanguageMenu: true })} aria-label="Language">
                        <img className="margin-right-8" src={this.getFlagUrl()} alt="" />
                        {this.getCountryCode()} <i className="fas fa-angle-down fa-fw" />
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Hidden>
        <Hidden smUp>
          <div className="container">
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <NavLink to="/">
                  <img className="headerLogo" src={Logo} alt="Chabber logo" />
                </NavLink>
              </Grid>
              <Grid item>
                <Button
                  className="icon"
                  variant="text"
                  size="medium"
                  onClick={() => this.setState({ drawerOpen: true })}
                >
                  <span>
                    <i className="fas fa-stream fa-lg"> </i>
                  </span>
                </Button>
              </Grid>
            </Grid>
          </div>
          <Drawer anchor="right" open={this.state.drawerOpen} onClose={() => this.setState({ drawerOpen: false })} classes={{ paper: 'mobileHeaderMenuPaper' }}>
            <div className="mobileHeaderMenu">
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                spacing={1}
              >

                <Grid item>
                  <Button
                    className="icon"
                    variant="text"
                    size="medium"
                    onClick={() => this.setState({ drawerOpen: false })}
                  >
                    <span>
                      <i className="fas fa-times fa-lg white"> </i>
                    </span>
                  </Button>
                </Grid>
              </Grid>
            </div>
            <div className="mobileHeaderMenu_content">
              <div className="mobileHeaderMenu_contentGroup">
                <div className="block padding-v-8">
                  <NavLink to="/lookingforwork">
                    <Button className="block" disableRipple onClick={() => this.setState({ drawerOpen: false })}>{getLanguage(205, "Looking for work")}</Button>
                  </NavLink>
                </div>
                <div className="block padding-v-8">
                  <NavLink to="/aboutus">
                    <Button disableRipple onClick={() => this.setState({ drawerOpen: false })}>{getLanguage(384, "About us")}</Button>
                  </NavLink>
                </div>
              </div>
              <div className="mobileHeaderMenu_contentGroup">
                <div className="block padding-v-8">
                  <NavLink to="/register">
                    <Button className="mobileHeaderMenu_CTA" color="primary" onClick={() => this.setState({ drawerOpen: false })}>
                      {getLanguage(387, "Sign up")}
                    </Button>
                  </NavLink>
                </div>
                <div className="block padding-v-8">
                  <NavLink to="/login">
                    <Button disableRipple onClick={() => this.setState({ drawerOpen: false })}>{getLanguage(386, "Log in")}</Button>
                  </NavLink>
                </div>
              </div>
              <div
                className="mobileHeaderMenu_countryAndLanguage"
                onClick={() => this.setState({ openCountryAndLanguageMenu: true, drawerOpen: false })} >
                <img src={this.getFlagUrl()} alt="" />
                {this.getCountryCode()} <i className="fas fa-angle-up fa-fw" />
              </div>
            </div>
          </Drawer>
        </Hidden>

        <CountryAndLanguage
          open={this.state.openCountryAndLanguageMenu}
          onClose={() => this.setState({ openCountryAndLanguageMenu: false })}
        />
      </div>
    );
  }
}
