// @ts-nocheck
import React, { PureComponent } from 'react';
import './AdminUser.scss';
import qs from 'query-string';
import Tabs from '../../Parts/General/Tabs';
import TabContent from '../../Parts/General/TabContent';
import { ImageCard } from '../../Parts/AdminUser/ImageCard';
import { MainInfo } from '../../Parts/AdminUser/MainInfo';
import { ImageCrop } from '../../Parts/General/ImageCrop';
import { SavingSnackbar } from '../../Parts/General/SavingSnackbar';
import { AdminUserModel as AdminUser, AdminUserModel } from '../../../Models/AdminUserModel';
import { AdminUserHelper } from '../../../Helpers/AdminUserHelper';
import { CountryHelper } from '../../../Helpers/CountryHelper';
import { RouteComponentProps, Prompt } from 'react-router';
import { Clearance } from '../../Parts/AdminUser/Clearance';
import { ClearanceModel } from '../../../Models/ClearanceModel';
import { ClearanceHelper } from '../../../Helpers/ClearanceHelper';
import { Grid } from '@material-ui/core';
import { IState } from '../../../Models/States/AdminUser';
import { setAdminData } from '../../../Helpers/SessionHelper';
import { SnackbarManager } from '../../../Helpers/SnackbarManager/SnackbarManager';
import { StorageHelper } from '../../../Helpers/StorageHelper';
import { configureCloudinaryUrl } from '../../../Helpers/ImageHelper';


interface IProps extends RouteComponentProps {
  location: any;
  classes: any;
}

export class AdminUserView extends PureComponent<IProps, IState> {
  adminUserHelper = new AdminUserHelper();
  countryHelper = new CountryHelper();
  clearanceHelper = new ClearanceHelper();
  storageHelper = new StorageHelper();

  constructor(props: any) {
    super(props);

    let id = qs.parse(this.props.location.search).id;
    if (typeof id === 'string') {
      this.fetchUpdateData(id);
    } else {
      this.fetchCreateData();
    }

    let user: AdminUser = new AdminUser({});
    let mode: 'edit' | 'create';

    if (typeof id === 'string') {
      mode = 'edit';
    } else {
      mode = 'create';
      id = '';
    }

    this.state = {
      contentChanged: false,
      user,
      mode,
      id: (id as string),
      countriesActive: [],
      cropOpen: false,
      profileImage: '',
      originalUser: user,
      profileImageUrl: configureCloudinaryUrl(user.ProfileImageUrl, ['w_300']),
      teams: [],
      clearances: [],
    }

    this.userEdited = this.userEdited.bind(this);
    this.resetUser = this.resetUser.bind(this);
    this.saveUser = this.saveUser.bind(this);
    this.clearanceChanged = this.clearanceChanged.bind(this);
    this.openCropping = this.openCropping.bind(this);
    this.closeCropping = this.closeCropping.bind(this);
    this.clearImage = this.clearImage.bind(this);
  }

  componentDidUpdate() {
    if (this.state.contentChanged) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = null
    }
  }

  async fetchUpdateData(id: string) {
    let clearances: ClearanceModel[] = await this.clearanceHelper.getAll();
    let countries = await this.countryHelper.getActiveCountries();
    let user = await this.adminUserHelper.getAdminUser(id);
    let teams = await this.adminUserHelper.getTeams();
    if (!user || !countries) {
      SnackbarManager.Instance.addError('Your clearance level is not high enough');
      return this.props.history.push('/admin');
    }
    this.setState({ countriesActive: countries, clearances, user: user, originalUser: user, profileImageUrl: configureCloudinaryUrl(user.ProfileImageUrl, ['w_300']), teams });
  }

  async fetchCreateData() {
    let countries = await this.countryHelper.getActiveCountries();
    let teams = await this.adminUserHelper.getTeams();
    let clearances: ClearanceModel[] = await this.clearanceHelper.getAll();
    console.log('clearances', clearances)
    if (!clearances || !countries) {
      return SnackbarManager.Instance.addError('Your clearance level is not high enough');
    }
    let user = new AdminUser(this.state.user);

    this.setState({ countriesActive: countries, user, teams, clearances });
  }

  userEdited(field: string, value: string) {
    // Create snackbar
    let user = new AdminUser(this.state.user);
    user[field] = value;

    this.setState({ user: user, contentChanged: true });
  }

  clearanceChanged(clearance: ClearanceModel) {
    let user = new AdminUser(this.state.user);
    let index = user.ClearanceLevels.findIndex(x => x.Id === clearance.Id);
    if (index !== -1) {
      let clearances = [...user.ClearanceLevels];
      clearances.splice(index, 1);

      user.ClearanceLevels = clearances;
    } else {
      user.ClearanceLevels.push(new ClearanceModel(clearance));
    }
    this.setState({ user: user, contentChanged: true });
    this.forceUpdate();
  }

  resetUser() {
    this.fetchUpdateData(this.state.id);
    SnackbarManager.Instance.addDefault('Changes reset');

    this.setState({
      contentChanged: false,
    });
  }

  async saveUser() {
    if (this.checkEmail(this.state.user.Email)) {
      // collect data from all fields and save it
      await this.setState({ contentChanged: false });

      let success: boolean;
      if (this.state.mode === 'edit') {
        success = await this.adminUserHelper.updateAdminUser(this.state);
        setAdminData(this.state.user);
        this.storageHelper.sendEvent('clearancesChanged');
      } else {
        success = await this.adminUserHelper.createNewAdminUser(this.state);
      }
      if (success) {
        SnackbarManager.Instance.addSuccess('Changes saved');
      } else {
        SnackbarManager.Instance.addError('Changes could not be saved');
      }
    } else {
      SnackbarManager.Instance.addError('Email is not correct');
    }
  }

  checkEmail(email: any) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  openCropping(image: string) {
    this.setState({ cropOpen: true, profileImage: image });
  }

  async closeCropping(image: string) {
    if (image != null) {
      let imageSaved = await this.adminUserHelper.saveAdminProfileImage(image, this.state.user.Id);
      let user = await this.adminUserHelper.getAdminUser(this.state.user.Id)
      if (!user || !imageSaved) {
        SnackbarManager.Instance.addError('Something went wrong')
        return this.props.history.push('/admin');
      }
      let imageUrl = user ? configureCloudinaryUrl(user.ProfileImageUrl, ['w_300']) : '';
      SnackbarManager.Instance.addSuccess('Photo saved');
      this.setState({
        cropOpen: false, profileImage: image, profileImageUrl: imageUrl,
      });
      // Save image
    } else {
      this.setState({ cropOpen: false, contentChanged: false, profileImage: '' });
    }
  };

  async clearImage() {
    let user = new AdminUserModel(this.state.user);
    if (user.ProfileImageId === this.state.originalUser.ProfileImageId) {
      user.ProfileImageId = '';
      user.ProfileImageUrl = '';
    } else {
      user.ProfileImageId = this.state.originalUser.ProfileImageId;
      user.ProfileImageUrl = configureCloudinaryUrl(this.state.originalUser.ProfileImageUrl, ['w_300']);
    }
    let profileImageDeleted = await this.adminUserHelper.deleteAdminProfileImage(this.state.user.Id);
    if (!profileImageDeleted) {
      SnackbarManager.Instance.addError('ProfileImage could not be deleted');
    }
    SnackbarManager.Instance.addSuccess('Photo deleted');
    this.setState({
      profileImage: '', profileImageUrl: '', user,
    });
  }

  render() {
    let savingSnackBar: any;

    if (this.state.mode === 'edit') {
      savingSnackBar = <SavingSnackbar open={this.state.contentChanged} resetClicked={this.resetUser} saveClicked={this.saveUser} />
    } else {
      savingSnackBar = <SavingSnackbar open={this.state.contentChanged} saveClicked={this.saveUser} />
    }


    return (
      <div>
        <div className='AdminPageHeader no-margin-bottom'>
          <div className='mediaItem_middle'>
            <div className='AdminPageAvatar'>
              <img src={this.state.profileImageUrl} alt='' />
            </div>
            <div className='AdminPageTitle'>
              {this.state.user.Name}
              <div className='AdminPageSubtitle'>{this.state.user.Title} </div>
            </div>
          </div>
        </div>
        <Tabs>
          <TabContent title='Info'>
            <Grid
              container
              spacing={5}
              justify='space-between'
              alignItems='stretch'
            >
              <Grid item xs={8}>
                <MainInfo User={this.state.user} UserEdited={this.userEdited} countries={this.state.countriesActive} teams={this.state.teams} />
              </Grid>
              <Grid item xs={4}>
                <div className='paper padding-24 radius-6 text-center flex-center-center'>
                  <ImageCard clearImage={this.clearImage} imageData={this.state.profileImage}
                    imageUrl={configureCloudinaryUrl(this.state.user.ProfileImageUrl, ['w_300'])} openCropping={this.openCropping} />
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={5}
              justify='space-between'
              alignItems='stretch'>
              <Grid item xs={8}>
                <Clearance
                  clearanceChanged={this.clearanceChanged}
                  userClearances={this.state.user.ClearanceLevels}
                  allClearances={this.state.clearances}
                />
              </Grid>
            </Grid>
          </TabContent>
          <TabContent title='Logs'>
            <h1>Logs</h1>
          </TabContent>
        </Tabs>

        <Prompt
          when={this.state.contentChanged}
          message={location => `You have unsaved content, are you sure you want to leave the page?`}
        />
        {savingSnackBar}
        {this.state.cropOpen ? <ImageCrop open={this.state.cropOpen} close={this.closeCropping} image={this.state.profileImage} cropMode={1} /> : null}
      </div>
    );
  }
}
