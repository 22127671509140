import React, { Component } from "react";
import "./Communication.scss";
import {
    Table, TableBody, TableCell, TableHead, TableRow
} from "@material-ui/core";
import { LanguageHelper } from "../../../../../Helpers/LanguageHelper";
import { StorageHelper } from "../../../../../Helpers/StorageHelper";
import { CommunicationEvent } from "../../../../../Models/CommunicationEvent";
import { CommunicationEventTemplateBinding } from "../../../../../Models/CommunicationEventTemplateBinding";
import { CommunicationTemplate } from "../../../../../Models/CommunicationTemplate";
import { CommunicationTemplateMaster } from "../../../../../Models/CommunicationTemplateMaster";
import { CommunicationHelper } from "../../../../../Helpers/CommunicationHelper";
import { RouteComponentProps } from "react-router";
import { PageLoader } from "../../../../Parts/General/PageLoader";
import { LanguageSelector } from "../../../../Parts/Atlas/LanguageSelector"
import { englishLanguageId } from "../../../../../Helpers/Cfg";

interface IProps extends RouteComponentProps {

}

interface IState {
    communicationEvents: CommunicationEvent[]
    communicationEventTemplateBindings: CommunicationEventTemplateBinding[]
    communicationTemplates: CommunicationTemplate[]
    communicationTemplateMasters: CommunicationTemplateMaster[]
    globalCommunicationEventTemplateBindings: CommunicationEventTemplateBinding[]
    globalCommunicationTemplates: CommunicationTemplate[]
    showPageLoader: boolean,
    languageId: string

}

export class CommunicationView extends Component<IProps, IState> {
    languageHelper = new LanguageHelper();
    storageHelper = new StorageHelper();
    communicationHelper = new CommunicationHelper();

    constructor(props: IProps) {
        super(props);

        this.state = {
            communicationEvents: [],
            communicationEventTemplateBindings: [],
            communicationTemplates: [],
            communicationTemplateMasters: [],
            globalCommunicationEventTemplateBindings: [],
            globalCommunicationTemplates: [],
            showPageLoader: false,
            languageId: ''
        };

        this.getAvailableLanguages = this.getAvailableLanguages.bind(this);
        this.languageChanged = this.languageChanged.bind(this)
    }

    async componentDidMount() {
        await this.getInitialData(null);
    }

    async languageChanged(event: any, field: 'language' | 'search' | 'order') {
        await this.setState({
            languageId: event
        });
        this.getInitialData(event);
    }

    async getAvailableLanguages() {
        let country = await this.storageHelper.getAtlasCountryFromLocal();
        let localLang;
        if (country) {
            localLang = await this.languageHelper.getLocalLanguagesForCountry(country, true);
            return localLang;
        }
    }

    async getInitialData(overrideLanguageId: string | null) {
        this.setState({ showPageLoader: true })

        let languageId = this.state.languageId
        if (overrideLanguageId) {
            languageId = overrideLanguageId
        }

        if (!languageId) {
            this.setState({ showPageLoader: false })
            return
        }

        let communicationEvents = await this.communicationHelper.getAllEvents();
        let communicationEventTemplateBindings = await this.communicationHelper.getAllCommunicationEventBindings(languageId);
        let communicationTemplates = await this.communicationHelper.getAllCommunicationTemplates(languageId);
        let communicationTemplateMasters = await this.communicationHelper.getAllCommunicationTemplateMasters(languageId);

        // Sort events
        communicationEvents = communicationEvents.sort((a: CommunicationEvent, b: CommunicationEvent) => (a.Title > b.Title ? 1 : -1));

        // Get all Global (english) variants, unless that's the one we're working on already
        let globalCommunicationEventTemplateBindings: CommunicationEventTemplateBinding[] = [];
        let globalCommunicationTemplates: CommunicationTemplate[] = [];

        if (languageId !== englishLanguageId) {
            globalCommunicationEventTemplateBindings = await this.communicationHelper.getAllCommunicationEventBindings(englishLanguageId)
            globalCommunicationTemplates = await this.communicationHelper.getAllCommunicationTemplates(englishLanguageId)
        }

        await this.setState({
            communicationEvents,
            communicationEventTemplateBindings,
            communicationTemplates,
            communicationTemplateMasters,
            globalCommunicationEventTemplateBindings,
            globalCommunicationTemplates,
            showPageLoader: false
        });

    }

    formBindingsCellForEvent(communicationEvent: CommunicationEvent) {

        let mailActive = undefined;
        let textActive = undefined;
        let pushActive = undefined;

        if (this.state.communicationEventTemplateBindings.find(t => t.CommunicationEventId === communicationEvent.Id && this.state.communicationTemplates.find(y => y.Id === t.CommunicationTemplateId && y.CommunicationType === 0) !== undefined)) {
            mailActive = <div className="inline-block margin-right-16"><i className="fas fa-circle fa-fw success"></i>Mail</div>
        } else if (this.state.globalCommunicationEventTemplateBindings.find(t => t.CommunicationEventId === communicationEvent.Id && this.state.globalCommunicationTemplates.find(y => y.Id === t.CommunicationTemplateId && y.CommunicationType === 0) !== undefined)) {
            mailActive = <div className="inline-block margin-right-16 placeholder"><i className="fas fa-circle fa-fw danger"></i>Mail</div>
        } else {
            mailActive = <div className="inline-block margin-right-16 placeholder"><i className="far fa-circle fa-fw placeholder"></i>Mail</div>
        }

        if (this.state.communicationEventTemplateBindings.find(t => t.CommunicationEventId === communicationEvent.Id && this.state.communicationTemplates.find(y => y.Id === t.CommunicationTemplateId && y.CommunicationType === 1) !== undefined)) {
            textActive = <div className="inline-block margin-right-16"><i className="fas fa-circle fa-fw success"></i>Text</div>
        } else if (this.state.globalCommunicationEventTemplateBindings.find(t => t.CommunicationEventId === communicationEvent.Id && this.state.globalCommunicationTemplates.find(y => y.Id === t.CommunicationTemplateId && y.CommunicationType === 1) !== undefined)) {
            textActive = <div className="inline-block margin-right-16 placeholder"><i className="fas fa-circle fa-fw danger"></i>Text</div>
        } else {
            textActive = <div className="inline-block margin-right-16 placeholder"><i className="far fa-circle fa-fw placeholder"></i>Text</div>
        }

        if (this.state.communicationEventTemplateBindings.find(t => t.CommunicationEventId === communicationEvent.Id && this.state.communicationTemplates.find(y => y.Id === t.CommunicationTemplateId && y.CommunicationType === 2) !== undefined)) {
            pushActive = <div className="inline-block margin-right-16"><i className="fas fa-circle fa-fw success"></i>Push</div>
        } else if (this.state.globalCommunicationEventTemplateBindings.find(t => t.CommunicationEventId === communicationEvent.Id && this.state.globalCommunicationTemplates.find(y => y.Id === t.CommunicationTemplateId && y.CommunicationType === 2) !== undefined)) {
            pushActive = <div className="inline-block margin-right-16 placeholder"><i className="fas fa-circle fa-fw danger"></i>Push</div>
        } else {
            pushActive = <div className="inline-block margin-right-16 placeholder"><i className="far fa-circle fa-fw placeholder"></i>Push</div>
        }

        return (<TableCell className="tableCell" align="right">
            {mailActive}
            {textActive}
            {pushActive}
        </TableCell>);
    }

    render() {
        return (<div>
            <div className="AdminPageHeader">
                <div className="AdminPageTitle">Communication system</div>
                <div className="AdminPageHeader_options">
                    <div className="inputGroup">
                        <LanguageSelector className="inputGroup_item" valueChanged={this.languageChanged} />

                    </div>
                </div>
            </div>
            <div className="adminTable">
                <Table className="table">
                    <TableHead className="tableHead">
                        <TableRow className="tableRow">
                            <TableCell>Event name</TableCell>
                            <TableCell align="right">Communication type</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="tableBody">
                        {this.state.communicationEvents.map(communicationEvent => (
                            <TableRow key={communicationEvent.Id}
                                onClick={() => {
                                    this.props.history.push("/admin/communicationevent?id=" + communicationEvent.Id + "&languageId=" + this.state.languageId);
                                }}
                                className="tableRow cursorPointer"
                                hover={true}>
                                <TableCell className="tableCell">{communicationEvent.Title}</TableCell>
                                {this.formBindingsCellForEvent(communicationEvent)}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
            {this.state.showPageLoader ? <PageLoader /> : null}
        </div>)
    }
}