import { AdminUserModel } from '../Models/AdminUserModel';
import { LoginModel } from '../Models/LoginModel';
import { StorageHelper } from './StorageHelper';
import { UserHelper } from './UserHelper';
import { BroadcastHelper } from "./BroadcastHelper";


export function getCurrentAdminUser(): AdminUserModel | null {
  try {
    let userStr = new StorageHelper().getAdminUser();

    if (!userStr) return null;
    let user: AdminUserModel = userStr;
    return user;
  } catch (error: any) {
    return null;
  }
}

export function hasClearanceLevel(clearance: Clearances) {
  try {
    let user = getCurrentAdminUser();
    if (!user) return false;
    let index = user.ClearanceLevels.findIndex(x => x.Name === clearance);
    return index !== -1;
  } catch (error: any) {
    return false;
  }
}

export function getCurrentUser(): LoginModel | null {
  try {
    let userStr = new StorageHelper().getCurrentUser();
    if (!userStr) {
      return null;
    }
    let user: LoginModel = new LoginModel(userStr);
    return user;
  } catch (error: any) {
    return null;
  }
}

export function getCurrentLanguage() {
  try {
    const localDataModel = new StorageHelper().getFacadeLocalization();
    return localDataModel?.language;
  } catch (error: any) {
    return null;
  }
}

export function isAdminLoggedIn() {
  try {
    let loggedIn = new StorageHelper().getAdminToken();
    return !loggedIn ? false : true;
  } catch (error: any) {
    return false;
  }
}

export function isUserLoggedIn() {
  try {
    let loggedIn = new StorageHelper().getAccessToken();
    return !loggedIn ? false : true;
  } catch (error: any) {
    return false;
  }
}

// export function setAdminLoggedIn(state: boolean) {
//   let storageHelper = new StorageHelper();
//   let str = JSON.stringify(state);
//   storageHelper.setLoggedInAdminInSession(str);
// }

export function setAdminData(admin: AdminUserModel) {
  let loginStr = JSON.stringify(admin);
  new StorageHelper().setAdminUser(loginStr)
}

export function setUserData(login: LoginModel) {
  let storageHelper = new StorageHelper();
  let localData = storageHelper.getFacadeLocalization();
  if (localData) {
    localData.language = login.PrimaryLanguage;
    if (login.Freelancer?.Country) {
      localData.country = login.Freelancer.Country;
    }
    else if (login.Company?.Country) {
      localData.country = login.Company.Country;
    }
    storageHelper.setFacadeLocalization(localData, true);
  }

  let loginStr = JSON.stringify(login);
  storageHelper.setCurrentUser(loginStr);
}

export async function logUserOut() {
  let storageHelper = new StorageHelper();

  storageHelper.removeAccessToken();
  storageHelper.removeCurrentUser();
  BroadcastHelper.Instance?.userLoggedOut();
  await storageHelper.removeInitialPostingData()
  return await new UserHelper().logOut();
}

function sendEvent(name: string) {
  let evnt = new Event(name);
  window.dispatchEvent(evnt);
}


export enum Clearances {
  AddAdminUser = 'Add admin user',
  EditAdminUser = 'Edit Admin user',
  DeleteAdminUser = 'Delete admin user',
  EditClearanceLevels = 'Edit clearance levels',
  ShowRunSalary = 'Show RunSalary',
  ShowEconomyPage = 'Show Economy page',
  PublishCms = 'Publish Cms',
  DeleteCms = 'Delete Cms',
  PreviewCms = 'Preview Cms',
  CreateCms = 'Create Cms',
  EditGlobalLanguage = 'Edit global language',
  EditLocalLanguage = 'Edit local language',
  EditJobPost = 'Edit jobpost',
  ViewAtlas = 'View Atlas',
  Founder = 'Founder',
  FacilitateVerification = 'Facilitate Ids and work permits',
}