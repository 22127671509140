// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from "react";
import { SwipeableDrawer, Grid, Tooltip, Menu, MenuItem, Button, Avatar } from "@material-ui/core";
import "./EditPosting.scss";
import { PostingTemplateHelper } from "../../../../../Helpers/PostingTemplateHelper";
import { PostingTemplateModel } from "../../../../../Models/PostingTemplateModel";
import { getLanguage } from "../../../../../Helpers/LanguageHelper";
import { LoginModel } from "../../../../../Models/LoginModel";
import { getMoneyOutput } from "../../../../../Helpers/LocalizationHelper";
import { StorageHelper } from "../../../../../Helpers/StorageHelper";
import { SkillModel } from "../../../../../Models/SkillModel";
import { resolveSalaryPerHourForCompany } from "../../../../../Modules/CollectiveAgreement/Utils";
import { getCurrentUser } from "../../../../../Helpers/SessionHelper";

interface IProps {
  open: boolean;
  templates: PostingTemplateModel[];
  onClose: () => void;
  createTemplate: () => void;
  applyTemplate: (template: PostingTemplateModel) => Promise<boolean>;
  editTemplate: (template: PostingTemplateModel) => void;
  removeTemplate: (templateId: string) => Promise<boolean>;
  jobOwners: LoginModel[];
}

interface IState {
  currentUser: LoginModel | null;
  selectedOption: { elem: HTMLElement; template: PostingTemplateModel } | null;
  skills: SkillModel[];
}
export class TemplateOverview extends Component<IProps, IState> {
  templateHelper = new PostingTemplateHelper();
  storageHelper = new StorageHelper();

  constructor(props: IProps) {
    super(props);
    this.state = {
      currentUser: null,
      selectedOption: null,
      skills: this.storageHelper.getSkillsFromLocal() || [],
    };
    this.openOptions = this.openOptions.bind(this);
    this.closeOptionsMenu = this.closeOptionsMenu.bind(this);
    this.deleteTemplate = this.deleteTemplate.bind(this);
    this.applyTemplate = this.applyTemplate.bind(this);
    this.editTemplate = this.editTemplate.bind(this);
  }

  getSkill(skillId: string) {
    let skills = this.state.skills;
    if (skills.length === 0) {
      skills = this.storageHelper.getSkillsFromLocal() || [];
      this.setState({ skills });
    }
    return skills?.find((x) => x.Id === skillId);
  }

  getOwnerName(template: PostingTemplateModel, ignoreIfIsMaster: boolean): string {
    if (!template.DefaultOwnerLoginId) {
      return "";
    }

    let owner = this.props.jobOwners.find((t) => t.Id === template.DefaultOwnerLoginId);
    if (!owner) {
      return getLanguage(179, "You");
    }

    if (ignoreIfIsMaster && owner.isMaster) {
      return getLanguage(179, "You");
    }

    let name = owner.Firstnames;
    if (this.state.currentUser?.Id === template.DefaultOwnerLoginId) {
      name = getLanguage(179, "You");
    }
    return owner ? name : "not set";
  }

  chabbersNeeded(template: PostingTemplateModel) {
    let singularisCode = 0;
    let pluralusCode = 0;
    let skill = this.getSkill(template.SkillId);
    singularisCode = skill?.LanguageCodeSingularis || 0;
    pluralusCode = skill?.LanguageCodePluralis || 0;

    let templateStr = template.AmountNeeded + " " + (template.AmountNeeded === 1 ? getLanguage(singularisCode) : getLanguage(pluralusCode));
    return templateStr;
  }

  openOptions(event: React.MouseEvent<HTMLDivElement, MouseEvent>, template: PostingTemplateModel) {
    event.stopPropagation();
    let elem = event.currentTarget.parentElement;

    if (elem == null) return;
    this.setState({ selectedOption: { elem, template } });
  }

  getInitial(template: PostingTemplateModel): string {
    if (this.state.currentUser && this.state.currentUser.Id === template.DefaultOwnerLoginId) {
      return "";
    }

    let initial = this.getOwnerName(template, false).substring(0, 1);
    return initial;
  }

  closeOptionsMenu() {
    this.setState({ selectedOption: null });
  }

  async deleteTemplate() {
    if (this.state.selectedOption == null) return;
    let id = this.state.selectedOption.template.Id;
    let response = await this.props.removeTemplate(id);

    if (response === true) {
      this.setState({ selectedOption: null });
    }
  }

  async applyTemplate(template?: PostingTemplateModel) {
    let result;
    if (this.state.selectedOption == null) {
      if (template == null) return;
      result = await this.props.applyTemplate(template);
    } else {
      result = await this.props.applyTemplate(this.state.selectedOption.template);
    }
    if (result === true) {
      this.setState({ selectedOption: null });
    }
  }

  async editTemplate() {
    if (this.state.selectedOption == null) return;
    await this.props.editTemplate(this.state.selectedOption.template);
    this.setState({ selectedOption: null });
  }

  render() {
    let menu;
    if (this.state.selectedOption != null) {
      menu = (
        <Menu
          id="simple-menu"
          anchorEl={this.state.selectedOption.elem}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          getContentAnchorEl={null}
          keepMounted
          open={Boolean(this.state.selectedOption.elem)}
          onClose={this.closeOptionsMenu}
          disableAutoFocusItem={true}
          className="dropdownMenu"
          classes={{ paper: "dropdownMenu_content" }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem className="menuItem" onClick={() => this.applyTemplate()}>
            <i className="fas fa-check fa-fw"></i>
            {getLanguage(434, "Use template")}
          </MenuItem>
          <MenuItem className="menuItem" onClick={this.editTemplate}>
            <i className="fas fa-edit fa-fw"></i>
            {getLanguage(113, "Edit")}
          </MenuItem>
          <MenuItem className="menuItem" onClick={this.deleteTemplate}>
            <i className="far fa-trash-alt fa-fw"></i>
            {getLanguage(117, "Delete")}
          </MenuItem>
        </Menu>
      );
    }
    let currentUser = getCurrentUser();
    return (
      <SwipeableDrawer
        anchor="right"
        open={this.props.open}
        onOpen={() => console.log("myellow")}
        onClose={() => this.props.onClose()}
        className="templateDrawerWrapper"
        variant="temporary"
        classes={{ paper: "templateDrawer" }}
      >
        <div className="padding-32">
          <div className="margin-bottom-32">
            <Grid container justify="space-between">
              <Grid item>
                {" "}
                <h1 className="margin-0">{getLanguage(432, "My templates")}</h1>
              </Grid>
              <Grid item>
                {" "}
                <Button variant="text" size="small" onClick={this.props.createTemplate}>
                  <span className="btn-icon">
                    <i className="fas fa-plus" />
                  </span>
                  {getLanguage(433, "Add new")}
                </Button>
              </Grid>
            </Grid>
          </div>
          {/* If template count = <1  */}
          <div>
            <Grid container alignItems="stretch" spacing={2}>
              <Grid item xs={12}>
                {this.props.templates.map((template) => {
                  let timeString = template.StartTime.substr(0, 5) + " - " + template.EndTime.substr(0, 5);

                  return (
                    <div key={template.Id} className="templateItem" onClick={() => this.applyTemplate(template)}>
                      <Grid container item justify="space-between">
                        <Grid item>
                          <div className="templateTitle">{template.Title}</div>
                        </Grid>
                        <Grid item>
                          <div className="templateOptions" onClick={(e) => this.openOptions(e, template)}>
                            <Button variant="text" size="small" className="icon">
                              <span>
                                <i className="fas fa-ellipsis-v fa-md" />
                              </span>
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                      <div className="templateItemRow">
                        <i className="fas fa-user-friends fa-fw" />
                        {this.chabbersNeeded(template)}
                      </div>
                      <div className="templateItemRow">
                        <i className="fas fa-clock fa-fw" />
                        {timeString}
                      </div>
                      <div className="templateItemRow">
                        <i className="fas fa-coins fa-fw" />
                        {getMoneyOutput((resolveSalaryPerHourForCompany(template.SalaryPerHour, currentUser?.Company, template.SkillId) / 100).toString(), true, null)}
                      </div>
                      <div className="templateItemRow">
                        <i className="fas fa-info-circle fa-fw" />
                        {template.Description.substring(0, 100) + (template.Description.length > 100 ? "..." : "")}
                      </div>
                      <div className="templateItemRow">
                        <i className="fas fa-user-tie fa-fw" />
                        {template.MustBring}
                      </div>
                      <div className="margin-top-24">
                        <Grid container justify="space-between" spacing={2} item>
                          <Grid item>
                            <div className={"templateIcon" + (template.LikelihoodOfOvertime === 0 ? "" : " active")}>
                              <Tooltip title={getLanguage(418, "Expected overtime") + ": " + template.LikelihoodOfOvertime + "min"} placement="top">
                                <i className="fas fa-plus fa-fw" />
                              </Tooltip>
                            </div>
                          </Grid>
                          <Grid item>
                            <div className={template.RequiresRelevantExperience ? "templateIcon active" : "templateIcon"}>
                              <Tooltip
                                title={template.RequiresRelevantExperience ? getLanguage(429, "Relevant experience required") : getLanguage(452, "No experience required")}
                                placement="top"
                              >
                                <i className="fas fa-certificate fa-fw" />
                              </Tooltip>
                            </div>
                          </Grid>
                          <Grid item>
                            <div className={template.PaysTransport > 0 ? "templateIcon active" : "templateIcon"}>
                              <Tooltip
                                title={
                                  template.PaysTransport > 0 ? getLanguage(423, "Paid transport") + ": " + template.PaysTransport + " min" : getLanguage(451, "No paid transport")
                                }
                                placement="top"
                              >
                                <i className="fas fa-train fa-fw" />
                              </Tooltip>
                            </div>
                          </Grid>
                          <Grid item>
                            <div className={template.ReferenceKey ? "templateIcon active" : "templateIcon"}>
                              <Tooltip
                                title={
                                  template.ReferenceKey ? getLanguage(426, "Internal refenrece nr") + ": '" + template.ReferenceKey + "'" : getLanguage(450, "No refenrence nr.")
                                }
                                placement="top"
                              >
                                <i className="fab fa-slack-hash fa-fw" />
                              </Tooltip>
                            </div>
                          </Grid>
                          <Grid item>
                            <div className={this.getInitial(template) === "" ? "templateIcon" : "templateIcon active"}>
                              <Tooltip title={getLanguage(449, "Person in charge") + ": " + this.getOwnerName(template, true)} placement="top">
                                <Avatar className="templateAvatar">{this.getInitial(template)}</Avatar>
                              </Tooltip>
                            </div>
                          </Grid>
                          <Grid item>
                            <div className={template.SelfPaidBreak ? "templateIcon active" : "templateIcon"}>
                              <Tooltip
                                title={
                                  template.SelfPaidBreak
                                    ? getLanguage(903, "Self-paid break") + ": " + getLanguage(810, "Yes")
                                    : getLanguage(903, "Self-paid break") + ": " + getLanguage(281, "No")
                                }
                                placement="top"
                              >
                                <i className="fas fa-utensils fa-fw" />
                              </Tooltip>
                            </div>
                          </Grid>
                          <Grid item>
                            <div className={template.MealIncluded ? "templateIcon active" : "templateIcon"}>
                              <Tooltip
                                title={
                                  template.MealIncluded
                                    ? getLanguage(811, "Meal included") + ": " + getLanguage(810, "Yes")
                                    : getLanguage(811, "Meal included") + ": " + getLanguage(281, "No")
                                }
                                placement="top"
                              >
                                <i className="fas fa-utensils fa-fw" />
                              </Tooltip>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  );
                })}
              </Grid>
              <Grid item xs={12} className={this.props.templates.length === 0 ? "hidden" : ""}>
                <div className="templateItem newTemplate" onClick={this.props.createTemplate}>
                  <h2>{getLanguage(435, "New template")}</h2>
                </div>
              </Grid>
            </Grid>
          </div>
          {/* If template count ==0  */}
          <div className={this.props.templates.length === 0 ? "" : "hidden"}>
            {getLanguage(
              436,
              "Stop wasting time filling out the same form over and over again. Use templates to save time by reusing the same job forms again and again. Use them as a starting point or post a job with just one click. You can create as many as you need."
            )}
          </div>
        </div>

        {menu}
      </SwipeableDrawer>
    );
  }
}
