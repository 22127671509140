import moment from "moment";
const lateCancelationQuarantineTresholdInHours = 4;
export const shouldQuarantineForLateCancellation = (
  workStartTime: moment.Moment
): boolean => {
  const distanceToShiftStart = Math.floor(
    moment.duration(workStartTime.diff(moment())).asHours()
  );

  return !(distanceToShiftStart > lateCancelationQuarantineTresholdInHours - 1);
};
