import React, { useEffect } from "react";
import { useState } from "react";
import "./Checkout.scss";
import { PostingModel } from "../../../../Models/PostingModel";
import { RequestModel } from "../../../../Models/RequestModel";
import { ChatHelper } from "../../../../Helpers/ChatHelper";
import { SnackbarManager } from "../../../../Helpers/SnackbarManager/SnackbarManager";
import { getLanguage } from "../../../../Helpers/LanguageHelper";
import { useQuery } from "react-query";
import axios, { AxiosResponse } from "axios";
import { StorageHelper } from "../../../../Helpers/StorageHelper";
import { Grid, Button, CircularProgress } from "@material-ui/core";
import { getMoneyOutput } from "../../../../Helpers/LocalizationHelper";
import { CompanyHelper } from "../../../../Helpers/CompanyHelper";

interface IProps {
  requestsToHire: {
    Request: RequestModel;
  }[];
  activePosting: PostingModel;
  cancelCheckout: () => void;
  requestIds: string[];
  confirmed: (hiringChabbers: boolean) => void;
  loginIdsToConfirm: string[];
}

type CheckoutDto = {
  salaryAmount: number;
  salaryUnitPrice: number;
  salaryTotal: number;
  hourlyFeeAmount: number;
  hourlyFeeUnitPrice: number;
  hourlyFeeTotal: number;
  startFeeAmount: number;
  startFeeUnitPrice: number;
  startFeeTotal: number;
  cancelInsuranceFeeAmount: number;
  cancelInsuranceFeeUnitPrice: number;
  cancelInsuranceFeeTotal: number;
  totalExVat: number;
  vat: number;
  totalInclVat: number;
};

const mapFromMoney = (value: number) => value / 100;

export const CheckoutV2 = (props: IProps) => {
  const [loading, setLoading] = useState(false);
  const [checkout, setCheckout] = useState<CheckoutDto>();
  const { data: response, isLoading } = useQuery<AxiosResponse<CheckoutDto>>(
    ["checkout-data", props.requestIds],
    () => {
      return axios.post<CheckoutDto>(
        `/api/request/cost`,
        {
          requestIds: props.requestIds,
        },
        {
          headers: {
            "Content-type": "application/json",
            authToken: new StorageHelper().getAccessToken(),
          },
        }
      );
    }
  );

  useEffect(() => {
    if (response) {
      const { data } = response;
      setCheckout({
        ...data,
        salaryUnitPrice: mapFromMoney(data.salaryUnitPrice),
        salaryTotal: mapFromMoney(data.salaryTotal),
        hourlyFeeUnitPrice: mapFromMoney(data.hourlyFeeUnitPrice),
        hourlyFeeTotal: mapFromMoney(data.hourlyFeeTotal),
        startFeeUnitPrice: mapFromMoney(data.startFeeUnitPrice),
        startFeeTotal: mapFromMoney(data.startFeeTotal),
        cancelInsuranceFeeUnitPrice: mapFromMoney(
          data.cancelInsuranceFeeUnitPrice
        ),
        cancelInsuranceFeeTotal: mapFromMoney(data.cancelInsuranceFeeTotal),
        totalExVat: mapFromMoney(data.totalExVat),
        vat: mapFromMoney(data.vat),
        totalInclVat: mapFromMoney(data.totalInclVat),
      });
    }
  }, [response]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  const confirmRequests = async () => {
    setLoading(true);
    let success = await new CompanyHelper().hireChabbers(props.requestIds);

    if (success === 403) {
      SnackbarManager.Instance.addWarning(
        getLanguage(711, "must have creditcard to hire chabbers")
      );
      props.cancelCheckout();
    } else if (success === 402) {
      SnackbarManager.Instance.addWarning(
        getLanguage(712, "problem with registered creditcard ")
      );
      props.cancelCheckout();
    } else if (success === false) {
      SnackbarManager.Instance.addWarning(getLanguage(469, "unknown error"));
      props.cancelCheckout();
    } else {
      if (props.activePosting.Id && props.loginIdsToConfirm) {
        new ChatHelper().chatMessageNetworkMembers(
          props.activePosting.Id,
          props.loginIdsToConfirm
        );
      }
      props.confirmed(true);
    }

    setLoading(false);
  };
  return checkout && !loading ? (
    <div className="checkoutView">
      <div className="dialogHeader padding-24">
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <h2 className="no-margin">
              {getLanguage(728, "Hire {{count}} selected chabber(s)").replace(
                "{{count}}",
                props.requestsToHire.length.toString()
              )}
            </h2>
          </Grid>
          <Grid item>
            <Button onClick={props.cancelCheckout} disabled={loading}>
              {getLanguage(242, "Cancel")}
              {loading && (
                <CircularProgress size={20} className="buttonLoader" />
              )}
            </Button>
          </Grid>
        </Grid>
      </div>
      <div className="padding-40">
        <table>
          <thead>
            <tr>
              <td align="left">{getLanguage(70, "Description")}</td>
              <td>{getLanguage(729, "Amount")}</td>
              <td>{getLanguage(730, "Unit price")}</td>
              <td>{getLanguage(731, "Total")}</td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td align="left">
                {props.requestsToHire.length}{" "}
                {getLanguage(732, "Salary incl. holiday pay")}
              </td>
              <td>
                {checkout.salaryAmount}{' '}
                {getLanguage(733, "hours")}
              </td>
              <td>
                {getMoneyOutput(
                  checkout.salaryUnitPrice.toFixed(2),
                  false,
                  props.activePosting ? props.activePosting.Currency : null,
                  true
                )}
              </td>
              <td>
                {getMoneyOutput(
                  checkout.salaryTotal.toFixed(2),
                  false,
                  props.activePosting ? props.activePosting.Currency : null,
                  true
                )}
              </td>
            </tr>
            <tr>
              <td align="left">{getLanguage(734, "Hourly fee")} </td>
              <td>
                {checkout.hourlyFeeAmount}{' '}
                {getLanguage(733, "hours")}
              </td>
              <td>
                {getMoneyOutput(
                  checkout.hourlyFeeUnitPrice.toFixed(2),
                  false,
                  props.activePosting ? props.activePosting.Currency : null,
                  true
                )}
              </td>
              <td>
                {getMoneyOutput(
                  checkout.hourlyFeeTotal.toFixed(2),
                  false,
                  props.activePosting ? props.activePosting.Currency : null,
                  true
                )}
              </td>
            </tr>
            {checkout.startFeeAmount !== 0 && (
              <tr>
                <td align="left">{getLanguage(726, "Start fee")}</td>
                <td>{checkout.startFeeAmount}</td>
                <td>
                  {getMoneyOutput(
                    checkout.startFeeUnitPrice.toFixed(2),
                    false,
                    props.activePosting ? props.activePosting.Currency : null,
                    true
                  )}
                </td>
                <td>
                  {getMoneyOutput(
                    checkout.startFeeTotal.toFixed(2),
                    false,
                    props.activePosting ? props.activePosting.Currency : null,
                    true
                  )}
                </td>
              </tr>
            )}

            {checkout.cancelInsuranceFeeAmount !== 0 && (
              <tr>
                <td align="left">{getLanguage(725, "Cancel insurance")}</td>
                <td>{checkout.cancelInsuranceFeeAmount}</td>
                <td>
                  {getMoneyOutput(
                    checkout.cancelInsuranceFeeUnitPrice.toFixed(2),
                    false,
                    props.activePosting ? props.activePosting.Currency : null,
                    true
                  )}
                </td>
                <td>
                  {getMoneyOutput(
                    checkout.cancelInsuranceFeeTotal.toFixed(2),
                    false,
                    props.activePosting ? props.activePosting.Currency : null,
                    true
                  )}
                </td>
              </tr>
            )}
          </tbody>

          <tbody className="checkoutMiddle">
            <tr>
              <td align="left">{getLanguage(735, "Total ex. VAT")}</td>
              <td></td>
              <td></td>
              <td>
                {getMoneyOutput(
                  checkout.totalExVat.toFixed(2),
                  false,
                  props.activePosting ? props.activePosting.Currency : null,
                  true
                )}
              </td>
            </tr>
          </tbody>
          <tbody className="checkoutMiddle">
            <tr>
              <td align="left">{getLanguage(854, "Tax")}</td>
              <td></td>
              <td></td>
              <td>
                {getMoneyOutput(
                  checkout.vat.toFixed(2),
                  false,
                  props.activePosting ? props.activePosting.Currency : null,
                  true
                )}
              </td>
            </tr>
          </tbody>
          <tbody className="checkoutTotal">
            <tr>
              <td align="left">{getLanguage(855, "Total incl. VAT")}</td>
              <td></td>
              <td></td>
              <td>
                {getMoneyOutput(
                  checkout.totalInclVat.toFixed(2),
                  false,
                  props.activePosting ? props.activePosting.Currency : null,
                  true
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="margin-top-24">
          <Grid
            container
            spacing={8}
            justify="space-between"
            alignItems="center"
          >
            <Grid item></Grid>
            <Grid item>
              <Button
                disabled={loading}
                className="success"
                variant="text"
                size="large"
                onClick={confirmRequests}
              >
                <span className="btn-icon">
                  <i className="fas fa-check" />
                </span>
                {getLanguage(737, "Confirm")}
                {loading && (
                  <CircularProgress size={20} className="buttonLoader" />
                )}
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  ) : (
    <>{loading && <CircularProgress size={20} className="buttonLoader" />}</>
  );
};
