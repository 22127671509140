import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { Grid, TextField, MenuItem, RadioGroup, FormControlLabel, Radio, Divider, Button } from "@material-ui/core";
import { SavingSnackbar } from '../../../Parts/General/SavingSnackbar';
import { CourseModel } from "../../../../Models/CourseModel";
import { CourseDateModel } from "../../../../Models/CourseDateModel";
import { CourseHelper } from '../../../../Helpers/CourseHelper';
import { CourseOrganizerHelper } from '../../../../Helpers/CourseOrganizerHelper';
import { CoursePartnerModel } from '../../../../Models/CoursePartnerModel';
import { SnackbarManager } from '../../../../Helpers/SnackbarManager/SnackbarManager';

interface IProps extends RouteComponentProps {
  course: CourseModel | null;
  titleChanged: (title: string) => void;
}

export function CourseDataTab(props: IProps) {
  let courseHelper = new CourseHelper();
  let courseOrganizerHelper = new CourseOrganizerHelper();

  const [isDirty, setIsDirty] = useState(false);
  const [course, setCourse] = useState(new CourseModel({}));
  const [courseDates, setCourseDates] = useState<CourseDateModel[]>([new CourseDateModel({})]);
  const [coursePartners, setCoursePartners] = useState<CoursePartnerModel[]>([]);
  const [terms, setTerms] = useState<'existingTerms' | 'customTerms'>('existingTerms');
  const [customTerms, setCustomTerms] = useState(''); //Should we prefill with existing terms..?
  const [errors, setErrors] = useState<Record<string, boolean>>({});

  useEffect(() => {
    preparePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.state])


  useEffect(() => {
    if (props.course == null) return;

    setCourseDates(props.course.CourseDates);
    if (props.course.CustomTerms) {
      setCustomTerms(props.course.CustomTerms);
      setTerms('customTerms')
    }


    setCourse(props.course);
  }, [props.course])

  async function saveData() {
    let hasErrors = validateFields();

    if (hasErrors) {
      SnackbarManager.Instance.addWarning('Please correct all missing fields');
      setIsDirty(false);
      return;
    }

    let courseForSaving = new CourseModel(course);
    courseForSaving.CourseDates = courseDates;
    if (terms === 'customTerms') {
      courseForSaving.CustomTerms = customTerms;
    } else {
      courseForSaving.CustomTerms = '';
    }
    let result
    if (props.course) {
      result = await courseHelper.updateCourse(courseForSaving);
    } else {
      result = await courseHelper.saveCourse(courseForSaving);
    }
    if (result) {
      props.history.push('/admin/courses', { id: result.Id });
    } else {
      SnackbarManager.Instance.addError('Unknown error, did not save');
    }
    setIsDirty(false);
  }

  function validateFields(fieldName?: string, unError?: boolean) {
    let hasError = false;

    function validateString(val: string, name: string, previous: boolean) {
      if (fieldName && fieldName !== name) {
        return previous;
      } else if (fieldName && fieldName === name && unError === true) {
        return false;
      } else if (fieldName == null && unError === true) {
        return false;
      }
      let result = !val;
      if (result === true) {
        hasError = result;
      }
      return result;
    }

    function validateNumber(val: number, minNumber: number, name: string, previous: boolean) {
      if (fieldName && fieldName !== name) {
        return previous;
      } else if (fieldName && fieldName === name && unError === true) {
        return false;
      } else if (fieldName == null && unError === true) {
        return false;
      }
      let result = typeof val !== 'number' || val < minNumber;

      if (result === true) {
        hasError = result;
      }
      return result;
    }

    let errorObj: Record<string, boolean> = {};
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        errorObj[key] = errors[key];
      }
    }

    errorObj['title'] = validateString(course.Title, 'title', errorObj['title']);
    errorObj['maxRegistrations'] = validateNumber(course.MaxRegistrations, 1, 'maxRegistrations', errorObj['maxRegistrations']);
    errorObj['coursePartnerId'] = validateString(course.CoursePartnerId, 'coursePartnerId', errorObj['coursePartnerId']);
    errorObj['valueInLocalCurrency'] = validateNumber(course.ValueInLocalCurrency, 0, 'valueInLocalCurrency', errorObj['valueInLocalCurrency']);
    errorObj['workingMinutesToTakeAsPayment'] =
      validateNumber(course.WorkingMinutesToTakeAsPayment, 0, 'workingMinutesToTakeAsPayment', errorObj['workingMinutesToTakeAsPayment']);
    if (terms === 'customTerms') { errorObj['customTerms'] = validateString(customTerms, 'customTerms', errorObj['customTerms']); }
    errorObj['address'] = validateString(course.Address, 'address', errorObj['address']);
    errorObj['zip'] = validateString(course.Zip, 'zip', errorObj['zip']);
    errorObj['city'] = validateString(course.City, 'city', errorObj['city']);


    for (let index = 0; index < courseDates.length; index++) {
      const element = courseDates[index];
      errorObj[index + 'date'] = validateString(element.Date, index + 'date', errorObj[index + 'date']);
      errorObj[index + 'startAtUTC'] = validateString(element.StartAtUTC, index + 'startAtUTC', errorObj[index + 'startAtUTC']);
      errorObj[index + 'endAtUTC'] = validateString(element.EndAtUTC, index + 'endAtUTC', errorObj[index + 'endAtUTC']);

      if (element.StartAtUTC >= element.EndAtUTC && element.EndAtUTC) {
        errorObj[index + 'endAtUTC'] = true;
      }
    }
    setErrors(errorObj);
    return hasError;
  }

  async function preparePage() {

    if (props.course != null) {
      setCourseDates(props.course.CourseDates);
      if (props.course.CustomTerms) {
        setCustomTerms(props.course.CustomTerms);
        setTerms('customTerms')
      }
      setCourse(props.course);
    } else {
      setCourse(new CourseModel({}));
      setCourseDates([new CourseDateModel({})]);
      setCustomTerms('');
      setTerms('existingTerms');
    }
    setIsDirty(false);

    if (!coursePartners || coursePartners.length === 0) {
      setCoursePartners(await courseOrganizerHelper.getOrganizers(true));
    }

    validateFields(undefined, true);
  }

  function changeCourseDay(index: number, field: string, val: string) {
    let dateArr = courseDates.map(x => x);
    // let index = dateArr.findIndex(x => x.Id === currentCourseDate.Id);
    dateArr[index] = new CourseDateModel(dateArr[index] || {});
    switch (field) {
      case 'Date':
        dateArr[index]!.Date = val;
        break;
      case 'StartAtUTC':
        dateArr[index]!.StartAtUTC = val;
        break;
      case 'EndAtUTC':
        dateArr[index]!.EndAtUTC = val;
        break;
    }
    setCourseDates(dateArr);
    setIsDirty(true);
  }

  function renderCourseDays() {
    return (
      courseDates.map((currentCourseDate, index) => {
        return (
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={2}
            key={index}
          >
            <Grid item xs={3}>
              <TextField
                type="date"
                label="Date day 1"
                variant="outlined"
                fullWidth
                margin="dense"
                InputLabelProps={{
                  shrink: true
                }}
                value={currentCourseDate.Date}
                onChange={e => changeCourseDay(index, 'Date', e.target.value)}
                error={errors[index + 'date']}
                onBlur={() => validateFields(index + 'date')}
                onFocus={() => validateFields(index + 'date', true)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                type="time"
                label="Start time"
                variant="outlined"
                fullWidth
                margin="dense"
                InputLabelProps={{
                  shrink: true
                }}
                value={currentCourseDate.StartAtUTC}
                onChange={e => changeCourseDay(index, 'StartAtUTC', e.target.value)}
                error={errors[index + 'startAtUTC']}
                onBlur={() => validateFields(index + 'startAtUTC')}
                onFocus={() => validateFields(index + 'startAtUTC', true)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                type="time"
                label="End time"
                variant="outlined"
                fullWidth
                margin="dense"
                InputLabelProps={{
                  shrink: true
                }}
                value={currentCourseDate.EndAtUTC}
                onChange={e => changeCourseDay(index, 'EndAtUTC', e.target.value)}
                error={errors[index + 'endAtUTC']}
                onBlur={() => validateFields(index + 'endAtUTC')}
                onFocus={() => validateFields(index + 'endAtUTC', true)}
              />
            </Grid>
            {
              index !== 0 &&
              <Button
                onClick={() => removeCourseDate(index)}
              >
                <i className="fas fa-times"></i>
              </Button>
            }
          </Grid>
        )
      })
    );
  }

  function removeCourseDate(index: number) {
    validateFields(undefined, true);
    let arr = courseDates.map(x => x);
    arr.splice(index, 1);

    setCourseDates(arr);
  }

  return (
    <>
      <div className="paper padding-40 radius-6">
        <h2>Course info</h2>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={6}>
            <TextField
              type="text"
              label="Course title"
              variant="outlined"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true
              }}
              value={course.Title}
              onChange={e => {
                let val = e.target.value;
                setCourse(perv => new CourseModel({ ...perv, Title: val }));
                props.titleChanged(val || '');
                setIsDirty(true);
              }}
              error={errors['title']}
              onBlur={() => validateFields('title')}
              onFocus={() => validateFields('title', true)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              type="text"
              label="Max registrations"
              variant="outlined"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true
              }}
              value={course.MaxRegistrations}
              onChange={e => {
                let val = Number(e.target.value);
                if (Number.isNaN(val)) return;
                setCourse(prev => new CourseModel({ ...prev, MaxRegistrations: val }));
                setIsDirty(true);
              }}
              error={errors['maxRegistrations']}
              onBlur={() => validateFields('maxRegistrations')}
              onFocus={() => validateFields('maxRegistrations', true)}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={3}>
            <TextField
              label="Organizer"
              variant="outlined"
              fullWidth
              margin="dense"
              select
              value={course.CoursePartnerId}
              onChange={e => {
                let val = e.target.value;
                setCourse(prev => new CourseModel({ ...prev, CoursePartnerId: val }));
                setIsDirty(true);
              }}
              error={errors['coursePartnerId']}
              onBlur={() => validateFields('coursePartnerId')}
              onFocus={() => validateFields('coursePartnerId', true)}
            >
              {coursePartners.map(x => {
                return <MenuItem key={x.Id} value={x.Id}>{x.Name} </MenuItem>
              })}
            </TextField>
          </Grid>
          <Grid item xs={3}>
            <TextField
              type="text"
              label="Course price incl vat"
              variant="outlined"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true
              }}
              value={course.ValueInLocalCurrency}
              onChange={e => {
                let val = Number(e.target.value);
                if (Number.isNaN(val)) return;
                setCourse(prev => new CourseModel({ ...prev, ValueInLocalCurrency: val }));
                setIsDirty(true);
              }}
              error={errors['valueInLocalCurrency']}
              onBlur={() => validateFields('valueInLocalCurrency')}
              onFocus={() => validateFields('valueInLocalCurrency', true)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              type="text"
              label="Hours of payment"
              variant="outlined"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true
              }}
              value={Math.round(course.WorkingMinutesToTakeAsPayment / 60)}
              onChange={e => {
                let val = Number(e.target.value);
                if (Number.isNaN(val)) return;
                setCourse(prev => new CourseModel({ ...prev, WorkingMinutesToTakeAsPayment: 60 * val }));
                setIsDirty(true);
              }}
              error={errors['workingMinutesToTakeAsPayment']}
              onBlur={() => validateFields('workingMinutesToTakeAsPayment')}
              onFocus={() => validateFields('workingMinutesToTakeAsPayment', true)}
            />
          </Grid>
        </Grid>

        <RadioGroup aria-label="Gender" name="terms" value={terms} onChange={e => { setTerms(e.target.value as 'existingTerms' | 'customTerms'); setIsDirty(true); }}>
          <FormControlLabel
            value="existingTerms"
            color="primary"
            control={<Radio color="primary" />}


            label={course?.CoursePartnerId != null && course?.CoursePartnerId !== '' ? "Use default " + coursePartners.find(x => x.Id === course?.CoursePartnerId)?.Name + "'s default terms" : 'Use default terms'}
            onClick={() => validateFields('customTerms', true)}
          />
          <FormControlLabel
            value="customTerms"
            control={<Radio color="primary" />}
            label="New custom terms for this course"
          />
        </RadioGroup>
        {
          terms === 'customTerms' &&
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={9}>
              <TextField
                multiline
                type="text"
                label="New terms"
                variant="outlined"
                fullWidth
                rows="4"
                margin="dense"
                InputLabelProps={{
                  shrink: true
                }}
                value={customTerms}
                onChange={e => {
                  let val = e.target.value;
                  if (val.length > 500) return;
                  setCustomTerms(e.target.value);
                  setIsDirty(true);
                }}
                error={errors['customTerms']}
                onBlur={() => validateFields('customTerms')}
                onFocus={() => validateFields('customTerms', true)}
              />
              {customTerms.length}/500
            </Grid>
          </Grid>
        }

        <h2>Address</h2>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={3}>
            <TextField
              type="text"
              label="Address"
              variant="outlined"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true
              }}
              value={course.Address}
              onChange={e => {
                let val = e.target.value;
                setCourse(prev => new CourseModel({ ...prev, Address: val }));
                setIsDirty(true);
              }}
              error={errors['address']}
              onBlur={() => validateFields('address')}
              onFocus={() => validateFields('address', true)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              type="text"
              label="Zip"
              variant="outlined"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true
              }}
              value={course.Zip}
              onChange={e => {
                let val = e.target.value;
                setCourse(prev => new CourseModel({ ...prev, Zip: val }));
                setIsDirty(true);
              }}
              error={errors['zip']}
              onBlur={() => validateFields('zip')}
              onFocus={() => validateFields('zip', true)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              type="text"
              label="City"
              variant="outlined"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true
              }}
              value={course.City}
              onChange={e => {
                let val = e.target.value;
                setCourse(prev => new CourseModel({ ...prev, City: val }));
                setIsDirty(true);
              }}
              error={errors['city']}
              onBlur={() => validateFields('city')}
              onFocus={() => validateFields('city', true)}
            />
          </Grid>
        </Grid>


        <Divider className="divider-20" />
        <h2>Course duration</h2>

        {renderCourseDays()}

        <Button size="medium" onClick={() => {
          let dateArr = courseDates.map(x => x);
          let lastDate = dateArr[dateArr.length - 1];
          dateArr.push(new CourseDateModel(lastDate))
          setCourseDates(dateArr);
        }}>
          <span className="btn-icon"><i className="fas fa-plus"></i></span>
          Add date
      </Button>
      </div>
      <SavingSnackbar open={isDirty} saveClicked={saveData} resetClicked={preparePage} />
    </>
  )
}