import React, { useEffect, useState } from "react";
import { getLanguage } from "../../../../../../Helpers/LanguageHelper";
import { MobileSidebarTrigger } from "../../../../../Parts/General/MobileSidebarTrigger";
import { InvoiceHelper } from "../../../../../../Helpers/InvoiceHelper";
import DatePicker from "react-datepicker";
import {
    Button,
    Grid, TextField, Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@material-ui/core";
import { CalendarLanguages } from "../../../../../../Common/CalendarLanguages";
import { getMoneyOutput } from "../../../../../../Helpers/LocalizationHelper";
import { RequestModel } from "../../../../../../Models/RequestModel";
import { RequestHelper } from "../../../../../../Helpers/RequestHelper";

export function CompanySettingsInvoices() {
    const [invoices, setInvoices] = useState<any[] | null>(null);
    const [futureRequests, setFutureRequests] = useState<RequestModel[] | null>(null);
    const [uniqueDatesForFutureRequests, setUniqueDatesForFutureRequests] = useState<Date[]>([]);
    const [costForRequests, setCostForRequests] = useState<any[]>([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [viewMode, setViewMode] = useState("archive");

    const invoiceHelper = new InvoiceHelper();
    const requestHelper = new RequestHelper();

    useEffect(() => {
        getAllInvoices();

        // Set startDate to today minus 1 month
        const start = new Date();
        start.setMonth(start.getMonth() - 1);
        setStartDate(start);

    }, []);

    const switchViewMode = () => {
        if (viewMode === "archive") {
            setViewMode("future");
            if (futureRequests === null) {
                getFutureRequests();
            }
        } else {
            setViewMode("archive");
        }
    }

    const getFutureRequests = async () => {
        const futureRequests = await requestHelper.getFutureRequests();
        setFutureRequests(futureRequests);

        getCostForRequests(futureRequests);
    }

    const getCostForRequests = async (requests: RequestModel[]) => {
        //Get every unique date in futureRequests, using the date part of EndDateTimeLocal
        const uniqueDates = [...new Set(requests.map((request) => {
            const date = new Date(request.EndDateTime!);
            return (new Date(date.getFullYear(), date.getMonth(), date.getDate())).toString();
        }))];

        setUniqueDatesForFutureRequests(uniqueDates.map((date) => new Date(date)));

        const costs = [];
        // Get cost totals per day
        for (const date of uniqueDates) {
            // Get every request ID where EndDateTime is on from 5:00 to 5:00 the next day
            const requestIds = requests.filter((request) => {
                const requestDate = new Date(request.EndDateTime!);
                let finalEndDate = new Date(requestDate.getFullYear(), requestDate.getMonth(), requestDate.getDate());
                // Make sure finalEndDate is always the coming day at 5:00
                if (requestDate.getHours() < 5) {
                    finalEndDate.setDate(finalEndDate.getDate() - 1);
                }

                return (new Date(requestDate.getFullYear(), requestDate.getMonth(), requestDate.getDate())).toString() === date;
            }).map((request) => request.Id);

            const cost = await requestHelper.getCostForRequests(requestIds);
            cost["date"] = date;
            cost["countRequests"] = requestIds.length;
            costs.push(cost);
        }

        setCostForRequests(costs);
    }

    const getAllInvoices = async () => {
        const allInvoices = await invoiceHelper.getAllInvoices();
        if (allInvoices) {
            setInvoices(allInvoices);
        }
    }

    const downloadPdf = (invoice: any) => async () => {
        const pdf = await invoiceHelper.downloadInvoicePdf(invoice.ExternalId);

        if (pdf) {
            const dataUri = `data:application/pdf;base64,${pdf}`;

            const iframe = document.createElement('iframe');
            iframe.src = dataUri;
            iframe.style.width = '100%';
            iframe.style.height = '100%';

            const newTab = window.open('', '_blank');
            if (newTab) {
                newTab.document.body.appendChild(iframe);
            } else {
                alert('Please allow pop-ups to view the PDF.');
            }
        }
    }

    const downloadCsv = (invoice: any) => async () => {
        const csv = await invoiceHelper.downloadInvoiceCsv([invoice.ExternalId]);

        if (csv) {
            const blob = new Blob([csv], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = invoice.ExternalId + ".csv";
            a.click();
            window.URL.revokeObjectURL(url);
        }
    }

    const downloadAllCsv = () => async () => {
        const filtered = invoices?.filter(
            (invoice) => {
                const invoiceDate = new Date(invoice.Created);
                return invoiceDate >= startDate && invoiceDate <= endDate;
            }
        ) ?? [];
        const csv = await invoiceHelper.downloadInvoiceCsv(filtered?.map((invoice) => invoice.ExternalId));

        if (csv) {
            const blob = new Blob([csv], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = "invoices.csv";
            a.click();
            window.URL.revokeObjectURL(url);
        }
    }

    const formatDate = (date: Date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${day}/${month}/${year}`;
    }

    const calendarLanguage = CalendarLanguages.Instance.getCalendarLanguage();

    const getDateSelectionPart = () => {
        return <>
            <MobileSidebarTrigger />
            <h1 className="inline-block">{getLanguage(924, "Invoices")}</h1>
            <div>
                {viewMode === "archive" && <Button style={{ marginTop: "8px" }} onClick={() => switchViewMode()}>{getLanguage(933, "See upcoming expenses")}</Button>}
                {viewMode === "future" && <Button style={{ marginTop: "8px" }} onClick={() => switchViewMode()}>{getLanguage(934, "See invoices")}</Button>}
                {viewMode === "archive" && <hr />}
            </div>
            <Grid container spacing={3}>
                <Grid item lg={4}>
                    {viewMode === "archive" && <DatePicker
                        dateFormat="dd/MM/yyyy"
                        customInput={<TextField variant="outlined" fullWidth label={getLanguage(925, "From date")} margin="dense" />}
                        locale={calendarLanguage}
                        selected={startDate}
                        onChange={(date) => setStartDate(date as Date)}
                        calendarClassName="Chabber_Calendar editPostingCalendar"
                        withPortal
                    />}
                </Grid>
                <Grid item lg={4}>
                    {viewMode === "archive" && <DatePicker
                        dateFormat="dd/MM/yyyy"
                        customInput={<TextField variant="outlined" fullWidth label={getLanguage(926, "To date")} margin="dense" />}
                        locale={calendarLanguage}
                        selected={endDate}
                        onChange={(date) => setEndDate(date as Date)}
                        calendarClassName="Chabber_Calendar editPostingCalendar"
                        withPortal
                    />}
                </Grid>
            </Grid>
            <br />
        </>
    }

    const getFutureInvoiceLine = (text: string, unitPrice: string, units: string, total: string) => {
        // Check that at least "total" is a number and not zero
        if (total === "" || Number(total) === 0) return null;

        // Convert unitPrice, units and total to numbers, if they are not empty
        const unitPricenumber = unitPrice !== "" ? Number(unitPrice) / 100.0 : null;
        const unitsNumber = units !== "" ? Number(units) : null;
        const totalNumber = total !== "" ? Number(total) / 100.0 : null;

        return <TableRow>
            <TableCell className="tableCell" align="left">
                {text}
            </TableCell>
            <TableCell className="tableCell" align="right">
                {unitPricenumber && getMoneyOutput(
                    unitPricenumber.toFixed(2),
                    false,
                    null,
                    true
                )}
            </TableCell>
            <TableCell className="tableCell" align="right">
                {unitsNumber && unitsNumber.toFixed(0)}

            </TableCell>
            <TableCell className="tableCell" align="right">
                {totalNumber && getMoneyOutput(
                    totalNumber.toFixed(2),
                    false,
                    null,
                    true
                )}
            </TableCell>
        </TableRow>;
    };

    return (
        <div>
            <div className="padding-v-32 ">
                {getDateSelectionPart()}

                {viewMode === "archive" && <>
                    {invoices === null &&
                        <div>
                            <div className="padding-v-32 ">
                                <h3>{getLanguage(401, "Loading")}</h3>
                            </div>
                        </div>
                    }

                    {invoices !== null && invoices.filter(
                        (invoice) => {
                            const invoiceDate = new Date(invoice.Created);
                            return invoiceDate >= startDate && invoiceDate <= endDate;
                        }
                    ).length === 0 && <h3>{getLanguage(930, "No invoices found")}</h3>}

                    {invoices !== null && invoices.filter(
                        (invoice) => {
                            const invoiceDate = new Date(invoice.Created);
                            return invoiceDate >= startDate && invoiceDate <= endDate;
                        }
                    ).length > 0 &&
                        <>
                            <Table className="table">
                                <TableHead className="tableHead">
                                    <TableRow className="tableRow">
                                        <TableCell align="center">{getLanguage(927, "Invoice number")}</TableCell>
                                        <TableCell>{getLanguage(183, "Date")}</TableCell>
                                        <TableCell align="right">{getLanguage(928, "Total")}</TableCell>
                                        <TableCell>{getLanguage(929, "Download PDF")}</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody className="tableBody">
                                    {invoices.sort((a, b) => new Date(b.Created).getTime() - new Date(a.Created).getTime()).filter(
                                        (invoice) => {
                                            const invoiceDate = new Date(invoice.Created);
                                            return invoiceDate >= startDate && invoiceDate <= endDate;
                                        }
                                    ).map((invoice) => {
                                        return (
                                            <TableRow
                                                key={invoice.ExternalId}
                                            >
                                                <TableCell className="tableCell" align="center">{invoice.ExternalId}
                                                </TableCell>
                                                <TableCell className="tableCell">
                                                    {formatDate(new Date(invoice.Created))}
                                                </TableCell>
                                                <TableCell className="tableCell" align="right">{getMoneyOutput(
                                                    (invoice.InvoiceTotal / 100).toFixed(2),
                                                    false,
                                                    null,
                                                    true
                                                )}
                                                </TableCell>
                                                <TableCell className="tableCell">
                                                    <span style={{ cursor: "pointer", fontWeight: "bold" }} onClick={downloadPdf(invoice)}>{getLanguage(931, "PDF")}</span>&nbsp; &nbsp;
                                                    <span style={{ cursor: "pointer", fontWeight: "bold" }} onClick={downloadCsv(invoice)}>{getLanguage(932, "CSV")}</span>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                            <Button style={{ marginTop: "8px" }} onClick={downloadAllCsv()}>{getLanguage(950, "Download all (CSV)")}</Button>
                        </>
                    }
                </>}

                {viewMode === "future" && <>
                    {futureRequests === null &&
                        <div>
                            <div className="padding-v-32 ">
                                <h3>{getLanguage(401, "Loading")}</h3>
                            </div>
                        </div>
                    }

                    {futureRequests !== null && futureRequests.length === 0 && <h3>{getLanguage(935, "No upcoming expenses in period")}</h3>}

                    {futureRequests !== null && futureRequests.length > 0 && uniqueDatesForFutureRequests.length > 0 && uniqueDatesForFutureRequests.map((date) => {
                        // Get cost for this date
                        const cost = costForRequests.find((cost) => cost.date === date.toString());

                        if (cost === undefined) return null;

                        // Create date object from date string, added with three days (to get the date of the invoice)
                        const invoiceDate = new Date(date);
                        invoiceDate.setDate(invoiceDate.getDate() + 3);

                        // Get count of requests for date


                        return <div key={date.toISOString()}>
                            <h3>{formatDate(invoiceDate)}</h3>
                            <Table className="table">
                                <colgroup>
                                    <col style={{ width: '40%' }} />
                                    <col style={{ width: '20%' }} />
                                    <col style={{ width: '20%' }} />
                                    <col style={{ width: '20%' }} />
                                </colgroup>
                                <TableHead className="tableHead">
                                    <TableRow className="tableRow">
                                        <TableCell align="left">{getLanguage(70, "Description")}</TableCell>
                                        <TableCell align="right">{getLanguage(730, "Unit price")}</TableCell>
                                        <TableCell align="right">{getLanguage(729, "Amount")}</TableCell>
                                        <TableCell align="right">{getLanguage(731, "Total")}</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody className="tableBody">
                                    {getFutureInvoiceLine(`(${cost["countRequests"]}) ${getLanguage(732, "Salary incl. social costs")}`, cost["salaryUnitPrice"], cost["salaryAmount"], cost["salaryTotal"])}
                                    {getFutureInvoiceLine(getLanguage(734, "Hourly fee"), cost["hourlyFeeUnitPrice"], cost["hourlyFeeAmount"], cost["hourlyFeeTotal"])}
                                    {getFutureInvoiceLine(getLanguage(726, "Start fee"), cost["startFeeUnitPrice"], cost["startFeeAmount"], cost["startFeeTotal"])}
                                    {getFutureInvoiceLine(getLanguage(725, "Cancel insurance"), cost["cancelInsuranceFeeUnitPrice"], cost["cancelInsuranceFeeAmount"], cost["cancelInsuranceFeeTotal"])}
                                    {getFutureInvoiceLine(getLanguage(735, "Total ex. VAT"), "", "", cost["totalExVat"])}
                                    {getFutureInvoiceLine(getLanguage(854, "Tax"), "", "", cost["vat"])}
                                    {getFutureInvoiceLine(getLanguage(855, "Total incl. VAT"), "", "", cost["totalInclVat"])}
                                </TableBody>
                            </Table>
                        </div>
                    }
                    )}
                </>}
            </div>
        </div >
    );
}
