import React, { Component } from 'react';
import { Dialog } from "@material-ui/core";
import { FreelancerProfile } from "../../../Freelancer/FreelancerProfile/FreelancerProfile";
import { RequestModel } from '../../../../../../Models/RequestModel';
import { LoginModel } from '../../../../../../Models/LoginModel';
import { FreelancerModel } from '../../../../../../Models/FreelancerModel';

interface IProps {
  open: boolean;
  request: RequestModel | undefined;
  freelancer: FreelancerModel | undefined;
  handleClose: () => void;
}
interface IState { }

export class FreelancerDialogue extends Component<IProps, IState> {

  render() {
    let { freelancer } = this.props;
    let login: LoginModel | null = freelancer ? freelancer.Login : null;
    if (login && freelancer) login.Freelancer = freelancer; //FIXME: This is haxy

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
        scroll="body"
      >
        <FreelancerProfile
          context={'fromFeedback'}
          request={this.props.request ? this.props.request : undefined}
          loginProfile={login}
        />
      </Dialog>

    )
  }
}