import React, { Component } from 'react';
import { withSnackbar, WithSnackbarProps, OptionsObject, VariantType } from 'notistack';

interface IProps extends WithSnackbarProps { }
interface IState { }

export class SnackbarManager extends Component<IProps, IState> {
  private static instance: SnackbarManager;
  public static get Instance(): SnackbarManager {
    if (!SnackbarManager.instance) {
      // @ts-ignore
      SnackbarManager.instance = new SnackbarManager({});
    }
    return SnackbarManager.instance;
  }

  constructor(props: IProps) {
    super(props);
    SnackbarManager.instance = this;
  }

  addDefault(message: string) {
    this.addSnackbar(message, 'default');
  }
  addSuccess(message: string) {
    this.addSnackbar(message, 'success');
  }
  addError(message: string) {
    this.addSnackbar(message, 'error');
  }
  addWarning(message: string) {
    this.addSnackbar(message, 'warning');
  }

  createSnackbar(message: string, options: OptionsObject) {
    this.props.enqueueSnackbar(message, options);
    setTimeout(() => {
      this.props.closeSnackbar(options.key);
    }, 2000);
  }

  private addSnackbar(message: string, variant: VariantType, ) {
    let key = Math.random().toString(36);

    let options: OptionsObject = {
      key,
      variant, // snackbar type
      persist: true,
    }

    this.createSnackbar(message, options);
  }


  render() {
    return (<div></div>)
  }
}

export default withSnackbar(SnackbarManager);