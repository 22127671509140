import React from 'react';
import { CourseModel } from '../../../../../Models/CourseModel';
import { TextField, MenuItem } from '@material-ui/core';
import { getLanguage } from '../../../../../Helpers/LanguageHelper';
import { dateAsFormatedStringWithoutTimeDK } from '../../../../../Helpers/DateTimeHelper';

interface IProps {
  disabled: boolean;
  courses: CourseModel[];
  selectedCourse: string;
  changeCourse: (str: string) => void;
}

export function CourseSelector(props: IProps) {
  return (
    <div>
      <TextField
        select
        fullWidth
        variant='outlined'
        value={props.selectedCourse || 42}
        onChange={e => props.changeCourse(e.target.value)}
        disabled={props.disabled}

      >
        <MenuItem key={42} value={42} disabled>{'- ' + getLanguage(784, 'Please choose a date') + ' -'}</MenuItem>
        {props.courses.map(x => {
          let dates = x.Title + ': ';
          for (const date of x.CourseDates) {

            dates += '[' + dateAsFormatedStringWithoutTimeDK(date.Date) + ', ' + date.StartAtUTC + '-' + date.EndAtUTC + ']';
          }

          return <MenuItem key={x.Id} value={x.Id}>{dates}</MenuItem>
        })}
      </TextField>

    </div>
  )
}