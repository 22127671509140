import 'react-app-polyfill/ie9'
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { UnsupportedBrowser } from './Components/Parts/General/UnsupportedBrowser';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { detect } from "detect-browser";
import App from "./App";
import { QueryClient, QueryClientProvider } from 'react-query';

Sentry.init({
  dsn: "https://5f03e730fbce40e6b6433514e24b47cf@o1413091.ingest.sentry.io/6756478",
  integrations: [new BrowserTracing()],
  ignoreErrors: [/Unexpected token '<'/], //cookiebot
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
const browser = detect();

function isVersionAcceptable(browser: { version: string }, minimumVersion: number) {
  let version = Number(browser.version.split('.')[0]);
  return version >= minimumVersion;
}

let canRender = false;
if (browser) {
  if (browser.name === 'ie') {
    canRender = false;
  } else if (browser.name === 'edge') {
    canRender = isVersionAcceptable(browser, 15);
  } else if (browser.name === 'firefox') {
    canRender = isVersionAcceptable(browser, 52);
  } else if (browser.name === 'safari') {
    canRender = isVersionAcceptable(browser, 11);
  } else if (browser.name === 'chrome') {
    canRender = isVersionAcceptable(browser, 55);
  } else {
    canRender = true; //FIXME: what do we do in other browsers?
  }
}
let app;
const queryClient = new QueryClient()
if (canRender) {
  // @ts-ignore
  app = <QueryClientProvider client={queryClient}><App /></QueryClientProvider>
} else {
  app = <UnsupportedBrowser browser={browser} />
}
ReactDOM.render(app, document.getElementById("root"));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
