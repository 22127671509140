import { AxiosRequestConfig } from 'axios';
import { CallManager } from './CallManager/CallManager';

export class SkillHelper {
  async getAll() {
    try {
      let config: AxiosRequestConfig = {
        method: 'GET',
        url: '/api/skill',
        withCredentials: true
      }
      let skills = await CallManager.Instance.makeCall(config, false, false);
      return skills.data;
    } catch (error: any) {
      return false;
    }
  }
}