// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheckimport React, { Component } from "react";
import {
  Grid,
  Button,
  Dialog,
  Slide,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
} from "@material-ui/core";
import Tabs from "../../../../Parts/General/Tabs";
import TabContent from "../../../../Parts/General/TabContent";
import {
  getPriceForProduct,
  getMoneyOutput,
} from "../../../../../Helpers/LocalizationHelper";

import { ApplicantProfile } from "../ApplicantProfile";
import { getLanguage } from "../../../../../Helpers/LanguageHelper";
import { RouteComponentProps } from "react-router-dom";
import { FreelancerProfile } from "../../Freelancer/FreelancerProfile/FreelancerProfile";
import { PostingModel } from "../../../../../Models/PostingModel";
import { StorageHelper } from "../../../../../Helpers/StorageHelper";
import { PostingHelper } from "../../../../../Helpers/PostingHelper";
import { RequestModel } from "../../../../../Models/RequestModel";
import { CompanyHelper } from "../../../../../Helpers/CompanyHelper";
import { RequestHelper } from "../../../../../Helpers/RequestHelper";
import { UserHelper } from "../../../../../Helpers/UserHelper";
import { PageLoader } from "../../../../Parts/General/PageLoader";
import { LoginModel } from "../../../../../Models/LoginModel";
import { CompetenceQuestionModel } from "../../../../../Models/CompetenceQuestionModel";
import { SavingSnackbar } from "../../../../Parts/General/SavingSnackbar";
import { Checkout } from "../Checkout";
import { FreelancerModel } from "../../../../../Models/FreelancerModel";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import { getDateObjectFromDateString } from "../../../../../Helpers/StringExtractorHelper";
import { ChatHelper } from "../../../../../Helpers/ChatHelper";
import { getCurrentUser } from "../../../../../Helpers/SessionHelper";
import { SnackbarManager } from "../../../../../Helpers/SnackbarManager/SnackbarManager";
import { ReopenPostingDialog } from "../../../../Parts/Posting/ReopenPostingDialog";
import { FavoriteProfileModel } from "../../../../../Models/FavoriteProfileModel";
import React, { Component } from "react";
import { CheckoutV2 } from "../CheckoutV2";
import { convertDistance, getDistance } from "geolib";

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    return <Slide direction="up" ref={ref} children={<>{props.children}</>} />;
  }
);

interface IProps extends RouteComponentProps {
  posting: PostingModel;
  favourites: FavoriteProfileModel[];
  postingApplicantsUpdated: () => void;
}

const getDistanceToPosting = (
  posting?: PostingModel | null,
  freelancer?: FreelancerModel | null
) => {
  if (!posting || !freelancer) return null; //defensive approach, some objects don't match typescript types
  const postingLocation = {
    lat: posting.Latitude,
    lon: posting.Longitude,
  };
  const freelancerLocation = {
    lat: freelancer.Latitude,
    lon: freelancer.Longitude,
  };
  const distance = convertDistance(
    getDistance(postingLocation, freelancerLocation),
    "km"
  ).toFixed(1);
  return distance;
};

interface IState {
  // chatDrawerOpen: boolean;
  freelancerDialogueOpen: boolean;
  checkoutOpen: boolean;
  cancelDialogOpen: boolean;
  activePosting: PostingModel;
  updatedActivePost: PostingModel | null;
  day: string;
  requests: RequestModel[];
  freelancersNotConfirmed:
    | {
        Request: RequestModel;
        Selected: boolean;
        Confirmed: boolean;
        PreviouslyHired: boolean;
      }[]
    | null;
  freelancersConfirmed:
    | {
        Request: RequestModel;
        Selected: boolean;
        Confirmed: boolean;
        PreviouslyHired: boolean;
      }[]
    | null;
  showPageLoader: boolean;
  activeLogin: LoginModel;
  competenceQuestions: CompetenceQuestionModel[];
  selectedRequests: {
    Request: RequestModel;
  }[];
  openCheckout: boolean;
  hours: number;
  minutes: number;
  activeSelected: boolean;
  requestIdsToConfirmWith: string[];
  amountOfApplicants: number;
  chabberIdToDelete: string;
  activeTab: number;
  activeLoginIsPreviouslyHired: boolean;
  previouslyHired: {
    Id: string;
    Login: {
      Id: string;
      Firstnames: string;
      LastName: string;
      ProfilePictureUrl: string;
    };
    LoginId: string;
    RatingsAverage: number;
  }[];
  activeFreelancer: FreelancerModel;
  activeRequest: RequestModel;
  loginIdsToConfirm: string[];
  unreadMessages: number;
  messageNetworkId: string;
  applicants: HTMLElement | null;
  openMenu: boolean;
  anchorEl: HTMLElement | null;
  openPreviewDialog: boolean;
  CSV: string;
  wholeDayCSV: boolean;
  jobHasEnded: boolean;
  savingSnackbarOpen: boolean;
  displayReopenPostingDialog: boolean;
}

export class ActivePosting extends Component<IProps, IState> {
  storageHelper = new StorageHelper();
  postingHelper = new PostingHelper();
  companyHelper = new CompanyHelper();
  userHelper = new UserHelper();
  requestHelper = new RequestHelper();

  constructor(props: IProps) {
    super(props);

    this.state = {
      // chatDrawerOpen: false,
      freelancerDialogueOpen: false,
      checkoutOpen: false,
      cancelDialogOpen: false,
      activePosting: this.props.posting, //FIXME: Assigning from props to state is generally a bad practice
      updatedActivePost: null,
      day: "",
      requests: [],
      freelancersNotConfirmed: null,
      freelancersConfirmed: null,
      showPageLoader: false,
      activeLogin: new LoginModel({}),
      competenceQuestions: [],
      selectedRequests: [],
      openCheckout: false,
      hours: 0,
      minutes: 0,
      activeSelected: false,
      requestIdsToConfirmWith: [],
      amountOfApplicants: 0,
      chabberIdToDelete: "",
      activeTab: 0,
      activeLoginIsPreviouslyHired: false,
      previouslyHired: [],
      activeFreelancer: new FreelancerModel({}),
      activeRequest: new RequestModel({}),
      loginIdsToConfirm: [],
      unreadMessages: 0,
      messageNetworkId: "",
      applicants: null,
      openMenu: false,
      anchorEl: null,
      openPreviewDialog: false,
      CSV: "",
      wholeDayCSV: false,
      jobHasEnded: false,
      savingSnackbarOpen: false,
      displayReopenPostingDialog: false,
    };

    this.chabberSelected = this.chabberSelected.bind(this);
    this.confirmSelectedChabbers = this.confirmSelectedChabbers.bind(this);
    this.deleteChabberRequestOrCancelChabberIfConfirmed =
      this.deleteChabberRequestOrCancelChabberIfConfirmed.bind(this);
    this.cancelCheckout = this.cancelCheckout.bind(this);
    this.preparePage = this.preparePage.bind(this);
    this.reloadPosting = this.reloadPosting.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.cancelConfirmedChabber = this.cancelConfirmedChabber.bind(this);
    this.beginCancelChabber = this.beginCancelChabber.bind(this);
    this.applicants = this.applicants.bind(this);
    this.confirmed = this.confirmed.bind(this);
    this.activeTab = this.activeTab.bind(this);
    this.loadPageIn = this.loadPageIn.bind(this);
    this.reopenPostingClicked = this.reopenPostingClicked.bind(this);
    this.closeReopenPostingDialog = this.closeReopenPostingDialog.bind(this);
    this.freelancersConfirmed = this.freelancersConfirmed.bind(this);
  }

  async componentDidMount() {
    this.preparePage();
  }

  async preparePage(comingFromReloadPage?: boolean) {
    await this.setState({ showPageLoader: true });
    let posting: PostingModel = this.state.activePosting;

    if (
      (!posting.StartAtLocalObject || !posting.EndAtLocalObject) &&
      posting.StartAtLocal &&
      posting.EndAtLocal
    ) {
      posting.StartAtLocalObject = getDateObjectFromDateString(
        posting.StartAtLocal
      );
      posting.EndAtLocalObject = getDateObjectFromDateString(
        posting.EndAtLocal
      );
    }

    let days: string[] = [];
    for (let i = 8; i <= 14; i++) {
      days.push(getLanguage(i - 1));
    }

    let dateString = new Date(posting.StartAtLocal);
    let dayIndex = dateString.getDay();

    let requests: RequestModel[] =
      await this.requestHelper.getAllRequestsForPosting(posting.Id);
    let previouslyHired = await this.companyHelper.searchPreviouslyHired("");

    await this.setState({ previouslyHired });

    let freelancersNotConfirmed = [];
    let freelancersConfirmed = [];

    for (const request of requests) {
      if (request) {
        if (request.FreelancerProfile && request.FreelancerProfile.Login) {
          let isPreviouslyHired = false;
          isPreviouslyHired = await this.previouslyHiredCheck(
            request.FreelancerProfile.Id
          );

          //TODO::Hack so we don't need to make another api call. Our request is missing FreelancerProfile from Login. When we start on API remember to fix this.
          request.FreelancerProfile.Login.FreelancerProfile =
            request.FreelancerProfile;

          let RequestAndConfirmation = {
            Request: request,
            Selected: false,
            Confirmed: false,
            PreviouslyHired: isPreviouslyHired,
          };

          if (request.RequestStatus === 0) {
            freelancersNotConfirmed.push(RequestAndConfirmation);
          } else if (request.RequestStatus === 3) {
            RequestAndConfirmation.Selected = false;
            RequestAndConfirmation.Confirmed = true;
            freelancersConfirmed.push(RequestAndConfirmation);
          }
        }
      }
    }

    let activeTab =
      (this.state.activePosting &&
        this.state.activePosting.AmountHired >=
          this.state.activePosting.AmountNeededWithBuffer) ||
      (this.state.activePosting && this.state.activePosting.ClosedByCompany)
        ? 1
        : 0;

    let amountOfApplicants = freelancersNotConfirmed.length;

    let competenceQuestions =
      this.storageHelper.getCompetenceQuestionsFromLocal();
    if (competenceQuestions == null) return;

    if (
      comingFromReloadPage &&
      posting.AmountHired >= posting.AmountNeededWithBuffer
    ) {
      freelancersNotConfirmed = [];
    }

    let dateNow = new Date();
    let endAtDate = new Date(posting!.EndAtLocal);
    if (endAtDate < dateNow) {
      await this.setState({ jobHasEnded: true });
    }

    await this.setState({
      activePosting: posting,
      day: days[dayIndex],
      requests,
      freelancersNotConfirmed,
      freelancersConfirmed,
      competenceQuestions,
      amountOfApplicants,
      activeTab,
      showPageLoader: false,
    });
  }

  async previouslyHiredCheck(freelancerId: string) {
    if (this.state.previouslyHired && this.state.previouslyHired.length > 0) {
      for (const prev of this.state.previouslyHired) {
        if (prev.Id === freelancerId) {
          return true;
        }
      }
    }
    return false;
  }

  async reloadPosting(hiringChabbers?: boolean) {
    let confirmed = this.state.selectedRequests;
    let confirmedChabbers = this.state.freelancersConfirmed;
    if (confirmedChabbers) {
      for (const i of confirmed) {
        let freelancer = {
          Request: i.Request,
          Selected: false,
          Confirmed: true,
          PreviouslyHired: false,
        };
        confirmedChabbers.push(freelancer);
      }
    }

    if (hiringChabbers) {
      let messageLanguageCode =
        confirmedChabbers && confirmedChabbers.length > 1 ? 333 : 334;
      SnackbarManager.Instance.addSuccess(
        getLanguage(messageLanguageCode, "Chabbers hired")
      );
      this.setState({ activeTab: 1 });
    }

    await this.setState({
      freelancersConfirmed: confirmedChabbers,
      checkoutOpen: false,
      selectedRequests: [],
      showPageLoader: true,
    });
    if (
      this.state.activePosting &&
      confirmedChabbers &&
      confirmedChabbers.length ===
        this.state.activePosting.AmountNeededWithBuffer
    ) {
      await this.setState({ freelancersNotConfirmed: [] });
    }

    if (confirmed && confirmed.length > 0) {
      let activePosting = this.state.activePosting;
      activePosting.AmountHired += confirmed.length;
      this.setState({ activePosting });
    }

    this.preparePage(true);
  }

  async handleClickOpen(
    requestWithSelectedBoolean: {
      Request: RequestModel;
      Selected: boolean;
      Confirmed: boolean;
      PreviouslyHired: boolean;
    } | null
  ) {
    let login: LoginModel = new LoginModel({});
    let activeFreelancer = new FreelancerModel({});
    let activeRequest = new RequestModel({});

    let previouslyHired = false;
    if (
      requestWithSelectedBoolean &&
      requestWithSelectedBoolean.Request.FreelancerProfile &&
      requestWithSelectedBoolean.Request.FreelancerProfile.Login
    ) {
      login = requestWithSelectedBoolean.Request.FreelancerProfile.Login;
      activeFreelancer = requestWithSelectedBoolean.Request.FreelancerProfile;
      previouslyHired = requestWithSelectedBoolean.PreviouslyHired;
      activeRequest = requestWithSelectedBoolean.Request;
    }

    await this.setState({
      activeRequest,
      activeFreelancer,
      freelancerDialogueOpen: true,
      activeLogin: login,
      activeLoginIsPreviouslyHired: previouslyHired,
      activeSelected: requestWithSelectedBoolean!.Selected,
    });
  }

  async chabberSelected(event: any, freelancerId: string) {
    event.stopPropagation();
    let freelancersNotConfirmed = this.state.freelancersNotConfirmed;

    if (freelancersNotConfirmed) {
      for (const i of freelancersNotConfirmed) {
        if (
          i.Request.FreelancerProfile &&
          i.Request.FreelancerProfile.Id === freelancerId
        ) {
          i.Selected = i.Selected ? false : true;
        }
      }

      await this.setState({
        freelancersNotConfirmed,
        savingSnackbarOpen: true,
      });
    }

    let requestsWithFreelancersSelected = this.state.selectedRequests;

    if (
      requestsWithFreelancersSelected &&
      requestsWithFreelancersSelected.length !== 0
    ) {
      for (let i = 0; i < requestsWithFreelancersSelected.length; i++) {
        let freelancer =
          requestsWithFreelancersSelected[i].Request.FreelancerProfile!;
        if (freelancer && freelancer.Id === freelancerId) {
          requestsWithFreelancersSelected.splice(i, 1);
          return await this.setState({
            selectedRequests: requestsWithFreelancersSelected,
          });
        }
      }
    }

    let freelancers = this.state.freelancersNotConfirmed;
    let freelancer;
    if (freelancers) {
      for (const i of freelancers) {
        if (
          i.Request.FreelancerProfile &&
          i.Request.FreelancerProfile.Id === freelancerId
        ) {
          freelancer = i;
          requestsWithFreelancersSelected.push(freelancer);
          return await this.setState({
            selectedRequests: requestsWithFreelancersSelected,
          });
        }
      }
    }
  }

  async confirmSelectedChabbers() {
    let requests = this.state.requests;
    let selectedChabbers = this.state.selectedRequests;
    let requestIdsToConfirmWith: string[] = [];
    let loginIdsToConfirm: string[] = [];

    if (this.state.activePosting == null) return;
    if (
      requests &&
      requests.length > 0 &&
      selectedChabbers &&
      selectedChabbers.length > 0
    ) {
      for (const i of requests) {
        for (const u of selectedChabbers) {
          if (i && i.FreelancerProfile) {
            if (
              u.Request.FreelancerProfile &&
              i.FreelancerProfile.Id === u.Request.FreelancerProfile.Id
            ) {
              requestIdsToConfirmWith.push(i.Id);
              loginIdsToConfirm.push(i.FreelancerProfile.LoginId);
            }
          }
        }
      }
    }

    let hours: number = Math.floor(
      this.state.activePosting.JobLengthInMinutes / 60
    );
    let minutes: number =
      this.state.activePosting.JobLengthInMinutes - hours * 60;

    await this.setState({
      savingSnackbarOpen: false,
      checkoutOpen: true,
      hours,
      minutes,
      requestIdsToConfirmWith,
      loginIdsToConfirm,
      freelancerDialogueOpen: false,
    });
  }

  cancelCheckout() {
    this.setState({ checkoutOpen: false, savingSnackbarOpen: true });
  }

  async deleteChabberRequestOrCancelChabberIfConfirmed(
    event: any,
    freelancerId: string
  ) {
    event.stopPropagation();
    let requests = this.state.requests;

    for (let i = 0; i < requests.length; i++) {
      if (requests[i] && requests[i].FreelancerProfile) {
        if (requests[i].FreelancerProfile!.Id === freelancerId) {
          this.requestHelper.cancelRequest(requests[i].Id);
          requests.splice(i, 1);
        }
      }
    }

    let freelancerArray = this.state.freelancersNotConfirmed;
    if (freelancerArray) {
      for (let i = 0; i < freelancerArray.length; i++) {
        let freelancer = freelancerArray[i].Request.FreelancerProfile;
        if (freelancer && freelancer.Id === freelancerId) {
          freelancerArray.splice(i, 1);
        }
      }
    }

    let amountOfApplicants = this.state.amountOfApplicants;

    amountOfApplicants = amountOfApplicants - 1;

    //@ts-ignore
    let selectedRequests = this.state.selectedRequests.filter(
      (t: any) => t.Request.FreelancerProfileId !== freelancerId
    );

    this.props.postingApplicantsUpdated();

    SnackbarManager.Instance.addSuccess(
      getLanguage(718, "Chabber application has been deleted from this post.")
    );
    await this.setState({
      freelancerDialogueOpen: false,
      requests,
      freelancersNotConfirmed: freelancerArray,
      amountOfApplicants,
      selectedRequests,
    });
  }

  async beginCancelChabber(event: any, freelancerId: string) {
    event.stopPropagation();
    await this.setState({
      cancelDialogOpen: true,
      chabberIdToDelete: freelancerId,
    });
  }

  async cancelConfirmedChabber(event: any) {
    event.stopPropagation();
    await this.setState({
      freelancerDialogueOpen: false,
      cancelDialogOpen: false,
      showPageLoader: true,
    });
    let requests = this.state.requests;
    let freelancerArray = this.state.freelancersConfirmed;
    let freelancerLoginId = "";
    let postingId = "";
    let success: boolean = false;

    if (freelancerArray) {
      for (let i = 0; i < requests.length; i++) {
        if (requests[i] && requests[i].FreelancerProfile) {
          if (
            requests[i].FreelancerProfile!.Id === this.state.chabberIdToDelete
          ) {
            success = await this.requestHelper.cancelRequest(requests[i].Id);
            freelancerLoginId = requests[i].FreelancerProfile!.LoginId;
            postingId = requests[i].Posting!.Id;

            for (let i = 0; i < freelancerArray.length; i++) {
              let freelancer = freelancerArray[i].Request.FreelancerProfile;
              if (
                freelancer &&
                freelancer.Id === this.state.chabberIdToDelete
              ) {
                freelancerArray.splice(i, 1);
              }
            }
          }
        }
      }
    }

    await this.setState({ showPageLoader: false });

    let activePosting = this.state.activePosting;
    activePosting.AmountHired--;

    if (success) {
      await new ChatHelper().removeChabberFromMessageNetwork(
        freelancerLoginId,
        postingId
      );
      await this.reloadPosting(false);
      SnackbarManager.Instance.addSuccess(
        getLanguage(719, "chabber cancelled")
      );
      this.setState({
        freelancersConfirmed: freelancerArray,
        cancelDialogOpen: false,
        activePosting,
      });
    } else {
      SnackbarManager.Instance.addWarning(
        getLanguage(720, "Chabber could not be cancelled")
      );
      this.setState({
        cancelDialogOpen: false,
        activePosting,
      });
    }
  }

  applicants() {
    if (
      this.state.activePosting?.AmountHired >=
        this.state.activePosting?.AmountNeededWithBuffer ||
      this.state.activePosting?.Deactivated
    ) {
      return (
        <div className="emptyState_wrapper">
          <div className="emptyState_content">
            <img
              src="/img/illustrations/jobOccupied.svg"
              className="noDrag"
              alt="job occupied"
            />
            <h1 className="margin-0">
              {getLanguage(344, "Job is fully occupied.")}
            </h1>
            <p className="infoParagraph no-margin-top">
              {getLanguage(
                619,
                "If you need more chabbers, you can re-open the job and get more applicants."
              )}
            </p>
            <br />
            {/* TODO: Call the re-open function from /PostingOptions */}
            <Button
              size="small"
              className="default"
              onClick={this.reopenPostingClicked}
            >
              {getLanguage(191, "Re-open")}
            </Button>
          </div>
        </div>
      );
    } else if (
      !this.state.amountOfApplicants ||
      this.state.amountOfApplicants === 0
    ) {
      return (
        <div className="emptyState_wrapper">
          <div className="emptyState_content">
            <img
              src="/img/illustrations/noApplicants.svg"
              className="noDrag"
              alt="job occupied"
            />
            <h1 className="margin-0">{getLanguage(620, "No applicants")}</h1>
            <p className="infoParagraph no-margin-top">
              {getLanguage(
                203,
                "There are no applicant at the moment. We'll let you know as soon as this changes."
              )}
            </p>
          </div>
        </div>
      );
    } else {
      if (!this.state.freelancersNotConfirmed) return;

      return (
        <div className="margin-bottom-40">
          <Grid container spacing={3} justify="flex-start" alignItems="stretch">
            {this.state.freelancersNotConfirmed.map((row, index) => {
              const distance = getDistanceToPosting(
                this.state.activePosting,
                row.Request.FreelancerProfile
              );

              return (
                <Grid
                  item
                  lg={4}
                  md={6}
                  sm={12}
                  xs={12}
                  key={index}
                  onClick={() => this.handleClickOpen(row)}
                >
                  <ApplicantProfile
                    distance={distance}
                    favourites={this.props.favourites}
                    requestWithSelectedAndConfirmedBoolean={row}
                    skillId={
                      (this.state.activePosting &&
                        this.state.activePosting.SkillId) ||
                      ""
                    }
                    compQuestions={this.state.competenceQuestions}
                    chabberSelected={this.chabberSelected}
                    deleteChabberRequest={
                      this.deleteChabberRequestOrCancelChabberIfConfirmed
                    }
                    beginCancelChabber={this.beginCancelChabber}
                    confirmed={false}
                  />
                </Grid>
              );
            })}
          </Grid>
        </div>
      );
    }
  }

  confirmed() {
    if (
      !this.state.freelancersConfirmed ||
      this.state.freelancersConfirmed.length === 0
    ) {
      return (
        <div className="emptyState_wrapper">
          <div className="emptyState_content">
            <img
              src="/img/illustrations/noHired.svg"
              className="noDrag"
              alt="job occupied"
            />
            <h1 className="margin-0">
              {getLanguage(202, "You haven't hired anybody yet")}
            </h1>
            <p className="infoParagraph no-margin-top">
              {getLanguage(
                621,
                "Once you hire your chabbers you'll be able to see their contact information and communicate with them through the chat."
              )}
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="margin-bottom-40">
          <Grid container spacing={3} justify="flex-start" alignItems="stretch">
            {this.state.freelancersConfirmed.map((row, index) => {
              const distance = getDistanceToPosting(
                this.state.activePosting,
                row.Request.FreelancerProfile
              );
              return (
                <Grid
                  item
                  lg={4}
                  md={6}
                  sm={12}
                  xs={12}
                  key={index}
                  onClick={() => this.handleClickOpen(row)}
                >
                  <ApplicantProfile
                    distance={distance}
                    favourites={this.props.favourites}
                    requestWithSelectedAndConfirmedBoolean={row}
                    skillId={this.state.activePosting?.SkillId || ""}
                    compQuestions={this.state.competenceQuestions}
                    chabberSelected={this.chabberSelected}
                    deleteChabberRequest={
                      this.deleteChabberRequestOrCancelChabberIfConfirmed
                    }
                    beginCancelChabber={this.beginCancelChabber}
                    confirmed={true}
                  />
                </Grid>
              );
            })}
          </Grid>
        </div>
      );
    }
  }

  toggleMenu() {
    let openMenu = !this.state.openMenu;
    this.setState({ openMenu });
  }

  async handleClose() {
    await this.setState({
      anchorEl: null,
      openPreviewDialog: false,
      CSV: "",
      wholeDayCSV: false,
      jobHasEnded: false,
      freelancerDialogueOpen: false,
      displayReopenPostingDialog: false,
    });
  }

  activeTab(value: number) {
    this.setState({ activeTab: value });
  }

  loadPageIn() {
    if (
      !this.state.activePosting ||
      !this.state.freelancersNotConfirmed ||
      !this.state.freelancersConfirmed
    )
      return;

    if (this.state.activePosting.Skill != null) {
      //TODO: Figure out why this is here...
      let skill = this.storageHelper
        .getSkillsFromLocal()!
        .find((x) => x.Id === this.state.activePosting!.Skill!.Id);
      let user = getCurrentUser();
    }

    let actualAmountNeeded = this.state.activePosting.AmountNeeded;
    if (
      this.state.activePosting.AmountNeededWithBuffer >
      this.state.activePosting.AmountNeeded
    ) {
      actualAmountNeeded = this.state.activePosting.AmountNeededWithBuffer;
    }

    return (
      <div>
        <Tabs value={this.state.activeTab} changeValue={this.activeTab}>
          <TabContent
            title={
              getLanguage(188, "Applicants") +
              " (" +
              this.state.freelancersNotConfirmed.length +
              ")"
            }
          >
            {this.applicants()}
          </TabContent>
          <TabContent
            title={
              getLanguage(187, "Confirmed") +
              " (" +
              this.state.freelancersConfirmed.length +
              "/" +
              actualAmountNeeded +
              ")"
            }
          >
            {this.confirmed()}
          </TabContent>
        </Tabs>
      </div>
    );
  }

  async closeReopenPostingDialog(addedToNeededAmount: number) {
    if (addedToNeededAmount > 0) {
      this.props.postingApplicantsUpdated();
      let activePosting = this.state.activePosting;
      activePosting.AmountNeeded += addedToNeededAmount;
      activePosting.AmountNeededWithBuffer += addedToNeededAmount;
      await this.setState({ displayReopenPostingDialog: false, activePosting });
    } else {
      await this.setState({ displayReopenPostingDialog: false });
    }
  }

  async reopenPostingClicked() {
    await this.setState({ displayReopenPostingDialog: true });
  }

  freelancersConfirmed() {
    this.props.postingApplicantsUpdated();
    this.reloadPosting();
  }

  render() {
    let savingSnackBar: any;
    // TODO: Sean, this is hardcoded
    if (this.state.activePosting == null) return <div>No active post</div>;

    let actualAmountNeeded = this.state.activePosting.AmountNeeded;
    if (
      this.state.activePosting.AmountNeededWithBuffer >
      this.state.activePosting.AmountNeeded
    ) {
      actualAmountNeeded = this.state.activePosting.AmountNeededWithBuffer;
    }

    savingSnackBar = (
      <SavingSnackbar
        open={
          this.state.savingSnackbarOpen &&
          this.state.selectedRequests.length > 0
        }
        message={
          this.state.selectedRequests.length +
          "/" +
          (actualAmountNeeded - this.state.activePosting.AmountHired) +
          " " +
          getLanguage(743, "selected")
        }
        saveClicked={() => this.confirmSelectedChabbers()}
        saveButtonText={getLanguage(742, "Continue")}
      />
    );

    const distance = getDistanceToPosting(
      this.state.activePosting,
      this.state.activeFreelancer
    );

    return (
      <div>
        {this.loadPageIn()}
        <Dialog
          open={this.state.freelancerDialogueOpen}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth
          scroll="body"
        >
          <FreelancerProfile
            distance={distance}
            request={this.state.activeRequest}
            loginProfile={this.state.activeLogin}
            isPreviouslyHired={this.state.activeLoginIsPreviouslyHired}
            context={"viewer"}
            chabberSelected={this.chabberSelected}
            deleteChabberRequest={
              this.deleteChabberRequestOrCancelChabberIfConfirmed
            }
            competencesQuestions={this.state.competenceQuestions}
            selected={this.state.activeSelected}
            skillId={this.state.activePosting.SkillId}
            beginCancelChabber={this.beginCancelChabber}
            favourites={this.props.favourites}
          />
        </Dialog>
        {savingSnackBar}
        <Dialog
          className="checkoutDialog"
          open={this.state.checkoutOpen}
          onClose={() => this.setState({ checkoutOpen: false })}
          aria-labelledby="form-dialog-title"
          fullScreen
          TransitionComponent={Transition}
        >
          <CheckoutV2
            requestsToHire={this.state.selectedRequests}
            activePosting={this.state.activePosting}
            cancelCheckout={this.cancelCheckout}
            requestIds={this.state.requestIdsToConfirmWith}
            confirmed={this.freelancersConfirmed}
            loginIdsToConfirm={this.state.loginIdsToConfirm}
          />
        </Dialog>

        {/* CANCEL A CONFIRMED FREELANCER */}
        <Dialog
          className="cancelDialog dialog"
          open={this.state.cancelDialogOpen}
          maxWidth="sm"
          fullWidth
          // onClose={() => this.setState({ cancelDialogOpen: false })}
          aria-labelledby="cancel-dialog"
        >
          <DialogTitle id="form-dialog-title">
            {getLanguage(215, "Cancel freelancer?")}
          </DialogTitle>
          <DialogContent>
            {this.state.activePosting.CancelInsuranceSelected ? (
              <DialogContentText>
                {getLanguage(
                  216,
                  "you have chosen a cancellation insurance..."
                )}
              </DialogContentText>
            ) : (
              <DialogContentText>
                {getLanguage(
                  217,
                  "Cancelling a freelancer will result in a payment..."
                ).replace(
                  "{{cancelCost}}",
                  getMoneyOutput(
                    getPriceForProduct("Cancel_Freelancer"),
                    false,
                    null
                  )
                )}
              </DialogContentText>
            )}

            <TextField
              margin="dense"
              id="cancelText"
              label={getLanguage(220, "Why are you cancelling?")}
              type="text"
              fullWidth
              variant="outlined"
              rowsMax="4"
              multiline
            />
          </DialogContent>
          <DialogActions className="dialogActions">
            <Button onClick={() => this.setState({ cancelDialogOpen: false })}>
              {getLanguage(218, "Back")}
            </Button>
            <Button
              className="danger"
              onClick={(event) => this.cancelConfirmedChabber(event)}
            >
              {getLanguage(219, "Yes, cancel")}
            </Button>
          </DialogActions>
        </Dialog>
        <ReopenPostingDialog
          handleClose={this.handleClose}
          closeReopenPostingDialog={this.closeReopenPostingDialog}
          comingFrom={"posting"}
          preparePage={() => this.preparePage(false)}
          reloadPosting={() => this.reloadPosting(false)}
          visible={this.state.displayReopenPostingDialog}
          {...this.props}
          posting={this.props.posting}
        ></ReopenPostingDialog>
        {this.state.showPageLoader ? <PageLoader releaseNavbar={true} /> : null}
      </div>
    );
  }
}
