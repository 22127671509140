import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { Grid, Button } from "@material-ui/core";
import { getLanguage } from "../../../../Helpers/LanguageHelper";
import { CompanyStepBasic } from "./CompanySettings/Parts/CompanyStepBasic";
import { CompanyStepPayment } from "./CompanySettings/Parts/CompanyStepPayment";
import { CompanyStepProfile } from "./CompanySettings/Parts/CompanyStepProfile";


interface IProps extends RouteComponentProps {

}

interface IState {
  step: number;
  editing: boolean;
}

export class CompanyCreateUser extends Component<IProps, IState> {


  constructor(props: IProps) {
    super(props);

    this.state = {
      step: 1,
      editing: false,
    }

    this.goToProfile = this.goToProfile.bind(this)
    this.stepProfile = this.stepProfile.bind(this)
    this.stepBasic = this.stepBasic.bind(this)
    this.stepBilling = this.stepBilling.bind(this)
    this.saveStep = this.saveStep.bind(this)
  }

  scrollToTopFunction() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  async saveStep(stepBasicCompleted: boolean | null) {
    let nextStep = this.state.step + 1
    if (stepBasicCompleted) {
      if (nextStep === 4) {
        this.props.history.push('/company/postings')
      } else {
        await this.setState({ step: nextStep })
      }
    }
    this.scrollToTopFunction();
  }

  goToProfile() {
    this.props.history.push('/company/postings')
  }



  stepBasic() {
    return (
      <div>
        <div className="backgroundGradient">
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="stretch"
            spacing={0}
          >
            <Grid item xs={12} md={5} className="backgroundWhite relative">
              <div className="padding-32">
                <h1>{getLanguage(777, 'Finish your profile')}</h1>
                <Grid container justify="space-between">
                  <Grid item>
                    <h1>{getLanguage(55, "basic")}</h1>
                  </Grid>
                </Grid>
                <CompanyStepBasic creatingUser={true} saveStep={this.saveStep} />
              </div>
            </Grid>
            <Grid item className="vh-100" />
          </Grid>
        </div>
      </div>
    )
  }

  stepBilling() {
    return (
      <div>
        <div className="backgroundGradient">
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="stretch"
            spacing={0}
          >
            <Grid item xs={12} md={5} className="backgroundWhite relative">
              <div className="padding-32">
                <Grid container item justify="space-between">
                  <Grid item>
                    <h2>{getLanguage(109, "Billing email")}</h2>
                  </Grid>
                  <Grid item>
                    <Button variant="text" onClick={this.goToProfile}>{getLanguage(778, 'skip')}</Button>
                  </Grid>
                </Grid>
                {/* <FreelancerStepResume creatingUser={true} saveStep={this.saveStep}/> */}
                <CompanyStepPayment creatingUser={true} saveStep={this.saveStep} />

              </div>
            </Grid>
            <Grid item className="vh-100" />
          </Grid>
        </div>
      </div>
    )
  }

  stepProfile() {
    return (
      <div>
        <div className="backgroundGradient">
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="stretch"
            spacing={0}
          >
            <Grid item xs={12} md={5} className="backgroundWhite relative">
              <div className="padding-32">
                <h1>{getLanguage(777, 'Finish your profile')}</h1>
                <Grid container justify="space-between">
                  <Grid item>
                    <h2>{getLanguage(110, "Company description")}</h2>
                  </Grid>
                  <Grid item>
                    <Button variant="text" onClick={this.goToProfile}>{getLanguage(778, 'skip')}</Button>
                  </Grid>
                </Grid>
                <CompanyStepProfile creatingUser={true} saveStep={this.saveStep} />
              </div>
            </Grid>
            <Grid item className="vh-100" />
          </Grid>
        </div>
      </div>
    )
  }



  render() {
    let step;

    switch (this.state.step) {
      case 1:
        step = this.stepBasic()
        break;
      case 2:
        step = this.stepBilling()
        break;
      case 3:
        step = this.stepProfile()
        break;
    }

    return (
      <div>
        {step}
      </div>
    );
  }
}
