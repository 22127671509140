import {CMSActions } from '../Helpers/CMSHelper';

export class CMSRevisionModel {
  Id: string;
  Created: Date;
  Action: CMSActions;
  AdminUser: string;
  Text: string;
  CoverImageId: string;
  CoverImageUrl: string;
  [key: string]: string | Date | CMSActions;

  
  constructor(val: Partial<CMSRevisionModel> = {}) {
    this.Id = val.Id || '';
    this.Created = val.Created || new Date();
    this.Action = val.Action || 0;
    this.AdminUser = val.AdminUser || '';
    this.Text = val.Text || '';
    this.CoverImageId = val.CoverImageId || '';
    this.CoverImageUrl = val.CoverImageUrl || '';
  }
}