import React, { Component } from "react";
import { NavLink, RouteComponentProps } from "react-router-dom";
import "./CompanyNav.scss";
import { Button, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Tooltip } from "@material-ui/core";
import { getLanguage } from "../../../../Helpers/LanguageHelper";
import { UserHelper } from "../../../../Helpers/UserHelper";
import { getCurrentUser, logUserOut } from "../../../../Helpers/SessionHelper";
import { StorageHelper } from "../../../../Helpers/StorageHelper";
import { CompanyModel } from "../../../../Models/CompanyModel";
import { LoginModel } from "../../../../Models/LoginModel";
import { ProfilePictureViewer } from "../../../Parts/General/ProfilePictureViewer";
//@ts-ignore
import { IntercomAPI } from "react-intercom";
import { LocalDataModel } from "../../../../Models/LocalDataModel";

interface IProps extends RouteComponentProps { }

interface IState {
  user: LoginModel | null;
  toggled: boolean;
}

export class CompanyNav extends Component<IProps, IState> {
  userHelper = new UserHelper();
  storageHelper = new StorageHelper();

  constructor(props: IProps) {
    super(props);

    this.state = {
      user: null,
      toggled: true,
    };

    this.logOut = this.logOut.bind(this);
    this.updateUserInNavBar = this.updateUserInNavBar.bind(this);
  }

  async componentDidMount() {
    await this.updateUserInNavBar();
    window.addEventListener("userUpdated", this.updateUserInNavBar);
  }

  async updateUserInNavBar() {
    let user = await getCurrentUser();
    if (user) {
      await this.setState({ user: user });
    }
  }

  async logOut() {
    let response = await logUserOut();
    if (response === true) {
      this.props.history.push("/");
    } else {
      // FIXME: show snackbar
    }
  }

  chatClicked() {
    //@ts-ignore
    IntercomAPI("show");
  }

  render() {
    let facadeData: LocalDataModel | null = new StorageHelper().getFacadeLocalization();

    let supportPhone = "(phone number not found)";
    if (facadeData && facadeData.country) {
      supportPhone = facadeData.country.SupportPhone;
    }
    return (
      <div className="">
        <div className="companyMenu">
          <div className="companyMenu_top">
            <ProfilePictureViewer
              login={this.state.user}
              company={this.state.user != null ? this.state.user!.Company : null}
              freelancer={null}
              customClass="companyLogo"
              altText=""
            ></ProfilePictureViewer>
            <div className="companyName">{this.state.user && this.state.user.Company ? this.state.user.Company.CompanyName : ""}</div>
          </div>
          <div className="companyMenu_wrapper">
            <div className="companyMenu_content">
              {/* <NavLink exact className="companyMenu_link" to="/company/postings">
                <div className="companyMenu_link-icon">
                  <i className="fas fa-home" />
                </div>
                <div className="companyMenu_link-label">{getLanguage(90, "Home")}</div>
              </NavLink> */}
              <div className="companyMenu_linkGroup">
                <div className="companyMenu_linkGroupTitle">{getLanguage(91, "Freelance shifts")}</div>
                <NavLink className="companyMenu_link" to="/company/postings">
                  <div className="companyMenu_link-icon">
                    <i className="fas fa-list fa-fw" />
                  </div>
                  <div className="companyMenu_link-label">{getLanguage(45, "My shifts")}</div>
                </NavLink>
                <NavLink className="companyMenu_link" to="/company/archive">
                  <div className="companyMenu_link-icon">
                    <i className="fas fa-archive fa-fw" />
                  </div>
                  <div className="companyMenu_link-label">{getLanguage(200, "Archive")}</div>
                </NavLink>
              </div>
              <div className="companyMenu_linkGroup">
                <div className="companyMenu_linkGroupTitle">{getLanguage(44, "Profile")}</div>
                <NavLink className="companyMenu_link" to="/company/users">
                  <div className="companyMenu_link-icon">
                    <i className="fas fa-user-friends fa-fw" />
                  </div>
                  <div className="companyMenu_link-label">{getLanguage(94, "Users")}</div>
                </NavLink>
                <NavLink className="companyMenu_link" to="/company/favourites">
                  <div className="companyMenu_link-icon">
                    <i className="fas fa-heart fa-fw" />
                  </div>
                  <div className="companyMenu_link-label">{getLanguage(95, "Favourites")}</div>
                </NavLink>
                <div className="companyMenu_link cursorPointer" onClick={this.chatClicked}>
                  <div className="companyMenu_link-icon">
                    <i className="fas fa-question-circle fa-fw" />
                  </div>
                  <div className="companyMenu_link-label">{getLanguage(52, "Support")}</div>
                </div>
                <ExpansionPanel
                  className="expansionPanel companyMenu_expansionPanel"
                  classes={{
                    expanded: "expanded",
                  }}
                >
                  <ExpansionPanelSummary
                    className="companyMenu_expansionPanel_title"
                    classes={{
                      expandIcon: "expandIcon",
                      expanded: "expanded",
                    }}
                    expandIcon={<i className="fas fa-caret-down expansionIcon" />}
                  >
                    <div className="companyMenu_link-icon">
                      <i className="fas fa-cog fa-fw" />
                    </div>
                    <div className="companyMenu_link-label">{getLanguage(54, "Settings")}</div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className="companyMenu_expansionPanel_content">
                    <NavLink to="/company/settings/basic">
                      <div className="companyMenu_expansionLink">{getLanguage(97, "Company data")}</div>
                    </NavLink>
                    <NavLink to="/company/settings/payment">
                      <div className="companyMenu_expansionLink">{getLanguage(98, "Billing")}</div>
                    </NavLink>
                    <NavLink to="/company/settings/profile">
                      <div className="companyMenu_expansionLink">{getLanguage(99, "Edit profile")}</div>
                    </NavLink>
                    <NavLink to="/company/settings/changepassword">
                      <div className="companyMenu_expansionLink">{getLanguage(57, "Change password")}</div>
                    </NavLink>
                    <NavLink to="/company/settings/salaryrules">
                      <div className="companyMenu_expansionLink">{getLanguage(898, "Salary rules")}</div>
                    </NavLink>
                    <NavLink to="/company/settings/invoices">
                      <div className="companyMenu_expansionLink">{getLanguage(924, "Invoices")}</div>
                    </NavLink>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
              <NavLink to={{ pathname: "/company/redirecttoeditposting", search: "" }}>
                <div className="margin-v-24">
                  <Button className="button-gradient" variant="contained" fullWidth>
                    {getLanguage(100, "New shift")}
                  </Button>
                </div>
              </NavLink>
              <div className="companyMenu_support">
                {getLanguage(52, "Support")}: <a href={`tel:${supportPhone}`}>{supportPhone}</a>
              </div>
            </div>
          </div>
          <div className="companyMenu_bottom">
            <div onClick={this.logOut} className="companyMenu_Bottomlink">
              <div className="companyMenu_link-icon">
                <i className="fas fa-sign-out-alt" />
              </div>
              <div className="companyMenu_link-label">{getLanguage(58, "Log out")}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
