// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import "./EditPosting.scss";
import {
  Button,
  Grid,
  Popover,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Hidden,
  CircularProgress,
} from "@material-ui/core";
import { getLanguage } from "../../../../../Helpers/LanguageHelper";
import moment from "moment";
import { PostingHelper } from "../../../../../Helpers/PostingHelper";
import { StorageHelper } from "../../../../../Helpers/StorageHelper";
import { LocalDataModel } from "../../../../../Models/LocalDataModel";
import { CountryModel } from "../../../../../Models/CountryModel";
import {
  getCurrentUser,
  setUserData,
} from "../../../../../Helpers/SessionHelper";
import { LoginModel } from "../../../../../Models/LoginModel";
import "moment/locale/da";
import "moment/locale/en-gb";
import "moment/locale/nb";
import { CompanyHelper } from "../../../../../Helpers/CompanyHelper";
import { PostingModel } from "../../../../../Models/PostingModel";
import { PageLoader } from "../../../../Parts/General/PageLoader";
import qs from "qs";
import { JobDetails } from "./JobDetails";
import { InformationForChabber } from "./InformationForChabber";
import { AdditionalInfo } from "./AdditionalInfo";
import { TemplateOverview } from "./TemplateOverview";
import {
  getEndTimes,
  getStartTimes,
  ValidateValues,
  submitForm,
  stringTimeToHalfHours,
  stringToSalaryIntervals,
  createTemplateData,
} from "./EditPostingHelpers";
import { PostingTemplateModel } from "../../../../../Models/PostingTemplateModel";
import { PostingTemplateHelper } from "../../../../../Helpers/PostingTemplateHelper";
import {
  JobDetailsHolder,
  iEditPostingState,
  iInitialPostingData,
  iAverageSalary,
  FormStates,
} from "./EditPostingTypes";
import { changeToLocalDecimal } from "../../../../../Helpers/LocalizationHelper";
import { MobileSidebarTrigger } from "../../../../Parts/General/MobileSidebarTrigger";
import { SnackbarManager } from "../../../../../Helpers/SnackbarManager/SnackbarManager";
import { TermsConditionsAndPrivacy } from "../../../../Parts/General/TermsConditionsAndPrivacy";
import { getQuery } from "../../../../../Helpers/queryHelper";
import { nowAsFormatedString } from "../../../../../Helpers/DateTimeHelper";
import {
  isCollectiveAgreementOnboardingRequired,
  CollectiveAgreementOnboardingModal,
} from "../../../../../Modules/CollectiveAgreement";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

interface IProps extends RouteComponentProps {}

export class EditPosting extends Component<IProps, iEditPostingState> {
  postingsHelper = new PostingHelper();
  storageHelper = new StorageHelper();
  companyHelper = new CompanyHelper();
  postingTemplateHelper = new PostingTemplateHelper();

  constructor(props: IProps) {
    super(props);

    this.state = {
      jobDetails: new JobDetailsHolder({}),

      informationForChabber: {
        chabberMustBring: "",
        jobDescription: "",
        contact: "",
        address: "",
        zip: "",
        city: "",
      },
      additionalInfo: {
        InternalRef: "", //Companies own reference to the job
        selectedJobOwner: "", //Id to jobowner
        jobOwners: [],
        RequireExperience: false,
        CancellationInsurance: false,
        AutohireFavourites: true,
        DisableAutohireToggle: true,
        PaidTransportCollectiveAgreement: false,
        AutoHireOnCancel: false,
        invoiceRecipientId: "",
        invoiceRecipients: [],
        SeldPaidBreak: false,
        MealIncluded: false,
        country: null,
      },

      Voucher: "",
      templateDrawerOpen: false,
      lastUpdate: new Date(),
      AcceptedTerms: false,
      errors: {},
      averageDailySalary: 0,
      minimumHourlyWage: 0,
      salaryElement: null,
      salaryBelowAverage: false,
      submittingForm: false,
      originalPosting: null,
      formState: FormStates.preparing,
      saveTemplateDialogueOpen: false,
      overbookingDialogueOpen: false,
      templateTitle: "",
      originalTemplate: null,
      overbookAmount: 0,
      templateTitleTooShort: false,
      templates: [],
      appliedTemplate: null,
      displayPdf: null,
      disableContinueButton: false,
      disableBufferButton: false,
      user: null,
      hasCreditcard: false,
    };

    this.handleDateChange = this.handleDateChange.bind(this);
    this.AddTimeInterval = this.AddTimeInterval.bind(this);
    this.removeInterval = this.removeInterval.bind(this);
    this.getDailyAverageSalary = this.getDailyAverageSalary.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.changeStartTime = this.changeStartTime.bind(this);
    this.changeEndTime = this.changeEndTime.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.getStartValue = this.getStartValue.bind(this);
    this.getEndValue = this.getEndValue.bind(this);
    this.clientValidation = this.clientValidation.bind(this);
    this.closeTemplateDrawer = this.closeTemplateDrawer.bind(this);
    this.createTemplate = this.createTemplate.bind(this);
    this.submitTemplate = this.submitTemplate.bind(this);
    this.applyTemplate = this.applyTemplate.bind(this);
    this.editTemplate = this.editTemplate.bind(this);
    this.updateTemplate = this.updateTemplate.bind(this);
    this.removeTemplate = this.removeTemplate.bind(this);
  }

  componentDidMount() {
    this.initializeForm();
  }

  async initializeForm() {
    //Fetches the query id from the url
    let id =
      qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).id ||
      "";
    let cloneId = getQuery("clone", this.props.location.search);
    // gets and saves initial posting data
    let initialData = this.storageHelper.getInitialPostingData();
    if (initialData == null) {
      initialData = await this.postingsHelper.getInitialData(id);
      if (initialData != null) {
        this.storageHelper.setInitialPostingData(initialData);
      }
    }

    if (initialData == null) {
      SnackbarManager.Instance.addError(getLanguage(469, "unkown error"));
      return;
    }
    await this.setBasicData(initialData);

    let invoiceRecipients = initialData.invoiceRecipients;
    await this.setState((prev) => ({
      additionalInfo: {
        ...prev.additionalInfo,
        invoiceRecipients,
      },
    }));

    if ((id == null || id === "") && (cloneId == null || cloneId === "")) {
      this.prepareNewForm(initialData);
    } else if (id != null && id !== "") {
      this.fillExistingFormData(id, initialData, "isExisting");
    } else if (cloneId != null && cloneId !== "") {
      this.fillExistingFormData(cloneId, initialData, "isNew");
    }
  }

  setFormState(posting: PostingModel, existing: "isNew" | "isExisting") {
    if (existing === "isNew") {
      this.setState({ formState: FormStates.newForm });
    } else if (posting.Deactivated) {
      this.setState({ formState: FormStates.loadClosedForm });
    } else if (posting.CountRequests > 0) {
      this.setState({ formState: FormStates.loadFormWithChabbers });
    } else {
      this.setState({ formState: FormStates.loadForm });
    }
  }

  async setBasicData(initialData: iInitialPostingData) {
    let currentUser = getCurrentUser() as LoginModel;
    const { data: country } = await axios.get(
      `/api/countries/countrybyid?countryid=${currentUser.Company?.CountryId}`,
      {
        headers: {
          "Content-type": "application/json",
          authToken: new StorageHelper().getAccessToken(),
        },
      }
    );
    // let favorites: any[] = await this.userHelper.getFavorites();
    this.setState({ user: currentUser });
    let favorites = initialData.favourited;

    let skills = this.storageHelper.getSkillsFromLocal();
    if (skills == null) skills = [];
    skills = skills.filter((x) => x.Activated);

    let userArr: LoginModel[] = [];
    if (currentUser.AdminGranted) {
      userArr = initialData.jobOwners.map((x) => new LoginModel(x));
    }

    let jobDetails = this.state.jobDetails;
    jobDetails.skills = skills;

    let eventTypes = await new PostingHelper().getEventTypes();

    if (eventTypes) {
      jobDetails.EventTypes = eventTypes;
    }

    var hasCreditcard = await this.companyHelper.getCreditCard();

    await this.setState((prev) => ({
      additionalInfo: {
        ...prev.additionalInfo,
        jobOwners: userArr,
        DisableAutohireToggle: favorites.length === 0,
        country,
      },
      minimumHourlyWage: country.MinimumHourlyWage,
      suggestedHourlyRate: country.SuggestedHourlyRate,
      jobDetails,
      templates: initialData.templates,
      hasCreditcard,
    }));

    await this.getDailyAverageSalary();
  }

  async fillExistingFormData(
    id: string,
    initialData: iInitialPostingData,
    existing: "isNew" | "isExisting"
  ) {
    // FIXME: Not using the initial posting data, should it?
    let posting = await this.postingsHelper.getPosting(id);
    if (!posting) {
      SnackbarManager.Instance.addError(
        getLanguage(710, "Could not load data")
      );
      return;
    }

    await this.setFormState(posting, existing);
    let startDate = moment(posting.StartAtLocal);
    if (existing === "isNew") {
      let hours = startDate.get("hours");
      let minutes = startDate.get("minutes");
      startDate = moment(nowAsFormatedString())
        .set("hours", hours)
        .set("minutes", minutes);
    }
    let startTime = startDate.get("hours") * 2 + startDate.get("minutes") / 30;
    let date = moment(posting.StartAtLocal);
    if (existing === "isNew") {
      date = moment(nowAsFormatedString()).add(3, "hours");
    }
    date.set("hours", 0);
    date.set("minutes", 0);

    let incommingIntervals: { Minute: number; Salary: number }[] = JSON.parse(
      posting.SalaryIntervals
    );
    let salaryIntervals: { timeInHalfHours: number; salaryPerHour: string }[] =
      [];

    let endDate = moment(posting.EndAtLocal);
    if (existing === "isNew") {
      let hours = endDate.get("hours");
      let minutes = endDate.get("minutes");
      endDate = moment(nowAsFormatedString())
        .set("hours", hours)
        .set("minutes", minutes);
      if (endDate < startDate) endDate.add(1, "day");
    }
    let endTime = startTime + endDate.diff(startDate, "minutes") / 30;
    for (let index = 0; index < incommingIntervals.length; index++) {
      let interval = incommingIntervals[index];

      if (index === 0) {
        endTime = startTime + interval.Minute / 30;
      }

      let intervalObj = {
        salaryPerHour: (interval.Salary / 100).toFixed(2),
        timeInHalfHours: 0,
      };

      if (
        incommingIntervals.findIndex((x) => x.Minute === interval.Minute) ===
        incommingIntervals.length - 1
      ) {
        let endAt = moment(posting.EndAtLocal);
        let halfHours = endAt.get("hours") * 2;

        intervalObj.timeInHalfHours = halfHours;
      } else {
        let nextTime = incommingIntervals[index + 1].Minute;
        let currentTime =
          startTime + (interval.Minute + nextTime - interval.Minute) / 30;
        intervalObj.timeInHalfHours = currentTime;
      }

      salaryIntervals.push(intervalObj);
    }

    let informationForChabber = this.state.informationForChabber;

    informationForChabber.chabberMustBring = posting.MustBring;
    if (posting.AddressLine1 && posting.AddressLine1 !== "") {
      informationForChabber.address =
        posting.AddressLine1 + " " + posting.AddressLine2;
      informationForChabber.city = posting.City;
      informationForChabber.zip = posting.ZipCode;
    } else {
      let currentUser = getCurrentUser() as LoginModel;
      if (currentUser && currentUser.Company) {
        informationForChabber.address =
          currentUser.Company.AddressLine1 +
          " " +
          currentUser.Company.AddressLine2;
        informationForChabber.city = currentUser.Company.City;
        informationForChabber.zip = currentUser.Company.ZipCode;
      }
    }
    informationForChabber.jobDescription = posting.Description;
    informationForChabber.contact = posting.Contact;

    let originalPosting = posting as PostingModel;

    this.setState((prev) => ({
      jobDetails: {
        ...prev.jobDetails,
        amountNeeded: originalPosting.AmountNeeded,
        selectedJobtype: originalPosting.SkillId,
        salaryIntervals,
        selectedPaidTransport:
          originalPosting.PaysTransport === -1
            ? 0
            : originalPosting.PaysTransport,

        selectedOvertime: originalPosting.LikelihoodOfOvertime,
        salaryPerHour: (originalPosting.SalaryPerHour / 100).toFixed(2),
        date: date,
        startTime: startTime,
        endTime,
        StartAtLocal: originalPosting.StartAtLocal,
      },

      informationForChabber: {
        ...prev.informationForChabber,
        chabberMustBring: originalPosting.MustBring,
        address:
          originalPosting.AddressLine1 + " " + originalPosting.AddressLine2,
        city: originalPosting.City,
        zip: originalPosting.ZipCode,
        jobDescription: originalPosting.Description,
        contact: originalPosting.Contact,
      },

      additionalInfo: {
        ...prev.additionalInfo,
        AutohireFavourites: originalPosting.AutoHireFavourites,
        AutoHireOnCancel: originalPosting.AutoHireOnCancel,
        CancellationInsurance: originalPosting.CancelInsuranceSelected,
        InternalRef: originalPosting.ReferenceKey,
        PaidTransportCollectiveAgreement:
          originalPosting.CoveredByCollectiveAgreement,
        RequireExperience: originalPosting.RequiresRelevantExperience,
        selectedJobOwner: originalPosting.OwnerLoginId,
        invoiceRecipientId: originalPosting.CompanyInvoiceRecipientId,
        SelfPaidBreak: originalPosting.SelfPaidBreak,
        MealIncluded: originalPosting.MealIncluded,
      },

      originalPosting,
      AcceptedTerms: true,
    }));
  }

  async setSalaryPerHour() {
    const currentUser = getCurrentUser() as LoginModel;
    const { data: country } = await axios.get(
      `/api/countries/countrybyid?countryid=${currentUser.Company?.CountryId}`,
      {
        headers: {
          "Content-type": "application/json",
          authToken: new StorageHelper().getAccessToken(),
        },
      }
    );
    let salaryPerHour = Math.round(country.MinimumHourlyWage);

    if (this.state.averageDailySalary) {
      salaryPerHour = this.state.averageDailySalary;
    }

    this.setState((prev) => {
      const newState = {
        ...prev,
        jobDetails: {
          ...prev.jobDetails,
          salaryPerHour: (salaryPerHour / 100).toFixed(2),
        },
      };
      return newState;
    });
  }

  async prepareNewForm(initialData: iInitialPostingData) {
    let currentUser = getCurrentUser() as LoginModel;

    let localization =
      this.storageHelper.getFacadeLocalization() as LocalDataModel;
    let country = localization.country as CountryModel;
    let address = (
      currentUser.Company!.AddressLine1 +
      " " +
      currentUser.Company!.AddressLine2
    ).trim();
    let zip = currentUser.Company!.ZipCode;
    let city = currentUser.Company!.City;

    let chabberMustBring = currentUser.Company!.SavedMustBringString;
    let description = currentUser.Company!.SavedPostingDescriptionString;

    let salaryPerHour = Math.round(country.MinimumHourlyWage);

    if (this.state.averageDailySalary) {
      salaryPerHour = this.state.averageDailySalary;
    }

    // let logins = initialData.jobOwners;

    // let userArr: TinyLoginModel[] = [new TinyLoginModel(currentUser)];
    // if (currentUser.AdminGranted) {
    //   userArr = userArr.concat(logins.map(x => new TinyLoginModel(x)));
    // }

    await this.setState((prev) => ({
      jobDetails: {
        ...prev.jobDetails,
        salaryPerHour: (salaryPerHour / 100).toFixed(2),
        selectedJobtype: this.state.jobDetails.skills[0].Id,
      },
      informationForChabber: {
        ...prev.informationForChabber,
        address,
        chabberMustBring,
        zip,
        city,
        jobDescription: description,
      },
      additionalInfo: {
        ...prev.additionalInfo,
        selectedJobOwner: currentUser.Id,
        // jobOwners: userArr
      },
      formState: FormStates.newForm,
    }));
  }

  async getDailyAverageSalary(
    averageSalaryObject: iAverageSalary | null = null
  ) {
    if (averageSalaryObject == null) {
      averageSalaryObject = await this.postingsHelper.getAverageSalary(
        this.state.jobDetails.date
      );
    }

    if (averageSalaryObject == null) return;

    if (Number(averageSalaryObject.neededValue) >= 50) {
      this.setState((prev) => ({
        averageDailySalary: Math.round(Number(averageSalaryObject!.avgValue)),
        jobDetails: {
          ...prev.jobDetails,
          defaultSalary: averageSalaryObject!.avgValue,
        },
      }));
    } else if (this.state.suggestedHourlyRate) {
      this.setState((prev) => ({
        averageDailySalary: this.state.suggestedHourlyRate,
        jobDetails: {
          ...prev.jobDetails,
          defaultSalary: this.state.suggestedHourlyRate.toString(),
        },
      }));
    }
  }

  async handleDateChange(incommingDate: Date | null) {
    if (incommingDate == null) return;
    let date = moment(incommingDate);
    date.set("hours", 0);
    date.set("minutes", 0);
    date.set("seconds", 0);

    await this.setState((prev) => ({
      jobDetails: { ...prev.jobDetails, date },
    }));
    await this.getDailyAverageSalary();
    await this.setSalaryPerHour();
    //should recalc
  }

  async clientValidation(
    name: string,
    value: any,
    submissionValidation?: boolean
  ) {
    if (name === "salaryHour") {
      let salary = Number(this.state.jobDetails.salaryPerHour);
      if (
        salary < this.state.averageDailySalary / 100 &&
        salary >= this.state.minimumHourlyWage / 100
      ) {
        if (
          this.state.formState !== "newTemplate" &&
          this.state.formState !== "editTemplate" &&
          submissionValidation !== true
        ) {
          if (
            this.state.averageDailySalary !== this.state.suggestedHourlyRate
          ) {
            await this.setState({ salaryBelowAverage: true });
          }
        }
      }
    }

    let errors = ValidateValues(name, value, this.state);
    this.setState({ errors });
  }

  async changeStartTime(value: string, index: number) {
    let intervals = this.state.jobDetails.salaryIntervals;
    if (index === -1) {
      if (this.state.jobDetails.endTime <= Number(value)) {
        await this.setState((prev) => ({
          jobDetails: { ...prev.jobDetails, endTime: Number(value) + 1 },
        }));
      }
      let lastVal = this.state.jobDetails.endTime;
      for (let i = 0; i < intervals.length; i++) {
        if (intervals[i].timeInHalfHours < lastVal) {
          intervals[i].timeInHalfHours = lastVal + 1;
        }
        lastVal = intervals[i].timeInHalfHours;
      }
      this.setState((prev) => ({
        jobDetails: {
          ...prev.jobDetails,
          startTime: Number(value),
          salaryIntervals: intervals,
        },
      }));
      return;
    } else {
      intervals[index].timeInHalfHours = Number(value);
      let lastVal = Number(value);
      for (let i = index - 1; i < intervals.length; i++) {
        if (i === -1 && this.state.jobDetails.endTime < lastVal) {
          await this.setState((prev) => ({
            jobDetails: { ...prev.jobDetails, endTime: Number(value) },
          }));
        } else if (i >= 0) {
          if (
            i === 0 &&
            intervals[i].timeInHalfHours <= this.state.jobDetails.endTime
          )
            intervals[i].timeInHalfHours = this.state.jobDetails.endTime + 1;
          if (
            i > 0 &&
            intervals[i].timeInHalfHours <= intervals[i - 1].timeInHalfHours
          )
            intervals[i].timeInHalfHours = intervals[i - 1].timeInHalfHours + 1;
        }
      }
    }
    this.setState((prev) => ({
      jobDetails: { ...prev.jobDetails, salaryIntervals: intervals },
    }));
  }

  async changeEndTime(value: string, index: number) {
    let intervals = this.state.jobDetails.salaryIntervals;

    if (index === -1) {
      await this.setState((prev) => ({
        jobDetails: { ...prev.jobDetails, endTime: Number(value) },
      }));
      for (let i = 0; i < intervals.length; i++) {
        if (
          i === 0 &&
          intervals[i].timeInHalfHours <= this.state.jobDetails.endTime
        ) {
          intervals[i].timeInHalfHours = this.state.jobDetails.endTime + 1;
        }
        if (
          i > 0 &&
          intervals[i].timeInHalfHours <= intervals[i - 1].timeInHalfHours
        ) {
          intervals[i].timeInHalfHours = intervals[i - 1].timeInHalfHours + 1;
        }
      }
    } else {
      intervals[index].timeInHalfHours = Number(value);
      for (let i = index; i < intervals.length; i++) {
        if (
          i === 0 &&
          intervals[i].timeInHalfHours <= this.state.jobDetails.endTime
        ) {
          intervals[i].timeInHalfHours = this.state.jobDetails.endTime + 1;
        }
        if (
          i > 0 &&
          intervals[i].timeInHalfHours <= intervals[i - 1].timeInHalfHours
        ) {
          intervals[i].timeInHalfHours = intervals[i - 1].timeInHalfHours + 1;
        }
      }
    }
    this.setState((prev) => ({
      jobDetails: { ...prev.jobDetails, salaryIntervals: intervals },
    }));
  }

  AddTimeInterval(event: any) {
    if (
      !this.state.jobDetails.salaryPerHour ||
      (this.state.errors["salaryHour"] && this.state.errors["salaryHour"].isErr)
    ) {
      SnackbarManager.Instance.addError(
        getLanguage(
          562,
          "You need to set a salary per hour before you can add extra intervals"
        )
      );
      return;
    }

    let intervals = this.state.jobDetails.salaryIntervals.map((x) => x);

    if (this.state.jobDetails.salaryIntervals.length === 0) {
      intervals.push({
        timeInHalfHours: this.state.jobDetails.endTime + 1,
        salaryPerHour: this.state.jobDetails.salaryPerHour,
      });
    } else {
      let last =
        this.state.jobDetails.salaryIntervals[
          this.state.jobDetails.salaryIntervals.length - 1
        ];
      intervals.push({
        timeInHalfHours: last.timeInHalfHours + 1,
        salaryPerHour: last.salaryPerHour,
      });
    }
    this.setState((prev) => ({
      jobDetails: { ...prev.jobDetails, salaryIntervals: intervals },
    }));
  }

  getStartValue(index: number) {
    if (index === -1) {
      return this.state.jobDetails.startTime;
    }
    if (index === 0) {
      return this.state.jobDetails.endTime;
    }
    let startValue =
      this.state.jobDetails.salaryIntervals[index - 1].timeInHalfHours;
    return startValue;
  }

  getEndValue(index: number) {
    let intervals = this.state.jobDetails.salaryIntervals;

    if (index === -1) {
      return this.state.jobDetails.endTime;
    }
    if (index === 0) {
      return intervals[index].timeInHalfHours;
    } else return intervals[index].timeInHalfHours;
  }

  removeInterval(index: number) {
    if (index === -1) return;
    let intervals = this.state.jobDetails.salaryIntervals.map((x) => x);
    intervals.splice(index, 1);
    this.setState((prev) => ({
      jobDetails: { ...prev.jobDetails, salaryIntervals: intervals },
    }));
  }

  async submitForm() {
    debugger;
    console.log("submituje");
    const collectiveAgreementRequired =
      await isCollectiveAgreementOnboardingRequired(
        this.state.jobDetails.selectedJobtype,
        this.state.user?.Company?.Id
      );
    if (collectiveAgreementRequired) {
      this.setState({ isCollectiveAgreementOnboardingModalOpen: true });
      return;
    }

    if (this.state.formState === "newTemplate") {
      //opens the title dialogue before saving template
      this.setState({ saveTemplateDialogueOpen: true });
      return;
    }

    if (this.state.formState === "editTemplate") {
      this.updateTemplate();
      this.setState({ submittingForm: true });
      return;
    }

    if (
      this.state.AcceptedTerms === false ||
      this.state.AcceptedTerms == null
    ) {
      SnackbarManager.Instance.addError(
        getLanguage(454, "You must accept the terms and conditions")
      );
      return;
    }
    await this.setState({ submittingForm: true });

    await this.clientValidation(
      "amountNeeded",
      this.state.jobDetails.amountNeeded,
      true
    );
    await this.clientValidation(
      "salaryHour",
      this.state.jobDetails.salaryPerHour,
      true
    );
    await this.clientValidation(
      "jobDescription",
      this.state.informationForChabber.jobDescription,
      true
    );
    await this.clientValidation(
      "contact",
      this.state.informationForChabber.contact,
      true
    );
    await this.clientValidation(
      "address",
      this.state.informationForChabber.address,
      true
    );
    await this.clientValidation(
      "zip",
      this.state.informationForChabber.zip,
      true
    );
    await this.clientValidation(
      "city",
      this.state.informationForChabber.city,
      true
    );

    let now = moment();
    let start = moment(this.state.jobDetails.date);
    start.set("minutes", this.state.jobDetails.startTime * 30);
    if (start.isAfter(now.add(2, "hours")) === false) {
      let message = "";
      if (this.state.originalPosting == null) {
        message = getLanguage(469, "unknown error");
      } else if (
        start.isSame(moment(this.state.originalPosting!.StartAtLocal)) === false
      ) {
        message = getLanguage(
          723,
          "Start time can't be within 2 hours of creation time"
        );
      }

      if (message !== "") {
        SnackbarManager.Instance.addError(message);
        this.setState({
          submittingForm: false,
        });
        return;
      }
    }

    let activeErrors = false;
    for (const key in this.state.errors) {
      if (this.state.errors.hasOwnProperty(key)) {
        const element = this.state.errors[key];
        if (element.isErr === true) activeErrors = true;
      }
    }

    if (activeErrors) {
      SnackbarManager.Instance.addError(
        getLanguage(453, "Please correct all errors")
      );
      this.setState({ submittingForm: false });
      return;
    }
    let currentUser = getCurrentUser();
    if (currentUser == null || currentUser.Company == null) {
      SnackbarManager.Instance.addError(getLanguage(469, "Unknown error"));
      return;
    }

    if (
      this.state.jobDetails.amountNeeded > 2 &&
      this.state.formState === "newForm"
    ) {
      let neededWithBuffer = Math.ceil(
        this.state.jobDetails.amountNeeded *
          (currentUser.Company.CalculatedHireBuffer / 100.0)
      );
      this.setState({
        overbookingDialogueOpen: true,
        overbookAmount: neededWithBuffer,
      });
    } else {
      this.submitFormStepTwo(false);
    }
  }

  async submitFormStepTwo(useOverbooking: boolean) {
    console.log("debug2");
    let currentUser = getCurrentUser();
    if (currentUser == null || currentUser.Company == null) {
      return;
    }

    if (useOverbooking) {
      this.setState({ disableBufferButton: true });
    } else {
      this.setState({ disableContinueButton: true });
    }

    let response = await submitForm(
      this.state,
      useOverbooking,
      currentUser.Company.IsPrivate
    );

    await this.setState({
      submittingForm: false,
      disableBufferButton: false,
      disableContinueButton: false,
    });

    if (response instanceof Array && response.length) {
      let message = "";
      for (let index = 0; index < response.length; index++) {
        const str: string = response[index];
        message += str + ". ";
      }

      SnackbarManager.Instance.addError(message.trim());
      return;
    } else if (typeof response === "string") {
      SnackbarManager.Instance.addError(response);
      return;
    }

    currentUser.Company!.SavedMustBringString =
      this.state.informationForChabber.chabberMustBring;
    currentUser.Company!.SavedPostingDescriptionString =
      this.state.informationForChabber.jobDescription;
    setUserData(currentUser);

    this.props.history.push("postings", response.Id);
  }

  async submitTemplate() {
    if (
      this.state.templateTitle == null ||
      this.state.templateTitle.length < 1
    ) {
      this.setState({ templateTitleTooShort: true });
      return;
    }
    this.setState({
      submittingForm: true,
    });

    let data = createTemplateData(
      this.state.jobDetails,
      this.state.informationForChabber,
      this.state.additionalInfo
    );
    data.Title = this.state.templateTitle;
    let result = await this.postingTemplateHelper.savePostingTemplate(data);

    if (result !== false) {
      result.Skill = this.state.jobDetails.skills.find(
        (x) => x.Id === result.SkillId
      );
      let addedTemplates = this.state.templates;
      addedTemplates.unshift(result);
      this.storageHelper.removeInitialPostingData();

      SnackbarManager.Instance.addSuccess(
        'saved template "' + data.Title + '"'
      );
      await this.setState({
        templates: addedTemplates,
        formState: FormStates.newForm,
        saveTemplateDialogueOpen: false,
        templateDrawerOpen: true,
        jobDetails: new JobDetailsHolder({}),
        templateTitle: "",
        submittingForm: false,
      });
      await this.initializeForm();
    } else {
      // show error
      SnackbarManager.Instance.addError(
        getLanguage(698, "Changes could not be saved")
      );
    }
  }

  async updateTemplate() {
    let data = createTemplateData(
      this.state.jobDetails,
      this.state.informationForChabber,
      this.state.additionalInfo
    );
    data.Title = this.state.originalTemplate!.Title;
    data.Id = this.state.originalTemplate!.Id;
    data.Title = this.state.originalTemplate!.Title;
    data.CompanyId = this.state.originalTemplate!.CompanyId;
    let response = await this.postingTemplateHelper.updatePostingTemplate(data);
    if (response === true) {
      let templates = this.state.templates;
      let templateIndex = templates.findIndex((x) => x.Id === data.Id);
      templates[templateIndex] = data;

      this.storageHelper.removeInitialPostingData();

      SnackbarManager.Instance.addSuccess(getLanguage(697, "Changes saved"));
      await this.setState({
        jobDetails: new JobDetailsHolder({}),
        templateDrawerOpen: true,
        templates,
        templateTitle: "",
        formState: FormStates.newForm,
        submittingForm: false,
      });
      await this.initializeForm();
    } else {
      SnackbarManager.Instance.addError(
        getLanguage(698, "Could not save changes")
      );
      this.setState({
        submittingForm: false,
      });
    }
  }

  changeValues(value: any, name: string) {
    switch (name) {
      case "selectedPaidTransport":
        this.setState((prev) => ({
          jobDetails: { ...prev.jobDetails, selectedPaidTransport: value },
        }));
        break;
      case "selectedJobtype":
        this.setState((prev) => ({
          jobDetails: { ...prev.jobDetails, selectedJobtype: value },
        }));
        break;
      case "amountNeeded":
        this.setState((prev) => ({
          jobDetails: { ...prev.jobDetails, amountNeeded: value },
        }));
        break;
      case "salaryPerHour":
        this.setState((prev) => ({
          jobDetails: { ...prev.jobDetails, salaryPerHour: value },
        }));
        break;
      case "eventType":
        this.setState((prev) => ({
          jobDetails: { ...prev.jobDetails, SelectedEventType: value },
        }));
        break;
      case "salaryElement":
        this.setState({ salaryElement: value });
        break;
      case "salaryIntervals":
        this.setState((prev) => ({
          jobDetails: { ...prev.jobDetails, SalaryIntervals: value },
        }));
        break;
      case "selectedOvertime":
        this.setState((prev) => ({
          jobDetails: { ...prev.jobDetails, selectedOvertime: value },
        }));
        break;
      case "chabberMustBring":
        this.setState((prev) => ({
          informationForChabber: {
            ...prev.informationForChabber,
            chabberMustBring: value,
          },
        }));
        break;
      case "jobDescription":
        this.setState((prev) => ({
          informationForChabber: {
            ...prev.informationForChabber,
            jobDescription: value,
          },
        }));
        break;
      case "contact":
        this.setState((prev) => ({
          informationForChabber: {
            ...prev.informationForChabber,
            contact: value,
          },
        }));
        break;
      case "address":
        this.setState((prev) => ({
          informationForChabber: {
            ...prev.informationForChabber,
            address: value,
          },
        }));
        break;
      case "zip":
        this.setState((prev) => ({
          informationForChabber: { ...prev.informationForChabber, zip: value },
        }));
        break;
      case "city":
        this.setState((prev) => ({
          informationForChabber: { ...prev.informationForChabber, city: value },
        }));
        break;
      case "InternalRef":
        this.setState((prev) => ({
          additionalInfo: { ...prev.additionalInfo, InternalRef: value },
        }));
        break;
      case "SelectedJobOwner":
        this.setState((prev) => ({
          additionalInfo: { ...prev.additionalInfo, selectedJobOwner: value },
        }));
        break;
      case "RequireExperience":
        this.setState((prev) => ({
          additionalInfo: { ...prev.additionalInfo, RequireExperience: value },
        }));
        break;
      case "CancellationInsurance":
        this.setState((prev) => ({
          additionalInfo: {
            ...prev.additionalInfo,
            CancellationInsurance: value,
          },
        }));
        break;
      case "AutohireFavourites":
        this.setState((prev) => ({
          additionalInfo: { ...prev.additionalInfo, AutohireFavourites: value },
        }));
        break;
      case "PaidTransportCollectiveAgreement":
        this.setState((prev) => ({
          additionalInfo: {
            ...prev.additionalInfo,
            PaidTransportCollectiveAgreement: value,
          },
        }));
        break;
      case "AutoHireOnCancel":
        this.setState((prev) => ({
          additionalInfo: { ...prev.additionalInfo, AutoHireOnCancel: value },
        }));
        break;
      case "InvoiceRecipient":
        this.setState((prev) => ({
          additionalInfo: { ...prev.additionalInfo, invoiceRecipientId: value },
        }));
        break;
      case "SelfPaidBreak":
        this.setState((prev) => ({
          additionalInfo: { ...prev.additionalInfo, SelfPaidBreak: value },
        }));
        break;
      case "MealIncluded":
        this.setState((prev) => ({
          additionalInfo: { ...prev.additionalInfo, MealIncluded: value },
        }));
        break;
    }
  }

  closeTemplateDrawer() {
    this.setState({ templateDrawerOpen: false });
  }

  createTemplate() {
    let date = moment();
    date.set("hours", 0);
    date.set("minutes", 0);
    date.add(1, "year");

    this.setState((prev) => ({
      errors: {},
      templateDrawerOpen: false,
      formState: FormStates.newTemplate,
      additionalInfo: { ...prev.additionalInfo, DisableAutohireToggle: false },
      jobDetails: { ...prev.jobDetails, date },
      appliedTemplate: null,
    }));
  }

  async editTemplate(template: PostingTemplateModel) {
    let date = moment();
    date.set("hours", 0);
    date.set("minutes", 0);
    date.add(1, "year");
    await this.setState((prev) => ({
      errors: {},
      templateDrawerOpen: false,
      formState: FormStates.editTemplate,
      originalTemplate: template,
      additionalInfo: {
        ...prev.additionalInfo,
        DisableAutohireToggle: false,
        invoiceRecipientId: template.CompanyInvoiceRecipientId,
      },
      jobDetails: { ...prev.jobDetails, date },
    }));
    let result = await this.applyTemplate(template);
    if (!result) {
      console.log("template failed");
    }
  }

  async applyTemplate(template: PostingTemplateModel): Promise<boolean> {
    try {
      let jobDetails = this.state.jobDetails;
      jobDetails.amountNeeded = template.AmountNeeded;
      jobDetails.startTime = stringTimeToHalfHours(template.StartTime);
      jobDetails.endTime = stringTimeToHalfHours(template.EndTime);
      jobDetails.selectedJobtype = template.SkillId;
      jobDetails.salaryPerHour = (template.SalaryPerHour / 100).toFixed(2);
      jobDetails.selectedOvertime = template.LikelihoodOfOvertime;
      jobDetails.selectedPaidTransport = template.PaysTransport;
      jobDetails.salaryIntervals = stringToSalaryIntervals(
        jobDetails.endTime,
        template.SalaryIntervals
      );

      let informationForChabber = this.state.informationForChabber;
      informationForChabber.chabberMustBring = template.MustBring;
      informationForChabber.jobDescription = template.Description;
      informationForChabber.address = template.Addressline1;
      informationForChabber.zip = template.ZipCode;
      informationForChabber.city = template.City;

      let additionalInfo = this.state.additionalInfo;
      additionalInfo.InternalRef = template.ReferenceKey;
      additionalInfo.selectedJobOwner = template.DefaultOwnerLoginId;
      additionalInfo.RequireExperience = template.RequiresRelevantExperience;
      additionalInfo.invoiceRecipientId = template.CompanyInvoiceRecipientId;
      additionalInfo.SelfPaidBreak = template.SelfPaidBreak;
      additionalInfo.MealIncluded = template.MealIncluded;
      additionalInfo.AutoHireOnCancel = template.AutoHireOnCancel;
      additionalInfo.CancellationInsurance = template.CancelInsuranceSelected;
      additionalInfo.AutohireFavourites = template.AutoHireFavourites;
      additionalInfo.PaidTransportCollectiveAgreement =
        template.CoveredByCollectiveAgreement;

      let errors = this.state.errors;
      for (const key in errors) {
        if (errors.hasOwnProperty(key)) {
          errors[key] = { isErr: false, message: "" };
        }
      }

      await this.setState({
        jobDetails,
        informationForChabber,
        additionalInfo,
        errors,
        templateDrawerOpen: false,
        appliedTemplate: template,
        lastUpdate: Date.now(),
      });
      return true;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  async removeTemplate(templateId: string) {
    let index = this.state.templates.findIndex((x) => x.Id === templateId);
    let title = this.state.templates[index].Title;
    let didDeleteTemplate =
      await this.postingTemplateHelper.deletePostingTemplate(templateId);

    if (didDeleteTemplate === true) {
      let templates = this.state.templates;
      templates.splice(index, 1);
      let message = getLanguage(447, "Template {{title}} deleted");
      message = message.replace("{{title}}", title);
      SnackbarManager.Instance.addSuccess(message);
      this.setState({ templates });
      return true;
    } else {
      let message = getLanguage(448, "Template {{title}} could not be deleted");
      message = message.replace("{{title}}", title);
      SnackbarManager.Instance.addError(message);
      return false;
    }
  }

  renderPdfs() {
    return (
      <Dialog
        open={this.state.displayPdf != null}
        onClose={() => this.setState({ displayPdf: null })}
        fullWidth
        maxWidth="lg"
      >
        <embed
          src={"/" + this.state.displayPdf + ".pdf"}
          type="application/pdf"
          width="100%"
          height="1000px"
        />
      </Dialog>
    );
  }

  cancelEditButton() {
    if (
      this.state.formState === "loadClosedForm" ||
      this.state.formState === "loadFormWithChabbers" ||
      this.state.formState === "loadForm"
    ) {
      return (
        <Button onClick={() => this.props.history.push("/company/postings")}>
          {getLanguage(242, "cancel")}
        </Button>
      );
    }
    return null;
  }

  render() {
    if (this.state.formState === "unavailable") {
      return (
        <div className="emptyState_wrapper">
          <div className="emptyState_content">
            <img
              src="/img/illustrations/ccError_alt.svg"
              className="noDrag"
              alt="error"
            />
            <h1 className="margin-bottom-0">
              {getLanguage(406, "The page is currently unavailable")}
            </h1>
            {/* <p className="infoParagraph">{getLanguage(407, "This may be because you have yet to register a creditcard. Please add a credit card and try again.")}</p> */}
            {/* <br /> */}
            {/* <NavLink to="settings/payment"><Button size="small" variant="contained">{getLanguage(466, "Add card")}</Button></NavLink> */}
          </div>
        </div>
      );
    }

    let loader = <div></div>;
    if (this.state.formState === "preparing")
      loader = <PageLoader releaseNavbar navbarSize={"20"} />;

    let jobDetails =
      this.state.user?.Company &&
      this.state.jobDetails.selectedJobtype !== "0" ? (
        <JobDetails
          lastUpdate={this.state.lastUpdate}
          addTimeInterval={this.AddTimeInterval}
          changeEndTime={this.changeEndTime}
          changeStartTime={this.changeStartTime}
          clientValidation={this.clientValidation}
          errors={this.state.errors}
          formState={this.state.formState}
          getEndTimes={getEndTimes}
          getEndValue={this.getEndValue}
          getStartTimes={getStartTimes}
          getStartValue={this.getStartValue}
          handleDateChange={this.handleDateChange}
          originalPosting={this.state.originalPosting}
          removeInterval={this.removeInterval}
          jobDetails={this.state.jobDetails}
          changeValues={this.changeValues}
          userIsPrivate={
            this.state.user &&
            this.state.user.Company &&
            this.state.user.Company.IsPrivate
              ? this.state.user.Company.IsPrivate
              : null
          }
          companyId={this.state.user?.Company?.Id}
          refetch={this.state.isCollectiveAgreementOnboardingModalOpen}
        />
      ) : (
        <></>
      );

    let informationForChabber = (
      <InformationForChabber
        changeValues={this.changeValues}
        clientValidation={this.clientValidation}
        errors={this.state.errors}
        formState={this.state.formState}
        informationForChabbers={this.state.informationForChabber}
      />
    );

    let additionalInfo = (
      <AdditionalInfo
        additionalInfo={this.state.additionalInfo}
        formState={this.state.formState}
        changeValues={this.changeValues}
        userIsPrivate={
          this.state.user &&
          this.state.user.Company &&
          this.state.user.Company.IsPrivate
            ? this.state.user.Company.IsPrivate
            : null
        }
      />
    );

    let overbookString = getLanguage(
      438,
      "Yes, update to {{this.state.overbookAmount}}"
    );
    if (this.state.overbookAmount) {
      overbookString = overbookString.replace(
        "{{overbookAmount}}",
        this.state.overbookAmount.toString()
      );
    } else {
      overbookString = overbookString.replace(
        "{{overbookAmount}}",
        getLanguage(335, "Error!")
      );
    }

    let continueWithString = getLanguage(
      439,
      "No, continue with {{amountNeeded}}"
    );
    if (this.state.overbookAmount) {
      continueWithString = continueWithString.replace(
        "{{amountNeeded}}",
        this.state.jobDetails.amountNeeded.toString()
      );
    } else {
      continueWithString = continueWithString.replace(
        "{{amountNeeded}}",
        getLanguage(335, "Error!")
      );
    }

    let overbookingDialogueString = getLanguage(
      440,
      "Sickness and accidents can occur. " +
        "Therefore we suggest that you increase the amount of Chabbers needed to {{overbookAmount}}. " +
        "If there are no cancellations, you can cancel the extra {{oberbookAmount-amountNeeded}} Chabber(s) as late as 2 hours before the shift is set to start."
    );
    if (this.state.overbookAmount && this.state.jobDetails.amountNeeded) {
      overbookingDialogueString = overbookingDialogueString.replace(
        "{{overbookAmount}}",
        this.state.overbookAmount.toString()
      );
      overbookingDialogueString = overbookingDialogueString.replace(
        "{{oberbookAmount-amountNeeded}}",
        (
          this.state.overbookAmount - this.state.jobDetails.amountNeeded
        ).toString()
      );
    } else {
      overbookingDialogueString = overbookingDialogueString.replace(
        "{{overbookAmount}}",
        getLanguage(335, "Error!")
      );
      overbookingDialogueString = overbookingDialogueString.replace(
        "{{oberbookAmount-amountNeeded}}",
        getLanguage(335, "Error!")
      );
    }

    let templateTitleDialogue = (
      <Dialog
        open={this.state.saveTemplateDialogueOpen}
        onClose={() => this.setState({ saveTemplateDialogueOpen: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
        className="dialog"
      >
        <DialogTitle id="alert-dialog-title">
          {getLanguage(396, "Save template")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {getLanguage(404, "Please name your template")}
          </DialogContentText>
          <TextField
            autoFocus
            variant="outlined"
            margin="dense"
            id="name"
            label={getLanguage(403, "Template title")}
            type="text"
            fullWidth
            value={this.state.templateTitle}
            onChange={(e) => this.setState({ templateTitle: e.target.value })}
            error={this.state.templateTitleTooShort}
            helperText={
              this.state.templateTitleTooShort
                ? getLanguage(405, "Your title is too short.")
                : ""
            }
            onFocus={() => this.setState({ templateTitleTooShort: false })}
          />
        </DialogContent>
        <DialogActions className="dialogActions">
          <Button
            onClick={() => this.setState({ saveTemplateDialogueOpen: false })}
            disabled={this.state.submittingForm}
          >
            {getLanguage(242, "Cancel")}
          </Button>
          <Button
            className="success"
            onClick={this.submitTemplate}
            disabled={this.state.submittingForm}
          >
            {getLanguage(396, "Save template")}
          </Button>
        </DialogActions>
      </Dialog>
    );

    let overBookingDialogue = (
      <Dialog
        className="dialog"
        open={this.state.overbookingDialogueOpen}
        onClose={() =>
          this.setState({
            overbookingDialogueOpen: false,
            submittingForm: false,
          })
        }
      >
        <DialogTitle id="alert-dialog-title">
          {getLanguage(402, "Hey! May we suggest a change?")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {overbookingDialogueString}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialogActions">
          <Button
            onClick={() => this.submitFormStepTwo(false)}
            disabled={
              this.state.disableContinueButton || this.state.disableBufferButton
            }
          >
            {continueWithString}
            {this.state.disableContinueButton && (
              <CircularProgress size={20} className="buttonLoader" />
            )}
          </Button>
          <Button
            className="success"
            onClick={() => this.submitFormStepTwo(true)}
            disabled={
              this.state.disableContinueButton || this.state.disableBufferButton
            }
          >
            {overbookString}
            {this.state.disableBufferButton && (
              <CircularProgress size={20} className="buttonLoader" />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );

    let submitButtonTitle = "";
    if (this.state.formState === "newForm") {
      submitButtonTitle = getLanguage(398, "Post job");
    } else if (
      this.state.formState === "loadForm" ||
      this.state.formState === "loadClosedForm" ||
      this.state.formState === "loadFormWithChabbers"
    ) {
      submitButtonTitle = getLanguage(397, "Post job");
    } else if (
      this.state.formState === "newTemplate" ||
      this.state.formState === "editTemplate"
    ) {
      submitButtonTitle = getLanguage(396, "Post job");
    }

    let pageTitle = "Title";
    switch (this.state.formState) {
      case "newForm":
        pageTitle = getLanguage(100, "New shift");
        if (this.state.appliedTemplate) {
          pageTitle += " - " + this.state.appliedTemplate.Title;
        }
        break;
      case "loadForm":
      case "loadFormWithChabbers":
      case "loadClosedForm":
        pageTitle = getLanguage(180, "Update shift");
        break;
      case "preparing":
        pageTitle = getLanguage(401, "Loading");
        break;
      case "newTemplate":
        pageTitle = getLanguage(399, "Create template");
        break;
      case "editTemplate":
        pageTitle = getLanguage(400, "Update template");
        break;
    }

    let outerDivClassName = "editPostingView";
    if (
      this.state.formState === "newTemplate" ||
      this.state.formState === "editTemplate"
    ) {
      outerDivClassName += " templateMode";
    }

    let cancelTemplateButton;
    if (
      this.state.formState === "newTemplate" ||
      this.state.formState === "editTemplate"
    ) {
      cancelTemplateButton = (
        <Button
          className="inputGroup_item"
          variant="contained"
          onClick={() => this.setState({ formState: FormStates.newForm })}
        >
          {getLanguage(242, "cancel")}
        </Button>
      );
    }

    let creditcardWarning = <></>;
    if (
      !this.state.hasCreditcard &&
      this.state.user &&
      this.state.user.Company &&
      this.state.user.Company.ForceCreditcard
    ) {
      creditcardWarning = (
        <div className="warning">
          <br />
          <br />
          {getLanguage(
            848,
            "You haven't yet addd a creditcard. You won't be able to hire any chabbers before a card have been added."
          )}
        </div>
      );
    }
    return (
      <div className={outerDivClassName}>
        {loader}
        <div className="margin-v-24">
          <Grid
            container
            spacing={1}
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Hidden smUp>
                <MobileSidebarTrigger />
              </Hidden>
              <h1 className="inline-block">{pageTitle} </h1>
            </Grid>
            {this.state.user &&
            this.state.user.Company &&
            !this.state.user.Company.IsPrivate ? (
              <Grid item hidden={this.state.formState !== "newForm"}>
                <Button
                  className="white"
                  variant="contained"
                  size="medium"
                  onClick={() => this.setState({ templateDrawerOpen: true })}
                >
                  <span className="btn-icon">
                    <i className="fas fa-file-alt" />
                  </span>
                  {getLanguage(394, "My templates")}
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </div>
        <div className="paper padding-32 radius-12 margin-v-32">
          {jobDetails}
          {informationForChabber}
          {additionalInfo}

          {creditcardWarning}
          <div className="text-center margin-v-40">
            <Grid container justify="space-between" alignItems="center">
              <Grid
                item
                hidden={
                  this.state.formState === "newTemplate" ||
                  this.state.formState === "editTemplate"
                }
              >
                <TermsConditionsAndPrivacy
                  onChange={(value) => this.setState({ AcceptedTerms: value })}
                  checked={this.state.AcceptedTerms}
                  profileType={"company"}
                />
              </Grid>
              <Grid item>
                <div className="inputGroup">
                  <Button
                    className="success inputGroup_item"
                    variant="contained"
                    onClick={this.submitForm}
                    disabled={this.state.submittingForm}
                  >
                    {submitButtonTitle}
                  </Button>
                  {cancelTemplateButton}
                  {this.cancelEditButton()}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        {this.state.isCollectiveAgreementOnboardingModalOpen && (
          <CollectiveAgreementOnboardingModal
            skillId={this.state.jobDetails.selectedJobtype}
            companyId={this.state.user?.Company?.Id}
            isOpen={true}
            onClose={() =>
              this.setState({ isCollectiveAgreementOnboardingModalOpen: false })
            }
          />
        )}

        <Popover
          id="simple-popper"
          open={this.state.salaryBelowAverage}
          anchorEl={this.state.salaryElement}
          onClose={() => this.setState({ salaryBelowAverage: false })}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <div className="padding-8">
            {getLanguage(395, "Your salary is below the daily average of ")}{" "}
            {changeToLocalDecimal(
              (this.state.averageDailySalary / 100).toFixed(2)
            )}
          </div>
        </Popover>
        {this.state.user &&
        this.state.user.Company &&
        !this.state.user.Company.IsPrivate ? (
          <TemplateOverview
            open={this.state.templateDrawerOpen}
            templates={this.state.templates}
            onClose={this.closeTemplateDrawer}
            createTemplate={this.createTemplate}
            applyTemplate={this.applyTemplate}
            editTemplate={this.editTemplate}
            removeTemplate={this.removeTemplate}
            jobOwners={this.state.additionalInfo.jobOwners}
          />
        ) : null}

        {this.renderPdfs()}
        {templateTitleDialogue}
        {overBookingDialogue}
      </div>
    );
  }
}
