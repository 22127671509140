import React, { Component } from "react";
import { TextField, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { CountryHelper } from "../../../../../../../Helpers/CountryHelper";
import { UserHelper } from "../../../../../../../Helpers/UserHelper";
import {
  LanguageHelper, getLanguage
} from "../../../../../../../Helpers/LanguageHelper";
import { getCurrentUser } from "../../../../../../../Helpers/SessionHelper";
import { StorageHelper } from "../../../../../../../Helpers/StorageHelper";
import { LoginModel } from "../../../../../../../Models/LoginModel";
import uuid from "uuid";
import { EducationModel } from "../../../../../../../Models/EducationModel";
import { SnackbarManager } from "../../../../../../../Helpers/SnackbarManager/SnackbarManager";

interface IProps {
  creatingUser: boolean;
  updateEducation: (education: EducationModel[]) => void;
  reset: boolean;
  initialStartup: (component: 'education', data: any) => void;
  validationProblem: validationProblem | null;


}

interface validationProblem {
  type: string;
  index: number;
}

interface IState {
  //education
  newEducationDescription: string;
  newEducationSchoolName: string;
  newEducationTitle: string;
  newEducationFinishedYear: number;
  educations: EducationModel[];
  openEducationExperienceId: string;
  user: LoginModel;
  openDialog: boolean;
}


export class Education extends Component<IProps, IState> {
  countryHelper = new CountryHelper();
  userHelper = new UserHelper();
  languageHelper = new LanguageHelper();
  storageHelper = new StorageHelper();

  constructor(props: IProps) {
    super(props);

    this.state = {
      newEducationDescription: '',
      newEducationSchoolName: '',
      newEducationTitle: '',
      newEducationFinishedYear: 0,
      educations: [],
      openEducationExperienceId: '',
      user: new LoginModel({}),
      openDialog: false,
    };

    this.closeEducation = this.closeEducation.bind(this)
    this.addNewEducationExperience = this.addNewEducationExperience.bind(this)
    this.deleteEducationExperience = this.deleteEducationExperience.bind(this)
    this.openEducationExperienceDialog = this.openEducationExperienceDialog.bind(this)

  }

  async UNSAFE_componentWillReceiveProps() {
    if (this.props.reset)
      await this.prepareForm();
    if (this.props.validationProblem && this.props.validationProblem.type === 'education') {
      let edu = this.state.educations;
      for (let i = 0; i < edu.length; i++) {
        if (i === this.props.validationProblem!.index) {
          edu[i].error = "freelancerResume_item error";
        } else {
          edu[i].error = "freelancerResume_item";
        }

      }
      await this.setState({ educations: edu })
    }
  }


  async componentDidMount() {
    await this.prepareForm();

  }



  async prepareForm() {
    let user: LoginModel = getCurrentUser()!;

    if (!user || !user.Freelancer) {
      return SnackbarManager.Instance.addError(getLanguage(469, 'unknown error'));

    }
    await this.setState({ user: user })


    let educations = user.Freelancer.Educations;

    for (const edu of educations) {
      edu.error = "freelancerResume_item";
    }

    await this.setState({
      educations: educations,
    })

    this.props.initialStartup('education', this.state.educations)

  }



  async openEducationExperienceDialog(rowId: string) {
    if (rowId !== '') {
      let existingEducations = this.state.educations;
      for (const edu of existingEducations) {
        if (edu.Id === rowId) {
          await this.setState({
            openEducationExperienceId: rowId,
            newEducationTitle: edu.Title,
            newEducationSchoolName: edu.SchoolName,
            newEducationFinishedYear: edu.FinishedYear,
            newEducationDescription: edu.Description,
          })
        }
      }
    }
    await this.setState({ openDialog: true })

  }


  async addNewEducationExperience(newEducationDescription: string, newEducationSchoolName: string, newEducationTitle: string, newEducationFinishedYear: number) {
    if (!newEducationDescription || newEducationDescription === '' ||
      !newEducationSchoolName || newEducationSchoolName === '' ||
      !newEducationTitle || newEducationTitle === '' ||
      !newEducationFinishedYear || newEducationFinishedYear === 0) {
      SnackbarManager.Instance.addError(getLanguage(665, 'You have to fill out all fields'));
      return;
    }
    if (!this.state.user || !this.state.user.Freelancer) {
      SnackbarManager.Instance.addError(getLanguage(469, 'Unknown error'));
      return;
    }

    let existingEducations = this.state.educations;

    let id = uuid();

    let education: EducationModel = new EducationModel({
      Id: id,
      Title: newEducationTitle,
      SchoolName: newEducationSchoolName,
      FinishedYear: Number(newEducationFinishedYear),
      Description: newEducationDescription,
      FreelancerProfileId: this.state.user.Freelancer.Id,
      error: "freelancerResume_item"

    })

    if (this.state.openEducationExperienceId) {
      id = this.state.openEducationExperienceId
      for (const edu of existingEducations) {
        if (this.state.openEducationExperienceId === edu.Id) {
          education = edu;

          education.Title = newEducationTitle;
          education.SchoolName = newEducationSchoolName;
          education.FinishedYear = Number(newEducationFinishedYear);
          education.Description = newEducationDescription;
          education.error = "freelancerResume_item";

        }
      }
    } else {
      existingEducations.push(education)
    }

    await this.setState({
      educations: existingEducations,
      newEducationTitle: '',
      newEducationDescription: '',
      newEducationFinishedYear: 0,
      newEducationSchoolName: '',
      openEducationExperienceId: '',
      openDialog: false
    })

    let old: EducationModel[] = existingEducations.map(x => {
      return ({
        Description: x.Description,
        FinishedYear: x.FinishedYear,
        FreelancerProfileId: x.FreelancerProfileId,
        Id: x.Id,
        SchoolName: x.SchoolName,
        Title: x.Title
      } as EducationModel)
    });
    this.props.updateEducation(old)
  }


  async deleteEducationExperience() {
    if (this.state.openEducationExperienceId !== '') {
      let allEducations = await this.state.educations;
      for (let i = 0; i < allEducations.length; i++) {

        if (allEducations[i].Id === this.state.openEducationExperienceId)
          allEducations.splice(i, 1)
      }
      await this.setState({
        openEducationExperienceId: '',
        educations: allEducations,
        newEducationDescription: '',
        newEducationSchoolName: '',
        newEducationFinishedYear: 0,
        newEducationTitle: '',

      })
      await this.setState({ openDialog: false })

      let old: EducationModel[] = this.state.educations.map(x => {
        return ({
          Description: x.Description,
          FinishedYear: x.FinishedYear,
          FreelancerProfileId: x.FreelancerProfileId,
          Id: x.Id,
          SchoolName: x.SchoolName,
          Title: x.Title
        } as EducationModel)
      });
      this.props.updateEducation(old)
    }
  }


  async closeEducation() {
    await this.setState({
      newEducationDescription: '',
      newEducationSchoolName: '',
      newEducationTitle: '',
      newEducationFinishedYear: 0,
      openDialog: false
    })


  }




  render() {
    return (
      <div>
        {this.state.educations.map((row, index) => (
          <div key={index} className={row.error}>
            <div className="freelancerResume_edit" onClick={() => this.openEducationExperienceDialog(row.Id)}>
              <i className="fas fa-edit fa-lg" />
            </div>
            <div className="freelancerResume_item_title">{row.Title}, {row.SchoolName}</div>
            <div className="freelancerResume_item_date">
              {row.FinishedYear}
            </div>
            <div className="freelancerResume_item_content">{row.Description}</div>
          </div>
        ))}

        <div className="text-center">
          <Button
            className="default"
            variant="text"
            size="medium"
            onClick={() => this.openEducationExperienceDialog('')}
          >
            <span className="btn-icon">
              <i className="fas fa-plus" />
            </span>
            {getLanguage(319, "Add item")}
          </Button>
        </div>





        <Dialog
          open={this.state.openDialog}
          aria-labelledby="form-dialog-title"
          fullWidth
          className="dialog"
        >
          <DialogTitle id="form-dialog-title">
            <Grid container justify="space-between" alignItems="center">
              <Grid item>  {getLanguage(326, "Add an education")}</Grid>
              <Grid item>
                <Button
                  className="icon inputGroup_item"
                  variant="text"
                  size="medium"
                  onClick={() => this.deleteEducationExperience()}
                >
                  <span>
                    <i className="fas fa-trash-alt"> </i>
                  </span>
                </Button>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item sm={12}>
                <TextField
                  autoFocus
                  required
                  variant="outlined"
                  margin="dense"
                  id="schoolName"
                  label={getLanguage(327, "School name")}
                  type="text"
                  fullWidth
                  value={this.state.newEducationSchoolName}
                  onChange={(event) => this.setState({ newEducationSchoolName: event.target.value })}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item sm={6}>
                <TextField
                  variant="outlined"
                  margin="dense"
                  id="Title"
                  label={getLanguage(328, "Title")}
                  type="text"
                  fullWidth
                  value={this.state.newEducationTitle}
                  onChange={(event) => this.setState({ newEducationTitle: event.target.value })}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  variant="outlined"
                  margin="dense"
                  id="year"
                  label={getLanguage(329, "Graduation year/expected finish")}
                  type="text"
                  fullWidth
                  value={this.state.newEducationFinishedYear !== 0 ? this.state.newEducationFinishedYear : ''}
                  onChange={(event) => this.setState({ newEducationFinishedYear: +event.target.value })}
                />
              </Grid>
            </Grid>

            <TextField
              type="text"
              label="Description"
              multiline
              rows="5"
              variant="outlined"
              fullWidth
              margin="dense"
              value={this.state.newEducationDescription}
              onChange={(event) => this.setState({ newEducationDescription: event.target.value })}
            />
          </DialogContent>
          <DialogActions className="dialogActions">
            <Button onClick={() => this.closeEducation()}>{getLanguage(242, "Cancel")}</Button>
            <Button
              onClick={() => this.addNewEducationExperience(
                this.state.newEducationDescription,
                this.state.newEducationSchoolName,
                this.state.newEducationTitle,
                this.state.newEducationFinishedYear
              )}
              color="primary"
              className="button-gradient"
            >
              {getLanguage(319, "Add item")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
