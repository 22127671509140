// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { Grid, Button, Hidden, Dialog, DialogContent } from "@material-ui/core";
import { getLanguage } from "../../../../../Helpers/LanguageHelper";
import "./Segments.scss";
import blobLarge from "../../../../../assets/img/shapes/blobLarge.svg";
import blobMedium from "../../../../../assets/img/shapes/blobMedium.svg";
import blobMediumTwo from "../../../../../assets/img/shapes/blobMedium_2.svg";
import { NavLink } from "react-router-dom";
import { EventCaseWedding } from "../cases/Wedding";
import { FeaturePreview } from "../parts/FeaturePreview";
import { Footer } from "../../Navigation/Footer";
import { YouTubeEmbed } from "../../../../Parts/General/YouTubeEmbed";

interface IProps extends RouteComponentProps { }
interface IState {
  dialogOpen: boolean;
}
export class PrivateFunctionsSegment extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      dialogOpen: false,
    }
  }
  render() {
    return (
      <div className="segmentPage">
        <div className="hero">
          <Hidden mdUp>
            <img
              className="heroImage_mobile"
              src="/img/photos/segment_private_mobile@2x.png"
              alt="Product image"
            />
          </Hidden>
          <div className="container">
            <Grid container>
              <Grid item md={6}>
                <h1 className="tagline">{getLanguage(505, "Waiting staff for your private events.")}</h1>
                <p className="heroContent">
                  {getLanguage(506, "49% of a host's time is spent doing practical tasks. Hire service staff and become a guest for your own party.")}
                </p>
                <div className="inputGroup">
                  <Button
                    className="button-gradient inputGroup_item"
                    variant="contained"
                    size="medium"
                  >
                    <span className="btn-icon">
                      <i className="fas fa-arrow-right" />
                    </span>
                    {getLanguage(351, "Get started!")}
                  </Button>
                  <Button className="inputGroup_item" onClick={() => this.setState({ dialogOpen: true })}>
                    {getLanguage(352, "Watch video")}
                  </Button>
                </div>
              </Grid>
              <Grid item md={6}>
                <Hidden smDown>
                  <img
                    className="heroImage"
                    src="/img/photos/segment_private@2x.png"
                    alt="Product image"
                  />
                </Hidden>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="container">
          <section className="text-center">
            <Hidden smDown>
              <img className="blob blobMedium blobValue" src={blobMediumTwo} alt="svg blob" />
            </Hidden>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <div className="valueProp">
                  <div className="valueProp_Icon">
                    <i className="far fa-clock fa-3x"></i>
                  </div>
                  <div className="valueProp_Title">{getLanguage(507, "Choose your own waiters")}</div>
                  <div className="valueProp_Content">
                    {getLanguage(508, "You know your own preferences best. That's why you choose the serving staff you want to ensure you the best party.")}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="valueProp">
                  <div className="valueProp_Icon">
                    <i className="far fa-clock fa-3x"></i>
                  </div>
                  <div className="valueProp_Title">{getLanguage(509, "Lean back and relax")}</div>
                  <div className="valueProp_Content">
                    {getLanguage(510, "Forget about pouring wine, serving food, cleaning up during your party. We take care of everything so you can relax with your guests.")}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="valueProp">
                  <div className="valueProp_Icon">
                    <i className="far fa-clock fa-3x"></i>
                  </div>
                  <div className="valueProp_Title">{getLanguage(511, "Zero administration")}</div>
                  <div className="valueProp_Content">
                    {getLanguage(512, "You've got enough on your mind. That's why we take care of payment, insurance, taxes and everything else.")}
                  </div>
                </div>
              </Grid>
            </Grid>
          </section>
          <section>
            <img className="blob blobLarge blobCase" src={blobLarge} alt="svg blob" />
            <div className="sectionHeading">{getLanguage(361, "This is what our customers say")}</div>
            <EventCaseWedding />
          </section>
          <section>
            <Hidden smDown>
              <img className="blob blobMedium blobFeatures" src={blobMedium} alt="svg blob" />
            </Hidden>
            <FeaturePreview />
          </section>
        </div>
        <Footer />
        <Dialog open={this.state.dialogOpen} onClose={() => this.setState({ dialogOpen: false })} fullWidth maxWidth="md">
          <DialogContent>
            <YouTubeEmbed videoID="PrRcapzWsno" />
          </DialogContent>
        </Dialog>
      </div>
    )
  }
}