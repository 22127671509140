import React, { useState } from 'react';
import { TextField } from "@material-ui/core";

interface IProps {
  label: string;
  value: string;
  rows: string;
  error?: boolean;
  limit: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

export function LimitedTextArea(props: IProps) {

  function changeVal(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (e.target.value.length > props.limit) e.target.value = props.value;
    props.onChange(e)
  }

  return (
    <>
      <TextField
        label={props.label}
        multiline
        fullWidth
        rows={props.rows}
        margin="dense"
        variant="outlined"
        value={props.value}
        onChange={e => changeVal(e)}
        onBlur={(e) => props.onBlur?.(e)}
        onFocus={e => props.onFocus?.(e)}
        error={props.error}
      />
      {props.limit - props.value.length}/{props.limit}
    </>
  )
}