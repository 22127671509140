import moment, { Moment } from 'moment';
import { LanguageHelper, getLanguage } from './LanguageHelper';
import { ExperienceModel } from '../Models/ExperienceModel';

let formatString = 'YYYY-MM-DD HH:mm:ss';
let formatStringWithoutTime = 'YYYY-MM-DD';

export function convertFromDbStringToDateObject(dbString: string): Moment {
  return moment(dbString)
}

export function convertDateObjectToDbString(date: Moment): string {
  return date.format(formatString)
}

export function getNowAsDbString(): string {
  return moment().format(formatString)
}

export function getNowAsDateObject(): Moment {
  return moment()
}

export function getTimeBetweenDateTimes(startDbString: string, endDbString: string, unit: 'hours' | 'minutes' | 'days') {
  let startDateObj = moment(startDbString)
  let endDateObj = moment(endDbString)
  let duration = moment.duration(endDateObj.diff(startDateObj))
  switch (unit) {
    case 'hours':
      return duration.asHours();
    case 'minutes':
      return duration.asMinutes();
    case 'days':
      return duration.asDays();
  }
}

export function addToDate(date: string, amount: number, unit: 'minutes' | 'hours' | 'days') {
  return moment(date).add(amount, unit).format(formatString);
}

export function subtractFromDate(date: string, amount: number, unit: 'minutes' | 'hours' | 'days') {
  return moment(date).subtract(amount, unit).format(formatString);
}




// TODO: Everything below is shit, we'll lose understanding of the code the more we use it.

export function compareDatePrecise(dateA: string, dateB?: string): 'past' | 'future' | 'now' {
  let momentA = moment(dateA);
  let momentB = moment(dateB);

  if (momentA < momentB) return 'past';
  if (momentA > momentB) return 'future';
  return 'now';
}

export function compareChangedDatePrecise(dateA: string, changedDate: string, changedAmount: number, changedUnit: 'minutes' | 'hours' | 'days') {
  let changed = moment(changedDate);
  if (changedAmount > 0) {
    changed.add(changedAmount, changedUnit);
  } else {
    changed.subtract(Math.abs(changedAmount), changedUnit);
  }
  return compareDatePrecise(dateA, changed.format(formatString));
}

export function nowAsFormatedString() {
  return moment().format(formatString);
}

export function dateAsFormatedStringWithoutTime(date: string) {
  return moment(date).format(formatStringWithoutTime);
}

export function dateAsFormatedStringWithoutTimeDK(date: string) {
  return moment(date).format('DD-MM-YYYY'); //TODO: [DT-1963] find a way to do this localized
}


export function experienceToDateString(experience: ExperienceModel, abreviated?: boolean) {
  if (abreviated == null) abreviated = true;
  let symbolAfterMonth = abreviated === true ? '. ' : ' ';

  let months = new LanguageHelper().getMonthsByLanguage(abreviated);
  let fromDate = months[experience.FromMonth] + symbolAfterMonth + experience.FromYear;

  let toDate = '';
  if (experience.ToYear > 0) {
    toDate = months[experience.ToMonth] + symbolAfterMonth + experience.ToYear;
  } else {
    toDate = getLanguage(318, 'Still employed');
  }

  return fromDate + ' - ' + toDate;
}