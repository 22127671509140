import React, { Component } from "react";
import {
  Drawer,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  IconButton,
  Tooltip,
  Zoom,
  MenuItem
} from "@material-ui/core";
import Select from "react-select";
import "./Drawer.scss";
import { CMSMetaModel } from "../../../Models/CMSMetaModel";
import { AdminUserHelper } from "../../../Helpers/AdminUserHelper";
import { cleanUrlString } from "../../../Helpers/UrlHelper";
import { CMSTypes } from "../../../Helpers/CMSHelper";
import { CoursePartnerModel } from "../../../Models/CoursePartnerModel";
import { CourseOrganizerHelper } from "../../../Helpers/CourseOrganizerHelper";

interface IProps {
  metaData: CMSMetaModel;
  open: boolean;
  shouldPublish: number;
  metaChanged: (field: string, value: any) => void;
  togglePublish: (int: number) => void;
  deleteContent: () => void;
  closeDrawer: () => void;
}

interface IState {
  authors: iSelectable[];
  coursePartners: CoursePartnerModel[];
  selectedAuthor: iSelectable;
  history: iSelectable[];
  currentRevision: iSelectable;
  selectedCoursePartner: iSelectable;
}

interface iSelectable {
  label: string;
  value: string
}

export class MetaDrawer extends Component<IProps, IState> {
  adminuserHelper = new AdminUserHelper();
  coursePartnerHelper = new CourseOrganizerHelper();

  constructor(props: any) {
    super(props);
    this.state = {
      authors: [],
      coursePartners: [],
      selectedAuthor: { label: '', value: '' },
      history: [],
      currentRevision: { label: '', value: '' },
      selectedCoursePartner: { label: '', value: '' }
    };

    this.fetchData = this.fetchData.bind(this);
    this.authorSelected = this.authorSelected.bind(this);
    this.revisionSelected = this.revisionSelected.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    let revisions: { label: string, value: string }[] =
      this.props.metaData.Revisions.map(x => { return { label: x.Created.toString(), value: x.Id } });

    await this.setState({ history: revisions });

    if (this.props.metaData.Type === CMSTypes.Blog) {
      this.getAuthors();
    } else if (this.props.metaData.Type === CMSTypes.Course) {
      this.getcourseData();
    }
  }

  async getAuthors() {
    let users = await this.adminuserHelper.getAll();
    if (!users) return;
    let authors: { label: string, value: string }[] = [];
    for (const user of users) {
      authors.push({ label: user.Name, value: user.Id });
    }
    let meta = new CMSMetaModel(this.props.metaData);
    let index = authors.findIndex(x => x.value === meta.AuthorId);
    let selectedAuthor;
    if (index === -1) {
      selectedAuthor = { label: '', value: '' };
    } else {
      selectedAuthor = authors[index];
    }
    this.setState({ authors, selectedAuthor });
  }

  async getcourseData() {
    let coursePartners = await this.coursePartnerHelper.getOrganizers(true);
    let selectedPartner = coursePartners.find(x => x.CourseMetaId === this.props.metaData.Id);
    if (selectedPartner) {
      await this.setState({ selectedCoursePartner: { value: selectedPartner.Id, label: selectedPartner.Name } });
    }
    this.setState({ coursePartners });
  }

  async authorSelected(author: any) {
    await this.setState({ selectedAuthor: author });
    this.props.metaChanged('AuthorId', author.value);
  }

  coursepartnerSelected = async (selectedCoursePartner: any) => {
    await this.setState({ selectedCoursePartner });
    this.props.metaChanged('CoursePartnerId', selectedCoursePartner.value);
  }

  revisionSelected(rev: any) {
    this.setState({ currentRevision: rev });
  }

  async deleteContent() {
    this.props.deleteContent();
  }

  render() {
    return (
      <div className="drawer">
        <Drawer
          anchor="right"
          open={this.props.open}
          onClose={this.props.closeDrawer}
        >
          <div tabIndex={0} role="button" className="drawerContent">
            <div className="relative">
              <h1 className="margin-v-16">Edit meta information</h1>
              {this.renderDeleteButton()}
            </div>
            <div>
              <TextField
                disabled
                type="text"
                label="Page URL"
                variant="outlined"
                fullWidth
                margin="dense"
                InputLabelProps={{
                  shrink: true
                }}
                value={window.location.hostname + '/' + this.props.metaData.PublicId + '/' + cleanUrlString(this.props.metaData.Title)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className="inputAdornment">
                      <i className="far fa-copy" />
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                type="text"
                label="Content title"
                variant="outlined"
                disabled={this.props.metaData.Type !== CMSTypes.Blog && this.props.metaData.Type !== CMSTypes.Course}
                fullWidth
                margin="dense"
                value={this.props.metaData.Title}
                onChange={(event) => this.props.metaChanged('Title', event.target.value)}
                InputLabelProps={{
                  shrink: true
                }}
              />
              {this.renderAuthorSelect()}
              {this.renderCoursePartnerSelect()}
              <TextField
                disabled={this.props.metaData.Type !== CMSTypes.Blog && this.props.metaData.Type !== CMSTypes.Course}
                type="text"
                label="Page description/text snippet"
                multiline
                rows="5"
                variant="outlined"
                fullWidth
                margin="dense"
                value={this.props.metaData.Description}
                onChange={(event) => this.props.metaChanged('Description', event.target.value)}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                select
                fullWidth
                variant="outlined"
                margin="dense"
                label="Status"
                className="something"
                value={this.props.shouldPublish}
                onChange={(event) => this.props.togglePublish(Number(event.target.value))}
              >
                <MenuItem value={0}>
                  <i className="fas fa-circle-notch warning"> </i> Draft
                  </MenuItem>
                <MenuItem value={1}>
                  <i className="far fa-check-circle success"> </i> Publish
                  </MenuItem>
              </TextField>
              {this.renderIncludeInOverview()}
            </div>
            <div className="text-center margin-v-24">
            </div>
          </div>
        </Drawer>
      </div >
    );
  }
  renderCoursePartnerSelect(): React.ReactNode {
    if (this.props.metaData.Type !== CMSTypes.Course) return;
    let options = this.state.coursePartners.map(x => {
      let label = x.Name;
      if (x.CourseMetaId) {
        label += ' - has course';
        if (x.CourseMeta) {
          label += ': "' + x.CourseMeta.Title + '"';
        }

      }
      return { label: label, value: x.Id }
    })
    return (
      <Select
        className="reactSelect margin-v-8"
        classNamePrefix="reactSelect"
        options={options}
        value={this.state.selectedCoursePartner}
        onChange={this.coursepartnerSelected}
        placeholder="Author"
        isSearchable={true}
        isDisabled={this.props.metaData.Type !== CMSTypes.Course}
      />
    )
  }
  renderAuthorSelect(): React.ReactNode {
    if (this.props.metaData.Type !== CMSTypes.Blog) return;
    return (
      <Select
        className="reactSelect margin-v-8"
        classNamePrefix="reactSelect"
        options={this.state.authors}
        value={this.state.selectedAuthor}
        onChange={this.authorSelected}
        placeholder="Author"
        isSearchable={true}
        isDisabled={this.props.metaData.Type !== CMSTypes.Blog}
      />
    )
  }
  renderDeleteButton() {
    if (this.props.metaData.Type === CMSTypes.FreelancerContract
      || this.props.metaData.Type === CMSTypes.Gdpr
      || this.props.metaData.Type === CMSTypes.TermsAndConditions) return;

    return (
      <Tooltip
        TransitionComponent={Zoom}
        title="Delete 4-ever"
        placement="left"
      >
        <IconButton aria-label="Delete" className="deleteBlogPost" onClick={() => this.deleteContent()}>
          <i className="fas fa-trash-alt" />
        </IconButton>
      </Tooltip>
    );
  }

  renderIncludeInOverview() {
    if (this.props.metaData.Type !== CMSTypes.Blog) return;
    return <FormControlLabel
      control={
        <Checkbox
          defaultChecked
          color="primary"
          onChange={
            (e) => this.props.metaChanged('IncludeInOverview', e.target.checked)
          }
          checked={this.props.metaData.IncludeInOverview}
        />
      }
      label="Include in overview"
    />

  }
}
