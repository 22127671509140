import React from "react";
import { Grid } from "@material-ui/core";
import { OptimizedTextField } from "../../../../Components/Parts/General/OptimizedTextField";
import { TimeInterval } from "../../Types";
import { IntervalsFrom, IntervalsTo } from "../../Utils";
import { getLanguage } from "../../../../Helpers/LanguageHelper";

type TimeOfTheDayPredicateFormProps = {
    interval: TimeInterval;
    onChange: (next: TimeInterval) => void;
}

export function TimeOfTheDayPredicateForm({ interval, onChange }: TimeOfTheDayPredicateFormProps) {

    const onStartChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) =>
        onChange({
            start: e.target.value,
            end: interval.end
        });

    const onEndChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) =>
        onChange({
            start: interval.start,
            end: e.target.value
        })

    return <Grid container spacing={3}>
        <Grid item xs={6}>
            <OptimizedTextField
                label={getLanguage(879, 'From')}
                type="time"
                margin="dense"
                select
                value={interval.start}
                onChange={onStartChange}
            >
                {IntervalsFrom()}
            </OptimizedTextField>
        </Grid>
        <Grid item xs={6}>
            <OptimizedTextField
                label={getLanguage(880, 'To')}
                type='time'
                select
                margin="dense"
                value={interval.end}
                onChange={onEndChange}
            >
                {IntervalsTo()}
            </OptimizedTextField>
        </Grid>
    </Grid>
}