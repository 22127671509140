// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from "react";
import "./FreelancerProfile.scss";
import { Button, Tooltip, Grid } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  LanguageHelper,
  getLanguage,
} from "../../../../../Helpers/LanguageHelper";
import { getCurrentUser } from "../../../../../Helpers/SessionHelper";
import { FreelancerModel } from "../../../../../Models/FreelancerModel";
import { EducationModel } from "../../../../../Models/EducationModel";
import { StorageHelper } from "../../../../../Helpers/StorageHelper";
import { LoginModel } from "../../../../../Models/LoginModel";
import { RatingStars } from "../../../../Parts/General/RatingStars";
import { ExperienceModel } from "../../../../../Models/ExperienceModel";
import { CompanyHelper } from "../../../../../Helpers/CompanyHelper";
import { CompetenceQuestionModel } from "../../../../../Models/CompetenceQuestionModel";
import { ExperienceCategoryModel } from "../../../../../Models/ExperienceCategoryModel";
import { RequestModel } from "../../../../../Models/RequestModel";
import { LanguagesAvailableByCountryModel } from "../../../../../Models/LanguagesAvailableByCountryModel";
import { CountryModel } from "../../../../../Models/CountryModel";
import { KnownLanguageModel } from "../../../../../Models/KnownLanguageModel";
import { CompetenceSelectionsModel } from "../../../../../Models/CompetenceSelectionsModel";
import { PageLoader } from "../../../../Parts/General/PageLoader";
import { translateOrNot } from "../../../../Parts/General/Translater";
import { experienceToDateString } from "../../../../../Helpers/DateTimeHelper";
import { SkillModel } from "../../../../../Models/SkillModel";
import { RatingModel } from "../../../../../Models/RatingModel";
import { RequestHelper } from "../../../../../Helpers/RequestHelper";
import { CourseRegistrantHelper } from "../../../../../Helpers/CourseRegistrantHelper";
import { SkillHelper } from "../../../../../Helpers/SkillHelper";
import { LateCancelModel } from "../../../../../Models/LateCancelModel";
import { configureCloudinaryUrl } from "../../../../../Helpers/ImageHelper";
import Rating from "@material-ui/lab/Rating";
import { FavoriteProfileModel } from "../../../../../Models/FavoriteProfileModel";
import Distance from "../../../../../Common/Distance/Distance";

interface IProps {
  request?: RequestModel;
  loginProfile: LoginModel | null;
  selected?: boolean;
  context?: "viewer" | "fromFeedback";
  competencesQuestions?: CompetenceQuestionModel[];
  skillId?: string;
  isPreviouslyHired?: boolean;
  favourites?: FavoriteProfileModel[];

  chabberSelected?: (event: any, freelancerId: string) => void;
  deleteChabberRequest?: (event: any, freelancerId: string) => void;
  beginCancelChabber?: (event: any, freelancerId: string) => void;
  distance?: string | null;
}

interface IState {
  context: "owner" | "viewer" | "fromFeedback";
  name: string;
  age: number;
  workExperience: ExperienceModel[];
  education: EducationModel[];
  profileImageUrl: string;
  description: string;
  jobsTaken: number;
  cancelPercent: number;
  experienceCats: ExperienceCategoryModel[];
  freelancer: FreelancerModel;
  selected: boolean;
  freelancerCompetences: {
    CompetenceQuestion: CompetenceQuestionModel;
    FreelancerHasCompetence: boolean;
  }[];
  facadeLocalization: {
    country: CountryModel;
    language: string;
    countries: CountryModel[];
    knownLanguages: KnownLanguageModel[];
    languagesAvailableByCountry: LanguagesAvailableByCountryModel[];
  };
  showPageLoader: boolean;
  showTranslation: boolean;
  showTranslateButton: boolean;
  viewPrevJobs: number;
  viewCancels: number;
  skills: SkillModel[];
  ratings: RatingModel[];
  requests: RequestModel[];
  lateCancels: LateCancelModel[];
  loadJobState: "initial" | "loading" | "done";
  loadCancelState: "initial" | "loading" | "done";
  graduations: { title: string; description: string }[];
}

export class FreelancerProfile extends Component<IProps, IState> {
  languageHelper = new LanguageHelper();
  storageHelper = new StorageHelper();
  companyHelper = new CompanyHelper();
  requestHelper = new RequestHelper();
  skillHelper = new SkillHelper();
  courseRegistrantHelper = new CourseRegistrantHelper();

  constructor(props: IProps) {
    super(props);
    this.state = {
      context: this.props.context ? this.props.context : "owner",
      name: "",
      age: 0,
      workExperience: [],
      education: [],
      profileImageUrl: "",
      description: "",
      jobsTaken: 0,
      cancelPercent: 0,
      experienceCats: [],
      freelancer: new FreelancerModel({}),
      selected: this.props.selected ? this.props.selected : false,
      freelancerCompetences: [],
      facadeLocalization: {
        country: new CountryModel({}),
        language: "",
        countries: [],
        knownLanguages: [],
        languagesAvailableByCountry: [],
      },
      showPageLoader: false,
      showTranslation: false,
      showTranslateButton: false,
      viewPrevJobs: 0,
      viewCancels: 0,
      skills: [],
      ratings: [],
      requests: [],
      lateCancels: [],
      loadCancelState: "initial",
      loadJobState: "initial",
      graduations: [],
    };

    this.extractSkills = this.extractSkills.bind(this);
    this.getPreviouslyHiredBadge = this.getPreviouslyHiredBadge.bind(this);
    this.getAutoHiredAsFavourite = this.getAutoHiredAsFavourite.bind(this);
    this.getBestMatchBadge = this.getBestMatchBadge.bind(this);
    this.getNonNativeSpeakerBadge = this.getNonNativeSpeakerBadge.bind(this);
    this.getCompetences = this.getCompetences.bind(this);
    this.translationButton = this.translationButton.bind(this);
  }

  async componentDidMount() {
    let user;

    if (!this.props.loginProfile) {
      user = getCurrentUser();
    } else {
      user = new LoginModel(this.props.loginProfile);
    }

    if (user == null) return;

    if (user.Freelancer == null && user.FreelancerProfile) {
      //TODO: When writing the new api we really need to eliminate freelancerprofile.
      user.Freelancer = user.FreelancerProfile;
    }

    if (user.Freelancer == null) return;

    let freelancer = new FreelancerModel(user.Freelancer);

    let firstName = user.Firstnames;
    let lastName = user.Lastname;

    if (this.props.context === "viewer") {
      firstName = firstName.split(" ", 1).toString();
      lastName = lastName.substring(0, 1);
    }

    let name = firstName + " " + lastName;
    let age = freelancer.Age;
    let profileImageUrl = user.getProfileImageUrl();

    if (!freelancer.Age && freelancer.Birthday) {
      age = this.calculate_age(new Date(freelancer.Birthday));
    }

    await this.setState({
      freelancer,
      name,
      age,
      profileImageUrl,
    });

    //Doing this after the freelancer check because we need the freelancer id for this call
    if (this.props.context && this.props.context === "fromFeedback") {
      await this.setState({ showPageLoader: true });
      let getFreelancers = await this.companyHelper.getFreelancerById(
        freelancer.Id
      );
      freelancer = getFreelancers[0];
    }

    let experienceCats =
      (this.storageHelper.getExperienceCategoriesFromLocal() as ExperienceCategoryModel[]) ||
      [];
    let workExperience = freelancer.Experiences;
    let education = freelancer.Educations;
    let description = freelancer.Description;

    let jobsTaken = freelancer.CountHired;
    let cancelPercent = Math.round(+freelancer.CancellationPercentage);

    let facadeLocalization = this.storageHelper.getFacadeLocalization();
    if (facadeLocalization == null) return;

    let showTranslateButton = false;
    if (
      this.props.request &&
      this.props.request.Posting &&
      this.props.request.Posting.CompanyProfile &&
      this.props.request.Posting.CompanyProfile.Login
    ) {
      let companyLanguage =
        this.props.request.Posting.CompanyProfile.Login.PrimaryLanguage;
      if (companyLanguage !== user.PrimaryLanguage) {
        showTranslateButton = true;
      }
    }

    await this.setState({
      showTranslateButton,
      workExperience,
      education,
      description,
      jobsTaken,
      cancelPercent,
      experienceCats,
      facadeLocalization,
      skills: this.storageHelper.getStaticData()?.skills || [],
      showPageLoader: false,
    });

    let graduations =
      await this.courseRegistrantHelper.getFreelancersCourseGraduations(
        freelancer.Id
      );

    await this.setState({
      graduations,
    });
    await this.extractSkills();
    let ratings = await this.requestHelper.getRatingsById(user.Id);
    await this.setState({ ratings });
  }

  async loadCancellations() {
    await this.setState({ loadCancelState: "loading" });
    let lateCancels = await this.requestHelper.getCancelledByFreelancerId(
      this.state.freelancer.Id
    );
    await this.setState({ lateCancels: lateCancels, loadCancelState: "done" });
  }
  async loadPreviousJobs() {
    await this.setState({ loadJobState: "loading" });
    let requests = await this.requestHelper.getCompletedForFreelancer(
      this.state.freelancer.Id
    );
    await this.setState({ requests, loadJobState: "done" });
  }

  async extractSkills() {
    let relevantCompQuestions: CompetenceQuestionModel[] = [];

    if (this.props.competencesQuestions) {
      for (const compQ of this.props.competencesQuestions) {
        if (compQ.SkillId === this.props.skillId) {
          relevantCompQuestions.push(compQ);
        }
      }
    }

    let freelancer = this.state.freelancer;
    let freelancersCompetences: CompetenceSelectionsModel[] = [];
    if (freelancer) freelancersCompetences = freelancer.CompetenceSelections;
    let competences = [];

    for (const i of relevantCompQuestions) {
      let comp = {
        CompetenceQuestion: i,
        FreelancerHasCompetence: false,
      };
      for (const u of freelancersCompetences) {
        if (comp.CompetenceQuestion.Id === u.CompetenceQuestionId) {
          comp.FreelancerHasCompetence = true;
        }
      }
      competences.push(comp);
    }

    await this.setState({ freelancerCompetences: competences });
  }

  calculate_age(dateOfBirth: Date) {
    var diff_ms = Date.now() - dateOfBirth.getTime();
    var age_dt = new Date(diff_ms);
    return Math.abs(age_dt.getUTCFullYear() - 1970);
  }

  getWorkExperience() {
    let self = this;
    if (!this.state.workExperience || this.state.workExperience.length === 0) {
      return (
        <div className="freelancerProfile_resumeSectionContent emptyContent">
          {getLanguage(207, "No work experience added")}
        </div>
      );
    } else {
      return this.state.workExperience.map((x) => {
        let descriptionLocalizedOrNonLocalized: string | JSX.Element = "";
        descriptionLocalizedOrNonLocalized = translateOrNot(
          x.Description,
          x.DescriptionNonLocalised,
          self
        );

        let dateString = experienceToDateString(x, false);

        let expCat: ExperienceCategoryModel | undefined =
          this.state.experienceCats.find(
            (y) => y.Id === x.ExperienceCategoryId
          );
        let jobTitle = "";

        if (expCat && expCat.Id !== "75121c82-e2d7-478a-97f8-e4c90f23e547") {
          // If not other
          jobTitle = getLanguage(
            expCat!.LanguageCode,
            "experience category title"
          );
        } else {
          jobTitle = x.JobTitle;
        }

        jobTitle += " - " + x.CompanyName;

        return (
          <div key={x.Id} className="freelancerProfile_resumeSectionContent">
            <div className="resumeSection_title">{jobTitle}</div>
            <div className="resumeSection_date">{dateString}</div>
            <p>{descriptionLocalizedOrNonLocalized}</p>
          </div>
        );
      });
    }
  }

  renderCancelationSection() {
    if (this.state.cancelPercent === 0) {
      // If there a 0 cancellations show this div
      return (
        <div className="freelancerProfile_resumeSectionContent emptyContent">
          {getLanguage(798, "{{freelancerName}} has no cancellations").replace(
            "{{freelancerName}}",
            this.props.loginProfile?.Firstnames || "freelancer"
          )}
        </div>
      );
    }
    let cancelMinutes = this.state.freelancer.AverageMinutesCancellelingBefore;
    let cancellationText = "";
    if (cancelMinutes > 1440) {
      let cancelDays = Number((cancelMinutes / 60 / 24).toFixed(1)).toString();

      cancellationText = getLanguage(
        799,
        "{{freelancerName}} has {{percent}} cancellations and cancels an average of {{cancellationWarning}} days before the jobs starts"
      )
        .replace(
          "{{freelancerName}}",
          this.props.loginProfile?.Firstnames || "freelancer"
        )
        .replace("{{percent}}", "<b>" + this.state.cancelPercent + "%</b>")
        .replace("{{cancellationWarning}}", "<b>" + cancelDays + "</b>");
    } else {
      let cancelHours = (cancelMinutes / 60).toFixed(0);
      cancellationText = getLanguage(
        809,
        "{{freelancerName}} has {{percent}} cancellations and cancels an average of {{cancellationWarning}} hours before the jobs starts"
      )
        .replace(
          "{{freelancerName}}",
          this.props.loginProfile?.Firstnames || "freelancer"
        )
        .replace("{{percent}}", "<b>" + this.state.cancelPercent + "%</b>")
        .replace("{{cancellationWarning}}", "<b>" + cancelHours + "</b>");
    }

    let allCancellations = [<div key="1337"></div>];
    if (this.state.loadCancelState === "done") {
      allCancellations = this.state.lateCancels
        .slice(0, this.state.viewCancels)
        .map((lateCancel) => {
          let cancelledText = "";
          if (lateCancel.CancelledMinutesBefore) {
            if (lateCancel.CancelledMinutesBefore > 1440) {
              // use days
              let cancelledDays = Number(
                (Number(lateCancel.CancelledMinutesBefore) / 60 / 24).toFixed(1)
              ).toString();
              cancelledText = getLanguage(
                807,
                "Cancelled {{cancelledDays}} days before job start"
              ).replace("{{cancelledDays}}", cancelledDays);
            } else {
              // Use hours
              let cancelledHours = (
                Number(lateCancel.CancelledMinutesBefore) / 60
              ).toFixed(0);
              cancelledText = getLanguage(
                808,
                "Cancelled {{cancelledHours}} hours before job start"
              ).replace("{{cancelledHours}}", cancelledHours);
            }
          }

          return (
            <div
              className="freelancerProfile_extraDetails"
              key={lateCancel.Id + "c"}
            >
              <div
                className="freelancerProfile_companyLogo"
                style={{
                  backgroundImage:
                    "url(" +
                    configureCloudinaryUrl(
                      lateCancel.Request?.Company?.Login?.ProfilePictureUrl,
                      ["w_300"]
                    ) +
                    ")",
                }}
              ></div>
              <div className="extraDetails_info">
                <div className="extraDetails_tilte">
                  {lateCancel.Request?.Company?.CompanyName}
                </div>
                <p className="extraDetails_description">{cancelledText}</p>
              </div>
            </div>
          );
        });
    } else if (this.state.loadCancelState === "loading") {
      allCancellations = [
        emptyContent("b0"),
        emptyContent("b1"),
        emptyContent("b2"),
        emptyContent("b3"),
        emptyContent("b4"),
      ];
    }

    let showOrHide =
      this.state.viewCancels === 0
        ? getLanguage(800, "Show all cancellations")
        : getLanguage(801, "Hide all cancellations");

    return (
      // If there are cancellations to show, then use this section
      <div className="freelancerProfile_resumeSectionContent">
        <div dangerouslySetInnerHTML={{ __html: cancellationText }} />

        {/* Clicking this should load up to 5 jobs at a time */}
        <div
          className="freelancerProfile_showMoreLink"
          onClick={() => {
            if (this.state.loadCancelState === "loading") return;
            if (this.state.loadCancelState === "initial") {
              this.loadCancellations();
            }
            this.setState({ viewCancels: this.state.viewCancels > 0 ? 0 : 5 });
          }}
        >
          {showOrHide}
        </div>
        {allCancellations}

        {/* Clicking this should load up to 5 jobs more */}
        {this.state.viewCancels !== 0 &&
          this.state.viewCancels < this.state.lateCancels.length && (
            <div
              className="freelancerProfile_loadMoreLink"
              onClick={() =>
                this.setState({ viewCancels: this.state.viewCancels + 5 })
              }
            >
              {getLanguage(834, "Load more")}
            </div>
          )}
      </div>
    );
  }

  renderPreviousJobs = () => {
    if (this.state.jobsTaken === 0) {
      // If the freelancer has no jobs yet show this
      return (
        <div className="freelancerProfile_resumeSectionContent emptyContent">
          {getLanguage(
            796,
            "{{freelancerName}} has not yet completed a job. Help get them started!"
          ).replace(
            "{{freelancerName}}",
            this.props.loginProfile?.Firstnames || "freelancer"
          )}
        </div>
      );
    }
    let ratingValue = "";
    if (this.state.ratings.length === 1) {
      ratingValue = this.state.ratings[0].RatingValue.toString();
    } else if (this.state.ratings.length > 1) {
      let mapped = this.state.ratings.map((x) => x.RatingValue);
      ratingValue = (
        mapped.reduce((a, b) => a + b) / this.state.ratings.length
      ).toString();
    }

    let requests = this.state.requests.slice(0, this.state.viewPrevJobs);

    // Otherwise show this
    let jobsTakenAndRating = getLanguage(
      797,
      "{{freelancerName}} has completed {{amount}} jobs with an average satisfaction of {{percent}}"
    )
      .replace("{{freelancerName}}", this.props.loginProfile?.Firstnames || "")
      .replace("{{amount}}", "<b>" + this.state.jobsTaken.toString() + "</b>")
      .replace(
        "{{percent}}",
        "<b>" + Math.round(Number(ratingValue)).toString() + "%</b>"
      );

    let previousJobs: JSX.Element[] = [<></>];
    if (this.state.loadJobState === "done") {
      previousJobs = requests.map((request) => {
        let rating = this.state.ratings.find((x) => x.RequestId === request.Id);
        let ratingText = (
          <div className="placeholder">{getLanguage(831, "Not rated")}</div>
        );
        if (rating != null) {
          ratingText = (
            <Rating
              readOnly
              name={"rating " + request.Id}
              className="ratingStars"
              value={rating.RatingValue / 20}
              emptyIcon={<i className="far fa-star" />}
              icon={<i className="fas fa-star" />}
              size="small"
            />
          );
        }
        let skill = this.state.skills.find((x) => x.Id === request.SkillId);
        let skillTitle = "";
        if (skill) {
          skillTitle = getLanguage(skill.LanguageCodeSingularis).toLowerCase();
        }
        return (
          <div
            key={"hhh" + request.Id}
            className="freelancerProfile_extraDetails"
          >
            <Grid
              key={request.Id}
              container
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <div
                  className="freelancerProfile_companyLogo"
                  style={{
                    backgroundImage:
                      "url(" +
                      configureCloudinaryUrl(
                        request?.Company?.Login?.ProfilePictureUrl,
                        ["w_300"]
                      ) +
                      ")",
                  }}
                ></div>
                <div className="extraDetails_info">
                  <div className="extraDetails_tilte">
                    {request.Company?.CompanyName}
                  </div>
                  <p className="extraDetails_description">
                    {getLanguage(832, "Hired as {{JobType}}").replace(
                      "{{JobType}}",
                      skillTitle
                    )}
                  </p>
                </div>
              </Grid>
              <Grid item>{ratingText}</Grid>
            </Grid>
          </div>
        );
      });
    } else if (this.state.loadJobState === "loading") {
      previousJobs = [
        emptyContent("a0"),
        emptyContent("a1"),
        emptyContent("a2"),
        emptyContent("a3"),
      ];
    }

    let loadMore = <></>;
    if (
      this.state.viewPrevJobs &&
      this.state.viewPrevJobs < (this.state.requests.length || 0)
    ) {
      loadMore = (
        <div
          className="freelancerProfile_loadMoreLink"
          onClick={() =>
            this.setState({ viewPrevJobs: this.state.viewPrevJobs + 5 })
          }
        >
          {getLanguage(834, "Load more")}
        </div>
      );
    }

    let showOrHide =
      this.state.viewPrevJobs === 0
        ? getLanguage(802, "Show all previous jobs")
        : getLanguage(803, "Hide all previous jobs");

    return (
      <div className="freelancerProfile_resumeSectionContent">
        <div dangerouslySetInnerHTML={{ __html: jobsTakenAndRating }} />

        {/* Clicking this should load up to 5 jobs at a time */}
        <div
          className="freelancerProfile_showMoreLink"
          onClick={() => {
            if (this.state.loadJobState === "initial") {
              this.loadPreviousJobs();
            }
            this.setState({
              viewPrevJobs: this.state.viewPrevJobs === 0 ? 5 : 0,
            });
          }}
        >
          {showOrHide}
        </div>

        {this.state.viewPrevJobs !== 0 && previousJobs}

        {/* Clicking this should load up to 5 jobs more */}
        {loadMore}
      </div>
    );
  };

  getFavouriteBadge() {
    if (this.props.favourites == null || this.props.favourites.length < 1)
      return <div></div>;
    // @ts-ignore
    let fave = this.props.favourites.find(
      (x) =>
        x.FreelancerProfileId === this.props.loginProfile?.FreelancerProfile?.Id
    );
    if (fave?.ApprovalStatus === 1)
      return (
        <span className="favouriteIndicator">
          <i className="far fa-heart fa-sm" />
        </span>
      );
    return <div></div>;
  }

  getEducation() {
    if (!this.state.education || this.state.education.length === 0) {
      return (
        <div className="freelancerProfile_resumeSectionContent emptyContent">
          {getLanguage(208, "No education added")}
        </div>
      );
    } else {
      return this.state.education.map((x) => {
        let descriptionLocalizedOrNonLocalized: string | JSX.Element = "";
        descriptionLocalizedOrNonLocalized = translateOrNot(
          x.Description,
          x.DescriptionNonLocalised,
          this
        );

        return (
          <div key={x.Id} className="freelancerProfile_resumeSectionContent">
            <div className="resumeSection_title">
              {x.Title}, {x.SchoolName}
            </div>
            <div className="resumeSection_date">{x.FinishedYear}</div>
            <p>{descriptionLocalizedOrNonLocalized}</p>
          </div>
        );
      });
    }
  }

  changeState(event: any) {
    if (this.props.chabberSelected) {
      if (this.state.selected) {
        this.props.chabberSelected(event, this.state.freelancer.Id);
        this.setState({ selected: false });
      } else {
        this.props.chabberSelected(event, this.state.freelancer.Id);
        this.setState({ selected: true });
      }
    }
  }

  getActionButtons() {
    if (this.props.request && this.props.request.RequestStatus === 3) {
      return (
        <div>
          <div className="FreelancerProfile_actionButtons">
            <div className="inputGroup">
              <Button
                className=""
                variant="outlined"
                onClick={(event: any) =>
                  this.props.beginCancelChabber!(
                    event,
                    this.state.freelancer.Id
                  )
                }
              >
                {getLanguage(721, "Cancel")}
              </Button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="FreelancerProfile_actionButtons">
          <div className="inputGroup">
            <Tooltip title={getLanguage(63, "Reject")}>
              <Button
                className="icon inputGroup_item"
                variant="outlined"
                onClick={(event: any) =>
                  this.props.deleteChabberRequest!(
                    event,
                    this.state.freelancer.Id
                  )
                }
              >
                <span>
                  <i className="fas fa-trash-alt"> </i>
                </span>
              </Button>
            </Tooltip>
            <div className="inputGroup_item inline-block">
              <Button
                className={this.state.selected ? "success" : ""}
                variant="outlined"
                onClick={(event: any) => this.changeState(event)}
              >
                {getLanguage(64, "Select")}
              </Button>
            </div>
          </div>
        </div>
      );
    }
  }

  getBestMatchBadge() {
    if (this.props.request && this.props.request.MatchingRating >= 98) {
      return (
        <div className="freelancerProfile_badge bestMatch">
          {getLanguage(65, "Best match")}
        </div>
      );
    }
  }

  getPreviouslyHiredBadge() {
    if (this.props.isPreviouslyHired) {
      return (
        <div className={"freelancerProfile_badge previouslyHired"}>
          {getLanguage(66, "Prevously hired")}
        </div>
      );
    }
  }

  getAutoHiredAsFavourite() {
    if (this.props.request?.AutoHiredAsFavourite) {
      return (
        <div className={"freelancerProfile_badge favouriteHired"}>
          {getLanguage(826, "Autohired")}
        </div>
      );
    }
  }

  getNonNativeSpeakerBadge() {
    let countryId = this.state.freelancer.CountryId;
    let knownLanguages = this.state.freelancer.KnownLanguages;

    let facadeLocalization = this.storageHelper.getFacadeLocalization();
    if (facadeLocalization == null) return null;
    let nativeLanguageId = "";

    for (const i of facadeLocalization.languagesAvailableByCountry) {
      if (i.CountryId === countryId) {
        nativeLanguageId = i.LanguageId;
      }
    }

    if (nativeLanguageId !== "") {
      for (const u of knownLanguages) {
        if (u.Id === nativeLanguageId) {
          return;
        }
      }
      return (
        <div className="freelancerProfile_badge nonNativeSpeaker">
          {getLanguage(67, "English-speaker")}
        </div>
      );
    }
  }

  getCompetences() {
    let skills = this.storageHelper.getSkillsFromLocal();
    let skillLanguageCode = 0;

    if (this.props.skillId && skills) {
      let skill = skills.find((t) => t.Id === this.props.skillId);
      if (skill) {
        skillLanguageCode = skill.LanguageCodeSingularis;
        if (skill.CompetenceQuestions?.length === 0) {
          return <></>;
        }
      }

      return (
        <div className="freelancerProfile_resumeSection">
          <h2 className="freelancerProfile_resumeSectionTitle">
            {getLanguage(62, "Skills")}
          </h2>

          <div className="freelancerProfile_resumeSectionContent">
            <div className="resumeSection_title">
              {getLanguage(skillLanguageCode, "skill name")}
            </div>
            {this.state.freelancerCompetences.map((row, index) => (
              <div
                className={
                  row.FreelancerHasCompetence
                    ? "competence_has"
                    : "competence_hasNot"
                }
                key={index}
              >
                {getLanguage(
                  row.CompetenceQuestion.LanguageStringCode,
                  "Competence"
                )}
              </div>
            ))}
          </div>
        </div>
      );
    } else return <div></div>;
  }

  getJobsAndCancels() {
    // cancelations

    let val: JSX.Element[] = [];
    val.push(
      <div key="0">
        <b>{this.state.jobsTaken}</b> {getLanguage(60, "Jobs taken")}
      </div>
    );
    val.push(
      <div key="1">
        <b>{this.state.cancelPercent}%</b> {getLanguage(61, "Cancels")}
      </div>
    );

    return val;
  }

  getDescription() {
    let descriptionLocalizedOrNonLocalized: string | JSX.Element = "";

    if (this.state.context === "owner") {
      descriptionLocalizedOrNonLocalized = this.state.description;
    } else {
      if (!this.props.request || !this.props.request.FreelancerProfile) return;
      descriptionLocalizedOrNonLocalized = translateOrNot(
        this.props.request.FreelancerProfile.Description,
        this.props.request.FreelancerProfile.DescriptionNonLocalised,
        this
      );
    }

    if (!this.state.description || this.state.description === "") {
      return (
        <div className="freelancerProfile_resumeSectionContent emptyContent">
          {getLanguage(209, "No description added")}
        </div>
      );
    } else {
      return (
        <div className="freelancerProfile_resumeSectionContent">
          {descriptionLocalizedOrNonLocalized}
        </div>
      );
    }
  }

  translationButton() {
    if (this.props.context !== "viewer" || !this.state.showTranslateButton)
      return;
    if (this.state.showTranslation) {
      return (
        <div>
          <Button
            size="small"
            onClick={() => this.setState({ showTranslation: false })}
          >
            {getLanguage(271, "translated")} -{" "}
            {getLanguage(272, "Show original")}
          </Button>
        </div>
      );
    } else {
      return (
        <div>
          <Button
            size="small"
            onClick={() => this.setState({ showTranslation: true })}
          >
            {getLanguage(273, "Translate")}
          </Button>
        </div>
      );
    }
  }

  renderCourses() {
    if (this.state.graduations.length < 1) return null;

    return (
      <>
        <div className="freelancerProfile_resumeSection">
          <h2 className="freelancerProfile_resumeSectionTitle">
            {getLanguage(682, "Courses")}
          </h2>

          {this.state.graduations.map((x) => (
            <div
              key={x.title}
              className="freelancerProfile_resumeSectionContent"
            >
              <div className="resumeSection_title">{x.title}</div>
              <p>{x.description}</p>
            </div>
          ))}
        </div>
      </>
    );
  }

  render() {
    let workExperience = this.getWorkExperience();
    let education = this.getEducation();
    let description = this.getDescription();

    let actionButtons,
      bestMatchBadge,
      previouslyHiredBadge,
      autoHiredAsFavourite,
      nonNativeSpeakerBadge,
      competences,
      favouriteBadge = <div></div>;

    if (this.state.context === "viewer") {
      actionButtons = this.getActionButtons();
      bestMatchBadge = this.getBestMatchBadge();
      previouslyHiredBadge = this.getPreviouslyHiredBadge();
      autoHiredAsFavourite = this.getAutoHiredAsFavourite();
      nonNativeSpeakerBadge = this.getNonNativeSpeakerBadge();
      competences = this.getCompetences();
      favouriteBadge = this.getFavouriteBadge();
    }
    let jobsAndCancels = this.getJobsAndCancels();

    return (
      <div>
        <div className="freelancerProfile">
          <div className="freelancerProfile_top">
            <img
              className="freelancerProfile_image noDrag"
              alt="profile"
              src={this.state.profileImageUrl}
            />
            <div className="freelancerProfile_info">
              {actionButtons}
              <h1 className="freelancerProfile_name">
                {this.state.name} {favouriteBadge}
              </h1>
              <RatingStars freelancer={this.state.freelancer} company={null} />
              {jobsAndCancels}
              {this.props.distance && (
                <Distance distance={this.props.distance} />
              )}
              <div className="freelancerProfile_badgeWrapper">
                {bestMatchBadge}
                {previouslyHiredBadge}
                {autoHiredAsFavourite}
                {nonNativeSpeakerBadge}
              </div>
            </div>
          </div>
          <div className="freelancerProfile_content">
            {/* Competences */}
            {competences}
            {/* Cancellations */}
            <div className="freelancerProfile_resumeSection">
              <h2 className="freelancerProfile_resumeSectionTitle">
                {getLanguage(61, "Cancellations")}
              </h2>

              {this.renderCancelationSection()}
            </div>
            {/* Previous jobs */}
            <div className="freelancerProfile_resumeSection">
              <h2 className="freelancerProfile_resumeSectionTitle">
                {getLanguage(60, "Jobs taken")}
              </h2>
              {this.renderPreviousJobs()}
            </div>

            {/* Courses */}
            {this.renderCourses()}
            {/* Work experience */}
            <div className="freelancerProfile_resumeSection">
              <h2 className="freelancerProfile_resumeSectionTitle">
                {getLanguage(68, "Work experience")}
              </h2>
              {workExperience}
            </div>
            {/* Education */}
            <div className="freelancerProfile_resumeSection">
              <h2 className="freelancerProfile_resumeSectionTitle">
                {getLanguage(69, "Education")}
              </h2>
              {education}
            </div>
            {/* Description */}
            <div className="freelancerProfile_resumeSection">
              <h2 className="freelancerProfile_resumeSectionTitle">
                {getLanguage(70, "Description")}
              </h2>
              {description}
            </div>
          </div>
        </div>
        {this.translationButton()}
        {this.state.showPageLoader ? <PageLoader releaseNavbar={true} /> : null}
      </div>
    );
  }
}

function emptyContent(id: string) {
  return (
    <div key={id} className="freelancerProfile_extraDetails">
      <div className="freelancerProfile_companyLogo">
        <Skeleton variant="rect" animation="wave" width={45} height={45} />
      </div>
      <div className="extraDetails_info">
        <div className="extraDetails_tilte">
          <Skeleton variant="text" animation="wave" width={85} />
        </div>
        <p className="extraDetails_description">
          <Skeleton variant="text" animation="wave" width={250} />
        </p>
      </div>
    </div>
  );
}
