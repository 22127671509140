import React, { useState } from "react";
import { Grid, Dialog, DialogContent, DialogContentText } from "@material-ui/core";
import waveDark from "../../../../assets/img/shapes/waveDark.svg";
import "./Footer.scss";
import { NavLink } from "react-router-dom";
import { getLanguage } from "../../../../Helpers/LanguageHelper";
import { CMSTypes, CMSHelper } from "../../../../Helpers/CMSHelper";
import { StorageHelper } from "../../../../Helpers/StorageHelper";
import { CMSRevisionModel } from "../../../../Models/CMSRevisionModel";
import { SnackbarManager } from "../../../../Helpers/SnackbarManager/SnackbarManager";
interface IProps { }


export function Footer(props: IProps) {
  let storageHelper = new StorageHelper();
  let cmsHelper = new CMSHelper();

  const [displayMenu, setDisplayMenu] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState<CMSRevisionModel | null>(null);
  const [gdpr, setGdpr] = useState<CMSRevisionModel | null>(null);
  const [privacyPolicy, setPrivacyPolicy] = useState<CMSRevisionModel | null>(null);
  const [dialogueContent, setDialogueContent] = useState<null | CMSTypes>(null);


  function renderCmsDialogue(): React.ReactNode {
    let dialogueText = ''
    if ((dialogueContent === CMSTypes.TermsAndConditions || dialogueContent === CMSTypes.TermsAndConditionsCompany) && termsAndConditions != null) {
      dialogueText = termsAndConditions.Text;
    }
    if (dialogueContent === CMSTypes.PrivacyPolicy && privacyPolicy != null) {
      dialogueText = privacyPolicy.Text
    }

    if (dialogueContent === CMSTypes.Gdpr && gdpr != null) {
      dialogueText = gdpr.Text;
    }


    return (
      <Dialog
        open={displayMenu}
        onClose={_ => setDisplayMenu(false)}
      >
        <DialogContent>
          <DialogContentText dangerouslySetInnerHTML={{ __html: dialogueText }} />
        </DialogContent>
      </Dialog>
    );
  }


  async function prepareText(contentType: CMSTypes.TermsAndConditions | CMSTypes.PrivacyPolicy | CMSTypes.Gdpr | CMSTypes.TermsAndConditionsCompany) {
    if (contentType) {
      let country = storageHelper.getFacadeCountry();
      if (country == null) {
        SnackbarManager.Instance.addError(getLanguage(469, 'Unknown error'));
        return;
      }

      let content = await cmsHelper.getPrimaryDraftByCountry(country.Id, contentType);
      if (content == null) {
        SnackbarManager.Instance.addError(getLanguage(469, 'Unknown error'));
        return;
      }

      if (contentType === CMSTypes.TermsAndConditions || contentType === CMSTypes.TermsAndConditionsCompany) {
        setTermsAndConditions(content);
      } else if (contentType === CMSTypes.PrivacyPolicy) {
        setPrivacyPolicy(content);
      } else {
        setGdpr(content);
      }

      setDialogueContent(contentType);
      setDisplayMenu(true);
    }
  }

  return (
    <div className="Footer">
      <img className="topWave" src={waveDark} alt="svg wave" />
      <section className="section_dark sectionWithWave">
        <div className="container">
          <div className="footerHeading">{getLanguage(380, "Get started today")}</div>
          <div className="footerParagraph">
            {getLanguage(381, "Signing up is quick and easy. On Chabber you only pay when hiring a freelancer. Having a profile is completely free.")}
          </div>
          <div className="text-center">
            <div className="bottomCTAGroup">
              {/* <div id="frontpageCTA_inputWrapper">
                <input
                  type="email"
                  id="frontpageCTA_input"
                  placeholder={getLanguage(383, "your@mail.com")}
                />
              </div> */}
              <NavLink to="/register"><input type="submit" value={getLanguage(382, "Start!")} id="frontpageCTA_signUp" /></NavLink>
            </div>
          </div>
        </div>
      </section>
      <div className="mainFooter">
        <div className="container">
          <Grid
            container
            spacing={3}
            direction="row"
            justify="space-between"
            alignItems="flex-start"
          >
            <Grid item lg={4}>
              <NavLink to="/"><h1 className="white">CHABBER</h1></NavLink>
              <p>We fight to make being a chabber much more <br />than just a job. We want to bring people together. </p>
              <p><i className="far fa-copyright fa-fw"></i> Chabber Danmark ApS 2020 | CVR: 41 25 12 20</p>
            </Grid>
            <Grid item lg={2}>
              <h2>Product</h2>

              <NavLink to="/events"><p>{getLanguage(354, "Events")}</p></NavLink>
              <NavLink to="/kitchens"><p>{getLanguage(357, "Professional kitchens")}</p></NavLink>
              <NavLink to="/privatefunctions"><p>{getLanguage(359, "Private parties")}</p></NavLink>
              <NavLink to="/lookingforwork"><p>{getLanguage(548, "Looking for work")}</p></NavLink>
            </Grid>
            <Grid item lg={2}>
              <h2>Support</h2>
              <a href="http://faq.chabber.com" target="_blank"><p>{getLanguage(446, "FAQ")}</p></a>
              <p className="cursorPointer" onClick={() => prepareText(CMSTypes.TermsAndConditionsCompany)}> {getLanguage(866, "Terms and Conditions")}</p>
              <p className="cursorPointer" onClick={() => prepareText(CMSTypes.PrivacyPolicy)}>{getLanguage(442, "Privacy Policy")}</p>
              <p className="cursorPointer" onClick={() => prepareText(CMSTypes.Gdpr)}>{getLanguage(443, "GDPR Compliance")}</p>
            </Grid>
            <Grid item lg={2}>
              <h2>Company</h2>
              <NavLink to="/aboutus"><p>{getLanguage(384, "About us")}</p></NavLink>
              <NavLink to="/overview"><p>{getLanguage(444, "Blog")}</p></NavLink>
            </Grid>
            <Grid item lg={2}>
              <h2>{getLanguage(445, "Contact")}</h2>
              <p>DK: (+45) 60 80 39 94</p>
              {/* <p>NO: (+47) 21 95 92 03</p>
              <p>SWE: (+46) 85 250 31 45</p>
              <p>NL: (+31) 20 262 63 91</p> */}
              <a href="https://facebook.com/chabberapp/" target="_blank"><i className="fab fa-facebook-square fa-fw fa-lg"></i></a>
              <a href="https://www.instagram.com/chabberapp/" target="_blank"><i className="fab fa-instagram fa-fw fa-lg"></i></a>
              <a href="https://www.linkedin.com/company/chabber/" target="_blank"><i className="fab fa-linkedin fa-fw"></i></a>
            </Grid>
          </Grid>
        </div>
      </div>
      {renderCmsDialogue()}
    </div >
  );
}
