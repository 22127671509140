import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router'
import { Avatar } from '@material-ui/core';
import { LoginModel } from "../../../Models/LoginModel"
import { FreelancerModel } from "../../../Models/FreelancerModel"
import { CompanyModel } from "../../../Models/CompanyModel"
import { configureCloudinaryUrl } from '../../../Helpers/ImageHelper';

interface IProps {
    login: LoginModel | null,
    freelancer: FreelancerModel | null,
    company: CompanyModel | null
    customClass: string | null
    altText: string
}
interface IState { }

export class ProfilePictureViewer extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
    }

    render() {
        if (this.props.login == null) {
            return (<div>N/A</div>)
        }
        // Private
        if (this.props.company != null && this.props.company.IsPrivate) {
            return (<Avatar
                className={this.props.customClass != null ? this.props.customClass : "privateLogo"}
                alt={this.props.altText}
            >{this.getInitials()}</Avatar>)
        }

        // Freelancer/Company, with profile picture
        if (this.props.login.ProfilePictureUrl && this.props.login.ProfilePictureUrl.length > 0) {
            return (<img
                className={this.props.customClass != null ? this.props.customClass : ""}
                src={configureCloudinaryUrl(this.props.login.ProfilePictureUrl, ['w_300'])}
                alt={this.props.altText}
            />)
        }

        // No profile picture
        if (this.props.company != null) {
            return (<div                                                                        // TODO: Define default company picture  
                className={this.props.customClass != null ? this.props.customClass : ""}

            >
            </div>)
        } else {
            return (<div                                                                       // TODO: Define default freelancer picture
                className={this.props.customClass != null ? this.props.customClass : ""}

            ></div>)
        }
    }

    getInitials() {
        if (this.props.login == null || !this.props.login.Firstnames || !this.props.login.Lastname) {
            return 'N/A'
        }
        return this.props.login.Firstnames.substring(0, 1).toUpperCase() + this.props.login.Lastname.substring(0, 1).toUpperCase()
    }
}