import { Rule, RulesBySkillId } from "../Types";
import axios, { AxiosResponse } from "axios";
import { CompanyModel } from "../../../Models/CompanyModel";
import { StorageHelper } from "../../../Helpers/StorageHelper";
import { LocalDataModel } from "../../../Models/LocalDataModel";
import { useQuery } from "react-query";
import { CountryModel } from "../../../Models/CountryModel";
import { useEffect, useState } from "react";

export const DK_ID = "4d8744a7-cf06-426c-95b4-bd24e465bd4f";

export const getRulesBySkillId = async (
  skillId: string,
  companyId: string
): Promise<RulesBySkillId | undefined> => {
  const allRules = await getRules(companyId);
  if (!allRules) return;
  return allRules.find((x) => x.skillId == skillId);
};

export const getRules = async (
  companyId: string
): Promise<RulesBySkillId[] | undefined> => {
  const { data: company } = await axios.get<CompanyModel>(
    `/api/company/${companyId}`,
    {
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    }
  );

  if (!company) return;

  const companyCollectiveAgreementRules = company.CollectiveAgreementRules;
  if (!companyCollectiveAgreementRules) return;

  return JSON.parse(companyCollectiveAgreementRules) as RulesBySkillId[];
};

export const isCollectiveAgreementOnboardingAccepted = async (
  skillId: string,
  companyId?: string
) => {
  if (!companyId) return false;
  const skillIdRules = await getRulesBySkillId(skillId, companyId);

  if (!skillIdRules) return false;
  return skillIdRules.isAccepted;
};

export const isCollectiveAgreementOnboardingRequired = async (
  skillId: string,
  companyId?: string
) => {
  if (!companyId) return true;
  const skillIdRules = await getRulesBySkillId(skillId, companyId);

  if (!skillIdRules) return true;
  if (skillIdRules.rules.length === 0 && !skillIdRules.isAccepted) return false; //user said 'no' to collective agreement
  if (skillIdRules.isAccepted) return false; //user said 'yes' to collective agreement
  return true; //user haven't gone through collective agreement yet
};

export const getUserCountryId = (): string => {
  const localization =
    new StorageHelper().getFacadeLocalization() as LocalDataModel;
  return localization.country.Id ?? DK_ID; //hotfix for country id getting out of sync (facadelocalisation localstorage)
};

export const useDefaultCollectiveAgreementRules = (skillId: string) => {
  const [data, setData] = useState<any>();
  const countryId = getUserCountryId();
  const { data: response, isLoading } = useQuery<AxiosResponse<CountryModel>>(
    ["collective-agreement-rules", skillId],
    () => {
      return axios.get(
        `/api/countries/countrybyid?countryid=${
          !!countryId?.length ? countryId : DK_ID
        }`,
        {
          headers: {
            "Content-type": "application/json",
            authToken: new StorageHelper().getAccessToken(),
          },
        }
      );
    },
    {
      staleTime: 1 * 1000 * 60,
    }
  );

  useEffect(() => {
    if (!response) {
      setData({ data: [], isLoading });
      return;
    }
    const allRules = JSON.parse(
      response.data.CollectiveAgreementRules
    ) as RulesBySkillId[];

    setData({
      data:
        allRules
          .find((x) => x.skillId === skillId)
          ?.rules.map((x) => ({
            ...x,
            value: (Number(x.value) / 100).toFixed(2),
          })) || [],
      isLoading,
    });
  }, [response]);

  return { data, isLoading };
};
