import React from "react";
import { Grid } from "@material-ui/core";
import { OptimizedTextField } from "../../../../Components/Parts/General/OptimizedTextField";
import { TimeInterval } from "../../Types";
import { GetOvertimeHours } from "../../Utils";
import { getLanguage } from "../../../../Helpers/LanguageHelper";

type OffsetBetweenPredicateFormProps = {
    interval: TimeInterval;
    onChange: (next: TimeInterval) => void;
}

export function OffsetBetweenPredicateForm({ interval, onChange }: OffsetBetweenPredicateFormProps) {

    const onStartChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) =>
        onChange({
            start: e.target.value,
            end: interval.end
        });

    const onEndChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) =>
        onChange({
            start: interval.start,
            end: e.target.value
        })

    return <Grid container spacing={3}>
        <Grid item xs={6}>
            <OptimizedTextField
                label={getLanguage(878, 'From hour')}
                type="number"
                margin="dense"
                select
                value={interval.start}
                onChange={onStartChange}
            >
                {GetOvertimeHours()}
            </OptimizedTextField>
        </Grid>
        <Grid item xs={6}>
            <OptimizedTextField
                label={getLanguage(881, 'To and including hour')} 
                type='number'
                select
                margin="dense"
                value={interval.end}
                onChange={onEndChange}
            >
                {GetOvertimeHours()}
            </OptimizedTextField>
        </Grid>
    </Grid>
}