import moment from "moment";
import { FreelancerModel } from "../../../../../Models/FreelancerModel";
import { FavoriteProfileModel } from "../../../../../Models/FavoriteProfileModel";
import { RequestModel } from "../../../../../Models/RequestModel";
import { OvertimeRequestModel } from "../../../../../Models/OvertimeRequestModel";

export function getTimeString(value: number) {
  let val = value / 10;
  if (val >= 24) val -= 24;
  let label = val.toString() + ':00';
  if (value % 10 !== 0) label = Math.floor(val) + ':30';
  return label;
}

export function getTimeAsNumber(value: string | Date | null) {
  let mom = moment(value || undefined);
  return mom.get('hours') + mom.get('minutes') / 60;
}

export function getTimeRangeAsNumbers(valueA: string | Date, valueB: string | Date) {
  let momA = moment(valueA);
  let momB = moment(valueB);
  let diff = momB.diff(momA, 'minutes') / 60;
  let valA = momA.get('hours') + momA.get('minutes') / 60;
  let valB = valA + diff;

  return [valA, valB];
}

export function getFavouritedStatus(freelancer: FreelancerModel, favourites: FavoriteProfileModel[]): FavouriteStatus {
  if (!favourites) return FavouriteStatus.notInvitedToBeFavorite;
  let favourite = favourites.find(x => x.Freelancer != null && x.FreelancerProfileId === freelancer.Id);

  if (!favourite) {
    return FavouriteStatus.notInvitedToBeFavorite;
  }

  if (favourite.ApprovalStatus === 0) {
    return FavouriteStatus.invitedToBeFavorite;
  }

  if (favourite.ApprovalStatus === 1) {
    return FavouriteStatus.confirmedFavorite;
  }
  return FavouriteStatus.notInvitedToBeFavorite;
}

export function getMinimumSalary(request: RequestModel) {
  let salaryInterval: any[] = JSON.parse(request.SalaryIntervals);

  let minimumSalary = request.SalaryPerHour;

  if (salaryInterval.length > 0) {
    minimumSalary = Math.max(...salaryInterval.map(o => o.Salary), salaryInterval[0].Salary);
  }

  return minimumSalary / 100;
}

export function getFaveLangCodeForTooltip(langCode: FavouriteStatus) {
  if (langCode === 0) return 688; //Invited to be favourite
  if (langCode === 1) return 302; //Invite to be favourite
  if (langCode === 2) return 689; //Favourited 
  if (langCode === 3) return 688; //Invited to be favourite

  return 469; //unknown error
}

export interface ICompanyFeedback {
  request: RequestModel;
  freelancer: FreelancerModel;
  overtimeRequest: OvertimeRequestModel | undefined;
  endTime: number;
  favourited: FavouriteStatus;
  rating: number;
  originalRating: number;
  ratingReadOnly: boolean;
  overtimeSalary: number;
  minimumOvertimeSalary: number;
  hired: boolean;
  noShow: boolean;
  comment: string;
}

export enum DialogueTypes { chabber, manual, noShow, permaHire }

export enum PostState { loading, activePosting, pastPosting, archivedPosting, error }

export enum FavouriteStatus {
  invitedToBeFavorite = 0,
  notInvitedToBeFavorite = 1,
  confirmedFavorite = 2,
  notConfirmedFavorite = 3
}