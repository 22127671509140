import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { Grid, Dialog, DialogContent } from "@material-ui/core";
import { YouTubeEmbed } from "../../../../Parts/General/YouTubeEmbed";
import { Footer } from "../../Navigation/Footer";
import { getLanguage } from "../../../../../Helpers/LanguageHelper";
import YouTube from "react-youtube";
interface IProps {
}
interface IState {
  dialogOpen: boolean;
}
export class EventCaseVenues extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      dialogOpen: false,
    }
  }

  render() {

    return (
      <div>
        <div className="paper">
          <Grid container spacing={0} alignItems="stretch">
            <Grid item sm={6}>
              <div className="videoCase">
                <div className="caseTitle">
                  {getLanguage(
                    362,
                    "This is why Copenhagen Venues outsources all their staffing to Chabber"
                  )}
                </div>
                <div className="caseContent">
                  <i>
                    {getLanguage(
                      363,
                      "With Chabber we don't have to spends hours working out the work schedule. It removes a lot of pressure before and during events."
                    )}
                  </i>
                  <br />
                  <div className="text-right">
                    {getLanguage(364, "– Sarah, F&B Afvikler.")}
                  </div>
                  <br />
                  {getLanguage(
                    365,
                    "Copenhagen Venues operates the Locomotive Workshop in Copenhagen. A 6,500 m2 warehouse and event venue that seats 3,850 guests. Since 2017, Copenhagen Venues has used Chabber to events big and small, as well as cover urgent gaps in the work plan. They hire waiters, bartenders and wardrobe staff."
                  )}
                </div>

                <div className="cardCTA" onClick={() => this.setState({ dialogOpen: true })}>
                  {getLanguage(352, "Watch video")}
                  <span>
                    <i className="fas fa-play fa-fw brand" />
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="videoPoster caseOne" onClick={() => this.setState({ dialogOpen: true })}>
                <div className="videoPoster_playIcon">
                  <i className="fas fa-play fa-4x"></i>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Dialog open={this.state.dialogOpen} onClose={() => this.setState({ dialogOpen: false })} fullWidth maxWidth="md">
          <DialogContent>
            <YouTubeEmbed videoID="Km7u9dtLnnc" />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
