import { AxiosRequestConfig } from 'axios';
import { StorageHelper } from './StorageHelper';
import { CommunicationTemplate } from '../Models/CommunicationTemplate';
import { CallManager } from './CallManager/CallManager';




export class CommunicationHelper {
    storageHelper = new StorageHelper();
    clientKey = '04216571796E48ABB8FD781DEA966A4F';


    async getAllEvents() {
        try {
            let config: AxiosRequestConfig = {
                method: 'GET',
                url: '/api/communicationevent',
                headers: {
                    'Content-Type': 'application/json',
                    clientKey: this.clientKey
                }
            }
            let response = await CallManager.Instance.makeCall(config, false, true);
            return response.data;
        } catch (error: any) {
            console.log(error);
            return false;
        }
    }

    async getAllCommunicationTemplates(languageId: string) {
        try {
            let config: AxiosRequestConfig = {
                method: 'GET',
                url: '/api/communicationtemplate?LanguageId=' + languageId,
                headers: {
                    'Content-Type': 'application/json',
                    clientKey: this.clientKey
                }
            }
            let response = await CallManager.Instance.makeCall(config, false, true);
            return response.data;
        } catch (error: any) {
            console.log(error);
            return false;
        }
    }

    async getAllCommunicationTemplateMasters(languageId: string) {
        try {
            let config: AxiosRequestConfig = {
                method: 'GET',
                url: '/api/communicationtemplatemaster?LanguageId=' + languageId,
                headers: {
                    'Content-Type': 'application/json',
                    clientKey: this.clientKey
                }
            }
            let response = await CallManager.Instance.makeCall(config, false, true);
            return response.data;
        } catch (error: any) {
            console.log(error);
            return false;
        }
    }

    async getAllCommunicationEventBindings(languageId: string) {
        try {
            let config: AxiosRequestConfig = {
                method: 'GET',
                url: '/api/communicationeventtemplatebinding?LanguageId=' + languageId,
                headers: {
                    'Content-Type': 'application/json',
                    clientKey: this.clientKey
                }
            }
            let response = await CallManager.Instance.makeCall(config, false, true);
            return response.data;
        } catch (error: any) {
            console.log(error);
            return false;
        }
    }

    async insertCommunicationTemplate(template: CommunicationTemplate) {
        try {
            let config: AxiosRequestConfig = {
                method: 'POST',
                url: '/api/communicationtemplate',
                headers: {
                    'Content-Type': 'application/json',
                    clientKey: this.clientKey
                },
                data: template
            }
            let response = await CallManager.Instance.makeCall(config, false, true);
            return response.data;
        } catch (error: any) {
            console.log(error);
            return false;
        }
    }

    async insertCommunicationEventBindingsForEventIdAndTemplateId(eventId: string, templateId: string, languageId: string, version: number) {
        try {
            let config: AxiosRequestConfig = {
                method: 'POST',
                url: '/api/communicationeventtemplatebinding/forallenvironments/' + eventId + '/' + templateId + '/' + languageId + '/' + version,
                headers: {
                    'Content-Type': 'application/json',
                    clientKey: this.clientKey
                },
            }
            let response = await CallManager.Instance.makeCall(config, false, true);
            return response.data;
        } catch (error: any) {
            console.log(error);
            return false;
        }
    }

    async deleteCommunicationEventBindingsForEventIdAndCommunicationType(eventId: string, communicationType: number, languageId: string) {
        try {
            let config: AxiosRequestConfig = {
                method: 'DELETE',
                url: '/api/communicationeventtemplatebinding/deleteAll/' + eventId + '/' + communicationType + '/' + languageId,
                headers: {
                    'Content-Type': 'application/json',
                    clientKey: this.clientKey
                },
            }
            let response = await CallManager.Instance.makeCall(config, false, true);
            return response.data;
        } catch (error: any) {
            console.log(error);
            return false;
        }
    }


}