import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Dialog,
  DialogTitle,
  InputAdornment,
  Button,
} from "@material-ui/core";
import axios, { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { StorageHelper } from "../../../Helpers/StorageHelper";
import { CountryModel } from "../../../Models/CountryModel";
import { DK_ID } from "../../../Modules/CollectiveAgreement";
import {
  Rule,
  RulesBySkillId,
} from "../../../Modules/CollectiveAgreement/Types";
import { formatAdornment } from "../../../Modules/CollectiveAgreement/Utils";
import { CountrySelector } from "../../Parts/General/CountrySelector";
import { CurrencyInput } from "../../Parts/General/CurrencyInput";

interface CollectiveAgreementRuleFormProps {
  rule: Rule;
  onSubmit: (rule: Rule) => void;
}

const CollectiveAgreementRuleForm = ({
  rule,
  onSubmit,
}: CollectiveAgreementRuleFormProps) => {
  const [formState, setFormState] = useState<Rule>(rule);
  return (
    <div>
      <CurrencyInput
        margin="normal"
        key={formState.identifier}
        label={formState.title}
        value={formState.value.toString()}
        defaultValue={formState.value}
        onChange={(e) => {
          setFormState({
            ...formState,
            value: e,
          });
        }}
        error={!!formState.validationResult}
        helperText={formState.validationResult || <span>&nbsp;&nbsp;</span>}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {formatAdornment(formState.handler)}
            </InputAdornment>
          ),
          inputProps: {
            style: { textAlign: "right", paddingRight: "2px" },
          },
        }}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Button
        onClick={() => {
          onSubmit(formState);
        }}
      >
        Save
      </Button>
    </div>
  );
};

export const CollectiveAgreementRulesView = () => {
  const [selectedCountryId, setSelectedCountryId] = useState<
    string | undefined
  >();

  const [rulesBySkillId, setRulesBySkillId] = useState<
    RulesBySkillId[] | undefined
  >();

  const [selectedForEdit, setSelectedForEdit] = useState<
    { skillIndex: number; ruleIndex: number } | undefined
  >();

  const allSkills = new StorageHelper().getSkillsFromLocal();

  const { data: response } = useQuery<AxiosResponse<CountryModel>>({
    enabled: !!selectedCountryId,
    queryKey: ["country-details", selectedCountryId],
    queryFn: () => {
      return axios.get<CountryModel>(
        `/api/countries/countrybyid?countryid=${selectedCountryId ?? DK_ID}`,
        {
          headers: {
            "Content-type": "application/json",
            authToken: new StorageHelper().getAccessToken(),
          },
        }
      );
    },
  });

  const mutation = useMutation((payload: { rules: RulesBySkillId[] }) => {
    return axios.post(
      `/api/countries/${selectedCountryId}/collective-agreement`,
      {
        rules: JSON.stringify(
          payload.rules.map((x) => ({
            ...x,
            rules: x.rules.map((y) => ({ ...y, value: Number(y.value) * 100 })),
          }))
        ),
      },
      {
        withCredentials: true,
        headers: {
          "Content-type": "application/json",
          authToken: new StorageHelper().getAdminToken(),
        },
      }
    );
  });

  useEffect(() => {
    if (!response?.data) return;
    const rulesParsed = JSON.parse(
      response.data.CollectiveAgreementRules
    ) as RulesBySkillId[];
    setRulesBySkillId(
      rulesParsed.map((x) => ({
        ...x,
        rules: x.rules.map((y) => ({
          ...y,
          value: (Number(y.value) / 100).toFixed(2),
        })),
      }))
    );
  }, [response?.data]);

  return (
    <div>
      <div className="AdminPageHeader">
        <div className="AdminPageTitle">Collective agreement rules</div>
        <div className="AdminPageHeader_options">
          <div className="inputGroup">
            <CountrySelector
              className="inputGroup_item"
              valueChanged={(id) => setSelectedCountryId(id)}
            />
          </div>
        </div>
      </div>
      {rulesBySkillId?.map((rulesGroup, groupIndex) => (
        <div>
          <h2>{allSkills.find((x) => x.Id === rulesGroup.skillId)?.Title}</h2>
          <div className="adminTable">
            <Table className="table">
              <TableHead className="tableHead">
                <TableRow className="tableRow">
                  <TableCell>Title</TableCell>
                  <TableCell>Value</TableCell>
                  <TableCell>Handler</TableCell>
                  <TableCell>Identifier</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rulesGroup.rules.map((rule, ruleIndex) => (
                  <TableRow key={ruleIndex}>
                    <TableCell className="tableCell">{rule.title}</TableCell>
                    <TableCell className="tableCell">{rule.value}</TableCell>
                    <TableCell className="tableCell">{rule.handler}</TableCell>
                    <TableCell className="tableCell">
                      {rule.identifier}
                    </TableCell>
                    <TableCell className="tableCell">
                      <Tooltip
                        title="Edit"
                        placement="top"
                        onClick={() =>
                          setSelectedForEdit({
                            skillIndex: groupIndex,
                            ruleIndex,
                          })
                        }
                      >
                        <i className="fas fa-edit"></i>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      ))}
      <Dialog
        onClose={() => setSelectedForEdit(undefined)}
        open={!!selectedForEdit}
      >
        <DialogTitle>Edit rule default value</DialogTitle>
        {selectedForEdit && rulesBySkillId && (
          <CollectiveAgreementRuleForm
            rule={
              rulesBySkillId[selectedForEdit?.skillIndex].rules[
                selectedForEdit?.ruleIndex
              ]
            }
            onSubmit={(rule) => {
              const copy = [...rulesBySkillId];
              copy[selectedForEdit.skillIndex].rules[
                selectedForEdit.ruleIndex
              ] = rule;
              setRulesBySkillId(copy);
              setSelectedForEdit(undefined);
            }}
          ></CollectiveAgreementRuleForm>
        )}
      </Dialog>
      <Button
        onClick={() => {
          if (rulesBySkillId) {
            mutation.mutateAsync({
              rules: rulesBySkillId,
            });
          }
        }}
      >
        Save
      </Button>
    </div>
  );
};
