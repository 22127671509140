import React, { Component } from "react";
import Switch from "@material-ui/core/Switch";
import { ClearanceModel } from '../../../Models/ClearanceModel';

interface IProps {
  userClearances: ClearanceModel[];
  allClearances: ClearanceModel[];
  clearanceChanged: (clearance: ClearanceModel) => void
}

interface IState {

}

export class Clearance extends Component<IProps, IState> {

  ValueChanged(elem: ClearanceModel) {
    this.props.clearanceChanged(elem);
  }

  render() {
    return (
      <div className="paper padding-24 radius-6 clearanceAdmin">
        <h2>Clearance levels</h2>

        {this.props.allClearances.map(row => (
          <div className="toggleBlock" key={row.Id}>
            <div className="toggleBlock_title">{row.Name}</div>
            <div className="toggleBlock_switch">
              <Switch
                checked={this.props.userClearances.findIndex(x => x.Id === row.Id) !== -1}
                onChange={() => this.ValueChanged(row)}
                className="toggleSwitch" color="primary"
              />
            </div>
          </div>
        ))}
      </div>
    );
  }
}
