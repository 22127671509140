// @ts-nocheck
import React, { useState, useEffect } from "react";
import Tabs from "../../../Parts/General/Tabs";
import TabContent from "../../../Parts/General/TabContent";
import { RouteComponentProps } from "react-router";
import { SignupsTab } from "./SignupsTab";
import { CourseDataTab } from "./CourseDataTab";
import { CourseHelper } from "../../../../Helpers/CourseHelper";
import qs from 'query-string';
import { CourseModel } from "../../../../Models/CourseModel";
import { Button, TextField, InputAdornment } from "@material-ui/core";
import { SnackbarManager } from "../../../../Helpers/SnackbarManager/SnackbarManager";

interface IProps extends RouteComponentProps { }

export function Course(props: IProps) {
  let courseHelper = new CourseHelper();
  const [tabIndex, setTabIndex] = useState(0);
  const [course, setCourse] = useState<CourseModel | null>(null)
  const [title, setTitle] = useState('')


  useEffect(() => {
    if (props.location.state && props.location.state.courseData) {
      setTabIndex(1);
    }
    getData();
  }, [props.location.state])

  async function getData() {
    let id = qs.parse(props.location.search).id;
    if (!id) return;
    if (Array.isArray(id)) id = id[0];

    let currentCourse = await courseHelper.getCourseById(id);
    setCourse(currentCourse);
    setTitle((currentCourse?.Title) || '');
  }

  function updateCourse(course: CourseModel) {
    setCourse(course);
  }

  async function sendRegistrationMail() {
    if (!course?.Id) return;
    let sendt = await courseHelper.sendRegistrationMail(course.Id);
    if (sendt) {
      SnackbarManager.Instance.addSuccess('Email sent');
    } else {
      SnackbarManager.Instance.addError('Email could not be sent');
    }
  }

  function openParticipationWindow() {
    window.open('/courseattendance?id=' + course?.Id);
  }

  return (
    <div>
      <div className="AdminPageHeader">
        <div className="AdminPageTitle">
          {title}
        </div>
        <div className="AdminPageHeader_options">
          <div className="inputGroup">
            <Button className="inputGroup_item white" variant="contained" size="medium"
              onClick={() => sendRegistrationMail()}
            >
              <span className="btn-icon">
                <i className="fas fa-envelope" />
              </span>
              Send Registration mail
            </Button>
            <Button className="inputGroup_item white" variant="contained" size="medium"
              onClick={() => openParticipationWindow()}
            >
              <span className="btn-icon">
                <i className="fas fa-user-check" />
              </span>
              Register
              </Button>
          </div>
        </div>
      </div>
      <Tabs value={tabIndex} changeValue={setTabIndex}>
        <TabContent title="Sign-ups">
          <SignupsTab reset={getData} updateCourseData={updateCourse} course={course} {...props} />
        </TabContent>
        <TabContent title="Course data">
          <CourseDataTab titleChanged={title => setTitle(title)} course={course} {...props} />
        </TabContent>
      </Tabs>
    </div>
  );
}
