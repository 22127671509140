import { SkillModel } from "./SkillModel";
import { ExperienceCategoryModel } from "./ExperienceCategoryModel";
import { CompetenceQuestionModel } from "./CompetenceQuestionModel";
import moment, { Moment } from "moment";

export class StaticDataModel {
  skills: SkillModel[];
  experienceCategories: ExperienceCategoryModel[];
  competenceQuestions: CompetenceQuestionModel[];
  creationDate: Moment;

  constructor(val: Partial<StaticDataModel>) {
    this.skills = val.skills || [];
    this.experienceCategories = val.experienceCategories || [];
    this.competenceQuestions = val.competenceQuestions || [];
    if (typeof val.creationDate === 'string') {
      this.creationDate = moment(val.creationDate);
    } else if (val.creationDate) {
      this.creationDate = val.creationDate;
    } else {
      this.creationDate = moment();
    }
  }
}