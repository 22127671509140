import React, { useMemo } from "react";
interface IProps {
  ratingValue: number;
}
export const RatingStarsV2 = ({ ratingValue }: IProps) => {
  // converts the range from 0-100 to 0-5
  const ratingMappedToStars = useMemo(
    () => (ratingValue * 5) / 100,
    [ratingValue]
  );

  //logic from legacy RatingsStars.tsx. Should be reviewed, covered with unit tests and refactored.
  const stars = useMemo(() => {
    let acc = [];
    for (let index = 0; index < Math.floor(ratingMappedToStars); index++) {
      acc.push(<i key={index} className="fas fa-star"></i>);
    }
    if (
      ratingMappedToStars - acc.length > 0.2 &&
      ratingMappedToStars - acc.length < 0.8
    ) {
      acc.push(<i key="7" className="fas fa-star-half-alt"></i>);
    } else if (ratingMappedToStars > 4.75) {
      acc = [];
      for (let index = 0; index < 5; index++) {
        acc.push(<i key={index} className="fas fa-star"></i>);
      }
    }

    for (let index = acc.length; index < 5; index++) {
      acc.push(<i key={"a" + index} className="far fa-star"></i>);
    }
    return acc;
  }, [ratingMappedToStars]);

  return <>{stars}</>;
};
