import React, { Component, ChangeEvent } from "react";
import { Select, OutlinedInput, MenuItem } from '@material-ui/core';
import { CountryHelper } from "../../../Helpers/CountryHelper";
import { CountryModel } from "../../../Models/CountryModel";
import { StorageHelper } from "../../../Helpers/StorageHelper";

interface IProps {
  valueChanged?: (countryId: string, field?: any) => void
  disabled?: boolean
  fullWidth?: boolean
  className?: string
}

interface IState {
  country: number
  countries: CountryModel[]
}

export class CountrySelector extends Component<IProps, IState> {
  storageHelper = new StorageHelper();
  constructor(props: IProps) {
    super(props);
    this.state = {
      country: 1,
      countries: []
    }
    this.valueChanged = this.valueChanged.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    let countries = await (new CountryHelper()).getActiveCountries();
    if (countries) {
      let countryId = this.storageHelper.getCountryFromLocal();
      let index = countries.findIndex(x => x.Id === countryId);
      this.setState({ countries, country: index });
      if (this.props.valueChanged != null) {
        if (index === -1) {
          return this.props.valueChanged('', 'country');
        }
        this.props.valueChanged(countries[index].Id, 'country');
      }
    } return false;
  }

  valueChanged(event: ChangeEvent<{ name?: string | undefined; value: any; }>, name: string) {
    let index: number = parseInt(event.target.value);
    let countryId = '';
    if (index !== -1) {
      countryId = this.state.countries[index].Id;
    }
    this.storageHelper.setCountryInLocal(countryId);
    this.storageHelper.setRegionInLocal('');
    this.setState({ country: index });
    if (this.props.valueChanged != null) {
      this.props.valueChanged(countryId, 'country');
    }
  }

  buildCountrySelects() {
    let countrySelects = [<MenuItem key='-1' value='-1'>{'Global'}</MenuItem>];

    for (let i = 0; i < this.state.countries.length; i++) {
      let country = this.state.countries[i];
      countrySelects.push(<MenuItem key={i} value={i}>{country.CountryName}</MenuItem>)
    }
    return countrySelects;
  }

  render() {
    let countrySelects = this.buildCountrySelects();
    return (
      <Select
        fullWidth={this.props.fullWidth}
        className={this.props.className}
        disabled={this.props.disabled}
        classes={{ select: 'inputGroup_item_inner' }}
        value={this.state.country}
        onChange={event => this.valueChanged(event, "country")}
        input={
          <OutlinedInput
            labelWidth={0}
            name="county"
            margin="dense"
            id="outlined-country-simple"
          />
        }
      >
        {countrySelects}
      </Select>
    );
  }
}