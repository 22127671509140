import { PostingModel } from "./PostingModel";
import { RatingModel } from "./RatingModel";
import { RequestModel } from "./RequestModel";

export class PastPostingModel {
  AllJobsCount: number;
  CountJobs: number;
  HiddenPostingsExist: boolean;
  Jobs: PostingModel[];
  OvertimeRequests: any[]; //FIXME: What type is this?
  Ratings: RatingModel[];
  ReceivedRatings: RatingModel[];
  Requests: RequestModel[];
  ThisUsersJobsCount: number;

  constructor(val: Partial<PastPostingModel>) {
    this.AllJobsCount = val.AllJobsCount || 0;
    this.CountJobs = val.CountJobs || 0;
    this.HiddenPostingsExist = val.HiddenPostingsExist || false;
    this.Jobs = val.Jobs || [];
    this.OvertimeRequests = val.OvertimeRequests || [];
    this.Ratings = val.Ratings || [];
    this.Requests = val.Requests || [];
    this.ThisUsersJobsCount = val.ThisUsersJobsCount || 0;
    this.ReceivedRatings = val.ReceivedRatings || [];
  }
}
