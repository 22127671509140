import { AxiosRequestConfig } from "axios";
import { CallManager } from "./CallManager/CallManager";
import { CourseRegistrationModel } from "../Models/CourseRegistrationModel";
import { CourseModel } from "../Models/CourseModel";

export class CourseRegistrantHelper {


  async getRegistrationsByCourses(courseIds: string[]): Promise<CourseRegistrationModel[]> {
    try {
      let config: AxiosRequestConfig = {
        method: 'get',
        url: '/api/courseregistrationbycourse',
        params: {
          courseIds
        }
      }
      let response = await CallManager.Instance.makeCall(config, false, false);
      return response.data.map((x: CourseRegistrationModel) => new CourseRegistrationModel(x))
    } catch (error: any) {
      console.log(error);
      return [];
    }
  }

  async getFreelancersRegistrations(): Promise<CourseRegistrationModel[]> {
    try {
      let config: AxiosRequestConfig = {
        method: 'get',
        url: '/api/courseregistrations',
      }
      let response = await CallManager.Instance.makeCall(config, false, false);
      return response.data.map((x: CourseRegistrationModel) => new CourseRegistrationModel(x));
    } catch (error: any) {
      return [];
    }
  }

  async getFreelancersCourseGraduations(freelancerId: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'get',
        url: '/api/course/freelancersgraduations',
        params: {
          freelancerId
        }
      }
      let response = await CallManager.Instance.makeCall(config, false, false);
      return response.data;
    } catch (error: any) {
      return [];
    }
  }

  async submitRegistration(registration: CourseRegistrationModel) {
    try {
      let config: AxiosRequestConfig = {
        method: 'post',
        url: '/api/courseregistration',
        data: registration
      }
      let response = await CallManager.Instance.makeCall(config, false, false);
      return response.data;
    } catch (error: any) {
      return null;
    }
  }

  async registerAttendance(registrantId: string, courseId: string, courseDateId: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'post',
        url: '/api/courseattendance',
        data: {
          courseId,
          registrantId,
          courseDateId
        },
        withCredentials: true
      }
      let response = await CallManager.Instance.makeCall(config, false, false);

      return response.data;
    } catch (error: any) {
      console.log(error);
      return null;
    }
  }

  async unregisterAttendance(registrantId: string, courseId: string, courseDateId: string, attendanceId: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'delete',
        url: '/api/courseattendance',
        params: {
          courseId,
          registrantId,
          courseDateId,
          attendanceId
        },
        withCredentials: true
      }
      let response = await CallManager.Instance.makeCall(config, false, false);

      return response.data;
    } catch (error: any) {
      console.log(error);
      return null;
    }
  }

  async registerGraduation(registrantId: string, courseId: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'post',
        url: '/api/courseattendance/graduation',
        data: {
          registrantId,
          courseId,
        }
      }
      let response = await CallManager.Instance.makeCall(config, false, false);
      return response.data as boolean;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  async unregisterGraduation(registrantId: string, courseId: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'delete',
        url: '/api/courseattendance/graduation',
        params: {
          registrantId,
          courseId,
        }
      }
      let response = await CallManager.Instance.makeCall(config, false, false);
      return response.data as boolean;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }
}