import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Table, TableHead, TableRow, TableCell, TableBody, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Button } from "@material-ui/core";
import { NavLink } from 'react-router-dom';
import { CourseModel } from '../../../../Models/CourseModel';
import { dateAsFormatedStringWithoutTimeDK } from '../../../../Helpers/DateTimeHelper';

interface IProps extends RouteComponentProps {
  courses: CourseModel[];
  countryId: string;
  searchQuery: string;
  highlightedCourseId: string;
  deleteCourse: (id: string) => Promise<boolean>;
}

export function CoursesTab(props: IProps) {

  const [idForDeletion, setIdForDeletion] = useState('');


  function renderCourses() {
    let filteredCourses: CourseModel[];
    if (props.countryId != null && props.countryId !== '') {
      filteredCourses = props.courses.filter(x => x.CountryId === props.countryId);
    } else {
      filteredCourses = props.courses;
    }

    if (props.searchQuery !== '') {
      filteredCourses = filteredCourses.filter(x => x.Title.toLowerCase().includes(props.searchQuery.toLowerCase()));
    }

    filteredCourses.sort((a, b) => a.CourseDate < b.CourseDate ? 1 : -1)

    return (
      filteredCourses.map(x => {
        let icon = 'fas fa-check-circle success';
        if (x.Deactivated) icon = 'fas fa-ban danger';
        else if (x.Registrations.length < x.MaxRegistrations) icon = 'fas fa-circle-notch warning';

        let classname = 'tableRow';
        if (props.highlightedCourseId && props.highlightedCourseId === x.Id) {
          classname += ' added';
        }

        return (
          // TODO: Sean, add highlighting here based on active
          <TableRow key={x.Id} className={classname}>
            <TableCell className="tableCell"> {dateAsFormatedStringWithoutTimeDK(x.CourseDate)} </TableCell>
            <TableCell className="tableCell">
              <NavLink to={"/admin/course?id=" + x.Id}> {x.Title} </NavLink>
            </TableCell>
            <TableCell className="tableCell">{x.CoursePartner?.Name}</TableCell>
            <TableCell className="tableCell"> {x.Registrations.length + '/' + x.MaxRegistrations}</TableCell>
            <TableCell className="tableCell tableStatus" align="center"><i className={icon}></i></TableCell>
            {
              x.Deactivated ?
                <TableCell className="tableCell" />
                :
                <TableCell className="tableCell tableOptions" onClick={() => setIdForDeletion(x.Id)}>
                  <i hidden={x.Deactivated} className="fas fa-trash-alt"></i>
                </TableCell>
            }
          </TableRow>
        )
      })
    );
  }

  async function deleteCourse() {
    let result = await props.deleteCourse(idForDeletion);
    if (result) {
      setIdForDeletion('');
    }
  }

  function renderDeletionDialog() {
    let course = props.courses.find(x => x.Id === idForDeletion);

    if (idForDeletion === '' || course == null) return;

    return (
      <Dialog
        open={idForDeletion !== ''}
        onClose={() => setIdForDeletion('')}
      >
        <DialogTitle>Delete {course.Title}? </DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete {course.Title} </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIdForDeletion('')}>Cancel</Button>
          <Button onClick={deleteCourse}>Delete</Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <div className='adminTable'>
      <Table className="table">
        <TableHead className="tableHead">
          <TableRow className="tableRow">
            <TableCell>Date</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Partner</TableCell>
            <TableCell>Spots</TableCell>
            <TableCell className="tableStatus" align="right">Status</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="tableBody">
          {renderCourses()}
        </TableBody>
      </Table>
      {renderDeletionDialog()}

    </div>
  )
}