import React, { useState } from 'react';
import { Button, Dialog, DialogContent, DialogActions, Checkbox, DialogContentText, DialogTitle, FormControlLabel } from "@material-ui/core";
import { CourseModel } from "../../../../../Models/CourseModel";
import { CoursePartnerModel } from '../../../../../Models/CoursePartnerModel';
import { StorageHelper } from '../../../../../Helpers/StorageHelper';

interface IProps {
  open: boolean;
  course: CourseModel | undefined;
  coursePartner: CoursePartnerModel | null;
  closeDialogue: () => void;
  submit: () => void;
}

export function TermsDialogue(props: IProps) {
  let storageHelper = new StorageHelper();
  const [termsChecked, setTermsChecked] = useState(false)

  if (!props.course) return <></>;
  let terms = props.course.CustomTerms || props.coursePartner?.DefaultCourseTerms;

  let localData = storageHelper.getFacadeLocalization();
  let currency = localData?.country.Currency?.Shorthand;
  if (localData?.country.Currency?.OutputFormat === 'A') {
    currency = props.course.ValueInLocalCurrency + ' ' + (currency || '');
  } else if (localData?.country.Currency?.OutputFormat === 'B') {
    currency = (currency || '') + ' ' + props.course.ValueInLocalCurrency;
  }

  return (
    <Dialog
      open={props.open}
      onClose={() => props.closeDialogue()}
    >
      <DialogTitle>Course Terms</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {terms}
        </DialogContentText>
        <DialogContentText>
          Total cost if terms not met: {currency}
        </DialogContentText>
        <FormControlLabel
          label="Read and accepted"
          labelPlacement="end"
          control={
            <Checkbox color="primary"
              onChange={e => setTermsChecked(e.target.checked)}
              checked={termsChecked}
            />
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.closeDialogue}>Cancel</Button>
        <Button
          onClick={props.submit}
          disabled={termsChecked === false}
        >Submit</Button>
      </DialogActions>
    </Dialog>
  )
}