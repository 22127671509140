import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Grid } from '@material-ui/core';
import { getLanguage } from '../../../../../Helpers/LanguageHelper';

interface IProps { }

export function CommunityCompany(props: IProps) {
    return (
        <div className="communityCompanyView">
            <Grid container>
                <Grid item md={6}>
                    <img className="noDrag" src="/img/photos/community/community_company.jpg" alt="Macbook with product image" width="800px" id="communityImage" />
                </Grid>
                <Grid item md={6}>
                    <div className="displayFont">
                        {getLanguage(758, "We're all about people")}<br />{getLanguage(759, "Here are a few examples of how that shows.")}
                    </div>
                    <div className="margin-top-40">
                        <div className="communityCompany_item">
                            <div className="communityCompany_title">{getLanguage(760, "We employ our chabbers ")}</div>
                            <div className="communityCompany_content">{getLanguage(763, "We were one of the world’s first platforms to employ our freelancers. We want to offer them security, social benefits and make sure that their taxes are reported. ")}</div>
                        </div>
                        <div className="communityCompany_item">
                            <div className="communityCompany_title">{getLanguage(761, "Flexibility should not come at a price")}</div>
                            <div className="communityCompany_content">{getLanguage(764, "Freelancers usually exchange personal growth, leadership and social relationships for flexibility. We’re changing that.")}</div>
                        </div>
                        <div className="communityCompany_item">
                            <div className="communityCompany_title">{getLanguage(762, "There are humans behind our technology")}</div>
                            <div className="communityCompany_content">{getLanguage(765, "We’ve got Community Managers dedicated to making sure that our freelancers thrive and succeed.")}</div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}