// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from "react";
import {
  Grid,
  Menu,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Hidden
} from "@material-ui/core";
import illustration from "../../../../assets/img/illustrations/cooperate.svg";
import "./Users.scss";
import { CompanyHelper } from "../../../../Helpers/CompanyHelper";
import { LoginModel } from "../../../../Models/LoginModel";
import { getLanguage } from "../../../../Helpers/LanguageHelper";
import { MobileSidebarTrigger } from "../../../Parts/General/MobileSidebarTrigger";
import { StorageHelper } from "../../../../Helpers/StorageHelper";
import { SnackbarManager } from "../../../../Helpers/SnackbarManager/SnackbarManager";

interface IProps { }

interface IState {
  open: boolean;
  companyLogins: CompanyLoginsModels[];
  userStatus: string;
  newUserFirstnames: string;
  newUserEmail: string;
  newUserLoginPhone: string;
  newuserAdmin: number;
  vmUserRole: string;
  openMenu: boolean;
  anchorEl: any;
  activeUser: number;
  noUsers: string;
  users: string;
  userToEdit: CompanyLoginsModels | null;
  actionButton: string;
}

interface CompanyLoginsModels extends LoginModel {
  UserTag: string;
  AdminGrantedClassname: string;
  isMaster: boolean;
  shouldHideIfMaster: string;
  vmUserRole?: string;
}

export class Users extends Component<IProps, IState> {
  companyHelper = new CompanyHelper();
  storageHelper = new StorageHelper();

  constructor(props: IProps) {
    super(props);

    this.state = {
      open: false,
      companyLogins: [],
      userStatus: "",
      newUserFirstnames: "",
      newUserEmail: "",
      newUserLoginPhone: "",
      newuserAdmin: 0,
      vmUserRole: "user",
      openMenu: false,
      anchorEl: null,
      activeUser: -1,
      noUsers: "emptyState_wrapper hidden",
      users: "hidden",
      userToEdit: null,
      actionButton: "Send invite"
    };

    this.preparePage = this.preparePage.bind(this);
    this.saveUser = this.saveUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  async componentDidMount() {
    await this.preparePage();
  }

  async preparePage() {
    let result = await this.companyHelper.getCompanyLogins();
    let companyLogins: CompanyLoginsModels[] = [];
    if (result && result.data) companyLogins = result.data;

    console.log(companyLogins)

    if (companyLogins && companyLogins.length !== 0) {
      for (let i = 0; i < companyLogins.length; i++) {
        if (companyLogins[i].isMaster) {
          console.log("isMaster")
          companyLogins.splice(i, 1);
        }
        if (companyLogins.length !== 0) {
          if (companyLogins[i].AdminGranted) {
            companyLogins[i].AdminGrantedClassname = "userStatus_admin";
            companyLogins[i].UserTag = getLanguage(240, "Admin");
          } else {
            companyLogins[i].AdminGrantedClassname = "userStatus_user";
            companyLogins[i].UserTag = getLanguage(241, "User");
          }
        }
      }
      if (companyLogins.length === 0) {
        await this.setState({ noUsers: "emptyState_wrapper", users: "hidden" });
      } else {
        await this.setState({ noUsers: "emptyState_wrapper hidden", users: "" });
      }
      await this.setState({ companyLogins: companyLogins, newuserAdmin: 0 });
    }
  }

  async handleClickOpen() {
    if (this.state.userToEdit) {
      await this.setState({ actionButton: getLanguage(244, "Update user") });
    } else {
      await this.setState({ actionButton: getLanguage(243, "Send invite") });
    }
    this.setState({ open: true });
  }

  async handleClose() {
    await this.setState({
      open: false,
      newUserEmail: "",
      newUserFirstnames: "",
      newUserLoginPhone: "",
      newuserAdmin: 0,
      userToEdit: null
    });
  }

  async changeUserRole(status: any) {
    if (status) await this.setState({ userStatus: "userStatus_admin" });
    else await this.setState({ userStatus: "userStatus_user" });
  }

  async saveUser() {
    await this.setState({ openMenu: false });

    let userRole = "user";
    if (this.state.newuserAdmin) userRole = "admin";

    let adminGranted = false;
    if (this.state.newuserAdmin) {
      adminGranted = true;
    }

    let user: CompanyLoginsModels;

    if (this.state.userToEdit) {
      user = this.state.userToEdit;
      user.Firstnames = this.state.newUserFirstnames;
      user.Email = this.state.newUserEmail;
      user.LoginPhone = this.state.newUserLoginPhone;
      user.vmUserRole = userRole;
      user.AdminGranted = adminGranted;
    } else {
      // @ts-ignore FIXME: This is poorly written.. should not be using an interface for this
      user = new LoginModel({});
      user.Firstnames = this.state.newUserFirstnames;
      user.Email = this.state.newUserEmail;
      user.LoginPhone = this.state.newUserLoginPhone;
      user.vmUserRole = userRole;
      user.AdminGranted = this.state.newuserAdmin === 1;
    }

    let success;
    if (this.state.userToEdit) {
      success = await this.companyHelper.updateAdminsCompanyLogins(user);
    } else {
      success = await this.companyHelper.createNewCompanyLogin(user);
    }

    if (success) {
      await this.preparePage();
      let message = this.state.userToEdit
        ? getLanguage(246, "User updated")
        : getLanguage(247, "User added")
      SnackbarManager.Instance.addSuccess(message);

      await this.setState({
        newUserEmail: "",
        newUserFirstnames: "",
        newUserLoginPhone: "",
        newuserAdmin: 0
      });
      this.handleClose();

      this.storageHelper.changeCompanyUsers(user)
    } else {
      let message = this.state.userToEdit
        ? getLanguage(249, "User could not be updated")
        : getLanguage(248, "User could not be added")
      SnackbarManager.Instance.addWarning(message);
      this.handleClose();

      await this.setState({
        newUserEmail: "",
        newUserFirstnames: "",
        newUserLoginPhone: "",
        newuserAdmin: 0
      });
    }
  }

  async deleteUser() {
    await this.setState({ anchorEl: null, openMenu: false });
    let userIndex = this.state.activeUser;
    let companyLogins = this.state.companyLogins;

    let id = companyLogins[userIndex].Id;

    let success = await this.companyHelper.deleteCompanyLogin(id);

    if (success) {
      await this.preparePage();
      SnackbarManager.Instance.addSuccess(getLanguage(250, "User deleted"));
      await this.setState({
        newUserEmail: "",
        newUserFirstnames: "",
        newUserLoginPhone: "",
        newuserAdmin: 0,
        activeUser: -1
      });
    } else {
      await this.preparePage();
      SnackbarManager.Instance.addWarning(getLanguage(251, "User could not be deleted"));
      await this.setState({
        newUserEmail: "",
        newUserFirstnames: "",
        newUserLoginPhone: "",
        newuserAdmin: 0,
        activeUser: -1
      });
    }
  }

  async updateUser() {
    await this.setState({
      open: true,
      openMenu: false,
      actionButton: "Update user"
    });

    let userIndex = this.state.activeUser;
    if (userIndex !== -1) {
      let companyLogins = this.state.companyLogins;
      let companyLogin = companyLogins[userIndex];
      let existingUser = companyLogin;
      await this.setState({
        newUserFirstnames: existingUser.Firstnames,
        newUserEmail: existingUser.Email,
        newUserLoginPhone: existingUser.LoginPhone,
        newuserAdmin: existingUser.AdminGranted ? 1 : 0,
        userToEdit: existingUser
      });
    }
  }

  render() {
    return (
      <div>
        {/* If no users have been added, show .emptystate */}
        <div className={this.state.noUsers}>
          <div className="emptyState_content">
            <img
              className="noDrag"
              src={illustration}
              alt="Co-operate with collegues"
            />
            <h1 className="margin-bottom-0">{getLanguage(235, "Co-operate with your team")}</h1>
            <p className="infoParagraph">
              {getLanguage(236, "Invite your colleagues to join your profile and let them post...")}
            </p>
            <br />
            <Button
              className="button-gradient"
              variant="contained"
              size="medium"
              onClick={this.handleClickOpen}
            >
              <span className="btn-icon">
                <i className="fas fa-user-friends" />
              </span>
              {getLanguage(234, " Add team member")}
            </Button>
          </div>
        </div>
        {/* If more than 1 user has been added, show this div (remove .hidden className) */}
        <div className={this.state.users}>
          <div className="margin-v-24">
            <Grid
              container
              spacing={1}
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <Hidden smUp>
                  <MobileSidebarTrigger />
                </Hidden>
                <h1 className="inline-block">{getLanguage(94, "Users")}</h1>
              </Grid>
              <Grid item>
                <Button
                  className="button-gradient"
                  variant="contained"
                  size="medium"
                  onClick={this.handleClickOpen}
                >
                  <span className="btn-icon">
                    <i className="fas fa-user-plus" />
                  </span>
                  {getLanguage(234, " Add team member")}
                </Button>
              </Grid>
            </Grid>
          </div>

          <Grid
            container
            spacing={4}
            direction="row"
            justify="flex-start"
            alignItems="stretch"
          >
            {/* An item is FROM HERE... */}
            {this.state.companyLogins.map((row, index) => (
              <Grid item md={4} sm={6} xs={12} key={index}>
                <div className="paper padding-24 radius-6">
                  <Grid container item justify="space-between">
                    <Grid>
                      <div className="companyUserName">{row.Firstnames}</div>
                      <div className={row.AdminGrantedClassname}>
                        {row.UserTag}
                      </div>
                      {/* <div className="userStatus_user">User</div> */}
                    </Grid>
                    <Grid>
                      <div className="editUser">
                        <i
                          className="fas fa-ellipsis-v fa-lg"
                          onClick={event =>
                            this.setState({
                              anchorEl: event.currentTarget,
                              activeUser: index,
                              openMenu: true
                            })
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <p>
                    <i className="fas fa-envelope" /> {row.Email}
                  </p>
                  <p className="margin-bottom-0">
                    <i className="fas fa-phone" /> {row.LoginPhone}
                  </p>
                </div>
              </Grid>
            ))}
            {/* TO HERE */}
            <Menu
              id="user-menu"
              className="dropdownMenu"
              classes={{ paper: "dropdownMenu_content" }}
              disableAutoFocusItem
              open={this.state.openMenu}
              anchorEl={this.state.anchorEl}
              onClose={() => this.setState({ anchorEl: null, openMenu: false })}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              getContentAnchorEl={null}
            >
              <div className="menuTitle">{getLanguage(252, "User options")}</div>
              <MenuItem className="menuItem" onClick={this.updateUser}>
                <i className="far fa-edit fa-fw" /> {getLanguage(113, "Edit")}
              </MenuItem>
              <MenuItem className="menuItem" onClick={this.deleteUser}>
                <i className="far fa-trash-alt fa-fw" /> {getLanguage(117, "Delete")}
              </MenuItem>
            </Menu>
          </Grid>
        </div>
        <Dialog
          className="dialog"
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{getLanguage(234, "Add team member")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {getLanguage(237, "Let multiple users access your Chabber account and mannage their own shifts and events.")}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label={getLanguage(238, "Name")}
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.newUserFirstnames}
              onChange={event =>
                this.setState({ newUserFirstnames: event.target.value })
              }
            />
            <TextField
              margin="dense"
              id="mail"
              label={getLanguage(73, "Email")}
              type="email"
              fullWidth
              variant="outlined"
              value={this.state.newUserEmail}
              onChange={event =>
                this.setState({ newUserEmail: event.target.value })
              }
            />
            <TextField
              margin="dense"
              id="phone"
              label={getLanguage(75, "Mobile number")}
              type="number"
              fullWidth
              variant="outlined"
              value={this.state.newUserLoginPhone}
              onChange={event =>
                this.setState({ newUserLoginPhone: event.target.value })
              }
            />
            <TextField
              margin="dense"
              id="status"
              label={getLanguage(239, "User level")}
              fullWidth
              variant="outlined"
              select
              helperText={getLanguage(245, "Admins have full access and can manage all jobs. Users can only manage their own jobs and do not have access to edit your company details and settings")}
              value={this.state.newuserAdmin}
              onChange={event =>
                this.setState({ newuserAdmin: Number(event.target.value) })
              }
            >
              <MenuItem value={1}>{getLanguage(240, "Admin")}</MenuItem>
              <MenuItem value={0}>{getLanguage(241, "User")}</MenuItem>
            </TextField>
          </DialogContent>
          <DialogActions className="dialogActions">
            <Button onClick={this.handleClose}>{getLanguage(242, "Cancel")}</Button>
            <Button onClick={this.saveUser} variant="contained" className="button-gradient">
              {this.state.actionButton}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
