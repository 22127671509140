import React, { Component } from "react";
import FreelancerSlider from "./FreelancerSlider";
import { Mark } from "@material-ui/core/Slider";
import { getTimeString } from "./PostingHelper";
import { Button, Grid } from "@material-ui/core";

interface IProps {
  min: number;
  max: number;
  value: number;
  jobStart: number;
  onSlide: (val: number) => void;
  onMaxChanged: (val: number) => void;
}
interface IState { }

export default class MasterSlider extends Component<IProps, IState> {

  marks() {
    let arr: Mark[] = [];
    for (
      let index = this.props.jobStart * 10;
      index <= this.props.max * 10;
      index += 1
    ) {
      if (index % 5 === 0) {
        let label = getTimeString(index);
        if (label.endsWith(":30")) {
          label = "|"
        }
        arr.push({ value: index / 10, label });
      }
    }
    return arr;
  }

  render() {
    return (
      <div className="masterSliderWrapper">
        <Grid container>
          <Grid item xs={11}>
            <FreelancerSlider
              max={this.props.max}
              min={this.props.min}
              onSlide={this.props.onSlide}
              jobStart={this.props.jobStart}
              value={this.props.value}
              marks={this.marks()}
            />
          </Grid>
          <Grid item xs={1}>
            <Button
              className="icon"
              size="medium"
              onClick={() => this.props.onMaxChanged(-1)}
            >
              <span>
                <i className="fas fa-minus"> </i>
              </span>
            </Button>
            <Button
              className="icon"
              size="medium"
              onClick={() => this.props.onMaxChanged(1)}
            >
              <span>
                <i className="fas fa-plus"> </i>
              </span>
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}
