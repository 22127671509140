import React, { Component, ChangeEvent } from "react";
import { Select, OutlinedInput, MenuItem } from '@material-ui/core';
import { RegionHelper } from "../../../Helpers/RegionHelper";
import { StorageHelper } from "../../../Helpers/StorageHelper";

interface IProps {
  valueChanged?: (regionId: string, field: any) => void
  disabled?: boolean
  fullWidth?: boolean
  className?: string
}

interface IState {
  region: number
  regions: any[]
  countryActive: string;
}

export class RegionSelector extends Component <IProps, IState> {
regionHelper = new RegionHelper();
storageHelper = new StorageHelper();

  constructor(props: IProps) {
    super(props);
    this.state = {
      region: 1,
      regions: [],
      countryActive: '',
    }
    this.valueChanged = this.valueChanged.bind(this);
    this.fetchData = this.fetchData.bind(this)
  }

  UNSAFE_componentWillMount(){
    window.addEventListener('localStorageUpdated', this.fetchData);
  }

  async fetchData(){
    let countryId = await this.storageHelper.getCountryFromLocal();
    if (countryId != this.state.countryActive){
        if (countryId) {
            this.setState({countryActive: countryId});
            let regions = await this.regionHelper.getAllRegionsByCountry(countryId!);
            if (regions !== false) {
              let regionId = this.storageHelper.getRegionFromLocal();
              let index = regions.findIndex((x: {Id: string}) => x.Id === regionId);
              this.setState({ regions, region: index });
              if(this.props.valueChanged != null){
                if(index === -1) {
                  return this.props.valueChanged('', 'region');
                }
                this.props.valueChanged(regions[index].Id, 'region');
              }
            } return false;
        } this.setState({regions: [], region: -1})

    }
  }

  valueChanged(event: ChangeEvent<{name?: string | undefined; value: any}>, name:string) {
    let index: number = parseInt(event.target.value);
    let regionId = '';
    if (index !== -1) {
        regionId = this.state.regions[index].Id;
    }
    this.storageHelper.setRegionInLocal(regionId);
    this.setState({region: index});
    if(this.props.valueChanged != null){
      this.props.valueChanged(regionId, 'region');
    }
  }

  buildRegionSelects() {
    let regionSelects = [<MenuItem key='-1' value='-1'>{'Select Region'}</MenuItem>];

    for(let i = 0; i < this.state.regions.length; i++){
      let region = this.state.regions[i];
      regionSelects.push(<MenuItem key={i} value={i}>{region.Title}</MenuItem>)
    }
    return regionSelects;
  }

  render() {
    let regionSelects = this.buildRegionSelects();
    return (
      <Select  
        fullWidth={this.props.fullWidth}  
        className={this.props.className}    
        disabled={this.props.disabled}
        classes={{ select: 'inputGroup_item_inner' }}
        value={this.state.region}
        onChange={event => this.valueChanged(event, "region")}
        input={
          <OutlinedInput
            labelWidth={0}
            name="region"
            margin="dense"
            id="outlined-country-simple"                    
          />
        }
      >
        {regionSelects}
      </Select>
    );
  }
}