import { AxiosRequestConfig } from "axios";
import { StorageHelper } from "./StorageHelper";
import { englishLanguageId } from "./Cfg";
import { CallManager } from "./CallManager/CallManager";
import { LocalDataModel } from "../Models/LocalDataModel";
import moment from "moment";
import { KnownLanguageModel } from "../Models/KnownLanguageModel";
import { getCurrentAdminUser } from "./SessionHelper";
import { loggedInUserHasClearance } from "./ClearanceHelper";
import { CountryHelper } from "./CountryHelper";



export class LanguageHelper {
  storageHelper = new StorageHelper();

  async getLocalLanguagesForCountry(countryId: string, isAdmin: boolean) {
    try {
      let config: AxiosRequestConfig = {
        method: 'get',
        url: '/api/alllanguagestringsbycountry?countryid=' + countryId,
        withCredentials: true,
      }
      let response = await CallManager.Instance.makeCall(config, false, isAdmin);
      return response.data;
    } catch (error: any) {
      console.log(error)
      return null;
    }
  }

  async getLanguageStringsForLanguage(languageId: string, isAdmin: boolean) {
    try {

      let config: AxiosRequestConfig = {
        method: 'get',
        url: '/api/alllanguagestringsforlanguage?languageid=' + languageId,
        withCredentials: true,
      }
      let response = await CallManager.Instance.makeCall(config, false, isAdmin);
      return response.data;
    } catch (error: any) {
      console.log(error)
      return false;
    }
  }

  async getSingleLanguageString(code: number, languageId: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/getlanguagestring',
        withCredentials: true,
        data: {
          languageStringCode: code,
          languageId: languageId
        },
      };

      let response = await CallManager.Instance.makeCall(config, false, false)

      return response.status === 200 ? response.data : false;
    } catch (error: any) {
      console.log(error)
      return false;
    }
  }

  async saveStrings(newStrings: any) {
    try {
      let config: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/insertlanguagestring',
        withCredentials: true,
        data: {
          newStrings: newStrings
        },
      };

      let response = await CallManager.Instance.makeCall(config, false, true)

      return response.status === 200 ? response : false;
    } catch (error: any) {
      console.log(error)
      return false;
    }
  }

  async getLanguageFromKnownLanguages(languageId: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'get',
        url: '/api/languagefromknownlanguages?Id=' + languageId,
        withCredentials: true,
      }
      let response = await CallManager.Instance.makeCall(config, false, false);
      return response.data.map((x: KnownLanguageModel) => new KnownLanguageModel(x));
    } catch (error: any) {
      console.log(error)
      return false;
    }
  }


  async cacheFacadeLanguageStrings(languageId?: string) {
    try {
      let localData: LocalDataModel | null = null;
      if (!languageId) {
        localData = this.storageHelper.getFacadeLocalization();
        if (localData) {
          languageId = localData.language;
        } else {
          languageId = englishLanguageId;
        }
      }
      let config: AxiosRequestConfig = {
        method: 'get',
        url: '/api/stringsforfacade?languageId=' + languageId,
        withCredentials: true,
      }
      let response = await CallManager.Instance.makeCriticalCall(config, false);

      this.storageHelper.setLanguageStrings(response.data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  getMonthsByLanguage(abbreviated: boolean) {
    if (abbreviated) {
      return [
        getLanguage(514, 'January'),
        getLanguage(516, 'February'),
        getLanguage(518, 'March'),
        getLanguage(520, 'April'),
        getLanguage(522, 'May'),
        getLanguage(524, 'June'),
        getLanguage(526, 'July'),
        getLanguage(528, 'August'),
        getLanguage(530, 'September'),
        getLanguage(532, 'October'),
        getLanguage(534, 'November'),
        getLanguage(536, 'December'),
      ]
    }
    return [
      getLanguage(513, 'January'),
      getLanguage(515, 'February'),
      getLanguage(517, 'March'),
      getLanguage(519, 'April'),
      getLanguage(521, 'May'),
      getLanguage(523, 'June'),
      getLanguage(525, 'July'),
      getLanguage(527, 'August'),
      getLanguage(529, 'September'),
      getLanguage(531, 'October'),
      getLanguage(533, 'November'),
      getLanguage(535, 'December'),
    ]
  }

  async getLanguagesAvailableToAdminUserForEditing() {
    let adminUser = getCurrentAdminUser()
    if (!adminUser) {
      return []
    }

    let adminHasLocalLanguageClearence = loggedInUserHasClearance("Edit local language")
    let adminHasGlobalLanguageClearence = loggedInUserHasClearance("Edit global language")
    if (adminHasLocalLanguageClearence === undefined && adminHasGlobalLanguageClearence === undefined) {
      return []
    }

    let countries = await (new CountryHelper()).getActiveCountries();
    if (!countries) {
      return []
    }

    let languages: KnownLanguageModel[] = []

    if (adminHasGlobalLanguageClearence !== undefined) {
      // Hardcoding english for global
      languages.push({
        Id: englishLanguageId,
        Title: "English",
        Iso6391Code: "en",
        LanguageCode: 654
      })
    }

    for (const country of countries) {
      for (const language of country.KnownLanguages) {
        if (languages.find(t => t.Id === language.Id) !== undefined) {
          continue;
        }

        if (adminHasGlobalLanguageClearence !== undefined) {
          languages.push(language);
        } else {
          if (adminUser.CountryId === country.Id) {
            languages.push(language)
          }
        }
      }
    }

    return languages.sort((a, b) => (a.Title > b.Title ? 1 : -1));
  }
}


export function getLanguage(code: number, value: string | null = null) {
  if (typeof code === 'string') code = Number(code);

  let languageStrings: { contents: string, code: number }[] | null = (new StorageHelper()).getFacadeLanguage();
  let env = 'production';
  if (process.env.prox)
    env = process.env.prox.trim();

  let defaultReturn = '';

  if (env != 'production')
    defaultReturn = 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX';


  if (languageStrings == null) return defaultReturn;
  let content = languageStrings.find(x => x.code === code);
  let result = content ? content.contents : defaultReturn;
  return result;
}



export function getDateFormatAccordingToLanguage(startDateAndTime: string, withoutTime: boolean, withoutYear: boolean, endDateAndTime?: string) {
  let facadeData: LocalDataModel | null = new StorageHelper().getFacadeLocalization();
  let languageId = '';
  let languageIso6391Code = '';
  let dateString = '';


  //Checks which language is active and adjusts the locale accordingly
  if (facadeData) {
    languageId = facadeData.language;
    for (const i of facadeData.knownLanguages) {
      if (i.Id === languageId) {
        languageIso6391Code = i.Iso6391Code;
      }
    }
  }

  if (withoutYear) {
    dateString = moment(startDateAndTime).locale(languageIso6391Code).format('ddd. DD/MM | ')
  } else {
    dateString = moment(startDateAndTime).locale(languageIso6391Code).format('ddd. DD/MM/YYYY | ')
  }

  if (startDateAndTime) {
    dateString = dateString + moment(startDateAndTime).locale('da').format('LT')
    dateString = dateString.charAt(0).toUpperCase() + dateString.slice(1)
  }

  if (withoutTime) {
    dateString = dateString.substring(0, dateString.indexOf('|'));
    var n = dateString.indexOf('|');
    dateString = dateString.substring(0, n != -1 ? n : dateString.length);
  }


  if (endDateAndTime) {
    let endTime = moment(endDateAndTime).locale('da').format("LT");
    let dateStringWithStartAndEndTime = dateString + "-" + endTime;

    dateString = dateStringWithStartAndEndTime;
  }


  return dateString;
}
