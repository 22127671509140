import { CMSRevisionModel } from "./CMSRevisionModel";
import { CMSTypes } from "../Helpers/CMSHelper";
import { AdminUserModel } from "./AdminUserModel";
import { CoursePartnerModel } from "./CoursePartnerModel";

export class CMSMetaModel {
  Id: string;
  Description: string;
  ParentPage: string;
  PrimaryRevision: string;
  PublicId: string;
  Title: string;
  Type: CMSTypes;
  MarkForDeletion: boolean;
  IncludeInOverview: boolean;
  Revisions: CMSRevisionModel[];
  AuthorId: string;
  LanguageActive: string;
  CountryId: string;
  Author: AdminUserModel | null;
  MarkedForPublish: boolean;
  CoursePartnerId: string;
  CoursePartner: CoursePartnerModel | null;
  [key: string]: (() => (CMSRevisionModel | null)) | string | CMSTypes | boolean | CMSRevisionModel[] | AdminUserModel | CoursePartnerModel | null


  constructor(val: Partial<CMSMetaModel> = {}) {
    this.Id = val.Id || '';
    this.Description = val.Description || '';
    this.ParentPage = val.ParentPage || '';
    this.PrimaryRevision = val.PrimaryRevision || '';
    this.PublicId = val.PublicId || '';
    this.Title = val.Title || '';
    this.Type = val.Type || 0;
    this.MarkForDeletion = val.MarkForDeletion || false;
    this.IncludeInOverview = val.IncludeInOverview || false;
    this.Revisions = val.Revisions || [];
    this.AuthorId = val.AuthorId || '';
    this.LanguageActive = val.LanguageActive || '';
    this.CountryId = val.CountryId || '';
    this.CoursePartnerId = val.CoursePartnerId || '';
    this.CoursePartner = val.CoursePartner || null;

    if (val.Author)
      this.Author = new AdminUserModel(val.Author)
    else
      this.Author = null;
    this.MarkedForPublish = val.MarkedForPublish || false;
  }


  getPrimaryRevision() {
    if (!this.PrimaryRevision) return null;

    let prime = this.Revisions.find(x => x.Id === this.PrimaryRevision);
    return prime || null;
  }

  getNewestRevision() {
    if (this.Revisions.length < 1) return null;
    let newestRev = this.Revisions[0];

    for (const rev of this.Revisions) {
      if (rev.Created > newestRev.Created)
        newestRev = rev;
    }

    return newestRev;
  }
}