import React, { Component } from "react";
import logo from "../../../assets/img/illustrations/error.svg";
import "./AdminPageNotFound.scss";
export class AdminPageNotFound extends Component {
  render() {
    return (
      <div className="errorView">
        <div className="error">
          <img className="noDrag" src={logo} alt="" />
          <h1>Sorry, this page does not exist</h1>
          <p className="infoParagraph">
            If you’re sure it should be here, please contact Team Tech and they’ll help you out. <br /> Thanks!
          </p>
        </div>
      </div>
    )
  }
}