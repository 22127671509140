import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { CountryHelper } from "../../../../Helpers/CountryHelper";
import { CountryModel } from "../../../../Models/CountryModel";
import { RegisterCompany } from "./RegisterCompany";
import { NavLink } from "react-router-dom";
//@ts-ignore
import { isEmail, isLength } from "validator";

import { Button, Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import { UserHelper } from "../../../../Helpers/UserHelper";
import { RegisterFreelancer } from "./RegisterFreelancer";
import { RegisterPrivate } from "./RegisterPrivate";
import {
  LanguageHelper,
  getLanguage,
} from "../../../../Helpers/LanguageHelper";
import { setUserData } from "../../../../Helpers/SessionHelper";
import { StorageHelper } from "../../../../Helpers/StorageHelper";
import ReCAPTCHA from "react-google-recaptcha";
import { siteKey } from "../../../../Helpers/Cfg";
import { SnackbarManager } from "../../../../Helpers/SnackbarManager/SnackbarManager";
import { TermsConditionsAndPrivacy } from "../../../Parts/General/TermsConditionsAndPrivacy";
import { ProfileType } from "../../../../Models/ProfileType";

interface IProps extends RouteComponentProps {}
interface IState {
  possibleCountries: CountryModel[];
  terms: boolean;
  formsData: any; //FIXME: This should have a type
  profileType: ProfileType;
  submitDisabled: boolean;
  captcha: string;
}
export class RegisterProfile extends Component<IProps, IState> {
  countryHelper = new CountryHelper();
  userHelper = new UserHelper();
  languageHelper = new LanguageHelper();
  storageHelper = new StorageHelper();

  recapRef = React.createRef<ReCAPTCHA>();
  registerFreelancerRef = React.createRef<RegisterFreelancer>();
  registerCompanyRef = React.createRef<RegisterCompany>();
  registerPrivateRef = React.createRef<RegisterPrivate>();

  constructor(props: IProps) {
    super(props);

    let profileType: ProfileType;
    switch (this.props.location.pathname) {
      case "/registercompany":
        profileType = "company";
        break;
      case "/registerfreelancer":
        profileType = "freelancer";
        break;
      case "/registerprivate":
        profileType = "private";
        break;
      default:
        profileType = "freelancer";
        break;
    }

    this.state = {
      possibleCountries: [],
      terms: false,
      formsData: {},
      profileType: profileType,
      submitDisabled: false,
      captcha: "",
    };

    this.register = this.register.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.changeField = this.changeField.bind(this);
    this.goToActivate = this.goToActivate.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    let countries = await this.countryHelper.getActiveCountries();
    if (!countries || (countries as CountryModel[]).length < 1) {
      return SnackbarManager.Instance.addError(
        getLanguage(710, "Could not load data")
      );
    }
    this.setState({ possibleCountries: countries });
  }

  validateForms() {
    let envVar = new StorageHelper().getEnvVar();
    if (!this.state.formsData.email || !isEmail(this.state.formsData.email)) {
      return getLanguage(474, "Please check your email address");
    }
    if (
      this.state.profileType !== "company" &&
      (!this.state.formsData.firstNames ||
        !isLength(this.state.formsData.firstNames || "", { min: 1, max: 128 }))
    ) {
      return getLanguage(475, "You need to fill out your first name");
    }
    if (
      this.state.profileType !== "company" &&
      (!this.state.formsData.lastName ||
        !isLength(this.state.formsData.lastName || "", { min: 1, max: 128 }))
    ) {
      return getLanguage(476, "You need to fill out your last name");
    }
    if (
      this.state.profileType === "company" &&
      !this.state.formsData.companyName
    ) {
      return getLanguage(477, "Company name missing");
    }
    if (
      !this.state.formsData.password ||
      (envVar === "Production" &&
        !isLength(this.state.formsData.password || "", { min: 1, max: 16 }))
    ) {
      return getLanguage(478, "Please check your password");
    }
    if (
      this.state.profileType === "company" &&
      !this.state.formsData.selectedBusinessType
    ) {
      return getLanguage(479, "Select a business type");
    }
    if (
      this.state.profileType !== "company" &&
      !isLength(this.state.formsData.phone || "", { min: 1, max: 20 })
    ) {
      return getLanguage(480, "Please check your phone number");
    }
    if (!this.state.formsData.selectedCountry) {
      return getLanguage(481, "Select a country");
    }
    if (!this.state.terms) {
      return getLanguage(454, "You need to accept the terms and conditions");
    }
    if (!this.state.captcha) {
      return getLanguage(482, "Captcha not accepted");
    }
  }

  async register() {
    let result = this.validateForms();

    if (result) {
      return SnackbarManager.Instance.addWarning(result);
    }
    await this.setState({ submitDisabled: true });

    let formsData = this.state.formsData;

    let localData = this.storageHelper.getFacadeLocalization();
    if (localData) {
      formsData.PrimaryLanguage = localData.language;
    }

    let response;
    switch (this.state.profileType) {
      case "company":
        response = await this.userHelper.registerCompany(
          formsData,
          this.state.captcha
        );
        this.storageHelper.sendEvent("trackEvent", {
          event: "ClickedRegisterAccount",
          data: "Company",
        });
        break;
      case "freelancer":
        formsData.phone =
          this.registerFreelancerRef.current!.getRawPhoneNumber();
        response = await this.userHelper.registerFreelancer(
          formsData,
          this.state.captcha
        );
        this.storageHelper.sendEvent("trackEvent", {
          event: "ClickedRegisterAccount",
          data: "Freelancer",
        });
        break;
      case "private":
        formsData.phone = this.registerPrivateRef.current!.getRawPhoneNumber();
        response = await this.userHelper.registerPrivate(
          formsData,
          this.state.captcha
        );
        this.storageHelper.sendEvent("trackEvent", {
          event: "ClickedRegisterAccount",
          data: "Private",
        });
        break;
    }

    if (typeof response === "string") {
      if (this.recapRef.current != null) {
        this.recapRef.current.reset();
      }
      SnackbarManager.Instance.addError(response);
      this.setState({ submitDisabled: false });
    } else {
      let result = await this.userHelper.logChabberIn(
        this.state.formsData.email,
        this.state.formsData.password
      );
      if (!result || !result.token || !result.userData) {
        SnackbarManager.Instance.addError(getLanguage(469, "Unknown error"));
        this.recapRef?.current?.reset();
      } else {
        (window as any).dataLayer.push({
          event: "sign_up",
          page: {
            url: window.location.href,
            title: `User signed up as ${this.state.profileType}`,
          },
        });
        setUserData(result.userData);
        this.storageHelper.setAccessToken(result.token);

        this.storageHelper.initializeCaches(true);
        this.goToActivate();
      }
    }
  }

  goToActivate() {
    this.props.history.push("/verification");
  }

  changeField(name: string, value: any) {
    let form = this.state.formsData;
    form[name] = value;
    this.setState({ formsData: form });
  }

  render() {
    let form;
    switch (this.state.profileType) {
      case "company":
        form = (
          <RegisterCompany
            ref={this.registerCompanyRef}
            possibleCountries={this.state.possibleCountries}
            changeField={this.changeField}
            formsData={this.state.formsData}
          />
        );
        break;
      case "freelancer":
        form = (
          <RegisterFreelancer
            ref={this.registerFreelancerRef}
            possibleCountries={this.state.possibleCountries}
            changeField={this.changeField}
            formsData={this.state.formsData}
          />
        );
        break;
      case "private":
        form = (
          <RegisterPrivate
            ref={this.registerPrivateRef}
            possibleCountries={this.state.possibleCountries}
            changeField={this.changeField}
            formsData={this.state.formsData}
          />
        );
        break;
    }

    return (
      <div>
        <div className="backgroundGradient">
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="stretch"
            spacing={0}
          >
            <Grid item xs={12} md={5} className="backgroundWhite relative">
              <div className="padding-32">
                {form}
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      value='Remember me'
                      color='primary'
                    />
                  }
                  onChange={(e: any) => this.setState({ terms: e.target.checked })}
                  label='I accept the Terms &amp; Conditions'
                /> */}
                <TermsConditionsAndPrivacy
                  onChange={(checked) => this.setState({ terms: checked })}
                  checked={this.state.terms}
                  profileType={this.state.profileType}
                />

                <ReCAPTCHA
                  ref={this.recapRef}
                  sitekey={siteKey}
                  onChange={(value) => this.setState({ captcha: value || "" })}
                />

                <div className="margin-v-24 text-right">
                  <Button
                    className="button-gradient"
                    variant="contained"
                    size="large"
                    onClick={this.register}
                    disabled={this.state.submitDisabled}
                  >
                    <span className="btn-icon">
                      <i className="fas fa-arrow-right" />
                    </span>
                    {getLanguage(387, "Sign up")}
                  </Button>
                  <p className="padding-v-16">
                    {getLanguage(550, "Already have an account?")}
                    <NavLink to="/login">
                      <strong> {getLanguage(551, "Sign in here")}</strong>
                    </NavLink>
                  </p>
                </div>
              </div>
            </Grid>
            <Grid item className="" />
          </Grid>
        </div>
      </div>
    );
  }
}
