import React, { Component, ChangeEvent } from "react";
import { Select, OutlinedInput, MenuItem } from '@material-ui/core';
import { StorageHelper } from "../../../Helpers/StorageHelper";
import { KnownLanguageModel } from "../../../Models/KnownLanguageModel";
import { ClearanceHelper } from "../../../Helpers/ClearanceHelper";
import { LanguageHelper } from "../../../Helpers/LanguageHelper";

interface IProps {
    valueChanged?: (languageId: string, field: any) => void
    disabled?: boolean
    fullWidth?: boolean
    className?: string
}

interface IState {
    selectedLanguage: number
    languages: KnownLanguageModel[]
    language: KnownLanguageModel | null
}

export class LanguageSelector extends Component<IProps, IState> {
    storageHelper = new StorageHelper();
    constructor(props: IProps) {
        super(props);
        this.state = {
            selectedLanguage: -1,
            languages: [],
            language: null
        }
        this.valueChanged = this.valueChanged.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        let indexFromStorage = this.storageHelper.getAdminEditLanguageFromLocal();

        let languageHelper = new LanguageHelper();
        let languages = await languageHelper.getLanguagesAvailableToAdminUserForEditing();
        this.setState({ languages })
        this.setNewValueAndCallChangedEvent(indexFromStorage ? parseInt(indexFromStorage) : 0)
    }

    valueChanged(event: ChangeEvent<{ name?: string | undefined; value: any; }>, name: string) {
        this.setNewValueAndCallChangedEvent(parseInt(event.target.value))
    }

    setNewValueAndCallChangedEvent(newValue: number) {
        if (!this.state.languages) {
            return
        }

        let languageId = ''
        if (newValue !== -1) {
            if (this.state.languages.length - 1 < newValue) {
                return
            }

            languageId = this.state.languages[newValue].Id
        }

        this.storageHelper.setAdminEditLanguageInLocal(newValue.toString());

        if (newValue !== this.state.selectedLanguage) {
            this.setState({ selectedLanguage: newValue });
        }

        if (this.props.valueChanged != null) {
            this.props.valueChanged(languageId, 'languageId');
        }
    }

    buildCountrySelects() {
        let languageSelects = [<MenuItem key='-1' value='-1'>{'Select Language'}</MenuItem>];

        for (let i = 0; i < this.state.languages.length; i++) {
            let language = this.state.languages[i];
            languageSelects.push(<MenuItem key={i} value={i}>{language.Title}</MenuItem>)
        }
        return languageSelects;
    }

    render() {
        let countrySelects = this.buildCountrySelects();
        return (
            <Select
                fullWidth={this.props.fullWidth}
                className={this.props.className}
                disabled={this.props.disabled}
                classes={{ select: 'inputGroup_item_inner' }}
                value={this.state.selectedLanguage}
                onChange={event => this.valueChanged(event, "country")}
                input={
                    <OutlinedInput
                        labelWidth={0}
                        name="county"
                        margin="dense"
                        id="outlined-country-simple"
                    />
                }
            >
                {countrySelects}
            </Select>
        );
    }
}