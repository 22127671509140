import React, { useState } from 'react';
import { CMSHelper, CMSTypes } from "../../../Helpers/CMSHelper";
import { StorageHelper } from '../../../Helpers/StorageHelper';
import { Dialog, DialogContent, DialogContentText, Checkbox } from "@material-ui/core";
import { SnackbarManager } from '../../../Helpers/SnackbarManager/SnackbarManager';
import { getDateFormatAccordingToLanguage, getLanguage } from '../../../Helpers/LanguageHelper';
import { CMSRevisionModel } from '../../../Models/CMSRevisionModel';
import { ProfileType } from '../../../Models/ProfileType';
import { getCurrentLanguage } from '../../../Helpers/SessionHelper';

interface IProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  profileType: ProfileType;
}

export function TermsConditionsAndPrivacy(props: IProps) {

  const [dialogueOpen, setDialogueOpen] = useState(false);
  const [content, setContent] = useState<null | CMSRevisionModel>(null);

  const cmsHelper = new CMSHelper();
  const storageHelper = new StorageHelper();

  let prepareText = async (contentType: CMSTypes) => {

    let country = storageHelper.getFacadeCountry();
    if (country == null) {
      SnackbarManager.Instance.addError(getLanguage(469, 'Unknown error'));
      return;
    }

    const primaryLanguage = getCurrentLanguage();

    let content = await cmsHelper.getPrimaryDraftByCountry(
      country.Id,
      contentType,
      primaryLanguage
    );
    
    if (content == null) {
      SnackbarManager.Instance.addError(getLanguage(469, 'Unknown error'));
      return;
    }

    if (contentType === CMSTypes.FreelancerContract) {
      const dateToBePlacedOnContract = getDateFormatAccordingToLanguage(
        new Date().toString(),
        true,
        false
      );

      content = cmsHelper.transformText(content, [
        {
          from: "%aktueldato%",
          to: dateToBePlacedOnContract,
        },
        {
          from: "%navn%",
          to: "Your Firstname and Lastname",
        },
        {
          from: "%adresse%",
          to: "Your full address",
        },
        {
          from: "%postnr%",
          to: "",
        },
        {
          from: "%city%",
          to: "",
        },
      ]);
    }
    setContent(content);
    setDialogueOpen(true);
  }

  function getCheckboxText() {
    try {
      let text = props.profileType === "company" ? getLanguage(906, 'read and accept the termsAndConditions and privacyPolicy') : getLanguage(740, 'read and accept the termsAndConditions and privacyPolicy');
      let [step1, remaining] = text.split('{{termsAndConditions}}');
      let termsAndConditions;
      [termsAndConditions, remaining] = remaining.split('{{/termsAndConditions}}');
      let step2;
      [step2, remaining] = remaining.split('{{privacyPolicy}}');
      let privacyPolicy;
      [privacyPolicy, remaining] = remaining.split('{{/privacyPolicy}}');

      return (
        <React.Fragment>
          {step1}
          <a
            className="bold cursorPointer"
            onClick={() =>
              prepareText(
                props.profileType === "company"
                  ? CMSTypes.TermsAndConditionsCompany
                  : CMSTypes.FreelancerContract
              )
            }
          >
            {termsAndConditions}
          </a>
          {/* Dont show Privacy Policy for company */}
          {props.profileType !== "company" && (
            <React.Fragment>
              {step2}
              <a
                className="bold cursorPointer"
                onClick={() => prepareText(CMSTypes.PrivacyPolicy)}
              >
                {privacyPolicy}
              </a>
              {remaining}
            </React.Fragment>
          )}
        </React.Fragment>
      );
    } catch (error: any) {
      SnackbarManager.Instance.addError(getLanguage(741, 'error while trying to fetch termsAndConditions and PrivacyPolicy'));
      return '';
    }
  }

  return (
    <React.Fragment>
      <Checkbox
        color="primary"
        onChange={(e) => props.onChange(e.target.checked)}
        checked={props.checked}
      />
      <p className="inline-block">{getCheckboxText()}</p>
      <Dialog open={dialogueOpen} onClose={(_) => setDialogueOpen(false)}>
        <DialogContent>
          {content && (
            <DialogContentText
              dangerouslySetInnerHTML={{ __html: content.Text }}
            />
          )}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

