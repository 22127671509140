import React, { useEffect, useState } from "react";
import { InputAdornment, IconButton } from "@material-ui/core";
import { NewRuleFormBody, Rule } from "../Types";
import { formatAdornment, validateNumber } from "../Utils";
import { CustomRuleForm } from "./CustomRuleForm";
import { getLanguage } from "../../../Helpers/LanguageHelper";
import "./RulesForm.scss";
import { CurrencyInput } from "../../../Components/Parts/General/CurrencyInput";
import { StorageHelper } from "../../../Helpers/StorageHelper";

export type RulesFormProps = {
  rules: Rule[];
  onChange: (next: Rule[]) => void;
  disabled?: boolean;
};

const mapToRule = (body: NewRuleFormBody): Rule => ({
  ...body,
  identifier: "Custom",
});

export function RulesForm({ rules, onChange, disabled }: RulesFormProps) {
  const [nextRuleNumber, setNextRuleNumber] = useState<number>();
  useEffect(() => {
    setNextRuleNumber(
      rules.filter((x) => x.identifier === "Custom").length + 1
    );
  }, [rules]);

  const handleChange = (next: string, index: number) => {
    const current = [...rules];
    current[index].validationResult = validateNumber(next);
    current[index].value = next;
    onChange(current);
  };

  const handleBlur = (next: any, index: number) => {
    const current = [...rules];

    // Validate if identifier is Base, that it's not below the minimum wage
    if (current[index].identifier === "Base") {
      const minimumHourlyWage = new StorageHelper().getCurrentUser()?.Company
        ?.Country?.MinimumHourlyWage;
      if (!minimumHourlyWage) return;

      const enteredSalary = parseInt(current[index].value.replace(".", ""));
      if (isNaN(enteredSalary) || enteredSalary < minimumHourlyWage) {
        current[index].value = (minimumHourlyWage / 100.0).toFixed(2);
        onChange(current);
      }
    }
  };

  const onNewRuleSubmit = (formBody: NewRuleFormBody) => {
    onChange([...rules, mapToRule(formBody)]);
  };

  const onDelete = (index: number) => {
    const next = [...rules];
    next.splice(index, 1);
    onChange(next);
  };

  const getTitleForRule = (rule: Rule) => {
    switch (rule.identifier) {
      case "Base":
        return getLanguage(889, "Base salary");
      case "Holiday":
        return getLanguage(890, "Holiday supplement");
      case "PensionEmployee":
        return getLanguage(891, "Pension (employee)");
      case "PensionCompany":
        return getLanguage(892, "Pension (company)");
      case "FixedSupplement":
        return getLanguage(893, "Fixed Supplement");
      default:
        switch (rule.title) {
          // TODO: We should add a language ID to the fixed rules in database to prevent to following
          case "Søndagstillæg":
            return getLanguage(894, "Sunday supplement");
          case "9. og 10. time":
            return getLanguage(895, "9th & 10th hour");
          case "+ 10 timer":
            return getLanguage(896, "+ 10 hours");
          default:
            return rule.title;
        }
    }
  };

  return (
    <form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
      {rules?.map((rule, index) => (
        <div className="rule-input-container">
          <CurrencyInput
            margin="normal"
            disabled={disabled}
            key={rule.identifier}
            label={getTitleForRule(rule)}
            value={rule.value.toString()}
            defaultValue={rule.value}
            onChange={(e) => {
              handleChange(e, index);
            }}
            onBlur={(e) => {
              handleBlur(e, index);
            }}
            error={!!rule.validationResult}
            helperText={rule.validationResult || <span>&nbsp;&nbsp;</span>}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {formatAdornment(rule.handler)}
                </InputAdornment>
              ),
              inputProps: {
                style: { textAlign: "right", paddingRight: "2px" },
              },
            }}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />

          <div className="toolbar-trigger-container">
            {!disabled && (
              <IconButton
                aria-label="more"
                style={{ visibility: rule.isEditable ? "visible" : "hidden" }}
                aria-controls="long-menu"
                size="small"
                aria-haspopup="true"
                onClick={(_e: React.MouseEvent<HTMLElement>) => {
                  rule.isEditable && onDelete(index);
                }}
              >
                <i className="far fa-trash-alt fa-fw" />{" "}
              </IconButton>
            )}
          </div>
        </div>
      ))}
      {!disabled && (
        <CustomRuleForm
          onSubmit={onNewRuleSubmit}
          nextRuleNumber={nextRuleNumber || 1}
        />
      )}
    </form>
  );
}
