import { AxiosRequestConfig } from "axios";
import { CMSMetaModel } from "../Models/CMSMetaModel";
import { CMSRevisionModel } from "../Models/CMSRevisionModel";
import { CallManager } from "./CallManager/CallManager";
import { CoursePartnerModel } from "../Models/CoursePartnerModel";
import { getCurrentAdminUser } from "./SessionHelper";

export enum CMSActions {
  None = 0,
  SaveDraft = 1,
  Publish = 2,
  Unpublish = 3,
  Delete = 4,
}

export enum CMSTypes {
  Blog = 0,
  Press = 1,
  Job = 2,
  Partner = 3,
  SEO = 4,
  Course = 5,
  FreelancerContract = 6, // Affiliation Agreement (and also TermsAndConditions)
  TermsAndConditions = 7,
  Gdpr = 8,
  PrivacyPolicy = 9,
  TermsAndConditionsCompany = 10
}

export class CMSHelper {
  async getAllContent(isAdmin: boolean, type?: CMSTypes, countryId?: string): Promise<CMSMetaModel[]> {
    try {
      let config: AxiosRequestConfig = {
        method: 'get',
        url: '/api/cms/getallcontent',
        params: {
          type,
          countryId
        },
        withCredentials: true
      }
      let response = await CallManager.Instance.makeCall(config, false, isAdmin);
      if (response.data) {
        return response.data.map((x: CMSMetaModel) => new CMSMetaModel(x));
      }
      return []
    } catch (error: any) {
      return [];
    }
  }

  async publishDraft(meta: CMSMetaModel, draft: CMSRevisionModel) {
    try {
      let config: AxiosRequestConfig = {
        method: 'post',
        url: '/api/cms/publishcontent',
        withCredentials: true,
        data: { metaId: meta.Id, revision: draft }
      }
      let response = await CallManager.Instance.makeCall(config, false, true);
      return response.data;
    } catch (error: any) {
      return false;
    }
  }

  async publishContent(id: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'post',
        url: '/api/cms/publishcontentbyid',
        withCredentials: true,
        data: { id }
      }
      let response = await CallManager.Instance.makeCall(config, false, true);
      return response.data;
    } catch (error: any) {
      return false;
    }
  }

  async getContentById(id: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'get',
        url: '/api/cms/getcontentbyid?id=' + id,
        withCredentials: true,
      }
      let response = await CallManager.Instance.makeCall(config, false, true);
      return new CMSMetaModel(response.data);
    } catch (error: any) {
      return null;
    }
  }

  async getContentByPId(id: string) {
    try {
      let isAdmin = getCurrentAdminUser() != null;

      let config: AxiosRequestConfig = {
        method: 'GET',
        url: '/api/cms/getcontentbypid?id=' + id,
        withCredentials: true,
      }
      let response = await CallManager.Instance.makeCall(config, false, isAdmin);
      return new CMSMetaModel(response.data) || null;
    } catch (error: any) {
      return null;
    }
  }

  async deleteContent(id: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/cms/deletecontent',
        data: { id },
        withCredentials: true
      };
      let response = await CallManager.Instance.makeCall(config, false, true);
      return response.data;
    } catch (error: any) {
      return false;
    }
  }

  async getContentByIdAndDuplicate(id: string, languageId: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/cms/getcontentbyidandduplicate',
        withCredentials: true,
        data: {
          id,
          languageId,
        }
      }
      let response = await CallManager.Instance.makeCall(config, false, true);
      return response.data;
    } catch (error: any) {
      return false;
    }
  }

  async unpublishContent(id: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'GET',
        url: '/api/cms/unpublish?q=' + id,
        withCredentials: true,
      }
      let response = await CallManager.Instance.makeCall(config, false, true);
      return response.data;
    } catch (error: any) {

    }
  }

  async saveMeta(meta: CMSMetaModel) {
    try {
      let config: AxiosRequestConfig = {
        method: 'post',
        url: '/api/cms/savemeta',
        withCredentials: true,
        data: { meta }
      }
      let response = await CallManager.Instance.makeCall(config, false, true);
      return response.data;
    } catch (error: any) {
      return false;
    }
  }

  async saveDraft(meta: CMSMetaModel, draft: CMSRevisionModel) {
    try {
      let config: AxiosRequestConfig = {
        method: 'post',
        url: '/api/cms/savedraft',
        withCredentials: true,
        data: { meta, draft }
      }
      let response = await CallManager.Instance.makeCall(config, false, true);
      return response.data as boolean;
    } catch (error: any) {
      return false;
    }
  }

  async getPrimaryDraftByCountry(countryId: string, type: CMSTypes, languageId?: string | null) {
    try {
      let config: AxiosRequestConfig = {
        method: 'get',
        url: '/api/cms/getdraftbycountryandtype',
        withCredentials: true,
        params: {
          country: countryId,
          type: type.valueOf(),
          languageId
        }
      };
      let response = await CallManager.Instance.makeCall(config, false);

      return new CMSRevisionModel(response.data);
    } catch (error: any) {
      return null;
    }
  }



  async getCourseContentByMetaId(metaId: string, asAdmin: boolean) {
    try {
      let config: AxiosRequestConfig = {
        method: 'get',
        url: '/api/cms/coursebymetaid?id=' + metaId,
        withCredentials: true,
      }
      let response = await CallManager.Instance.makeCall(config, false, asAdmin);
      if (response.data && response.data.payload) {

        return {
          meta: new CMSMetaModel(response.data.payload.meta),
          organizer: new CoursePartnerModel(response.data.payload.organizer)
        };
      }
      return null;
    } catch (error: any) {
      console.log(error);
      return null;
    }
  }

  async postCourseContent(meta: CMSMetaModel, revision: CMSRevisionModel, contentPartnerId: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'post',
        url: '/api/cms/course',
        data: {
          meta,
          revision,
          contentPartnerId
        }
      }

      let response = await CallManager.Instance.makeCall(config, false, true);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  transformText(content: CMSRevisionModel, dublets: { from: string; to: string }[]) {
    if (dublets.length === 0 || content === null) return content;
    let modifiedContent: CMSRevisionModel = { ...content };

    dublets.forEach((dublet) => {
      modifiedContent.Text = modifiedContent.Text.replace(dublet.from, dublet.to);
    });

    return modifiedContent;
  }
}