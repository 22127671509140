import React from "react";
import { getLanguage } from "../../../../Helpers/LanguageHelper";
import { RulesForm, RulesFormProps } from "../RulesForm";

type RuleStepProps = RulesFormProps;
export function RulesStep(props: RuleStepProps) {
    return (<>
        <h2 className="brand">{getLanguage(874, 'Confirm, adjust, or add the rates from your collective agreement below:')}</h2>
        <RulesForm {...props} />
    </>)
}