export class FailedCall {
  name: string;
  tries: number;
  untilNext: number;

  
  constructor(name: string, tries: number, untilNext: number) {
    this.name = name;
    this.tries = tries;
    this.untilNext = untilNext;
    
  }
}