// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { CourseHelper } from "../../../../../Helpers/CourseHelper";
import { CourseModel } from '../../../../../Models/CourseModel';
import { Grid, Hidden } from "@material-ui/core";
import { CMSHelper, CMSTypes } from '../../../../../Helpers/CMSHelper';
import { CMSMetaModel } from '../../../../../Models/CMSMetaModel';
import { StorageHelper } from '../../../../../Helpers/StorageHelper';
import { CourseCard } from './CourseCard';
import { CourseRegistrationModel } from '../../../../../Models/CourseRegistrationModel';
import { CourseRegistrantHelper } from '../../../../../Helpers/CourseRegistrantHelper';
import { PageLoader } from '../../../../Parts/General/PageLoader';
import { getLanguage } from '../../../../../Helpers/LanguageHelper';
import { NavLink } from 'react-router-dom';
import { CourseOrganizerHelper } from '../../../../../Helpers/CourseOrganizerHelper';
import noJobs from '../../../../../assets/img/illustrations/noJobs.svg';

interface IProps extends RouteComponentProps { }

export function Courses(props: IProps) {
  let courseHelper = new CourseHelper();
  let courseRegistrantHelper = new CourseRegistrantHelper();
  let cmsHelper = new CMSHelper();
  let storageHelper = new StorageHelper();
  let coursePartnerHelper = new CourseOrganizerHelper();
  const [courseMetas, setCourseMetas] = useState<CMSMetaModel[]>([]);
  const [courses, setCourses] = useState<CourseModel[]>([]);
  const [registrations, setRegistrations] = useState<CourseRegistrationModel[]>([])
  const [pageStatus, setPageStatus] = useState<'loading' | 'ready' | 'empty'>('loading')

  useEffect(() => {
    preparePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.pathname])

  async function preparePage() {
    let country = storageHelper.getFacadeCountry();
    if (!country) return;

    let [cmsCourses, futureCourses, registrations, coursePartners] = await Promise.all([
      cmsHelper.getAllContent(false, CMSTypes.Course, country.Id),
      courseHelper.getFutureCourses(country.Id),
      courseRegistrantHelper.getFreelancersRegistrations(),
      coursePartnerHelper.getOrganizers(false)
    ]);
    cmsCourses = cmsCourses.filter(x => coursePartners.findIndex(y => y?.CourseMetaId === x.Id) != -1);

    setPageStatus(cmsCourses.length > 0 ? 'ready' : 'empty');
    setCourseMetas(cmsCourses);
    setCourses(futureCourses);
    setRegistrations(registrations);
  }

  function renderBody() {
    switch (pageStatus) {
      case 'loading':
        return renderLoading();
      case 'ready':
        return renderCourses();
      case 'empty':
        return renderEmpty();
    }
  }

  function renderLoading() {
    return <PageLoader releaseNavbar={true} />
  }

  function renderCourses() {
    return <Grid container spacing={2}>
      {courseMetas.map(meta => {
        let course = courses.filter(x => x.CoursePartner?.CourseMetaId === meta.Id);
        if (course == null) return (<></>);
        return (
          <Grid item xs={12} sm={6} md={4} lg={4} key={meta.Id}>
            <CourseCard
              meta={meta}
              courses={course}
              registrations={registrations}
            />
          </Grid>)
      })}
    </Grid>
  }

  function renderEmpty() {
    return <div className="emptyState_wrapper">
      <div className="emptyState_content">
        <img
          className="noDrag"
          src={noJobs}
          alt="No jobs available"
        />
        <h1>{getLanguage(792, "No courses available")}</h1>
        <p>{getLanguage(793, "There are no courses available at the moment. We'll keep you updated.")}</p>
      </div>
    </div>
  }

  return (
    <>
      <div className="margin-v-24">
        <Hidden smUp>
          <NavLink to="/freelancer/settings">
            <i className="fas fa-arrow-left fa-lg margin-right-16"></i>
          </NavLink>
        </Hidden>
        <h1 className="inline-block"> {getLanguage(682, "Courses")}</h1>
      </div>
      {renderBody()}
    </>
  )
}