// @ts-nocheck
import React, { Component } from "react";
import { Route, withRouter, RouteComponentProps } from 'react-router-dom';
import { RouteHandler } from "../../../Helpers/RouteHandler";

interface IProps extends RouteComponentProps {
    path: string;
    component: any;
    keyPage?: number;
    archive?: boolean; // TODO: Remove anything related to this property
}
interface IState { }


class PageRouteImpl extends Component<IProps, IState> {
    routeHandler = new RouteHandler();

    render() {
        const { component: Component, ...props } = this.props;
        let result = this.routeHandler.checkRouting(this.props.history);

        if (!result) {
            return <></>
        }
        return (
            <Route
                {...props}
                render={(props: RouteComponentProps) => {
                    let route = null;
                    route = <Component {...props} />;
                    return route;
                }

                }
            />);
    }
}
const PageRoute: any = withRouter(PageRouteImpl);
export default PageRoute;