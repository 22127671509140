// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from "react";
import { CompanyStepProfile } from "./Parts/CompanyStepProfile";
import { getLanguage } from "../../../../../Helpers/LanguageHelper";
import { Hidden } from "@material-ui/core";
import { MobileSidebarTrigger } from "../../../../Parts/General/MobileSidebarTrigger";
interface IProps {}
interface IState {}
export class CompanySettingsProfile extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }
  render() {
    return (
      <div>
        <div className="padding-v-32 maxW-600">
          <Hidden smUp>
            <MobileSidebarTrigger />
          </Hidden>
          <h1 className="inline-block">{getLanguage(99, "Edit profile")}</h1>
          <CompanyStepProfile />
        </div>
      </div>
    );
  }
}
