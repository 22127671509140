// @ts-nocheck
import React, { Component } from "react"
import { RouteComponentProps, withRouter } from "react-router"

interface IState { }

interface IProps extends RouteComponentProps { }

class ScrollToTop extends Component<IProps, IState> {
  componentDidUpdate(prevProps: IProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)