import { LoginModel } from "./LoginModel";
import { SkillModel } from "./SkillModel";

export class PostingTemplateModel {
  Id: string;
  Addressline1: string;
  AmountNeeded: number;
  AutoHireOnCancel: boolean;
  City: string;
  CompanyId: string;
  DefaultOwnerLoginId: string;
  Description: string;
  EndTime: string;
  LikelihoodOfOvertime: number;
  Login: LoginModel | null;
  MustBring: string;
  PaysTransport: number;
  ReferenceKey: string;
  RequiresRelevantExperience: boolean;
  SalaryIntervals: string;
  SalaryPerHour: number;
  Skill: SkillModel | null;
  SkillId: string;
  StartTime: string;
  Title: string;
  ZipCode: string;
  CompanyInvoiceRecipientId: string;
  SelfPaidBreak: boolean;
  MealIncluded: boolean;
  CancelInsuranceSelected: boolean;
  AutoHireFavourites: boolean;
  CoveredByCollectiveAgreement: boolean;

  constructor(val: Partial<PostingTemplateModel>) {
    this.Id = val.Id || "";
    this.Addressline1 = val.Addressline1 || "";
    this.AmountNeeded = val.AmountNeeded || 0;
    this.AutoHireOnCancel = val.AutoHireOnCancel || false;
    this.City = val.City || "";
    this.CompanyId = val.CompanyId || "";
    this.DefaultOwnerLoginId = val.DefaultOwnerLoginId || "";
    this.Description = val.Description || "";
    this.EndTime = val.EndTime || "";
    this.LikelihoodOfOvertime = val.LikelihoodOfOvertime || 0;
    this.Login = val.Login || null;
    this.MustBring = val.MustBring || "";
    this.PaysTransport = val.PaysTransport || 0;
    this.ReferenceKey = val.ReferenceKey || "";
    this.RequiresRelevantExperience = val.RequiresRelevantExperience || false;
    this.SalaryIntervals = val.SalaryIntervals || "[]";
    this.SalaryPerHour = val.SalaryPerHour || 0;
    this.Skill = val.Skill || null;
    this.SkillId = val.SkillId || "";
    this.StartTime = val.StartTime || "";
    this.Title = val.Title || "";
    this.ZipCode = val.ZipCode || "";
    this.CompanyInvoiceRecipientId = val.CompanyInvoiceRecipientId || "";
    this.SelfPaidBreak = val.SelfPaidBreak || false;
    this.MealIncluded = val.MealIncluded || false;
    this.CancelInsuranceSelected = val.CancelInsuranceSelected || false;
    this.AutoHireFavourites = val.AutoHireFavourites || false;
    this.CoveredByCollectiveAgreement = val.CoveredByCollectiveAgreement || false;
  }
}
