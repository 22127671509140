import { AxiosRequestConfig } from 'axios';
import { FreelancerModel } from '../Models/FreelancerModel';
import { StorageHelper } from './StorageHelper';
import { CallManager } from './CallManager/CallManager';

export class SalaryHelper {
  storageHelper = new StorageHelper();
  async getFreelancers(countryId: string) {
    try {
      if (!countryId || countryId.length === 0) {
        return false;
      }

      let config: AxiosRequestConfig = {
        method: 'GET',
        url: '/api/payroll/freelancers/' + countryId,
        withCredentials: true
      };
      let response = await CallManager.Instance.makeCall(config, false, true);
      let freelancers: FreelancerModel[] = response.data;
      return freelancers;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  async getFilenameForFreelancers(countryId: string) {
    try {
      if (!countryId || countryId.length === 0) {
        return false;
      }

      let config: AxiosRequestConfig = {
        method: 'GET',
        url: '/api/payroll/freelancers/filename/' + countryId,
        withCredentials: true
      };
      let response = await CallManager.Instance.makeCall(config, false, true);
      let filename: string = response.data;
      return filename;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  async getFreelancersCsv(requests: string[], countryId: string, filename: string | false) {
    try {
      if (!countryId || countryId.length === 0) {
        return false;
      }

      let config: AxiosRequestConfig = {
        method: 'post',
        url: '/api/payroll/freelancers/' + countryId + '/csv',
        withCredentials: true,
        responseType: 'blob',
        data: {
          requests: requests
        }
      };
      let response = await CallManager.Instance.makeCall(config, false, true);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      let finalFilname = "employees.csv";
      if (filename) {
        finalFilname = filename;
      }

      link.setAttribute('download', finalFilname);
      document.body.appendChild(link);
      link.click();
    } catch (error: any) {
      return false;
    }
  }

  async getSalary(countryId: string) {
    try {
      if (!countryId || countryId.length === 0) {
        return false;
      }

      let config: AxiosRequestConfig = {
        method: 'get',
        url: '/api/payroll/salary/' + countryId,
        withCredentials: true
      };
      let response = await CallManager.Instance.makeCall(config, false, true);
      let freelancers: FreelancerModel[] = response.data;
      return freelancers;
    } catch (error: any) {
      if (error.response.status.toString() === '403') {
        return 403;
      }

      return false;
    }
  }

  async getFilenameForSalary(countryId: string) {
    try {
      if (!countryId || countryId.length === 0) {
        return false;
      }

      let config: AxiosRequestConfig = {
        method: 'GET',
        url: '/api/payroll/salary/filename/' + countryId,
        withCredentials: true
      };
      let response = await CallManager.Instance.makeCall(config, false, true);
      let filename: string = response.data;
      return filename;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  async getSalaryCsv(requests: string[], countryId: string, filename: string | false) {
    try {
      if (!countryId || countryId.length === 0) {
        return false;
      }

      let config: AxiosRequestConfig = {
        method: 'post',
        url: '/api/payroll/salary/' + countryId + '/csv',
        withCredentials: true,
        responseType: 'blob',
        data: {
          requests: requests
        }
      };
      let response = await CallManager.Instance.makeCall(config, false, true);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      let finalFilname = "salary.csv";
      if (filename) {
        finalFilname = filename;
      }

      link.setAttribute('download', finalFilname);
      document.body.appendChild(link);
      link.click();

    } catch (error: any) {
      return false;
    }
  }

  async getNewSalaryCsv(requests: string[], countryId: string, filename: string | false) {
    try {
      if (!countryId || countryId.length === 0) {
        return false;
      }

      let config: AxiosRequestConfig = {
        method: 'post',
        url: '/api/payroll/newsalary/' + countryId + '/csv',
        withCredentials: true,
        responseType: 'blob',
        data: {
          requests: requests
        }
      };
      let response = await CallManager.Instance.makeCall(config, false, true);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      let finalFilname = "salary.csv";
      if (filename) {
        finalFilname = filename;
      }

      link.setAttribute('download', finalFilname);
      document.body.appendChild(link);
      link.click();

    } catch (error: any) {
      return false;
    }
  }

  async setSalaryCompletedForRequests(requests: string[], countryId: string) {
    try {
      if (!countryId || countryId.length === 0) {
        return false;
      }

      let config: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/payroll/setrequestspayrollcompleted/' + countryId,
        withCredentials: true,
        data: {
          requests: requests
        }
      };
      let response = await CallManager.Instance.makeCall(config, false, true);
      return response;
    } catch (error: any) {
      return false;
    }
  }
}