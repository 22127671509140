import React, { Component } from "react";
import "./Communication.scss";
import { TextField, FormControlLabel, Switch, Button } from "@material-ui/core";
import { StorageHelper } from "../../../../../Helpers/StorageHelper";
import { CommunicationEvent } from "../../../../../Models/CommunicationEvent";
import { CommunicationEventTemplateBinding } from "../../../../../Models/CommunicationEventTemplateBinding";
import { CommunicationTemplate } from "../../../../../Models/CommunicationTemplate";
import { CommunicationTemplateMaster } from "../../../../../Models/CommunicationTemplateMaster";
import { CommunicationEventData } from "../../../../../Models/CommunicationEventData";
import { CommunicationHelper } from "../../../../../Helpers/CommunicationHelper";
import { RouteComponentProps } from "react-router";
import qs from 'query-string';
import { SavingSnackbar } from '../../../../Parts/General/SavingSnackbar';
import { PageLoader } from "../../../../Parts/General/PageLoader";
import { SnackbarManager } from "../../../../../Helpers/SnackbarManager/SnackbarManager";
import { englishLanguageId } from "../../../../../Helpers/Cfg";
import { CommunicationMailPreview } from "./CommunicationMailPreview";


interface IProps extends RouteComponentProps {
    localLanguage: any;
    communicationEvents: CommunicationEvent[]
    communicationEventTemplateBindings: CommunicationEventTemplateBinding[]
    communicationTemplates: CommunicationTemplate[]
    communicationTemplateMasters: CommunicationTemplateMaster[]
}

interface IState {
    eventId: string
    contentChanged: boolean
    communicationEvents: CommunicationEvent[]
    communicationEventTemplateBindings: CommunicationEventTemplateBinding[]
    communicationTemplates: CommunicationTemplate[]
    communicationTemplateMasters: CommunicationTemplateMaster[]
    data: CommunicationEventData
    currentMailBinding: CommunicationEventTemplateBinding | undefined
    currentTextBinding: CommunicationEventTemplateBinding | undefined
    currentPushBinding: CommunicationEventTemplateBinding | undefined
    showPageLoader: boolean
    languageId: string
    globalCommunicationEventTemplateBindings: CommunicationEventTemplateBinding[]
    globalCommunicationTemplates: CommunicationTemplate[]
    displayEmailPreview: boolean
    emailPreviewContents: string
}

export class CommunicationEventView extends Component<IProps, IState> {
    storageHelper = new StorageHelper();
    communicationHelper = new CommunicationHelper();

    constructor(props: IProps) {
        super(props);

        this.state = {
            eventId: '',
            contentChanged: false,
            communicationEvents: this.props.communicationEvents,
            communicationEventTemplateBindings: this.props.communicationEventTemplateBindings,
            communicationTemplates: this.props.communicationTemplates,
            communicationTemplateMasters: this.props.communicationTemplateMasters,
            data: new CommunicationEventData({}),
            currentMailBinding: undefined,
            currentTextBinding: undefined,
            currentPushBinding: undefined,
            showPageLoader: false,
            languageId: '',
            globalCommunicationEventTemplateBindings: [],
            globalCommunicationTemplates: [],
            displayEmailPreview: false,
            emailPreviewContents: ''
        };

        this.resetData = this.resetData.bind(this);
        this.saveData = this.saveData.bind(this);
        this.importMailFromEnglish = this.importMailFromEnglish.bind(this);
        this.importTextFromEnglish = this.importTextFromEnglish.bind(this);
        this.importPushFromEnglish = this.importPushFromEnglish.bind(this);
        this.displayEmailPreview = this.displayEmailPreview.bind(this);
        this.closeEmailPreview = this.closeEmailPreview.bind(this);
    }



    async componentDidMount() {
        await this.getInitialData();
    }

    async getInitialData() {
        this.setState({ showPageLoader: true })

        let id = qs.parse(this.props.location.search).id || '';
        if (typeof id !== 'string') {
            id = id[0]
        }

        let languageId = qs.parse(this.props.location.search).languageId || '';
        if (typeof languageId !== 'string') {
            languageId = languageId[0]
        }

        let communicationEvents = await this.communicationHelper.getAllEvents();
        let communicationEventTemplateBindings = await this.communicationHelper.getAllCommunicationEventBindings(languageId);
        let communicationTemplates = await this.communicationHelper.getAllCommunicationTemplates(languageId);
        let communicationTemplateMasters = await this.communicationHelper.getAllCommunicationTemplateMasters(languageId);

        // Sort events
        communicationEvents = communicationEvents.sort((a: CommunicationEvent, b: CommunicationEvent) => (a.Title > b.Title ? 1 : -1));

        // Filter await data for other event ids
        communicationTemplates = communicationTemplates.filter((t: CommunicationTemplate) => t.EventId === id);

        // Get all Global (english) variants, unless that's the one we're working on already
        let globalCommunicationEventTemplateBindings: CommunicationEventTemplateBinding[] = [];
        let globalCommunicationTemplates: CommunicationTemplate[] = [];

        if (languageId !== englishLanguageId) {
            globalCommunicationEventTemplateBindings = await this.communicationHelper.getAllCommunicationEventBindings(englishLanguageId)
            globalCommunicationTemplates = await this.communicationHelper.getAllCommunicationTemplates(englishLanguageId)
        }

        // Find current templates for event
        let mailBinding = communicationEventTemplateBindings.find((t: CommunicationEventTemplateBinding) => communicationTemplates.find((y: CommunicationTemplate) => y.CommunicationType === 0 && y.Id === t.CommunicationTemplateId) !== undefined);
        let textBinding = communicationEventTemplateBindings.find((t: CommunicationEventTemplateBinding) => communicationTemplates.find((y: CommunicationTemplate) => y.CommunicationType === 1 && y.Id === t.CommunicationTemplateId) !== undefined);
        let pushBinding = communicationEventTemplateBindings.find((t: CommunicationEventTemplateBinding) => communicationTemplates.find((y: CommunicationTemplate) => y.CommunicationType === 2 && y.Id === t.CommunicationTemplateId) !== undefined);

        await this.setState({
            eventId: id,
            contentChanged: false,
            languageId: languageId,
            communicationEvents: communicationEvents,
            communicationEventTemplateBindings: communicationEventTemplateBindings,
            communicationTemplates: communicationTemplates,
            communicationTemplateMasters: communicationTemplateMasters,
            data: new CommunicationEventData({
                CommunicationEventId: id,
                MailTitle: (mailBinding ? mailBinding.CommunicationTemplate.Title : ''),
                MailContents: (mailBinding ? mailBinding.CommunicationTemplate.Contents : ''),
                MailExtras: (mailBinding ? mailBinding.CommunicationTemplate.Extras : ''),
                TextContents: (textBinding ? textBinding.CommunicationTemplate.Contents : ''),
                PushTitle: (pushBinding ? pushBinding.CommunicationTemplate.Title : ''),
                PushContents: (pushBinding ? pushBinding.CommunicationTemplate.Contents : ''),
                PushPayload: (pushBinding ? pushBinding.CommunicationTemplate.Extras : ''),
                PushCommands: (pushBinding ? pushBinding.CommunicationTemplate.Commands : '')
            }),
            currentMailBinding: mailBinding,
            currentTextBinding: textBinding,
            currentPushBinding: pushBinding,
            showPageLoader: false,
            globalCommunicationEventTemplateBindings,
            globalCommunicationTemplates
        });
    }

    dataEdited(field: string, value: string) {
        let data = new CommunicationEventData(this.state.data);
        data[field] = value;

        this.setState({ data: data, contentChanged: true });
    }

    resetData() {
        this.getInitialData();
    }

    async saveData() {
        // Clear mail binding if user removed data for it
        if (this.state.currentMailBinding !== undefined && this.state.data.MailTitle === '' && this.state.data.MailContents === '') {
            await this.communicationHelper.deleteCommunicationEventBindingsForEventIdAndCommunicationType(this.state.eventId, 0, this.state.languageId);
        }
        // Clear text binding if user removed data for it
        if (this.state.currentTextBinding !== undefined && this.state.data.TextContents === '') {
            await this.communicationHelper.deleteCommunicationEventBindingsForEventIdAndCommunicationType(this.state.eventId, 1, this.state.languageId);
        }

        // Clear push binding if user removed data for it
        if (this.state.currentPushBinding !== undefined && this.state.data.PushTitle === '' && this.state.data.PushContents === '') {
            await this.communicationHelper.deleteCommunicationEventBindingsForEventIdAndCommunicationType(this.state.eventId, 2, this.state.languageId);
        }

        // Add new mail template and bindings, if changed
        if (this.state.data.MailTitle !== '' && this.state.data.MailContents !== '') {
            // Remove old binding if exists and a new template will be created
            if (this.state.currentMailBinding !== undefined && this.state.currentMailBinding.CommunicationTemplate !== undefined && (this.state.currentMailBinding.CommunicationTemplate.Title !== this.state.data.MailTitle || this.state.currentMailBinding.CommunicationTemplate.Contents !== this.state.data.MailContents || this.state.currentMailBinding.CommunicationTemplate.Extras !== this.state.data.MailExtras)) {
                await this.communicationHelper.deleteCommunicationEventBindingsForEventIdAndCommunicationType(this.state.eventId, 0, this.state.languageId);
            }

            if (this.state.currentMailBinding === undefined || this.state.currentMailBinding.CommunicationTemplate === undefined || this.state.currentMailBinding.CommunicationTemplate.Title !== this.state.data.MailTitle || this.state.currentMailBinding.CommunicationTemplate.Contents !== this.state.data.MailContents || this.state.currentMailBinding.CommunicationTemplate.Extras !== this.state.data.MailExtras) {
                let templateMaster = this.state.communicationTemplateMasters.find(t => t.CommunicationType === 0);
                if (templateMaster !== undefined) {
                    let newMailTemplate = new CommunicationTemplate({
                        Title: this.state.data.MailTitle,
                        Contents: this.state.data.MailContents,
                        Extras: this.state.data.MailExtras,
                        Commands: '',
                        LanguageId: this.state.languageId,
                        CommunicationType: 0,
                        CommunicationTemplateMasterId: templateMaster.Id,
                        EventId: this.state.eventId
                    })
                    let insertTemplateResponse = await this.communicationHelper.insertCommunicationTemplate(newMailTemplate);
                    await this.communicationHelper.insertCommunicationEventBindingsForEventIdAndTemplateId(this.state.eventId, insertTemplateResponse.Id, this.state.languageId, insertTemplateResponse.Version);
                }
            }
        }


        // Add new text template and bindings, if changed
        if (this.state.data.TextContents !== '') {
            // Remove old binding if exists and a new template will be created
            if (this.state.currentTextBinding !== undefined && this.state.currentTextBinding.CommunicationTemplate !== undefined && this.state.currentTextBinding.CommunicationTemplate.Contents !== this.state.data.TextContents) {
                await this.communicationHelper.deleteCommunicationEventBindingsForEventIdAndCommunicationType(this.state.eventId, 1, this.state.languageId);
            }

            if (this.state.currentTextBinding === undefined || this.state.currentTextBinding.CommunicationTemplate === undefined || this.state.currentTextBinding.CommunicationTemplate.Contents !== this.state.data.TextContents) {
                let templateMaster = this.state.communicationTemplateMasters.find(t => t.CommunicationType === 1);
                if (templateMaster !== undefined) {
                    let newMailTemplate = new CommunicationTemplate({
                        Title: '',
                        Contents: this.state.data.TextContents,
                        Extras: '',
                        Commands: '',
                        LanguageId: this.state.languageId,
                        CommunicationType: 1,
                        CommunicationTemplateMasterId: templateMaster.Id,
                        EventId: this.state.eventId
                    })
                    let insertTemplateResponse = await this.communicationHelper.insertCommunicationTemplate(newMailTemplate);
                    await this.communicationHelper.insertCommunicationEventBindingsForEventIdAndTemplateId(this.state.eventId, insertTemplateResponse.Id, this.state.languageId, insertTemplateResponse.Version);
                }
            }
        }


        // Add new push template and bindings, if changed
        if (this.state.data.PushTitle !== '' || this.state.data.PushContents !== '') {
            // Remove old binding if exists and a new template will be created
            if (this.state.currentPushBinding !== undefined && this.state.currentPushBinding.CommunicationTemplate !== undefined && (this.state.currentPushBinding.CommunicationTemplate.Title !== this.state.data.PushTitle || this.state.currentPushBinding.CommunicationTemplate.Contents !== this.state.data.PushContents || this.state.currentPushBinding.CommunicationTemplate.Extras !== this.state.data.PushPayload || this.state.currentPushBinding.CommunicationTemplate.Commands !== this.state.data.PushCommands)) {
                await this.communicationHelper.deleteCommunicationEventBindingsForEventIdAndCommunicationType(this.state.eventId, 2, this.state.languageId);
            }

            if (this.state.currentPushBinding === undefined || this.state.currentPushBinding.CommunicationTemplate === undefined || this.state.currentPushBinding.CommunicationTemplate.Title !== this.state.data.PushTitle || this.state.currentPushBinding.CommunicationTemplate.Contents !== this.state.data.PushContents || this.state.currentPushBinding.CommunicationTemplate.Extras !== this.state.data.PushPayload || this.state.currentPushBinding.CommunicationTemplate.Commands !== this.state.data.PushCommands) {
                let templateMaster = this.state.communicationTemplateMasters.find(t => t.CommunicationType === 2);
                if (templateMaster !== undefined) {
                    let newMailTemplate = new CommunicationTemplate({
                        Title: this.state.data.PushTitle,
                        Contents: this.state.data.PushContents,
                        Extras: this.state.data.PushPayload,
                        Commands: this.state.data.PushCommands,
                        LanguageId: this.state.languageId,
                        CommunicationType: 2,
                        CommunicationTemplateMasterId: templateMaster.Id,
                        EventId: this.state.eventId
                    })
                    let insertTemplateResponse = await this.communicationHelper.insertCommunicationTemplate(newMailTemplate);
                    await this.communicationHelper.insertCommunicationEventBindingsForEventIdAndTemplateId(this.state.eventId, insertTemplateResponse.Id, this.state.languageId, insertTemplateResponse.Version);
                }
            }
        }
        SnackbarManager.Instance.addSuccess('Changes saved');

        await this.setState({
            contentChanged: false,
        });

        this.getInitialData();
    }

    importMailFromEnglish(globalVersion: CommunicationTemplate | undefined) {
        if (globalVersion) {
            this.setState({
                data: new CommunicationEventData({
                    CommunicationEventId: this.state.data.CommunicationEventId,
                    MailTitle: globalVersion.Title,
                    MailContents: globalVersion.Contents,
                    MailExtras: globalVersion.Extras,
                    TextContents: this.state.data.TextContents,
                    PushTitle: this.state.data.PushTitle,
                    PushContents: this.state.data.PushContents,
                    PushPayload: this.state.data.PushPayload,
                    PushCommands: this.state.data.PushCommands
                })
            })
        }
    }

    importTextFromEnglish(globalVersion: CommunicationTemplate | undefined) {
        if (globalVersion) {
            this.setState({
                data: new CommunicationEventData({
                    CommunicationEventId: this.state.data.CommunicationEventId,
                    MailTitle: this.state.data.MailTitle,
                    MailContents: this.state.data.MailContents,
                    MailExtras: this.state.data.MailExtras,
                    TextContents: globalVersion.Contents,
                    PushTitle: this.state.data.PushTitle,
                    PushContents: this.state.data.PushContents,
                    PushPayload: this.state.data.PushPayload,
                    PushCommands: this.state.data.PushCommands
                })
            })
        }
    }

    importPushFromEnglish(globalVersion: CommunicationTemplate | undefined) {
        if (globalVersion) {
            this.setState({
                data: new CommunicationEventData({
                    CommunicationEventId: this.state.data.CommunicationEventId,
                    MailTitle: this.state.data.MailTitle,
                    MailContents: this.state.data.MailContents,
                    MailExtras: this.state.data.MailExtras,
                    TextContents: this.state.data.TextContents,
                    PushTitle: globalVersion.Title,
                    PushContents: globalVersion.Contents,
                    PushPayload: globalVersion.Extras,
                    PushCommands: globalVersion.Commands
                })
            })
        }
    }

    renderMailPart() {
        let importFromGlobal = <></>
        let globalBinding = this.state.globalCommunicationEventTemplateBindings.find(t => t.CommunicationEventId === this.state.eventId && this.state.globalCommunicationTemplates.find(y => y.Id === t.CommunicationTemplateId && y.CommunicationType === 0) !== undefined)
        let globalTemplate = this.state.globalCommunicationTemplates.find(y => globalBinding !== undefined && y.Id === globalBinding.CommunicationTemplateId && y.CommunicationType === 0)
        if (globalTemplate !== undefined) {
            importFromGlobal = <Button variant="contained" size="medium" onClick={(e: any) => this.importMailFromEnglish(globalTemplate)}>Import from English</Button>
        }
        return (<div>
            <h2>E-mail</h2>
            {importFromGlobal}
            <Button
                onClick={this.displayEmailPreview}>
                Preview
                </Button>
            <TextField
                autoComplete="off"
                margin="dense"
                id="mail"
                label="Title"
                type="text"
                fullWidth
                variant="outlined"
                value={this.state.data.MailTitle}
                onChange={event =>
                    this.dataEdited("MailTitle", event.target.value)
                }
            />
            <TextField
                autoComplete="off"
                multiline
                rows="20"
                margin="dense"
                id="mail"
                label="Contents"
                type="text"
                fullWidth
                variant="outlined"
                value={this.state.data.MailContents}
                onChange={event =>
                    this.dataEdited("MailContents", event.target.value)
                }
            />
            <TextField
                autoComplete="off"
                margin="dense"
                id="mail"
                label="Extras (i.e. optional mail header)"
                type="text"
                fullWidth
                variant="outlined"
                value={this.state.data.MailExtras}
                onChange={event =>
                    this.dataEdited("MailExtras", event.target.value)
                }
            />
        </div>)
    }

    renderTextPart() {
        let importFromGlobal = <></>
        let globalBinding = this.state.globalCommunicationEventTemplateBindings.find(t => t.CommunicationEventId === this.state.eventId && this.state.globalCommunicationTemplates.find(y => y.Id === t.CommunicationTemplateId && y.CommunicationType === 1) !== undefined)
        let globalTemplate = this.state.globalCommunicationTemplates.find(y => globalBinding !== undefined && y.Id === globalBinding.CommunicationTemplateId && y.CommunicationType === 1)
        if (globalTemplate !== undefined) {
            importFromGlobal = <Button variant="contained" size="medium" onClick={(e: any) => this.importTextFromEnglish(globalTemplate)}>Import from English</Button>
        }
        return (<div>
            <h2>Text message (SMS)</h2>
            {importFromGlobal}
            <TextField
                autoComplete="off"
                multiline
                margin="dense"
                id="mail"
                label="Contents"
                type="text"
                fullWidth
                variant="outlined"
                value={this.state.data.TextContents}
                onChange={event =>
                    this.dataEdited("TextContents", event.target.value)
                }
            />
        </div>)
    }

    renderPushPart() {
        let importFromGlobal = <></>
        let globalBinding = this.state.globalCommunicationEventTemplateBindings.find(t => t.CommunicationEventId === this.state.eventId && this.state.globalCommunicationTemplates.find(y => y.Id === t.CommunicationTemplateId && y.CommunicationType === 2) !== undefined)
        let globalTemplate = this.state.globalCommunicationTemplates.find(y => globalBinding !== undefined && y.Id === globalBinding.CommunicationTemplateId && y.CommunicationType === 2)
        if (globalTemplate !== undefined) {
            importFromGlobal = <Button variant="contained" size="medium" onClick={(e: any) => this.importPushFromEnglish(globalTemplate)}>Import from English</Button>
        }
        return (<div>
            <h2>Push message</h2>
            {importFromGlobal}
            <TextField
                autoComplete="off"
                margin="dense"
                id="mail"
                label="Title"
                type="text"
                fullWidth
                variant="outlined"
                value={this.state.data.PushTitle}
                onChange={event =>
                    this.dataEdited("PushTitle", event.target.value)
                }
            />
            <TextField
                autoComplete="off"
                margin="dense"
                id="mail"
                label="Contents"
                type="text"
                fullWidth
                variant="outlined"
                value={this.state.data.PushContents}
                onChange={event =>
                    this.dataEdited("PushContents", event.target.value)
                }
            />
            <TextField
                autoComplete="off"
                multiline
                margin="dense"
                id="mail"
                label="Payload"
                type="text"
                fullWidth
                variant="outlined"
                value={this.state.data.PushPayload}
                onChange={event =>
                    this.dataEdited("PushPayload", event.target.value)
                }
            />
            <FormControlLabel
                control={
                    <Switch
                        color="primary"
                        checked={this.state.data.PushCommands === 'increaseBadge'}
                        onChange={event =>
                            this.dataEdited("PushCommands", (event.target.checked ? 'increaseBadge' : ''))
                        }
                    />
                }
                label="Increase badge"
                labelPlacement="end"
            />
        </div>)

    }

    renderNotificationPart() {

    }

    renderAvailableVariables(event: CommunicationEvent | undefined) {
        if (!event) {
            return <></>
        }

        return <div>
            <b>Variables: </b> {event.AvailableVariables}<br />
            <b>Lists: </b> {event.AvailableLists}<br />
            <b>Cases: </b> {event.AvailableCases}
        </div>
    }

    displayEmailPreview() {
        let mailContents = ''

        this.setState({
            displayEmailPreview: true,
            emailPreviewContents: this.state.data.MailContents
        })
    }

    closeEmailPreview() {
        this.setState({
            displayEmailPreview: false,
            emailPreviewContents: ''
        })
    }

    render() {
        let savingSnackBar = (<SavingSnackbar open={this.state.contentChanged} resetClicked={this.resetData} saveClicked={this.saveData} />)

        let event: CommunicationEvent | undefined = undefined
        let title = "";
        if (this.state.eventId && this.state.communicationEvents) {
            event = this.state.communicationEvents.find(t => t.Id === this.state.eventId);
            if (event) {
                title = event.Title;
            }
        }

        return (<div>
            <h1>{title}</h1>
            {this.renderMailPart()}
            {this.renderTextPart()}
            {this.renderPushPart()}
            {this.renderNotificationPart()}
            <br />
            {this.renderAvailableVariables(event)}
            <br />
            {savingSnackBar}
            {this.state.showPageLoader ? <PageLoader /> : null}
            <CommunicationMailPreview
                visible={this.state.displayEmailPreview}
                contents={this.state.emailPreviewContents}
                close={this.closeEmailPreview}
                {...this.props}
            ></CommunicationMailPreview>
        </div>)
    }
}