import { CommunicationEvent } from "./CommunicationEvent";
import { CommunicationEventTemplateBinding } from "./CommunicationEventTemplateBinding";
import { CommunicationTemplateMaster } from "./CommunicationTemplateMaster";

export class CommunicationTemplate {
    Id: string;
    EventId: string;
    Title: string;
    Contents: string;
    Extras: string;
    Commands: string;
    LanguageId: string;
    Version: number;
    CommunicationType: number;
    Created: Date;
    CommunicationEvents: CommunicationEvent[];
    CommunicationEventTemplateBindings: CommunicationEventTemplateBinding[];
    CommunicationTemplateMasterId: string;
    CommunicationTemplateMaster: CommunicationTemplateMaster|undefined;
  
    constructor(val: Partial<CommunicationTemplate>) {
      this.Id = val.Id || '';
      this.EventId = val.EventId || '';
      this.Title = val.Title || '';
      this.Contents = val.Contents || '';
      this.Extras = val.Extras || '';
      this.Commands = val.Commands || '';
      this.LanguageId = val.LanguageId || '';
      this.Version = val.Version || 0;
      this.CommunicationType = val.CommunicationType || 0;
      this.Created = val.Created || new Date();
      this.CommunicationEvents = val.CommunicationEvents || [];
      this.CommunicationEventTemplateBindings = val.CommunicationEventTemplateBindings || [];
      this.CommunicationTemplateMasterId = val.CommunicationTemplateMasterId || '';
      this.CommunicationTemplateMaster = val.CommunicationTemplateMaster || undefined;
    }
  }
  