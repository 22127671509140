import React, { Component } from 'react';
// @ts-ignore
import PhoneInput from 'react-phone-input-2'

interface IProps {
  countryCode: string;
  value: string;
  onChange: (value: string) => void;
  onFocus: (value: string) => void;
}
interface IState { }

export class PhoneField extends Component<IProps, IState> {
  phoneRef = React.createRef<PhoneInput>();


  ensureCountryCodeUnchanged(newPhoneNumber: string, phoneNumber: string) {
    let countryCode = newPhoneNumber.split(' ')[0];
    let countryCodeOld = phoneNumber.split(' ')[0];

    if (countryCode === countryCodeOld) {
      return newPhoneNumber;
    } else {
      return phoneNumber;
    }
  }

  public getRawPhoneInput() {
    let phoneNumber = this.props.value;
    let index = phoneNumber.indexOf(' ');
    phoneNumber = phoneNumber.substring(index);
    phoneNumber = phoneNumber.replace(/\D/g, '');

    return phoneNumber;
  }


  render() {
    return (
      <PhoneInput
        inputClass="phoneInput_inputField"
        ref={this.phoneRef}
        defaultCountry={this.props.countryCode}
        value={this.props.value}
        onChange={(val: string) => {
          this.props.onChange(this.ensureCountryCodeUnchanged(val, this.props.value))
        }}
        disableDropdown
        countryCodeEditable={false}
        onFocus={(val: any) => this.props.onFocus(val.target.value)}
      />
    )
  }
}