

export class SideBarOpenSingleton {
    private static instance: SideBarOpenSingleton;
    private sideBarOpen: boolean = false;



    public static get Instance(): SideBarOpenSingleton {
        if (!SideBarOpenSingleton.instance) {
            SideBarOpenSingleton.instance = new SideBarOpenSingleton();
        }
        return SideBarOpenSingleton.instance;
    }

    public toggleSideBar() {
        this.sideBarOpen = !this.sideBarOpen;
    }


    public getSideBar() {
        return this.sideBarOpen;
    }
}

