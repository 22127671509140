import React, { Component } from 'react';
import { EventWithPayload } from '../../Common/EventWithPayload';

interface IProps { }

interface IState {
  errors: { name: string | undefined; tries: number; untilNext: number; }[]
}

export class CallManagerView extends Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);

    this.state = {
      errors: []
    }

    this.receivedUpdate = this.receivedUpdate.bind(this)
  }

  componentDidMount() {
    window.addEventListener('ConnectionIssueEvent', this.receivedUpdate);
  }

  receivedUpdate(event: any) {
    let currentEvent = event as EventWithPayload;
    if (currentEvent.payload === 'allclear') {

      // We can safely asume there are no errors
      this.setState({ errors: [] });
    } else if (currentEvent.payload instanceof Array) {
      // There are errors, render them
      this.setState({
        errors: currentEvent.payload
      });
    } else {
      console.error('received unexpected data', currentEvent.payload);
    }
  }


  render() {
    if (this.state.errors.length <= 0 || this.state.errors instanceof Array === false) return <div />;

    return (
      <div className='pageLoader_backdrop opaque'>
        <div className='emptyState_wrapper fullView'>
          <div className="emptyState_content">
            <h1>Connection lost</h1>
            {this.state.errors.map(err => {
              return (
                <div key={err.name}>
                  <p>Failed when connecting to <i> {err.name} </i></p>
                  {/* <p> has failed to connect <i> {err.tries} </i> times </p> */}
                  <p>Attempting again in </p>
                  <div className="errorCountdown">{Math.round(err.untilNext)}</div>
                </div>
              )
            })}
            <p>Please check your internet connection. If the problem persists please call our support on +45 60 80 39 94</p>
          </div>
        </div>
      </div>);
  }
}