import { AxiosRequestConfig } from 'axios';
import { CallManager } from './CallManager/CallManager';

export class ImageHelper {


  dataURLtoFile(dataurl: string, filename: string) {
    let arr = dataurl.split(',')
    let mime1 = arr[0].match(/:(.*?);/);
    if (mime1 == null) return;


    let mime = mime1[1]
    let bstr = atob(arr[1])
    let n = bstr.length
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }


  async savePictureToImageLibrary(img: string) {
    try {
      // img = await resizeImage(img, 300, 'max largest');
      let config: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/media',
        withCredentials: true,
        data: {
          img
        },
      };

      let response = await CallManager.Instance.makeCall(config, false, true);
      if (response.status === 200) {
        return response.data as { Id: any; Url: any; };
      }
      return null;
    } catch (error: any) {
      return null;
    }
  }

  async getAllImagesForLibrary() {
    try {
      let config: AxiosRequestConfig = {
        method: 'GET',
        url: '/api/media',
        withCredentials: true
      }
      let images = (await CallManager.Instance.makeCall(config, false, true)).data;
      return images;
    } catch (error: any) {
      return false;
    }
  }

  async deleteMediaImage(imgId: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'DELETE',
        url: '/api/media/' + imgId,
        withCredentials: true
      };

      let response = await CallManager.Instance.makeCall(config, false, true);

      return response.status === 200 ? true : false;
    } catch (error: any) {
      return false;
    }
  }
}


export function fileToDataUrl(file: File): Promise<string> {
  return new Promise((resolve: any, reject: any) => {
    let fr = new FileReader();
    fr.onload = x => resolve(fr.result as string);
    fr.readAsDataURL(file)
  });
}

export async function resizeImage(image: File | string, value: number, ratio: 'max height' | 'max width' | 'max largest' | 'both sides') {
  if (image instanceof File) {
    image = await fileToDataUrl(image);
  }
  let im = new Image();
  im.src = image;
  let height: number = 0;
  let width: number = 0;
  await new Promise((resolve: any, reject: any) => {
    // @ts-ignore
    im.onload = () => { height = im.height, width = im.width }
    resolve();
  });
  if (ratio == 'max largest') {
    if (height > width) {
      ratio = 'max height';
    } else {
      ratio = 'max width';
    }
  }

  switch (ratio) {
    case 'max height':
      image = await doImageResize(image, undefined, value);
      break;
    case 'max width':
      image = await doImageResize(image, value);
      break;
    case 'both sides':
      image = await doImageResize(image, value, value);
      break;
  }
  return image;
}

async function doImageResize(image: string, width?: number, height?: number): Promise<string> {
  return new Promise(async (resolve: any, reject: any) => {
    let originalImage = '' + image;
    if (height == null && width == null) {
      return resolve(originalImage);
    }

    let img: CanvasImageSource;
    // create an off-screen canvas
    let canvas = document.createElement('canvas');
    let ctx = <CanvasRenderingContext2D>canvas.getContext('2d');


    if (typeof image !== 'string') {
      return resolve(originalImage);
    }
    img = new Image();
    img.onload = function () {

      // Handle aspect ratio
      let h = height || 50;
      let w = width || 50;
      if (height == null && width != null) {
        h = (+img.height / +img.width) * width;
      } else if (width == null && height != null) {
        w = height / (+img.height / +img.width);
      }

      // Sets the canvas to the size of the new image
      canvas.width = w;
      canvas.height = h;

      ctx.drawImage(img, 0, 0, w, h);
      resolve(canvas.toDataURL());
    };
    img.src = image;
  })

  // // set its dimension to target size
  // canvas.width = width;
  // canvas.height = height;

  // // draw source image into the off-screen canvas:
  // // ctx.drawImage(img, 0, 0, width, height);

  // // encode image to data-uri with base64 version of compressed image
  // return canvas.toDataURL();
}

export function configureCloudinaryUrl(url: string | undefined, params: string[]): string {
  if (!url) {
    return ''
  }
  if (url.includes('f_auto')) {
    return url
  }

  let identifier = 'image/upload/';
  let step1 = url.split(identifier)[1];
  let editable = step1.split('/')[0];

  let cfg = 'f_auto';

  if (params.length > 0) {
    cfg += ',' + params.join(',');
  }

  return url.replace(editable, cfg);
}