import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { Grid, Button } from "@material-ui/core";
import { getLanguage } from "../../../../Helpers/LanguageHelper";
import { UserHelper } from "../../../../Helpers/UserHelper";
interface IProps {
  confirmFirstJob: () => void;
  isConfirming: boolean;
  hidden?: boolean;
}
interface IState { }
export class FirstJobConfirmation extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }



  render() {
    return (
      <div hidden={this.props.hidden} className="firstJobConfirmation">
        <div className="paper radius-6 padding-24 margin-bottom-16">
          <Grid
            container
            spacing={1}
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <h2 className="margin-0">{getLanguage(558, "This is your first job. Please confirm that you have seen this message.")}</h2>
            </Grid>
            <Grid item>
              <Button
                disabled={this.props.isConfirming}
                onClick={() => this.props.confirmFirstJob()}
                className="success"
              >
                {getLanguage(559, "Yes confirm")}
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
