import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Button
} from "@material-ui/core";
import "./Pricing.scss";
import { Footer } from "../../Navigation/Footer";
interface IProps extends RouteComponentProps {}
interface IState {}
export class Pricing extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }
  render() {
    return (
      <div className="pricingView">
        <div className="container">
          <div className="margin-v-32">
            <h1 className="displayFont text-center">Hvad koster det?</h1>            
          </div>
          <div className="margin-v-40 maxW-600 text-center">
            <Grid container spacing={8}>
              <Grid item md={12}>
                <h1 className="text-left">Erhverv</h1>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Beskrivelse</TableCell>
                      <TableCell align="right">Pris eksl. moms</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Timepris</TableCell>
                      <TableCell align="right">DKK 33,00</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Engangsgebyr per hyret freelancer*</TableCell>
                      <TableCell align="right">DKK 49,00</TableCell>
                    </TableRow>
                    {/* <TableRow>
                      <TableCell>Mindsteløn</TableCell>
                      <TableCell align="right">120,00</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Feriepenge</TableCell>
                      <TableCell align="right">12,5%</TableCell>
                    </TableRow> */}
                  </TableBody>
                </Table>
                <div className="placeholder text-left">
                  * Akutgebyr kan forekomme
                </div>
              </Grid>
              <Grid item md={12}>
                <h1 className="text-left">Privatkunder</h1>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Beskrivelse</TableCell>
                      <TableCell align="right">Pris inkl. moms</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Tjener, bartender, runner, køkkenmedhjælper</TableCell>
                      <TableCell align="right">199 kr/t</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Kok</TableCell>
                      <TableCell align="right">269 kr/t</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>            
              </Grid>
            </Grid>
          </div>
          <div className="margin-v-40 text-center">
            <p className="infoParagraph">
            Har du brug for en skræddersyet løsning? <br />Så ring til os på +45 60803994
            </p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
