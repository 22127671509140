import React, { Component } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import './Tabs.scss';

interface IProps {
  value?: number | null;
  changeValue?: (value: number) => void;
}

interface IState {
  value: number;
}


export default class TabComponent extends Component<IProps, IState> {
  
  constructor(props: IProps) {
    super(props);
    this.state = {
      value: this.props.value || 0,
    };
    
  }
  
  async componentDidUpdate() {
    if (this.props.value && this.props.value != this.state.value)
      await this.setState({value: this.props.value!})
  }
  

  handleChange = (event: object, value: number) => {
    
    this.setState({ value });
    if (this.props.changeValue)
      this.props.changeValue(value)
  };

  render() {
    const { value } = this.state;
    let tabs: any[] = [];
    let childElements = React.Children.toArray(this.props.children);
    
    for (let index = 0; index < childElements.length; index++) {
      tabs.push(
        <Tab
          key={index}
          disableRipple
          //@ts-ignore
          label={childElements[index].props.title}
          className="tabItem"
        />);
    }

    return (
      <div className="tabRoot">
        <Tabs
          indicatorColor='primary'
          value={value}
          onChange={this.handleChange}
          className="tabList"
          centered={false}
          classes={{
            indicator: 'tabIndicator'
          }}
          >
          {tabs}
        </Tabs>
        {childElements[value]}
      </div>
    );
  }
}