import React, { Component } from "react";
import { Menu, MenuItem, Grid } from "@material-ui/core/";
import { SalaryTable } from '../../Parts/RunSalary/SalaryTable'
import { SalaryHelper } from "../../../Helpers/SalaryHelper";
import { FreelancerModel } from "../../../Models/FreelancerModel";
import "./RunSalary.scss";
import { RequestModel } from "../../../Models/RequestModel";
import { RouteComponentProps } from "react-router";
import { CountrySelector } from '../../Parts/General/CountrySelector';
import { SnackbarManager } from "../../../Helpers/SnackbarManager/SnackbarManager";

interface IProps extends RouteComponentProps { }
interface IState {
  anchorEl: null | HTMLElement;
  freelancers: FreelancerModel[];
  requests: RequestModel[];
  totalNumberOfFreelancers: number;
  totalSalaryAllFreelancers: number;
  totalHolidayAllowance: number;
  countryId: string;
}

export class RunSalaryView extends Component<IProps, IState> {
  salaryHelper = new SalaryHelper();
  salaryTable: any

  constructor(props: any) {
    super(props);

    this.state = {
      anchorEl: null,
      freelancers: [],
      requests: [],
      totalNumberOfFreelancers: 0,
      totalSalaryAllFreelancers: 0,
      totalHolidayAllowance: 0,
      countryId: "",
    }

    this.salaryTable = React.createRef();
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleEmployeesExportClick = this.handleEmployeesExportClick.bind(this);
    this.handleSalaryExportClick = this.handleSalaryExportClick.bind(this);
    this.handleNewSalaryExportClick = this.handleNewSalaryExportClick.bind(this);
    this.callbackFreelancersMarkedAsTransferred = this.callbackFreelancersMarkedAsTransferred.bind(this);
    this.countryChanged = this.countryChanged.bind(this)
    this.fetchData();
  }

  handleClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  async handleEmployeesExportClick() {
    let requests = this.salaryTable.current.getRequestsMarked();
    let filename = await this.salaryHelper.getFilenameForFreelancers(this.state.countryId);
    await this.salaryHelper.getFreelancersCsv(requests, this.state.countryId, filename);
  }

  async countryChanged(event: any, field: 'country' | 'search' | 'order') {

    await this.setState({
      countryId: event
    });

    this.fetchData();
  }


  async handleSalaryExportClick() {
    let requests = this.salaryTable.current.getRequestsMarked();
    let filename = await this.salaryHelper.getFilenameForSalary(this.state.countryId);
    await this.salaryHelper.getSalaryCsv(requests, this.state.countryId, filename);
  }

  async handleNewSalaryExportClick() {
    let requests = this.salaryTable.current.getRequestsMarked();
    let filename = await this.salaryHelper.getFilenameForSalary(this.state.countryId);
    await this.salaryHelper.getNewSalaryCsv(requests, this.state.countryId, filename);
  }

  async fetchData() {
    if (this.state.countryId === '') return;
    let salaryData: any = await this.salaryHelper.getSalary(this.state.countryId);
    if (salaryData === 403) {
      return SnackbarManager.Instance.addError('Your clearance level is not high enough');
    } else if (!salaryData) {
      return SnackbarManager.Instance.addError('could not fetch data');
    }

    let requests = salaryData.requestsForTransferSalaryFor;
    let uniqueFreelancers = [];
    let totalNumberOfFreelancers = 0;
    let totalSalaryAllFreelancers = 0;
    let totalHolidayAllowance = 0;

    let flags = []
    for (let i = 0; i < requests.length; i++) {
      if (flags[requests[i].Freelancer.Id]) continue;
      flags[requests[i].Freelancer.Id] = true;
      uniqueFreelancers.push(requests[i].Freelancer);
    }

    uniqueFreelancers = uniqueFreelancers.sort(function (a, b) { return (a.Login.Firstnames + " " + a.Login.Firstnames > b.Login.Firstnames + " " + b.Login.Firstnames) ? 1 : ((b.Login.Firstnames + " " + b.Login.Firstnames > a.Login.Firstnames + " " + a.Login.Firstnames) ? -1 : 0); });
    totalNumberOfFreelancers = uniqueFreelancers.length;

    for (let i = 0; i < requests.length; i++) {
      let request = requests[i];
      let freelancer = uniqueFreelancers.find(t => t.Id === request.FreelancerId);
      if (!freelancer.FullSalary) {
        freelancer.FullSalary = 0;
      }

      freelancer.FullSalary += request.FullSalary;
      totalSalaryAllFreelancers += request.FullSalary;
    }

    totalHolidayAllowance = totalSalaryAllFreelancers * 0.125;

    this.setState({
      freelancers: uniqueFreelancers,
      requests: requests,
      totalNumberOfFreelancers: totalNumberOfFreelancers,
      totalSalaryAllFreelancers: totalSalaryAllFreelancers,
      totalHolidayAllowance: totalHolidayAllowance
    });
  }

  generateTable() {
    if (this.state.freelancers.length > 0) {
      return <SalaryTable ref={this.salaryTable} freelancers={this.state.freelancers} countryId={this.state.countryId} requests={this.state.requests} callbackFreelancersMarkedAsTransferred={this.callbackFreelancersMarkedAsTransferred}></SalaryTable>;
    }
    return <div></div>

  }

  async callbackFreelancersMarkedAsTransferred() {
    this.setState({
      anchorEl: null,
      freelancers: [],
      requests: [],
      totalNumberOfFreelancers: 0,
      totalSalaryAllFreelancers: 0,
      totalHolidayAllowance: 0
    });

    this.fetchData();
  }

  render() {
    let salaryTable = this.generateTable();

    const { anchorEl } = this.state;

    let freelancers = this.state.totalNumberOfFreelancers.toString();
    let salary = (this.state.totalSalaryAllFreelancers / 100.0).toFixed(2).toString();
    let holidayAllowance = (this.state.totalHolidayAllowance / 100.0).toFixed(2).toString();

    return (
      <div className="runSalaryView">
        <div className="AdminPageHeader">
          <div className="AdminPageTitle">Run salary</div>
          <div className="AdminPageHeader_options">
            <div className="inputGroup">
              <input
                className="inputGroup_item"
                type="text"
                placeholder="Search..."
              />

              <CountrySelector className="inputGroup_item" valueChanged={this.countryChanged} />

              <select className="inputGroup_item">
                <option value="volvo">March 2019</option>
                <option value="saab">February 2019</option>
                <option value="mercedes">January 2019</option>
              </select>

              <div className="button button-success inputGroup_item">
                <a
                  href="#"
                  aria-owns={anchorEl ? "export-menu" : undefined}
                  aria-haspopup="true"
                  onClick={this.handleClick}
                >
                  <span className="btn-icon">
                    <i className="fas fa-download" />
                  </span>
                  Export
                </a>
              </div>
            </div>
          </div>
        </div>
        <Menu
          id="export-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          className="dropdownMenu"
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <div className="menuTitle">Export</div>
          <MenuItem onClick={this.handleEmployeesExportClick} className="menuItem">
            {" "}
            <i className="far fa-user" />Employees
          </MenuItem>
          <MenuItem onClick={this.handleSalaryExportClick} className="menuItem">
            {" "}
            <i className="far fa-money-bill-alt" />Salary
          </MenuItem>
          <MenuItem onClick={this.handleNewSalaryExportClick} className="menuItem">
            {" "}
            <i className="far fa-money-bill-alt" />Salary (NEW)
          </MenuItem>
        </Menu>

        <Grid
          container
          spacing={2}
          justify='space-between'
          alignItems='stretch'>
          <Grid item xs={3}>
            <div className='paper padding-24 radius-6'>
              <h2>Total chabbers</h2>
              <div className="cardNumber">{freelancers}</div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className='paper padding-24 radius-6'>
              <h2>Total salary</h2>
              <div className="cardNumber">{salary}</div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className='paper padding-24 radius-6'>
              <h2>Holiday pay</h2>
              <div className="cardNumber">{holidayAllowance}</div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className='paper padding-24 radius-6'>
              <h2>Meh?</h2>
              <div className="cardNumber">0</div>
            </div>
          </Grid>
        </Grid>
        {salaryTable}
      </div>
    );
  }
}
