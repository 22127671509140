import "./AdminNav.scss";
import React, { Component } from "react";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { AdminUserHelper } from "../../../../Helpers/AdminUserHelper";
// import { setAdminLoggedIn } from "../../../../Helpers/SessionHelper";
import { StorageHelper } from "../../../../Helpers/StorageHelper";
import {
  hasClearanceLevel,
  Clearances,
} from "../../../../Helpers/SessionHelper";

interface IProps extends RouteComponentProps {
  toggleAtlas: any;
  toggleAtlasIcon: boolean;
}

interface IState {}

export class AdminNav extends Component<IProps, IState> {
  storageHelper = new StorageHelper();

  constructor(props: IProps) {
    super(props);
    this.logOut = this.logOut.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({ toggleIcon: false });
    window.addEventListener("clearancesChanged", this.rerender);
  }

  rerender = () => {
    this.forceUpdate();
  };

  async logOut() {
    await new AdminUserHelper().adminlogOut();
    this.storageHelper.removeAtlasCountryFromLocal();
    this.storageHelper.removeAdminToken();
    this.storageHelper.removeAdminUser();
    this.props.toggleAtlas(false);
    this.props.history.push("/admin/login");
  }

  render() {
    return (
      <div className="adminMenu">
        <div className="adminMenu_top">
          <div className="adminLogo">CHABBER</div>
          <div className="adminTagline">Administration Interface</div>
        </div>
        <div className="adminMenu_wrapper">
          <div className="adminMenu_content">
            <NavLink exact className="adminMenu_link" to="/admin">
              <div className="adminMenu_link-icon">
                <i className="fas fa-tachometer-alt"></i>
              </div>
              <div className="adminMenu_link-label">Dashboard</div>
            </NavLink>
            <div className="adminMenu_linkGroup">
              <div className="adminMenu_linkGroupTitle">Freelance shift</div>
              <NavLink className="adminMenu_link" to="/admin/Err">
                <div className="adminMenu_link-icon">
                  <i className="far fa-clock"></i>
                </div>
                <div className="adminMenu_link-label">Open shifts</div>
              </NavLink>
              <NavLink className="adminMenu_link" to="/admin/Err">
                <div className="adminMenu_link-icon">
                  <i className="far fa-check-circle"></i>
                </div>
                <div className="adminMenu_link-label">Confirmed shifts</div>
              </NavLink>
              <NavLink className="adminMenu_link" to="#">
                <div className="adminMenu_link-icon">
                  <i className="far fa-arrow-alt-circle-left"></i>
                </div>
                <div className="adminMenu_link-label">Past shifts</div>
              </NavLink>
            </div>
            <div className="adminMenu_linkGroup">
              <div className="adminMenu_linkGroupTitle">Users</div>
              <NavLink className="adminMenu_link" to="/admin/Err">
                <div className="adminMenu_link-icon">
                  <i className="far fa-user"></i>
                </div>
                <div className="adminMenu_link-label">Chabbers</div>
              </NavLink>
              <NavLink className="adminMenu_link" to="/admin/Err">
                <div className="adminMenu_link-icon">
                  <i className="far fa-building"></i>
                </div>
                <div className="adminMenu_link-label">Companies</div>
              </NavLink>
              <NavLink className="adminMenu_link" to="/admin/Err">
                <div className="adminMenu_link-icon">
                  <i className="fas fa-home"></i>
                </div>
                <div className="adminMenu_link-label">Private</div>
              </NavLink>
            </div>
            <div className="adminMenu_linkGroup">
              <div className="adminMenu_linkGroupTitle">Economy</div>
              <NavLink className="adminMenu_link" to="/admin/Err">
                <div className="adminMenu_link-icon">
                  <i className="fas fa-dollar-sign"></i>
                </div>
                <div className="adminMenu_link-label">Overview</div>
              </NavLink>
              <NavLink className="adminMenu_link" to="/admin/runsalary">
                <div className="adminMenu_link-icon">
                  <i className="fas fa-file-invoice-dollar"></i>
                </div>
                <div className="adminMenu_link-label">Run salary</div>
              </NavLink>
              <NavLink
                className="adminMenu_link"
                to="/admin/collective-agreement"
              >
                <div className="adminMenu_link-icon">
                  <i className="fas fa-file-invoice-dollar"></i>
                </div>
                <div className="adminMenu_link-label">Collective agreement</div>
              </NavLink>
            </div>
            <div className="adminMenu_linkGroup">
              <div className="adminMenu_linkGroupTitle">System</div>
              <NavLink className="adminMenu_link" to="/admin/Err">
                <div className="adminMenu_link-icon">
                  <i className="far fa-list-alt"></i>
                </div>
                <div className="adminMenu_link-label">Historic data</div>
              </NavLink>
              <NavLink className="adminMenu_link" to="/admin/courses">
                <div className="adminMenu_link-icon">
                  <i className="fas fa-certificate"></i>
                </div>
                <div className="adminMenu_link-label">Courses</div>
              </NavLink>
              <NavLink className="adminMenu_link" to="/admin/employees">
                <div className="adminMenu_link-icon">
                  <i className="fas fa-user-friends"></i>
                </div>
                <div className="adminMenu_link-label">Employees</div>
              </NavLink>
              {hasClearanceLevel(Clearances.FacilitateVerification) && (
                <NavLink className="adminMenu_link" to="/admin/verification">
                  <div className="adminMenu_link-icon">
                    <i className="fas fa-user-check"></i>
                  </div>
                  <div className="adminMenu_link-label">Verification</div>
                </NavLink>
              )}
              <NavLink className="adminMenu_link" to="/admin/settings">
                <div className="adminMenu_link-icon">
                  <i className="fas fa-cog"></i>
                </div>
                <div className="adminMenu_link-label">Settings</div>
              </NavLink>
              <div
                className="adminMenu_link cursorPointer"
                onClick={this.logOut}
              >
                <div className="adminMenu_link-icon">
                  <i className="fas fa-sign-out-alt"></i>
                </div>
                <div className="adminMenu_link-label">Log out</div>
              </div>
            </div>
          </div>
        </div>
        <div className="adminMenu_bottom">
          <div className="adminMenu_Atlaslink" onClick={this.props.toggleAtlas}>
            <div className="adminMenu_AtlasLabel">Atlas editor</div>
            <div className="adminMenu_AtlasIcon">
              <i
                className={
                  this.props.toggleAtlasIcon
                    ? "fas fa-toggle-on"
                    : "fas fa-toggle-off"
                }
              ></i>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
