import React, { useEffect, useState } from "react";
import { getLanguage } from "../../../../../../Helpers/LanguageHelper";
import { Button, Card, CardActions, CardContent, Dialog, DialogContent, DialogTitle, Grid, Hidden, IconButton, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { MobileSidebarTrigger } from "../../../../../Parts/General/MobileSidebarTrigger";
import { CollectiveAgreementOnboardingModal, getRules } from "../../../../../../Modules/CollectiveAgreement";
import { StorageHelper } from "../../../../../../Helpers/StorageHelper";
import { RulesBySkillId } from "../../../../../../Modules/CollectiveAgreement/Types";
import { getCurrentUser } from "../../../../../../Helpers/SessionHelper";
import { LoginModel } from "../../../../../../Models/LoginModel";
import { Alert } from "@material-ui/lab";
import "./CompanySettingsSalaryRules.scss";
import { RulesForm } from "../../../../../../Modules/CollectiveAgreement/Components/RulesForm";
import CloseIcon from "@material-ui/icons/Close";

export function CompanySettingsSalaryRules() {
  const [selectedJobtype, setSelectedJobtype] = useState<string | null>(null);
  const [skillsWithRules, setSkillsWithRules] = useState<RulesBySkillId[]>([]);
  const [isViewRulesOpen, setIsViewRulesOpen] = useState<boolean>(false);
  const [isEditRulesOpen, setIsEditRulesOpen] = useState<boolean>(false);
  const [selectedJobtypeRules, setSelectedJobtypeRules] = useState<RulesBySkillId | undefined>();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const storageHelper = new StorageHelper();

  useEffect(() => {
    const getCollectiveAgreementRules = async (companyId: string) => {
      const allRules = await getRules(companyId);
      const allSkillsWithRules = storageHelper.getSkillsFromLocal().map<RulesBySkillId>(({ Id }) => {
        const rulesBySkillId = allRules?.find((x) => x.skillId === Id);
        return {
          skillId: Id,
          rules: rulesBySkillId?.rules ?? [],
          isAccepted: rulesBySkillId?.isAccepted ?? false,
        };
      });
      setSkillsWithRules(allSkillsWithRules);
    };
    const currentUser = getCurrentUser() as LoginModel;
    if (currentUser.Company?.Id) {
      getCollectiveAgreementRules(currentUser.Company.Id);
    }
  }, [isEditRulesOpen]);

  const getJobTitle = (skillId: string) => {
    const skill = storageHelper.getSkillsFromLocal().find((x) => x.Id === skillId);
    if (!skill) return "";
    return getLanguage(skill.getLanguageCode(1, false));
  };

  useEffect(() => {
    setSelectedJobtypeRules(skillsWithRules.find((x) => x.skillId === selectedJobtype));
  }, [selectedJobtype, skillsWithRules]);

  return (
    <div>
      <div className="padding-v-32 maxW-600">
        <MobileSidebarTrigger />
        <h1 className="inline-block">{getLanguage(898, "Salary rules")}</h1>
      </div>
      <Grid container spacing={3}>
        {skillsWithRules.map((skill) => (
          <Grid item xs={12} md={6}>
            <Card variant="outlined">
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  {getJobTitle(skill.skillId)}
                </Typography>
                {skill.isAccepted ? (
                  <Alert severity="success">{getLanguage(899, "Rules already defined")}</Alert>
                ) : (
                  <Alert severity="warning">{getLanguage(900, "No rules for this job type")}</Alert>
                )}
              </CardContent>
              <CardActions className="salary-rules-actions-container">
                {skill && skill.rules && skill.rules.length > 0 && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setSelectedJobtype(skill.skillId);
                      setIsViewRulesOpen(true);
                    }}
                  >
                    {getLanguage(902, "View rules")}
                  </Button>
                )}
                <Button
                  variant="contained"
                  className="success"
                  onClick={() => {
                    setSelectedJobtype(skill.skillId);
                    setIsEditRulesOpen(true);
                  }}
                >
                  {getLanguage(901, "Update")}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      {selectedJobtype && (
        <CollectiveAgreementOnboardingModal
          skillId={selectedJobtype}
          isOpen={isEditRulesOpen}
          onClose={() => setIsEditRulesOpen(false)}
          currentRules={selectedJobtypeRules?.rules.map((r) => ({
            ...r,
            value: (Number(r.value) / 100).toFixed(2),
          }))}
        />
      )}
      {selectedJobtypeRules && (
        <Dialog
          fullScreen={fullScreen}
          open={isViewRulesOpen}
          onClose={() => setIsViewRulesOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>
            {"Collective agreement rules"}{" "}
            <IconButton aria-label="close" onClick={() => setIsViewRulesOpen(false)} style={{ position: "absolute", right: 0 }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className="view-rules">
            <RulesForm
              rules={selectedJobtypeRules.rules.map((r) => ({
                ...r,
                value: (Number(r.value) / 100).toFixed(2),
              }))}
              onChange={() => {}}
              disabled
            />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
