// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from "react";
import { Grid, Button, Tooltip, Hidden } from "@material-ui/core";
import "./Favourites.scss";
import favorite from "../../../../assets/img/illustrations/favourite.svg";
import { UserHelper } from "../../../../Helpers/UserHelper";
import { FavoriteProfileModel } from "../../../../Models/FavoriteProfileModel";
import { getLanguage } from "../../../../Helpers/LanguageHelper";
import { NavLink } from "react-router-dom";
import { SnackbarManager } from "../../../../Helpers/SnackbarManager/SnackbarManager";
import { ProfilePictureViewer } from "../../../Parts/General/ProfilePictureViewer";

interface IProps { }

interface IState {
  favorites: favoriteProfileModelExtended[];
  areThereFavorites: boolean | string;
}

interface favoriteProfileModelExtended extends FavoriteProfileModel {
  pending: string;
}

export class FreelancerFavourites extends Component<IProps, IState> {
  userHelper = new UserHelper();

  constructor(props: IProps) {
    super(props);

    this.state = {
      favorites: [],
      areThereFavorites: ""
    };

    this.getRatingsAverage = this.getRatingsAverage.bind(this);
    this.preparePage = this.preparePage.bind(this);
    this.sortFavorites = this.sortFavorites.bind(this);
    this.returnPendingState = this.returnPendingState.bind(this);
    this.whichFormToShow = this.whichFormToShow.bind(this);
  }

  async componentDidMount() {
    await this.preparePage();
  }

  async preparePage() {
    let favorites = await this.userHelper.getFavorites() as favoriteProfileModelExtended[]; // FIXME this is hacky

    if (favorites.length === 0) {
      return await this.setState({ areThereFavorites: false });
    }

    if (favorites) {
      await this.setState({ favorites, areThereFavorites: true });
    }

    await this.sortFavorites(favorites);
  }

  async sortFavorites(favorites: favoriteProfileModelExtended[]) {
    favorites.sort(function (a, b) {
      if (a.Company && b.Company) {
        if (a.Company.CompanyName < b.Company.CompanyName) {
          return -1;
        }
        if (a.Company.CompanyName > b.Company.CompanyName) {
          return 1;
        }
        return 0;
      }
      return 0;
    });

    if (favorites) {
      for (const fav of favorites) {
        if (fav.ApprovalStatus === 0) fav.pending = "favouritePending";
        else fav.pending = "hidden";
      }
      await this.setState({ favorites });
    }
  }

  returnPendingState(row: favoriteProfileModelExtended) {
    if (row.ApprovalStatus === 0) {
      return (
        <div className="inputGroup margin-v-8">
          <Button
            className="inputGroup_item"
            onClick={() => this.acceptOrRejectInvite(row.Id, 2)}
          >
            {getLanguage(301, "Reject")}
          </Button>
          <div className="success inputGroup_item inline-block">
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => this.acceptOrRejectInvite(row.Id, 1)}
            >
              {getLanguage(300, "Accept")}
            </Button>
          </div>
        </div>
      );
    } else {
      return;
    }
  }

  async acceptOrRejectInvite(id: string, status: number) {
    let success = await this.userHelper.acceptOrRejectFavorite(id, status);
    let favorites = this.state.favorites;
    if (success) {
      for (let i = 0; i < favorites.length; i++) {
        if (favorites[i].Id === id) {
          if (status === 1) favorites[i].ApprovalStatus = 1;
          else {
            favorites.splice(i, 1);
          }
        }
      }
      if (status === 1) {
        SnackbarManager.Instance.addSuccess(getLanguage(296, "Invite accepted"));
        await this.setState({ favorites });
      }
      else if (status === 2) {
        SnackbarManager.Instance.addSuccess(getLanguage(297, "Company removed from list"));
        await this.setState({ favorites });
        if (favorites.length === 0) {
          await this.setState({ areThereFavorites: false });
        }
      }
    } else {
      SnackbarManager.Instance.addWarning(getLanguage(298, "Something went wrong!"));
      await this.setState({ favorites });
    }
  }

  getRatingsAverage(row: favoriteProfileModelExtended) {
    let stars: JSX.Element[] = [];
    let ratingValue = 0;
    if (row.Freelancer)
      ratingValue =
        ((row.Freelancer.RatingsAverage - 0) * (5 - 0)) / (100 - 0) + 0; // converts the range from 0-100 to 0-5

    for (let index = 0; index < Math.floor(ratingValue); index++) {
      stars.push(<i key={index} className="fas fa-star" />);
    }
    if (ratingValue - stars.length > 0.2 && ratingValue - stars.length < 0.8) {
      stars.push(<i key="7" className="fas fa-star-half-alt" />);
    }

    for (let index = stars.length; index < 5; index++) {
      stars.push(<i key={"a" + index} className="far fa-star" />);
    }

    return <div className="freelancerProfile_rating">{stars}</div>;
  }

  showDeleteButton(row: favoriteProfileModelExtended) {
    if (row.ApprovalStatus === 1) {
      return (
        <Tooltip
          title={getLanguage(299, "Remove from favourites")}
        >
          <div
            className="editUser"
            onClick={() => this.acceptOrRejectInvite(row.Id, 2)}
          >
            <i className="fas fa-times fa-lg" />
          </div>
        </Tooltip>
      );
    }
    return;
  }

  whichFormToShow() {
    if (typeof this.state.areThereFavorites === "string") {
      return <div />;
    } else if (
      typeof this.state.areThereFavorites === "boolean" &&
      !this.state.areThereFavorites
    ) {
      return (
        <div className="emptyState_wrapper">
          <div className="emptyState_content">
            <img
              className="noDrag"
              src={favorite}
              alt="Co-operate with collegues"
            />
            <h1 className="margin-bottom-0">{getLanguage(294, "No invites yet")}</h1>
            <p className="infoParagraph">
              {getLanguage(295, "When you’re a part of a company's favourites we’ll inform you as soon as they posts a new shift you match. In some cases you’ll even be able to get automatically confirmed. We'll send you an email or push notification once you receive an invite.")}
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="">
          <div className="margin-v-24">
            <Hidden smUp>
              <NavLink to="/freelancer/settings">
                <i className="fas fa-arrow-left fa-lg margin-right-16"></i>
              </NavLink>
            </Hidden>
            <h1 className="inline-block">{getLanguage(95, "Favourites")}</h1>
          </div>
          <Grid
            container
            spacing={4}
            direction="row"
            justify="flex-start"
            alignItems="stretch"
          >
            {this.state.favorites.map((row, index) => (
              <Grid item md={4} sm={6} xs={12} key={index}>
                <div className="paper padding-24 radius-6">
                  <div className="favouriteItem relative">
                    <Grid
                      container
                      item
                      justify="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <ProfilePictureViewer
                          login={row.Company!.Login}
                          company={row.Company}
                          freelancer={null}
                          customClass="favouriteImg"
                          altText="favourite profile"
                        ></ProfilePictureViewer>
                      </Grid>
                      <Grid item>
                        <div className="favouriteName">
                          {row.Company ? row.Company.CompanyName : ""}
                        </div>
                        <div className="favouriteRating">
                          {this.getRatingsAverage(row)}
                        </div>
                        {this.returnPendingState(row)}
                      </Grid>
                    </Grid>
                    {this.showDeleteButton(row)}
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="freelancerFavouriteView">
        {this.whichFormToShow()}
      </div>
    );
  }
}
