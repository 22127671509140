import React, { Component } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField
} from "@material-ui/core";
import { getLanguage } from '../../../../../../Helpers/LanguageHelper';

interface IProps {
  open: boolean;
  activeRequestMinimumSalary: number;
  closeDialogue: () => void;
  valueChanged: (val: number) => void;
  submit: () => void;
}
interface IState { }

export class ManualDialogue extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.closeDialogue}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{getLanguage(581, "Set overtime salary")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {getLanguage(582, "You can change the overtime salary below")}
          </DialogContentText>
          <TextField
            type="number"
            label="Salary in overtime"
            variant="outlined"
            fullWidth
            margin="dense"
            value={this.props.activeRequestMinimumSalary}
            onChange={(event) => this.props.valueChanged( +(event.target.value))}
          >
          </TextField>
        </DialogContent>
        <DialogActions className="dialogActions">
          <Button onClick={this.props.closeDialogue}>{getLanguage(242, "Cancel")}</Button>
          <Button color="primary" onClick={this.props.submit}>{getLanguage(583, "OK")}</Button>
        </DialogActions>
      </Dialog>
    );
  }
}