import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./AtlasNav.scss";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { StorageHelper } from "../../../../Helpers/StorageHelper";
import { PhotoLibrary } from "../../../Parts/General/PhotoLibrary";
import { LocalDataModel } from "../../../../Models/LocalDataModel";
import { CountryModel } from "../../../../Models/CountryModel";
import { CountryHelper } from "../../../../Helpers/CountryHelper";

interface IProps { }

interface IState {
  anchorEl: any;
  activeCountry: string;
  openMediaLibrary: boolean;
  countries: CountryModel[];
}

export class AtlasNav extends Component<IProps, IState> {
  storageHelper = new StorageHelper();

  constructor(props: IProps) {
    super(props);

    this.state = {
      anchorEl: null,
      activeCountry: '',
      openMediaLibrary: false,
      countries: [],
    };

    this.changeCountry = this.changeCountry.bind(this);
    this.openMediaLibrary = this.openMediaLibrary.bind(this);
    this.closeMediaLibrary = this.closeMediaLibrary.bind(this);
    this.setInitialCountry = this.setInitialCountry.bind(this);
  }

  async componentDidMount() {
    let facadeLocalization = this.storageHelper.getFacadeLocalization();
    if (facadeLocalization && facadeLocalization.countries) {
      await this.setState({ countries: facadeLocalization.countries })
    }

    this.setInitialCountry();
  }

  setInitialCountry() {
    let initialCountryId = this.storageHelper.getAtlasCountryFromLocal();

    let facadeLocalization = this.storageHelper.getFacadeLocalization();
    let user = this.storageHelper.getAdminUser();
    if (user == null || facadeLocalization == null) return;

    if (initialCountryId == null || initialCountryId === '') {
      initialCountryId = user.CountryId;
    }

    let country = facadeLocalization.countries.find(x => x.Id === initialCountryId);

    let twoCharacterCountryCode = 'DK';
    if (country) {
      twoCharacterCountryCode = country.TwoCharacterCountryCode;
      this.storageHelper.setAtlasCountryInLocal(country.Id);
    } else {
      country = facadeLocalization.countries.find(x => x.TwoCharacterCountryCode === 'DK');
      this.storageHelper.setAtlasCountryInLocal(country!.Id);
    }

    this.setState({ activeCountry: twoCharacterCountryCode });

  }

  handleClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  async openMediaLibrary() {
    this.setState({ openMediaLibrary: true });
  }

  async closeMediaLibrary() {
    this.setState({ openMediaLibrary: false });
  }

  async changeCountry(country: CountryModel) {
    if (!country) return;
    await this.setState({ activeCountry: country.TwoCharacterCountryCode });
    this.storageHelper.setAtlasCountryInLocal(country.Id);
  }





  render() {
    const { anchorEl } = this.state;
    return (
      <div className="atlasMenu">
        <PhotoLibrary
          open={this.state.openMediaLibrary}
          close={this.closeMediaLibrary}
          accessSite="atlas"
        ></PhotoLibrary>
        <div className="atlasMenuContent">
          <div className="menu-primary__links">
            <a
              className="menu-primary__link"
              href="#"
              aria-owns={anchorEl ? "simple-menu" : undefined}
              aria-haspopup="true"
              onClick={this.handleClick}
            >
              <div className="menu-primary__link-inner">
                <div className="menu-primary__link-icon">
                  <i className="fas fa-plus" />
                </div>
                <div className="menu-primary__link-label">New page</div>
              </div>
            </a>
            <NavLink className="menu-primary__link" to="/admin/sitemap">
              <div className="menu-primary__link-inner">
                <div className="menu-primary__link-icon">
                  <i className="fas fa-sitemap" />
                </div>
                <div className="menu-primary__link-label">Sitemap</div>
              </div>
            </NavLink>
            <NavLink className="menu-primary__link" to="/admin/language">
              <div className="menu-primary__link-inner">
                <div className="menu-primary__link-icon">
                  <i className="fas fa-file-alt" />
                </div>
                <div className="menu-primary__link-label">
                  Language database
                </div>
              </div>
            </NavLink>
            <NavLink className="menu-primary__link" to="/admin/communication">
              <div className="menu-primary__link-inner">
                <div className="menu-primary__link-icon">
                  <i className="fas fa-satellite-dish" />
                </div>
                <div className="menu-primary__link-label">
                  Communication system
                </div>
              </div>
            </NavLink>
            <NavLink className="menu-primary__link" to="/admin/features">
              <div className="menu-primary__link-inner">
                <div className="menu-primary__link-icon">
                  <i className="fas fa-file-upload" />
                </div>
                <div className="menu-primary__link-label">Feature release</div>
              </div>
            </NavLink>
            <a
              className="menu-primary__link"
              href="#"
              onClick={this.openMediaLibrary}
            >
              <div className="menu-primary__link-inner">
                <div className="menu-primary__link-icon">
                  <i className="fas fa-images" />
                </div>
                <div className="menu-primary__link-label">Media library</div>
              </div>
            </a>
          </div>
          <div className="menu-primary__footer">
            <span className="menu-primary__footer-item menu-primary__user">
              <div className="menu-primary__link-inner">
                <div className="menu-primary__link-icon">
                  <i className="fas fa-globe" />
                  <div className={"menu-primary__link-icon_indicator " + this.state.activeCountry} />
                </div>

                <div className="menu-primary__menu menu-primary__user-menu">
                  <div className="menu-primary__menu-section">
                    <div className="menu-primary__menu-section-title">
                      Country
                    </div>

                    {this.state.countries.map((row, index) => (
                      <a key={index}
                        className="menu-primary__menu-item"
                        href="#"
                        onClick={() => this.changeCountry(row)}
                      >
                        <span
                          className={
                            "menu-primary__menu-item-icon icon-flag-" +
                            row.TwoCharacterCountryCode
                          }
                        />
                        {row.CountryName}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          className="dropdownMenu"
        >
          <div className="menuTitle">Add item</div>
          <NavLink to="/admin/blogmeta">
            <MenuItem onClick={this.handleClose} className="menuItem">
              {" "}
              <i className="far fa-file-alt" />
              Blog post
            </MenuItem>
          </NavLink>
          <MenuItem onClick={this.handleClose} className="menuItem">
            {" "}
            <i className="far fa-newspaper" />
            Press article
          </MenuItem>
          <MenuItem onClick={this.handleClose} className="menuItem">
            {" "}
            <i className="far fa-building" />
            Job post
          </MenuItem>
          <MenuItem onClick={this.handleClose} className="menuItem">
            {" "}
            <i className="far fa-handshake" />
            Partner
          </MenuItem>
          <MenuItem onClick={this.handleClose} className="menuItem">
            {" "}
            <i className="fas fa-search" />
            SEO page
          </MenuItem>
          <NavLink to="/admin/coursemeta">
            <MenuItem onClick={this.handleClose} className="menuItem">
              {" "}
              <i className="fas fa-certificate" />
              Course page
          </MenuItem>
          </NavLink>
        </Menu>
      </div>
    );
  }
}
