import { CountryModel } from "../Models/CountryModel";
import { LocalDataModel } from "../Models/LocalDataModel";
import { CurrencyModel } from "../Models/CurrencyModel";
import { StorageHelper } from "./StorageHelper";
import { getLanguage } from "./LanguageHelper";
import { ApplicationProductModel } from "../Models/ApplicationProductModel";

let local: LocalDataModel | null = null;
let decimalSymbol: string | null = null;
let applicationProducts: ApplicationProductModel[] | null = null;

function initializeCaches() {
  let storageHelper = new StorageHelper();

  local = storageHelper.getFacadeLocalization() as LocalDataModel;
  if (local == null) return;
  decimalSymbol = new CountryModel(local.country).getNumberFormat();

  applicationProducts = storageHelper.getApplicationProducts();
}

initializeCaches();

export function changeToLocalDecimal(value: string) {
  if (decimalSymbol == null) {
    initializeCaches();
  }
  if (typeof (decimalSymbol) == 'string') {
    return value.replace(/[.,]/g, decimalSymbol);
  } else {
    return value.replace(/[.,]/g, '.');
  }
}

export function getPriceForProduct(productCode: string) {
  if (applicationProducts == null) {
    initializeCaches();
  }

  let price = applicationProducts?.find(t => t.ProductCode === productCode)
  if (price == null) {
    return "0";
  }

  return (price.Price / 100.0).toString()
}

export function getPriceForProductAsDecimal(productCode: string) {
  if (applicationProducts == null) {
    initializeCaches();
  }

  let price = applicationProducts?.find(t => t.ProductCode === productCode)
  if (price == null) {
    return 0.9;
  }

  return (price.Price / 100.0);
}

export function getMoneyOutput(money: string, perHour: boolean, currency: CurrencyModel | null, useCurrenyCode: boolean = false) {
  if (!currency) {
    let facadeData = new StorageHelper().getFacadeLocalization();
    if (facadeData && facadeData.country && facadeData.country.Currency) {
      currency = facadeData.country.Currency;
    }
  }

  if (!currency) {
    return "N/A"
  }

  let moneyString = getCurrencyFormat(currency, perHour, money, useCurrenyCode)
  return moneyString;
}


export function getCurrencyFormat(currency: CurrencyModel, perHour: boolean, amount: number | string, useCurrenyCode: boolean = false) {
  let currencyShorthand = useCurrenyCode ? currency.Code : currency.Shorthand;
  let currencyString: string = '';
  //TODO: shorthand needs to be fixed in DB
  if (!perHour) {
    switch (currency.OutputFormat) {
      case "A":
        currencyString = amount + " " + (useCurrenyCode ? currencyShorthand : currencyShorthand.toLowerCase());
        break;
      case "B":
        currencyString = currencyShorthand + "" + amount;
        break;
    }
  } else {
    currencyString = getLanguage(204);
    currencyString = currencyString.replace("{amount}", amount.toString())
    currencyString = currencyString.replace("{currency}", useCurrenyCode ? currencyShorthand.toString().trim() : currencyShorthand.toString().toLowerCase().trim())
  }

  return currencyString;


}
