// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import "./Shift.scss";
import {
  Grid,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Chip,
  TablePagination,
  Hidden
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import {
  getLanguage,
  LanguageHelper
} from "../../../../Helpers/LanguageHelper";
import { UserHelper } from "../../../../Helpers/UserHelper";
import { RequestModel } from "../../../../Models/RequestModel";
import { PostingModel } from "../../../../Models/PostingModel";
import { StorageHelper } from "../../../../Helpers/StorageHelper";
import { getDateObjectFromDateString } from "../../../../Helpers/StringExtractorHelper";
import { getCurrentUser } from "../../../../Helpers/SessionHelper";
import { PostingHelper } from "../../../../Helpers/PostingHelper";
import { PastPostingModel } from "../../../../Models/PastPostingModel";
import { PageLoader } from "../../../Parts/General/PageLoader";
import { getMoneyOutput } from "../../../../Helpers/LocalizationHelper";
import { MessageNetworksModel } from "../../../../Models/MessageNetworksModel";
import { ChatHelper } from "../../../../Helpers/ChatHelper";
import { SkillModel } from "../../../../Models/SkillModel";
import { orderPostingsOrRequests } from "../../../../Helpers/PostingHelper";
import moment from "moment";
import {
  nowAsFormatedString,
  addToDate
} from "../../../../Helpers/DateTimeHelper";
import { ProfilePictureViewer } from "../../../Parts/General/ProfilePictureViewer";
import { FirstJobConfirmation } from "./FirstJobConfirmation";
import { resolveSalaryPerHourForRequest } from "../../../../Modules/CollectiveAgreement/Utils";

interface IProps extends RouteComponentProps {
  archive: boolean;
}

interface IState {
  userRequests: RequestModel[];
  openPostingDialog: boolean;
  postingToOpen: PostingModel | null;
  months: string[];
  anchorEl: HTMLElement | null;
  chips: string[];
  requestsToShow: RequestModel[];
  propsArchive: boolean | null;
  pageState: "loading" | "empty" | "showPosts";
  page: number;
  archiveCount: number;
  existingPages: number[];
  jobs: PastPostingModel | null;
  skills: SkillModel[];
  firstJob: boolean
  confirmFirstJob: boolean
  isConfirmingFirstJob: boolean
}

export class Shifts extends Component<IProps, IState> {
  userHelper = new UserHelper();
  storageHelper = new StorageHelper();
  languageHelper = new LanguageHelper();
  postingHelper = new PostingHelper();

  constructor(props: IProps) {
    super(props);

    this.state = {
      userRequests: [],
      openPostingDialog: false,
      postingToOpen: null,
      months: [],
      anchorEl: null,
      chips: [],
      requestsToShow: [],
      propsArchive: null,
      pageState: "loading",
      page: 0,
      archiveCount: 0,
      existingPages: [0],
      jobs: null,
      skills: this.storageHelper.getSkillsFromLocal() as SkillModel[],
      firstJob: false,
      confirmFirstJob: false,
      isConfirmingFirstJob: false
    };

    this.preparePage = this.preparePage.bind(this);
    this.openSpecificPosting = this.openSpecificPosting.bind(this);
    this.dateOfJob = this.dateOfJob.bind(this);
    this.setChips = this.setChips.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.showChip = this.showChip.bind(this);
    this.shiftOrArchiveTable = this.shiftOrArchiveTable.bind(this);
    this.salaryPayDateInfo = this.salaryPayDateInfo.bind(this);
    this.confirmFirstJob = this.confirmFirstJob.bind(this)
  }

  async componentDidMount() {
    await this.setState({ propsArchive: this.props.archive });
    this.preparePage();
  }

  async componentDidUpdate() {
    if (this.props.archive !== this.state.propsArchive) {
      this.preparePage();
    }
  }

  async preparePage(page?: number) {
    let user = getCurrentUser();
    let usersRequests: RequestModel[] = [];
    if (!this.props.archive) {
      usersRequests = await this.userHelper.getUsersRequests();
    }

    let pageToGet = this.state.page;
    if (page) {
      pageToGet = page;
    }

    let historyObjects: PastPostingModel = new PastPostingModel({});
    if (this.props.archive) {
      historyObjects = await this.postingHelper.getAllPastPostingsWhichAreOverThirtyDaysOld(
        pageToGet,
        10,
        false
      );
    } else {
      historyObjects = await this.postingHelper.getAllPastPostingsThatAreMaxThirtyDaysOld(
        pageToGet,
        10,
        false
      );
    }
    await this.setState({ jobs: historyObjects });

    let months = await this.languageHelper.getMonthsByLanguage(true);

    let jobsToOrder: RequestModel[] = [];

    for (const request of usersRequests) {
      let endDate = request.EndDateTimeLocal;
      let todaysDate = nowAsFormatedString();
      let archiveDate = addToDate(request.EndDateTimeLocal, 3, "days");

      if (request.Posting) {
        if (
          (request.DeclinedByCompany && request.RequestStatus === 1) ||
          request.RequestStatus !== 1
        ) {
          if (endDate > todaysDate || todaysDate < archiveDate) {
            request.Posting.StartAtLocalObject = getDateObjectFromDateString(
              request.Posting.StartAtLocal
            );
            request.Posting.EndAtLocalObject = getDateObjectFromDateString(
              request.Posting.EndAtLocal
            );
            request.vmUnreadMessages = false;
            jobsToOrder.push(request);
          }
        }
      }
    }

    let firstJob = user?.Freelancer?.LastConfirmedSmsNotification == null && usersRequests.find(t => t.RequestStatus === 3) != null

    let pastJobsToOrder: RequestModel[] = [];

    if (historyObjects && Array.isArray(historyObjects.Requests)) {
      let pastRequests = historyObjects.Requests;
      for (const request of pastRequests) {
        if (request.Posting && request.RequestStatus === 3 && request.EndDateTimeLocal < nowAsFormatedString()) {
          request.Posting.StartAtLocalObject = await getDateObjectFromDateString(request.Posting.StartAtLocal);
          request.Posting.EndAtLocalObject = await getDateObjectFromDateString(request.Posting.EndAtLocal);
          request.vmUnreadMessages = false;
          pastJobsToOrder.push(request);
        }
      }
    }

    let jobsToShow: RequestModel[] = [];

    jobsToOrder = await orderPostingsOrRequests(
      null,
      jobsToOrder,
      true
    ) as RequestModel[];
    for (const i of jobsToOrder) {
      jobsToShow.push(i);
    }

    pastJobsToOrder = await orderPostingsOrRequests(
      null,
      pastJobsToOrder,
      false
    ) as RequestModel[];
    for (const i of pastJobsToOrder) {
      jobsToShow.push(i);
    }

    let messageNetworks:
      | MessageNetworksModel[]
      | null = await new ChatHelper().getAllMessageNetworksAndMessages();

    if (Array.isArray(messageNetworks)) {
      messageNetworks = messageNetworks.filter(x => x.Type === 1);
    } else {
      messageNetworks = [];
    }

    for (const job of jobsToShow) {
      for (const messageNetwork of messageNetworks) {
        if (
          messageNetwork.Posting &&
          job?.PostingId === messageNetwork.PostingId
        ) {
          for (const login of messageNetwork.Logins) {
            if (login.Id === user?.Id) {
              if (
                login.MessageNetworkMembers &&
                (messageNetwork.LastMessageDate &&
                  ((login.MessageNetworkMembers.LastSeenDate && login.MessageNetworkMembers.LastSeenDate < messageNetwork.LastMessageDate)
                    || login.MessageNetworkMembers.LastSeenDate == null)
                )
              ) {
                job.vmUnreadMessages = true;
              }
            }
          }
        }
      }
    }

    let archiveCount = historyObjects.CountJobs || 0;

    await this.setState({
      userRequests: jobsToShow,
      requestsToShow: jobsToShow,
      months: months!,
      pageState: jobsToShow.length > 0 ? "showPosts" : "empty",
      archiveCount: archiveCount,
      firstJob
    });
  }

  async confirmFirstJob() {
    await this.setState({ isConfirmingFirstJob: true });
    let response = await this.userHelper.confirmFirstJob();
    if (response) {
      let user = getCurrentUser();
      if (user && user.Freelancer) {
        // @ts-ignore
        user.Freelancer.LastConfirmedSmsNotification = nowAsFormatedString();
        this.storageHelper.setCurrentUser(JSON.stringify(user));
      }

      this.storageHelper.sendEvent("trackEvent", {
        event: "ConfirmedFirstJob",
        data: "Freelancer"
      });
      this.setState({ isConfirmingFirstJob: false, firstJob: false });
    }
    this.setState({ isConfirmingFirstJob: false });
  }

  async openSpecificPosting(request: RequestModel) {
    if (request && request.Posting) {
      this.props.history.push("/freelancer/shift?id=" + request!.Posting!.Id, {
        activePosting: request.Posting,
        activeRequest: request,
        historyObjects: this.state.jobs
      });
    }
    // TODO: should we have a snackbar here?
  }

  dateOfJob(row: RequestModel) {
    if (row.Posting) {
      return (
        <div>
          <Hidden only="xs">
            {row.Posting.StartAtLocalObject.day
              ? row.Posting.StartAtLocalObject.day
              : ""}
            .{" "}
            {this.state.months
              ? this.state.months[
                +row.Posting!.StartAtLocalObject.month - 1
              ].toLowerCase()
              : ""}{" "}
            {row.Posting.StartAtLocalObject.year}
          </Hidden>
          <Hidden smUp>
            <span className="bold">
              {row.Posting.StartAtLocalObject.day
                ? row.Posting.StartAtLocalObject.day
                : ""}
            </span>
            <br />
            {this.state.months
              ? this.state.months[
                +row.Posting!.StartAtLocalObject.month - 1
              ].toLowerCase()
              : ""}
          </Hidden>
        </div>
      );
    }
    return;
  }

  setChips(row: RequestModel) {
    if (row.RequestStatus === 3) {
      if (moment(row.EndDateTimeLocal) < moment()) {
        return (
          <Tooltip
            title={getLanguage(673, "The shift has ended")}
            placement="top"
          >
            <i className="far fa-star" />
          </Tooltip>
        );
      }
      return (
        <Tooltip
          title={getLanguage(670, "ou've been confirmed for the shift")}
          placement="top"
        >
          <i className="fas fa-check-circle success" />
        </Tooltip>
      );
    } else if (row.RequestStatus === 1) {
      return (
        <Tooltip
          title={getLanguage(671, "You didn't get the shift")}
          placement="top"
        >
          <i className="fas fa-ban danger" />
        </Tooltip>
      );
    } else if (row.RequestStatus === 0) {
      return (
        <Tooltip title={getLanguage(672, "Application sent")} placement="top">
          <i className="fas fa-circle-notch warning fa-spin" />
        </Tooltip>
      );
    }
  }

  async handleClick(el: any, type: string) {
    el.stopPropagation();
    if (type === "table")
      await this.setState({
        anchorEl: el.currentTarget
      });
  }

  async handleClose() {
    await this.setState({
      anchorEl: null
    });
  }

  async showChip(
    chipType:
      | "Open requests"
      | "Confirmed requests"
      | "Rejected requests"
      | "Past requests"
  ) {
    let chips = this.state.chips;
    switch (chipType) {
      case "Open requests":
        if (chips.indexOf(chipType) === -1) {
          chips.push("Open requests");
          await this.setState({ chips: chips, anchorEl: null });
          await this.filterRequests();
        }
        break;
      case "Confirmed requests":
        if (chips.indexOf(chipType) === -1) {
          chips.push("Confirmed requests");
          await this.setState({ chips: chips, anchorEl: null });
          await this.filterRequests();
        }
        break;
      case "Rejected requests":
        if (chips.indexOf(chipType) === -1) {
          chips.push("Rejected requests");
          await this.setState({ chips: chips, anchorEl: null });
          await this.filterRequests();
        }
        break;
      case "Past requests":
        if (chips.indexOf(chipType) === -1) {
          chips.push("Past requests");
          await this.setState({ chips: chips, anchorEl: null });
          await this.filterRequests();
        }
        break;
    }
  }

  async filterRequests() {
    let chips = this.state.chips;
    let requestsAndUnreadMessages = this.state.userRequests;
    let pastRequestsAndUnreadMessages = this.state.userRequests;
    let showRequests: RequestModel[] = [];
    let jobsToOrder: RequestModel[] = [];
    let pastJobsToOrder: RequestModel[] = [];

    if (chips.length > 0) {
      for (const chipType of chips) {
        if (chipType === "Open requests") {
          let addToShowRequests = requestsAndUnreadMessages.filter(
            this.openRequestsFilter
          );
          for (const i of addToShowRequests) {
            if (jobsToOrder.indexOf(i) !== -1) return;
            jobsToOrder.push(i);
          }
        }
        if (chipType === "Confirmed requests") {
          let addToShowPostings = requestsAndUnreadMessages.filter(
            this.confirmedRequestsFilter
          );
          for (const i of addToShowPostings) {
            if (jobsToOrder.indexOf(i) !== -1) return;
            jobsToOrder.push(i);
          }
        }
        if (chipType === "Rejected requests") {
          let addToShowPostings = requestsAndUnreadMessages.filter(
            this.rejectedRequests
          );
          for (const i of addToShowPostings) {
            if (jobsToOrder.indexOf(i) !== -1) return;
            jobsToOrder.push(i);
          }
        }
        if (chipType === "Past requests") {
          let addToShowPostings = pastRequestsAndUnreadMessages.filter(
            this.pastRequests
          );
          for (const i of addToShowPostings) {
            if (pastJobsToOrder.indexOf(i) !== -1) return;
            pastJobsToOrder.push(i);
          }
        }
      }

      jobsToOrder = await orderPostingsOrRequests(
        null,
        jobsToOrder,
        true
      ) as RequestModel[];

      for (const i of jobsToOrder) {
        showRequests.push(i);
      }

      pastJobsToOrder = await orderPostingsOrRequests(
        null,
        pastJobsToOrder,
        false
      ) as RequestModel[];
      for (const i of pastJobsToOrder) {
        showRequests.push(i);
      }

      return await this.setState({ requestsToShow: showRequests });
    }
    return;
  }

  confirmedRequestsFilter(requestAndUnreadMessages: RequestModel) {
    if (
      requestAndUnreadMessages &&
      requestAndUnreadMessages &&
      requestAndUnreadMessages.RequestStatus === 3
    ) {
      return requestAndUnreadMessages;
    }
  }

  openRequestsFilter(requestAndUnreadMessages: RequestModel) {
    if (
      requestAndUnreadMessages &&
      requestAndUnreadMessages &&
      requestAndUnreadMessages.RequestStatus === 0
    ) {
      return requestAndUnreadMessages;
    }
  }

  rejectedRequests(requestAndUnreadMessages: RequestModel) {
    if (
      requestAndUnreadMessages &&
      requestAndUnreadMessages &&
      requestAndUnreadMessages.RequestStatus === 1
    )
      return requestAndUnreadMessages;
  }

  pastRequests(requestAndUnreadMessages: RequestModel) {
    if (
      !requestAndUnreadMessages ||
      !requestAndUnreadMessages ||
      !requestAndUnreadMessages.EndDateTimeLocal
    )
      return;

    let endDateTime = requestAndUnreadMessages.EndDateTimeLocal;
    let todayDateTime = nowAsFormatedString();
    if (endDateTime < todayDateTime) return requestAndUnreadMessages;
  }

  async handleDelete(label: string) {
    let chips = this.state.chips;
    for (let i = 0; i < chips.length; i++) {
      if (label === chips[i]) {
        chips.splice(i, 1);
      }
    }
    if (chips.length === 0) {
      return await this.setState({ requestsToShow: this.state.userRequests });
    }

    await this.filterRequests();

    await this.setState({ chips: chips });
  }

  async handleChangePage(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) {
    let existingPages = this.state.existingPages;
    let pageExists: boolean = false;
    pageExists = existingPages.includes(page);

    if (pageExists) {
      await this.setState({ page });
    } else {
      existingPages = existingPages.concat(page);
      await this.setState({ page, existingPages });
      await this.preparePage(page);
    }
  }

  shiftOrArchiveTable() {
    if (this.props.archive) {
      return (
        <div>
          <Table className="table">
            <TableHead className="tableHead">
              <TableRow className="tableRow">
                <TableCell> {getLanguage(183, "Date")}</TableCell>
                <TableCell> {getLanguage(184, "Time")}</TableCell>
                <TableCell>{getLanguage(198, "Workplace")}</TableCell>
                <TableCell>{getLanguage(199, "Job type")}</TableCell>
                <TableCell align="right">
                  {" "}
                  {getLanguage(185, "Salary")}
                </TableCell>
                <TableCell align="center">
                  {getLanguage(291, "Payment status")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="tableBody">
              {this.state.requestsToShow
                .map((row, index) => {
                  return (
                    <TableRow
                      onClick={() => this.openSpecificPosting(row)}
                      key={index}
                      className="tableRow"
                      classes={{ hover: "tableRow_hover" }}
                      hover={true}
                    >
                      <TableCell className="tableCell">
                        {this.dateOfJob(row)}
                      </TableCell>
                      <TableCell className="tableCell">
                        {row.Posting ? row.Posting.StartAtLocalObject.time : ""}
                        -{row.Posting ? row.Posting.EndAtLocalObject.time : ""}
                      </TableCell>
                      <TableCell className="tableCell">
                        {row.Posting && row.Posting.Company
                          ? row.Posting.Company.CompanyName
                          : ""}
                      </TableCell>
                      <TableCell className="tableCell">
                        {"\u00A0"}
                        {this.getJobTitle(
                          row.SkillId,
                          row.Posting ? row.Posting.AmountNeeded : 1
                        )}{" "}
                        ({row.Posting ? row.Posting.AmountNeeded : ""})
                      </TableCell>
                      <TableCell className="tableCell" align="right">
                        {getMoneyOutput(
                          (resolveSalaryPerHourForRequest(row) / 100).toString(),
                          true,
                          row.Currency
                        )}
                      </TableCell>
                      <TableCell className="tableCell" align="center">
                        {this.salaryPayDateInfo(row)}
                      </TableCell>
                    </TableRow>
                  );
                })}

              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  count={this.state.archiveCount}
                  rowsPerPage={10}
                  page={this.state.page}
                  backIconButtonProps={{
                    "aria-label": "previous page"
                  }}
                  nextIconButtonProps={{
                    "aria-label": "next page"
                  }}
                  onPageChange={(event, page) =>
                    this.handleChangePage(event, page)
                  }
                />
              </TableRow>
            </TableBody>
          </Table>
        </div>
      );
    } else {
      return (
        <div>
          <Hidden only="xs">
            <Table className="table">
              <TableHead className="tableHead">
                <TableRow className="tableRow" hover={false}>
                  <TableCell align="center">
                    {" "}
                    {getLanguage(182, "Status")}
                  </TableCell>
                  <TableCell> {getLanguage(183, "Date")}</TableCell>
                  <TableCell> {getLanguage(184, "Time")}</TableCell>
                  <TableCell>{getLanguage(198, "Workplace")}</TableCell>
                  <TableCell>{getLanguage(199, "Job type")}</TableCell>
                  <TableCell>{getLanguage(185, "Salary")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="tableBody">
                {this.state.requestsToShow.map((row, index) => {
                  return (
                    <TableRow
                      onClick={() => this.openSpecificPosting(row)}
                      // onClick={() => alert('alert!')}
                      key={index}
                      className="tableRow"
                      classes={{ hover: "tableRow_hover" }}
                      hover={true}
                    >
                      <TableCell className="tableCell" align="center">
                        {this.setChips(row)}
                      </TableCell>
                      <TableCell className="tableCell">
                        {this.dateOfJob(row)}
                      </TableCell>
                      <TableCell className="tableCell">
                        {row.Posting ? row.Posting.StartAtLocalObject.time : ""}
                        -{row.Posting ? row.Posting.EndAtLocalObject.time : ""}
                      </TableCell>
                      <TableCell className="tableCell">
                        {row.Posting && row.Posting.Company
                          ? row.Posting.Company.CompanyName
                          : ""}
                      </TableCell>
                      <TableCell className="tableCell">
                        {this.getJobTitle(
                          row.SkillId,
                          row.Posting ? row.Posting.AmountNeeded : 1
                        )}{" "}
                        ({row.Posting ? row.Posting.AmountNeeded : ""})
                      </TableCell>
                      <TableCell className="tableCell">
                        {getMoneyOutput(
                          (resolveSalaryPerHourForRequest(row) / 100).toString(),
                          true,
                          row.Currency
                        )}
                        {row.vmUnreadMessages ? (
                          <div className="tableBadge brand newMessage">
                            {getLanguage(211, "Unread message")}
                          </div>
                        ) : (
                            <div></div>
                          )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Hidden>
          <Hidden smUp>
            {this.state.requestsToShow.map((row, index) => {
              return (
                <div
                  onClick={() => this.openSpecificPosting(row)}
                  // onClick={() => alert('alert!')}
                  key={index}
                  className="freelancerShiftItem"
                >
                  <Grid container justify="space-between" alignItems="center">
                    <Grid container item xs wrap="nowrap" alignItems="center">
                      <Grid item>
                        <div className="freelancerShiftItem_icon">
                          {" "}
                          {this.setChips(row)}
                        </div>
                      </Grid>
                      <Grid item>
                        <div className="freelancerShiftItem_date">
                          {this.dateOfJob(row)}
                        </div>
                      </Grid>
                      <Grid item>
                        <div className="freelancerShiftItem_image">
                          <ProfilePictureViewer
                            login={row.Posting!.Company!.Login}
                            company={row.Posting!.Company}
                            freelancer={null}
                            customClass="postingFull_image"
                            altText="jobposting"
                          ></ProfilePictureViewer>
                        </div>
                      </Grid>
                      <Grid item xs zeroMinWidth>
                        <div className="freelancerShiftItem_details">
                          <div>
                            <h2 className="margin-0">
                              {row.Posting && row.Posting.Company
                                ? row.Posting.Company.CompanyName
                                : ""}
                            </h2>
                          </div>
                          <div>
                            {row.Posting
                              ? row.Posting.StartAtLocalObject.time
                              : ""}
                            -
                            {row.Posting
                              ? row.Posting.EndAtLocalObject.time
                              : ""}
                            {" | "}
                            {this.getJobTitle(
                              row.SkillId,
                              row.Posting ? row.Posting.AmountNeeded : 1
                            )}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item>
                      {getMoneyOutput(
                        (resolveSalaryPerHourForRequest(row) / 100).toString(),
                        true,
                        row.Currency
                      )}
                    </Grid>
                  </Grid>
                  <div>
                    {row.vmUnreadMessages ? (
                      <div className="tableBadge brand newMessage">
                        {getLanguage(211, "Unread message")}
                      </div>
                    ) : (
                        <div></div>
                      )}
                  </div>
                </div>
              );
            })}
          </Hidden>
        </div>
      );
    }
  }

  getJobTitle(skillId: string, amount: number) {
    let skill = this.state.skills.find(x => x.Id === skillId);
    if (!skill) return "";
    return getLanguage(skill.getLanguageCode(amount, true, true));
  }

  salaryPayDateInfo(request: RequestModel) {
    let dateNow = nowAsFormatedString();
    let salaryPayDate = getDateObjectFromDateString(
      request.DateForSalaryPayout
    );
    if (request.DateForSalaryPayout && request.DateForSalaryPayout < dateNow) {
      return (
        <div>
          <div className={"tableBadge success"}>
            {getLanguage(292, "Paid out: ")} {salaryPayDate.day}/
            {salaryPayDate.month}/{salaryPayDate.year}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className={"tableBadge"}>
            {getLanguage(293, "Pay-day: ")}
            {salaryPayDate.day}/{salaryPayDate.month}/{salaryPayDate.year}
          </div>
        </div>
      );
    }
  }

  renderEmptyState(): React.ReactNode {
    return (
      <div className="emptyState_wrapper">
        <div className="emptyState_content">
          <img
            src="/img/illustrations/noPostings.svg"
            className="noDrag"
            alt="No active shifts at the moment"
          />
          <h1 className="margin-bottom-0">
            {getLanguage(699, "No shifts to show")}
          </h1>
          <p className="infoParagraph">
            {getLanguage(
              700,
              "Once you have applied for shifts, they will all show up here in your overview. Apply for as many shifts you want."
            )}
          </p>
          <br />
          <NavLink to="searchpostings">
            <Button className="">{getLanguage(59, "Find shifts")}</Button>
          </NavLink>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <FirstJobConfirmation
          hidden={this.state.firstJob === false}
          confirmFirstJob={this.confirmFirstJob}
          isConfirming={this.state.isConfirmingFirstJob}
        />
        <div className="margin-top-24 margin-bottom-40">
          <Grid
            container
            spacing={1}
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <h1 className="inline-block">
                {this.props.archive
                  ? getLanguage(200, "archive")
                  : getLanguage(45, "My shifts")}
              </h1>
            </Grid>
            <Grid item>
              <Hidden only="xs">
                <NavLink to="/freelancer/searchpostings">
                  <Button className="button-gradient" variant="contained">
                    <span className="btn-icon">
                      <i className="fas fa-search" />
                    </span>
                    {getLanguage(59, "Find shifts")}
                  </Button>
                </NavLink>
              </Hidden>
              <Hidden smUp>
                <NavLink to="/freelancer/archive">
                  <Button className="icon" variant="contained" size="medium">
                    <span>
                      <i className="fas fa-archive"> </i>
                    </span>
                  </Button>
                </NavLink>
              </Hidden>
            </Grid>
          </Grid>
        </div>
        <div className="postingChipWrapper">
          {this.state.chips.map((row, index) => (
            <Chip
              className="postingChip"
              key={index}
              label={row}
              onDelete={() => this.handleDelete(row)}
            />
          ))}
        </div>
        {this.state.pageState === "showPosts" && this.shiftOrArchiveTable()}
        {this.state.pageState === "empty" && this.renderEmptyState()}
        {this.state.pageState === "loading" && (
          <PageLoader releaseNavbar={true} />
        )}
      </div>
    );
  }
}
