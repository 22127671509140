import React from 'react';
import { RouteComponentProps } from 'react-router';
import './Community.scss';
import { Grid } from '@material-ui/core';
import { getLanguage } from '../../../../../Helpers/LanguageHelper';

interface IProps { }

export function CommunityFreelancer(props: IProps) {
    return (
        <div className="communityFreelancerView">
            <Grid
                container
                spacing={5}
                justify="space-between"
                alignItems="stretch"
            >
                <Grid item md={4} xs={12}>
                    <div className="communityCard">
                        <div className="cardImage cardImage_flexibility" />
                        <div className="padding-24">
                            <div className="cardTitle">{getLanguage(751, "Flexibility should not come at a price")}</div>
                            <div className="cardContent">
                                {getLanguage(754, "Most freelancers give up personal growth, leadership and social relationships in exchange for flexibility. To hell with that. You should have it all, and we’re working to give it to you.")}
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item md={4} xs={12}>
                    <div className="communityCard">
                        <div className="cardImage cardImage_communityManager" />
                        <div className="padding-24">
                            <div className="cardTitle">{getLanguage(752, "We are seriously here for you.")}</div>
                            <div className="cardContent">
                                {getLanguage(755, "We’ve got Community Managers dedicated to helping you thrive. Include them in your thoughts and they will help you crush challenges, grow and succeed.")}
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item md={4} xs={12}>
                    <div className="communityCard">
                        <div className="cardImage cardImage_employment" />
                        <div className="padding-24">
                            <div className="cardTitle">{getLanguage(753, "We take care of you. We employ you")}</div>
                            <div className="cardContent">
                                {getLanguage(756, "You’re not just a tax number with us. We’ll employ you to ensure that you feel secure, you get social benefits and your taxes are reported correctly.")}
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}