import { DateFromStringModel } from "../Models/DateFromStringModel";

export function getDateObjectFromDateString(date: string) {
    if (!date) {
        return new DateFromStringModel({
            year: '0000',
            month: '00',
            day: '00',
            time: '00:00'
        });
    }

    let year = date.substring(0, 4)
    let month = date.substring(5, 7)
    let day = date.substring(8, 10)
    let time = date.substring(11, 16)

    let dateFromString: DateFromStringModel = new DateFromStringModel({
        year: year,
        month: month,
        day: day,
        time: time
    })

    return dateFromString;


}


