import { PostingModel } from "./PostingModel";
import { CountryModel } from "./CountryModel";
import { LoginModel } from "./LoginModel";
import { FavoriteProfileModel } from "./FavoriteProfileModel";

export class CompanyModel {
  Id: string;
  LoginId: string;
  Login: LoginModel | null;
  CompanyName: string;
  Vat: string;
  AddressLine1: string;
  AddressLine2: string;
  ZipCode: string;
  City: string;
  Phone: string;
  MobilePhone: string;
  Tagline: string;
  Description: string;
  VideoLink: string;
  WebsiteLink: string;
  FacebookProfile: string;
  GooglePlusProfile: string;
  LinkedInProfile: string;
  TwitterProfile: string;
  LastUpdated: Date;
  StripeCustomerId: string;
  UseContract: boolean;
  ContractAdditionalText: string;
  PresetPaysTransport: number;
  PresetAutoHire: number;
  OverrideDisputeHours: number;
  PaymentFee: number;
  PaymentFeePercent: number;
  UseFactoring: boolean;
  FactoringFeePercent: number;
  InvoiceFee: number;
  AltReceiptEmail: string;
  InvoiceEmail: string;
  PlatformFeePercent: number;
  LastCharge: Date;
  SavedMustBringString: string;
  UseManualPayment: boolean;
  CountryId: string;
  Country: CountryModel | null;
  UnionId: string;
  SalaryPeriodStartDay: number;
  SalaryPaidDayInMonth: number;
  FeeTemplateId: string;
  DateOfLastFeedbackReminderMail: Date;
  PayrollSystemId: string;
  ProfileComplete: Boolean;
  DefaultCoverImageId: string;
  CoverImagePath: string;
  CoverImageStyle: string;
  BillyContactId: string;
  BillyContactPersonId: string;
  SavedPostingDescriptionString: string;
  PresetAutoHireOnCancel: boolean;
  CustomInvoiceDueDays: number;
  InvoiceDisplayReferenceKeyOverview: boolean;
  InvoiceSendEveryday: boolean;
  LastPostWorkNotification: Date;
  CountPostWorkNotifications: number;
  LastManyRequestsForPostingNotification: Date;
  ShouldRecalcNumbers: boolean;
  RatingsAverage: number;
  CountRatings: number;
  CountLateCancels: number;
  CountChabbersHired: number;
  CountPostings: number;
  CountFreelancersNeeded: number;
  CalculatedHireBuffer: number;
  OneTimeFeeRate: number;
  HourlyFeeRate: number;
  UrgentJobPerDayFeeRate: number;
  UserAdminLogExists: boolean;
  FinancingCustomerId: number;
  CreditcardIdentifier: string;
  ForceCreditcard: boolean;
  Ean: string;
  EanOtherReferences: string;
  LastJobNotificationToFavourites: Date;
  LastJobNotificationToFavouritesUTC: string;
  InvoiceSendMonthly: boolean;
  Postings: PostingModel[];
  Requests: Request[];
  CollectiveAgreement: number;
  CollectiveAgreementRules: string;
  SalarySupplementPercentage: number;

  Favorites: FavoriteProfileModel[];
  // MatchmakingAlgorithmDatas: MatchmakingAlgorithmData[];

  IsPrivate: boolean;

  constructor(val: Partial<CompanyModel>) {
    this.Id = val.Id || "";
    this.LoginId = val.LoginId || "";
    this.Login = val.Login || null;
    this.CompanyName = val.CompanyName || "";
    this.Vat = val.Vat || "";
    this.AddressLine1 = val.AddressLine1 || "";
    this.AddressLine2 = val.AddressLine2 || "";
    this.ZipCode = val.ZipCode || "";
    this.City = val.City || "";
    this.Phone = val.Phone || "";
    this.MobilePhone = val.MobilePhone || "";
    this.Tagline = val.Tagline || "";
    this.Description = val.Description || "";
    this.VideoLink = val.VideoLink || "";
    this.WebsiteLink = val.WebsiteLink || "";
    this.FacebookProfile = val.FacebookProfile || "";
    this.GooglePlusProfile = val.GooglePlusProfile || "";
    this.LinkedInProfile = val.LinkedInProfile || "";
    this.TwitterProfile = val.TwitterProfile || "";
    this.LastUpdated = val.LastUpdated || new Date();
    this.StripeCustomerId = val.StripeCustomerId || "";
    this.UseContract = val.UseContract || false;
    this.ContractAdditionalText = val.ContractAdditionalText || "";
    this.PresetPaysTransport = val.PresetPaysTransport || -1;
    this.PresetAutoHire = val.PresetAutoHire || -1;
    this.OverrideDisputeHours = val.OverrideDisputeHours || -1;
    this.PaymentFee = val.PaymentFee || -1;
    this.PaymentFeePercent = val.PaymentFeePercent || -1;
    this.UseFactoring = val.UseFactoring || false;
    this.FactoringFeePercent = val.FactoringFeePercent || -1;
    this.InvoiceFee = val.InvoiceFee || -1;
    this.AltReceiptEmail = val.AltReceiptEmail || "";
    this.InvoiceEmail = val.InvoiceEmail || "";
    this.PlatformFeePercent = val.PlatformFeePercent || -1;
    this.LastCharge = val.LastCharge || new Date();
    this.SavedMustBringString = val.SavedMustBringString || "";
    this.UseManualPayment = val.UseManualPayment || false;
    this.CountryId = val.CountryId || "";
    this.Country = val.Country || null;
    this.UnionId = val.UnionId || "";
    this.SalaryPeriodStartDay = val.SalaryPeriodStartDay || -1;
    this.SalaryPaidDayInMonth = val.SalaryPaidDayInMonth || -1;
    this.FeeTemplateId = val.FeeTemplateId || "";
    this.DateOfLastFeedbackReminderMail = val.DateOfLastFeedbackReminderMail || new Date();
    this.PayrollSystemId = val.PayrollSystemId || "";
    this.ProfileComplete = val.ProfileComplete || false;
    this.DefaultCoverImageId = val.DefaultCoverImageId || "";
    this.CoverImageStyle = val.CoverImageStyle || "";
    this.BillyContactId = val.BillyContactId || "";
    this.BillyContactPersonId = val.BillyContactPersonId || "";
    this.SavedPostingDescriptionString = val.SavedPostingDescriptionString || "";
    this.PresetAutoHireOnCancel = val.PresetAutoHireOnCancel || false;
    this.CustomInvoiceDueDays = val.CustomInvoiceDueDays || -1;
    this.InvoiceDisplayReferenceKeyOverview = val.InvoiceDisplayReferenceKeyOverview || false;
    this.InvoiceSendEveryday = val.InvoiceSendEveryday || false;
    this.LastPostWorkNotification = val.LastPostWorkNotification || new Date();
    this.CountPostWorkNotifications = val.CountPostWorkNotifications || -1;
    this.LastManyRequestsForPostingNotification = val.LastManyRequestsForPostingNotification || new Date();
    this.ShouldRecalcNumbers = val.ShouldRecalcNumbers || false;
    this.RatingsAverage = val.RatingsAverage || -1;
    this.CountRatings = val.CountRatings || -1;
    this.CountLateCancels = val.CountLateCancels || -1;
    this.CountChabbersHired = val.CountChabbersHired || -1;
    this.CountPostings = val.CountPostings || -1;
    this.CountFreelancersNeeded = val.CountFreelancersNeeded || -1;
    this.CalculatedHireBuffer = val.CalculatedHireBuffer || -1;
    this.OneTimeFeeRate = val.OneTimeFeeRate || -1;
    this.HourlyFeeRate = val.HourlyFeeRate || -1;
    this.UrgentJobPerDayFeeRate = val.UrgentJobPerDayFeeRate || -1;
    this.UserAdminLogExists = val.UserAdminLogExists || false;
    this.FinancingCustomerId = val.FinancingCustomerId || -1;
    this.CreditcardIdentifier = val.CreditcardIdentifier || "";
    this.ForceCreditcard = val.ForceCreditcard || false;
    this.Ean = val.Ean || "";
    this.EanOtherReferences = val.EanOtherReferences || "";
    this.LastJobNotificationToFavourites = val.LastJobNotificationToFavourites || new Date();
    this.LastJobNotificationToFavouritesUTC = val.LastJobNotificationToFavouritesUTC || "";
    this.InvoiceSendMonthly = val.InvoiceSendMonthly || false;
    this.Postings = val.Postings || [];
    this.Requests = val.Requests || [];
    this.Favorites = val.Favorites || [];
    this.CoverImagePath = val.CoverImagePath || "";
    // this.MatchmakingAlgorithmDatas = val.MatchmakingAlgorithmDatas || '';
    this.IsPrivate = val.IsPrivate || false;
    this.CollectiveAgreement = val.CollectiveAgreement || 0;
    this.CollectiveAgreementRules = val.CollectiveAgreementRules || "";
    this.SalarySupplementPercentage = val.SalarySupplementPercentage || 0;
  }
}
