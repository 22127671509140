import React, { Component } from "react";
import {
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button
} from "@material-ui/core";
import { SavingSnackbar } from "../../../../../Parts/General/SavingSnackbar";
import { UserHelper } from "../../../../../../Helpers/UserHelper";
import { PageLoader } from "../../../../../Parts/General/PageLoader";
import { setUserData, getCurrentUser } from "../../../../../../Helpers/SessionHelper";
import { getLanguage } from "../../../../../../Helpers/LanguageHelper";
import { LoginModel } from "../../../../../../Models/LoginModel";
import { StorageHelper } from "../../../../../../Helpers/StorageHelper";
import { SnackbarManager } from "../../../../../../Helpers/SnackbarManager/SnackbarManager";

interface IProps {
  creatingUser?: boolean;
  saveStep?: ((stepBasicComplete: boolean) => void);
}

interface IState {
  user: LoginModel;
  LocalBankAccountNumber: string;
  LocalBankRegistrationNumber: string;
  TaxType: string;
  editing: boolean;
  cardActive: 'primary' | 'secondary';
  showPageLoader: boolean;
  LocalBankRegistrationNumberError: boolean;
  LocalBankAccountNumberError: boolean;
  ssn: string;
}

export class FreelancerStepBank extends Component<IProps, IState> {
  userHelper = new UserHelper();

  constructor(props: IProps) {
    super(props);

    this.state = {
      user: new LoginModel({}),
      LocalBankAccountNumber: '',
      LocalBankRegistrationNumber: '',
      TaxType: '',
      editing: false,
      cardActive: 'primary',
      showPageLoader: false,
      LocalBankRegistrationNumberError: false,
      LocalBankAccountNumberError: false,
      ssn: ""
    }

    this.resetForm = this.resetForm.bind(this)
    this.getExistingFreelancer = this.getExistingFreelancer.bind(this)
    this.updateBankInfo = this.updateBankInfo.bind(this)
    this.applyIBANFormatIfNetherlands = this.applyIBANFormatIfNetherlands.bind(this)
    this.regNumberField = this.regNumberField.bind(this)
    this.taxCardField = this.taxCardField.bind(this)
    this.getSsnField = this.getSsnField.bind(this)
  }


  async UNSAFE_componentWillMount() {
    await this.getExistingFreelancer();
  }

  async getExistingFreelancer() {
    let user: LoginModel | null = getCurrentUser();

    if (user == null || !user.Freelancer) {
      return;
    }

    let taxType = '0';
    if (user.Freelancer.TaxType.toString() === '0') {
      await this.setState({ cardActive: 'primary' })
    } else {
      taxType = '1'
      await this.setState({ cardActive: 'secondary' })
    }


    await this.setState({
      user,
      LocalBankAccountNumber: user.Freelancer.LocalBankAccountNumber,
      LocalBankRegistrationNumber: user.Freelancer.LocalBankRegistrationNumber,
      TaxType: taxType
    })
    let ssn = user.Freelancer.SSN ? user.Freelancer.SSN : '';
    this.handleSsn(ssn, false);
  }

  async resetForm() {
    await this.getExistingFreelancer();
  }


  async updateBankInfo() {
    await this.setState({ showPageLoader: true, editing: false })
    let newUser: LoginModel = this.state.user;
    if (!newUser || !newUser.Freelancer) return;



    newUser.Freelancer.LocalBankAccountNumber = this.state.LocalBankAccountNumber;
    newUser.Freelancer.LocalBankRegistrationNumber = this.state.LocalBankRegistrationNumber;
    newUser.Freelancer.TaxType = this.state.cardActive === 'primary' ? 0 : 1
    newUser.Freelancer.BirthDate = newUser.Freelancer.Birthday
    newUser.Freelancer.SSN = this.state.ssn;

    let success;
    let stepBankComplete = await this.userHelper.verifyStepBankComplete(newUser.Freelancer)

    if (typeof stepBankComplete !== 'boolean') {
      let failString = '';
      for (const fail of stepBankComplete) {
        failString = failString.concat(getLanguage(fail, "step bank fail info"))
      }
      switch (stepBankComplete[0]) {
        case 71:
          await this.setState({ LocalBankRegistrationNumberError: true })
          break;
        case 72:
          await this.setState({ LocalBankAccountNumberError: true })
          break;
      }

      SnackbarManager.Instance.addWarning(failString);
      return await this.setState({
        showPageLoader: false
      })

    } else if (stepBankComplete === true) {
      success = await this.userHelper.updateFreelancer(newUser);
    }



    if (success) {
      newUser.vmStepBankDone = true;
      await setUserData(newUser);
      SnackbarManager.Instance.addSuccess(getLanguage(697, 'Changes saved'));
      if (this.props.creatingUser && this.props.saveStep) {
        this.props.saveStep(true)
      }
    } else {
      SnackbarManager.Instance.addWarning(getLanguage(698, 'Changes could not be saved'));
    }
    await this.setState({ showPageLoader: false })
  }

  creatingUser() {
    if (this.props.creatingUser) {
      return (
        <div>
          <div className="text-right margin-v-32">
            <Button className="button-gradient" onClick={() => this.updateBankInfo()}>{getLanguage(794, 'next')}</Button>
          </div>
        </div>
      )
    }
    return (
      <div />
    )
  }

  handleSsn(value: string, markDirty: boolean) {
    if (!this.state.user || !this.state.user.Freelancer) {
      return
    }

    let country = this.state.user.Freelancer.Country
    if (!country) {
      return
    }

    if (!country.SsnFormat || country.SsnFormat.verify.length <= 0) {
      return this.setState({ ssn: value, editing: markDirty });
    }

    if (value.length > this.state.ssn.length && value.length > country.SsnFormat.verify.length) {
      return;
    }


    for (const instruction of country.SsnFormat.format) {
      value = value.replace(new RegExp(instruction.regex), instruction.replace);
    }


    this.setState({ ssn: value, editing: markDirty });
  }

  applyIBANFormatIfNetherlands() {
    let facadeLocal = new StorageHelper().getFacadeLocalization();
    let LocalBankAccountNumber = this.state.LocalBankAccountNumber;

    if (LocalBankAccountNumber && facadeLocal && !facadeLocal.country.UsesLocalAccountNumbers) {
      LocalBankAccountNumber = LocalBankAccountNumber.toUpperCase();
      LocalBankAccountNumber = LocalBankAccountNumber.replace(/ /g, '');
      return this.setState({ LocalBankAccountNumber })
    }
    return this.setState({ LocalBankAccountNumber })
  }


  getLabelTitle(defaultCode: number, propertyInCountry: string) {
    let facadeLocal = new StorageHelper().getFacadeLocalization();
    let langCode = defaultCode;
    if (facadeLocal && facadeLocal.country && facadeLocal.country[propertyInCountry]) {
      langCode = facadeLocal.country[propertyInCountry] as number;
    }
    return getLanguage(langCode, "");
  }

  regNumberField() {
    let facadeLocal = new StorageHelper().getFacadeLocalization();
    if (facadeLocal && facadeLocal.country.BankRegistrationNumberRegex) {
      return (
        <TextField
          type="text"
          label={this.getLabelTitle(85, "BankClearenceNumberLanguageCode")}
          variant="outlined"
          fullWidth
          margin="normal"
          value={this.state.LocalBankRegistrationNumber}
          onChange={(event) => this.setState({ LocalBankRegistrationNumber: event.target.value, editing: true })}
        />
      )
    } else return;
  }


  taxCardField() {
    let facadeLocal = new StorageHelper().getFacadeLocalization();
    if (facadeLocal && facadeLocal.country.MultipleTaxCards) {
      return (
        <div>
          <p>{this.getLabelTitle(87, "TaxCardLanguageCode")}</p>
          <RadioGroup aria-label="position" name="position" value={this.state.cardActive}>
            <FormControlLabel
              value="primary"
              control={<Radio color="primary" />}
              label={this.getLabelTitle(88, "TaxCardOption1LanguageCode")}
              labelPlacement="end"
              onClick={() => this.setState({ cardActive: 'primary', editing: true })}
            />
            <FormControlLabel
              value="secondary"
              control={<Radio color="primary" />}
              label={this.getLabelTitle(89, "TaxCardOption2LanguageCode")}
              labelPlacement="end"
              onClick={() => this.setState({ cardActive: 'secondary', editing: true })}
            />
          </RadioGroup>
        </div>
      )
    }
    else return;
  }

  getSsnField() {
    if (!this.state.user || !this.state.user.Freelancer) {
      return <></>;
    }

    let country = this.state.user.Freelancer.Country
    let langCode = 74;
    if (country && country.SsnLanguageCode) {
      langCode = country.SsnLanguageCode
    }

    return (
      <TextField
        type="text"
        label={getLanguage(langCode, "SSN")}
        variant="outlined"
        fullWidth
        margin="normal"
        value={this.state.ssn}
        onChange={event => this.handleSsn(event.target.value, true)}
      />
    )
  }

  render() {
    let savingSnackBar: any;

    if (this.state.editing === true && !this.props.creatingUser) {
      savingSnackBar = <SavingSnackbar open={this.state.editing} resetClicked={this.resetForm} saveClicked={this.updateBankInfo} />
    }

    let nextButton = this.creatingUser();

    return (
      <div>
        {this.regNumberField()}
        <TextField
          id='bankAccountNumber'
          type="text"
          label={this.getLabelTitle(86, "BankAccountNumberLanguageCode")}
          variant="outlined"
          fullWidth
          margin="normal"
          value={this.state.LocalBankAccountNumber}
          onChange={(event) => this.setState({ LocalBankAccountNumber: event.target.value, editing: true })}
          onBlur={this.applyIBANFormatIfNetherlands}
        />
        {this.taxCardField()}
        {this.getSsnField()}
        {nextButton}
        {savingSnackBar}
        {this.state.showPageLoader ? <PageLoader /> : null}
      </div>
    );
  }
}
