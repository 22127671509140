import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { Grid, Dialog, DialogContent } from "@material-ui/core";
import { YouTubeEmbed } from "../../../../Parts/General/YouTubeEmbed";
import { Footer } from "../../Navigation/Footer";
import { getLanguage } from "../../../../../Helpers/LanguageHelper";
interface IProps { }
interface IState {
  dialogOpen: boolean;
}
export class EventCaseKitchen extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      dialogOpen: false,
    }
  }
  render() {
    return (
      <div>
        <div className="paper">
          <Grid container spacing={0} alignItems="stretch">
            <Grid item sm={6}>
              <div className="videoCase">
                <div className="caseTitle">
                  {getLanguage(461, "DiningSix uses Chabber to maintain a full work schedule")}
                </div>
                <div className="caseContent">
                  <i>
                    {getLanguage(462, "We use Chabber because it is easy when we a person fall sick, or cannot fill our regular work schedule")}
                  </i>
                  <br />
                  <div className="text-right">
                    {getLanguage(463, "- Sigurd, Manager")}
                  </div>
                  <br />
                  {getLanguage(464, "DiningSix deals with with operation of canteens and Catering. It can be difficult to find chefs and kitchen assistants during busy periods or in the case of sudden illness. That's why DiningSix has been using Chabber's platform to hire chefs and kitchen helpers since 2017.")}
                </div>

                <div className="cardCTA" onClick={() => this.setState({ dialogOpen: true })}>
                  {getLanguage(352, "Watch video")}
                  <span>
                    <i className="fas fa-play fa-fw brand" />
                  </span>
                </div>

              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="videoPoster caseThree" onClick={() => this.setState({ dialogOpen: true })}>
                <div className="videoPoster_playIcon">
                  <i className="fas fa-play fa-4x"></i>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Dialog open={this.state.dialogOpen} onClose={() => this.setState({ dialogOpen: false })} fullWidth maxWidth="md">
          <DialogContent>
            <YouTubeEmbed videoID="_5h-E5ehq_8" />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
