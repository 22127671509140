import React from 'react';
import './CourseCard.scss';
import { CMSMetaModel } from '../../../../../Models/CMSMetaModel';
import { CourseModel } from '../../../../../Models/CourseModel';
import { NavLink } from 'react-router-dom';
import { CourseRegistrationModel } from '../../../../../Models/CourseRegistrationModel';
import { getLanguage } from '../../../../../Helpers/LanguageHelper';


interface IProps {
    meta: CMSMetaModel;
    courses: CourseModel[];
    registrations: CourseRegistrationModel[];
}

export function CourseCard(props: IProps) {
    let availableCourses = props.courses.filter(x => x.MaxRegistrations > x.Registrations.length).length;

    let coursesAvailable = <div className="courseCard_dates">{availableCourses} {getLanguage(787, "dates available")}</div>;
    if (props.registrations.findIndex(x => props.courses.findIndex(y => y.Id === x.CourseId) !== -1) !== -1) {
        coursesAvailable = <div className="courseCard_dates registered">{getLanguage(786, "Already registered")}</div>;
    }
    else if (!availableCourses) {
        coursesAvailable = <div className="courseCard_dates noDates">{getLanguage(785, "No available dates at the moment")}</div>;
    }

    let description = props.meta.Description;
    if (description.length > 150) {
        description = description.substring(0, 150) + '...';
    }
    let coverUrl = props.meta?.getPrimaryRevision()?.CoverImageUrl;

    return (
        <NavLink to={'/freelancer/course?metaId=' + props.meta.Id}>
            <div className="courseCardView">
                {/* {course.Title} */}
                <div className="courseCard paper radius-12">
                    <div className="courseCard_coverImage" style={{ backgroundImage: "url(" + coverUrl + ")" }} />
                    <div className="courseCard_content">
                        <h2 className="courseCard_title">{props.meta.Title}</h2>
                        {coursesAvailable}
                        <p className="courseCard_description">{description}</p>
                    </div>
                </div>
            </div>
        </NavLink >
    )
}