import { CourseModel } from "./CourseModel";
import { CourseDateModel } from "./CourseDateModel";
import { CourseRegistrationModel } from "./CourseRegistrationModel";

export class CourseAttendanceModel {
  Id: string;

  CourseRegistrationId: string;
  CourseRegistration: CourseRegistrationModel | null;

  CourseId: string;
  Course: CourseModel | null;

  CourseDateId: string;
  CourseDate: CourseDateModel | null;


  constructor(val: Partial<CourseAttendanceModel>) {
    this.Id = val.Id || '';
    this.CourseRegistrationId = val.CourseRegistrationId || '';
    this.CourseRegistration = val.CourseRegistration || null;
    this.CourseId = val.CourseId || '';
    this.Course = val.Course || null;
    this.CourseDateId = val.CourseDateId || '';
    this.CourseDate = val.CourseDate || null;
  }
}