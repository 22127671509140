import React from "react";
import { Grid } from "@material-ui/core";
import { TimeInterval } from "../../Types";
import { OptimizedTextField } from "../../../../Components/Parts/General/OptimizedTextField";
import { GetOvertimeHours } from "../../Utils";
import { getLanguage } from "../../../../Helpers/LanguageHelper";

type OffsetPredicateFormProps = {
    interval: TimeInterval;
    onChange: (next: TimeInterval) => void;
}

export function OffsetPredicateForm({ interval, onChange }: OffsetPredicateFormProps) {
    const onStartChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) =>
        onChange({
            start: e.target.value,
            end: interval.end
        });
    return <Grid container spacing={3}>
        <Grid item xs={6}>
            <OptimizedTextField
                label={getLanguage(878, 'From hour')}
                type='number'
                margin="dense"
                select
                value={interval.start}
                onChange={onStartChange}>
                {GetOvertimeHours()}
            </OptimizedTextField>
        </Grid>
    </Grid>
}