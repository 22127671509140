import React, { Component } from "react";
import { Grid, Tooltip, Button } from "@material-ui/core";
import { getLanguage } from "../../../../Helpers/LanguageHelper";
import "./ApplicantProfile.scss";
import {
  UserHelper,
  showFullLastNameOfChabber,
} from "../../../../Helpers/UserHelper";
import { CompetenceQuestionModel } from "../../../../Models/CompetenceQuestionModel";
import { RatingStars } from "../../../Parts/General/RatingStars";
import { RequestModel } from "../../../../Models/RequestModel";
import { StorageHelper } from "../../../../Helpers/StorageHelper";
import { CompetenceSelectionsModel } from "../../../../Models/CompetenceSelectionsModel";
import { ProfilePictureViewer } from "../../../Parts/General/ProfilePictureViewer";
import { FavoriteProfileModel } from "../../../../Models/FavoriteProfileModel";
import Distance from "../../../../Common/Distance/Distance";

interface IProps {
  requestWithSelectedAndConfirmedBoolean: {
    Request: RequestModel;
    Selected: boolean;
    Confirmed: boolean;
    PreviouslyHired: boolean;
  };
  skillId: string;
  compQuestions: CompetenceQuestionModel[];
  confirmed: boolean;
  favourites: FavoriteProfileModel[];
  chabberSelected: (event: any, freelancerId: string) => void;
  deleteChabberRequest: (event: any, freelancerId: string) => void;
  beginCancelChabber: (event: any, freelancerId: string) => void;
  distance?: string | null;
}

interface IState {
  cancelProcent: number;
  freelancerCompetences: {
    CompetenceQuestion: CompetenceQuestionModel;
    FreelancerHasCompetence: boolean;
  }[];
  selected: boolean;
}

export class ApplicantProfile extends Component<IProps, IState> {
  userHelper = new UserHelper();
  storageHelper = new StorageHelper();

  constructor(props: IProps) {
    super(props);

    this.state = {
      cancelProcent: 0,
      freelancerCompetences: [],
      selected: false,
    };

    // this.getRatingsAverage = this.getRatingsAverage.bind(this)
    this.getCancellationProcentage = this.getCancellationProcentage.bind(this);
    this.extractSkills = this.extractSkills.bind(this);
    this.bestMatchBadge = this.bestMatchBadge.bind(this);
    this.displayEmailAndPhone = this.displayEmailAndPhone.bind(this);
    this.showNotConfirmedButtons = this.showNotConfirmedButtons.bind(this);
    this.previouslyHiredBadge = this.previouslyHiredBadge.bind(this);
    this.autoHiredAsFavourite = this.autoHiredAsFavourite.bind(this);
    this.nonNativeSpeakerBadge = this.nonNativeSpeakerBadge.bind(this);
    this.chabberName = this.chabberName.bind(this);
  }

  async componentDidMount() {
    await this.getCancellationProcentage();
    await this.extractSkills();
  }

  async getCancellationProcentage() {
    let CancellationPercentage = 0;
    if (
      this.props.requestWithSelectedAndConfirmedBoolean.Request &&
      this.props.requestWithSelectedAndConfirmedBoolean.Request
        .FreelancerProfile
    ) {
      CancellationPercentage =
        this.props.requestWithSelectedAndConfirmedBoolean.Request
          .FreelancerProfile.CancellationPercentage;
    }
    await this.setState({ cancelProcent: CancellationPercentage });
  }

  async extractSkills() {
    let relevantCompQuestions: CompetenceQuestionModel[] = [];

    for (const compQ of this.props.compQuestions) {
      if (compQ.SkillId === this.props.skillId) {
        relevantCompQuestions.push(compQ);
      }
    }
    let freelancer =
      this.props.requestWithSelectedAndConfirmedBoolean.Request
        .FreelancerProfile;
    let freelancersCompetences: CompetenceSelectionsModel[] = [];
    if (freelancer) freelancersCompetences = freelancer.CompetenceSelections;
    let competences = [];

    for (const i of relevantCompQuestions) {
      let comp = {
        CompetenceQuestion: i,
        FreelancerHasCompetence: false,
      };
      for (const u of freelancersCompetences) {
        if (comp.CompetenceQuestion.Id === u.CompetenceQuestionId) {
          comp.FreelancerHasCompetence = true;
        }
      }

      competences.push(comp);
    }

    await this.setState({ freelancerCompetences: competences });
  }

  changeState(event: any) {
    if (this.props.requestWithSelectedAndConfirmedBoolean.Request) {
      if (this.state.selected) {
        this.props.chabberSelected(
          event,
          this.props.requestWithSelectedAndConfirmedBoolean.Request.Id
        );
        this.setState({ selected: false });
      } else {
        this.props.chabberSelected(
          event,
          this.props.requestWithSelectedAndConfirmedBoolean.Request.Id
        );
        this.setState({ selected: true });
      }
    }
  }

  bestMatchBadge() {
    if (
      this.props.requestWithSelectedAndConfirmedBoolean.Request
        .MatchingRating >= 98
    ) {
      return (
        <div className="ap_badge bestMatch">
          {getLanguage(65, "Best match")}
        </div>
      );
    } else return;
  }

  favouriteBadge() {
    let fav = this.props.favourites.find(
      (x) =>
        x.FreelancerProfileId ===
        (this.props.requestWithSelectedAndConfirmedBoolean.Request as any)
          .FreelancerProfileId
    );
    if (fav?.ApprovalStatus === 1) {
      return (
        <span className="ap_favouriteIndicator">
          <i className="far fa-heart" />
        </span>
      );
    }
  }

  previouslyHiredBadge() {
    if (this.props.requestWithSelectedAndConfirmedBoolean.PreviouslyHired) {
      return (
        <div className={"ap_badge previouslyHired"}>
          {getLanguage(66, "Prevously hired")}
        </div>
      );
    }
  }

  autoHiredAsFavourite() {
    if (
      this.props.requestWithSelectedAndConfirmedBoolean.Request
        .AutoHiredAsFavourite
    ) {
      return (
        <div className={"ap_badge favouriteHired"}>
          {getLanguage(826, "Autohired")}
        </div>
      );
    }
  }

  nonNativeSpeakerBadge() {
    let countryId =
      this.props.requestWithSelectedAndConfirmedBoolean.Request
        .FreelancerProfile!.CountryId;
    let knownLanguages =
      this.props.requestWithSelectedAndConfirmedBoolean.Request
        .FreelancerProfile!.KnownLanguages;

    let facadeLocalization = this.storageHelper.getFacadeLocalization();
    if (facadeLocalization == null) return;
    let nativeLanguageId = "";

    for (const i of facadeLocalization.languagesAvailableByCountry) {
      if (i.CountryId === countryId) {
        nativeLanguageId = i.LanguageId;
      }
    }

    if (nativeLanguageId !== "") {
      for (const u of knownLanguages) {
        if (u.Id === nativeLanguageId) {
          return;
        }
      }
      return (
        <div className="ap_badge nonNativeSpeaker">
          {getLanguage(67, "English-speaker")}
        </div>
      );
    }
  }

  displayEmailAndPhone() {
    if (
      this.props.requestWithSelectedAndConfirmedBoolean.Request &&
      this.props.requestWithSelectedAndConfirmedBoolean.Request
        .FreelancerProfile &&
      this.props.requestWithSelectedAndConfirmedBoolean.Request
        .FreelancerProfile.Login &&
      this.props.requestWithSelectedAndConfirmedBoolean.Confirmed
    ) {
      return (
        <div>
          <div>
            <i className="fas fa-phone fa-fw" />
            {
              this.props.requestWithSelectedAndConfirmedBoolean.Request
                .FreelancerProfile.MobilePhone
            }
          </div>
          <div>
            <i className="fas fa-at fa-fw" />
            {
              this.props.requestWithSelectedAndConfirmedBoolean.Request
                .FreelancerProfile.Login.Email
            }
          </div>
        </div>
      );
    }
    return;
  }

  showNotConfirmedButtons() {
    let requestWithSelectedBoolean =
      this.props.requestWithSelectedAndConfirmedBoolean;
    let freelancerId =
      this.props.requestWithSelectedAndConfirmedBoolean.Request!
        .FreelancerProfile!.Id;
    if (
      requestWithSelectedBoolean &&
      freelancerId &&
      !requestWithSelectedBoolean.Confirmed
    ) {
      return (
        <div className="inputGroup placeholder">
          <Tooltip title={getLanguage(63, "Reject")} placement="left">
            <Button
              className="icon inputGroup_item"
              variant="text"
              color="inherit"
              onClick={(event: any) =>
                this.props.deleteChabberRequest(event, freelancerId)
              }
            >
              <span>
                <i className="fas fa-trash-alt"> </i>
              </span>
            </Button>
          </Tooltip>

          <Button
            className={requestWithSelectedBoolean.Selected ? "success" : ""}
            variant="outlined"
            onClick={(event) => this.props.chabberSelected(event, freelancerId)}
          >
            {getLanguage(64, "Select")}
          </Button>
        </div>
      );
    } else
      return (
        <div>
          <Button
            className=""
            variant="outlined"
            onClick={(event: any) =>
              this.props.beginCancelChabber(event, freelancerId)
            }
          >
            {getLanguage(721, "Cancel")}
          </Button>
        </div>
      );
  }

  chabberName() {
    return showFullLastNameOfChabber(
      this.props.requestWithSelectedAndConfirmedBoolean!.Request!
        .FreelancerProfile!,
      this.props.confirmed
    );
  }

  outputSkills() {
    if (this.state.freelancerCompetences?.length === 0) {
      return <></>;
    }

    return (
      <>
        <h2>{getLanguage(62, "Skills")}</h2>
        {this.state.freelancerCompetences.map((row, index) => (
          <div
            className={
              row.FreelancerHasCompetence ? "hasCompetence" : "hasNotCompetence"
            }
            key={index}
          >
            {getLanguage(
              row.CompetenceQuestion.LanguageStringCode,
              "Competence"
            )}
          </div>
        ))}
      </>
    );
  }

  render() {
    let cancelMinutes = Number(
      this.props.requestWithSelectedAndConfirmedBoolean.Request
        .FreelancerProfile?.AverageMinutesCancellelingBefore
    );

    let cancelTooltip;
    if (Number.isNaN(cancelMinutes) || cancelMinutes === 0) {
      cancelTooltip = "";
    } else if (cancelMinutes > 1440) {
      // use days
      let cancelDays = Number((cancelMinutes / 60 / 24).toFixed(1)).toString();
      cancelTooltip = getLanguage(
        805,
        "On average cancels {{cancelDays}} days before"
      ).replace("{{cancelDays}}", cancelDays);
    } else {
      // use hours
      let cancelHours = (cancelMinutes / 60).toFixed(0);
      cancelTooltip = getLanguage(
        806,
        "On average cancels {{cancelDays}} days before"
      ).replace("{{cancelDays}}", cancelHours);
      // 806
    }

    return (
      <div className="applicantProfileWrapper">
        <div className="applicantProfile padding-24 radius-6">
          <div className="applicantProfile_top">
            <Grid
              container
              spacing={2}
              justify="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={4}>
                <div className="relative">
                  <ProfilePictureViewer
                    login={
                      this.props.requestWithSelectedAndConfirmedBoolean.Request!
                        .FreelancerProfile!.Login
                    }
                    freelancer={
                      this.props.requestWithSelectedAndConfirmedBoolean.Request!
                        .FreelancerProfile
                    }
                    customClass="ap_image"
                    altText=""
                    company={null}
                  ></ProfilePictureViewer>
                </div>
              </Grid>
              <Grid item xs={8}>
                <div className="ap_name">
                  {this.chabberName()}
                  {this.favouriteBadge()}
                </div>
                <Tooltip title={cancelTooltip} placement="top">
                  <div>
                    {
                      this.props.requestWithSelectedAndConfirmedBoolean.Request
                        ?.FreelancerProfile!.CountHired
                    }{" "}
                    {getLanguage(60, "Jobs taken")} | {this.state.cancelProcent}
                    % {getLanguage(61, "Cancels")}
                  </div>
                </Tooltip>
                <div className="ap_rating">
                  {this.props.distance && (
                    <Distance distance={this.props.distance} />
                  )}
                  <RatingStars
                    freelancer={
                      this.props.requestWithSelectedAndConfirmedBoolean.Request!
                        .FreelancerProfile
                    }
                    company={null}
                  />
                </div>
                {this.displayEmailAndPhone()}
                <div className="badgeSection">
                  {this.bestMatchBadge()}
                  {this.previouslyHiredBadge()}
                  {this.autoHiredAsFavourite()}
                  {this.nonNativeSpeakerBadge()}
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="applicantProfile_content">
            {this.outputSkills()}
            {/* <h2>Experience</h2> */}
          </div>
          <div className="applicantProfile_bottom">
            <div className="text-right">{this.showNotConfirmedButtons()}</div>
          </div>
        </div>
      </div>
    );
  }
}
