// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { FreelancerStepBasic } from "../../Freelancer/FreelancerSettings/Parts/FreelancerStepBasic";
import { getLanguage } from "../../../../../Helpers/LanguageHelper";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Grid,
  Hidden
} from "@material-ui/core";
import {
  getCurrentUser,
  logUserOut
} from "../../../../../Helpers/SessionHelper";
import { PageLoader } from "../../../../Parts/General/PageLoader";
import { UserHelper } from "../../../../../Helpers/UserHelper";
import { NavLink } from "react-router-dom";
import { SnackbarManager } from "../../../../../Helpers/SnackbarManager/SnackbarManager";
interface IProps extends RouteComponentProps { }
interface IState {
  deletionOpen: boolean;
  deletionEmail: string;
  deletionPassword: string;
  isDeleting: boolean;
}

export class FreelancerSettingsBasic extends Component<IProps, IState> {
  userHelper = new UserHelper();
  constructor(props: IProps) {
    super(props);
    this.state = {
      deletionOpen: false,
      deletionEmail: "",
      deletionPassword: "",
      isDeleting: false,
    };
    this.deleteAccount = this.deleteAccount.bind(this);
    this.getDeleteDialogue = this.getDeleteDialogue.bind(this);
  }

  async deleteAccount() {
    let user = getCurrentUser();
    if (!user || this.state.deletionEmail !== user.Email) {
      SnackbarManager.Instance.addError(getLanguage(715, 'could not delete account'))
      return null;
    }
    await this.setState({ isDeleting: true, deletionOpen: false });

    let result = await this.userHelper.deleteUser(
      this.state.deletionEmail,
      this.state.deletionPassword
    );
    if (result === true) {
      SnackbarManager.Instance.addSuccess(getLanguage(714, 'Account deleted successfully'));
      this.setState({
        isDeleting: false,
        deletionOpen: false
      });
      setTimeout(() => {
        this.gotoFront();
      }, 1000);
    } else {
      SnackbarManager.Instance.addError(getLanguage(715, 'could not delete account, seek support'));
    }
  }

  gotoFront() {
    logUserOut();
    this.props.history.push("/");
  }

  getDeleteDialogue() {
    return (
      <Dialog
        disableBackdropClick
        open={this.state.deletionOpen}
        onClose={() => this.setState({ deletionOpen: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"> {getLanguage(813, "Delete your account?")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {getLanguage(814, "Are you sure you want to delete your account? This action can not be reversed and you will lose all access to your account. Please enter your credentials to approve account deletion.")}
          </DialogContentText>
          <Grid container spacing={3}>
            <Grid item sm={6}>
              <TextField
                type="text"
                fullWidth
                value={this.state.deletionEmail}
                onChange={(e: any) =>
                  this.setState({ deletionEmail: e.target.value })
                }
                label={getLanguage(73, "Email")}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                fullWidth
                value={this.state.deletionPassword}
                onChange={(e: any) =>
                  this.setState({ deletionPassword: e.target.value })
                }
                label={getLanguage(170, "Password")}
                type="password"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="dialogActions">
          <Button onClick={() => this.setState({ deletionOpen: false })}>
            {getLanguage(242, "Cancel")}
          </Button>
          <Button className="danger" onClick={this.deleteAccount} autoFocus>
            {getLanguage(812, "Delete account")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    let deletionMenu = this.getDeleteDialogue();
    let pageLoader;
    if (this.state.isDeleting) {
      pageLoader = <PageLoader />;
    }

    return (
      <div>
        {pageLoader}
        {deletionMenu}
        <div>
          <div className="padding-v-32 maxW-600">
            <Hidden smUp>
              <NavLink to="/freelancer/settings">
                <i className="fas fa-arrow-left fa-lg margin-right-16"></i>
              </NavLink>
            </Hidden>
            <h1 className="inline-block">{getLanguage(55, "Personal data")}</h1>
            <FreelancerStepBasic creatingUser={false} />
            <div className="deleteOption">
              <Button onClick={() => this.setState({ deletionOpen: true })}>
                {getLanguage(812, "Delete account")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
