import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Grid, Button } from '@material-ui/core';
import { getLanguage } from "../../../../../Helpers/LanguageHelper";
import './FeaturePreview.scss';
interface IProps {}
interface IState {}
export class FeaturePreview extends Component<IProps, IState> {
constructor(props: IProps) {
super(props);
}
render(){
return(<div> 
     <div className="container">
              <div className="sectionHeading">{getLanguage(369,"Wanna know how it works?")}</div>
              <Grid container spacing={5} justify="space-evenly">
                <Grid item lg={4}>
                  <div className="featureItem">
                    <div className="featureTitle">{getLanguage(371,"Book 24 hours a day")}</div>
                    <div className="featureContent">
                    {getLanguage(372,"You always have access to your profile through the web and the app. That's why you can post jobs whenever you want and receive applications within minutes.")}
                    </div>
                  </div>
                </Grid>
                <Grid item lg={4}>
                  <div className="featureItem">
                    <div className="featureTitle">{getLanguage(373,"Pick your own freelancers")}</div>
                    <div className="featureContent">
                    {getLanguage(374,"Pick your freelancers based on their profiles which include ratings from their previous employers.")}
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={5} justify="space-evenly">
                <Grid item lg={4}>
                  <div className="featureItem">
                    <div className="featureTitle">{getLanguage(375,"Book favourites again")}</div>
                    <div className="featureContent">
                    {getLanguage(376,"Where you happy with a freelancers work? Then add them to your list of favourites. Now they'll get notified each time you post a job and you can even choose them to be hired automatically.")}
                    </div>
                  </div>
                </Grid>
                <Grid item lg={4}>
                  <div className="featureItem">
                    <div className="featureTitle">{getLanguage(377,"Add multiple users")}</div>
                    <div className="featureContent">
                   {getLanguage(378,"When you add multiple users to your profile your whole team can collaborate and post jobs on behalf of the organisation. Each person can manager their own events or work together with others.")}
                    </div>
                  </div>
                </Grid>
              </Grid>
              {/* <div className="text-center margin-top-40">
                <Button href="/features">{getLanguage(379,"See the full list of features")}</Button>
              </div> */}
            </div>
</div>)
}
}