import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Table, TableHead, TableRow, TableCell, TableBody, Menu, MenuItem, Divider, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from "@material-ui/core";
import { CourseModel } from '../../../../Models/CourseModel';
import { CourseRegistrationModel } from '../../../../Models/CourseRegistrationModel';
import { SnackbarManager } from '../../../../Helpers/SnackbarManager/SnackbarManager';
import { SavingSnackbar } from '../../../Parts/General/SavingSnackbar';
import { CourseHelper } from '../../../../Helpers/CourseHelper';

interface IProps extends RouteComponentProps {
  course: CourseModel | null;
  updateCourseData: (course: CourseModel) => void;
  reset: () => void;
}

export function SignupsTab(props: IProps) {
  let courseHelper = new CourseHelper();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [activeRegistrant, setActiveRegistrant] = useState<CourseRegistrationModel | null>(null)
  const [menuOpen, setMenuOpen] = useState<'editHours' | 'deleteSignup' | null>(null);
  const [editHoursValue, setEditHoursValue] = useState(0);
  const [isDirty, setIsDirty] = useState(false);
  const [markedAsDeletedArr, setMarkedAsDeletedArr] = useState<string[]>([]);

  function resetForm() {
    setMarkedAsDeletedArr([]);
    props.reset();
    setIsDirty(false);
  }

  function renderChabbers() {
    if (!props.course) return;
    return props.course.Registrations.map(registrant => {
      if (!registrant.Freelancer || !registrant.Freelancer.Login || !props.course) return null;

      let isDeleted = markedAsDeletedArr.findIndex(x => x === registrant.Id) !== -1;
      let classNames = 'tableRow';
      if (isDeleted) {
        classNames += ' deleted';
      }
      // TODO: Sean please style this based on whether they are deleted or not
      return <TableRow key={registrant.Id} className={classNames}>
        <TableCell className="tableCell">{registrant.Freelancer.Login.Firstnames + ' ' + registrant.Freelancer.Login.Lastname}</TableCell>
        <TableCell className="tableCell">{registrant.Freelancer.MobilePhone} </TableCell>
        <TableCell className="tableCell">{registrant.Freelancer.Login.Email} </TableCell>
        <TableCell className="tableCell" align="right">
          {
            registrant.Graduated ?
              <div className="badgeChip success">Graduated</div>
              :
              <div />
          }
        </TableCell>
        <TableCell className="tableCell" align="right">
          {registrant.WorkingMinutesPaidOff / 60}/{props.course.WorkingMinutesToTakeAsPayment / 60} {" "}
          <span className="padding-8">
            {
              registrant.WorkingMinutesPaidOff >= props.course.WorkingMinutesToTakeAsPayment ?
                <i className="fas fa-check success"></i>
                :
                <i className="fas fa-circle-notch fa-spin warning" />

            }
          </span>
        </TableCell>
        {isDeleted ?
          <TableCell />
          :
          <TableCell
            className="tableCell tableOptions"
            align="right"
            aria-owns={anchorEl ? "export-menu" : undefined}
            aria-haspopup="true"
            onClick={(e: React.MouseEvent) => {
              setAnchorEl(e.currentTarget);
              setActiveRegistrant(registrant);
            }}
          >
            <i className="fas fa-ellipsis-v" />
          </TableCell>
        }
      </TableRow>
    }
    );
  }

  function acceptEditHours() {
    let val = Number(editHoursValue);
    if (Number.isNaN(val) || val < 0 || val > props.course!.WorkingMinutesToTakeAsPayment / 60) {
      SnackbarManager.Instance.addError(
        'value is not a valid number (needs to be between 0 and ' + props.course!.WorkingMinutesToTakeAsPayment / 60 + ')');
      return;
    }
    if (!activeRegistrant || !props.course) return;
    let course = new CourseModel(props.course);
    let registrations = course.Registrations.map(x => x);
    let registrant = registrations.find(x => x.Id === activeRegistrant.Id);

    if (!registrant) return;

    registrant.WorkingMinutesPaidOff = editHoursValue * 60;
    course.Registrations = registrations;

    props.updateCourseData(course);

    setActiveRegistrant(null);
    setMenuOpen(null);
    setIsDirty(true);
  }

  async function saveData() {
    //Grab the course object and the array of deleted id's and send them to the server
    let course = props.course;
    let deletedArr = markedAsDeletedArr;

    let deletionResult;
    let changedHoursResult;

    if (Array.isArray(deletedArr) && deletedArr.length > 0) {
      deletionResult = await courseHelper.deleteCourseRegistrants(deletedArr);
    }

    if (course && course.Registrations) {
      changedHoursResult = await courseHelper.updateRegistrantHours(course.Registrations);
    }

    if (deletionResult !== undefined && deletionResult === false) {
      SnackbarManager.Instance.addError('Could not delete selected profiles');
      setIsDirty(false);
      return;
    }
    if (changedHoursResult !== undefined && changedHoursResult === false) {
      SnackbarManager.Instance.addError('Could not save changed hours');
      setIsDirty(false);
      return;
    }

    SnackbarManager.Instance.addSuccess('Changes saved');
    setIsDirty(false);
  }


  function renderEditHoursMenu() {
    return (
      <Dialog
        open={menuOpen === 'editHours'}
        onClose={() => {
          setAnchorEl(null);
          setActiveRegistrant(null);
          setMenuOpen(null);
        }}
      >
        <DialogTitle>Edit hours</DialogTitle>
        <DialogContent>
          <TextField
            label='Edit hours'
            type='text'
            variant="outlined"
            margin="dense"
            value={editHoursValue}
            onChange={e => {
              let val = Number(e.target.value);
              if (Number.isNaN(val)) return;
              setEditHoursValue(val);
            }}
            error={props.course != null ? (editHoursValue > props.course.WorkingMinutesToTakeAsPayment / 60 || editHoursValue < 0) : false}
          />
          /{props.course && props.course.WorkingMinutesToTakeAsPayment / 60}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setAnchorEl(null);
            setActiveRegistrant(null);
            setMenuOpen(null);
          }}>cancel</Button>
          <Button onClick={() => acceptEditHours()} >
            Ok
            </Button>
        </DialogActions>
      </Dialog>
    )
  }

  function renderDeleteMenu() {
    let name = '';
    if (activeRegistrant != null && activeRegistrant.Freelancer != null && activeRegistrant.Freelancer.Login != null) {
      name = activeRegistrant.Freelancer.Login.Firstnames + ' ' + activeRegistrant.Freelancer.Login.Lastname;
    }
    return (
      <Dialog
        open={menuOpen === 'deleteSignup'}
        onClose={() => {
          setActiveRegistrant(null);
          setMenuOpen(null);
        }}
      >
        <DialogTitle>Delete signup</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this signup ( {name} )?
      </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setActiveRegistrant(null);
            setMenuOpen(null);
          }}>cancel</Button>
          <Button onClick={() => {
            if (!activeRegistrant) return;

            let markedArr = markedAsDeletedArr.map(x => x);
            if (markedArr.findIndex(x => x === activeRegistrant.Id) !== -1) return;
            markedArr.push(activeRegistrant.Id);
            setMarkedAsDeletedArr(markedArr);

            setActiveRegistrant(null);
            setMenuOpen(null);
            setIsDirty(true);
          }}>Ok</Button>
        </DialogActions>
      </Dialog>)
  }

  return (
    <>
      <div className="adminTable">
        <Table className="table">
          <TableHead className="tableHead">
            <TableRow className="tableRow">
              <TableCell>Name</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Mail</TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right">Worked off</TableCell>
              <TableCell className="tableOptions" align="right" />
            </TableRow>
          </TableHead>
          <TableBody className="tableBody">
            {renderChabbers()}
          </TableBody>
        </Table>
      </div>

      {renderEditHoursMenu()}
      {renderDeleteMenu()}


      <Menu
        id="export-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
          setActiveRegistrant(null);
        }}
        className="dropdownMenu"
      >
        <div className="menuTitle">Edit signee</div>
        <MenuItem onClick={() => {
          setAnchorEl(null);
          setMenuOpen('editHours');
          setEditHoursValue(activeRegistrant != null ? activeRegistrant.WorkingMinutesPaidOff / 60 : -1);
        }} className="menuItem">
          {" "}
          <i className="far fa-edit" />
          Edit hours worked
              </MenuItem>
        <Divider />
        <MenuItem onClick={() => {
          setAnchorEl(null);
          setMenuOpen('deleteSignup');
        }} className="menuItem">
          {" "}
          <i className="far fa-trash-alt" />
          Delete signup
              </MenuItem>
      </Menu>

      <SavingSnackbar open={isDirty} saveClicked={() => saveData()} resetClicked={() => { resetForm() }} />
    </>
  )
}