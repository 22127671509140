import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import {
    Menu, MenuItem, Divider,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, CircularProgress, TextField
} from "@material-ui/core";
import { getLanguage, LanguageHelper } from "../../../../Helpers/LanguageHelper";
import { PostingModel } from "../../../../Models/PostingModel";
import { StorageHelper } from "../../../../Helpers/StorageHelper";
import { PostingFull } from "../Freelancer/PostingFull";
import { PostingHelper } from "../../../../Helpers/PostingHelper";
import { SnackbarManager } from "../../../../Helpers/SnackbarManager/SnackbarManager";
import { getPriceForProduct, getMoneyOutput } from "../../../../Helpers/LocalizationHelper";
import { ReopenPostingDialog } from "../../../Parts/Posting/ReopenPostingDialog";
import { getDateObjectFromDateString } from "../../../../Helpers/StringExtractorHelper";

interface IProps extends RouteComponentProps {
    comingFrom: 'postings' | 'posting';
    anchorEl: HTMLElement | null;
    activeMenuPosting: PostingModel | null;
    jobHasEnded: boolean;
    postings?: PostingModel[];
    handleClose: () => void;
    preparePage?: (getNewPosting?: boolean) => void;
    postingsUpdated?: () => void;
    postingCancelled?: (postingCancelled: boolean) => void;
    reloadPosting?: (hiringChabbers: boolean) => void;
}

interface IState {
    buttonsDisabled: boolean;
    wholeDayCSV: boolean;
    openPreviewDialog: boolean;
    closePosting: boolean;
    deletePostingDialog: boolean;
    CSV: string;
    displayReopenPostingDialog: boolean
}


export class PostingOptions extends Component<IProps, IState> {
    storageHelper = new StorageHelper();
    postingHelper = new PostingHelper();
    languageHelper = new LanguageHelper();


    constructor(props: IProps) {
        super(props);

        this.state = {
            buttonsDisabled: false,
            wholeDayCSV: false,
            openPreviewDialog: false,
            closePosting: false,
            deletePostingDialog: false,
            CSV: '',
            displayReopenPostingDialog: false
        }
        this.handleClose = this.handleClose.bind(this)
        this.closePosting = this.closePosting.bind(this)
        this.downloadSingleCSV = this.downloadSingleCSV.bind(this)
        this.downloadWholeDayCSV = this.downloadWholeDayCSV.bind(this)
        this.deletePosting = this.deletePosting.bind(this)
        this.getCSV = this.getCSV.bind(this)
        this.closeReopenPostingDialog = this.closeReopenPostingDialog.bind(this)

    }

    componentDidMount() {
    }

    async closePosting() {
        await this.setState({ buttonsDisabled: true });
        let postingToClose = this.props.activeMenuPosting;

        let success = await this.postingHelper.deleteOrClosePosting(
            postingToClose!.Id,
            false
        );

        if (success) {
            SnackbarManager.Instance.addSuccess(getLanguage(337, 'Posting could not be closed'));

            await this.setState({
                buttonsDisabled: false,
                closePosting: false
            });
            this.props.handleClose();
            if (this.props.preparePage) {
                await this.props.preparePage(true);
            }
        } else {
            SnackbarManager.Instance.addError(getLanguage(338, 'Posting could not be closed at this time'));

            await this.setState({
                buttonsDisabled: false,
                closePosting: false
            });
            this.props.handleClose();
        }
    }

    async downloadSingleCSV(posting: PostingModel) {
        if (posting != null) {
            let jobCSV = await this.postingHelper.getJobCSV(posting.Id)
            if (jobCSV) {
                await this.setState({
                    CSV: jobCSV,
                    wholeDayCSV: false,
                })
                SnackbarManager.Instance.addSuccess(getLanguage(341, 'Downloading CSV'));
                this.handleClose();
            }
        }
    }



    async downloadWholeDayCSV(posting: PostingModel) {
        this.handleClose();
        if (posting) {
            let dateInRightFormat: string = posting.StartAtLocalObject.year + "-" + posting.StartAtLocalObject.month + "-" + posting.StartAtLocalObject.day;
            let wholeDayCSV = await this.postingHelper.getWholeDayCSV(dateInRightFormat);
            await this.setState({
                CSV: wholeDayCSV,
            })
            SnackbarManager.Instance.addSuccess(getLanguage(341, 'Downloading CSV'));
        } return;
    }


    async handleClose() {
        await this.setState({
            // anchorEl: null,
            openPreviewDialog: false,
            CSV: '',
            wholeDayCSV: false,
            displayReopenPostingDialog: false
        });

        this.props.handleClose();
    }


    async deletePosting() {
        await this.setState({ buttonsDisabled: true });
        let postingToDelete = this.props.activeMenuPosting;

        let success = await this.postingHelper.deleteOrClosePosting(
            postingToDelete!.Id,
            true
        );

        if (success) {
            await this.setState({
                buttonsDisabled: false,
                deletePostingDialog: false
            });
            this.props.handleClose();
            if (this.props.comingFrom === 'postings' && this.props.postingsUpdated)
                this.props.postingsUpdated()
            else if (this.props.comingFrom === 'posting' && this.props.postingCancelled)
                this.props.postingCancelled(true)
        } else {
            await this.setState({
                buttonsDisabled: false,
                deletePostingDialog: false
            });
            this.props.handleClose();
            if (this.props.comingFrom === 'postings' && this.props.postingsUpdated)
                this.props.postingsUpdated()
            else if (this.props.comingFrom === 'posting' && this.props.postingCancelled)
                this.props.postingCancelled(false)
        }
    }


    async getCSV() {
        let posting: PostingModel | null = this.props.activeMenuPosting;
        if (posting) {

            posting.StartAtLocalObject = getDateObjectFromDateString(posting.StartAtLocal)
            posting.EndAtLocalObject = getDateObjectFromDateString(posting.EndAtLocal)

            let postDate = posting.StartAtLocalObject.year + "-" + posting.StartAtLocalObject.month + "-" + posting.StartAtLocalObject.day;

            let postingsOnSameDay: PostingModel[] = [];
            let postings = this.props.postings

            if (postings != null) {
                for (let i = 0; i < postings.length; i++) {
                    let dayFormattet = postings[i].StartAtLocalObject.year + "-" + postings[i].StartAtLocalObject.month +
                        "-" + postings[i].StartAtLocalObject.day;

                    if (postings[i] !== posting && postDate == dayFormattet) {
                        postingsOnSameDay.push(postings[i])
                    }
                }
            }
            if (postingsOnSameDay.length >= 1) {
                await this.setState({ wholeDayCSV: true })
                return
            }
            this.downloadSingleCSV(posting);
        }
    }

    async closeReopenPostingDialog() {
        await this.setState({ displayReopenPostingDialog: false })
    }

    render() {

        let postingFull = null;

        if (this.props.activeMenuPosting != null) {
            postingFull = <PostingFull
                {...this.props}
                visiting={true}
                posting={this.props.activeMenuPosting}
                viewState='company'
            />
        }


        if (this.state.CSV && this.state.CSV !== '') {
            const url = window.URL.createObjectURL(new Blob([this.state.CSV]));
            const link = document.createElement('a');
            link.href = url;
            let todaysDate = new Date();

            let finalFilname = "Chabber_" + todaysDate + ".csv";

            link.setAttribute('download', finalFilname);
            document.body.appendChild(link);
            link.click();
            this.setState({ CSV: '' })
        }

        return (
            <div>
                {/* TABLE MENU */}
                <Menu
                    id="table-menu"
                    anchorEl={this.props.anchorEl}
                    open={this.props.anchorEl ? true : false}
                    onClose={this.handleClose}
                    disableAutoFocusItem={true}
                    getContentAnchorEl={null}
                    className="dropdownMenu"
                    classes={{ paper: "dropdownMenu_content" }}
                    anchorOrigin={{
                        vertical: "center",
                        horizontal: "center"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                >
                    <div className="menuTitle">{getLanguage(196, "Job options")}</div>
                    <MenuItem
                        className="menuItem"
                        disabled={
                            this.props.jobHasEnded ? true : false
                        }
                        onClick={() => {
                            this.props.history.push('/company/editposting?id=' + this.props.activeMenuPosting!.Id);
                        }}
                    >
                        <i className="far fa-edit" />
                        {getLanguage(190, "Edit shift")}
                    </MenuItem>
                    <MenuItem
                        className="menuItem"
                        onClick={() => {
                            this.props.history.push('/company/editposting?clone=' + this.props.activeMenuPosting?.Id);
                        }}
                    >
                        <i className="far fa-copy" />
                        {getLanguage(835, 'New job based on this')}
                    </MenuItem>
                    <MenuItem
                        disabled={
                            !this.props.activeMenuPosting || this.props.activeMenuPosting &&
                                !this.props.activeMenuPosting.Deactivated || this.props.jobHasEnded
                                ? true
                                : false
                        }
                        className="menuItem"
                        onClick={() => this.setState({ displayReopenPostingDialog: true })}
                    >
                        <i className="fas fa-arrow-up" />
                        {getLanguage(191, "Re-open")}
                    </MenuItem>
                    <MenuItem className="menuItem" onClick={this.getCSV}>
                        <i className="fas fa-file-csv" />
                        {getLanguage(192, "Download csv")}
                    </MenuItem>
                    <MenuItem
                        disabled={
                            this.props.activeMenuPosting &&
                                this.props.activeMenuPosting.Deactivated || this.props.jobHasEnded
                                ? true
                                : false
                        }
                        className="menuItem"
                        onClick={() => this.setState({ closePosting: true })}
                    >
                        <i className="far fa-hand-paper" />
                        {getLanguage(193, "Close now")}
                    </MenuItem>
                    <MenuItem className="menuItem" onClick={() => this.setState({ openPreviewDialog: true })}>
                        <i className="fas fa-eye" />
                        {getLanguage(194, "Preview")}
                    </MenuItem>
                    <Divider />
                    <MenuItem
                        disabled={
                            this.props.jobHasEnded ? true : false
                        }
                        className="menuItem"
                        onClick={() => this.setState({ deletePostingDialog: true })}
                    >
                        <i className="far fa-trash-alt" />
                        {getLanguage(195, "Delete")}
                    </MenuItem>
                </Menu>

                {/* delete posting */}
                <Dialog
                    className="cancelDialog dialog"
                    open={this.state.deletePostingDialog}
                    aria-labelledby="delete-dialog"
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle id="form-dialog-title">{getLanguage(222, "Delete jobs posting?")}</DialogTitle>
                    <DialogContent>
                        {this.props.activeMenuPosting &&
                            this.props.activeMenuPosting.CancelInsuranceSelected ? (
                            <DialogContentText>
                                {getLanguage(223, "You have chosen a cancellation insurance and you will not be charged for deleting the shift.")}
                            </DialogContentText>
                        ) : (
                            <DialogContentText>
                                {getLanguage(224, "Cancelling a shift will result in a payment of {{amount}} per hired freelancer.").replace("{{amount}}", getMoneyOutput(getPriceForProduct("Cancel_Freelancer"), false, this.props.activeMenuPosting ? this.props.activeMenuPosting!.Currency : null))}
                            </DialogContentText>
                        )}
                    </DialogContent>
                    <DialogActions className="dialogActions">
                        <Button
                            disabled={this.state.buttonsDisabled}
                            onClick={() =>
                                this.setState({
                                    deletePostingDialog: false,
                                    buttonsDisabled: false
                                })
                            }
                        >
                            {getLanguage(218, "Back")}
                        </Button>
                        <Button
                            className="danger"
                            disabled={this.state.buttonsDisabled}
                            onClick={() => this.deletePosting()}
                        >
                            {getLanguage(221, "Yes, cancel job")}
                            {this.state.buttonsDisabled && (
                                <CircularProgress size={20} className="buttonLoader" />
                            )}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* close posting */}
                <Dialog
                    className="cancelDialog"
                    open={this.state.closePosting}
                    aria-labelledby="cancel-dialog"
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle id="form-dialog-title"> {getLanguage(226, "Close job posting?")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {getLanguage(227, "This will reject all remaining applicant and you'll not receive any new applications. Confirmed freelancers will not be cancelled.")}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="dialogActions">
                        <Button
                            disabled={this.state.buttonsDisabled}
                            onClick={() =>
                                this.setState({ closePosting: false, buttonsDisabled: false })
                            }
                        >
                            {getLanguage(218, "Back")}
                        </Button>
                        <Button
                            disabled={this.state.buttonsDisabled}
                            onClick={() => this.closePosting()}
                        >
                            {getLanguage(225, "Yes, close posting")}
                            {this.state.buttonsDisabled && (
                                <CircularProgress size={20} className="buttonLoader" />
                            )}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* re-open posting */}
                <ReopenPostingDialog

                    closeReopenPostingDialog={this.closeReopenPostingDialog}
                    posting={this.props.activeMenuPosting}
                    preparePage={this.props.preparePage}
                    reloadPosting={this.props.reloadPosting}
                    visible={this.state.displayReopenPostingDialog}
                    {...this.props}
                    handleClose={this.handleClose}
                    comingFrom={this.props.comingFrom}

                ></ReopenPostingDialog>

                {/* preview dialog */}
                <Dialog
                    className="cancelDialog"
                    maxWidth="md"
                    fullWidth
                    open={this.state.openPreviewDialog}
                    onClose={this.handleClose}
                    scroll="body"

                >

                    {postingFull ? postingFull : <div></div>}

                </Dialog>

                {/* download multiple CSV dialog */}
                <Dialog
                    className="dialog"
                    maxWidth="sm"
                    fullWidth
                    open={this.state.wholeDayCSV}
                    onClose={this.handleClose}

                >
                    <DialogTitle id="form-dialog-title">
                        {getLanguage(192, "Download csv")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {getLanguage(231, "You have multiple jobs on this day. Do you wish to download an updated list of all shifts on this day?")}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="dialogActions">
                        <Button
                            onClick={() => this.downloadSingleCSV(this.props.activeMenuPosting!)}
                        >
                            {getLanguage(232, "Only for this job")}
                        </Button>

                        <Button className="success"
                            onClick={() => this.downloadWholeDayCSV(this.props.activeMenuPosting!)}
                        >
                            {getLanguage(233, "Download all")}
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        );
    }
}
