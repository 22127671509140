import React from "react";
import { Button } from "@material-ui/core";
import "./PredefinedTemplates.scss";

export const PredefinedTemplates = ({
  onClick,
}: {
  onClick: (selected: string) => void;
}) => {
  const templates = [
    "You need to write your national ID-number (CPR- number) in your profile. Go to “settings” -> “payment details”. Then upload your ID document again. Thanks",
    "We need to see a picture of your work permit in front of a google search with today’s date. Make a google search with today’s date, hold your card next to the screen and take a picture. It’s important both the permit and date are visible. Thank you",
    "Kindly upload the document again all 4 corners need to be visible. You can adjust the size when it’s uploaded by pulling the corners.",
    "Change your nationality to your home country. Then you're able to upload all documents necessary.",
    "Vil du uploade billedet igen, hvor alle 4 hjørner af kortet er synlige? Du kan justere beskæringen når du uploader.",
    "Du skal tilføje dit CPR-nr. i din profil, det gør du under ”lønoplysninger”, derefter upload dokumentet igen.",
    "Vi skal se et gyldigt kørekort eller pas.",
    "Billedet er lidt utydeligt, venligst upload igen.",
  ];
  return (
    <div className="predefined-templates">
      {templates.map((template) => (
        <Button
          className="predefined-templates__btn"
          size="small"
          variant="contained"
          onClick={() => onClick(template)}
        >
          {template}
        </Button>
      ))}
    </div>
  );
};
