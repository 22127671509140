import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import {
    Menu, MenuItem, Divider,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, CircularProgress, TextField
} from "@material-ui/core";
import { PostingModel } from "../../../Models/PostingModel";
import { PostingHelper } from "../../../Helpers/PostingHelper";
import { getLanguage } from "../../../Helpers/LanguageHelper";
import { SnackbarManager } from "../../../Helpers/SnackbarManager/SnackbarManager";

interface IProps extends RouteComponentProps {
    visible: boolean
    closeReopenPostingDialog: (addedToNeededAmount: number) => void
    handleClose: () => void
    preparePage?: (getNewPosting?: boolean) => void;
    reloadPosting?: (hiringChabbers: boolean) => void;
    comingFrom: string
    posting: PostingModel | null
}
interface IState {
    reopenNumberNeeded: number
    buttonsDisabled: boolean
}

export class ReopenPostingDialog extends Component<IProps, IState> {
    postingHelper = new PostingHelper();
    constructor(props: IProps) {
        super(props);

        this.state = {
            reopenNumberNeeded: 1,
            buttonsDisabled: false
        }
        this.reopenJobPosting = this.reopenJobPosting.bind(this)
    }

    async reopenJobPosting() {
        if (this.state.reopenNumberNeeded < 1) {
            SnackbarManager.Instance.addError(getLanguage(745, 'Value must be at least 1'));
            return
        }

        await this.setState({ buttonsDisabled: true });

        let postingToReopen: PostingModel | null = this.props.posting;

        let amountMoreNeeded = this.state.reopenNumberNeeded

        let success = await this.postingHelper.reopenPosting(
            postingToReopen!.Id,
            amountMoreNeeded + postingToReopen!.AmountHired
        );

        if (success) {
            await this.setState({
                reopenNumberNeeded: 0,
                buttonsDisabled: false
            });
            SnackbarManager.Instance.addSuccess(getLanguage(339, 'Posting re-opened'));
            await this.props.closeReopenPostingDialog(amountMoreNeeded);
            await this.props.handleClose();
            if (this.props.comingFrom == 'postings' && this.props.preparePage)
                await this.props.preparePage();
            if (this.props.comingFrom == 'posting' && this.props.reloadPosting)
                await this.props.reloadPosting(false);

        } else {
            SnackbarManager.Instance.addSuccess(getLanguage(340, 'Posting could not be re-opened'));
            await this.setState({
                reopenNumberNeeded: 0,
                buttonsDisabled: false
            });
            this.props.handleClose();
        }
    }


    render() {
        return <Dialog
            className="dialog"
            maxWidth="sm"
            fullWidth
            open={this.props.visible}>
            <DialogTitle id="form-dialog-title">
                {getLanguage(228, "Re-open job?")}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {getLanguage(229, "Reopening your job will make it visible for freelancer and you'll be able to receive new applications. Please tell us, how many freelancers you need below.")}
                </DialogContentText>
                <TextField
                    className="inputGroup_item"
                    type="number"
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    style={{ margin: 0 }}
                    value={this.state.reopenNumberNeeded}
                    onChange={event =>
                        this.setState({ reopenNumberNeeded: +event.target.value })
                    }
                />
            </DialogContent>
            <DialogActions className="dialogActions">
                <Button
                    disabled={this.state.buttonsDisabled}
                    onClick={() => {
                        this.setState({
                            buttonsDisabled: false
                        })
                        this.props.closeReopenPostingDialog(0)
                    }
                    }>
                    {getLanguage(218, "Back")}
                </Button>
                <Button
                    disabled={this.state.buttonsDisabled}
                    onClick={() => this.reopenJobPosting()}
                    variant="contained"
                >
                    {getLanguage(230, "Re-open")}
                    {this.state.buttonsDisabled && (
                        <CircularProgress size={20} className="buttonLoader" />
                    )}
                </Button>
            </DialogActions>
        </Dialog >
    }
}