// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from "react";
import {
  Button,
  Grid,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  InputAdornment,
  Checkbox,
  Hidden
} from "@material-ui/core";
import favourite from "../../../../assets/img/illustrations/favourite.svg";
import "./Favourites.scss";
import { CompanyHelper } from "../../../../Helpers/CompanyHelper";
import { FavoriteProfileModel } from "../../../../Models/FavoriteProfileModel";
import { FreelancerModel } from "../../../../Models/FreelancerModel";
import { UserHelper } from "../../../../Helpers/UserHelper";
import { getLanguage } from "../../../../Helpers/LanguageHelper";
import { MobileSidebarTrigger } from "../../../Parts/General/MobileSidebarTrigger";
import { SnackbarManager } from "../../../../Helpers/SnackbarManager/SnackbarManager";
import { ProfilePictureViewer } from "../../../Parts/General/ProfilePictureViewer";

interface IProps { }

interface IState {
  open: boolean;
  favorites: favoriteProfileModelExtended[];
  name: string;
  previouslyHired: FreelancerModel[];
  searchPreviouslyHired: FreelancerModel[];
  picked: FreelancerModel[];
  searchQuery: string;
  areThereFavorites: boolean | string;
}

interface favoriteProfileModelExtended extends FavoriteProfileModel {
  pending: string;
}

export class Favourites extends Component<IProps, IState> {
  companyHelper = new CompanyHelper();
  userHelper = new UserHelper();

  constructor(props: IProps) {
    super(props);

    this.state = {
      open: false,
      favorites: [],
      name: "",
      previouslyHired: [],
      picked: [],
      searchQuery: "",
      searchPreviouslyHired: [],
      areThereFavorites: ""
    };

    this.preparePage = this.preparePage.bind(this);
    this.getRatingsAverage = this.getRatingsAverage.bind(this);
    this.addFavorites = this.addFavorites.bind(this);
    this.changePickedState = this.changePickedState.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.sendInvites = this.sendInvites.bind(this);
    this.searchChanged = this.searchChanged.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.sortFavorites = this.sortFavorites.bind(this);
    this.whichFormToShow = this.whichFormToShow.bind(this);
  }

  async componentDidMount() {
    await this.preparePage();
  }

  async preparePage() {
    let favorites: favoriteProfileModelExtended[] = await this.userHelper.getFavorites() as favoriteProfileModelExtended[]; //FIXME: this is hacky
    if (favorites.length === 0) {
      return await this.setState({ areThereFavorites: false });
    } else {
      await this.setState({ areThereFavorites: true });
    }

    await this.sortFavorites(favorites);
  }

  async sortFavorites(favorites: favoriteProfileModelExtended[]) {
    favorites.sort(function (a, b) {
      if (
        a.Freelancer &&
        a.Freelancer.Login &&
        b.Freelancer &&
        b.Freelancer.Login
      ) {
        if (a.Freelancer.Login.Firstnames < b.Freelancer.Login.Firstnames) {
          return -1;
        }
        if (a.Freelancer.Login.Firstnames > b.Freelancer.Login.Firstnames) {
          return 1;
        }
        return 0;
      }
      return 0;
    });

    if (favorites) {
      for (const fav of favorites) {
        if (fav.ApprovalStatus === 0) fav.pending = "favouritePending";
        else fav.pending = "hidden";
      }
      await this.setState({ favorites });
    }
  }

  async addFavorites() {
    let previouslyHired: FreelancerModel[] = await this.companyHelper.searchPreviouslyHired(
      ""
    );
    let favorites = this.state.favorites;

    if (favorites.length !== 0) {
      for (let u = 0; u < favorites.length; u++) {
        for (let i = 0; i < previouslyHired.length; i++) {
          if (previouslyHired[i] && favorites[u]) {
            if (previouslyHired[i].Id === favorites[u].FreelancerProfileId)
              previouslyHired.splice(i, 1);
          }
        }
      }
    }

    if (previouslyHired.length !== 0) {
      previouslyHired.sort(function (a, b) {
        if (a.Login && b.Login) {
          if (a.Login.Firstnames < b.Login.Firstnames) {
            return -1;
          }
          if (a.Login.Firstnames > b.Login.Firstnames) {
            return 1;
          }
          return 0;
        }
        return 0;
      });
    }

    await this.setState({
      open: true,
      previouslyHired,
      searchPreviouslyHired: previouslyHired
    });
  }

  async handleClose() {
    await this.setState({ open: false, picked: [] });
  }

  async handleDelete(row: favoriteProfileModelExtended) {
    let success = await this.companyHelper.deleteFavorite(row);
    let favorites = this.state.favorites;

    if (success) {
      let index = favorites.indexOf(row);
      favorites.splice(index, 1);
      if (favorites.length === 0) {
        await this.setState({ areThereFavorites: false });
      }
      SnackbarManager.Instance.addSuccess(getLanguage(307, "Favourite removed"));
      await this.setState({
        favorites,
      });
    } else {
      SnackbarManager.Instance.addError(getLanguage(308, "Favourite could not be removed"))
    }
  }

  getRatingsAverage(row: favoriteProfileModelExtended) {
    let stars: JSX.Element[] = [];
    let ratingValue = 0;
    if (row.Freelancer)
      ratingValue =
        ((row.Freelancer.RatingsAverage - 0) * (5 - 0)) / (100 - 0) + 0; // converts the range from 0-100 to 0-5

    for (let index = 0; index < Math.floor(ratingValue); index++) {
      stars.push(<i key={index} className="fas fa-star" />);
    }
    if (ratingValue - stars.length > 0.2 && ratingValue - stars.length < 0.8) {
      stars.push(<i key="7" className="fas fa-star-half-alt" />);
    }

    for (let index = stars.length; index < 5; index++) {
      stars.push(<i key={"a" + index} className="far fa-star" />);
    }

    return <div className="freelancerProfile_rating">{stars}</div>;
  }

  async changePickedState(row: FreelancerModel) {
    let picked = this.state.picked;
    let exists = picked.indexOf(row);
    if (exists !== -1) {
      picked.splice(exists, 1);
    } else {
      picked.push(row);
    }
    await this.setState({ picked });
  }

  async sendInvites() {
    let picked = this.state.picked;
    let pickedIds = [];

    for (const i of picked) {
      let pick = {
        FreelancerProfileId: i.Id
      };
      pickedIds.push(pick);
    }

    let success = await this.companyHelper.sendFavoriteInvites(pickedIds);

    if (success) {
      let existingFavorites: favoriteProfileModelExtended[] = this.state
        .favorites;
      for (const i of success) {
        existingFavorites.push(i);
      }
      await this.sortFavorites(existingFavorites);
      SnackbarManager.Instance.addSuccess(getLanguage(309, "Invite sent"));
      await this.setState({
        favorites: existingFavorites,
        areThereFavorites: true,
        open: false,
        picked: []
      });
    } else {
      SnackbarManager.Instance.addError(getLanguage(310, "Invite failed!"))
    }
  }

  async searchChanged(event: any) {
    let query = event.target.value.toLocaleLowerCase();

    let sortedRows = this.sortRows(this.state.searchPreviouslyHired, query);

    if (query === "") sortedRows = this.state.previouslyHired;

    await this.setState({
      searchPreviouslyHired: sortedRows,
      searchQuery: query
    });
  }

  sortRows(rows: any[], query: string) {
    let sortedRows: any[] = [];
    for (let index = 0; index < rows.length; index++) {
      const element = rows[index];
      if (element.Login.Firstnames.toLocaleLowerCase().includes(query)) {
        sortedRows.push(element);
      }
    }
    sortedRows = sortedRows.sort((a, b) => (a.Name < b.Name ? 1 : -1));

    return sortedRows;
  }

  whichFormToShow() {
    if (typeof this.state.areThereFavorites === "string") {
      return <div />;
    } else if (
      typeof this.state.areThereFavorites === "boolean" &&
      !this.state.areThereFavorites
    ) {
      return (
        <div className="emptyState_wrapper">
          <div className="emptyState_content">
            <img
              className="noDrag"
              src={favourite}
              alt="Co-operate with collegues"
            />
            <h1 className="margin-bottom-0">{getLanguage(303, "Start your own favourite list.")}</h1>
            <p className="infoParagraph">
              {getLanguage(304, "If you've had a particularly good experience...")}
              <i className="fas fa-heart favouriteHeart" /> {getLanguage(305, "on their profile...")}
            </p>
            <br />
            <Button
              className="button-gradient"
              variant="contained"
              size="medium"
              onClick={this.addFavorites}
            >
              <span className="btn-icon">
                <i className="fas fa-user-friends" />
              </span>
              {getLanguage(302, "Add favourite")}
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="">
          <div className="margin-v-24">
            <Grid
              container
              spacing={1}
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <Hidden smUp>
                  <MobileSidebarTrigger />
                </Hidden>
                <h1 className="inline-block">{getLanguage(95, "Favourites")}</h1>
              </Grid>
              <Grid item>
                <Button
                  className="button-gradient"
                  variant="contained"
                  size="medium"
                  onClick={this.addFavorites}
                >
                  <span className="btn-icon">
                    <i className="fas fa-user-plus" />
                  </span>
                  {getLanguage(302, "Add favourite")}
                </Button>
              </Grid>
            </Grid>
          </div>
          <Grid
            container
            spacing={4}
            direction="row"
            justify="flex-start"
            alignItems="stretch"
          >
            {this.state.favorites.map((row, index) => (
              <Grid item md={4} sm={6} xs={12} key={row.Id}>
                <div className="paper radius-6">
                  <div className="favouriteItem relative padding-16">
                    <Grid
                      container
                      item
                      justify="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <ProfilePictureViewer
                          login={row.Freelancer!.Login}
                          company={null}
                          freelancer={row.Freelancer}
                          customClass="favouriteImg"
                          altText="favourite"
                        ></ProfilePictureViewer>
                      </Grid>
                      <Grid item>
                        <div className="favouriteName">
                          {row.Freelancer && row.Freelancer.Login
                            ? row.Freelancer.Login.Firstnames
                            : ""}
                        </div>
                        <div className="favouriteRating">
                          {this.getRatingsAverage(row)}
                        </div>
                        <div className={row.pending}>{getLanguage(306, "Pending")}</div>
                      </Grid>
                    </Grid>
                    <Tooltip title={getLanguage(299, "remove from list")}>
                      <div
                        className="editUser"
                        onClick={() => this.handleDelete(row)}
                      >
                        <i className="fas fa-times" />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="favouriteView">
        {this.whichFormToShow()}
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          id="favouriteDialog"
          className="dialog"
        >
          <DialogTitle id="form-dialog-title">
            {getLanguage(302, "Add favourite")}
            <DialogContentText>
              {getLanguage(311, "Invite previously hired chabbers to join your favourites. They will get notified every time you post a new job and you can also choose to autohire them.")}
            </DialogContentText>
            <TextField
              className="favouriteSearch"
              type="text"
              fullWidth
              variant="outlined"
              margin="dense"
              placeholder="Search"
              onChange={event => this.searchChanged(event)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className="inputAdornment">
                    <i className="fas fa-search" />
                  </InputAdornment>
                )
              }}
            />
          </DialogTitle>
          <DialogContent>
            <div className="allFreelancersList">
              <div className="freelancerToInvite">
                {this.state.searchPreviouslyHired.map((row, index) => (
                  <div className="freelancerToInvite_row" key={row.Id}>
                    <Grid
                      container
                      justify="space-between"
                      alignItems="stretch"
                      spacing={2}
                      onClick={() => this.changePickedState(row)}
                    >
                      <Grid item>
                        <ProfilePictureViewer
                          login={row!.Login}
                          company={null}
                          freelancer={row}
                          customClass="freelancerToInvite_img"
                          altText="freelancer profile"
                        ></ProfilePictureViewer>
                        <h2>{row.Login ? row.Login.Firstnames : ""}</h2>
                      </Grid>
                      <Grid item>
                        <Checkbox
                          color="primary"
                          checked={this.state.picked.findIndex(x => x.Id === row.Id) !== -1}
                        />
                      </Grid>
                    </Grid>
                  </div>
                ))}
              </div>
            </div>
          </DialogContent>
          <DialogActions className="dialogActions">
            <Button onClick={this.handleClose}>{getLanguage(218, "Back")}</Button>
            <Button
              variant="contained"
              className="success"
              disabled={this.state.picked.length > 0 ? false : true}
              onClick={this.sendInvites}
              color="primary"
            >
              {getLanguage(312, "Send invite")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
