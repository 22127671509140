// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Grid, Hidden } from "@material-ui/core";
import { CompanyStepBasic } from "./Parts/CompanyStepBasic";
import { getLanguage } from "../../../../../Helpers/LanguageHelper";
import { UserHelper } from "../../../../../Helpers/UserHelper";
import { logUserOut, getCurrentUser } from "../../../../../Helpers/SessionHelper";
import { PageLoader } from "../../../../Parts/General/PageLoader";
import { RouteComponentProps } from "react-router";
import { MobileSidebarTrigger } from "../../../../Parts/General/MobileSidebarTrigger";
import { SnackbarManager } from "../../../../../Helpers/SnackbarManager/SnackbarManager";

interface IProps extends RouteComponentProps {
}

interface IState {
  deletionOpen: boolean;
  deletionEmail: string;
  deletionPassword: string;
  isDeleting: boolean;
}
export class CompanySettingsBasic extends Component<IProps, IState> {
  userHelper = new UserHelper();
  constructor(props: IProps) {
    super(props);
    this.state = {
      deletionOpen: false,
      deletionEmail: '',
      deletionPassword: '',
      isDeleting: false,
    }
    this.deleteAccount = this.deleteAccount.bind(this);
    this.getDeleteDialogue = this.getDeleteDialogue.bind(this);
  }

  async deleteAccount() {
    let user = getCurrentUser();
    if (!user || this.state.deletionEmail !== user.Email) {
      SnackbarManager.Instance.addError(getLanguage(713, 'Could not delete your account'));
      return null;
    }
    await this.setState({ isDeleting: true, deletionOpen: false });

    let result = await this.userHelper.deleteUser(this.state.deletionEmail, this.state.deletionPassword);
    if (result === true) {
      SnackbarManager.Instance.addSuccess(getLanguage(714, 'Account deleted successfully'));
      this.setState({ isDeleting: false, deletionOpen: false });
      this.gotoFront();
    } else {
      SnackbarManager.Instance.addError(getLanguage(715, 'Could not delete your account, try again'))
    }
  }

  gotoFront() {
    logUserOut();
    this.props.history.push('/');
  }

  getDeleteDialogue() {
    return (
      <Dialog
        disableBackdropClick
        open={this.state.deletionOpen}
        onClose={() => this.setState({ deletionOpen: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{getLanguage(813, "Delete your account?")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {getLanguage(814, "Are you sure you want to delete your account? This action can not be reversed and you will lose all access to your account. Please enter your credentials to approve account deletion.")}
          </DialogContentText>
          <Grid container spacing={3}>
            <Grid item sm={6}>
              <TextField
                fullWidth
                type="text"
                value={this.state.deletionEmail}
                onChange={(e: any) => this.setState({ deletionEmail: e.target.value })}
                label={getLanguage(73, "Email")}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                fullWidth
                value={this.state.deletionPassword}
                onChange={(e: any) => this.setState({ deletionPassword: e.target.value })}
                label={getLanguage(170, "Password")}
                type="password"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="dialogActions">
          <Button onClick={() => this.setState({ deletionOpen: false })} >
            {getLanguage(242, "Cancel")}
          </Button>
          <Button className="danger" onClick={this.deleteAccount} autoFocus>
            {getLanguage(812, "Delete account")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }





  render() {
    let deletionMenu = this.getDeleteDialogue();
    let pageLoader;
    if (this.state.isDeleting) {
      pageLoader = <PageLoader />;
    }

    let pageTitle = getLanguage(97, "Company data")
    let user = getCurrentUser()
    if (user && user.Company && user.Company.IsPrivate) {
      pageTitle = getLanguage(749, "Your personal data")
    }

    return (
      <div>
        {pageLoader}
        {deletionMenu}
        <div className="padding-v-32 maxW-600">
          <Hidden smUp>
            <MobileSidebarTrigger />
          </Hidden>
          <h1 className="inline-block">{pageTitle}</h1>
          <CompanyStepBasic />
          <div className="deleteOption">
            <Button onClick={() => this.setState({ deletionOpen: true })}>
              Delete
              </Button>
          </div>
        </div>
      </div>
    );
  }
}
