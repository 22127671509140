// @ts-nocheck
/**
 * SwipeableDrawer issues
 */
import { SwipeableDrawer, Grid, Button } from "@material-ui/core";
import DatePicker from "react-datepicker";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { getLanguage } from "../../../../Helpers/LanguageHelper";
import "../../../Parts/General/Calendar.scss";

interface IProps {
  onChange: (next: { start?: Date; end?: Date }) => void;
}

export const PostingsFilters = ({ onChange }: IProps) => {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [dateRange, setDateRange] = useState<{ start?: Date; end?: Date }>();

  useEffect(() => {
    onChange(dateRange);
  }, [dateRange]);

  return (
    <>
      <Button
        className="inputGroup_item"
        aria-owns={"simple-menu"}
        aria-haspopup="true"
        onClick={() => setFiltersOpen(true)}
      >
        <span className="btn-icon">
          <i className="fas fa-sort-amount-down" />
        </span>
        {getLanguage(210, "Filter")}
      </Button>

      <SwipeableDrawer
        anchor="right"
        open={filtersOpen}
        onOpen={() => setFiltersOpen(true)}
        onClose={() => setFiltersOpen(false)}
        className="filterDrawerWrapper"
        variant="temporary"
        calendarClassName="Chabber_Calendar editPostingCalendar"
        withPortal
      >
        <div className="padding-32">
          <div className="margin-bottom-32">
            <Grid container justify="space-between">
              <Grid item>
                <h1 className="margin-0">{getLanguage(189, "Filter jobs")}</h1>
              </Grid>
              <Grid item>
                <Button
                  variant="text"
                  size="small"
                  onClick={() => {
                    setDateRange({});
                    setFiltersOpen(false);
                  }}
                >
                  <span className="btn-icon">
                    <i className="fas fa-times" />
                  </span>
                  {getLanguage(288, "Clear filter")}
                </Button>
              </Grid>
            </Grid>
          </div>
          <div className="filterSection">
            <DatePicker
              onChange={([start, end]) => {
                setDateRange({
                  start,
                  end,
                });
                if (!!start === !!end) {
                  //both set/unset
                  setFiltersOpen(false);
                }
              }}
              selected={dateRange?.start}
              startDate={dateRange?.start}
              endDate={dateRange?.end}
              inline
              selectsRange
              calendarClassName="Chabber_Calendar filterCalendar"
            />
          </div>
        </div>
      </SwipeableDrawer>
    </>
  );
};
