import { registerLocale } from "react-datepicker";
import { enGB, da, nl, sv, nb } from 'date-fns/locale';
import { getCurrentUser } from '../Helpers/SessionHelper';
import { StorageHelper } from "../Helpers/StorageHelper";

export class CalendarLanguages {
  storageHelper = new StorageHelper();

  private static instance: CalendarLanguages;
  public static get Instance(): CalendarLanguages {
    if (this.instance == null) this.instance = new CalendarLanguages();
    return this.instance;
  }

  constructor() {
    this.registerCalendarLanguage()
  }

  getCalendarLanguage() {
    let user = getCurrentUser();
    let localization = this.storageHelper.getFacadeLocalization();
    if (user == null || localization == null) return 'en';
    let knownLanguage = localization.knownLanguages.find(x => user != null && x.Id === user.PrimaryLanguage);
    if (knownLanguage == null) return 'en';
    
    let langStr = knownLanguage.Iso6391Code.toString();
    
    if (!langStr || langStr === 'en') {
      langStr = 'en';
    }
    this.registerCalendarLanguage(langStr);
    
    return langStr;
  }

  private registerCalendarLanguage(isocode?: string ) {
    if (isocode == undefined) {
      isocode = this.getCalendarLanguage();
    }
    
    switch (isocode) {
      case 'en':
        registerLocale('en', enGB);
        break;
      case 'da':
        registerLocale('da', da);
        break;
      case 'nl':
        registerLocale('nl', nl);
        break;
      case 'sv':
        registerLocale('sv', sv);
        break;
      case 'no':
        registerLocale('no', nb);
        break;
      case 'nb':
        registerLocale('nb', nb);
        break;
    }
  }
}