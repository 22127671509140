import { AxiosError, AxiosRequestConfig } from 'axios';
import { AdminUserModel as AdminUser, AdminUserModel } from '../Models/AdminUserModel';
import { StorageHelper } from './StorageHelper';

import { IState as AdminUserState } from '../Models/States/AdminUser';
import { CallManager } from './CallManager/CallManager';

export class AdminUserHelper {
  storageHelper = new StorageHelper();
  async getAll() {
    try {

      let config: AxiosRequestConfig = {
        method: 'GET',
        url: '/api/adminuser/getall',
        withCredentials: true
      }
      let response = await CallManager.Instance.makeCall(config, false, true);
      let adminUsers: AdminUserModel[] = response.data.map((x: AdminUserModel) => new AdminUserModel(x));
      this.storageHelper.setAdminUsers(JSON.stringify(adminUsers))
      return adminUsers;
    } catch (error: any) {
      return false;
    }
  }

  async getAdminUser(id: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'get',
        url: '/api/adminuser/get?id=' + id,
        withCredentials: true
      };
      let response = await CallManager.Instance.makeCall(config, false, true);
      let User: AdminUser = new AdminUser(response.data);
      return User;
    } catch (error: any) {
      return false;
    }
  }

  async updateAdminUser(state: AdminUserState) {

    let user = new AdminUserModel(state.user);
    let team = state.teams.find(x => x.Id === state.user.TeamId);
    user.Team = team || null;

    try {
      let config: AxiosRequestConfig = {
        method: 'post',
        url: '/api/adminuser/update',
        withCredentials: true,
        data: { Id: state.id, User: user }
      };

      await CallManager.Instance.makeCall(config, false, true);
      return true;
    } catch (error: any) {
      return false;
    }
  }

  async createNewAdminUser(state: AdminUserState) {
    let user = new AdminUserModel(state.user);
    let team = state.teams.find(x => x.Id === state.user.TeamId);
    user.Team = team || null;

    try {
      let config: AxiosRequestConfig = {
        method: 'post',
        url: '/api/adminuser/create',
        withCredentials: true,
        data: {
          User: user
        }
      };

      await CallManager.Instance.makeCall(config, false, true);
      return true;
    } catch (error: any) {
      return false;
    }
  }

  async saveAdminProfileImage(img: string, id: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'post',
        url: '/api/adminuser/setprofileimage',
        withCredentials: true,
        data: {
          img,
          id
        },
      };

      let response = await CallManager.Instance.makeCall(config, false, true);

      return response.status === 200 ? true : false;
    } catch (error: any) {
      return false;
    }

  }

  async deleteAdminProfileImage(id: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'post',
        url: '/api/adminuser/deleteprofileimage',
        withCredentials: true,
        data: {
          id
        },
      };

      let response = await CallManager.Instance.makeCall(config, false, true);

      return response.status === 200 ? true : false;
    } catch (error: any) {
      return false;
    }
  }

  async deleteAdminUser(id: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'DELETE',
        url: '/api/adminuser/delete/' + id,
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true
      }
      let result = await CallManager.Instance.makeCall(config, false, true);
      return result;
    } catch (error: any) {
      return false;
    }

  }

  async saveSettings(settings: any) {
    try {
      let config: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/adminuser/savesettings',
        data: settings
      }

      let result = await CallManager.Instance.makeCall(config, false, true);
      return result;
    } catch (error: any) {
      return false;
    }
  }

  async verifyLoggedIn() {
    try {
      let config: AxiosRequestConfig = {
        method: 'GET',
        url: '/api/adminuser/verifyloggedin',
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true
      }
      let data = await CallManager.Instance.makeCall(config, false, true);
      return data.data as string;
    } catch (error: any) {
      return false
    }
  }


  async LogUserIn(email: string, password: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/adminuser/login',
        data: {
          Email: email,
          Password: password
        }, headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true
      }
      let response = await CallManager.Instance.makeCall(config, false, true);
      return response.data as { message: string, sessionCode: string, token: string, user: AdminUserModel };
    } catch (error: any) {
      console.log('error', error);
      if (error.response && error.response.status === 403) {
        this.storageHelper.removeAdminToken();
      }
      return null;
    }
  }

  async resetPassword(email: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/adminuser/resetpassword',
        data: {
          Email: email
        }
      }
      let response = await CallManager.Instance.makeCall(config, false, true);
      return response.data;
    } catch (error: any) {
      return false;
    }
  }

  async verifySessionCode(sessionCode: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/adminuser/verifysessioncode',
        params: {
          code: sessionCode
        }
      }
      let response = await CallManager.Instance.makeCall(config, false, true);
      return response.data as { token: string, user: AdminUserModel } | null;
    } catch (error: any) {
      console.log(error);
      return null;
    }
  }

  async getForPublic() {
    try {
      let config: AxiosRequestConfig = {
        method: 'GET',
        url: '/api/adminuser/forpublic',
      }
      let response = await CallManager.Instance.makeCall(config, false);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return false;

    }
  }

  async getTeams() {
    try {
      let config: AxiosRequestConfig = {
        method: 'GET',
        url: '/api/adminuser/teams',
        withCredentials: true
      }
      let response = await CallManager.Instance.makeCall(config, false, true);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }



  // TODO: The following requires no explanation, it's stupid and must be moved!
  async getPendingVerifications(status: number, page: number, pageSize: number, searchPhrase: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'GET',
        url: `/api/pendingverificationdocuments?status=${status}&page=${page}&pageSize=${pageSize}&searchPhrase=${searchPhrase}`,
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true
      }


      let response = await CallManager.Instance.makeCall(config, false, true);

      return response.data;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }


  async getFreelancersVerificationDocuments(loginId: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'GET',
        url: '/api/freelancersverificationdocuments?loginid=' + loginId,
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true
      }


      let response = await CallManager.Instance.makeCall(config, false, true);

      return response.data;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }


  async getSpecificDocument(fileName: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'GET',
        url: '/api/getspecificdocument?filename=' + fileName,
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true
      }

      let response = await CallManager.Instance.makeCall(config, false, true);

      return response.data;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }



  async approveOrRejectDocument(verificationDocumentId: string, adminUserId: string, status: number, workPermitEndDate: string | null, rejectionReason: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/approveorrejectdocument',
        data: {
          verificationDocumentId,
          workPermitEndDate,
          adminUserId,
          status,
          rejectionReason
        }
      }
      let response = await CallManager.Instance.makeCall(config, false, true);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }


  async adminlogOut() {
    try {
      let config: AxiosRequestConfig = {
        method: 'GET',
        url: '/api/adminuser/logout',
        withCredentials: true,
      }
      let response = await CallManager.Instance.makeCall(config, false, true);

      return response.data;
    } catch (error: any) {
      console.log('error', error.response);
      return false;
    }
  }



}
