import { ClearanceModel } from "./ClearanceModel";
import { AdminTeam } from "./AdminTeam";

export class AdminUserModel {
  Id: string;
  Email: string;
  Name: string;
  Phone: string;
  CountryId: string;
  TeamId: string;
  Team: AdminTeam | null;
  Title: string;
  ProfileImageUrl: string;
  ProfileImageId: string;
  ClearanceLevels: ClearanceModel[];
  Firstnames: string;
  Lastname: string;
  IsDeleted: boolean;
  [key: string]: string | ClearanceModel[] | AdminTeam | null | boolean;

  constructor(val: Partial<AdminUserModel>) {
    this.Id = val.Id || '';
    this.Email = val.Email || '';
    this.Phone = val.Phone || '';
    this.CountryId = val.CountryId || '';
    this.TeamId = val.TeamId || '';
    this.Team = val.Team || null;
    this.Title = val.Title || '';
    this.ProfileImageUrl = val.ProfileImageUrl || '';
    this.ProfileImageId = val.ProfileImageId || '';
    this.ClearanceLevels = val.ClearanceLevels || [];
    this.Firstnames = val.Firstnames || '';
    this.Lastname = val.Lastname || '';
    this.Name = val.Name || this.Firstnames + ' ' + this.Lastname;
    this.IsDeleted = val.IsDeleted || false;
  }
}

