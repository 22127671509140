import React, { useState, useEffect } from 'react';
import './CountryAndLanguage.scss';
import { ButtonBase } from '@material-ui/core';
import { StorageHelper } from '../../../../Helpers/StorageHelper';
import { CountryModel } from '../../../../Models/CountryModel';
import { KnownLanguageModel } from '../../../../Models/KnownLanguageModel';
import { LanguageHelper, getLanguage } from '../../../../Helpers/LanguageHelper';

interface IProps {
  open: boolean;
  onClose: () => void;

}

export function CountryAndLanguage(props: IProps) {
  let storageHelper = new StorageHelper();
  const [countries, setCountries] = useState<CountryModel[]>([]);



  useEffect(() => {
    preparePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (props.open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [props.open])

  async function preparePage() {
    let countryArr = storageHelper.getCountriesFromLocal();

    if (countryArr.length === 0) {
      storageHelper.addToCriticalCallbacks(preparePage);
      return;
    }

    let english = new KnownLanguageModel({
      Id: '0ed20374-a89b-494b-abb3-327e373d6bef',
      Iso6391Code: 'en',
      LanguageCode: 654,
      Title: 'English'
    })

    countryArr.forEach(x => {
      // if (Array.isArray(x.KnownLanguages) && x.KnownLanguages.length !== 0) {
      x.KnownLanguages.push(english)
      // }
    });

    countryArr = countryArr.sort((a, b) => {
      if (a.KnownLanguages.length === 1 && b.KnownLanguages.length === 1) {
        return a.CountryName > b.CountryName ? 1 : -1
      } else if (a.KnownLanguages.length === 1) {
        return 1;
      } else if (b.KnownLanguages.length === 1) {
        return -1;
      }
      return a.CountryName > b.CountryName ? 1 : -1
    });

    setCountries(countryArr);
  }


  async function setCountryAndLanguage(country: CountryModel, knownLanguage: KnownLanguageModel) {
    let facadeData = storageHelper.getFacadeLocalization();
    if (facadeData == null) return;
    facadeData.country = country;
    facadeData.language = knownLanguage.Id;
    storageHelper.setFacadeLocalization(facadeData, true);
    await storageHelper.initializeCaches(true)
    props.onClose();
  }



  function renderCountries() {

    return countries.map(country => {

      let countryFlagPath = '/img/flags/' + country.TwoCharacterCountryCode.toLocaleLowerCase() + '.svg';
      return country.KnownLanguages.map(knownLanguage => {
        return (
          // @ts-ignore
          <ButtonBase
            key={country.Id + knownLanguage.Id}
            focusRipple
            className="countryAndLanguage_buttonWrapper"
            onClick={() => setCountryAndLanguage(country, knownLanguage)}
          >
            <div className="countryAndLanguage_block" >
              <div className="countryAndLanguage_flag">
                <img src={countryFlagPath} alt="country flag" />
              </div>
              <div className="countryAndLanguage_name"> {country.CountryName} ({knownLanguage.Title})</div>
            </div>
          </ButtonBase>
        )
      })
    })
  }





  if (props.open === false) return <></>;

  return (
    <div className="CountryAndLanguageView" >
      <div className="countryAndLanguage_backdrop">
        <div className="countryAndLanguage_content">
          <h1 className="text-center margin-v-40">{getLanguage(757, 'Select your country and language')}</h1>
          {renderCountries()}
        </div>
      </div>
    </div>
  )
}