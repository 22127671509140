import React, { Component } from 'react';
import { Slider, Tooltip } from "@material-ui/core";
import { Mark } from '@material-ui/core/Slider';
import { getTimeString } from "./PostingHelper";

interface IProps {
  value: number;
  min: number;
  max: number;
  jobStart: number;
  jobEnd?: number;
  marks?: Mark[];
  disabled?: boolean;
  hidden?: boolean
  onSlide: (value: number) => void;
}
interface IState { }

export default class FreelancerSlider extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  // Custom tooltip
  ValueLabelComponent(props: any) {
    const { children, open, value } = props;

    const popperRef = React.useRef(null);
    React.useEffect(() => {
      if (popperRef.current != null) {
        // @ts-ignore
        popperRef.current!.update();
      }
    });

    return (
      <Tooltip
        PopperProps={{
          popperRef,
        }}
        open={open}
        enterTouchDelay={0}
        placement="top"
        title={getTimeString(value * 10)}
      >
        {children}
      </Tooltip>
    );
  }

  getSliderClass(): string | undefined {
    {
      if (this.props.jobEnd == null) return '';
      if (this.props.value < this.props.jobEnd) {
        return 'undertime';
      }
      if (this.props.value > this.props.jobEnd) {
        return 'overtime'
      }
    }
  }

  render() {
    return (
      <div hidden={this.props.hidden}>

        <Slider
          value={this.props.disabled ? 0 : this.props.value}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="auto"
          step={0.5}
          min={this.props.jobStart}
          max={this.props.max}
          onChange={(elem, value) => this.props.onSlide(value as number)}
          ValueLabelComponent={this.ValueLabelComponent}
          aria-label="custom thumb label"
          marks={this.props.marks}
          disabled={this.props.disabled}
          className={this.getSliderClass()}
        />
      </div>
    );
  }
}

