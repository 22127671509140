// @ts-nocheck
import React, { Component, ReactNode } from "react";
import "./Sitemap.scss";
import Tabs from "../../../../../Parts/General/Tabs";
import TabContent from "../../../../../Parts/General/TabContent";
import { Table, TableBody, TableCell, TableHead, TableRow, Button, InputAdornment, TextField, Tooltip } from "@material-ui/core";
import { TableOptions, IMenuItem } from "../../../../../Parts/General/TableOptions";
import { CMSHelper, CMSTypes } from "../../../../../../Helpers/CMSHelper";
import { CMSMetaModel } from "../../../../../../Models/CMSMetaModel";
import { RouteComponentProps } from "react-router";
import { StorageHelper } from "../../../../../../Helpers/StorageHelper";
import { CMSRevisionModel } from "../../../../../../Models/CMSRevisionModel";
import { SnackbarManager } from "../../../../../../Helpers/SnackbarManager/SnackbarManager";
import { ObligatoryPages } from "./ObligatoryPages";

interface IProps extends RouteComponentProps { }

interface IState {
  anchorElem: HTMLElement | null;
  menuItems: IMenuItem[][];
  tableData: CMSMetaModel[];
  activeCountryId: string;
}

interface ITableRow {
  id: string;
  pageName: string;
  pageLink: string;
  lastUpdate: string;
  contentStatus: { value: string, name: string };
  contentType: CMSTypes;
}

let statuses = {
  published: { value: 'far fa-check-circle success', name: 'Published' },
  draft: { value: 'fas fa-circle-notch warning', name: 'Draft' },
  empty: { value: '', name: '' }
};

export class SitemapView extends Component<IProps, IState> {
  cmsHelper = new CMSHelper();


  storageHelper = new StorageHelper();

  constructor(props: any) {
    super(props);
    if (this.props.location.state && this.props.location.state.deleted) {
      SnackbarManager.Instance.addSuccess('succesfully deleted ' + this.props.location.state.deleted); //TODO: Move this to whence it came
    }

    this.state = {
      anchorElem: null,
      menuItems: [],
      tableData: [],
      activeCountryId: this.storageHelper.getAtlasCountryFromLocal() || ''
    };

    this.openOptions = this.openOptions.bind(this);
    this.generateTable = this.generateTable.bind(this);
    this.closeOptions = this.closeOptions.bind(this);
    this.togglePublishStatus = this.togglePublishStatus.bind(this);
    this.deleteBlog = this.deleteBlog.bind(this);
    this.markObligatoryAsPublished = this.markObligatoryAsPublished.bind(this);

    this.fetchTableData();
    window.addEventListener('atlasCountryUpdated', this.languageUpdated);
  }


  languageUpdated = () => {
    this.setState({ activeCountryId: this.storageHelper.getAtlasCountryFromLocal() || '' });
  }

  async fetchTableData() {
    let tableData: CMSMetaModel[] = await this.cmsHelper.getAllContent(true);
    await this.setState({ tableData });
  }


  markObligatoryAsPublished(id: string, revisionId: string) {
    let tableData = this.state.tableData.map(x => x);
    let data = tableData.find(x => x.Id === id);
    if (data == null) {
      return SnackbarManager.Instance.addError('Could not mark obligatory as published');
    }
    data.PrimaryRevision = revisionId;

    this.setState({ tableData });
  }

  openOptions(event: any, menuItems: IMenuItem[][]) {
    event.stopPropagation();
    this.setState({ anchorElem: event.currentTarget, menuItems });
  }


  closeOptions() {
    this.setState({ anchorElem: null });
  }

  generateTable() {

    if (!this.state.tableData) {
      SnackbarManager.Instance.addError('no table data'); //TODO: Check that this is not called continuesly
      return;
    }

    let currentCountryId = this.storageHelper.getAtlasCountryFromLocal();
    let allBlogs = this.state.tableData.filter(x => x.Type === CMSTypes.Blog && x.CountryId === currentCountryId);
    let allPress = this.state.tableData.filter(x => x.Type === CMSTypes.Press && x.CountryId === currentCountryId);
    let allJob = this.state.tableData.filter(x => x.Type === CMSTypes.Job && x.CountryId === currentCountryId);
    let allPartner = this.state.tableData.filter(
      x => x.Type === CMSTypes.Partner && x.CountryId === currentCountryId
    );
    let allSEO = this.state.tableData.filter(x => x.Type === CMSTypes.SEO && x.CountryId === currentCountryId);
    let allCourse = this.state.tableData.filter(
      x => x.Type === CMSTypes.Course && x.CountryId === currentCountryId
    );

    if (allBlogs === undefined) return;

    let blogRows = this.buildTableRows(allBlogs);
    let pressRows = this.buildTableRows(allPress);
    let jobRows = this.buildTableRows(allJob);
    let partnerRows = this.buildTableRows(allPartner);
    let seoRows = this.buildTableRows(allSEO);
    let courseRows = this.buildTableRows(allCourse);

    // The first element in each array is always the title

    let arr: ITableRow[][] = [
      blogRows,
      pressRows,
      jobRows,
      partnerRows,
      seoRows,
      courseRows
    ];

    let tableRows: ReactNode[] = [];
    for (let i = 0; i < arr.length; i++) {
      const folderElem = arr[i];
      let tablePages: any[] = [];

      for (let j = 0; j < folderElem.length; j++) {
        const page = folderElem[j];
        if (j === 0) {
          tableRows.push(this.generateTableFolderRow(page));
        } else {
          tablePages.push(this.generateTablePageRow(page));
        }
      }
      tableRows.push(
        <TableBody key={i * folderElem.length + i}>{tablePages}</TableBody>
      );
    }

    let table = (
      <Table>
        <TableHead className="tableHead">
          <TableRow className="tableRow">
            <TableCell>Page name</TableCell>
            <TableCell>Page link</TableCell>
            <TableCell>Last Update</TableCell>
            <TableCell className="tableStatus" align="right">
              Status
            </TableCell>
            <TableCell align="right"> </TableCell>
          </TableRow>
        </TableHead>
        {tableRows}
      </Table>
    );

    return table;
  }

  buildTableRows(allContent: CMSMetaModel[]) {
    if (allContent.length < 1) return [];

    let title = CMSTypes[allContent[0].Type];
    let contentType = allContent[0].Type;

    let contentRows: ITableRow[] = [
      {
        id: "",
        pageName: title,
        pageLink: "/" + title.toLocaleLowerCase(),
        lastUpdate: "",
        contentStatus: statuses.empty,
        contentType
      }
    ];
    for (let index = 0; index < allContent.length; index++) {
      const elem = allContent[index];
      let date = "";
      let revIndex = elem.Revisions.length - 1;

      if (revIndex !== -1) {
        date = elem.Revisions[revIndex].Created.toString();
      } else {
        date = "No Revisions";
      }
      contentRows.push({
        id: elem.Id,
        pageName: elem.Title,
        pageLink:
          "/" +
          title.toLocaleLowerCase() +
          "/" +
          elem.PublicId +
          "/" +
          elem.Title,
        lastUpdate: date.toString(),
        contentStatus:
          elem.PrimaryRevision !== ""
            ? statuses.published
            : statuses.draft,
        contentType: contentType
      });
    }

    return contentRows;
  }

  async editContent(row: ITableRow) {
    switch (row.contentType) {
      case CMSTypes.Blog:
      case CMSTypes.Course:
        return this.props.history.push("/admin/cmsedit?q=" + row.id);
      // return this.props.history.push('/admin/coursepage?id=' + row.id);
      default:
        break;
    }
  }

  async togglePublishStatus(row: ITableRow) {
    this.closeOptions();

    if (row.contentStatus === statuses.published) {
      let response = await this.cmsHelper.unpublishContent(row.id);
      if (response == null || response === false) {
        SnackbarManager.Instance.addError('Unpublish failed');
      } else {
        let data = this.state.tableData;
        let index = data.findIndex(x => x.Id === row.id);
        data[index].PrimaryRevision = '';
        SnackbarManager.Instance.addSuccess('Succesfully unpublished "' + row.pageName + '"');
        this.setState({ tableData: data });
      }
    } else {
      let meta = new CMSMetaModel(this.state.tableData.find(x => x.Id === row.id));
      let rev = meta.getNewestRevision() as CMSRevisionModel;
      if (!rev.CoverImageUrl) {
        SnackbarManager.Instance.addWarning('Cover image required before publishing');
        return;
      }

      let response = await this.cmsHelper.publishContent(row.id);
      if (response == null || response === false) {
        SnackbarManager.Instance.addError('Publish failed');
      } else {
        let data = this.state.tableData;
        let index = data.findIndex(x => x.Id === row.id);
        data[index].PrimaryRevision = response;
        SnackbarManager.Instance.addSuccess('Succesfully published "' + row.pageName + '"');
        this.setState({ tableData: data });
      }
    }
  }

  async deleteBlog(id: string) {
    await this.setState({ anchorElem: null });
    let response = await this.cmsHelper.deleteContent(id);
    if (response === false) {
      SnackbarManager.Instance.addError('Could not delete content');
      return;
    }
    SnackbarManager.Instance.addSuccess('Content deleted');

    let tableData = this.state.tableData;
    let index = tableData.findIndex(x => x.Id === id);
    tableData.splice(index, 1);
    this.setState({ tableData });
  }

  async duplicateContent(id: string) {
    this.closeOptions();
    let languageActive = this.storageHelper.getLanguageActiveFromLocal();
    let response = await this.cmsHelper.getContentByIdAndDuplicate(id, languageActive!);
    if (response === false) {
      SnackbarManager.Instance.addError('Could not duplicate content');
      return;
    }
    await this.fetchTableData();
    SnackbarManager.Instance.addSuccess('Content duplicated');


    //TODO: Refresh the data
  }

  generateTableFolderRow(tableRow: ITableRow) {
    let menuItems: IMenuItem[][] = [
      [
        {
          callback: () => {
            console.log("plus");
          },
          iconCode: "fas fa-plus",
          title: "Add page"
        },
        {
          callback: () => {
            console.log("check");
          },
          iconCode: "far fa-check-circle",
          title: "Publish all pages"
        },
        {
          callback: () => {
            console.log("check");
          },
          iconCode: "fas fa-eye",
          title: "Go to overview"
        }
      ]
    ];

    let tb = (
      <TableBody key={tableRow.id} className="tableBody">
        <TableRow className="tableRow folder">
          <TableCell className="tableCell">{tableRow.pageName}</TableCell>
          <TableCell className="tableCell">{tableRow.pageLink}</TableCell>
          <TableCell className="tableCell"> </TableCell>
          <TableCell className="tableCell tableStatus" align="center">
            <i className={tableRow.contentStatus.value} />
          </TableCell>
          <TableCell
            className="tableCell tableOptions"
            align="right"
            onClick={(event: any) => this.openOptions(event, menuItems)}
            aria-owns={this.state.anchorElem ? "simple-menu" : undefined}
          >
            <i className="fas fa-ellipsis-v" />
          </TableCell>
        </TableRow>
      </TableBody>
    );
    return tb;
  }

  generateTablePageRow(tableRow: ITableRow) {
    let menuItems: IMenuItem[][] = [
      [
        {
          callback: () => {
            this.editContent(tableRow);
          },
          iconCode: "far fa-edit",
          title: "Edit page"
        },
        {
          callback: () => {
            this.togglePublishStatus(tableRow);
          },
          iconCode: "far fa-check-circle",
          title: tableRow.contentStatus === statuses.draft ? "Publish" : 'Unpublish'
        },
        {
          callback: () => {
            this.duplicateContent(tableRow.id);
          },
          iconCode: "far fa-copy",
          title: "Duplicate version"
        }
      ],
      [
        {
          callback: () => {
            this.deleteBlog(tableRow.id);
          },
          iconCode: "far fa-trash-alt",
          title: "Delete"
        }
      ]
    ];

    let tr = (
      <TableRow
        key={tableRow.id}
        className="tableRow subTable"
        onClick={() => this.props.history.push(tableRow.pageLink.toLocaleLowerCase())}
      >
        <TableCell className="tableCell">{tableRow.pageName}</TableCell>
        <TableCell className="tableCell">{tableRow.pageLink}</TableCell>
        <TableCell className="tableCell">{tableRow.lastUpdate}</TableCell>
        <TableCell className="tableCell tableStatus" align="right">
          <Tooltip
            title={tableRow.contentStatus.name}
            // "Show my status"
            placement="top"
          >
            <i className={tableRow.contentStatus.value} />
          </Tooltip>

        </TableCell>
        <TableCell
          className="tableCell tableOptions"
          align="right"
          onClick={(event: any) => this.openOptions(event, menuItems)}
        >
          <i className="fas fa-ellipsis-v" />
        </TableCell>
      </TableRow>
    );
    return tr;
  }

  render() {
    return (
      <div className="sitemap">
        <div className="AdminPageHeader">
          <div className="AdminPageTitle">Sitemap</div>
          <div className="AdminPageHeader_options">
            <div className="inputGroup">
              <TextField
                className="inputGroup_item"
                type="text"
                variant="outlined"
                margin="dense"
                style={{ margin: 0 }}
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className="inputAdornment">
                      <i className="fas fa-search" />
                    </InputAdornment>
                  )
                }}
              />
              <Button
                className="success inputGroup_item"
                variant="text"
                size="medium"
              >
                <span className="btn-icon">
                  <i className="fas fa-plus" />
                </span>
                Add page
              </Button>
            </div>
          </div>
        </div>

        <Tabs>
          <TabContent title="Custom pages">
            <div>
              {this.generateTable()}
            </div>
          </TabContent>
          <TabContent title="Obligatory pages">
            <ObligatoryPages
              termsAndConditions={this.state.tableData.filter(x => ( x.Type === CMSTypes.TermsAndConditions || x.Type === CMSTypes.TermsAndConditionsCompany) && x.CountryId === this.state.activeCountryId)}
              contracts={this.state.tableData.filter(x => x.Type === CMSTypes.FreelancerContract && x.CountryId === this.state.activeCountryId)}
              gdpr={this.state.tableData.filter(x => x.Type === CMSTypes.Gdpr && x.CountryId === this.state.activeCountryId)}
              privacyPolicy={this.state.tableData.filter(x => x.Type === CMSTypes.PrivacyPolicy && x.CountryId === this.state.activeCountryId)}
              markAsPublished={this.markObligatoryAsPublished}
              {...this.props}
            />
          </TabContent>
        </Tabs>

        <TableOptions
          anchorEl={this.state.anchorElem}
          title={"Title"}
          menuItems={this.state.menuItems}
          handleClose={this.closeOptions}
        />
      </div>
    );
  }
}
