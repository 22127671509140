import { CompetenceQuestionModel } from "./CompetenceQuestionModel";

export class SkillModel {
    Id: string;
    Title: string;
    TitlePluralis: string;
    Activated: boolean;
    SortBy: number;
    TitleForFreelancer: string;
    TitlePluralisForFreelancer: string;

    LanguageCodeSingularis: number;
    LanguageCodePluralis: number;
    LanguageCodeSingularisForFreelancer: number;
    LanguageCodePluralisForFreelancer: number;

    CompetenceQuestions: CompetenceQuestionModel[];


    constructor(val: Partial<SkillModel>) {
        this.Id = val.Id || '';
        this.Title = val.Title || '';
        this.TitlePluralis = val.TitlePluralis || '';
        this.Activated = val.Activated || false;
        this.SortBy = val.SortBy || -1;
        this.TitleForFreelancer = val.TitleForFreelancer || '';
        this.TitlePluralisForFreelancer = val.TitlePluralisForFreelancer || '';

        this.LanguageCodeSingularis = val.LanguageCodeSingularis || 0;
        this.LanguageCodePluralis = val.LanguageCodePluralis || 0;
        this.LanguageCodeSingularisForFreelancer = val.LanguageCodeSingularisForFreelancer || 0;
        this.LanguageCodePluralisForFreelancer = val.LanguageCodePluralisForFreelancer || 0;

        this.CompetenceQuestions = val.CompetenceQuestions || [];
    }

    getLanguageCode(amount: number, isFreelancer?: boolean, singularTitleWanted?: boolean) {
      let languageCode: number = 0;
          if (!isFreelancer) {
            languageCode = amount && amount <= 1 || singularTitleWanted ? this.LanguageCodeSingularis : this.LanguageCodePluralis
          } else {            
            languageCode = amount && amount <= 1 || singularTitleWanted ? this.LanguageCodeSingularisForFreelancer : this.LanguageCodePluralisForFreelancer
          }
        
          return languageCode;
    }
}