// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from 'react';
import { SwipeableDrawer } from "@material-ui/core";
import { Chat } from "../../Shared/Chat";
import { PostingModel } from '../../../../../Models/PostingModel';
import { ChatHelper } from '../../../../../Helpers/ChatHelper';

interface IProps {
  posting: PostingModel;
  drawerOpen: boolean;
  toggleChat: (e: boolean) => void;
  messageNetworkId: string;
}

interface IState { 
  
}

export class ChatDrawer extends Component<IProps, IState> {
  chatHelper = new ChatHelper();
  constructor(props: IProps) {
    super(props);

    this.state = {
    }
  }

  render() {
    return (<div>
      <SwipeableDrawer
          anchor="right"
          open={this.props.drawerOpen}
          onOpen={() => this.props.toggleChat(true)}
          onClose={() => this.props.toggleChat(false)}
          variant="temporary"
          classes={{ paper: 'chatDrawer' }}
        >
          <Chat messageNetworkId={this.props.messageNetworkId}></Chat>
        </SwipeableDrawer>
    </div>)
  }
}