import React, { Component } from "react";
import {
  InputLabelProps,
  OutlinedInputProps,
  TextField,
} from "@material-ui/core";
import { StorageHelper } from "../../../Helpers/StorageHelper";
import { LocalDataModel } from "../../../Models/LocalDataModel";
import { CountryModel } from "../../../Models/CountryModel";
import { changeToLocalDecimal } from "../../../Helpers/LocalizationHelper";

interface IProps {
  label?: string;
  fullWidth?: boolean;
  margin?: "dense" | "none" | "normal";
  value: string;
  onChange: (value: string) => void;
  onBlur?: (e: React.EventHandler<any>) => void;
  error?: boolean;
  helperText?: React.ReactNode;
  onFocus?: (e: any) => void;
  disabled?: boolean;
  defaultValue: string;
  InputProps?: Partial<OutlinedInputProps>;
  InputLabelProps?: Partial<InputLabelProps>;
  size?: "small" | "medium" | undefined;
}

interface IState {}

export class CurrencyInput extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    //Get the users local decimal symbol
    //FIXME: this is not used anywhere? #STL
    let local = new StorageHelper().getFacadeLocalization() as LocalDataModel;

    this.onBlur = this.onBlur.bind(this);
  }

  onChange(value: string) {
    value = value.replace(/[^\d.,]/g, "");
    value = value.replace(/[.,]/g, ".");

    let decimalCount = value.split(".").length;
    if (decimalCount > 2) {
      this.props.onChange(this.props.value);
    } else {
      this.props.onChange(value);
    }
  }

  onBlur(event?: React.EventHandler<any>) {
    let value = this.props.value.toString();
    value = value.replace(",", ".");
    if (value === "" || Math.floor(Number(value)) === 0) {
      value = (Number(this.props.defaultValue) / 100).toFixed(2);
    }
    value = (Math.round(Number(value) * 100) / 100).toFixed(2);
    if (!value.includes(".")) {
      value = value + ".00";
    }
    let missing = 2 - value.split(".")[1].length;

    for (let index = 0; index < missing; index++) {
      value += 0;
    }
    this.props.onChange(value);
    if (this.props.onBlur != null && event != null) this.props.onBlur(event);
  }

  render() {
    return (
      <TextField
        value={changeToLocalDecimal(this.props.value)}
        variant={"outlined"}
        size={this.props.size}
        label={this.props.label}
        fullWidth={this.props.fullWidth}
        margin={this.props.margin ? this.props.margin : "dense"}
        onBlur={(e: any) => this.onBlur(e)}
        onChange={(e) => this.onChange(e.target.value)}
        error={this.props.error}
        helperText={this.props.helperText}
        onFocus={this.props.onFocus}
        disabled={this.props.disabled}
        InputProps={this.props.InputProps}
        InputLabelProps={this.props.InputLabelProps}
      />
    );
  }
}
