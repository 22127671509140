import React, { Component } from 'react';
import { MenuItem } from '@material-ui/core';
//@ts-ignore
import { isEmail, isLength } from 'validator';
import { CountryModel } from '../../../../Models/CountryModel';
import { StorageHelper } from '../../../../Helpers/StorageHelper';
import { getLanguage } from '../../../../Helpers/LanguageHelper';
import { OptimizedTextField } from "../../../Parts/General/OptimizedTextField";
import { PhoneField } from "../../../Parts/General/PhoneField";

interface IProps {
  possibleCountries: CountryModel[];
  changeField: (fieldname: string, value: any) => void;
  formsData: any;
}

interface IState {
  errors: { email: boolean, password: boolean, companyName: boolean, [key: string]: boolean }
}
export class RegisterCompany extends Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      errors: { email: false, password: false, companyName: false, phone: false },
    }
    this.setFieldErrors = this.setFieldErrors.bind(this);
  }

  async setFieldErrors(name: string, state: boolean) {
    let errs = this.state.errors;
    errs[name] = state;

    let envVar = new StorageHelper().getEnvVar();
    if (name === 'password' && envVar !== 'Production') errs[name] = false;
    await this.setState({ errors: errs });
  }

  render() {
    return (
      <div>
        <div className='margin-v-24'>
          <h1>{getLanguage(539, "Register as a company")}</h1>
        </div>
        <OptimizedTextField
          id="outlined-country"
          select
          label={getLanguage(80, "Country")}
          value={this.props.formsData.selectedCountry || ''}
          onChange={(e) => this.props.changeField('selectedCountry', e.target.value)}
        >
          {this.props.possibleCountries.map(x => (<MenuItem value={x.Id} key={x.Id}>{x.LanguageCode ? getLanguage(x.LanguageCode, "Country name") : ""}</MenuItem>))}
        </OptimizedTextField>

        <OptimizedTextField
          type='text'
          label={getLanguage(101, "Company name")}
          error={this.state.errors.companyName}
          value={this.props.formsData.companyName || ''}
          onBlur={(e) => this.setFieldErrors('companyName', !isLength(e.target.value, { min: 1, max: 128 }))}
          onFocus={() => this.setFieldErrors('companyName', false)}
          onChange={(e) => this.props.changeField('companyName', e.target.value)}
        />

        <OptimizedTextField
          type='email'
          label={getLanguage(73, "Email")}
          error={this.state.errors.email}
          onBlur={(e) => this.setFieldErrors('email', !isEmail(e.target.value))}
          onFocus={() => this.setFieldErrors('email', false)}
          value={this.props.formsData.email || ''}
          onChange={(e) => this.props.changeField('email', e.target.value)}
        />
        <OptimizedTextField
          type='password'
          label={getLanguage(170, "Password")}
          error={this.state.errors.password}
          onBlur={(e) => this.setFieldErrors('password', !isLength(e.target.value, { min: 6, max: 32 }))}
          onFocus={() => this.setFieldErrors('password', false)}
          value={this.props.formsData.password || ''}
          onChange={(e) => this.props.changeField('password', e.target.value)}
        />
        <OptimizedTextField
          id='outlined-business-currency'
          select
          label={getLanguage(540, "Business type")}
          value={this.props.formsData.selectedBusinessType || ''}
          onChange={(e) => this.props.changeField('selectedBusinessType', e.target.value)}
        >
          <MenuItem value='Hotel'>{getLanguage(541, "Hotel")}</MenuItem>
          <MenuItem value='Event Agency'>{getLanguage(542, "Event agency")}</MenuItem>
          <MenuItem value='Restaurant'>{getLanguage(543, "Restarant")}</MenuItem>
          <MenuItem value='Bar'>{getLanguage(544, "Bar")}</MenuItem>
          <MenuItem value='Catering'>{getLanguage(545, "Catering")}</MenuItem>
          <MenuItem value='Other'>{getLanguage(546, "Other")}</MenuItem>
        </OptimizedTextField>
      </div>

    );
  }
}
