import React, { Component } from "react";
import Tabs from "../../Parts/General/Tabs";
import TabContent from "../../Parts/General/TabContent";
import { TextField } from "@material-ui/core";
import { AdminUserHelper } from '../../../Helpers/AdminUserHelper';
import { RouteComponentProps } from "react-router";
import { SavingSnackbar } from "../../Parts/General/SavingSnackbar";
import { StorageHelper } from "../../../Helpers/StorageHelper";
import { SnackbarManager } from "../../../Helpers/SnackbarManager/SnackbarManager";

interface IProps extends RouteComponentProps {
  location: any;
}

interface IState {
  currentPassword: string;
  newPassword1: string;
  newPassword2: string;
  passwordsMatches: boolean;
  allBoxesAreFilled: boolean;
}

export class SettingsView extends Component<IProps, IState> {
  adminUserHelper = new AdminUserHelper();
  storageHelper = new StorageHelper();


  constructor(props: IProps) {
    super(props);

    this.state = {
      currentPassword: '',
      newPassword1: '',
      newPassword2: '',
      passwordsMatches: true,
      allBoxesAreFilled: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.resetUser = this.resetUser.bind(this)
    this.saveUser = this.saveUser.bind(this)
  }


  async handleSubmit(e: any) {

    e.preventDefault();

    let settings = {
      currentPassword: this.state.currentPassword,
      newPassword1: this.state.newPassword1,
      newPassword2: this.state.newPassword2,
    }
    let confirmation = await this.adminUserHelper.saveSettings(settings);
    if (confirmation) {
      SnackbarManager.Instance.addSuccess('password updated');
    } else {
      SnackbarManager.Instance.addError('Current password is incorrect');
      this.setState({ currentPassword: '', newPassword1: '', newPassword2: '' });
    }
  }

  checkIfAllBoxesAreFilled() {
    if (this.state.currentPassword !== '' && this.state.newPassword1 !== '' && this.state.newPassword2 !== '') {
      this.setState({ allBoxesAreFilled: true })
    }
  }

  secondPasswordBox() {
    if (!this.state.passwordsMatches) {
      return (<TextField
        error
        id='component-error'
        type="password"
        label="Repeat new password"
        variant="outlined"
        fullWidth
        margin="dense"
        value={this.state.newPassword2}
        onChange={(event) => this.setState({ newPassword2: event.target.value, passwordsMatches: true })}
        InputLabelProps={{
          shrink: true
        }}
        aria-describedby="component-error-text"
        helperText="Passwords do not match."
      />)
    } else {
      return (<TextField
        id='component-error'
        type="password"
        label="Repeat new password"
        variant="outlined"
        fullWidth
        margin="dense"
        value={this.state.newPassword2}
        onChange={(event) => this.setState({ newPassword2: event.target.value })}
        InputLabelProps={{
          shrink: true
        }}
      />)
    }
  }

  resetUser() {
    SnackbarManager.Instance.addSuccess('Changes reset');
    this.setState({ currentPassword: '', newPassword1: '', newPassword2: '' });
  }

  saveUser() {
    if (this.state.newPassword1 !== this.state.newPassword2) {
      SnackbarManager.Instance.addError('Changes not saved');
      this.setState({ passwordsMatches: false, newPassword2: '' });
    } else {
      this.handleSubmit(event);
    }
  }

  render() {
    let savingSnackbar: any;
    if (this.state.currentPassword != '' && this.state.newPassword1 != '' && this.state.newPassword2 != '' &&
      this.state.newPassword1.length == this.state.newPassword2.length) {
      savingSnackbar = <SavingSnackbar open={true} resetClicked={this.resetUser} saveClicked={this.saveUser} />
    } else {
      savingSnackbar = <SavingSnackbar open={false} saveClicked={this.saveUser} />
    }

    return (
      <div className="settingsView">
        <div className="AdminPageHeader">
          <div className="AdminPageTitle">My settings</div>
          <Tabs>
            <TabContent title="Account settings">
              <form onSubmit={this.handleSubmit}>
                <div className="paper padding-24 radius-12 maxW-600">
                  <h2>Change password</h2>
                  <TextField
                    type="password"
                    label="Current password"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    value={this.state.currentPassword}
                    onChange={(event) => this.setState({ currentPassword: event.target.value })}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                  <TextField
                    type="password"
                    label="New password"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    value={this.state.newPassword1}
                    onChange={(event) => this.setState({ newPassword1: event.target.value })}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                  {this.secondPasswordBox()}
                </div>
                <br /><br />
              </form>
            </TabContent>
            <TabContent title="Theme">
              <h1>Theme</h1>
            </TabContent>
            <TabContent title="?">
              <h1>?</h1>
            </TabContent>
          </Tabs>
        </div>

        {savingSnackbar}
      </div>

    );
  }
}
