import React from "react";
import { MenuItem } from "@material-ui/core";
import {
  setMinutes,
  format,
  addDays,
  startOfWeek,
  isBefore,
  parseISO,
  isAfter,
} from "date-fns";
import { flatMap, range } from "lodash";
import { getLanguage } from "../../../Helpers/LanguageHelper";
import {
  OperationType,
  PredicateType,
  Rule,
  RulesBySkillId,
  TimeInterval,
} from "../Types";
import { PostingModel } from "../../../Models/PostingModel";
import { RequestModel } from "../../../Models/RequestModel";
import { CompanyModel } from "../../../Models/CompanyModel";
import { PostingTemplateModel } from "../../../Models/PostingTemplateModel";

export const shortWeekDaysArray = Array.from(Array(7)).map((e, i) =>
  format(addDays(startOfWeek(new Date()), i + 1), "EEEEEE")
);

export const formatAdornment = (type: OperationType) => {
  switch (type) {
    case "Addition":
    case "None":
      return "kr.";
    case "Percentage":
      return "%";
    default:
      return "";
  }
};

export function validateNumber(value: string): string | undefined {
  value = value.toString();
  if (
    !value.match(
      /^\s*[+-]?(?:(?:\d+(?:[\.\,]\d*)?)|(?:[\.\,]\d+))(?:e([+-]?\d+))?\s*$/i
    )
  ) {
    return getLanguage(165, "Must be a number");
  }
  if (Number(value) < 0) {
    return getLanguage(703, "Can not be less than 1");
  }

  return;
}

export function validateRequiredString(value: string): string | undefined {
  if (value && value.length > 0) return;
  return getLanguage(153, "Required");
}

export const checkIfAnyRuleInvalid = (rules: Rule[]) =>
  rules
    .map((rule) => ({
      ...rule,
      validationResult: validateNumber(rule.value),
    }))
    .some((rule) => !!rule.validationResult);

export const IntervalsFrom = () =>
  flatMap([...Array(24).keys()], (h) => {
    const full = setMinutes(new Date(), 0).setHours(h);
    const half = setMinutes(new Date(), 30).setHours(h);
    return [full, half];
  }).map((t, i) => {
    const formattedTime = format(t, "HH:mm");
    return (
      <MenuItem key={i} value={formattedTime}>
        {formattedTime}
      </MenuItem>
    );
  });

export const IntervalsTo = () =>
  flatMap([...Array(25).keys()], (h) => {
    const full = setMinutes(new Date(), 0).setHours(h);
    const half = setMinutes(new Date(), 30).setHours(h);
    if (h === 0) {
      return [half];
    }
    if (h === 24) {
      return [full];
    }
    return [full, half];
  }).map((t, i) => {
    //ref: https://trello.com/c/PLExR7dt/234-error-when-creating-a-timeslot-salary-rule
    if (i === 0) {
      const formattedTime = format(t, "HH:mm");
      return (
        <MenuItem key={i} value={formattedTime}>
          {formattedTime}
        </MenuItem>
      );
    } else {
      const formattedTime = format(t, "k:mm");
      return (
        <MenuItem key={i} value={formattedTime}>
          {formattedTime}
        </MenuItem>
      );
    }
  });

export const GetOvertimeHours = () =>
  range(1, 15).map((t, i) => {
    return (
      <MenuItem key={i} value={t}>
        {t}
      </MenuItem>
    );
  });

const defaultTimeOfTheDayInterval = {
  start: "01:00",
  end: "05:00",
};

const defaultOffsetBetweenInterval = {
  start: "1",
  end: "10",
};

const defaultOffsetFromInterval = {
  start: "1",
  end: "",
};

export const resolveDefaultInterval = (type: PredicateType) => {
  switch (type) {
    case "TimeOfTheDay":
      return defaultTimeOfTheDayInterval;
    case "ShiftStartOffsetBetween":
      return defaultOffsetBetweenInterval;
    case "ShiftStartOffsetFrom":
      return defaultOffsetFromInterval;
  }
};

const validateOffsetBetweenInterval = ({ start, end }: TimeInterval) => {
  if (Number(start) >= Number(end)) return "Invalid interval";
  return null;
};

const validateOffsetFromInterval = (_interval: TimeInterval) => {
  return null; //nothing to validate as it's single select
};

const validateTimeOfTheDayInterval = ({ start, end }: TimeInterval) => {
  const militaryStart = Number(start.replace(":", ""));
  const militaryEnd = Number(end?.replace(":", ""));

  if (militaryEnd === militaryStart) return "Invalid interval";
  if (militaryEnd === 0) return null;
  if (militaryStart > militaryEnd) {
    return "Invalid interval";
  }

  return null;
};

export const validateWeekdays = (weekdays: string[]) => {
  if (weekdays.length < 1) return "Select at least 1 day";
  return;
};

export const validateInterval = (
  type: PredicateType,
  interval: TimeInterval
) => {
  switch (type) {
    case "TimeOfTheDay":
      return validateTimeOfTheDayInterval(interval);
    case "ShiftStartOffsetBetween":
      return validateOffsetBetweenInterval(interval);
    case "ShiftStartOffsetFrom":
      return validateOffsetFromInterval(interval);
  }
};

const releaseDate = new Date(2023, 1, 18); //need to be changed before actual release

export const resolvePostingSalaryPerHour = (posting: PostingModel): number =>
  resolveSalaryPerHour(
    posting.SalaryPerHour,
    posting.Company ?? posting.CompanyProfile,
    posting.SkillId,
    parseISO(posting.Created as unknown as string)
  );

export const resolveSalaryPerHourForCompany = (
  salaryPerHour: number,
  company: CompanyModel,
  skillId: string
): number => resolveSalaryPerHour(salaryPerHour, company, skillId, new Date());

export const resolveSalaryPerHourForRequest = (request: RequestModel): number =>
  resolveSalaryPerHour(
    request.SalaryPerHour,
    request.Company ?? request.CompanyProfile,
    request.SkillId,
    parseISO(request.Created as unknown as string)
  );

export const resolveSalaryPerHour = (
  orginalValue: number,
  company: CompanyModel | null,
  skillId: string,
  createdAt: Date
): number => {
  const shouldDisplayValueFromCollectiveAgreement = isAfter(
    createdAt,
    releaseDate
  );
  if (!shouldDisplayValueFromCollectiveAgreement) return orginalValue;
  if (!company || !company.CollectiveAgreementRules) {
    return orginalValue;
  }

  const allCollectiveAgreementRules = JSON.parse(
    company?.CollectiveAgreementRules
  ) as RulesBySkillId[];
  const skillCollectiveAgreementRules = allCollectiveAgreementRules.find(
    (x) => x.skillId === skillId
  );
  if (!skillCollectiveAgreementRules) {
    return orginalValue;
  }

  const collectiveAgreementBaseSalary =
    skillCollectiveAgreementRules.rules.find((x) => x.identifier === "Base");

  if (!collectiveAgreementBaseSalary) {
    return orginalValue;
  }

  return parseInt(collectiveAgreementBaseSalary.value);
};
