import React, { PureComponent } from 'react';
import './Login.scss';
import { AdminUserHelper } from '../../../Helpers/AdminUserHelper';
import { RouteComponentProps } from 'react-router';
import { StorageHelper } from '../../../Helpers/StorageHelper';
import { setAdminData } from '../../../Helpers/SessionHelper';
import { getQuery } from '../../../Helpers/queryHelper';


interface IProps extends RouteComponentProps { }

interface IState {
  password: string;
  email: string;
  currentState: 'loginForm' | 'sessionCode';
  sessionCode: string;
  loginErrorActiveCssClass: string;
  submitDisabled: boolean;
}

export class Login extends PureComponent<IProps, IState> {
  storageHelper = new StorageHelper();
  adminUserHelper = new AdminUserHelper();

  constructor(props: IProps) {
    super(props);

    this.state = {
      email: '',
      password: '',
      currentState: 'loginForm',
      sessionCode: '',
      loginErrorActiveCssClass: 'loginError',
      submitDisabled: false,
    }
    this.checkLoggedIn();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSessionCodeSubmit = this.handleSessionCodeSubmit.bind(this);
  }

  async checkLoggedIn() {
    let token = await this.adminUserHelper.verifyLoggedIn();
    if (token) {
      let redirectPath = getQuery('redirect', this.props.location.search);

      if (redirectPath?.indexOf('/admin/') === 0) {
        this.props.history.push(redirectPath);
      } else {
        this.props.history.push('/admin');
      }
    } else {
      this.storageHelper.removeAdminToken();
    }
  }

  async handleSubmit(e: any) {
    try {
      e.preventDefault();
      await this.setState({ submitDisabled: true });
      let result = await this.adminUserHelper.LogUserIn(
        this.state.email,
        this.state.password
      );
      if (result?.token && result?.user) {
        setAdminData(result.user);

        this.storageHelper.setAtlasCountryInLocal(result.user.CountryId)
        this.storageHelper.setAdminToken(result.token);
        this.setState({ loginErrorActiveCssClass: 'loginError', submitDisabled: false }) // sets class to display error or not

        let redirectPath = getQuery('redirect', this.props.location.search);
        this.storageHelper.removeAtlasCountryFromLocal();
        if (typeof redirectPath === 'string' && redirectPath.indexOf('/admin/') === 0) {
          this.props.history.push(redirectPath);
        } else {
          this.props.history.push('/admin');
        }
      } else if (result?.message === 'noSessionCode') {
        // render session code window
        this.setState({ currentState: 'sessionCode', submitDisabled: false })
      } else {
        console.log('Render invalid login');
        this.setState({ loginErrorActiveCssClass: 'loginError active', submitDisabled: false }) // sets class to display error or not
      }
    } catch (error: any) {
      console.log('error', error);
      this.setState({ submitDisabled: false });
    }
  }

  async handleSessionCodeSubmit(e: any) {
    e.preventDefault();
    let tokenAndUser = await this.adminUserHelper.verifySessionCode(this.state.sessionCode);

    if (tokenAndUser?.token && tokenAndUser?.user) {
      this.storageHelper.setAdminToken(tokenAndUser.token);
      this.storageHelper.setAdminUser(JSON.stringify(tokenAndUser.user));
      this.props.history.push('/admin');
    }
  }

  getLoginForm() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div>
          <h2 className={this.state.loginErrorActiveCssClass}>Unable to log in with provided credentials</h2>
          <div className='inputPack block dark'>
            <div className='inputPack_icon'>
              <i className='fas fa-user' />
            </div>
            <input
              value={this.state.email}
              onChange={(event: any) => this.setState({ email: event.target.value })}
              className='block inputPack_input'
              type='mail'
              placeholder='Username'
              name='email'
              required
              autoFocus
            />
          </div>
          <div className='inputPack block dark'>
            <div className='inputPack_icon'>
              <i className='fas fa-unlock-alt' />
            </div>
            <input
              value={this.state.password}
              onChange={(event) => this.setState({ password: event.target.value })}
              className='block inputPack_input'
              type='password'
              placeholder='Password'
              name='psw'
              required
            />
          </div>
          <div className='block'>
            <button className='button button-gradient' type='submit' onClick={this.handleSubmit} disabled={this.state.submitDisabled}>
              Sign in
            </button>
          </div>
        </div>
      </form>
    )
  }



  getSessionCodeForm() {
    return (
      <form onSubmit={this.handleSessionCodeSubmit}>
        <div>
          <h2 className="white">Verify Session</h2>
          <div className='inputPack block dark'>
            <div className='inputPack_icon'>
              <i className='fas fa-user' />
            </div>
            <input
              value={this.state.sessionCode}
              onChange={(event: any) => this.setState({ sessionCode: event.target.value })}
              className='block inputPack_input'
              type='text'
              placeholder='Code'
              required
              autoFocus
            />
          </div>
          <div className='block'>
            <button className='button button-gradient' type='submit' onClick={this.handleSessionCodeSubmit}>
              Submit </button>
          </div>
        </div>
        <a href='#' onClick={() => this.setState({ currentState: 'loginForm' })}>back</a>
      </form>
    );
  }



  render() {
    let content;
    switch (this.state.currentState) {
      case 'loginForm':
        content = this.getLoginForm();
        break;
      case 'sessionCode':
        content = this.getSessionCodeForm();
        break;
      default:
        content = this.getLoginForm();
        break;
    }

    return (
      <div className='loginPageWrapper'>
        <div className='scrollWrapper'>
          <div className='loginForm'>
            <div className='loginPage_logo'>
              <img src={'/img/admin.svg'} alt='' />
            </div>
            {content}
          </div>
        </div>
      </div>
    );
  }
}
