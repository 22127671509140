import { SsnFormatModel } from "./SsnFormatModel";
import { CurrencyModel } from "./CurrencyModel";
import { KnownLanguageModel } from "./KnownLanguageModel";

export class CountryModel {
  CountryName: string;
  Id: string;
  TwoCharacterCountryCode: string;
  SsnFormat: SsnFormatModel;
  HolidayPayPercent: number;
  PensionPercent: number;
  MinimumHourlyWage: number;
  SuggestedHourlyRate?: number | null;
  NumberFormat: "A" | "B";
  Currency: CurrencyModel | null;
  MaxKmRadius: number;
  ZipCodeRegex: string | null;
  BankAccountNumberRegex: string | null;
  BankRegistrationNumberRegex: string | null;
  UsesLocalAccountNumbers: boolean;
  MultipleTaxCards: boolean;
  EuEeaMember: boolean;
  TaxPercent: number;
  LanguageCode: number | null;
  BankClearenceNumberLanguageCode: number | null;
  BankAccountNumberLanguageCode: number | null;
  TaxCardLanguageCode: number | null;
  TaxCardOption1LanguageCode: number | null;
  TaxCardOption2LanguageCode: number | null;
  SsnLanguageCode: number | null;
  SupportPhone: string;
  KnownLanguages: KnownLanguageModel[];
  CollectiveAgreementRules: string;

  [key: string]: any;

  constructor(val: Partial<CountryModel>) {
    this.Id = val.Id || "";
    this.CountryName = val.CountryName || "";
    this.TwoCharacterCountryCode = val.TwoCharacterCountryCode || "";
    this.SsnFormat = val.SsnFormat || { format: [], verify: { regex: "", length: -1 } };
    this.HolidayPayPercent = val.HolidayPayPercent || 0;
    this.PensionPercent = val.PensionPercent || 0;
    this.MinimumHourlyWage = val.MinimumHourlyWage || -1;
    this.NumberFormat = val.NumberFormat || "A";
    this.Currency = val.Currency || null;
    this.MaxKmRadius = val.MaxKmRadius || 0;
    this.ZipCodeRegex = val.ZipCodeRegex || null;
    this.BankAccountNumberRegex = val.BankAccountNumberRegex || null;
    this.BankRegistrationNumberRegex = val.BankRegistrationNumberRegex || null;
    this.UsesLocalAccountNumbers = val.UsesLocalAccountNumbers || false;
    this.MultipleTaxCards = val.MultipleTaxCards || false;
    this.EuEeaMember = val.EuEeaMember || false;
    this.TaxPercent = val.TaxPercent || 1000;
    this.LanguageCode = val.LanguageCode || null;
    this.BankClearenceNumberLanguageCode = val.BankClearenceNumberLanguageCode || null;
    this.BankAccountNumberLanguageCode = val.BankAccountNumberLanguageCode || null;
    this.TaxCardLanguageCode = val.TaxCardLanguageCode || null;
    this.TaxCardOption1LanguageCode = val.TaxCardOption1LanguageCode || null;
    this.TaxCardOption2LanguageCode = val.TaxCardOption2LanguageCode || null;
    this.SsnLanguageCode = val.SsnLanguageCode || null;
    this.SupportPhone = val.SupportPhone || "";
    this.KnownLanguages = val.KnownLanguages || [];
    this.SuggestedHourlyRate = val.SuggestedHourlyRate;
    this.CollectiveAgreementRules = val.CollectiveAgreementRules || "";
  }

  getNumberFormat() {
    return this.NumberFormat === "A" ? "," : ".";
  }
}
