import React, { Component } from "react";
import "./BlogEdit.scss";
import { Button, Avatar, } from "@material-ui/core";

// Require Font Awesome.
import 'font-awesome/css/font-awesome.css';

//@ts-ignore
import { CMSMetaModel } from "../../../../../../Models/CMSMetaModel";
import { BlogHelper } from "../../../../../../Helpers/BlogHelper";
import { CMSRevisionModel } from "../../../../../../Models/CMSRevisionModel";
import { CMSActions, CMSHelper, CMSTypes } from "../../../../../../Helpers/CMSHelper";
import { ImageHelper, configureCloudinaryUrl } from '../../../../../../Helpers/ImageHelper'
import { RouteComponentProps, Prompt } from "react-router";
import { PhotoLibrary } from "../../../../../Parts/General/PhotoLibrary"
import { AdminUserHelper } from "../../../../../../Helpers/AdminUserHelper";
import { MetaDrawer } from "../../../../../Parts/General/Drawer";
import { SnackbarManager } from "../../../../../../Helpers/SnackbarManager/SnackbarManager";
import { getQuery } from "../../../../../../Helpers/queryHelper";

interface IProps extends RouteComponentProps {
  openCropping: (image: any) => void;
  imageData: string;
  imageUrl: string;
}


interface IState {
  revision: CMSRevisionModel;
  meta: CMSMetaModel;
  openMediaLibrary: boolean;
  model: string;
  publishDate: string;
  contentChanged: boolean;
  drawerIsOpen: boolean;
  dragging: boolean;
  droppedFile: File | null;
  pageStatus: 'loading' | 'ready';
}

export class CmsEdit extends Component<IProps, IState> {
  fileInput: any;
  imageHelper = new ImageHelper();
  blogHelper = new BlogHelper();
  cmsHelper = new CMSHelper();
  adminuserHelper = new AdminUserHelper();

  constructor(props: IProps) {
    super(props);


    let meta: CMSMetaModel;
    let body = new CMSRevisionModel();
    meta = new CMSMetaModel();
    meta.Type = -1;


    this.handleBodyChange = this.handleBodyChange.bind(this);
    this.saveDraft = this.saveDraft.bind(this);
    this.closeMediaLibrary = this.closeMediaLibrary.bind(this);
    this.drawerMetaChanged = this.drawerMetaChanged.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.deleteContent = this.deleteContent.bind(this)

    this.state = {
      openMediaLibrary: false,
      meta,
      revision: body,
      model: '',
      publishDate: '',
      contentChanged: false,
      drawerIsOpen: false,
      dragging: false,
      droppedFile: null,
      pageStatus: 'loading'
    };
  }

  async componentDidMount() {
    let id = getQuery('q', this.props.location.search);
    if (id != null) {
      await this.fetchData(id);
    } else {
      SnackbarManager.Instance.addError('could not fetch data for the selected content');
    }
    this.setState({ pageStatus: 'ready' });
  }

  componentDidUpdate() {
    if (this.state.contentChanged) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  async fetchData(id: string) {
    let meta: CMSMetaModel | null = await this.cmsHelper.getContentById(id);
    if (meta == null) {
      SnackbarManager.Instance.addError('could not load the data');
      return;
    }
    meta = new CMSMetaModel(meta);

    let date = 'page is not published';

    if (meta.PrimaryRevision != null || meta.PrimaryRevision !== '') {
      let revision = meta.getPrimaryRevision();
      if (revision) {
        date = revision.Created.toString();
      }
    }
    meta.MarkedForPublish = true;
    let rev: CMSRevisionModel = new CMSRevisionModel(meta.getNewestRevision() as CMSRevisionModel);
    this.setState({ revision: rev, meta, publishDate: date });
  }

  openSettings() {
    this.setState({ drawerIsOpen: true });
  }

  handleBodyChange(text: string) {
    let revision = this.state.revision;
    if (revision.Text === text) {
      revision.Text = text;
      this.setState({ revision });
    } else {
      revision.Text = text;
      this.setState({ revision, contentChanged: true });
    }
  }

  async deleteContent() {
    let response = await this.cmsHelper.deleteContent(this.state.meta.Id);
    if (response === false) {
      SnackbarManager.Instance.addWarning('Could not delete content');
      return;
    }
    SnackbarManager.Instance.addSuccess('Content deleted');
    this.props.history.push({ pathname: '/admin/sitemap', state: { deleted: this.state.meta.Title } });
  }

  async saveDraft() {
    let meta = this.state.meta;
    let rev = this.state.revision;
    if ((meta.Type === CMSTypes.Blog || meta.Type === CMSTypes.Course) && meta.MarkedForPublish && !rev.CoverImageUrl) {
      return SnackbarManager.Instance.addWarning('Cover image required before publishing');
    }

    rev.Action = CMSActions.SaveDraft;
    let response = await this.cmsHelper.saveDraft(this.state.meta, rev);

    if (response == null || response === false) {
      SnackbarManager.Instance.addError('Save failed..')
    } else {
      this.setState({ contentChanged: false });
      if (this.state.meta.MarkedForPublish) {
        return SnackbarManager.Instance.addSuccess('Page published');
      }
      SnackbarManager.Instance.addDefault('Draft saved');
    }
  }

  async closeMediaLibrary(image: { Id: string, Url: string }) {
    if (image == null || image.Id === '') {
      this.setState({ openMediaLibrary: false });
      return
    }

    if (image && image.Url) {
      let body = this.state.revision;
      body.CoverImageId = image.Id;
      body.CoverImageUrl = image.Url;

      return this.setState({
        openMediaLibrary: false, revision: body, contentChanged: true
      });
    }

    let body = this.state.revision;
    body.CoverImageId = image.Id;
    body.CoverImageUrl = image.Url;
    return this.setState({ openMediaLibrary: false, revision: body, contentChanged: true });
  }

  onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ dragging: true });
  }

  dragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ dragging: true });
  }

  dragExit = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ dragging: false });
  }

  fileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();

    if (!event.dataTransfer.files || event.dataTransfer.files.length !== 1) {
      return this.setState({ dragging: false });
    }

    this.setState({ dragging: false, droppedFile: event.dataTransfer.files[0], openMediaLibrary: true });
  }


  headerImage() {
    if (this.state.meta.Type !== CMSTypes.Blog && this.state.meta.Type !== CMSTypes.Course) return;

    let orPart = (
      <>
        <br />
        <p className="margin-8">OR</p>
        <Button
          variant="contained"
          className="success"
          onClick={() => this.setState({ openMediaLibrary: true })}
        >
          Choose from library
        </Button>
      </>
    )

    if (!this.state.revision.CoverImageUrl || this.state.revision.CoverImageUrl === '') {
      return (<div className="blogHeaderImage"
        onDragEnter={this.dragEnter}
        onDragExit={this.dragExit}
        onDragOver={this.onDragOver}
        onDrop={this.fileDrop}
      >
        <div className="blogHeaderImage_placeholder">
          <div className="blogHeaderImage_placeholder_text">
            <i className="far fa-image fa-6x" />
            <h2 className="no-margin-bottom">Drop image here</h2>
            {!this.state.dragging && orPart}
          </div>
        </div>
      </div>)
    }
    let divStyle = {
      color: 'white',
      backgroundImage: 'url(' + configureCloudinaryUrl(this.state.revision.CoverImageUrl, ['w_850']) + ')',
      WebkitTransition: 'all', // note the capital 'W' here
      msTransition: 'all' // 'ms' is the only lowercase vendor prefix
    };

    return (<div className="blogHeaderImage" style={divStyle}>
      <Button variant="outlined" className="replaceImage" onClick={() => this.setState({ openMediaLibrary: true })}>
        Replace image
      </Button>
    </div>)
  }

  async drawerMetaChanged(field: string, value: any) {
    let meta = this.state.meta;
    meta[field] = value;
    await this.setState({ meta });
  }

  closeDrawer() {
    this.setState({ drawerIsOpen: false });
  }

  renderAuthor() {
    if (this.state.meta.Type !== CMSTypes.Blog) return;

    let authorImg = '';
    let authorName = '';
    if (this.state.meta.Author) {
      authorImg = configureCloudinaryUrl(this.state.meta.Author.ProfileImageUrl, ['w_300'])
      authorName = this.state.meta.Author.Name;
    }

    return (
      <div className="authorInfo">
        <Avatar className="authorImage" src={authorImg} />
        <div className="nameAndDate">
          <p>{authorName}</p>
          <h6>{this.state.publishDate}</h6>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.pageStatus === 'loading') return <></>;

    return (
      <div className="blogPost">
        <div className="blogWrapper">
          {this.headerImage()}
          <PhotoLibrary
            open={this.state.openMediaLibrary}
            close={this.closeMediaLibrary}
            accessSite="blog"
            droppedFile={this.state.droppedFile}
          />
          <div className="blogContent">
            <div className="blogHeader">
              <h1>{this.state.meta.Title}</h1>
              {this.renderAuthor()}

            </div>
            <div className="blogText">
              <textarea className="titleInput" value={this.state.revision.Text} onChange={(e) => this.handleBodyChange(e.target.value)} style={{ width: "100%", height: "700px" }} />
              <button className="saveButton" onClick={this.saveDraft}>Save</button>
            </div>
          </div>
        </div>


        <Prompt
          when={this.state.contentChanged}
          message={`You have unsaved content, are you sure you want to leave the page?`}
        />
        <MetaDrawer
          open={this.state.drawerIsOpen}
          closeDrawer={this.closeDrawer}
          metaData={this.state.meta}
          shouldPublish={this.state.meta.MarkedForPublish ? 1 : 0}
          metaChanged={this.drawerMetaChanged}
          togglePublish={(e) => {
            let meta = this.state.meta;
            meta.MarkedForPublish = (e === 1);
            this.setState({ meta });
          }}
          deleteContent={this.deleteContent}
        />
      </div>
    );
  }
}
