// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import qs from 'query-string';
import { Search } from "history";

//TODO: Use this function instead of qs
export function getQuery(name: string, search: Search) {
  let query = qs.parse(search)[name];
  if (typeof query !== 'string') {
    query = query?.[0];
  }
  return query;
}