import React, { Component } from "react";
import { TextField, MenuItem, Grid, Button } from "@material-ui/core";
import { CountryHelper } from "../../../../../../Helpers/CountryHelper";
import { SavingSnackbar } from "../../../../../Parts/General/SavingSnackbar";
import { CountryModel } from "../../../../../../Models/CountryModel";
import { UserHelper } from "../../../../../../Helpers/UserHelper";
import { PageLoader } from "../../../../../Parts/General/PageLoader";
import {
  LanguageHelper,
  getLanguage
} from "../../../../../../Helpers/LanguageHelper";
import {
  setUserData,
  getCurrentUser
} from "../../../../../../Helpers/SessionHelper";
import { KnownLanguageModel } from "../../../../../../Models/KnownLanguageModel";
import { StorageHelper, facadeLocalNames } from "../../../../../../Helpers/StorageHelper";
import { LoginModel } from "../../../../../../Models/LoginModel";
import { englishLanguageId } from "../../../../../../Helpers/Cfg";
import { OptimizedTextField } from "../../../../../Parts/General/OptimizedTextField";
import { getPhoneCountryCode } from "../../../../../../Helpers/GenericHelper";
import { PhoneField } from "../../../../../Parts/General/PhoneField";
import { SnackbarManager } from "../../../../../../Helpers/SnackbarManager/SnackbarManager";

interface IProps {
  creatingUser: boolean;
  saveStep?: ((stepBasicComplete: boolean) => void);
}

interface IState {
  existingUser: LoginModel;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  gender: 1 | 2;
  streetName: string;
  zipCode: string;
  city: string;
  activeCountryId: string;
  countries: CountryModel[];
  birthdayDay: string;
  birthdayMonth: string;
  birthdayYear: string;
  days: string[];
  months: string[];
  years: string[];
  editing: boolean;
  showPageLoader: boolean;
  activeLanguageId: string;
  languages: KnownLanguageModel[];
  nationalities: CountryModel[];
  countryNames: string[];
  nationality: string;
  firstNameError: boolean;
  lastNameError: boolean;
  emailError: boolean;
  mobilePhoneError: boolean;
  genderError: boolean;
  addressError: boolean;
  zipCodeError: boolean;
  cityError: boolean;
  countryError: boolean;
  languageError: boolean;
  birthdayError: boolean;
  nationalityError: boolean;
}

export class FreelancerStepBasic extends Component<IProps, IState> {
  countryHelper = new CountryHelper();
  userHelper = new UserHelper();
  languageHelper = new LanguageHelper();
  storageHelper = new StorageHelper();
  phoneRef = React.createRef<PhoneField>();

  constructor(props: IProps) {
    super(props);

    this.state = {
      existingUser: new LoginModel({}),
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      gender: 1,
      streetName: "",
      zipCode: "",
      city: "",
      activeCountryId: "",
      countries: [],
      birthdayDay: "",
      birthdayMonth: "",
      birthdayYear: "",
      days: [],
      months: [],
      years: [],
      editing: false,
      showPageLoader: false,
      activeLanguageId: "",
      languages: [],
      firstNameError: false,
      lastNameError: false,
      emailError: false,
      mobilePhoneError: false,
      genderError: false,
      addressError: false,
      zipCodeError: false,
      cityError: false,
      countryError: false,
      languageError: false,
      birthdayError: false,
      nationalityError: false,
      nationalities: [],
      nationality: '',
      countryNames: [],
    };

    this.birthdayMenuItems = this.birthdayMenuItems.bind(this);
    this.getUser = this.getUser.bind(this);
    this.updateUserInfo = this.updateUserInfo.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.creatingUser = this.creatingUser.bind(this)
    this.changeCountryField = this.changeCountryField.bind(this)
    this.getNationalities = this.getNationalities.bind(this)
    this.changeNationality = this.changeNationality.bind(this)
  }

  async componentDidMount() {
    this.birthdayMenuItems();
    await this.getNationalities();
    await this.getUser();
  }

  async getUser() {
    let user = getCurrentUser();

    if (!user || !user.Freelancer) return;

    let address = user.Freelancer.AddressLine1;
    if (user.Freelancer.AddressLine2 && user.Freelancer.AddressLine2 !== "") {
      address = address.concat(" ");
      address = address.concat(user.Freelancer.AddressLine2);
    }

    let countryId = user.Freelancer.CountryId;
    let countries = this.storageHelper.getCountriesFromLocal();
    let activeCountry = countries.find(x => x.Id === countryId);

    let birthdayString = user.Freelancer.Birthday;
    let birthdayArray = birthdayString.split("-", 3);
    let year = birthdayArray[0];
    let month = birthdayArray[1];
    let day = birthdayArray[2];

    let languages = [];
    let localLang = await this.languageHelper.getLocalLanguagesForCountry(countryId, false);
    if (localLang) {
      for (const lang of localLang) {
        let knownLanguage = await this.languageHelper.getLanguageFromKnownLanguages(
          lang.LanguageId
        );
        languages.push(knownLanguage[0]);
      }

      //English - Global
      let globalLang = this.storageHelper.getLanguageFromLocal(englishLanguageId);
      if (globalLang == null) {
        globalLang = (await this.languageHelper.getLanguageFromKnownLanguages(englishLanguageId))[0];
      }
      languages.push(globalLang);
    }

    let nationality = '';
    for (const i of this.state.nationalities) {
      if (i.Id === user.Freelancer.NationalityCountryId) {
        nationality = i.CountryName;
      }
    }

    await this.setState({
      existingUser: user,
      firstName: user.Firstnames,
      lastName: user.Lastname,
      email: user.Email,
      phoneNumber: user.Freelancer.MobilePhone,
      gender: user.Freelancer.Gender,
      streetName: address,
      zipCode: user.Freelancer.ZipCode,
      city: user.Freelancer.City,
      activeCountryId: activeCountry ? activeCountry.Id : "",
      countries: countries || [],
      birthdayDay: day,
      birthdayMonth: month,
      birthdayYear: year,
      activeLanguageId: user.PrimaryLanguage,
      languages: languages,
      nationality
    });
  }

  async birthdayMenuItems() {
    let days = [];
    for (let i = 1; i <= 31; i++) {
      if (i < 10) days.push("0" + i.toString());
      else days.push(i.toString());
    }

    let months = [];
    for (let i = 1; i <= 12; i++) {
      if (i < 10) months.push("0" + i.toString());
      else months.push(i.toString());
    }

    let currentYear = new Date().getFullYear() - 17;
    let year = [];
    for (let i = 1950; i <= currentYear; i++) {
      year.push(i.toString());
    }

    this.setState({ days: days, months: months, years: year });
  }

  async getNationalities() {
    let nationalities: CountryModel[] = this.state.nationalities;
    let countryNames = [];

    if (nationalities.length === 0) {
      nationalities = await this.storageHelper.getAllCountriesFromLocal();
      if (!nationalities || nationalities.length === 0) {
        nationalities = await this.countryHelper.getNationalities();
        this.storageHelper.setAllCountriesInLocal(JSON.stringify(nationalities));
      }

      for (const i of nationalities) {
        countryNames.push(i.CountryName)
      }

      countryNames = countryNames.sort((a, b) => a < b ? -1 : 1);
      this.setState({ nationalities, countryNames })
    }
  }

  changeNationality(e: { value: string, label: string }) {
    if (e) {
      this.setState({ nationality: e.value, editing: true })
    }
  }


  async resetForm() {
    this.setState({ editing: false });
    await this.getUser();
  }


  async updateUserInfo() {
    this.setState({ showPageLoader: true, editing: false });
    let updatedUser = this.state.existingUser;
    let countries = this.state.countries;
    let birthday =
      this.state.birthdayYear +
      "-" +
      this.state.birthdayMonth +
      "-" +
      this.state.birthdayDay;

    let activeCountryId = "";
    let activeCountry = null;

    for (const country of countries) {
      if (country.Id === this.state.activeCountryId) {
        activeCountryId = country.Id;
        activeCountry = country;
      }
    }
    let nationalityId = '';
    for (const i of this.state.nationalities) {
      if (i.CountryName === this.state.nationality) {
        nationalityId = i.Id
      }
    }

    if (updatedUser && updatedUser.Freelancer) {
      updatedUser.Freelancer.Firstnames = this.state.firstName;
      updatedUser.Freelancer.Lastname = this.state.lastName;
      updatedUser.Freelancer.BirthDate = birthday;
      updatedUser.Freelancer.Birthday = birthday;
      if (this.phoneRef.current) {
        updatedUser.Freelancer.MobilePhone = this.phoneRef.current.getRawPhoneInput();
      }
      updatedUser.Freelancer.Gender = this.state.gender;
      updatedUser.Freelancer.AddressLine1 = this.state.streetName;
      updatedUser.Freelancer.ZipCode = this.state.zipCode;
      updatedUser.Freelancer.City = this.state.city;
      updatedUser.Freelancer.CountryId = activeCountryId;
      updatedUser.Freelancer.Country = activeCountry;
      updatedUser.PrimaryLanguage = this.state.activeLanguageId;
      updatedUser.Firstnames = this.state.firstName;
      updatedUser.Lastname = this.state.lastName;
      updatedUser.Freelancer.NationalityCountryId = nationalityId;
    }



    await this.setState({
      firstNameError: false,
      lastNameError: false,
      emailError: false,
      mobilePhoneError: false,
      genderError: false,
      addressError: false,
      zipCodeError: false,
      cityError: false,
      countryError: false,
      languageError: false,
      birthdayError: false,
      nationalityError: false,
    })


    let success;
    let stepBasicComplete = await this.userHelper.verifyStepBasicComplete(updatedUser);
    if (typeof stepBasicComplete !== 'boolean' || !this.state.activeLanguageId || stepBasicComplete !== true) {
      let failString = '';
      for (const fail of stepBasicComplete) {
        failString = failString.concat(getLanguage(fail, "step basic fail info"))
      }
      switch (stepBasicComplete[0]) {
        case 71:
          await this.setState({ firstNameError: true })
          break;
        case 72:
          await this.setState({ lastNameError: true })
          break;
        case 73:
          await this.setState({ emailError: true })
          break;
        case 75:
          await this.setState({ mobilePhoneError: true })
          break;
        case 76:
          await this.setState({ genderError: true })
          break;
        case 77:
          await this.setState({ addressError: true })
          break;
        case 78:
          await this.setState({ zipCodeError: true })
          break;
        case 79:
          await this.setState({ cityError: true })
          break;
        case 80:
          await this.setState({ countryError: true })
          break;
        case 25:
          await this.setState({ birthdayError: true })
          break;
        case 147:
          await this.setState({ birthdayError: true })
          break;
        case 146:
          await this.setState({ birthdayError: true })
          break;
        case 629:
          await this.setState({ nationalityError: true })
          break;
      }

      if (!this.state.activeLanguageId) {
        await this.setState({ languageError: true });
      }

      SnackbarManager.Instance.addWarning(failString);
      await this.setState({
        showPageLoader: false,
        phoneNumber: this.state.phoneNumber,
        gender: this.state.gender,
        streetName: this.state.streetName,
        zipCode: this.state.zipCode,
        city: this.state.city
      })
      return;
    } else if (stepBasicComplete === true) {
      await this.userHelper.updatePrimaryLanguage(this.state.activeLanguageId);
      success = await this.userHelper.updateFreelancer(updatedUser);
      updatedUser.vmStepBasicDone = true;

      let user = getCurrentUser();
      if (user?.Email !== this.state.email) {
        let updateEmailSuccess = await this.userHelper.updateEmail(this.state.email)

        if (updateEmailSuccess.status === 409) {
          SnackbarManager.Instance.addWarning(getLanguage(618, "Email address is already in use"));
          success = false
        } else if (updateEmailSuccess.status !== 200) {
          SnackbarManager.Instance.addWarning(getLanguage(469, "Unknown error"));
          success = false
        } else {
          if (updatedUser && updatedUser.Freelancer) {
            updatedUser.Email = this.state.email;
            updatedUser.Freelancer.Email = this.state.email;
          }
        }
      }
    }

    if (success) {
      await setUserData(updatedUser);
      this.storageHelper.sendEvent('userUpdated')
      await this.storageHelper.updateFacadeLocalization(facadeLocalNames.country, updatedUser.Freelancer!.Country);
      await this.storageHelper.updateFacadeLocalization(facadeLocalNames.language, this.state.activeLanguageId);
      await this.storageHelper.initializeCaches(true)
      await this.getUser();
      SnackbarManager.Instance.addSuccess(getLanguage(697, 'Changes saved'))

      if (this.props.creatingUser && this.props.saveStep) {
        return this.props.saveStep(true);
      }
    }
    await this.setState({ showPageLoader: false });
  }

  creatingUser() {
    if (this.props.creatingUser) {
      return (
        <div>
          <div className="text-right margin-v-32">
            <Button className="button-gradient" onClick={() => this.updateUserInfo()}>Next</Button>
          </div>
        </div>
      )
    }
    return (
      <div />
    )
  }

  async changeCountry(event: any) {
    let activeCountryId = event.target.value;
    this.setState({ activeCountryId, editing: true, activeLanguageId: '', languageError: true });

    let selectedCountry = this.state.countries.find(x => x.Id === activeCountryId);
    if (selectedCountry == null) return;

    let localData = this.storageHelper.getFacadeLocalization();
    if (!localData) return;

    let countryLanguages = await this.languageHelper.getLocalLanguagesForCountry(selectedCountry!.Id, false)

    let globalLang = await this.languageHelper.getLanguageFromKnownLanguages(englishLanguageId);
    let languages: KnownLanguageModel[] = globalLang;
    for (const countryLang of countryLanguages) {
      if (countryLang.CountryId === selectedCountry.Id) {
        let lang = localData.knownLanguages.find(x => x.Id === countryLang.LanguageId);
        if (lang) {
          languages.push(lang);
        }
      }
    }
    await this.setState({ languages });

  }



  changeCountryField() {
    let currentUser = getCurrentUser();
    if (currentUser && currentUser.Freelancer && currentUser.Freelancer.CountHired >= 1) {
      return (
        <TextField
          disabled={true}
          id="outlined-country"
          select
          label={getLanguage(80, "Country")}
          fullWidth
          margin="normal"
          variant="outlined"
          error={this.state.countryError}
          value={this.state.activeCountryId}
          onChange={event => this.changeCountry(event)}
        >
          {this.state.countries.map((row, index) => (
            <MenuItem value={row.Id} key={index}>
              {row.CountryName}
            </MenuItem>
          ))}
        </TextField>
      )
    } else {
      return (
        <TextField
          id="outlined-country"
          select
          label={getLanguage(80, "Country")}
          fullWidth
          margin="normal"
          variant="outlined"
          error={this.state.countryError}
          value={this.state.activeCountryId}
          onChange={event => this.changeCountry(event)}
        >
          {this.state.countries.map((row, index) => (
            <MenuItem value={row.Id} key={row.Id}>
              {row.CountryName}
            </MenuItem>
          ))}
        </TextField>
      )
    }
  }

  render() {
    let savingSnackBar: any;

    if (this.state.editing === true && !this.props.creatingUser) {
      savingSnackBar = (
        <SavingSnackbar
          open={this.state.editing}
          resetClicked={this.resetForm}
          saveClicked={this.updateUserInfo}
        />
      );
    }

    let nextButton = this.creatingUser();

    return (
      <div>
        <TextField
          type="text"
          label={getLanguage(71, "First name")}
          variant="outlined"
          fullWidth
          margin="normal"
          error={this.state.firstNameError}
          value={this.state.firstName}
          onChange={event =>
            this.setState({ firstName: event.target.value, editing: true })
          }
        />
        <TextField
          type="text"
          label={getLanguage(72, "Last name")}
          variant="outlined"
          fullWidth
          margin="normal"
          error={this.state.lastNameError}
          value={this.state.lastName}
          onChange={event =>
            this.setState({ lastName: event.target.value, editing: true })
          }
        />
        <TextField
          type="email"
          label={getLanguage(73, "Email")}
          variant="outlined"
          fullWidth
          margin="normal"
          error={this.state.emailError}
          value={this.state.email}
          onChange={event =>
            this.setState({ email: event.target.value, editing: true })
          }
        />

        <div className="phoneInput">
          <label id="phoneInputLabel">{getLanguage(75, "Phone number")}</label>
          <PhoneField
            ref={this.phoneRef}
            countryCode={getPhoneCountryCode(this.state.countries, this.state.activeCountryId, this.storageHelper)}
            value={this.state.phoneNumber}
            onChange={(val) => this.setState({ phoneNumber: val, editing: true })}
            onFocus={(val) => this.setState({ phoneNumber: val })}
          />
        </div>
        <TextField
          id="outlined-gender"
          select
          label={getLanguage(76, "Gender")}
          fullWidth
          margin="normal"
          variant="outlined"
          error={this.state.genderError}
          value={this.state.gender}
          onChange={event =>
            this.setState({ gender: Number(event.target.value) as 1 | 2, editing: true })
          }
        >
          <MenuItem value={"1"}>{getLanguage(770, "Male")}</MenuItem>
          <MenuItem value={"2"}>{getLanguage(771, "Female")}</MenuItem>
        </TextField>
        <TextField
          type="text"
          label={getLanguage(77, "Address")}
          variant="outlined"
          fullWidth
          margin="normal"
          error={this.state.addressError}
          value={this.state.streetName}
          onChange={event =>
            this.setState({ streetName: event.target.value, editing: true })
          }
        />
        <TextField
          label={getLanguage(78, "Zip code")}
          variant="outlined"
          fullWidth
          margin="normal"
          error={this.state.zipCodeError}
          value={this.state.zipCode}
          onChange={event =>
            this.setState({ zipCode: event.target.value, editing: true })
          }
        />
        <TextField
          type="text"
          label={getLanguage(79, "City")}
          variant="outlined"
          fullWidth
          margin="normal"
          error={this.state.cityError}
          value={this.state.city}
          onChange={event =>
            this.setState({ city: event.target.value, editing: true })
          }
        />
        {this.changeCountryField()}
        <div className="relative">
          <OptimizedTextField
            className="margin-v-16"
            select
            label={getLanguage(537, "Citizenship")}
            value={this.state.nationality}
            onChange={e => this.setState({ nationality: e.target.value, editing: true })}
            error={this.state.nationalityError}
          >
            {this.state.countryNames.map((countryName: string) => (
              <MenuItem key={countryName} value={countryName}>{countryName}</MenuItem>
            ))}
          </OptimizedTextField>
        </div>
        <TextField
          id="outlined-language"
          select
          label={getLanguage(81, "Primary language")}
          fullWidth
          margin="normal"
          variant="outlined"
          error={this.state.languageError}
          value={this.state.activeLanguageId}
          onChange={event =>
            this.setState({
              activeLanguageId: event.target.value,
              editing: true,
              languageError: false
            })
          }
        >
          {this.state.languages.map((row, index) => (
            <MenuItem value={row.Id} key={index}>
              {row.LanguageCode ? getLanguage(row.LanguageCode, "Language name") : ""}
            </MenuItem>
          ))}
        </TextField>
        {/* <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              id="outlined-day"
              select
              label={getLanguage(82, "Birthday")}
              fullWidth
              margin="normal"
              variant="outlined"
              error={this.state.birthdayError}
              value={this.state.birthdayDay}
              onChange={event =>
                this.setState({
                  birthdayDay: event.target.value,
                  editing: true
                })
              }
            >
              {this.state.days.map((row, index) => (
                <MenuItem value={row} key={index}>
                  {row}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-month"
              select
              label={getLanguage(83, "month")}
              fullWidth
              margin="normal"
              variant="outlined"
              error={this.state.birthdayError}
              value={this.state.birthdayMonth}
              onChange={event =>
                this.setState({
                  birthdayMonth: event.target.value,
                  editing: true
                })
              }
            >
              {this.state.months.map((row, index) => (
                <MenuItem value={row} key={index}>
                  {row}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-Year"
              select
              label={getLanguage(84, "Year")}
              fullWidth
              margin="normal"
              variant="outlined"
              error={this.state.birthdayError}
              value={this.state.birthdayYear}
              onChange={event =>
                this.setState({
                  birthdayYear: event.target.value,
                  editing: true
                })
              }
            >
              {this.state.years.map((row, index) => (
                <MenuItem value={row} key={index}>
                  {row}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid> */}
        {nextButton}
        {savingSnackBar}
        {this.state.showPageLoader ? <PageLoader /> : null}
      </div>
    );
  }
}
