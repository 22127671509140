// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { Footer } from "./Navigation/Footer";
import { Button, Grid, TextField, Hidden } from "@material-ui/core";
import "./Frontpage.scss";
import blobLarge from "../../../assets/img/shapes/blobLarge.svg";
import blobMedium from "../../../assets/img/shapes/blobMedium.svg";
import blobMediumTwo from "../../../assets/img/shapes/blobMedium_2.svg";
import heroImage from "../../../assets/img/photos/heroImage@2x.png";
import { NavLink } from "react-router-dom";
import { getLanguage } from "../../../Helpers/LanguageHelper";
import { CaseCarousel } from "./Home/parts/CaseCarousel";
import { FeaturePreview } from "./Home/parts/FeaturePreview";
import { CommunityCompany } from "./Home/parts/CommunityCompany";
interface IProps extends RouteComponentProps { }
interface IState { }

export class Frontpage extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    return (
      <div className="Frontpage">
        <div className="content">
          <div className="hero">
            <Hidden mdUp>
              <img
                className="heroImage_mobile"
                src="/img/photos/companyHeroImage_mobile.png"
                alt="Product image"
              />
            </Hidden>
            <div className="container">
              <Grid container>
                <Grid item xs={12} lg={5}>
                  <h1 className="tagline">
                    {getLanguage(349, "Hire waiting and kitchen freelancers 24/7")}
                  </h1>
                  <p className="heroContent">
                    {getLanguage(350, "Describe what you need, receive applications, and choose your favourites.")}

                  </p>
                  <div className="inputGroup">
                    <Button
                      className="button-gradient inputGroup_item button"
                      variant="contained"
                      size="medium"
                      href="register"
                    >
                      <span className="btn-icon">
                        <i className="fas fa-arrow-right" />
                      </span>
                      {getLanguage(351, "Get started!")}
                    </Button>
                  </div>
                </Grid>
                <Hidden smDown>
                  <Grid item lg={7}>
                    <img className="heroImage" src={heroImage} alt="Product image" />
                  </Grid>
                </Hidden>
              </Grid>
            </div>
          </div>
          <section className="section_light">
            <Hidden smDown>
              <img className="blob blobMedium blobSegments" src={blobMediumTwo} alt="svg blob" />
            </Hidden>
            <div className="container">
              <div className="sectionHeading">
                {getLanguage(353, "Hire waiting and kitchen freelancers for all occasions")}
              </div>
              <Grid
                container
                spacing={5}
                justify="space-between"
                alignItems="stretch"
              >
                <Grid item lg={4}>
                  <NavLink to="/events">
                    <div className="segmentCard">
                      <div className="cardImage cardImage_events" />
                      <div className="padding-24">
                        <div className="cardTitle">{getLanguage(354, "Events")}</div>
                        <div className="cardContent">
                          {getLanguage(355, "Using Chabber for your events you'll always be guarantied waiting and kitchen freelancers 24/7. That way you'll always be fully staffed and have time for your guests.")}
                        </div>
                        <div className="cardCTA">
                          {getLanguage(356, "Read more")}
                          <span>
                            <i className="fas fa-arrow-right brand" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </Grid>
                <Grid item lg={4}>
                  <NavLink to="/kitchens">
                    <div className="segmentCard">
                      <div className="cardImage cardImage_kitchen" />
                      <div className="padding-24">
                        <div className="cardTitle">{getLanguage(357, "Professional kitchens")}</div>
                        <div className="cardContent">
                          {getLanguage(358, "Use Chabber to find freelancers for your kitchens. We offer everything form professional chefs to kitchen hands. All fully covered by our work insurance.")}
                        </div>
                        <div className="cardCTA">
                          {getLanguage(356, "Read more")}
                          <span>
                            <i className="fas fa-arrow-right brand" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </Grid>
                <Grid item lg={4}>
                  <NavLink to="/privatefunctions">
                    <div className="segmentCard">
                      <div className="cardImage cardImage_private" />
                      <div className="padding-24">
                        <div className="cardTitle">{getLanguage(359, "Private parties")}</div>
                        <div className="cardContent">
                          {getLanguage(360, "")}
                        </div>
                        <div className="cardCTA">
                          {getLanguage(356, "Read more")}
                          <span>
                            <i className="fas fa-arrow-right brand" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </Grid>
              </Grid>
            </div>
          </section>
          <section className="section_light">
            <div className="container">
              <CommunityCompany />
            </div>
          </section>
          <section className="section_light">
            <img className="blob blobLarge blobVideo" src={blobLarge} alt="svg blob" />
            <div className="container">
              <div className="sectionHeading">{getLanguage(361, "This is what our customers say")}</div>
              <CaseCarousel />
            </div>
          </section>
          <section className="section_light">
            <img className="blob blobMedium blobFeatures" src={blobMedium} alt="svg blob" />
            <FeaturePreview />
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}
