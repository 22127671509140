import { AxiosRequestConfig } from 'axios';
import { StorageHelper } from './StorageHelper';
import { MessageModel } from '../Models/MessageModel';
import { CallManager } from './CallManager/CallManager';




export class ChatHelper {
    storageHelper = new StorageHelper();
    clientKey = '04216571796E48ABB8FD781DEA966A4F';


    async createChatNetworkForPosting(postingId: string) {
        try {
            let authToken = this.storageHelper.getAccessToken();

            let config: AxiosRequestConfig = {
                method: 'POST',
                url: '/api/messagenetwork',
                headers: {
                    'Content-Type': 'application/json',
                    clientKey: this.clientKey,
                    authToken
                },
                data: { PostingId: postingId }
            }
            let response = await CallManager.Instance.makeCall(config, false);
            return response.data;
        } catch (error: any) {
            console.log(error);
            return false;
        }
    }



    async chatMessageNetworkMembers(postingId: string, loginIds: string[]) {
        try {
            let config: AxiosRequestConfig = {
                method: 'POST',
                url: '/api/messagenetworkmember',
                headers: {
                    'Content-Type': 'application/json',
                    clientKey: this.clientKey,
                },
                data: {
                    PostingId: postingId,
                    LoginIds: loginIds
                }
            }
            let response = await CallManager.Instance.makeCall(config, false, false);
            return response.data;
        } catch (error: any) {
            console.log(error);
            return false;
        }
    }




    async getAllMessageNetworksAndMessages() {
        try {
            let config: AxiosRequestConfig = {
                method: 'GET',
                url: '/api/messagenetwork',
                headers: {
                    'Content-Type': 'application/json',
                    clientKey: this.clientKey
                }
            }
            let response = await CallManager.Instance.makeCall(config, false);
            return response.data;
        } catch (error: any) {
            console.log(error);
            return false;
        }
    }


    async getMessageNetworkDetails(id: string) {
        try {
            let config: AxiosRequestConfig = {
                method: 'GET',
                url: '/api/messagenetwork?id=' + id,
                headers: {
                    'Content-Type': 'application/json',
                    clientKey: this.clientKey
                }
            }
            let response = await CallManager.Instance.makeCall(config, false);
            return response.data;
        } catch (error: any) {
            console.log(error);
            return false;
        }
    }


    async getMessages(id: string) {
        try {
            let config: AxiosRequestConfig = {
                method: 'GET',
                url: '/api/message?MessageNetworkId=' + id,
                headers: {
                    'Content-Type': 'application/json',
                    clientKey: this.clientKey,
                }
            }
            let response = await CallManager.Instance.makeCall(config, false);
            return response.data;
        } catch (error: any) {
            console.log(error);
            return false;
        }
    }



    async createMessage(message: MessageModel) {
        try {
            let config: AxiosRequestConfig = {
                method: 'POST',
                url: '/api/message',
                headers: {
                    'Content-Type': 'application/json',
                    clientKey: this.clientKey,
                },
                data: {
                    DateTime: message.DateTime,
                    FreelancerProfileId: message.FreelancerProfileId,
                    CompanyProfileId: message.CompanyProfileId,
                    Id: message.Id,
                    MessageNetworkId: message.MessageNetworkId,
                    SenderId: message.SenderId,
                    Text: message.Text,
                }
            }
            let response = await CallManager.Instance.makeCall(config, false);
            return response.data;
        } catch (error: any) {
            console.log(error);
            return false;
        }
    }



    async removeChabberFromMessageNetwork(loginId: string, postingId: string) {
        try {
            let config: AxiosRequestConfig = {
                method: 'PUT',
                url: '/api/messagenetworkmember/' + postingId,
                headers: {
                    'Content-Type': 'application/json',
                    clientKey: this.clientKey,
                },
                data: {
                    attributes: { ActiveStatus: 5 },
                    parameters:
                    {
                        LoginId: loginId
                    }
                }
            }

            let response = await CallManager.Instance.makeCall(config, false, false);

            return response.data;
        } catch (error: any) {
            console.log(error);
            return false;
        }
    }



}