import React, { Component } from "react";
import { Grid, Button } from "@material-ui/core";
import { getLanguage } from "../../../../Helpers/LanguageHelper";
import { UserHelper } from "../../../../Helpers/UserHelper";
interface IProps {
  confirmFirstJob: () => void;
  isConfirming: boolean;
  hidden?: boolean;
  location: any;
}
interface IState {}
export class StopAccountDelete extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.performStopAccountDelete();
  }

  async performStopAccountDelete() {
    const userHelper = new UserHelper();

    await userHelper.stopAccountDelete(this.props.location.pathname.split("/")[2]);
  }

  render() {
    return (
      <div hidden={this.props.hidden} className="stopAccountDelete">
        <div className="paper radius-6 padding-24 margin-bottom-16">
          <Grid container spacing={1} justify="space-between" alignItems="center">
            <Grid item>
              <h2 className="margin-0">{getLanguage(911, "We'll keep your account intact and hope to see you soon!")}</h2>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
