import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { Avatar, Grid } from '@material-ui/core';
import { CMSHelper } from '../../../../../../Helpers/CMSHelper';
import { CMSRevisionModel } from '../../../../../../Models/CMSRevisionModel';
import { CMSMetaModel } from '../../../../../../Models/CMSMetaModel';

import "./BlogView.scss";
import { BlogHelper } from '../../../../../../Helpers/BlogHelper';
import { SnackbarManager } from '../../../../../../Helpers/SnackbarManager/SnackbarManager';
import { getCurrentAdminUser } from '../../../../../../Helpers/SessionHelper';
import { getLanguage } from '../../../../../../Helpers/LanguageHelper';
import { configureCloudinaryUrl } from '../../../../../../Helpers/ImageHelper';


interface IProps extends RouteComponentProps { }

export function BlogView(props: IProps) {
  let cmsHelper = new CMSHelper();
  let blogHelper = new BlogHelper();

  const [body, setBody] = useState(new CMSRevisionModel());
  const [meta, setMeta] = useState(new CMSMetaModel());
  const [publishDate, setPublishDate] = useState('');
  const [recent, setRecent] = useState<CMSMetaModel[]>([]);


  useEffect(() => {
    let pathName = window.location.pathname.replace('/blog/', '');
    let id = pathName.split('/')[0];

    if (id != null) {
      fetchData(id);
    } else {
      SnackbarManager.Instance.addError('could not load the blogpost')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function scrollToTopFunction() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  async function fetchData(id: string) {
    scrollToTopFunction();

    let post = await cmsHelper.getContentByPId(id);
    let isAdmin = getCurrentAdminUser();
    if (!isAdmin && post?.isPublished === false) {
      props.history.push('/');
      SnackbarManager.Instance.addError(getLanguage(772, 'Selected blogpost is unavailable'));
      return;
    }

    compareUrl(post);

    let recentPosts = await blogHelper.getRecentPosts();
    recentPosts = recentPosts.filter(x => x.Id !== post!.Id);

    if (post == null) {
      return SnackbarManager.Instance.addError('could not load data from server');
    } else if (post && post.isPublished === false) {
      return props.history.push('/404');
    }
    let date = 'blogpost is not published';
    if (post.PrimaryRevision != null && post.PrimaryRevision !== '') {
      let revision = new CMSMetaModel(post).getPrimaryRevision();
      if (revision && revision.AdminUser) {
        date = revision.Created.toString();
        setBody(revision);
        setMeta(post);
        setPublishDate(date);
        setRecent(recentPosts);
      } else {
        SnackbarManager.Instance.addError('could not load data from server');
        setRecent(recentPosts);
        return;
      }
    } else {
      let rev = new CMSMetaModel(post).getNewestRevision();
      if (!rev) return null;
      setBody(rev);
      setMeta(post);
      setPublishDate(date);
      setRecent(recentPosts);
    }
  }

  function compareUrl(post: CMSMetaModel | null) {
    //@ts-ignore
    if (!post || (props.location.state && props.location.state.redirected === true)) { return }

    let url = '/blog/' + post.PublicId + '/' + post.Title.toLowerCase();
    let actualUrl = props.location.pathname;

    if (url !== actualUrl) {
      props.history.replace(url, { redirected: true });

    }
  }

  function headerImage() {
    if (!body.CoverImageUrl || body.CoverImageUrl === '') {
      return (<div className="blogHeaderImage">
        <div className="blogHeaderImage_placeholder">
          <div className="blogHeaderImage_placeholder_text">
          </div>
        </div>
      </div>)
    }
    let divStyle = {
      color: 'white',
      backgroundImage: 'url(' + configureCloudinaryUrl(body.CoverImageUrl, ['w_' + window.innerWidth]) + ')',
      WebkitTransition: 'all', // note the capital 'W' here
      msTransition: 'all' // 'ms' is the only lowercase vendor prefix
    };

    return (<div className="blogHeaderImage" style={divStyle}> </div>)
  }
  function goToBlog(post: CMSMetaModel) {
    props.history.push('/blog/' + post.PublicId + '/' + post.Title);
    fetchData(post.PublicId);
  }

  function buildRecentArray() {
    let arr = [];
    if (!recent) return [];
    for (const post of recent) {
      arr.push(<Grid item xs={4} key={post.Id} onClick={() => goToBlog(post)}>
        <div className="borderBox radius-6">
          <img className="blogCard_image" src={configureCloudinaryUrl(post.Revisions[0].CoverImageUrl, ['w_300'])} alt="blogCard" />
          <div className="padding-24 blogCard_Content">
            <h2 className="displayFont">{post.Title}</h2>
            <p>{post.Description}</p>
            <h6 className="margin-v-8">{post.Revisions[0].Created}</h6>
          </div>
        </div>

      </Grid>);
    }
    return arr;
  }


  let authorImg = '';
  let authorName = '';
  if (meta.Author) {
    authorImg = configureCloudinaryUrl(meta.Author.ProfileImageUrl, ['w_300'])
    authorName = meta.Author.Name;
  }

  return (
    <div className="blogPost_view">
      {headerImage()}
      <div className="blogWrapper">
        <div className="blogContent">
          <div className="blogHeader">
            <h1 className="displayFont">{meta.Title}</h1>
            <div className="authorInfo">
              <Avatar className="authorImage" src={authorImg} />
              <div className="nameAndDate">
                <p>{authorName}</p>
                <h6>{publishDate}</h6>
              </div>
            </div>
          </div>
          <div className="blogText">
            <div dangerouslySetInnerHTML={{ __html: body.Text }} />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="blogs_recent">
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
            spacing={5}>
            {buildRecentArray()}
          </Grid>
        </div>
      </div>
    </div>
  )

}

