import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { Grid, Dialog, DialogContent } from "@material-ui/core";
import { YouTubeEmbed } from "../../../../Parts/General/YouTubeEmbed";
import { Footer } from "../../Navigation/Footer";
import { getLanguage } from "../../../../../Helpers/LanguageHelper";
interface IProps { }
interface IState {
  dialogOpen: boolean;
}
export class EventCaseWedding extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      dialogOpen: false,
    }
  }
  render() {
    return (
      <div>
        <div className="paper">
          <Grid container spacing={0} alignItems="stretch">
            <Grid item sm={6}>
              <div className="videoCase">
                <div className="caseTitle">
                  {getLanguage(457, "Nana used Chabber for her wedding")}
                </div>
                <div className="caseContent">
                  <i>
                    {getLanguage(458, "I use Chabber so I can focus on enjoying my day and evening with my husband and not worry about the practical stuff")}
                  </i>
                  <br />
                  <div className="text-right">
                    {getLanguage(459, "- Nana, Bride")}
                  </div>
                  <br />
                  {getLanguage(460, "Nana married Seejou in May 2019. They had arranged the wedding reception and party for 87 guests by themselves. Nana wanted to enjoy one of the greatest days of her life. That is why she hired 2 waiters who took care of everything so that she could lean back and have the night of her life.")}
                </div>

                <div className="cardCTA" onClick={() => this.setState({ dialogOpen: true })}>
                  {getLanguage(352, "Watch video")}
                  <span>
                    <i className="fas fa-play fa-fw brand" />
                  </span>
                </div>

              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="videoPoster caseTwo" onClick={() => this.setState({ dialogOpen: true })}>
                <div className="videoPoster_playIcon">
                  <i className="fas fa-play fa-4x"></i>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Dialog open={this.state.dialogOpen} onClose={() => this.setState({ dialogOpen: false })} fullWidth maxWidth="md">
          <DialogContent>
            <YouTubeEmbed videoID="PrRcapzWsno" />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
