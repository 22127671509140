// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import {
  Grid,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Menu,
  MenuItem,
  Chip,
  Tooltip,
  Hidden,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { getLanguage } from "../../../../Helpers/LanguageHelper";
import { PostingHelper } from "../../../../Helpers/PostingHelper";
import { getDateObjectFromDateString } from "../../../../Helpers/StringExtractorHelper";
import { LanguageHelper } from "../../../../Helpers/LanguageHelper";
import { PageLoader } from "../../../Parts/General/PageLoader";
import { NavLink } from "react-router-dom";
import { PostingModel } from "../../../../Models/PostingModel";
import { getCurrentUser } from "../../../../Helpers/SessionHelper";
import { StorageHelper } from "../../../../Helpers/StorageHelper";
import { RequestModel } from "../../../../Models/RequestModel";
import { RatingModel } from "../../../../Models/RatingModel";
import TablePagination from "@material-ui/core/TablePagination";
import { getMoneyOutput } from "../../../../Helpers/LocalizationHelper";
import { MobileSidebarTrigger } from "../../../Parts/General/MobileSidebarTrigger";
import { ChatHelper } from "../../../../Helpers/ChatHelper";
import { MessageNetworksModel } from "../../../../Models/MessageNetworksModel";
import { SkillModel } from "../../../../Models/SkillModel";
import { LoginModel } from "../../../../Models/LoginModel";
import { PostingOptions } from "./PostingOptions";
import { orderPostingsOrRequests } from "../../../../Helpers/PostingHelper";
import {
  nowAsFormatedString,
  getNowAsDbString,
} from "../../../../Helpers/DateTimeHelper";
import { SnackbarManager } from "../../../../Helpers/SnackbarManager/SnackbarManager";
import { resolvePostingSalaryPerHour } from "../../../../Modules/CollectiveAgreement/Utils";
import { format } from "date-fns/esm";
import { parseISO } from "date-fns";
import { AvarageRating } from "../../../Parts/General/AvarageRating";

import { PostingsFilters } from "./PostingsFilters";

interface IProps extends RouteComponentProps {
  archive: boolean;
}

interface IState {
  allPostings: PostingModel[];
  postingsToDisplay: PostingModel[];
  months: string[];
  anchorEl: HTMLElement | null;
  chipsLanguageCode: number[];
  chipType: string;
  anchorElTable: HTMLElement | null;
  activeMenuPosting: PostingModel | null;
  buttonsDisabled: boolean;
  openPreviewDialog: boolean;
  addedId: string;
  showPageLoader: boolean;
  jobHasEnded: boolean;
  noPostings: boolean;
  page: number;
  user: LoginModel | null;
  showAllJobs: boolean;
  overtimeRequests: any[];
  ratings: RatingModel[];
  receivedRatings: RatingModel[];
  requests: RequestModel[];
  countJobs: number;
  messageNetworks: MessageNetworksModel[] | null;
  jobDateFrom?: Date;
  jobDateTo?: Date;
}

export class Postings extends Component<IProps, IState> {
  storageHelper = new StorageHelper();
  postingHelper = new PostingHelper();
  languageHelper = new LanguageHelper();

  constructor(props: IProps) {
    super(props);

    this.state = {
      allPostings: [],
      postingsToDisplay: [],
      months: [],
      anchorEl: null,
      chipsLanguageCode: [],
      chipType: "",
      anchorElTable: null,
      activeMenuPosting: null,
      buttonsDisabled: false,
      openPreviewDialog: false,
      //@ts-ignore
      addedId: this.props.location.state || "",
      showPageLoader: false,
      jobHasEnded: false,
      noPostings: false,
      page: 0,
      user: getCurrentUser(),
      showAllJobs: false,
      overtimeRequests: [],
      ratings: [],
      receivedRatings: [],
      requests: [],
      countJobs: 0,
      messageNetworks: null,
    };

    this.preparePage = this.preparePage.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemoveChip = this.handleRemoveChip.bind(this);
    this.showChip = this.showChip.bind(this);
    this.openSpecificPosting = this.openSpecificPosting.bind(this);
    this.openShiftsFilter = this.openShiftsFilter.bind(this);
    this.confirmedShiftsFilter = this.confirmedShiftsFilter.bind(this);
    this.pastShiftsFilter = this.pastShiftsFilter.bind(this);
    this.setChips = this.setChips.bind(this);
    this.postings = this.postings.bind(this);
    this.archive = this.archive.bind(this);
    this.showAllJobsSwitch = this.showAllJobsSwitch.bind(this);
    this.showAllJobsChanged = this.showAllJobsChanged.bind(this);
  }

  async componentDidMount() {
    await this.preparePage();
  }

  async preparePage(page?: number) {
    let currentUser = getCurrentUser();
    if (!currentUser) {
      return;
    }

    await this.setState({ showPageLoader: true });

    let postings: PostingModel[] = [];

    // Get all future postings for non-archive view
    if (!this.props.archive) {
      let data = await this.postingHelper.getAllPostings();
      if (data) {
        // Filter away all postings having been closed and without any hired
        postings = postings.concat(
          data.filter((t) => !(t.Deactivated && t.AmountHired === 0))
        );
      }

      for (const u of postings) {
        if (!u.Deactivated || (u.Deactivated && u.AmountHired > 0)) {
          u.vmRatingFinished = true;
          u.vmUnreadMessages = false;
        }
      }
    }

    // Get job history including post postings, requests, overtimerequests and ratings
    let jobHistory = await this.postingHelper.getPastPostings(
      page == null ? this.state.page : page,
      this.props.archive,
      true,
      this.state.jobDateFrom,
      this.state.jobDateTo
    );
    // Remove archived if this is the jobs over
    if (!this.props.archive && jobHistory.Jobs) {
      jobHistory.Jobs = jobHistory.Jobs.filter((t) => !t.ArchivedByCompany);
    }
    postings = postings.concat(jobHistory.Jobs);
    postings = postings.filter((x) => x != null);

    // TODO: Eliminate this
    for (const posting of postings) {
      posting.StartAtLocalObject = getDateObjectFromDateString(
        posting.StartAtLocal
      );
      posting.EndAtLocalObject = getDateObjectFromDateString(
        posting.EndAtLocal
      );
    }

    let messageNetworks =
      this.state.messageNetworks == null
        ? await new ChatHelper().getAllMessageNetworksAndMessages()
        : this.state.messageNetworks;

    let months = await this.languageHelper.getMonthsByLanguage(true);

    this.setNewMessagesStatusOnPostings(postings, messageNetworks, currentUser);
    this.setRatingStatusOnPostings(
      postings,
      jobHistory.Requests,
      jobHistory.Ratings
    );

    let postingsToDisplay = await this.filterPostingsForViewing(
      postings,
      this.state.chipsLanguageCode,
      this.state.showAllJobs
    );
    let noPostings =
      postings.length === 0 ||
      (postingsToDisplay.length === 0 && !currentUser!.AdminGranted);

    this.setState({
      allPostings: postings,
      postingsToDisplay,
      overtimeRequests: this.state.overtimeRequests.concat(
        jobHistory.OvertimeRequests
      ),
      ratings: this.state.ratings.concat(jobHistory.Ratings),
      receivedRatings: this.state.receivedRatings.concat(
        jobHistory.ReceivedRatings
      ),
      requests: this.state.requests.concat(jobHistory.Requests),
      countJobs: jobHistory.CountJobs,
      messageNetworks,
      months,
      showPageLoader: false,
      page: page != null ? page : 0,
      noPostings: noPostings,
    });
  }

  async filterPostingsForViewing(
    allPostings: PostingModel[],
    chips: number[],
    showAllJobs: boolean
  ) {
    if (!showAllJobs && !this.props.archive) {
      let currentUser = getCurrentUser();
      allPostings = allPostings.filter(
        (t) => t.OwnerLoginId === currentUser?.Id
      );
    }

    let filteredByChips = await this.filterPostingsBySelectedChips(
      allPostings,
      chips
    );
    if (filteredByChips) {
      allPostings = filteredByChips;
    }
    return allPostings;
  }

  async filterPostingsBySelectedChips(
    postings: PostingModel[],
    chips: number[]
  ) {
    if (chips.length === 0) {
      return undefined;
    }

    let showPostings: PostingModel[] = [];
    let jobsToOrder: PostingModel[] = [];
    let pastJobsToOrder: PostingModel[] = [];

    for (const chipType of chips) {
      if (chipType === 92) {
        // Open shifts
        let addToShowPostings = postings.filter(this.openShiftsFilter);
        for (const i of addToShowPostings) {
          if (jobsToOrder.indexOf(i) !== -1) return;
          jobsToOrder.push(i);
        }
      }
      if (chipType === 47) {
        // Confirmed shift
        let addToShowPostings = postings.filter(this.confirmedShiftsFilter);
        for (const i of addToShowPostings) {
          if (jobsToOrder.indexOf(i) !== -1) return;
          jobsToOrder.push(i);
        }
      }
      if (chipType === 48) {
        // Past shifts
        let addToShowPostings = postings.filter(this.pastShiftsFilter);
        for (const i of addToShowPostings) {
          if (pastJobsToOrder.indexOf(i) !== -1) return;
          pastJobsToOrder.push(i);
        }
      }
    }

    jobsToOrder = (await orderPostingsOrRequests(
      jobsToOrder,
      null,
      true
    )) as PostingModel[];
    for (const i of jobsToOrder) {
      showPostings.push(i);
    }

    pastJobsToOrder = (await orderPostingsOrRequests(
      pastJobsToOrder,
      null,
      false
    )) as PostingModel[];
    for (const i of pastJobsToOrder) {
      showPostings.push(i);
    }

    let currentUser = getCurrentUser();
    if (!this.state.showAllJobs && currentUser && currentUser.Id) {
      showPostings = showPostings.filter(
        (x) => x.OwnerLoginId === currentUser!.Id
      );
    }

    return showPostings;
  }

  async handleChipsChanged(chips: number[]) {
    let postingsToDisplay = await this.filterPostingsForViewing(
      this.state.allPostings,
      chips,
      this.state.showAllJobs
    );
    await this.setState({
      chipsLanguageCode: chips,
      anchorEl: null,
      postingsToDisplay: postingsToDisplay,
    });
  }

  async handleRemoveChip(languageCode: number) {
    let chips = this.state.chipsLanguageCode;
    for (let i = 0; i < chips.length; i++) {
      if (languageCode === chips[i]) {
        chips.splice(i, 1);
      }
    }

    this.handleChipsChanged(chips);
  }

  async postingsCancelled() {
    SnackbarManager.Instance.addSuccess(getLanguage(566, "Job closed"));
    await this.handleClose();
    this.preparePage();
  }

  async handleChangePage(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) {
    await this.preparePage(page);
  }

  setNewMessagesStatusOnPostings(
    postings: PostingModel[],
    messageNetworks: MessageNetworksModel[],
    currentUser: LoginModel
  ) {
    if (!messageNetworks) {
      return;
    }
    messageNetworks = messageNetworks.filter((x) => x.Type === 1);

    for (const posting of postings) {
      let messageNetwork = messageNetworks.find(
        (t) => t.PostingId === posting.Id
      );
      if (messageNetwork == null) {
        continue;
      }

      let messageNetworkLogin = messageNetwork.Logins.find(
        (t) => t.Id === currentUser.Id
      );
      if (messageNetworkLogin == null) {
        continue;
      }

      if (
        (messageNetworkLogin?.MessageNetworkMembers?.LastSeenDate == null &&
          messageNetwork?.LastMessageDate != null) ||
        messageNetworkLogin?.MessageNetworkMembers?.LastSeenDate! <
          messageNetwork?.LastMessageDate!
      ) {
        posting.vmUnreadMessages = true;
      }
    }
  }

  setRatingStatusOnPostings(
    postings: PostingModel[],
    requests: RequestModel[],
    Ratings: RatingModel[]
  ) {
    for (const posting of postings) {
      let countRequests = 0;
      let countRatings = 0;

      if (requests) {
        requests
          .filter((t: RequestModel) => t.PostingId === posting.Id)
          .forEach((request: RequestModel) => {
            countRequests++;
            var ratings = Ratings.filter(
              (t: RatingModel) => t.RequestId === request.Id
            );

            if (ratings.length > 0) {
              countRatings++;
            }
          });
      }

      let ratingFinished = false;
      if (countRequests === countRatings) {
        ratingFinished = true;
      }
      posting.vmRatingFinished = ratingFinished;
    }
  }

  async showAllJobsChanged(showAllJobs: boolean) {
    let postingsToDisplay = await this.filterPostingsForViewing(
      this.state.allPostings,
      this.state.chipsLanguageCode,
      showAllJobs
    );
    this.setState({
      postingsToDisplay,
      showAllJobs,
    });
  }

  showAllJobsSwitch() {
    let currentUser = getCurrentUser();
    let otherUsersJobsExists = false;

    if (currentUser == null) {
      return <></>;
    }

    otherUsersJobsExists =
      this.state.allPostings.find((t) => t.OwnerLoginId !== currentUser!.Id) !=
      null;

    if (currentUser && currentUser.AdminGranted) {
      if (otherUsersJobsExists) {
        return (
          <div className="text-right">
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  size="small"
                  checked={this.state.showAllJobs}
                  onChange={() =>
                    this.showAllJobsChanged(!this.state.showAllJobs)
                  }
                />
              }
              label={getLanguage(473, "Show all jobs")}
              labelPlacement="start"
            />
          </div>
        );
      }
    }
  }

  confirmedShiftsFilter(postingWithRatingFinishedCheck: PostingModel) {
    let today = nowAsFormatedString();
    if (
      (postingWithRatingFinishedCheck.AmountHired >=
        postingWithRatingFinishedCheck.AmountNeeded ||
        (postingWithRatingFinishedCheck.Deactivated &&
          postingWithRatingFinishedCheck.AmountHired > 0)) &&
      new Date(postingWithRatingFinishedCheck.StartAtLocal) > new Date(today)
    ) {
      return postingWithRatingFinishedCheck;
    }
  }

  openShiftsFilter(postingWithRatingFinishedCheck: PostingModel) {
    let today = nowAsFormatedString();
    if (
      new Date(postingWithRatingFinishedCheck.EndAtLocal) > new Date(today) &&
      new Date(postingWithRatingFinishedCheck.StartAtLocal) > new Date(today) &&
      postingWithRatingFinishedCheck.AmountHired <
        postingWithRatingFinishedCheck.AmountNeeded &&
      !postingWithRatingFinishedCheck.Deactivated
    ) {
      return postingWithRatingFinishedCheck;
    }
  }

  pastShiftsFilter(postingWithRatingFinishedCheck: PostingModel) {
    let today = nowAsFormatedString();
    if (new Date(postingWithRatingFinishedCheck.EndAtLocal) < new Date(today))
      return postingWithRatingFinishedCheck;
  }

  async handleClose() {
    await this.setState({
      anchorEl: null,
      anchorElTable: null,
      activeMenuPosting: null,
      openPreviewDialog: false,
      jobHasEnded: false,
    });
  }

  async handleClick(el: any, type: string, posting?: PostingModel) {
    el.stopPropagation();
    if (type !== "table" && posting) {
      await this.setState({
        anchorEl: el.currentTarget,
        activeMenuPosting: posting,
      });
      let dateNow = new Date();
      let endAtDate = new Date(posting!.EndAtLocal);
      if (endAtDate < dateNow) {
        await this.setState({ jobHasEnded: true });
      }
    } else {
      await this.setState({ anchorElTable: el.currentTarget });
    }
  }

  openSpecificPosting(posting: PostingModel) {
    if (new Date(posting.EndAtLocal) < new Date()) {
      this.props.history.push("/company/posting?id=" + posting.Id, {
        activePosting: posting,
        page: this.state.page,
      });
    } else {
      this.props.history.push("/company/posting?id=" + posting.Id, {
        activePosting: posting,
      });
    }
  }

  async showChip(chipType: 92 | 47 | 48) {
    let chips = this.state.chipsLanguageCode;
    switch (chipType) {
      case 92:
        if (chips.indexOf(chipType) === -1) {
          chips.push(chipType);
        }
        break;
      case 47:
        if (chips.indexOf(chipType) === -1) {
          chips.push(chipType);
        }
        break;
      case 48:
        if (chips.indexOf(chipType) === -1) {
          chips.push(chipType);
        }
        break;
    }

    this.handleChipsChanged(chips);
  }

  setChips(row: PostingModel) {
    let hoursTillCose =
      this.postingHelper.getTimeTillCloseDueToManyApplicants(row);

    let today = nowAsFormatedString();
    if (row.StartAtLocal > getNowAsDbString()) {
      if (!row.Deactivated && row.OpenRequests < 1) {
        if (hoursTillCose != null) {
          return (
            <Tooltip
              title={getLanguage(748, "Shift in need of closure")}
              placement="top"
            >
              <div>
                <i className="fas fa-exclamation-triangle danger" />
              </div>
            </Tooltip>
          );
        }
        return (
          <Tooltip
            title={getLanguage(485, "Waiting for applicants")}
            placement="top"
          >
            <div>
              <i className="fas fa-circle-notch fa-spin placeholder" />
            </div>
          </Tooltip>
        );
      } else if (
        row.AmountHired >= row.AmountNeeded ||
        (row.Deactivated && row.AmountHired > 0)
      ) {
        return (
          <Tooltip
            title={getLanguage(667, "Shift fully booked")}
            placement="top"
          >
            <div>
              <i className="fas fa-check-circle success" />
            </div>
          </Tooltip>
        );
      } else {
        if (hoursTillCose != null) {
          return (
            <Tooltip
              title={getLanguage(748, "Shift in need of closure")}
              placement="top"
            >
              <div>
                <i className="fas fa-exclamation-triangle danger" />
              </div>
            </Tooltip>
          );
        }
        return (
          <Tooltip title={getLanguage(668, "Shift open")} placement="top">
            <i className="fas fa-circle-notch warning" />
          </Tooltip>
        );
      }
    } else if (row.EndAtLocal > getNowAsDbString()) {
      return (
        <Tooltip title={getLanguage(766, "Job is in progress")} placement="top">
          <i className="fas fa-stopwatch info" />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={getLanguage(669, "Shift has ended")} placement="top">
          <i className="far fa-star" />
        </Tooltip>
      );
    }
  }

  getAmountNeededText(posting: PostingModel) {
    let amountNeededText = "";
    if (posting.Skill != null) {
      let skill = this.storageHelper
        .getSkillsFromLocal()!
        .find((x) => x.Id === posting.Skill!.Id) as SkillModel;

      amountNeededText = posting.AmountNeeded + " ";
      amountNeededText += getLanguage(
        skill.getLanguageCode(
          posting.AmountNeeded,
          this.state.user!.LoginType === 1
        )
      );
      if (
        posting.AmountNeededWithBuffer &&
        posting.AmountNeededWithBuffer > posting.AmountNeeded
      ) {
        amountNeededText +=
          " ( +" +
          (posting.AmountNeededWithBuffer - posting.AmountNeeded) +
          " )";
      }
    }
    return amountNeededText;
  }

  postings() {
    return (
      <div>
        <div className="margin-top-24 margin-bottom-40 pageHeaderBar">
          <Grid
            container
            spacing={1}
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Hidden smUp>
                <MobileSidebarTrigger />
              </Hidden>
              <h1 className="inline-block">
                {getLanguage(91, "Freelance shifts")}{" "}
              </h1>
            </Grid>
            <Grid item>
              <div className="inputGroup">
                <Button
                  className="inputGroup_item"
                  aria-owns={this.state.anchorEl ? "simple-menu" : undefined}
                  aria-haspopup="true"
                  onClick={(event) => this.handleClick(event, "table")}
                >
                  <span className="btn-icon">
                    <i className="fas fa-sort-amount-down" />
                  </span>
                  {getLanguage(210, "Filter")}
                </Button>
                <NavLink to="/company/editposting">
                  <Button
                    className="button-gradient inputGroup_item"
                    variant="contained"
                  >
                    <span className="btn-icon">
                      <i className="fas fa-plus" />
                    </span>
                    {getLanguage(100, "New shifts")}
                  </Button>
                </NavLink>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="postingChipWrapper">
          {this.state.chipsLanguageCode.map((row, index) => (
            <Chip
              className="postingChip"
              classes={{
                root: "chip",
                deleteIcon: "deleteIcon",
                outlined: "chipOutlined",
              }}
              key={index}
              label={getLanguage(row)}
              onDelete={() => this.handleRemoveChip(row)}
              deleteIcon={<i className="fas fa-times" />}
            />
          ))}
        </div>
        {this.showAllJobsSwitch()}
        <Table className="table">
          <TableHead className="tableHead">
            <TableRow className="tableRow">
              <TableCell align="center">{getLanguage(182, "Status")}</TableCell>
              <TableCell>{getLanguage(183, "Date")}</TableCell>
              <TableCell>{getLanguage(184, "Time")}</TableCell>
              {/* <TableCell>{getLanguage(185, "Salary")}</TableCell> */}
              <TableCell>{getLanguage(186, "Needed")}</TableCell>
              <TableCell>{getLanguage(187, "Confirmed")} </TableCell>
              <TableCell className="tableCell_last">
                {getLanguage(188, "Applicants")}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody className="tableBody">
            {this.state.postingsToDisplay.map((row, index) => {
              let actualAmountNeeded = row.AmountNeeded;
              if (row.AmountNeededWithBuffer > row.AmountNeeded) {
                actualAmountNeeded = row.AmountNeededWithBuffer;
              }

              return (
                <TableRow
                  className={
                    "tableRow" + (row.Id === this.state.addedId ? " added" : "")
                  }
                  key={index}
                  onClick={() => this.openSpecificPosting(row)}
                  hover={true}
                  classes={{ hover: "tableRow_hover" }}
                >
                  <TableCell className="tableCell" align="center">
                    {this.setChips(row)}
                  </TableCell>
                  <TableCell className="tableCell">
                    {row.StartAtLocalObject.day
                      ? row.StartAtLocalObject.day
                      : ""}
                    .{" "}
                    {this.state.months
                      ? this.state.months[
                          +row.StartAtLocalObject.month - 1
                        ].toLowerCase()
                      : ""}
                  </TableCell>
                  <TableCell className="tableCell">
                    {row.StartAtLocalObject.time} - {row.EndAtLocalObject.time}
                  </TableCell>

                  <TableCell className="tableCell">
                    {this.getAmountNeededText(row)}
                  </TableCell>
                  <TableCell className="tableCell">
                    {row.AmountHired}/{actualAmountNeeded}
                  </TableCell>
                  <TableCell className="tableCell">
                    {row.OpenRequests}
                    {row.vmUnreadMessages ? (
                      <div className="tableBadge brand newMessage">
                        {getLanguage(211, "Unread message")}
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </TableCell>
                  <TableCell
                    className="tableCell tableOptions"
                    aria-owns={
                      this.state.anchorElTable ? "table-menu" : undefined
                    }
                    aria-haspopup="true"
                    onClick={(event: any) => this.handleClick(event, "", row)}
                  >
                    <i className="fas fa-ellipsis-v" />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }

  archive() {
    let pagination = <></>;
    if (this.state.countJobs > 10) {
      pagination = (
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[10]}
            count={this.state.countJobs}
            rowsPerPage={10}
            page={this.state.page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onPageChange={(event, page) => this.handleChangePage(event, page)}
          />
        </TableRow>
      );
    }

    return (
      <div>
        <div className="margin-v-24">
          <Grid
            container
            spacing={8}
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Hidden smUp>
                <MobileSidebarTrigger />
              </Hidden>
              <h1 className="inline-block">{getLanguage(200, "Archive")}</h1>
            </Grid>
            <Grid item>
              <div className="inputGroup">
                <PostingsFilters
                  onChange={(next) => {
                    this.setState((curr) => ({
                      ...curr,
                      jobDateFrom: next?.start,
                      jobDateTo: next?.end,
                    }));
                    this.preparePage(0);
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <Table className="table">
          <TableHead className="tableHead">
            <TableRow className="tableRow">
              <TableCell>{getLanguage(183, "Date")}</TableCell>
              <TableCell>{getLanguage(184, "Time")}</TableCell>
              <TableCell>{getLanguage(185, "Salary")}</TableCell>
              <TableCell>Rating</TableCell>
              <TableCell>{getLanguage(186, "Needed")}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody className="tableBody">
            {this.state.postingsToDisplay.map((postingToDisplay, index) => {
              const postingRequests = this.state.requests.filter(
                (request) => request.PostingId === postingToDisplay.Id
              );
              const postingRatings = this.state.receivedRatings.filter(
                (rating) =>
                  postingRequests.some(
                    (request) =>
                      rating.RequestId === request.Id && rating.GivenToCompany
                  )
              );

              return (
                <TableRow
                  onClick={() => this.openSpecificPosting(postingToDisplay)}
                  key={index}
                  className="tableRow"
                  classes={{ hover: "tableRow_hover" }}
                  hover={true}
                >
                  <TableCell className="tableCell">
                    {format(parseISO(postingToDisplay.StartAtLocal), "PP")}
                  </TableCell>
                  <TableCell className="tableCell">
                    {postingToDisplay.StartAtLocalObject.time} -{" "}
                    {postingToDisplay.EndAtLocalObject.time}
                  </TableCell>
                  <TableCell className="tableCell">
                    {getMoneyOutput(
                      (
                        resolvePostingSalaryPerHour(postingToDisplay) / 100
                      ).toString(),
                      true,
                      postingToDisplay.Currency
                    )}
                  </TableCell>
                  <TableCell className="tableCell">
                    <AvarageRating ratings={postingRatings} />
                  </TableCell>
                  <TableCell className="tableCell">
                    {this.getAmountNeededText(postingToDisplay)}
                  </TableCell>
                </TableRow>
              );
            })}
            {pagination}
          </TableBody>
        </Table>
      </div>
    );
  }

  render() {
    let form = null;

    if (this.props.archive) {
      form = this.archive();
    } else {
      form = this.postings();
    }

    return (
      <div>
        {form}
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorElTable}
          open={this.state.anchorElTable ? true : false}
          onClose={this.handleClose}
          disableAutoFocusItem={true}
          getContentAnchorEl={null}
          className="dropdownMenu"
          classes={{ paper: "dropdownMenu_content" }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div className="menuTitle"> {getLanguage(189, "Filter jobs")}</div>
          <MenuItem className="menuItem" onClick={() => this.showChip(92)}>
            <i className="fas fa-circle-notch warning" />
            {getLanguage(92, "Open shifts")}
          </MenuItem>
          <MenuItem className="menuItem" onClick={() => this.showChip(47)}>
            <i className="fas fa-check-circle success" />
            {getLanguage(47, "Confirmed shift")}
          </MenuItem>
          <MenuItem className="menuItem" onClick={() => this.showChip(48)}>
            <i className="far fa-star" />
            {getLanguage(48, "Past shifts")}
          </MenuItem>
        </Menu>
        <PostingOptions
          comingFrom={"postings"}
          anchorEl={this.state.anchorEl}
          activeMenuPosting={this.state.activeMenuPosting}
          handleClose={this.handleClose}
          jobHasEnded={this.state.jobHasEnded}
          history={this.props.history}
          location={this.props.location}
          match={this.props.match}
          preparePage={() => this.preparePage()}
          postings={this.state.allPostings}
          postingsUpdated={this.postingsCancelled}
        />
        {this.state.showPageLoader ? <PageLoader releaseNavbar={true} /> : null}
      </div>
    );
  }
}
