import { CompanyModel } from "./CompanyModel";
import { FreelancerModel } from "./FreelancerModel";
import { RequestModel } from "./RequestModel";



export class RatingModel {
    CompanyProfile: CompanyModel | null;
    CompanyProfileId: string;
    CounterStatement: string;
    Created: string;
    FreelancerProfile: FreelancerModel | null;
    FreelancerProfileId: string
    GivenToCompany: boolean;
    Id: string;
    RatingValue: number;
    RatingValue1: number;
    RatingValue2: number;
    RatingValue3: number;
    Released: boolean;
    ReleasedDateTime: string | null;
    Request: RequestModel | null;
    RequestId: string;
    Statement: string;
    vmOvertimeAdded: any;


    constructor(val: Partial<RatingModel>) {
        this.CompanyProfile = val.CompanyProfile || null;
        this.CompanyProfileId = val.CompanyProfileId || '';
        this.CounterStatement = val.CounterStatement || '';
        this.Created = val.Created || '';
        this.FreelancerProfile = val.FreelancerProfile || null;
        this.FreelancerProfileId = val.FreelancerProfileId || '';
        this.GivenToCompany = val.GivenToCompany || false;
        this.Id = val.Id || '';
        this.RatingValue = val.RatingValue || 0;
        this.RatingValue1 = val.RatingValue1 || 0;
        this.RatingValue2 = val.RatingValue2 || 0;
        this.RatingValue3 = val.RatingValue3 || 0;
        this.Released = val.Released || false;
        this.ReleasedDateTime = val.ReleasedDateTime || null;
        this.Request = val.Request || null;
        this.RequestId = val.RequestId || '';
        this.Statement = val.Statement || '';
        this.vmOvertimeAdded = val.vmOvertimeAdded || null;



    }
}