// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from 'react';
import { Grid, Hidden, Badge, Button, Dialog } from '@material-ui/core';
import { MobileSidebarTrigger } from '../../../../Parts/General/MobileSidebarTrigger';
import { getLanguage, getDateFormatAccordingToLanguage } from '../../../../../Helpers/LanguageHelper';
import { PostingModel } from '../../../../../Models/PostingModel';
import { getCurrentUser } from '../../../../../Helpers/SessionHelper';
import { StorageHelper } from '../../../../../Helpers/StorageHelper';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { PostingOptions } from '../PostingOptions';
import { PostState } from './PostingHelper';
import { PostingHelper } from '../../../../../Helpers/PostingHelper';

interface IProps extends RouteComponentProps {
  postState: PostState;
  posting: PostingModel;
  unreadMessages: number;
  isEditing: boolean;
  toggleChat: (e: boolean) => void;
  preparePage: (getNewPosting?: boolean) => void;
  archiveJob: () => void;
}
interface IState {
  anchorEl: null;
  openPreviewDialog: boolean;
  CSV: string;
  wholeDayCSV: boolean;
  jobHasEnded: boolean;
  freelancerDialogueOpen: boolean;
}

export default class PostingsHeader extends Component<IProps, IState> {
  storageHelper = new StorageHelper();
  postingHelper = new PostingHelper();

  constructor(props: IProps) {
    super(props);

    this.state = {
      anchorEl: null,
      openPreviewDialog: false,
      CSV: '',
      wholeDayCSV: false,
      jobHasEnded: false,
      freelancerDialogueOpen: false,
    }

    this.handleClickOptions = this.handleClickOptions.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  async handleClickOptions(el: any) {
    el.stopPropagation();
    await this.setState({
      anchorEl: el.currentTarget
    });
  }

  async handleClose() {
    await this.setState({
      anchorEl: null,
      openPreviewDialog: false,
      CSV: '',
      wholeDayCSV: false,
      jobHasEnded: false,
      freelancerDialogueOpen: false
    });
  }

  async postingCancelled(postingCancelled: boolean) {
    if (postingCancelled) {
      this.props.history.push("/company/postings");
    }
  }

  optionsDialogue() {
    return <Dialog open={this.state.anchorEl ? true : false}>
      <PostingOptions
        comingFrom={'posting'}
        anchorEl={this.state.anchorEl}
        activeMenuPosting={this.props.posting}
        jobHasEnded={this.props.postState === PostState.pastPosting || this.props.postState === PostState.archivedPosting}
        handleClose={this.handleClose}
        postingCancelled={this.postingCancelled}
        {...this.props}
        preparePage={this.props.preparePage}
      />
    </Dialog>
  }

  renderArchiveButton() {
    if (this.props.postState === PostState.pastPosting) {
      return <div
        className="inputGroup_item inline-block"
      >
        <Button
          className="button-gradient"
          variant="contained"
          size="medium"
          disabled={this.props.isEditing}
          onClick={this.props.archiveJob}
        >
          <span className="btn-icon">
            <i className="fas fa-archive" />
          </span>
          {getLanguage(578, "Archive")}
        </Button>
      </div>
    }
  }

  closeWarningDueToManyApplicants() {
    let hours = this.postingHelper.getTimeTillCloseDueToManyApplicants(this.props.posting)
    if (hours == null) {
      return <></>
    }
    let hoursInt = Math.floor(hours)

    if (hoursInt > 0) {
      return <span className="danger"> <i className="fas fa-exclamation-triangle" /> {getLanguage(746, 'Job will be closed in {{hours}} hours. Please accept or decline some chabbers.').replace('{{hours}}', hoursInt.toString())}</span>
    } else {
      return <span className="danger"> <i className="fas fa-exclamation-triangle" /> {getLanguage(747, 'This job will be closed very soon. Please accept or decline some applicants to avoid this.')}</span>
    }
  }


  render() {
    let activePostTitle = '';
    let amountNeededText = ''
    if (this.props.posting.Skill != null) {
      let skill = this.storageHelper.getSkillsFromLocal()!.find(x => x.Id === this.props.posting!.Skill!.Id);
      let user = getCurrentUser();
      activePostTitle = getLanguage(skill!.getLanguageCode(this.props.posting.AmountNeeded, user!.LoginType === 1));

      amountNeededText = this.props.posting.AmountNeeded + ''
      if (this.props.posting.AmountNeededWithBuffer && this.props.posting.AmountNeededWithBuffer > this.props.posting.AmountNeeded) {
        amountNeededText += '(+' + (this.props.posting.AmountNeededWithBuffer - this.props.posting.AmountNeeded) + ')'
      }

    }
    return (
      <div className="margin-v-24">
        {this.optionsDialogue()}
        <Grid
          container
          spacing={1}
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Hidden smUp>
              <MobileSidebarTrigger />
            </Hidden>
            <h1 className="margin-bottom-0">
              <NavLink to={this.props.postState === PostState.archivedPosting ? '/company/archive' : '/company/postings'}>
                <span className="margin-right-8 cursorPointer"><i className="fas fa-arrow-left brand fa-sm" ></i></span>
              </NavLink>
              {this.props.posting && this.props.posting.StartAtLocal ?
                getDateFormatAccordingToLanguage(this.props.posting.StartAtLocal, false, true, this.props.posting.EndAtLocal) : ''} | {amountNeededText}{"\u00A0"}{activePostTitle}
            </h1>
            <p className="margin-0">
              {getLanguage(214, "Ref.")} {this.props.posting.ReferenceKey} {this.closeWarningDueToManyApplicants()}
            </p>
          </Grid>
          <Grid item>
            <div className="inputGroup">
              <Badge color="error" variant="dot" badgeContent={this.props.unreadMessages} className="inputGroup_item badge" classes={{ badge: "badgeElement", dot: "badgeDot" }}>
                <Button
                  className="white"
                  variant="contained"
                  onClick={() => this.props.toggleChat(true)}
                >
                  <span className="btn-icon">
                    <i className="fas fa-comment" />
                  </span>
                  {getLanguage(212, "Chat")}
                </Button>
              </Badge>
              <Button
                className="white inputGroup_item"
                variant="contained"
                onClick={this.handleClickOptions}
              >
                <span className="btn-icon">
                  <i className="fas fa-cog" />
                </span>
                {getLanguage(213, "Options")}
              </Button>
              {this.renderArchiveButton()}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}