// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { Grid, Button, TextField, Chip, Snackbar } from "@material-ui/core";
import { AdminUserHelper } from "../../../Helpers/AdminUserHelper";
import { VerificationDocumentModel } from "../../../Models/VerificationDocumentModel";
import { LoginModel } from "../../../Models/LoginModel";
import { AdminUserModel } from "../../../Models/AdminUserModel";
import { getCurrentAdminUser } from "../../../Helpers/SessionHelper";
import Iframe from "react-iframe";
import { SnackbarManager } from "../../../Helpers/SnackbarManager/SnackbarManager";
import { LimitedTextArea } from "../../Parts/General/LimitedTextArea";
import { UserHelper } from "../../../Helpers/UserHelper";
import { FreelancerModel } from "../../../Models/FreelancerModel";
import { CountryModel } from "../../../Models/CountryModel";
import { CountryHelper } from "../../../Helpers/CountryHelper";
import { configureCloudinaryUrl } from "../../../Helpers/ImageHelper";
import ImagePreview from "../../UI/ImagePreview/ImagePreview";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./VerificationUser.scss";
import { PredefinedTemplates } from "./PredefinedTemplates/PredefinedTemplates";
import { Alert } from "@material-ui/lab";
import { isAbove18YearsOld } from "../../../Helpers/SSNValidator";
import cpr from "danish-ssn";
import { differenceInMonths, parse } from "date-fns";
import { first } from "lodash";

interface IProps extends RouteComponentProps {}

//should be move to shared packaged
enum VerificationStatus {
  "Pending" = 0,
  "Rejected" = 1,
  "Approved" = 2,
}

type VerificationStatusOption = {
  status: VerificationStatus;
  predicate?: (doc: VerificationDocumentModel) => boolean;
  color: "default" | "primary" | "secondary";
  label: string;
  order: number;
};

//Common document statuses extended with extra information.
const StatusesMatrix: VerificationStatusOption[] = [
  {
    status: VerificationStatus.Pending,
    color: "default",
    label: "Pending",
    order: 0,
  },
  {
    status: VerificationStatus.Pending,
    order: 1,
    predicate: (doc) =>
      (doc.Type === 0 || doc.Type === 3) &&
      differenceInMonths(
        new Date(),
        parse(doc.CreatedUTC.substring(0, 9), "yyyy-MM-dd", new Date())
      ) >= 3,
    color: "secondary",
    label: "Over deadline",
  },
  {
    order: 3,
    status: VerificationStatus.Rejected,
    color: "default",
    label: "Rejected",
  },
  {
    order: 2,
    status: VerificationStatus.Approved,
    color: "primary",
    label: "Approved",
  },
];

const ResolveStatus = (doc: VerificationDocumentModel) => {
  const matchingStatuses = StatusesMatrix.filter(
    ({ status }) => status === doc.Status
  );

  const optionWithExtraPredicate = matchingStatuses.find(
    (option) => !!option.predicate
  );
  if (optionWithExtraPredicate) {
    const hasMeetPredicate = optionWithExtraPredicate.predicate(doc);
    if (hasMeetPredicate) {
      return optionWithExtraPredicate;
    }
  }

  return first(matchingStatuses);
};

export const VerificationStatusReadable = ({
  document,
}: {
  document: VerificationDocumentModel;
}): string => {
  const { label, color } = ResolveStatus(document);

  return (
    <Chip
      style={{ margin: "0 5px" }}
      label={label}
      variant="default"
      color={color}
    />
  );
};

export function VerificationUser(props: IProps) {
  const [verificationDocuments, setVerificationDocuments] = useState<
    VerificationDocumentModel[]
  >([]);
  const [user, setUser] = useState<LoginModel | null>(null);
  const [adminUsers, setAdminUsers] = useState<AdminUserModel[]>([]);
  const [adminUser, setAdminUser] = useState<AdminUserModel | null>(null);
  const [workPermitEndDate, setWorkPermitEndDate] = useState<string>("");
  const [rejectionReason, setRejectionReason] = useState("");
  const [freelancer, setFreelancer] = useState<FreelancerModel | null>(null);
  const [nationalities, setNationalities] = useState<CountryModel[] | null>(
    null
  );

  const [isOfAge, setIsOfAge] = useState(true);
  const [isSSNValid, setIsSSNValid] = useState(false);
  const [invalidSSNSnackbarVisible, setInvalidSSNSnackbarVisible] =
    useState(false);

  useEffect(() => {
    if (user?.Freelancer?.SSN) {
      setIsOfAge(isAbove18YearsOld(user?.Freelancer?.SSN));
    } else {
      setIsOfAge(true);
    }
  }, [user]);

  useEffect(() => {
    if (user?.Freelancer?.SSN) {
      try {
        const isCprValid = cpr(user?.Freelancer?.SSN);
        setIsSSNValid(isCprValid.valid);
      } catch (error: any) {
        setIsSSNValid(false);
      }
    } else {
      setIsSSNValid(false);
    }
  }, [user]);

  const [expandedIds, setExpandedIds] = React.useState([]);

  useEffect(() => {
    preparePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getVerificationDocumentsForUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  async function preparePage() {
    if (props.location.state) {
      let user = props.location.state.user;
      if (user && user.Id) {
        setUser(user);

        let freelancer = await new UserHelper().getFreelancerByLoginId(user.Id);
        setFreelancer(freelancer);

        let countries = await new CountryHelper().getNationalities();
        setNationalities(countries);
      }
      let adminUser = getCurrentAdminUser();
      if (adminUser) {
        setAdminUser(adminUser);
      }
      let adminUsers = await new AdminUserHelper().getAll();
      if (adminUsers) {
        setAdminUsers(adminUsers);
      }
    }
  }

  function b64toBlob(dataURI: string, documentType: string) {
    let type = "image/jpeg";
    if (documentType === "pdf") type = "application/pdf";

    var byteString = atob(dataURI.split(",")[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type });
  }

  async function getVerificationDocumentsForUser() {
    if (user?.Id) {
      let verificationDocuments: VerificationDocumentModel[] | null =
        await new AdminUserHelper().getFreelancersVerificationDocuments(
          user.Id
        );
      if (verificationDocuments) {
        for (const verificationDocument of verificationDocuments) {
          // Download image data
          let imageData = await new AdminUserHelper().getSpecificDocument(
            verificationDocument.Filename
          );

          if (imageData.image) {
            verificationDocument.vmImageDataAsBlob = b64toBlob(
              imageData.image,
              verificationDocument.Extension.toLocaleLowerCase()
            );
          }
        }

        setVerificationDocuments(verificationDocuments);
        setExpandedIds(
          verificationDocuments
            .filter((x) => x.Status === 0)
            .map(({ id }) => id)
        );
      }
    }
  }

  async function approveOrRejectDocument(
    verificationDocument: VerificationDocumentModel,
    approveOrReject: "approved" | "rejected"
  ) {
    if (approveOrReject === "approved" && !isSSNValid) {
      setInvalidSSNSnackbarVisible(true);
      // return;
    }

    if (verificationDocument && adminUser) {
      if (
        verificationDocument.Type === 0 &&
        !workPermitEndDate &&
        approveOrReject === "approved"
      ) {
        SnackbarManager.Instance.addError("Please select a date");
        return;
      }

      let approved = approveOrReject === "approved" ? 2 : 1;

      let success = await new AdminUserHelper().approveOrRejectDocument(
        verificationDocument.Id,
        adminUser.Id,
        approved,
        workPermitEndDate,
        rejectionReason
      );

      if (success) {
        setExpandedIds((prev) => {
          const clone = [...prev];
          clone.slice(
            clone.findIndex((x) => x === verificationDocument.Id),
            0
          );
          return clone;
        });
        return getVerificationDocumentsForUser();
      } else {
        return "Verification error";
      }
    }
  }

  function outputDocuments() {
    let documentsOutputElements = [];

    const sortedByStatus = verificationDocuments.sort((leftDoc, rightDoc) => {
      const leftStatus = ResolveStatus(leftDoc);
      const rightStatus = ResolveStatus(rightDoc);

      return leftStatus?.order - rightStatus?.order;
    });

    if (sortedByStatus) {
      for (const document of sortedByStatus) {
        let adminUser = document.AdminUserId;
        let date = null;
        if (adminUser && adminUsers && adminUsers.length !== 0) {
          for (const u of adminUsers) {
            if (adminUser === u.Id) {
              adminUser = u.Firstnames + " " + u.Lastname;
              date = document.LastUpdatedUTC;
            }
          }
        }

        let dataOutput = <div></div>;

        if (document.vmImageDataAsBlob) {
          let elem = URL.createObjectURL(document.vmImageDataAsBlob);
          if (document.Extension === "pdf") {
            dataOutput = <Iframe width="600px" height="800px" url={elem} />;
          } else {
            dataOutput = <ImagePreview src={elem} alt={"verification doc"} />;
          }
        }

        let expireDateField = <div></div>;
        if (document.Type === 0) {
          expireDateField = (
            <div className="margin-v-24">
              <TextField
                type="date"
                label="Expiration date"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setWorkPermitEndDate(e.target.value)}
                value={workPermitEndDate}
              />
            </div>
          );
        }

        documentsOutputElements.push(
          <div className="document-container">
            <Accordion
              expanded={!!expandedIds.find((x) => x === document.Id)}
              onChange={(_e, isExpanded) =>
                setExpandedIds((prev) => {
                  if (isExpanded) {
                    return [...prev, document.Id];
                  } else {
                    const clone = [...prev];
                    clone.splice(
                      prev.findIndex((x) => x === document.Id),
                      1
                    );
                    return clone;
                  }
                })
              }
              className="document-accordion"
              defaultExpanded={document.Status === 0}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className="accordion-title">
                  <span>
                    {document.Type === 0
                      ? "Work permit (front)"
                      : document.Type === 3
                      ? "Work permit (back)"
                      : "Picture Id"}
                  </span>
                  <span className="accordion-title__last-updated">
                    <VerificationStatusReadable document={document} />
                    Last updated: {document.LastUpdatedUTC}
                  </span>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12}>
                  <h2>
                    {document.Type === 0
                      ? "Work permit (front)"
                      : document.Type === 3
                      ? "Work permit (back)"
                      : "Picture Id"}
                  </h2>
                  <div className="inputGroup">
                    <Button
                      // disabled={document.Status !== 0}
                      className={
                        document.Status === 1
                          ? "inputGroup_item danger"
                          : "inputGroup_item"
                      }
                      size="small"
                      variant="contained"
                      onClick={() =>
                        approveOrRejectDocument(document, "rejected")
                      }
                    >
                      Reject
                    </Button>
                    <Button
                      // disabled={document.Status !== 0}
                      className={
                        document.Status === 2
                          ? "inputGroup_item success"
                          : "inputGroup_item"
                      }
                      size="small"
                      variant="contained"
                      onClick={() =>
                        approveOrRejectDocument(document, "approved")
                      }
                    >
                      Approve
                    </Button>
                  </div>
                  {expireDateField}
                  <h2>Use on of the predefined templates</h2>
                  <PredefinedTemplates
                    onClick={(selected) => setRejectionReason(selected)}
                  />
                  <LimitedTextArea
                    label="Why was it rejected?"
                    rows="4"
                    limit={500}
                    value={rejectionReason}
                    onChange={(e) => setRejectionReason(e.target.value)}
                  />

                  <p className="small">
                    {adminUser && date ? "By " + adminUser + " | " + date : ""}
                  </p>
                  <p>{document.RejectionReason || ""}</p>

                  {dataOutput}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>
        );
      }
    }

    return (
      <div>
        {documentsOutputElements.map((x) => (
          <div>{x}</div>
        ))}
      </div>
    );
  }

  let content = outputDocuments();
  let nationality = "";

  if (nationalities && freelancer) {
    nationality =
      nationalities.find((t) => t.Id === freelancer.NationalityCountryId)
        ?.CountryName ?? "";
  }

  return (
    <div>
      <div className="AdminPageHeader">
        <div>
          <img
            className=""
            src={configureCloudinaryUrl(user?.ProfilePictureUrl, ["w_100"])}
          />
        </div>
        <div className="AdminPageTitle">
          {user ? user.Firstnames + " " + user.Lastname : ""}
        </div>
        <div>Nationality: {nationality}</div>
        <div>SSN: {user?.Freelancer?.SSN}</div>
        {!isOfAge && (
          <Alert severity="error">This chabber is under 18 years old!</Alert>
        )}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={invalidSSNSnackbarVisible}
          autoHideDuration={6000}
          onClose={() => setInvalidSSNSnackbarVisible(false)}
        >
          <Alert severity="warning">Invalid SSN number</Alert>
        </Snackbar>
      </div>
      {content}
    </div>
  );
}
