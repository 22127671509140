import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { Avatar, Button } from "@material-ui/core";
import { CourseHelper } from "../../../../../Helpers/CourseHelper";
import { CourseOrganizerHelper } from "../../../../../Helpers/CourseOrganizerHelper";
import { CourseModel } from "../../../../../Models/CourseModel";
import qs from 'query-string';
import './Course.scss';
import { CMSHelper } from '../../../../../Helpers/CMSHelper';
import { CMSMetaModel } from '../../../../../Models/CMSMetaModel';
import { StorageHelper } from '../../../../../Helpers/StorageHelper';
import { CourseSelector } from "./CourseSelector";
import { CoursePartnerModel } from '../../../../../Models/CoursePartnerModel';
import { TermsDialogue } from "./TermsDialogue";
import { PageLoader } from '../../../../Parts/General/PageLoader';
import { CourseRegistrationModel } from '../../../../../Models/CourseRegistrationModel';
import { getCurrentUser } from '../../../../../Helpers/SessionHelper';
import { CourseRegistrantHelper } from '../../../../../Helpers/CourseRegistrantHelper';
import { SnackbarManager } from '../../../../../Helpers/SnackbarManager/SnackbarManager';
import { getLanguage } from '../../../../../Helpers/LanguageHelper';

interface IProps extends RouteComponentProps { }

export function Course(props: IProps) {
  let courseHelper = new CourseHelper();
  let courseRegistrantHelper = new CourseRegistrantHelper();
  let cmsHelper = new CMSHelper();
  let storageHelper = new StorageHelper();

  const [courses, setCourses] = useState<CourseModel[]>([]);
  const [cmsMeta, setCmsMeta] = useState<CMSMetaModel>();
  const [selectedCourse, setSelectedCourse] = useState('');
  const [termsOpen, setTermsOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [coursePartner, setCoursePartner] = useState<CoursePartnerModel | null>(null);
  const [registrations, setRegistrations] = useState<CourseRegistrationModel[]>([]);
  let course = courses.find(x => x.Id === selectedCourse);

  useEffect(() => {
    preparePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function preparePage() {
    let metaId = qs.parse(props.location.search).metaId;
    if (!metaId) return;
    if (Array.isArray(metaId)) metaId = metaId[0];

    // let currentCoursePartner = await courseOrganizerHelper.getCoursePartnerByCmsMetaId(metaId);
    let courseContent = await cmsHelper.getCourseContentByMetaId(metaId, false);

    let country = storageHelper.getFacadeCountry();
    if (!country || /*!currentCoursePartner ||*/ !courseContent) {
      console.log(country, courseContent);

      SnackbarManager.Instance.addError('couldn\'t load data')
      return
    };

    let currentUser = getCurrentUser();
    if (!currentUser) return;
    let allCourses = await courseHelper.getFutureCourses(country.Id);
    allCourses = allCourses.filter(x => x.CoursePartnerId === courseContent?.organizer?.Id);
    let courseIds = allCourses.map(x => x.Id);
    let registrations = await courseRegistrantHelper.getRegistrationsByCourses(courseIds);
    allCourses = allCourses.filter(x => x.Registrations.length < x.MaxRegistrations || x.Registrations.findIndex(y => y.FreelancerProfileId === currentUser?.Freelancer?.Id) !== -1);
    if (registrations.length > 0) {
      let courseId = allCourses.find(x => registrations.findIndex(y => y.CourseId === x.Id) !== -1)?.Id || '';
      setSelectedCourse(courseId);
    }

    setRegistrations(registrations);
    setCmsMeta(courseContent.meta);
    setCourses(allCourses);
    setCoursePartner(courseContent.organizer);
  }

  async function submitToCourse() {
    setTermsOpen(false);
    setSubmitting(true);

    let registration = new CourseRegistrationModel({
      CourseId: selectedCourse,
      FreelancerProfileId: getCurrentUser()?.Id,
    });
    let result = await courseRegistrantHelper.submitRegistration(registration);

    if (result != null) {
      SnackbarManager.Instance.addSuccess('Course Registered!');
      setRegistrations([result]);
    } else {
      SnackbarManager.Instance.addError('Could not register course');
    }
    setSubmitting(false);
  }

  function renderSelector() {
    let applyButton = null;
    if (registrations.length > 0) {
      applyButton = <div className="text-center margin-v-24"><Button className="default" disabled>{getLanguage(786, "Already registered")}</Button></div>
    } else {
      applyButton = <div className="text-center margin-v-24">
        <Button className="button-gradient" variant="contained" disabled={submitting || !selectedCourse} onClick={() => setTermsOpen(true)} >
          {getLanguage(788, "Sign up for course")}
        </Button>
      </div>
    }
    return (
      <>
        <CourseSelector selectedCourse={selectedCourse} changeCourse={setSelectedCourse} courses={courses} disabled={registrations.length > 0} />
        {applyButton}
      </>
    )
  }
  function alreadyRegistered() {
    if (registrations.length > 0) {
      return (
        <div className="alreadyRegistered">
          <i className="fas fa-check success fa-fw"></i>{getLanguage(786, "Already registered")}
        </div>
      )
    }
  }

  function renderBody() {
    if (!cmsMeta || !cmsMeta.PrimaryRevision) return null;
    let primaryRevision = cmsMeta.Revisions.find(x => x.Id === cmsMeta.PrimaryRevision);

    if (!primaryRevision) return null;

    let courseDates: JSX.Element[] = [];

    if (Array.isArray(course?.CourseDates) && course?.CourseDates?.length !== 0) {
      courseDates = course?.CourseDates.map(x => {
        return <p key={x.Id}>
          {x.Date} {x.StartAtUTC}-{x.EndAtUTC}
        </p>
      }) || [];
    } else {
      courseDates = [<div key={'meh'}>{course?.CourseDate}</div>]
    }


    let coverUrl = cmsMeta.getPrimaryRevision()?.CoverImageUrl;

    return (
      <div className="courseView">
        {submitting && <PageLoader />}
        <div className="courseCoverImage" style={{ backgroundImage: "url(" + coverUrl + ")" }} />
        <div className="courseWrapper">
          <div className="courseContent">
            <div className="courseHeader">
              <h1 className="displayFont">{cmsMeta.Title}</h1>
              <div className="authorInfo">
                <Avatar className="authorImage" />
                <div className="nameAndDate">
                  <p>CoursePartner</p>
                  {alreadyRegistered()}
                  {/* <p>{course.CoursePartner}</p> */}
                </div>
              </div>
            </div>
            <div className="courseText">
              <p dangerouslySetInnerHTML={{ __html: primaryRevision.Text }}></p>
              <h2>{getLanguage(791, "Additional course information")}</h2>
              <ul>
                {/* <li>CourseDates: {courseDates}</li> */}
                <li>{getLanguage(789, "Number of participants")}: {course?.Registrations.length}/{course?.MaxRegistrations}</li>
                <li>{getLanguage(790, "Standard price")}: {course?.ValueInLocalCurrency}</li>
              </ul>
            </div>
            {renderSelector()}
          </div>
        </div>
        <TermsDialogue
          open={termsOpen}
          course={course}
          coursePartner={coursePartner}
          closeDialogue={() => setTermsOpen(false)}
          submit={() => submitToCourse()}
        />
      </div>
    )
  }

  return (
    <div>
      {renderBody()}
    </div>
  )
}