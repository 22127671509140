// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as historyObj from "history";
import { UserHelper } from "./UserHelper";
import { setUserData, getCurrentUser, isUserLoggedIn } from "./SessionHelper";
import { StorageHelper } from "./StorageHelper";
import { LoginModel } from "../Models/LoginModel";
import { LanguageHelper } from "./LanguageHelper";
import { getQuery } from "./queryHelper";



export class RouteHandler {
  userHelper = new UserHelper();
  storageHelper = new StorageHelper();
  languageHelper = new LanguageHelper();
  async attemptLogIn(history: historyObj.History, redirectPath?: string, email?: string, password?: string, token?: string) {
    if (history.location.pathname.includes('/admin')) {
      return;
    }

    const loggedIn = await this.userHelper.attemptLogin(email, password, token);
    if (loggedIn?.userData) {
      this.storageHelper.setAccessToken(loggedIn.token);
      setUserData(loggedIn.userData);
      await this.storageHelper.initializeCaches(true)
      if (!redirectPath) {
        redirectPath = getQuery('redirectPath', history.location.search);
      }

      this.checkRouting(history, loggedIn.userData, redirectPath);
    } else {
      if (history.location.pathname.includes('/login')) {
        return false;
      } else if (history.location.pathname.includes('/freelancer') || history.location.pathname.includes('/company')) {
        let currentPath = history.location.pathname;
        let currentSearch = history.location.search;
        history.push('/login?redirectPath=' + currentPath + currentSearch);
        return false;
      }
    }
  }

  checkRouting(history: historyObj.History, login?: LoginModel, redirectPath?: string) {
    if (history.location.pathname.includes('/admin') || history.location.pathname.includes('courseattendance')) {
      return true;
    }

    login = login ?? getCurrentUser() ?? undefined;
    let loggedIn = isUserLoggedIn();
    //Check for search postings
    if (history.location.pathname.includes('/searchpostings')) {
      if (login && login.Company) {
        history.push('/company');
        return;
      }
      if (!login && !loggedIn && history.location.pathname.includes('freelancer')) {
        history.push({ pathname: '/searchpostings', search: history.location.search });
        return;
      }
    }

    if (!login || !loggedIn) {
      if (history.location.pathname.includes('/freelancer') || history.location.pathname.includes('/company')) {
        let currentPath = history.location.pathname;
        let currentSearch = history.location.search;
        history.push('/login?redirectPath=' + currentPath + currentSearch);
        return false;
      }
      return true;
    }

    if (login && !login.Activated) {
      if (history.location.pathname.includes('login')) return true;
      history.push('/verification');
      return false;
    }

    if (login?.LoginType === 1 && login.Freelancer) {
      return this.checkFreelancerRoute(login, history, redirectPath);
    } else if (login?.LoginType === 0 && login.Company) {
      return this.checkCompanyRoute(login, history, redirectPath);
    } else {
      let currentPath = history.location.pathname;
      let currentSearch = history.location.search;
      history.push('/login?redirectPath=' + currentPath + currentSearch);
      return false;
    }
  }

  checkFreelancerRoute(login: LoginModel, history: historyObj.History, redirectPath?: string) {
    let path = '';
    if (login.vmStepBasicDone !== true) {
      path = '/createuser';
    } else if (typeof redirectPath === 'string' && (redirectPath.includes('/freelancer/') || redirectPath.includes('searchpostings'))) {
      path = redirectPath;
    } else if (history.location.pathname.includes('/freelancer/') === false) {
      path = '/freelancer/profile';
    }

    if (path && !history.location.pathname.includes(path)) {
      history.push(path);
      return false;
    }
    return true;
  }

  checkCompanyRoute(login: LoginModel, history: historyObj.History, redirectPath?: string) {
    let path = '';
    if (login.vmStepBasicDone !== true && !(login?.Company?.IsPrivate)) {
      path = '/company/companycreateuser';
    } else if (typeof redirectPath === 'string' && redirectPath.includes('/company/')) {
      path = redirectPath;
    } else if (history.location.pathname.includes('/company/') === false) {
      path = '/company/postings';
    }

    if (path && !history.location.pathname.includes(path)) {
      history.push({ pathname: path, search: history.location.search });
      return false;
    }
    return true;
  }
}