export class ClearanceModel {
  Id: string;
  Name: string;

  constructor(val: Partial<ClearanceModel> | undefined) {
    if (val == null) {
      this.Id = '';
      this.Name = '';
      return;
    }

    this.Id = val.Id || '';
    this.Name = val.Name || '';
  }
}