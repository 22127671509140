import React, { Component } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Checkbox, Button } from '@material-ui/core';
import { FreelancerModel } from '../../../Models/FreelancerModel';
import { SalaryHelper } from "../../../Helpers/SalaryHelper";
import { RequestModel } from '../../../Models/RequestModel';

interface IProps {
  freelancers: FreelancerModel[];
  requests: RequestModel[];
  countryId: string;
  callbackFreelancersMarkedAsTransferred: () => void;
}

interface IState {
  numSelected: number;
  selectedRows: number[];
}


export class SalaryTable extends Component<IProps, IState> {
  salaryHelper = new SalaryHelper();

  constructor(props: any) {
    super(props);

    // data.sort((one, two) => (one.freelancerName < two.freelancerName ? -1 : 1));

    this.state = {
      numSelected: 0,
      selectedRows: [],
    }
    this.onSelectAllClick = this.onSelectAllClick.bind(this);
    this.onSelectClick = this.onSelectClick.bind(this);
    this.buildRows = this.buildRows.bind(this)
    this.markRequestsTransferred = this.markRequestsTransferred.bind(this);
    this.buildRows = this.buildRows.bind(this)
  }


  onSelectAllClick() {
    if (this.state.selectedRows.length === this.props.freelancers.length) {
      // Empty
      this.setState({ numSelected: 0, selectedRows: [] });
    } else {
      // Select all

      let selectedRows: number[] = []
      for (let index = 0; index < this.props.freelancers.length; index++) {
        selectedRows.push(index);
      }
      this.setState({ numSelected: this.props.freelancers.length, selectedRows });
    }
  }

  onSelectClick(event: any, id: number) {
    let index = this.state.selectedRows.indexOf(id);
    if (index == -1) {
      let selectedRows = [...this.state.selectedRows, id];
      let numSelected = selectedRows.length;
      this.setState({ numSelected, selectedRows })
    } else {
      let selectedRows = this.state.selectedRows;
      selectedRows.splice(index, 1);
      let numSelected = selectedRows.length;
      this.setState({ numSelected, selectedRows })
    }
  }

  buildRows() {
    let rows: JSX.Element[] = [];
    for (let index = 0; index < this.props.freelancers.length; index++) {
      const element = this.props.freelancers[index];

      let fullSalary = element.FullSalary.toString();
      let len = fullSalary.length;
      if (len > 2) {
        fullSalary = fullSalary.substring(0, len - 2) + '.' + fullSalary.substring(len - 2, len);
      }

      rows.push(

        <TableRow key={index} onClick={(event: any) => this.onSelectClick(event, index)}>
          <TableCell className="tableCell" padding="checkbox">
            <Checkbox
              checked={this.state.selectedRows.indexOf(index) != -1}
              color="primary"
            />
          </TableCell>
          <TableCell className="tableCell">{element.Login!.Firstnames} {element.Login!.Lastname}</TableCell>
          <TableCell className="tableCell">{element.SSN}</TableCell>
          <TableCell className="tableCell">{element.LocalBankRegistrationNumber} {element.LocalBankAccountNumber}</TableCell>

          <TableCell className="tableCell" align="right">{fullSalary}</TableCell>
        </TableRow>);
    }
    return rows;
  }

  async markRequestsTransferred() {
    let requestIds = [];

    for (let i = 0; i < this.state.selectedRows.length; i++) {
      let requests = this.props.requests.filter(t => t.FreelancerId === this.props.freelancers[this.state.selectedRows[i]].Id);
      for (let j = 0; j < requests.length; j++) {
        requestIds.push(requests[j].Id)
      }
    }
    
    await this.salaryHelper.setSalaryCompletedForRequests(requestIds, this.props.countryId);
    this.props.callbackFreelancersMarkedAsTransferred();
  }

  getRequestsMarked() {
    let requestIds = [];

    for (let i = 0; i < this.state.selectedRows.length; i++) {
      let requests = this.props.requests.filter(t => t.FreelancerId === this.props.freelancers[this.state.selectedRows[i]].Id);
      for (let j = 0; j < requests.length; j++) {
        requestIds.push(requests[j].Id)
      }
    }
    
    return requestIds;
  }


  render() {
    let { numSelected } = this.state || 0;
    let rows = this.buildRows();

    let table =
      <Table className="table">
        <TableHead className="tableHead">
          <TableRow className="tableRow">
            <TableCell className="tableCell" padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < this.props.freelancers.length}
                checked={numSelected === this.props.freelancers.length}
                onChange={this.onSelectAllClick}
                color="primary"
              />
            </TableCell>
            <TableCell>Freelancer Name</TableCell>
            <TableCell>SSN-NUMBER</TableCell>
            <TableCell>Bankinformation</TableCell>
            <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="tableBody">

          {rows}

        </TableBody>
      </Table>

    return (
      <div className="adminTable">
        {table}
        <Button className="button-gradient" type="submit" onClick={this.markRequestsTransferred}>
          Mark selected as transferred
      </Button>
      </div>
    );
  }
}