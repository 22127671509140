import { StorageHelper } from "./StorageHelper";
import { CountryModel } from "../Models/CountryModel";

export function getPhoneCountryCode(countries: CountryModel[], activeCountryId: string, storageHelper: StorageHelper) {
  let selectedCountry = countries.find(x => x.Id === activeCountryId);
  if (selectedCountry == null) {
    let localData = storageHelper.getFacadeLocalization();
    if (localData) {
      return localData.country.TwoCharacterCountryCode.toLowerCase();
    }
    return 'dk';
  }
  return selectedCountry.TwoCharacterCountryCode.toLowerCase();
}
