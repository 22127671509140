import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { Button } from "@material-ui/core";
import { UserHelper } from "../../../../../../Helpers/UserHelper";
import { getCurrentUser } from "../../../../../../Helpers/SessionHelper";
import { LoginModel } from "../../../../../../Models/LoginModel";
import { VerificationDocumentsStatus } from "../../../../../../Models/VerificationDocumentStatus";
import { getLanguage } from "../../../../../../Helpers/LanguageHelper";
import { StorageHelper } from "../../../../../../Helpers/StorageHelper";
import { PageLoader } from "../../../../../Parts/General/PageLoader";
import { SnackbarManager } from "../../../../../../Helpers/SnackbarManager/SnackbarManager";
import "./FreelancerStepVerification.scss";

import { PhotoCapture } from "../../../../../../Modules/PhotoCapture/PhotoCapture";
import { VerificationDocumentType } from "../../../../../../Models/VerificationDocumentModel";
import ImagePreview from "../../../../../UI/ImagePreview/ImagePreview";

interface IProps extends RouteComponentProps {
  creatingUser?: boolean;
  saveStep?: (stepOneCompleted: boolean) => void;
  verificationDocumentStatus?: VerificationDocumentsStatus | null;
}

interface IState {
  verificationDocumentStatus: VerificationDocumentsStatus | null;
  image?: string;
  showPageLoader: boolean;
  user: LoginModel | null;
  type: VerificationDocumentType | null;
  isPhotoCaptureOpen: boolean;
}

export class FreelancerStepVerification extends Component<IProps, IState> {
  userHelper = new UserHelper();
  storageHelper = new StorageHelper();
  fileInput: any;

  constructor(props: IProps) {
    super(props);

    this.state = {
      verificationDocumentStatus: null,
      showPageLoader: false,
      user: null,
      type: null,
      isPhotoCaptureOpen: false,
    };

    this.workpermitRequired = this.workpermitRequired.bind(this);
    this.workpermitBackRequired = this.workpermitBackRequired.bind(this);
    this.pictureIdRequired = this.pictureIdRequired.bind(this);
    this.nationalIdRequired = this.nationalIdRequired.bind(this);
  }

  async componentDidMount() {
    let user: LoginModel | null = getCurrentUser();

    if (!user || !user.Freelancer) {
      return;
    }

    await this.setState({ user });
    await this.getVerificationDocumentStatus();
  }

  async getVerificationDocumentStatus() {
    if (
      this.state.user &&
      this.state.user.Freelancer &&
      (!this.props.creatingUser ||
        (this.props.creatingUser && !this.props.verificationDocumentStatus))
    ) {
      let verificationDocumentStatus: VerificationDocumentsStatus | boolean =
        await this.userHelper.getVerificationDocumentStatus(
          this.state.user.Freelancer.CountryId,
          this.state.user.Id,
          this.state.user.Freelancer.Id
        );

      if (
        (!this.props.creatingUser && !verificationDocumentStatus) ||
        typeof verificationDocumentStatus == "boolean"
      ) {
        SnackbarManager.Instance.addWarning(
          getLanguage(
            486,
            "The status of the verification document(s) is currently unavailable"
          )
        );
      } else {
        if (this.props.creatingUser) {
          if (this.props.verificationDocumentStatus) {
            verificationDocumentStatus = this.props.verificationDocumentStatus;
          }
          let documentsRejected: boolean = false;
          if (
            verificationDocumentStatus.WorkPermit != 0 &&
            verificationDocumentStatus.WorkPermit != 3
          ) {
            documentsRejected = true;
          }
          if (
            verificationDocumentStatus.WorkPermitBack != 0 &&
            verificationDocumentStatus.WorkPermitBack != 3
          ) {
            documentsRejected = true;
          }
          if (
            verificationDocumentStatus.PictureId != 0 &&
            verificationDocumentStatus.PictureId != 3
          ) {
            documentsRejected = true;
          }
          if (
            verificationDocumentStatus.NationalID != 0 &&
            verificationDocumentStatus.NationalID != 3
          ) {
            documentsRejected = true;
          }

          if (!documentsRejected) {
            return this.props.history.push("/freelancer/profile");
          }
        }

        this.setState({ verificationDocumentStatus });
      }
    }
  }

  documentPhotoCapturePartial(
    documentType: VerificationDocumentType,
    submitText: string
  ) {
    return (
      <>
        <div className="preview-container">
          <ImagePreview src={this.state.image} />
        </div>
        <div className="photo-actions">
          <div className="photo-actions_btn">
            <Button
              onClick={() => this.setState({ isPhotoCaptureOpen: true })}
              variant="contained"
              component="span"
              className="default"
              color={"default"}
            >
              {this.state.image ? "Retake" : "Take photo"}
            </Button>
          </div>

          {this.state.image && (
            <div className="photo-actions_btn">
              <Button
                variant="contained"
                component="span"
                className="default"
                onClick={() => this.startImageUploadProcess(documentType)}
              >
                {submitText}
              </Button>
            </div>
          )}
        </div>
        <PhotoCapture
          onCapture={(src) =>
            this.setState({
              image: src,
              isPhotoCaptureOpen: false,
            })
          }
          isOpen={this.state.isPhotoCaptureOpen}
          onClose={() => this.setState({ isPhotoCaptureOpen: false })}
        />
      </>
    );
  }

  workpermitRequired() {
    if (
      this.state.verificationDocumentStatus &&
      this.state.verificationDocumentStatus.WorkPermit != 0
    ) {
      if (this.state.verificationDocumentStatus.WorkPermit == 1) {
        return (
          <div>
            <h2>{getLanguage(586, "Upload work permit")}</h2>
            {getLanguage(587, "Remember the following:")}
            <ul>
              <li>
                {getLanguage(
                  588,
                  "Make sure all corners of the document are visible"
                )}
              </li>
              <li>{getLanguage(589, "Make sure all the text is legible")}</li>
              <li>
                {getLanguage(
                  590,
                  "Make sure the experiation date is valid and clear"
                )}
              </li>
            </ul>
            {this.documentPhotoCapturePartial(0, getLanguage(114, "Upload"))}
          </div>
        );
      } else if (this.state.verificationDocumentStatus.WorkPermit == 2) {
        return (
          <div>
            <span>
              <i className="fas fa-circle-notch fa-spin placeholder" />{" "}
              {getLanguage(592, "Work permit pending")}
            </span>
          </div>
        );
      } else if (this.state.verificationDocumentStatus.WorkPermit == 3) {
        return (
          <div>
            <span>
              <i className="fas fa-check-circle fa-fw success" />{" "}
              {getLanguage(593, "Work permit approved")}
            </span>
          </div>
        );
      } else if (this.state.verificationDocumentStatus.WorkPermit == 4) {
        return (
          <div>
            <div>
              <i className="fas fa-minus-circle fa-fw danger" />{" "}
              {getLanguage(594, "Work permit declined")}
              {this.documentPhotoCapturePartial(0, getLanguage(591, "Retry"))}
            </div>
            <h3>{getLanguage(804, "grounds for rejection")}</h3>
            <p>{this.state.verificationDocumentStatus.RejectionReason}</p>
          </div>
        );
      }
    }
  }

  workpermitBackRequired() {
    if (
      this.state.verificationDocumentStatus &&
      this.state.verificationDocumentStatus.WorkPermitBack != 0
    ) {
      if (this.state.verificationDocumentStatus.WorkPermitBack == 1) {
        return (
          <div>
            <h2>{getLanguage(864, "Upload residence card (back)")}</h2>
            {this.documentPhotoCapturePartial(3, getLanguage(114, "Upload"))}
          </div>
        );
      } else if (this.state.verificationDocumentStatus.WorkPermitBack == 2) {
        return (
          <div>
            <span>
              <i className="fas fa-circle-notch fa-spin placeholder" />{" "}
              {getLanguage(861, "Work permit pending")}
            </span>
          </div>
        );
      } else if (this.state.verificationDocumentStatus.WorkPermitBack == 3) {
        return (
          <div>
            <span>
              <i className="fas fa-check-circle fa-fw success" />{" "}
              {getLanguage(862, "Work permit approved")}
            </span>
          </div>
        );
      } else if (this.state.verificationDocumentStatus.WorkPermitBack == 4) {
        return (
          <div>
            <div>
              <i className="fas fa-minus-circle fa-fw danger" />{" "}
              {getLanguage(863, "Work permit declined")}
              {this.documentPhotoCapturePartial(3, getLanguage(591, "Retry"))}
            </div>
          </div>
        );
      }
    }
  }

  pictureIdRequired() {
    if (
      this.state.verificationDocumentStatus &&
      this.state.verificationDocumentStatus.PictureId != 0
    ) {
      if (this.state.verificationDocumentStatus.PictureId == 1) {
        return (
          <div>
            <h2>{getLanguage(595, "Upload picture ID")}</h2>
            {getLanguage(587, "Remember the following:")}
            <ul>
              <li>
                {getLanguage(
                  588,
                  "Make sure all corners of the document are visible"
                )}
              </li>
              <li>{getLanguage(589, "Make sure all the text is legible")}</li>
              <li>
                {getLanguage(
                  590,
                  "Make sure the experiation date is valid and clear"
                )}
              </li>
            </ul>
            {this.documentPhotoCapturePartial(1, getLanguage(114, "Upload"))}
          </div>
        );
      } else if (this.state.verificationDocumentStatus.PictureId == 2) {
        return (
          <div>
            <span>
              <i className="fas fa-circle-notch fa-spin placeholder" />{" "}
              {getLanguage(596, "Picture ID uploaded and pending")}
            </span>
          </div>
        );
      } else if (this.state.verificationDocumentStatus.PictureId == 3) {
        return (
          <div>
            <span>
              <i className="fas fa-check-circle fa-fw success" />{" "}
              {getLanguage(597, "Picture ID approved")}
            </span>
          </div>
        );
      } else if (this.state.verificationDocumentStatus.PictureId == 4) {
        return (
          <div>
            <div>
              <i className="fas fa-minus-circle fa-fw danger" />{" "}
              {getLanguage(598, "Picture ID declined")}
              {this.documentPhotoCapturePartial(1, getLanguage(591, "Retry"))}
            </div>
            <h3>{getLanguage(804, "grounds for rejection")}</h3>
            <p>{this.state.verificationDocumentStatus.RejectionReason}</p>
          </div>
        );
      }
    }
  }

  nationalIdRequired() {
    if (
      this.state.verificationDocumentStatus &&
      this.state.verificationDocumentStatus.NationalID != 0
    ) {
      if (this.state.verificationDocumentStatus.NationalID == 1) {
        return (
          <div>
            <h2>{getLanguage(599, "Upload National ID")}</h2>
            {getLanguage(587, "Remember the following:")}
            <ul>
              <li>
                {getLanguage(
                  588,
                  "Make sure all corners of the document are visible"
                )}
              </li>
              <li>{getLanguage(589, "Make sure all the text is legible")}</li>
              <li>
                {getLanguage(
                  590,
                  "Make sure the experiation date is valid and clear"
                )}
              </li>
            </ul>
            {this.documentPhotoCapturePartial(2, getLanguage(114, "Upload"))}
          </div>
        );
      } else if (this.state.verificationDocumentStatus.NationalID == 2) {
        return (
          <div>
            <span>
              <i className="fas fa-circle-notch fa-spin placeholder" />{" "}
              {getLanguage(600, "National ID uploaded and pending")}
            </span>
          </div>
        );
      } else if (this.state.verificationDocumentStatus.NationalID == 3) {
        return (
          <div>
            <span>
              <i className="fas fa-check-circle fa-fw success" />{" "}
              {getLanguage(601, "National ID approved")}
            </span>
          </div>
        );
      } else if (this.state.verificationDocumentStatus.NationalID == 4) {
        return (
          <div>
            <div>
              <i className="fas fa-minus-circle fa-fw danger" />{" "}
              {getLanguage(602, "National ID declined")}
              {this.documentPhotoCapturePartial(2, getLanguage(591, "Retry"))}
            </div>
            <h3>{getLanguage(804, "grounds for rejection")}</h3>
            <p>{this.state.verificationDocumentStatus.RejectionReason}</p>
          </div>
        );
      }
    }
  }

  startImageUploadProcess(type: VerificationDocumentType) {
    this.setState({ type, showPageLoader: true });
    if (this.state.image) {
      this.saveImage(this.state.image, type);
    }
  }

  async saveImage(image: string, type: VerificationDocumentType) {
    if (type) {
      let documentSaved = await this.userHelper.uploadVerificationDocument(
        image,
        type
      );

      if (documentSaved) {
        SnackbarManager.Instance.addSuccess(
          getLanguage(489, "document uploaded")
        );
        this.getVerificationDocumentStatus();
        return;
      }
    }
    SnackbarManager.Instance.addError(
      getLanguage(490, "The document could not be uploaded")
    );
  }

  render() {
    let pageLoader;
    if (
      (!this.state.verificationDocumentStatus &&
        !this.props.verificationDocumentStatus) ||
      this.state.showPageLoader
    ) {
      if (this.props.creatingUser) {
        pageLoader = <PageLoader />;
      } else {
        pageLoader = <PageLoader releaseNavbar={true} />;
      }
    }

    return (
      <div>
        {/* --------- If Picture Id is required --------- */}
        <div>{this.pictureIdRequired()}</div>
        <br />

        {/* ------------If Work Permit is required-------------- */}
        <div>{this.workpermitRequired()}</div>
        <br />

        {/* ------------If Work Permit back is required-------------- */}
        <div>{this.workpermitBackRequired()}</div>
        <br />

        {/* ------------If National Id is required-------------- */}
        <div>{this.nationalIdRequired()}</div>
        {pageLoader}
      </div>
    );
  }
}
