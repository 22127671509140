import React, { Component } from "react";
import { TextField, Button, Collapse, Grid } from "@material-ui/core";
import { SavingSnackbar } from "../../../../../Parts/General/SavingSnackbar";
import { CompanyHelper } from "../../../../../../Helpers/CompanyHelper";
import {
  LanguageHelper,
  getLanguage
} from "../../../../../../Helpers/LanguageHelper";
import { PageLoader } from "../../../../../Parts/General/PageLoader";
import {
  getCurrentUser,
  setUserData
} from "../../../../../../Helpers/SessionHelper";
import { LoginModel } from "../../../../../../Models/LoginModel";
import { SnackbarManager } from "../../../../../../Helpers/SnackbarManager/SnackbarManager";

interface IProps {
  creatingUser?: boolean;
  saveStep?: ((stepBasicComplete: boolean) => void);
}

interface IState {
  company: any;
  loginObj: any;
  showPageLoader: boolean;
  cardholder: string;
  cardnumber: string;
  cvv: string;
  expireMonth: string;
  expireYear: string;
  email: string;
  invoiceEmail: string;
  creditCardExists: boolean;
  pageState: 'hidden' | 'unhidden' | 'changed';
  requestedInvoiceSolution: boolean;
}

export class CompanyStepPayment extends Component<IProps, IState> {
  companyHelper = new CompanyHelper();
  languageHelper = new LanguageHelper();

  constructor(props: IProps) {
    super(props);

    this.state = {
      company: null,
      loginObj: null,
      showPageLoader: false,
      cardholder: "",
      cardnumber: "",
      cvv: "",
      expireMonth: "",
      expireYear: "",
      email: "",
      invoiceEmail: "",
      creditCardExists: false,
      pageState: 'hidden',
      requestedInvoiceSolution: false
    };

    this.updateCardInfo = this.updateCardInfo.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.fillForm = this.fillForm.bind(this);
    this.getCreditCard = this.getCreditCard.bind(this)
    this.getPaymentLink = this.getPaymentLink.bind(this)
    this.requestInvoiceSolution = this.requestInvoiceSolution.bind(this)
  }

  async UNSAFE_componentWillMount() {
    await this.fillForm();
  }

  async fillForm() {
    await this.setState({ showPageLoader: true });
    let user = getCurrentUser() as LoginModel;
    user.ProfilePictureUrl = "";
    await this.setState({ loginObj: user });
    await this.setState({ company: user.Company });

    let email = user.Email;
    if (user && user.Company) {
      if (user.Company.InvoiceEmail && user.Company.InvoiceEmail !== "") {
        email = user.Company.InvoiceEmail;
      }
      // Naming inconsistencies from API; should be fixed to always be InvoiceEmail and never InvoiceMail!
      //@ts-ignore
      if (user.Company.InvoiceMail && user.Company.InvoiceMail !== "") {
        //@ts-ignore
        email = user.Company.InvoiceMail;
      }

    }

    await this.setState({ invoiceEmail: email });


    let creditCardExists = await this.getCreditCard()

    if (!creditCardExists && (user.Company && user.Company.ForceCreditcard)) {
      this.setState({ pageState: 'unhidden' })
    }

    await this.setState({ showPageLoader: false, creditCardExists });
    await this.setState({ showPageLoader: false });

  }



  async updateCardInfo() {
    await this.setState({ showPageLoader: true });
    // let newCardInfo = {
    //   cardholder: this.state.cardholder,
    //   cardnumber: this.state.cardnumber,
    //   cvc: this.state.cvv,
    //   expireMonth: this.state.expireMonth,
    //   expireYear: this.state.expireYear
    // };

    // let success = await this.companyHelper.updateCardDetails(newCardInfo);

    let success;

    let address = "";
    if (this.state.company && this.state.company.AddressLine1) {
      address = address.concat(this.state.company.AddressLine1);
      if (
        this.state.company.AddressLine2 &&
        this.state.company.AddressLine2 !== ""
      ) {
        address = address.concat(" ");
        address = address.concat(this.state.company.AddressLine2);
      }
    }

    let company = {
      CompanyName: this.state.company.CompanyName,
      Vat: this.state.company.Vat,
      AddressLine1: address,
      ZipCode: this.state.company.ZipCode,
      City: this.state.company.City,
      Phone: this.state.company.Phone,
      MobilePhone: this.state.company.MobilePhone,
      CountryId: this.state.company.CountryId,
      Login: { ProfilePictureUrl: "", Email: this.state.loginObj.Email },
      InvoiceEmail: this.state.invoiceEmail
    };

    if (
      JSON.stringify(this.state.loginObj.Email) !=
      JSON.stringify(this.state.invoiceEmail)
    ) {
      success = await this.companyHelper.updateCompany(company);
      if (success && success.Login) {
        let user = this.state.loginObj;
        user.Company.InvoiceMail = success.InvoiceMail;
        setUserData(user);
        await this.fillForm();
      }
    }

    success = await this.companyHelper.updateCompany(company);


    if (success) {
      SnackbarManager.Instance.addSuccess(getLanguage(697, 'Changes saved'));
      this.setState({
        pageState: 'hidden',
        cardholder: '',
        cardnumber: '',
        expireMonth: '',
        expireYear: '',
        cvv: ''

      });
      let user = this.state.loginObj;
      user.Company.InvoiceEmail = success.InvoiceMail;
      setUserData(user);

      await this.fillForm();
    } else {
      SnackbarManager.Instance.addError(getLanguage(698, 'Changes could not be saved'));
    }
    await this.setState({ showPageLoader: false });
    if (this.props.creatingUser && this.props.saveStep) {
      return this.props.saveStep(true);
    }
  }

  async resetForm() {
    this.setState({
      cardholder: "",
      cardnumber: "",
      cvv: "",
      expireMonth: "",
      expireYear: "",
      invoiceEmail: this.state.company.invoiceEmail
        ? this.state.company.invoiceEmail
        : this.state.loginObj.Email
    });
  }



  creatingUser() {
    if (this.props.creatingUser) {
      return (
        <div>
          <div className="text-right margin-v-32">
            <Button className="button-gradient" onClick={() => this.updateCardInfo()}>Next</Button>
          </div>
        </div>
      )
    }
    return (
      <div />
    )
  }

  async getCreditCard() {
    let creditCard = await this.companyHelper.getCreditCard();
    return creditCard;
  }

  async getPaymentLink() {
    let link = await this.companyHelper.getPaymentLink();
    console.log(link);
    window.open(link, "_blank")
  }

  async requestInvoiceSolution() {
    await this.companyHelper.requestInvoiceSolution();
    this.setState({
      requestedInvoiceSolution: true
    })
  }

  renderRequestInvoiceSolutionPart() {
    if (this.state?.company?.ForceCreditcard) {

      if (!this.state.requestedInvoiceSolution) {
        return <>
          <h2>{getLanguage(850, "Request invoice solution")}</h2>
          {getLanguage(851, "By requesting an invoice, your company will be rated for creditability according to Chabber's terms and conditions. You will thereafter be contacted on mail or phone.")}
          <br /><br />
          <Button className="button-gradient" onClick={() => this.requestInvoiceSolution()}>{getLanguage(852, "Request now")}</Button>
        </>
      }

      return <>
        <h2>{getLanguage(850, "Request invoice solution")}</h2>
        {getLanguage(853, "Thank you! We'll get back to you as soon as possible.")}
      </>
    }

    return <></>
  }

  render() {
    let savingSnackBar = (
      <SavingSnackbar
        open={this.state.pageState === 'changed'}
        resetClicked={this.resetForm}
        saveClicked={this.updateCardInfo}
      />
    );

    let nextButton = this.creatingUser();

    let btn = <Button className="button-gradient" onClick={() => this.setState({ pageState: 'unhidden' })}>Change creditcard info</Button>;
    if (this.state.pageState !== 'hidden') {
      btn = <Button className="button-gradient" onClick={() => this.setState({ pageState: 'hidden' })}>Hide creditcard info</Button>;
    }

    let msg = '';
    if (this.state.showPageLoader === false) {
      if (this.state.creditCardExists) {
        msg = getLanguage(560, "You have already registered a credit card");
      } else {
        msg = ' '
      }
    }

    let updateCardButton = <><br /><Button className="button-gradient" onClick={() => this.getPaymentLink()}>{getLanguage(846, "Add credit card (opens in a new window)")}</Button><br /><br /></>;
    if (this.state.creditCardExists) {
      updateCardButton = <><br /><Button className="button-gradient" onClick={() => this.getPaymentLink()}>{getLanguage(847, "Update credit card (opens in a new window)")}</Button><br /><br /></>;
    }

    return (
      <div>
        <TextField
          type="email"
          label={getLanguage(856, "Mail for receipts or invoices")}
          variant="outlined"
          fullWidth
          margin="normal"
          value={this.state.invoiceEmail}
          onChange={event =>
            this.setState({ invoiceEmail: event.target.value, pageState: 'changed' })
          }
        />

        {savingSnackBar}
        {this.state.showPageLoader ? <PageLoader /> : null}
        {nextButton}
        <br />
        <h2>{getLanguage(849, "Credit card")}</h2>
        <div>
          {msg}
        </div>
        {updateCardButton}

        <br />
        {this.renderRequestInvoiceSolutionPart()}
      </div>
    );
  }
}
