import React, { Component } from "react";
import Switch from "@material-ui/core/Switch";
import "./Features.scss";
import { FeatureHelper } from "../../../../Helpers/FeatureHelper";
import { StorageHelper } from "../../../../Helpers/StorageHelper";


interface IProps {
  countryId: string
}

interface IState {
  allFeatures:
  {
    Id: string;
    Activated: boolean;
    CountryId: string;
    FeatureName: string;
  }[];
}



export class Features extends Component<IProps, IState> {
  storageHelper = new StorageHelper();
  timeToRun = true;
  featureHelper = new FeatureHelper();
  runOnce = true;
  countryActive = '';

  constructor(props: IProps) {
    super(props);


    this.state = {
      allFeatures: [],
    };

    this.getAllFeatures = this.getAllFeatures.bind(this)

    // this.featureHelper.createFeature('myTestFeature');
  }

  async componentDidMount() {
    if (this.timeToRun) {
      this.timeToRun = false;
      let country = await this.storageHelper.getCountryFromLocal();
      if (country)
        this.countryActive = country;
      await this.getAllFeatures();

    }
  }

  // async createFeature(nameOfFeature: string){
  //   await this.featureHelper.createFeature(nameOfFeature);
  // }


  async getAllFeatures() {
    let country = await this.storageHelper.getAtlasCountryFromLocal()
    let features;
    if (country) {
      features = await this.featureHelper.getAllFeatures(country);

      if (features !== false && features !== true) {
        await this.setState({ allFeatures: features })
      }

    }
  }

  async activationChanged(event: any, elem: any) {
    let activationState = elem.Activated ? false : true;
    let country = await this.storageHelper.getAtlasCountryFromLocal()
    let changedActivationState;
    if (country)
      changedActivationState = await this.featureHelper.changeStateOfFeatureForCountry(activationState, elem.FeatureName, country)
    if (changedActivationState) {
      await this.getAllFeatures();
    }
  }


  render() {
    return (
      <div className="features">
        <div className="AdminPageHeader">
          <div className="AdminPageTitle">Release features</div>
          <div className="AdminPageHeader_options">
            <div className="inputGroup">
              <input
                className="inputGroup_item"
                type="text"
                placeholder="Search..."
              />
            </div>
          </div>
        </div>
        <div className="paper padding-24 radius-6 MainInfoAdmin">
          <h2>List of features</h2>
          {/* Loop from here */}
          {this.state.allFeatures.map(row => (
            <div className="toggleBlock" key={row.Id}>
              <div className="toggleBlock_title">
                {row.FeatureName}
              </div>
              <div className="toggleBlock_switch">
                <Switch checked={row.Activated} onClick={(event) => this.activationChanged(event, row)} className="toggleSwitch" color="primary" />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
