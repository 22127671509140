// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { Grid, Button, Hidden } from "@material-ui/core";
import { getLanguage, getDateFormatAccordingToLanguage } from "../../../../../Helpers/LanguageHelper";
import "./Contract.scss";
import { getCurrentUser } from "../../../../../Helpers/SessionHelper";
import { RequestHelper } from "../../../../../Helpers/RequestHelper";
import { RequestModel } from "../../../../../Models/RequestModel";
import { NavLink } from "react-router-dom";
import { CMSHelper, CMSTypes } from "../../../../../Helpers/CMSHelper";
import { StorageHelper } from "../../../../../Helpers/StorageHelper";
import { CMSRevisionModel } from "../../../../../Models/CMSRevisionModel";
import { PageLoader } from "../../../../Parts/General/PageLoader";


interface IProps extends RouteComponentProps {

}

interface IState {
  firstContract: RequestModel | null;
  contract: CMSRevisionModel | null;
  isLoading: boolean;
}


export class Contract extends Component<IProps, IState> {
  cmsHelper = new CMSHelper();
  storageHelper = new StorageHelper();

  constructor(props: IProps) {
    super(props);


    this.state = {
      firstContract: null,
      contract: null,
      isLoading: false
    }

    this.renderContract = this.renderContract.bind(this)
    this.showPrintButton = this.showPrintButton.bind(this)
  }

  async componentDidMount() {
    const localData = this.storageHelper.getFacadeLocalization();
    if (localData === null) return;

    const {PrimaryLanguage} = getCurrentUser();

    this.setState({ isLoading: true })
    const [firstContract,contract] = await Promise.all([
      new RequestHelper().getFirstConfirmed(),
      this.cmsHelper.getPrimaryDraftByCountry(localData.country.Id, CMSTypes.FreelancerContract, PrimaryLanguage)
    ])

    // TODO: Handle error

    this.setState({ firstContract, contract, isLoading: false })
  }

  renderContract() {
    let contractText = this.getContractText();

    if (typeof contractText !== 'string') {
      return contractText;
    }

    return <div className="contractWrapper">
      <div dangerouslySetInnerHTML={{ __html: contractText }}></div>
    </div>

  }

  getContractText() {
    let currentUser = getCurrentUser();
    if (!currentUser || !currentUser.Freelancer || this.state.firstContract == null) {
      return (
        <div></div>
      )
    }

    const dateToBePlacedOnContract = getDateFormatAccordingToLanguage(
      currentUser.Created.toString(),
      true,
      false
    );

    let address = '';
    if (currentUser.Freelancer && currentUser.Freelancer.AddressLine1) {
      address = currentUser.Freelancer.AddressLine1;
      if (currentUser.Freelancer.AddressLine2) {
        address += " " + currentUser.Freelancer.AddressLine2;
      }
    }

    const dublets = [
      {
        from: "%aktueldato%",
        to: dateToBePlacedOnContract,
      },
      {
        from: "%navn%",
        to: currentUser.Firstnames + " " + currentUser.Lastname,
      },
      {
        from: "%adresse%",
        to: address,
      },
      {
        from: "%postnr%",
        to: currentUser.Freelancer.ZipCode,
      },
      {
        from: "%city%",
        to: currentUser.Freelancer.City,
      },
    ];
    
    return this.cmsHelper.transformText(this.state.contract, dublets).Text;
  }


  showPrintButton() {
    let currentUser = getCurrentUser();
    if (!currentUser || !currentUser.Freelancer) {
      return (
        <div></div>
      )
    }


    if (currentUser.Freelancer.CountHired >= 1) {
      return (
        <div>
          <Button className="white" variant="contained" size="medium">
            <span className="btn-icon">
              <i className="fas fa-print" />
            </span>
            Print
              </Button>
        </div>
      )
    } return <div></div>;
  }

  render() {

    if (this.state.isLoading) return <PageLoader />;

    return (
      <div className="freelancerContract">
        <div className="margin-v-24">
          <Grid
            container
            spacing={1}
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Hidden smUp>
                <NavLink to="/freelancer/settings">
                  <i className="fas fa-arrow-left fa-lg margin-right-16"></i>
                </NavLink>
              </Hidden>
              <h1 className="inline-block">{getLanguage(53, "Contract")}</h1>
            </Grid>
            <Grid item>
              {this.showPrintButton()}
            </Grid>
          </Grid>
        </div>
        <div>
          {this.renderContract()}
        </div>
      </div>
    );
  }
}
