import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import qs from 'query-string';
import { RouteComponentProps } from 'react-router';
import { UserHelper } from '../../../../Helpers/UserHelper';
import { TextField, Button } from '@material-ui/core';
import { getLanguage } from '../../../../Helpers/LanguageHelper';
import { StorageHelper } from '../../../../Helpers/StorageHelper';
import { SnackbarManager } from '../../../../Helpers/SnackbarManager/SnackbarManager';

interface IProps extends RouteComponentProps { }
interface IState {
  id: string;
  token: string;
  pageState: 'wrongData' | 'setNewPassword' | 'resetting' | 'password reset'
  password1: string;
  password2: string;
  passwordError1: string;
  passwordError2: string;
}
export class ResetPassword extends Component<IProps, IState> {
  userHelper = new UserHelper();

  constructor(props: IProps) {
    super(props);
    let query = qs.parse(this.props.location.search);
    let id;
    let token;

    if (typeof (query.id) === 'string') {
      id = query.id;
    }
    if (typeof (query.token) === 'string') {
      token = query.token;
    }

    this.state = {
      id: id || '',
      token: token || '',
      pageState: (id !== '' && token !== '') ? 'setNewPassword' : 'wrongData',
      password1: '',
      password2: '',
      passwordError1: '',
      passwordError2: '',
    }

    this.submitPasswords = this.submitPasswords.bind(this)
  }


  async submitPasswords() {
    if (this.state.password1 === '' || this.state.password1 === null) {

      this.setState({ passwordError1: 'password is empty' });
      return;
    }

    if (this.state.password2 === '' || this.state.password2 === null) {
      this.setState({ passwordError2: 'password is empty' });
      return;
    }

    if (this.state.password1 !== this.state.password2) {
      this.setState({ passwordError2: 'passwords do not mach' });
      return;
    }
    let envVar = new StorageHelper().getEnvVar();
    if (this.state.password1.length < 6 && envVar === 'Production') {
      this.setState({ passwordError2: 'passwords is too short' });
      return;
    }

    await this.setState({ pageState: 'resetting' })
    let response = await this.userHelper.resetForgotPassword(this.state.id, this.state.token, this.state.password1);

    if (response) {
      this.setState({ pageState: 'password reset' });
    } else {
      SnackbarManager.Instance.addError(getLanguage(469, 'unknown error'));
    }
  }

  renderInputPassword() {
    return <div className="padding-40">
      <div className="margin-v-24">
        <h1>{getLanguage(607, "Reest password")}</h1>
      </div>
      <TextField
        type="password"
        label={getLanguage(119, "New password")}
        variant="outlined"
        fullWidth
        margin="normal"
        onClick={() => this.setState({ passwordError1: '' })}
        value={this.state.password1}
        onChange={e => this.setState({ password1: e.target.value })}
        error={this.state.passwordError1 !== '' && this.state.passwordError1 !== null}
        helperText={this.state.passwordError1 || ''}
      />

      <TextField
        type="password"
        label={getLanguage(120, "Repeat new password")}
        variant="outlined"
        fullWidth
        name="repeat password"
        margin="normal"
        onClick={() => this.setState({ passwordError2: '' })}
        value={this.state.password2}
        onChange={e => this.setState({ password2: e.target.value })}
        error={this.state.passwordError2 !== '' && this.state.passwordError2 !== null}
        helperText={this.state.passwordError2 || ''}
      />
      <div className="margin-top-40 text-right">
        <Button
          className="button-gradient"
          variant="contained"
          size="large"
          onClick={this.submitPasswords}
          type="submit"
        >
          {getLanguage(608, "Reset")}
        </Button>
      </div>
    </div>
  }

  renderResetting() {
    return <div className="padding-40">
      <i className="fas fa-unlock fa-2x"></i>
      <p>{getLanguage(609, "Resetting password")}</p>
    </div>
  }


  renderPasswordReset() {
    return <div>
      <div className="padding-40">
        <i className="fas fa-lock fa-2x"></i>
        <p>{getLanguage(610, "Password has been reset")}</p>
        <Button onClick={() => this.props.history.push('/login')}>{getLanguage(611, "Go to login")}</Button>
      </div>
    </div>
  }


  render() {
    let content = <p>missing data</p>;
    if (this.state.pageState === 'setNewPassword') {
      content = this.renderInputPassword();
    } else if (this.state.pageState === 'resetting') {
      content = this.renderResetting();
    } else if (this.state.pageState === 'password reset') {
      content = this.renderPasswordReset();
    }

    if (this.state.id && this.state.token) {
      return <div>
        <div className="backgroundGradient vh-100">
          <div className="text-center">
            <div className="loginPaper">{content}</div>
          </div>
        </div>
      </div>
    } else {
      return <Redirect to='/' />
    }
  }
}