// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from "react";
import "./FreelancerFeedback.scss";
import { Grid, } from "@material-ui/core";
import { getLanguage } from "../../../../Helpers/LanguageHelper";
import { PostingModel } from "../../../../Models/PostingModel";
import { RequestModel } from "../../../../Models/RequestModel";
import moment from "moment";
import { FeedbackModel } from "../../../../Models/FeedbackModel";
import { CompanyHelper } from "../../../../Helpers/CompanyHelper";
import { RatingModel } from "../../../../Models/RatingModel";
import { getTimeBetweenDateTimes, getNowAsDbString, nowAsFormatedString, addToDate } from "../../../../Helpers/DateTimeHelper";
import Rating from '@material-ui/lab/Rating';
import { SnackbarManager } from "../../../../Helpers/SnackbarManager/SnackbarManager";


interface IProps {
  posting: PostingModel;
  request: RequestModel;
  rating: RatingModel | null;
  ratingChanged: (value: number) => Promise<void>;
}
interface IState {
  submitting: boolean;
  canRate: boolean;
}

export class FreelancerFeedback extends Component<IProps, IState> {
  companyHelper = new CompanyHelper();

  constructor(props: IProps) {
    super(props);


    let canRate = true;

    // Rating was created less than 24 hours ago
    if (this.props.rating && this.props.rating.Created > addToDate(nowAsFormatedString(), 24, 'hours')) {
      canRate = false;
    }
    // Job ended less than 30 days ago
    if (this.props.request && this.props.request.EndDateTimeLocal > addToDate(nowAsFormatedString(), 30, 'days')) {
      canRate = false;
    }

    this.state = {
      submitting: false,
      canRate
    }

    this.getJobState = this.getJobState.bind(this);
    this.giveRating = this.giveRating.bind(this);
  }

  getJobState() {
    let dateDifference = getTimeBetweenDateTimes(getNowAsDbString(), this.props.request.EndDateTimeLocal, 'days')

    if (this.props.request.FinalMinutesWorked !== 0 || dateDifference < -3) {
      let startTime = moment(this.props.request.WorkDateTimeLocal || undefined);
      let endTime = moment(this.props.request.EndDateTimeLocal);

      let finalMinutes = this.props.request.FinalMinutesWorked;
      let overtime = null
      if (finalMinutes > 1) {
        let proposedMinutes = endTime.diff(startTime, 'minutes');
        let overtimeHours = ((finalMinutes - proposedMinutes) / 60.0).toFixed(1);

        overtime = <p className="margin-0">
          <b>({overtimeHours} {getLanguage(391, "hours overtime")})</b>
        </p>;
      }

      let endTimeVisual = endTime;
      if (finalMinutes > 1) {
        endTimeVisual = moment(startTime).add('minutes', finalMinutes);
      }

      return <div>
        <p className="margin-0">
          {getLanguage(390, "Registered work time")}: {startTime.format('HH:mm')} - {endTimeVisual.format('HH:mm')} {" "}
        </p>
        {overtime}
      </div>
    }
    let str = getLanguage(773, '{{company}} has 3 days from the shifts end to register overtime and the like');
    str = str.replace('{{company}}', this.props.posting.Company?.CompanyName || '');
    return <p className="margin-top-0">{str}</p>;
  }



  getPayoutDetails() {
    let salaryDate = moment(this.props.request.DateForSalaryPayout);
    if (salaryDate.isValid() === false) {
      let date = this.props.request.DateForSalaryPayout.split(' ')[0];
      salaryDate = moment(date);
    }


    if (salaryDate.isBefore(moment())) {
      return (<div className="payoutBadge paymentSuccess">
        {getLanguage(292, "Paid out:")}{salaryDate.format('L')}
      </div>)
    }
    return (<div className="payoutBadge paymentIncoming">
      {getLanguage(270, "Pay-day")} {salaryDate.format('L')}
    </div>)
  }

  async giveRating(val: number) {
    if (!this.state.canRate || val === this.props.rating?.RatingValue) return;
    let oldRating = this.props.rating?.RatingValue || 0;

    let feedback = new FeedbackModel({
      Comment: '',
      DidntShowUp: 0,
      LeftEarlyHours: null,
      OverallRating: val,
      OvertimeHours: 0,
      OvertimeSalaryPerHour: '',
      Rating1: val,
      Rating2: val,
      Rating3: val,
      Released: true,
      RequestId: this.props.request.Id,
    });

    await this.props.ratingChanged(val * 20);
    await this.setState({ submitting: true });
    let response = await this.companyHelper.submitFeedback([feedback]);
    if (response === true) {
      SnackbarManager.Instance.addSuccess(getLanguage(697, 'Changes saved'));
    }
    else {
      await this.props.ratingChanged(oldRating);
      SnackbarManager.Instance.addError(getLanguage(698, 'Changes could not be saved'));
    }
    this.setState({ submitting: false });

  }


  render() {
    let rating = 0;
    if (this.props.rating && this.props.rating.RatingValue > 5) {
      rating = this.props.rating.RatingValue / 20;
    } else if (this.props.rating != null) {
      rating = this.props.rating.RatingValue;
    }


    return (
      <div className="feedbackComponent">
        <div className="paper radius-12 padding-24 margin-bottom-16">
          <Grid container justify="center" alignItems="center">
            <Grid item container xs={12} sm={9} spacing={3} alignItems="center">
              <Grid item xs={12} sm={"auto"}>
                <div className="rateSection">
                  <div>
                    <Rating
                      className="ratingStars"
                      name="simple-controlled"
                      readOnly={this.state.canRate === false || this.state.submitting}
                      value={rating}
                      onChange={(e, value) => this.giveRating(Number(value))}
                      emptyIcon={<i className="far fa-star" />}
                      icon={<i className="fas fa-star" />}
                    />
                  </div>
                  <p className="placeholder text-center no-margin-bottom">
                    {getLanguage(388, "Rate")}
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} sm={"auto"}>
                <div className="">
                  <h2 className="margin-0"> {getLanguage(389, "Job completed")}</h2>
                  {this.getJobState()}
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3}>
              {this.getPayoutDetails()}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
