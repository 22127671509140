// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from 'react';
import { PostingModel } from '../../../../../Models/PostingModel';
import { RouteComponentProps } from 'react-router';
import { RequestModel } from '../../../../../Models/RequestModel';
import FreelancerSlider from "./FreelancerSlider";
import { FreelancerModel } from '../../../../../Models/FreelancerModel';
import MasterSlider from './MasterSlider';
import { Tooltip, IconButton, Grid, TextField, Grow, Collapse } from '@material-ui/core';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { SavingSnackbar } from '../../../../Parts/General/SavingSnackbar';
import { getTimeAsNumber, getFavouritedStatus, getMinimumSalary, ICompanyFeedback, DialogueTypes, FavouriteStatus, getFaveLangCodeForTooltip, getTimeRangeAsNumbers } from "./PostingHelper";
import { FavoriteProfileModel } from '../../../../../Models/FavoriteProfileModel';
import { StorageHelper } from '../../../../../Helpers/StorageHelper';
import { getLanguage } from '../../../../../Helpers/LanguageHelper';
import { FreelancerMenu } from "./FreelancerMenu";
import { FreelancerDialogue } from "./Dialogues/FreelancerDialogue";
import { ManualDialogue } from "./Dialogues/ManualDialogue";
import { NoShowDialogue } from './Dialogues/NoShowDialogue';
import { PermahireDialogue } from "./Dialogues/PermahireDialogue";
import { FeedbackModel } from '../../../../../Models/FeedbackModel';
import { CompanyHelper } from '../../../../../Helpers/CompanyHelper';
import "./Feedback.scss";
import { OvertimeRequestModel } from '../../../../../Models/OvertimeRequestModel';
import { SnackbarManager } from '../../../../../Helpers/SnackbarManager/SnackbarManager';
import { ProfilePictureViewer } from '../../../../Parts/General/ProfilePictureViewer';
import { RatingModel } from '../../../../../Models/RatingModel';
import { compareChangedDatePrecise, nowAsFormatedString, addToDate } from '../../../../../Helpers/DateTimeHelper';
import Rating from '@material-ui/lab/Rating';

interface IProps extends RouteComponentProps {
  posting: PostingModel;
  requests: RequestModel[];
  favourites: FavoriteProfileModel[];
  isDirty: boolean;
  overtimeRequests: OvertimeRequestModel[];
  ratings: RatingModel[];
  changeIsDirty: (val?: boolean) => void
}

interface IState {
  feedbackArray: ICompanyFeedback[];
  minTime: number;
  maxTime: number;
  globalMax: number;
  startTime: number;
  endTime: number;
  masterSliderValue: number;
  chabberMenuElement: Element | null;
  activeFeedback: ICompanyFeedback | null;
  openDialogue: DialogueTypes | null;
  activeRequestMinimumSalary: number;
  past3Days: boolean;
}


export class Feedback extends Component<IProps, IState> {
  storageHelper = new StorageHelper();
  companyHelper = new CompanyHelper();

  constructor(props: IProps) {
    super(props);
    this.state = {
      feedbackArray: [],
      minTime: 0,
      maxTime: 0,
      globalMax: 3,
      startTime: 0,
      endTime: 0,
      masterSliderValue: 0,
      chabberMenuElement: null,
      activeFeedback: null,
      openDialogue: null,
      activeRequestMinimumSalary: 0,
      past3Days: false,
    }

    this.changeSlider = this.changeSlider.bind(this);
    this.masterSliderChanged = this.masterSliderChanged.bind(this);
    this.initializePage = this.initializePage.bind(this);
    this.onMaxChanged = this.onMaxChanged.bind(this);
    this.addOrWithdrawFromFavorites = this.addOrWithdrawFromFavorites.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.giveRating = this.giveRating.bind(this);
    this.openOptions = this.openOptions.bind(this);
    this.closeDialogue = this.closeDialogue.bind(this);
    this.setOvertimeSalary = this.setOvertimeSalary.bind(this);
    this.markNoShow = this.markNoShow.bind(this);
    this.permaHire = this.permaHire.bind(this);
  }

  componentDidMount() {
    this.initializePage();
  }


  async initializePage() {
    let { requests, posting } = this.props;

    if (!requests || !posting) {
      return;
    }
    let feedbackArray: ICompanyFeedback[] = requests.map(request => {
      //FIXME: Get rid of Freelancerprofile, use Freelancer instead
      let freelancer = request.Freelancer || request.FreelancerProfile as FreelancerModel

      let savedRating = this.props.ratings.find(x => x.RequestId === request.Id && x.GivenToCompany === false);
      let ratingReadOnly = false;
      let rating = -1;

      if (savedRating) {
        rating = savedRating.RatingValue;
        if (rating > 5) rating /= 20;
        rating = Math.round(rating);

        if (addToDate(savedRating.Created, 24, 'hours') < nowAsFormatedString()
          || addToDate(request.EndDateTimeLocal, 30, 'days') < nowAsFormatedString()) {
          ratingReadOnly = true;
        }
      }

      let overtimeRequest = this.props.overtimeRequests.find(x => x.RequestId === request.Id && !x.Executed);
      let minimumSalary = getMinimumSalary(request);
      let overtimeSalary = overtimeRequest ? overtimeRequest.SalaryPerHour / 100 : 0;

      let endTime = getTimeRangeAsNumbers(request.WorkDateTimeLocal, request.EndDateTimeLocal)[1] + this.calculateOvertime(request, overtimeRequest);

      let feedback: ICompanyFeedback = {
        request,
        freelancer: freelancer,
        overtimeRequest,
        endTime,
        favourited: getFavouritedStatus(freelancer, this.props.favourites),
        rating,
        originalRating: rating,
        overtimeSalary: overtimeSalary || minimumSalary,
        minimumOvertimeSalary: minimumSalary,
        hired: false, //FIXME: Can we get this from somewhere? .. we can not .. not with .net
        noShow: false,
        ratingReadOnly,
        comment: savedRating?.Statement ?? ''
      }
      return feedback;
    });

    let [startTime, endTime] = getTimeRangeAsNumbers(posting.StartAtLocal, posting.EndAtLocal)
    let maxTime = endTime + this.state.globalMax;
    let highest = Math.max(...feedbackArray.map(o => o.endTime), feedbackArray[0].endTime);
    if (highest >= maxTime) {
      maxTime = highest + 1;
    }

    let minTime = startTime + 4;
    if (minTime > endTime) minTime = endTime;

    let masterSliderValue = endTime;
    if (feedbackArray.length === 1) {
      masterSliderValue = feedbackArray[0].endTime;
    }
    let past3Days = compareChangedDatePrecise(this.props.posting.EndAtLocal, nowAsFormatedString(), -72, 'hours') !== 'future';
    await this.setState({
      feedbackArray,
      maxTime,
      minTime,
      startTime,
      endTime,
      masterSliderValue,
      past3Days
    });

    this.props.changeIsDirty(false);
  }
  openOptions(e: React.MouseEvent<Element, MouseEvent>, activeFeedback: ICompanyFeedback) {
    this.setState({ chabberMenuElement: e.currentTarget, activeFeedback });
  }

  calculateOvertime(request: RequestModel, overtimeRequest: OvertimeRequestModel | undefined) {
    let [startTime, endTime] = getTimeRangeAsNumbers(request.WorkDateTimeLocal, request.EndDateTimeLocal);
    let overtime = 0;
    if (overtimeRequest && !overtimeRequest.Executed) {
      if (request.FinalMinutesWorked) {
        overtime = request.FinalMinutesWorked / 60 - (endTime - startTime);
      }
      overtime += overtimeRequest.AddMinutes / 60;
    }
    else if (request.FinalMinutesWorked) {
      overtime = request.FinalMinutesWorked / 60 - (endTime - startTime);
    }
    return overtime;
  }

  async changeSlider(value: number, requestId: string) {
    let feedbackObj = this.state.feedbackArray.find(x => x.request.Id === requestId);
    if (!feedbackObj) return;
    let overtimeObj = feedbackObj.overtimeRequest;
    let overtime = this.calculateOvertime(feedbackObj.request, overtimeObj);
    let originalEnd = getTimeRangeAsNumbers(this.props.posting.StartAtLocal, this.props.posting.EndAtLocal)[1] + overtime;

    if (this.state.past3Days && value < originalEnd) return;
    if (value < this.state.minTime) value = this.state.minTime;

    let feedbackArray = this.state.feedbackArray.map(x => x);
    let feedback = feedbackArray.find(x => x.request.Id === requestId);
    if (!feedback) return;


    feedback.endTime = value;
    await this.setState({ feedbackArray });
    this.props.changeIsDirty();
  }


  async masterSliderChanged(value: number) {
    // 72 hours later is too late for shortening the time
    let end = getTimeRangeAsNumbers(this.props.posting.StartAtLocal, this.props.posting.EndAtLocal)[1];
    if (this.state.past3Days && value < end) return;

    if (value < this.state.minTime) value = this.state.minTime;
    let feedbackArray: ICompanyFeedback[] = this.state.feedbackArray.map(feedbackObject => {
      let endTime = value;

      if (this.state.past3Days) {
        let overtime = this.calculateOvertime(feedbackObject.request, feedbackObject.overtimeRequest);
        let originalEnd = getTimeRangeAsNumbers(this.props.posting.StartAtLocal, this.props.posting.EndAtLocal)[1] + overtime;

        if (endTime < originalEnd) endTime = originalEnd;
      }

      let feedback: ICompanyFeedback = {
        ...feedbackObject,
        endTime,
      }
      return feedback;
    })

    await this.setState({ masterSliderValue: value, feedbackArray });
    this.props.changeIsDirty();
  }


  onMaxChanged(changeBy: number) {
    let maxTime = this.state.maxTime;
    let changedTime = maxTime + changeBy;
    let index = this.state.feedbackArray.findIndex(x => x.endTime > changedTime);

    if (changedTime > getTimeAsNumber(this.props.posting.EndAtLocal) + 3
      && changedTime <= getTimeAsNumber(this.props.posting.StartAtLocal) + 24
      && changedTime >= this.state.masterSliderValue
      && index === -1
    ) {
      maxTime += changeBy;
      this.setState({ maxTime });
    }
  }


  async addOrWithdrawFromFavorites(feedback: ICompanyFeedback) {
    let feedbackArray = this.state.feedbackArray.map(x => x);
    let currentFeedback = feedbackArray.find(x => x.request.Id === feedback.request.Id);
    if (currentFeedback == null) return;

    switch (feedback.favourited) {
      case FavouriteStatus.confirmedFavorite:
      case FavouriteStatus.invitedToBeFavorite:
      case FavouriteStatus.notConfirmedFavorite:
        currentFeedback.favourited = FavouriteStatus.notInvitedToBeFavorite;
        break;
      case FavouriteStatus.notInvitedToBeFavorite:
        currentFeedback.favourited = FavouriteStatus.invitedToBeFavorite;
        break;
    }
    //FIXME: Should not set state etc, but rather should update to API directly
    await this.setState({ feedbackArray });
    this.props.changeIsDirty();
  }


  async saveChanges() {
    let feedbackArrayToSave: FeedbackModel[] = [];

    let favouriteRemoveInviteArr: FavoriteProfileModel[] = [];
    let faveouriteInviteArr: { FreelancerProfileId: string }[] = [];

    for (const feedbackObject of this.state.feedbackArray) {
      let leftEarlyHours = 0.0;
      let overtimeHours = 0.0;

      let hours = feedbackObject.endTime - (getTimeRangeAsNumbers(this.props.posting.StartAtLocal, this.props.posting.EndAtLocal)[1]);
      if (hours < 0) {
        leftEarlyHours = Math.abs(hours);
      } else if (hours > 0) {
        overtimeHours = hours;
      }
      let rating: number | undefined = feedbackObject.rating;
      if (rating === -1) rating = undefined;
      else rating = Math.round(rating);

      let saveObject = new FeedbackModel({
        DidntShowUp: feedbackObject.noShow ? 1 : 0, // TODO: this really should just be a boolean
        OverallRating: rating,
        Rating1: rating,
        Rating2: rating,
        Rating3: rating,
        Comment: feedbackObject.comment,
        LeftEarlyHours: leftEarlyHours,
        OvertimeHours: overtimeHours,
        OvertimeSalaryPerHour: feedbackObject.overtimeSalary.toString(),
        RequestId: feedbackObject.request.Id,
      });
      feedbackArrayToSave.push(saveObject);

      //get all changes to favourites
      let favourite = this.props.favourites.find(x => x.FreelancerProfileId === feedbackObject.freelancer.Id);

      if (favourite == null && feedbackObject.favourited === FavouriteStatus.invitedToBeFavorite) {
        faveouriteInviteArr.push({ FreelancerProfileId: feedbackObject.freelancer.Id });
      } else if (
        favourite != null
        && favourite.ApprovalStatus !== feedbackObject.favourited
        && feedbackObject.favourited === FavouriteStatus.notInvitedToBeFavorite) {

        favouriteRemoveInviteArr.push(favourite);
      }

    }
    //Save feedback (except favourites);
    let success = await this.companyHelper.submitFeedback(feedbackArrayToSave);

    if (success === true) {
      //Save favourites
      let allFavouriteCalls = [this.companyHelper.sendFavoriteInvites(faveouriteInviteArr)];

      for (const fave of favouriteRemoveInviteArr) {
        allFavouriteCalls.push(this.companyHelper.deleteFavorite(fave));
      }

      await Promise.all(allFavouriteCalls); // TODO: How do we check the results to see if they were successful? What even is the return value apart from any..?


      await this.props.changeIsDirty(false);
      SnackbarManager.Instance.addSuccess(getLanguage(684, 'Feedback saved'));
    } else {
      SnackbarManager.Instance.addError(getLanguage(685, 'Could not save feedback'));
    }
  }

  async giveRating(feedbackId: string, val: number) {
    let feedbackArray = [...this.state.feedbackArray];
    let selectedFeedbackObj = feedbackArray.find(x => x.request.Id === feedbackId);
    if (selectedFeedbackObj == null || selectedFeedbackObj.rating === val) return;

    selectedFeedbackObj.rating = val;
    await this.setState({ feedbackArray });
    this.props.changeIsDirty();
  }

  renderChabberRating(feedback: ICompanyFeedback, requestId: string) {
    return (
      <div className="inline-block">
        <Rating
          readOnly={feedback.ratingReadOnly}
          name={"rating " + requestId}
          className="ratingStars"
          value={feedback.rating}
          emptyIcon={<i className="far fa-star" />}
          icon={<i className="fas fa-star" />}
          disabled={feedback.noShow}
          onClick={(e) => {
            // @ts-ignore
            this.giveRating(requestId, Number(e.target.value))
          }}
        />
      </div>
    );
  }


  openDialogue(dialogueName: DialogueTypes) {
    this.setState({ openDialogue: dialogueName, chabberMenuElement: null });
  }

  closeDialogue() {
    this.setState({ openDialogue: null, activeFeedback: null })
  }

  openManualModeDialog() {
    let feedback: ICompanyFeedback = this.state.activeFeedback as ICompanyFeedback;

    let minimumSalary = getMinimumSalary(feedback.request);
    if (minimumSalary < feedback.overtimeSalary) minimumSalary = feedback.overtimeSalary;

    this.setState({ chabberMenuElement: null, openDialogue: DialogueTypes.manual, activeRequestMinimumSalary: minimumSalary });
  }


  async setOvertimeSalary() {
    let { activeFeedback } = this.state;
    if (activeFeedback == null) return;

    if (this.state.activeRequestMinimumSalary < activeFeedback.minimumOvertimeSalary) {
      SnackbarManager.Instance.addWarning(getLanguage(563, 'overtime salary must be higher than highest salary interval'));
    } else {

      let feedbackArray = this.state.feedbackArray.map(x => x);
      let foundFeedback = feedbackArray.find(x => x.request.Id === activeFeedback!.request.Id);
      foundFeedback!.overtimeSalary = this.state.activeRequestMinimumSalary;

      await this.setState({ openDialogue: null, activeFeedback: null, activeRequestMinimumSalary: 0, feedbackArray: feedbackArray })
      this.props.changeIsDirty();
    }

  }


  async markNoShow() {
    let noShowChabber = this.state.activeFeedback;
    let feedbackArray = this.state.feedbackArray.map(x => x);
    let feedback = feedbackArray.find(x => x.request.Id === noShowChabber!.request.Id);

    feedback!.noShow = true;
    await this.setState({ openDialogue: null, activeFeedback: null, feedbackArray });
    this.props.changeIsDirty();
  }

  async permaHire() {
    // How do we mark the chabber as hired?
    let feedbackObj = this.state.activeFeedback as ICompanyFeedback;
    let feedbackArray = this.state.feedbackArray.map(x => x);
    let selectedFeedback = feedbackArray.find(x => x.request.Id === feedbackObj.request.Id);
    let success = await this.companyHelper.permaHireChabber(feedbackObj.request.Id);

    let name = '';
    let login = feedbackObj.request.FreelancerProfile!.Login;
    if (login) {
      name = login.Firstnames + ' ' + login.Lastname;
    }

    if (success) {
      selectedFeedback!.hired = true;
      await this.setState({ feedbackArray: feedbackArray, openDialogue: null, activeFeedback: null });
      SnackbarManager.Instance.addSuccess(getLanguage(686, 'You have marked {{name}} as hired').replace('{{name}}', name));
    } else {
      SnackbarManager.Instance.addError(getLanguage(687, 'Could not mark {{name}} as hired').replace('{{name}}', name));
    }
  }


  render() {
    return (
      <div className="companyFeedbackView">
        <MasterSlider
          max={this.state.maxTime}
          min={this.state.minTime}
          onSlide={this.masterSliderChanged}
          jobStart={this.state.startTime}
          onMaxChanged={this.onMaxChanged}
          value={this.state.masterSliderValue}
        />
        {this.state.feedbackArray.map((feedbackObject: ICompanyFeedback) => {
          return (
            <div key={feedbackObject.request.Id} className={feedbackObject.noShow === true ? "feedbackItem noShow" : "feedbackItem"}>
              <Grid container spacing={0} >
                <Grid container item justify="space-between" >
                  <Grid item>
                    <ProfilePictureViewer
                      login={feedbackObject.freelancer!.Login}
                      freelancer={feedbackObject.freelancer}
                      customClass="feedbackImage"
                      altText=""
                      company={null}
                    ></ProfilePictureViewer>
                    <h2 className="feedbackName">{feedbackObject.freelancer.Login!.Firstnames}</h2>
                  </Grid>
                  <Grid item>
                    {this.renderChabberRating(feedbackObject, feedbackObject.request.Id)}
                    <Tooltip title={getLanguage(getFaveLangCodeForTooltip(feedbackObject.favourited), 'Add to favourites')} placement="top">
                      <div className="inline-block">
                        <IconButton
                          disabled={feedbackObject.noShow || feedbackObject.favourited === FavouriteStatus.confirmedFavorite}
                          aria-label="Favourite"
                          color="secondary"
                          className={FavouriteStatus[feedbackObject.favourited]}
                          onClick={() => this.addOrWithdrawFromFavorites(feedbackObject)}
                        >
                          <i className="far fa-heart" />
                        </IconButton>
                      </div>
                    </Tooltip>
                    <IconButton
                      onClick={(e) => this.openOptions(e, feedbackObject)}
                      disabled={feedbackObject.noShow}

                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid item xs={11}>
                  <FreelancerSlider
                    hidden={this.state.feedbackArray.length < 2}
                    value={feedbackObject.endTime}
                    jobStart={this.state.startTime}
                    jobEnd={this.state.endTime}
                    min={this.state.minTime}
                    max={this.state.maxTime}
                    onSlide={(value) => this.changeSlider(value, feedbackObject.request.Id)}
                    disabled={feedbackObject.noShow}
                  />
                </Grid>
                <Grid item xs={11}>
                  <Collapse
                    in={feedbackObject.rating !== feedbackObject.originalRating
                      || (feedbackObject.comment != null && feedbackObject.comment !== '')}
                    timeout={500}
                  >
                    <div className="margin-v-16">
                      <TextField
                        label={getLanguage(829, "Comment")}
                        multiline
                        rows="2"
                        placeholder={getLanguage(830, "Add a public comment")}
                        variant="outlined"
                        fullWidth
                        value={feedbackObject.comment}
                        onChange={async (e) => {
                          feedbackObject.comment = e.target.value;
                          await this.setState({ feedbackArray: [...this.state.feedbackArray] });
                          this.props.changeIsDirty();
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        disabled={feedbackObject.ratingReadOnly}
                      />
                    </div>
                  </Collapse >
                </Grid>

              </Grid>
            </div>
          );
        })
        }

        <FreelancerMenu
          anchorEl={this.state.chabberMenuElement}
          feedbackObject={this.state.activeFeedback}
          handleClose={() => this.setState({ chabberMenuElement: null })}
          openFreelancerDialog={() => this.openDialogue(DialogueTypes.chabber)}
          openManualModeDialog={() => this.openManualModeDialog()}
          openNoShowDialogue={() => this.openDialogue(DialogueTypes.noShow)}
          openHireDialogue={() => this.openDialogue(DialogueTypes.permaHire)}
        />

        <FreelancerDialogue
          open={this.state.openDialogue === DialogueTypes.chabber}
          freelancer={this.state.activeFeedback != null ? this.state.activeFeedback.freelancer : undefined}
          request={this.state.activeFeedback != null ? this.state.activeFeedback.request : undefined}
          handleClose={() => this.setState({ openDialogue: null, activeFeedback: null })}
        />

        <ManualDialogue
          open={this.state.openDialogue === DialogueTypes.manual}
          closeDialogue={this.closeDialogue}
          activeRequestMinimumSalary={this.state.activeRequestMinimumSalary}
          submit={this.setOvertimeSalary}
          valueChanged={(val) => this.setState({ activeRequestMinimumSalary: val })}
        />

        <NoShowDialogue
          open={this.state.openDialogue === DialogueTypes.noShow}
          feedbackObject={this.state.activeFeedback}
          handleClose={this.closeDialogue}
          submit={this.markNoShow}
        />

        <PermahireDialogue
          open={this.state.openDialogue === DialogueTypes.permaHire}
          feedbackObject={this.state.activeFeedback}
          permaHire={this.permaHire}
          handleClose={this.closeDialogue}
        />

        <SavingSnackbar
          open={this.props.isDirty}
          resetClicked={this.initializePage}
          saveClicked={this.saveChanges}
        />
      </div >
    );
  }
}