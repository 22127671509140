

export class CurrencyModel {
    Code: string;
    FullName: string;
    Id: string;
    Shorthand: string;
    OutputFormat: string;

  constructor(val: Partial<CurrencyModel>) {
    this.Code = val.Code || '';
    this.FullName = val.FullName || '';
    this.Id = val.Id || '';
    this.Shorthand = val.Shorthand || '';
    this.OutputFormat =  val.OutputFormat || 'A';
  }
}

