// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Menu, MenuItem, Button, TextField, InputAdornment } from "@material-ui/core/";
import Tabs from '../../../Parts/General/Tabs';
import TabContent from '../../../Parts/General/TabContent';
import { RouteComponentProps } from 'react-router-dom';
import { CountrySelector } from "../../../Parts/General/CountrySelector";
import { OrganizersTab } from "./OrganizersTab";
import { CoursesTab } from "./CoursesTab";
import { CourseHelper } from "../../../../Helpers/CourseHelper";
import { CourseOrganizerHelper } from "../../../../Helpers/CourseOrganizerHelper";
import { CoursePartnerModel } from "../../../../Models/CoursePartnerModel";
import { CourseModel } from "../../../../Models/CourseModel";
import { SnackbarManager } from "../../../../Helpers/SnackbarManager/SnackbarManager";

interface IProps extends RouteComponentProps {

}

export function Courses(props: IProps) {
  let courseHelper = new CourseHelper();
  let courseOrganizerHelper = new CourseOrganizerHelper();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [organizers, setOrganizers] = useState<CoursePartnerModel[]>([]);
  const [courses, setcourses] = useState<CourseModel[]>([])
  const [countryId, setCountryId] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [tabNumber, setTabNumber] = useState(props.location.state != null ? props.location.state.tab : 0);
  const [highlightedCourseId, setHighlightedCourseId] = useState('');

  useEffect(() => {
    fetchPageData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function fetchPageData() {
    if (props.location.state && props.location.state.id) {
      setHighlightedCourseId(props.location.state.id);
    }

    let courses = await courseHelper.getCourses();
    setcourses(courses || []);
    let organizerArr = await courseOrganizerHelper.getOrganizers(true);
    setOrganizers(organizerArr);
  }

  function createNew(path: string) {
    props.history.push(path, { courseData: true });
    setAnchorEl(null);
  }


  async function deleteCourse(id: string) {
    let result = await courseHelper.deleteCourse(id);

    if (result === false) {
      SnackbarManager.Instance.addError('Something went wrong');
      return false;
    }

    let localCourses = courses.map(x => x);
    let selectedCourse = localCourses.find(x => x.Id === id);
    selectedCourse!.Deactivated = true;
    setcourses(localCourses);
    return true;
  }

  return (
    <div>
      <div className="AdminPageHeader">
        <div className="AdminPageTitle">
          Courses
            </div>
        <div className="AdminPageHeader_options">
          <div className="inputGroup">

            <TextField
              className="inputGroup_item"
              onChange={event => setSearchQuery(event.target.value)}
              type="text"
              variant="outlined"
              margin="dense"
              style={{ margin: 0 }}
              InputLabelProps={{
                shrink: true
              }}
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className="inputAdornment">
                    <i className="fas fa-search" />
                  </InputAdornment>
                )
              }}
            />
            <CountrySelector
              className="inputGroup_item"
              valueChanged={setCountryId}
            />

            <Button className="success inputGroup_item" variant="text" size="medium" aria-owns={anchorEl ? "export-menu" : undefined}
              aria-haspopup="true"
              onClick={e => setAnchorEl(e.currentTarget)}>
              <span className="btn-icon">
                <i className="fas fa-plus" />
              </span>
              Create new
              </Button>
          </div>
        </div>
        <Menu
          id="export-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          className="dropdownMenu"
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <div className="menuTitle">Create new</div>
          <MenuItem onClick={() => createNew('/admin/course')} className="menuItem">
            {" "}
            <i className="fas fa-certificate" />New course
          </MenuItem>
          <MenuItem onClick={() => createNew('/admin/organizer')} className="menuItem">
            {" "}
            <i className="fas fa-plus" />New organizer
          </MenuItem>
        </Menu>
      </div>
      <Tabs value={tabNumber} changeValue={setTabNumber}>
        <TabContent title='Courses'>
          <CoursesTab
            courses={courses}
            countryId={countryId}
            searchQuery={searchQuery}
            deleteCourse={deleteCourse}
            highlightedCourseId={highlightedCourseId}
            {...props} />
        </TabContent>
        <TabContent title='Organizers'>
          <OrganizersTab
            organizers={organizers}
            countryId={countryId}
            searchQuery={searchQuery}
            {...props} />
        </TabContent>
      </Tabs>
    </div >
  )
}