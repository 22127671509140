// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Grid, Button, Dialog, DialogContent, Hidden } from '@material-ui/core';
import { Footer } from '../Navigation/Footer';
import "./FrontpageFreelancer.scss";
import waveDark from "../../../../assets/img/shapes/waveDark.svg";
import blobLarge from "../../../../assets/img/shapes/blobLarge.svg";
import blobMedium from "../../../../assets/img/shapes/blobMedium.svg";
import blobMediumTwo from "../../../../assets/img/shapes/blobMedium_2.svg";
import heroImage from "../../../../assets/img/devices/freelancerHeroImage@2x.png";
import valueDevice from "../../../../assets/img/devices/valueDevice@2x.png";
import appDownload from "../../../../assets/img/devices/downloadApp@2x.png";
import appDownload_IOS from "../../../../assets/img/devices/appDownloadButton_ios@2x.png";
import appDownload_Android from "../../../../assets/img/devices/appDownloadButton_android@2x.png";
import { getLanguage } from '../../../../Helpers/LanguageHelper';
import { YouTubeEmbed } from '../../../Parts/General/YouTubeEmbed';
import { CommunityFreelancer } from './parts/CommunityFreelancer';

interface IProps extends RouteComponentProps { }
interface IState {
  dialogOpen: boolean;
}
export class FrontpageFreelancer extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      dialogOpen: false,
    }
  }
  render() {
    return (
      <div className="FrontpageFreelancer">

        <div className="content">
          <div className="hero">

            <div className="container">
              <Grid container>
                <Grid item xs={12} lg={5}>
                  <h1 className="tagline">
                    {getLanguage(633, "Freelance shifts as waiting and kitchen staff")}
                  </h1>
                  <p className="heroContent">
                    {getLanguage(634, "Grab shifts when it suits you and meet tons of new people in the process.")}
                  </p>
                  <div className="inputGroup">
                    <Button
                      className="inputGroup_item button"
                      variant="contained"
                      size="medium"
                      id="freelancer_heroCTA"
                      href="searchpostings"
                    >
                      <span className="btn-icon">
                        <i className="fas fa-search" />
                      </span>
                      {getLanguage(59, "Search shifts")}
                    </Button>
                    <Button className="inputGroup_item" id="freelancer_secondaryCTA" onClick={() => this.setState({ dialogOpen: true })}>
                      <span className="btn-icon">
                        <i className="fas fa-play" />
                      </span>
                      {getLanguage(352, "Watch video")}
                    </Button>
                  </div>
                </Grid>
                <Hidden smDown>
                  <Grid item lg={7}>
                    <img className="heroImage noDrag" src={heroImage} alt="Product image" />
                  </Grid>
                </Hidden>
              </Grid>
            </div>
          </div>
          <section className="section_light">
            <div className="container padding-top-40">
              <Grid container>
                <Grid item md={6}>
                  <img className="noDrag" src={valueDevice} alt="Macbook with product image" width="800px" id="valueImage" />
                </Grid>
                <Grid item md={6}>
                  <div className="displayFont">
                    {getLanguage(635, "Tailor your working life.")}
                  </div>
                  <div className="margin-top-40">
                    <div className="freelancer_valueProp">
                      <div className="freelancer_valuePropTitle">{getLanguage(636, "Get 100% flexibility")}</div>
                      <div className="freelancer_valuePropContent">{getLanguage(637, "Grab shifts and get 100% control of when and where you work.")}</div>
                    </div>
                    <div className="freelancer_valueProp">
                      <div className="freelancer_valuePropTitle">{getLanguage(638, "Leave it to us")}</div>
                      <div className="freelancer_valuePropContent">{getLanguage(639, "We’ll let you know when you get accepted to a new shift and we make sure you receive your salary.")}</div>
                    </div>
                    <div className="freelancer_valueProp">
                      <div className="freelancer_valuePropTitle">{getLanguage(640, "A smart solution")}</div>
                      <div className="freelancer_valuePropContent">{getLanguage(641, "Apply for as many shifts as you want. Our system will make sure you will never have a double booking.")}</div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </section>
          <section className="section_light">
            <div className="container">
              <div className="sectionHeading">{getLanguage(769, "At Chabber we put people first. Here’s how.")}</div>
              <CommunityFreelancer />
            </div>
          </section>
          <section className="section_light noSectionPadding">
            <div className="container">
              <div className="sectionHeading">{getLanguage(642, "Download the app")}</div>
              <Grid container>
                <Grid item sm={6}>
                  <p className="paragraphText bold">{getLanguage(643, "Download Chabber and sign up today")}</p>
                  <p className="paragraphText">
                    {getLanguage(644, " With our app in your pocked you’ll have endless access to shifts. We’ll also let you know of new opportunities when you want it.")}
                  </p>
                  <div className="margin-v-40">
                    <a href="https://apps.apple.com/dk/app/chabber/id1265782763" target="_blank">
                      <img className="noDrag margin-right-32" src={appDownload_IOS} alt="Download for IOS" width="150px" />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.chabber.app" target="_blank">
                      <img className="noDrag" src={appDownload_Android} alt="Download for Andriod" width="150px" />
                    </a>
                  </div>
                </Grid>
                <Grid item sm={6}>
                  <img className="appDownload noDrag" src={appDownload} alt="App download image" width="472" />
                </Grid>
              </Grid>
            </div>
            <img className="blob blobMedium blobApp" src={blobMedium} alt="svg blob" />
          </section>
        </div>
        <Footer />

        <Dialog open={this.state.dialogOpen} onClose={() => this.setState({ dialogOpen: false })} fullWidth maxWidth="md">
          <DialogContent>
            <YouTubeEmbed videoID="wX2NJV6EQuw" />
          </DialogContent>
        </Dialog>
      </div>
    )
  }
}