import React, { Component } from "react";
import errorImg from "../../../assets/img/illustrations/outOfDate_emptyState.png";
import { getLanguage } from "../../../Helpers/LanguageHelper";
import { Button } from "@material-ui/core";
import { NavLink } from "react-router-dom";
export class PageNotFound extends Component {
    render() {
        return (
            <div className="emptyState_wrapper">
                <div className="emptyState_content">
                    <img className="noDrag" src={errorImg} alt="" />
                    <h1>{getLanguage(827, "Sorry, this page does not exist")}</h1>
                    <p className="infoParagraph">
                        {getLanguage(828, " If you’re sure it should be here, please contact Team Chabber and they’ll help you out.")}
                    </p>
                    <br />
                    <NavLink to="/">
                        <Button>{getLanguage(218, "Back")}</Button>
                    </NavLink>
                </div>
            </div>
        )
    }
}