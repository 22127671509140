import React from "react";
import NearMeIcon from "@material-ui/icons/NearMe";
import "./Distance.scss";
export default ({ distance }: { distance: string }) => {
  return (
    <div className="distance-container">
      <NearMeIcon fontSize="small" />
      <span>{distance} km</span>
    </div>
  );
};
