// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { CarouselProvider, Slider, Slide, DotGroup } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import "./CaseCarousel.scss";
import { EventCaseVenues } from "../cases/Venues";
import { EventCaseWedding } from "../cases/Wedding";
import { EventCaseKitchen } from "../cases/Kitchen";

interface IProps {}
interface IState {}
export class CaseCarousel extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    return (
      <div>
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={125}
          totalSlides={3}
          isPlaying={true}
          lockOnWindowScroll={true}
        >
          <Slider>
            <Slide index={0}>
              <EventCaseVenues />
            </Slide>
            <Slide index={1}>
              <EventCaseKitchen />
            </Slide>
            <Slide index={2}>
              <EventCaseWedding />
            </Slide>
          </Slider>
          <DotGroup className="carouselDots" />
        </CarouselProvider>
      </div>
    );
  }
}
