import { AxiosRequestConfig } from "axios";
import { CallManager } from "./CallManager/CallManager";
import { CourseModel } from "../Models/CourseModel";
import { CourseRegistrationModel } from "../Models/CourseRegistrationModel";

export class CourseHelper {

  async getCourses(): Promise<CourseModel[]> {
    try {
      let config: AxiosRequestConfig = {
        method: 'get',
        url: '/api/course',
        withCredentials: true
      }
      let response = await CallManager.Instance.makeCall(config, false);
      return response.data.map((x: CourseModel) => new CourseModel(x));
    } catch (error: any) {
      console.log(error);
      return [];
    }
  }

  async getCourseById(id: string): Promise<CourseModel | null> {
    try {
      let config: AxiosRequestConfig = {
        method: 'get',
        url: '/api/course/' + id,
        withCredentials: true
      }
      let response = await CallManager.Instance.makeCall(config, false, true);
      return new CourseModel(response.data);
    } catch (error: any) {
      console.log(error);
      return null;
    }
  }

  async getFutureCourses(countryId: string): Promise<CourseModel[]> {
    try {
      let config: AxiosRequestConfig = {
        method: 'get',
        url: '/api/courses/future',
        params: {
          countryId
        },
        withCredentials: true,
      }
      let response = await CallManager.Instance.makeCall(config, false, false);

      if (response.data) {
        return response.data.map((x: CourseModel) => new CourseModel(x));
      }
      return [];

    } catch (error: any) {
      return [];
    }
  }


  async deleteCourse(id: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'delete',
        url: '/api/course/' + id,
        withCredentials: true
      }

      let response = await CallManager.Instance.makeCall(config, false, true);
      return response.data;
    } catch (error: any) {
      console.log('error', error)
      return false;
    }
  }

  async saveCourse(course: CourseModel) {
    try {
      let config: AxiosRequestConfig = {
        method: 'post',
        url: '/api/course/',
        data: {
          course
        },
        withCredentials: true
      }

      let response = await CallManager.Instance.makeCall(config, false, true);
      if (response && response.data) {
        return response.data;
      }
      return null;
    } catch (error: any) {
      console.log('error', error);
      return null;
    }
  }

  async updateCourse(course: CourseModel) {
    try {
      let config: AxiosRequestConfig = {
        method: 'put',
        url: '/api/course/',
        data: {
          course
        },
        withCredentials: true
      }

      let response = await CallManager.Instance.makeCall(config, false, true);
      if (response) {
        return response;
      }
      return null;
    } catch (error: any) {
      console.log('error', error);
      return null;
    }
  }

  async deleteCourseRegistrants(ids: string[]) {
    try {
      let config: AxiosRequestConfig = {
        method: 'delete',
        url: '/api/courseregistration/',
        params: {
          ids
        },
        withCredentials: true
      }

      let response = await CallManager.Instance.makeCall(config, false, true);
      return response;
    } catch (error: any) {
      console.log(error);
      return null;
    }
  }

  async updateRegistrantHours(registrants: CourseRegistrationModel[]) {
    try {
      let config: AxiosRequestConfig = {
        method: 'put',
        url: '/api/courseregistration/',
        data: {
          registrants
        },
        withCredentials: true
      }
      let response = await CallManager.Instance.makeCall(config, false, true);

      return response.data;

    } catch (error: any) {
      console.log(error);
      return null;
    }
  }

  async sendRegistrationMail(courseId: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'get',
        url: '/api/coursepartner/emailattendencelink',
        params: { courseId },
      }

      await CallManager.Instance.makeCall(config, false, true);

      return true;
    } catch (error: any) {
      console.log(error)
      return false;
    }
  }
}