// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Axios, { AxiosRequestConfig } from "axios";
import * as historyObj from "history";
import { StorageHelper } from "../StorageHelper";
import { Call } from "./Call";
import { FailedCall } from "./FailedCall";
import { getOldApi } from "../Cfg";
import { logUserOut, isUserLoggedIn } from "../SessionHelper";
import { SnackbarManager } from "../SnackbarManager/SnackbarManager";
import { getLanguage } from "../LanguageHelper";
import * as Sentry from "@sentry/react";

export class CallManager {
  storageHelper = new StorageHelper();
  private static instance: CallManager;

  historyObject: historyObj.History | undefined = undefined;

  isRunning = false;

  hasFacadeLocalization: boolean = false;
  hasStaticData: boolean = false;

  criticalCalls: Call[] = [];
  standardCalls: Call[] = [];

  static get Instance(): CallManager {
    if (!CallManager.instance) {
      CallManager.instance = new CallManager();
    }
    return CallManager.instance;
  }

  checkIfCriticalDataHasBeenSaved() {
    let facadeData = this.storageHelper.getFacadeLocalization();
    let staticData = this.storageHelper.getStaticData();

    if (facadeData != null) {
      this.hasFacadeLocalization = true;
    }
    if (staticData != null) {
      this.hasStaticData = true;
    }
  }

  /**
   * Adds call to the critical list. Critical calls must be completed before any others
   * If they are not completed the website locks until they can be completed
   * @param config The axios request to be made
   */
  async makeCriticalCall(
    config: AxiosRequestConfig,
    isOldApi: boolean
  ): Promise<any> {
    let promise = new Promise(function (resolve, reject) {
      CallManager.Instance.criticalCalls.push(
        new Call(
          config,
          (response: any) => {
            return resolve(response);
          },
          isOldApi,
          false
        )
      );
    });

    if (this.isRunning === false) this.callLoop();

    return promise;
  }

  async makeCall(
    config: AxiosRequestConfig,
    isOldApi: boolean,
    isAdmin?: boolean
  ): Promise<any> {
    let promise = new Promise(function (resolve, reject) {
      CallManager.Instance.standardCalls.push(
        new Call(
          config,
          (response: any) => {
            if (response?.status < 400) {
              return resolve(response);
            } else {
              console.log("failed call: " + config.url, response);
              return reject(response);
            }
          },
          isOldApi,
          isAdmin
        )
      );
    });

    if (this.isRunning === false) this.callLoop();

    return promise;
  }

  private async callLoop() {
    this.isRunning = true;
    while (this.isRunning === true) {
      let errorCalls: FailedCall[] = [];

      // For each call in critical Calls do this
      if (this.criticalCalls.length > 0) {
        errorCalls = await this.makeCalls(this.criticalCalls, true);
      } else if (
        this.standardCalls.length > 0 &&
        this.hasFacadeLocalization &&
        this.hasStaticData
      ) {
        await this.makeCalls(this.standardCalls, false);
      }

      // If there are no more errors send the all clear to the whole page (most importantly to the CallManagerView, that handles displaying errors)
      if (this.criticalCalls.length === 0) {
        this.storageHelper.sendEvent("ConnectionIssueEvent", "allclear");

        // If there aren't any other calls waiting either, stop this loop
        if (
          this.standardCalls.length === 0 &&
          this.hasFacadeLocalization &&
          this.hasStaticData
        ) {
          this.isRunning = false;
          return;
        }
      }

      // If there are still more errors go here
      // If there are errors that have failed more than thrice, send error event
      if (errorCalls.length > 0) {
        this.storageHelper.sendEvent("ConnectionIssueEvent", errorCalls);
      }
      //Lastly, delay for 1/10th of a second
      this.checkIfCriticalDataHasBeenSaved();
      await this.delay();
    }
  }

  private async makeCalls(callArray: Call[], retryOnFail: boolean) {
    let errorCalls = [];
    for (const call of callArray) {
      // If the call has failed 3 times in a row, mark it down
      if (
        call.retries >= 3 &&
        call.config.url != null &&
        callArray === this.criticalCalls
      ) {
        errorCalls.push(
          new FailedCall(call.config.url, call.retries, call.delayTimer)
        );
      }

      // If the delay-timer has run out make the call again
      if (call.delayTimer < 1) {
        let response;
        try {
          if (call.isOldApi === true && call.config.url) {
            call.config.baseURL = getOldApi();
          }
          if (call.config.headers == null) {
            if (call.isAdmin) {
              call.config.headers = {
                authToken: this.storageHelper.getAdminToken(),
              };
            } else {
              call.config.headers = {
                authToken: this.storageHelper.getAccessToken(),
              };
            }
          } else {
            if (call.isAdmin) {
              call.config.headers.authToken =
                this.storageHelper.getAdminToken();
            } else {
              call.config.headers.authToken =
                this.storageHelper.getAccessToken();
            }
          }
          response = await Axios(call.config);
        } catch (error: any) {
          response = error.response;
          Sentry.captureException(error);
        }

        if (response?.status === 401 && isUserLoggedIn() === true) {
          logUserOut();
          if (this.historyObject) {
            this.historyObject.push("/login");
            SnackbarManager.Instance.addError(
              getLanguage(768, "You have been logged out")
            );
          } else {
            console.log("could not redirect");
          }
        } else if (response?.status === 200 || retryOnFail === false) {
          // If the call succedes
          let index = callArray.findIndex((x) => x === call);
          callArray.splice(index, 1);
          call.callback(response);
        } else {
          // Else mark down the retry and set a new delay
          call.retries++;
          if (call.retries < 5) {
            call.delayTimer = 0;
          } else {
            call.delayTimer = call.retries < 20 ? call.retries : 20;
          }
        }
        // If the delay timer has not run out, remove 1 unit of time from the delay timer (we are measuring in 1/10th of a second)
      } else {
        call.delayTimer -= 0.1;
      }
    }
    return errorCalls;
  }

  private delay() {
    return new Promise((resolve) => setTimeout(resolve, 100));
  }
}
