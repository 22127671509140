import React, { useState, useEffect } from "react";
import "./PhotoLibrary.scss";
import {
  Dialog,
  Grid,
  Button,
  IconButton,
  Slide
} from "@material-ui/core";

import { ImageCrop } from "../../Parts/General/ImageCrop";
import { ImageHelper, fileToDataUrl } from '../../../Helpers/ImageHelper'
import { PageLoader } from "../General/PageLoader";
import { SnackbarManager } from "../../../Helpers/SnackbarManager/SnackbarManager";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} children={<>{props.children}</>} />;
});

type mediaModel = {
  Id: string;
  CloudinaryId: string;
  CloudinaryUrl: string;
  Created: string;
}


interface IProps {
  open: boolean;
  accessSite: "atlas" | "blog";
  droppedFile?: File | null;
  close: (image: any) => void;
}


export function PhotoLibrary(props: IProps) {
  let fileInput: any;
  let imageHelper = new ImageHelper();

  const [cropOpen, setCropOpen] = useState(false);
  const [imageToCrop, setImageToCrop] = useState('');
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [imagesForLibrary, setImagesForLibrary] = useState<mediaModel[]>([]);
  const [imagesClickable, setImagesClickable] = useState('');
  const [showPageLoader, setShowPageLoader] = useState(false);

  useEffect(() => {

    // if (runOnlyOnce) {
    openMediaLibrary();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    cropDroppedFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.droppedFile])

  async function cropDroppedFile() {
    if (!props.droppedFile) return;
    let str = await fileToDataUrl(props.droppedFile);
    openCropping(str)
  }

  async function openCropping(image: string) {
    var image2 = new Image();
    image2.src = image;
    image2.onload = function () {
      if (image2.naturalWidth >= 850 || image2.naturalHeight >= 300) {
        setCropOpen(true);
        setImageToCrop(image);
      } else {
        SnackbarManager.Instance.addWarning('Minimum size for blog header images are 850px width x 300px height');
        setCropOpen(false);
        setImageToCrop('');
      }
    }
  }

  /**
   * Function for receiving an image file from the user and save it.
   */
  async function fetchImage(event: any) {
    const file: File = Array.from(event.target.files)[0] as File;


    if (file && file instanceof Blob) {
      var imageReg = /[\/.](gif|jpg|jpeg|tiff|png)$/i;
      if (imageReg.test(file.type)) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          if (props.accessSite === 'blog') {
            openCropping(e.target.result);
          } else {
            saveImage(e.target.result);
          }
        }
        reader.readAsDataURL(event.target.files[0]);
      } else {
        SnackbarManager.Instance.addError('Please select an image file');
      }
    } else {
      SnackbarManager.Instance.addError('Please select an image file');
    }
    event.target.value = null;
  }

  async function saveImage(image: string) {
    setShowPageLoader(true);
    let imageData = await imageHelper.savePictureToImageLibrary(image);
    if (imageData) {
      SnackbarManager.Instance.addSuccess('Image saved');
      await openMediaLibrary();
      setShowPageLoader(false);
      return imageData;
    } else {
      SnackbarManager.Instance.addError('Unknown error, could not save image');
      return;
    }
  }

  async function openMediaLibrary() {
    let images = await imageHelper.getAllImagesForLibrary()
    if (images) {
      if (props.accessSite === 'atlas') { setImagesClickable('disabled') }
      setImagesForLibrary(images);
    }
  };

  async function closeMediaLibrary() {
    await setCropOpen(false);
    props.close(null);
  };

  async function closeCropping(image: string) {
    await setCropOpen(false);
    if (image != null) {
      let imageData = await saveImage(image);
      return props.close(imageData);
    }
    props.close(null);
  }


  async function chooseImageFromLibrary(image: any) {
    if (image.CloudinaryUrl && !image.Url) {
      image.Url = image.CloudinaryUrl;
    }
    props.close(image);
  }

  async function deleteImageFromLibrary(image: any, e: any) {
    e.stopPropagation();
    let result = await imageHelper.deleteMediaImage(image.Id)
    if (result) {
      SnackbarManager.Instance.addSuccess('Image deleted from media library');
      let imageIndex = imagesForLibrary.findIndex(x => x.Id === image.Id);
      let images = imagesForLibrary.map(x => x);
      images.splice(imageIndex, 1);
      setImagesForLibrary(images);
    }
    else {
      SnackbarManager.Instance.addError('Image could not be deleted');
    }
  }




  return (
    <div className="photoLibrary">
      <Dialog
        fullScreen
        open={props.open}
        onClose={closeMediaLibrary}
        //@ts-ignore
        TransitionComponent={Transition}
      >
        <div className="dialogHeader padding-24">
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <h2 className="no-margin-bottom"><i className="far fa-images"></i> Photo library</h2>
            </Grid>
            <Grid item>
              <div className="inputGroup">
                <Button
                  disabled={buttonsDisabled}
                  className="inputGroup_item"
                  onClick={closeMediaLibrary}
                  aria-label="Close"
                >
                  Close
                  </Button>
                <Button
                  disabled={buttonsDisabled}
                  className="inputGroup_item success"
                  aria-label="Close"
                  onClick={() => fileInput.click()}
                >
                  <span className="btn-icon">
                    <i className="fas fa-upload" />
                  </span>
                  Upload
                  </Button>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="imageGallery padding-40">
          <Grid
            container
            spacing={5}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            {imagesForLibrary.map(row => (
              <Grid item xs={3} key={row.Id}>
                <div className="imageGallery_thumbnail">
                  <IconButton aria-label="Delete" className="deleteFromLibrary"
                    onClick={(e: any) => deleteImageFromLibrary(row, e)}>
                    <i className="fas fa-trash" />
                  </IconButton>
                  <img src={row.CloudinaryUrl} alt='' onClick={() => chooseImageFromLibrary(row)} className={imagesClickable} />
                </div>
              </Grid>
            ))}

          </Grid>
        </div>
        {cropOpen ? <ImageCrop open={cropOpen} close={closeCropping} image={imageToCrop} cropMode={16 / 9} /> : null}
        <input
          accept="image/*"
          style={{ display: 'none' }}
          type="file"
          onChange={fetchImage}
          ref={fileInputRef => fileInput = fileInputRef}
        />
        {showPageLoader ? <PageLoader /> : null}
      </Dialog>
    </div>
  )
}