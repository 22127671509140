import { AxiosRequestConfig } from 'axios';
import { StorageHelper } from './StorageHelper';
import { CallManager } from './CallManager/CallManager';

export class RegionHelper {
  storageHelper = new StorageHelper();
  async getAllRegionsByCountry(countryId: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'GET',
        url: '/api/allregions?CountryId=' + countryId,
        withCredentials: true,
      };

      let response = await CallManager.Instance.makeCall(config, false, false);
      if (response && response.data)
        return response.data;
      return false;
    } catch (error: any) {
      return false;
    }
  }

  async getRegion(regionId: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'get',
        url: '/api/regionbyid?Id=' + regionId,
        withCredentials: true,
      };
      let response = await CallManager.Instance.makeCall(config, false, false);

      return response.data;
    } catch (error: any) {
      return false;
    }
  }
}