// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from "react";
import {
  Route,
  Redirect,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";
import { isAdminLoggedIn } from "../../../Helpers/SessionHelper";

interface IProps extends RouteComponentProps {
  path: string;
  component: any;
}
interface IState { }

class PrivateAdminRouteimpl extends Component<IProps, IState> {
  render() {
    const { component: Component, ...props } = this.props;
    let adminLoggedIn = isAdminLoggedIn();

    return (
      <Route
        {...props}
        render={(props) => {
          if (adminLoggedIn === true) {
            return <Component {...props} />;
          }

          let originalRoute = this.props.path;

          if (originalRoute === "/admin") {
            return (
              <Redirect
                to={{
                  pathname: "/admin/login",
                  state: { from: props.location },
                }}
              />
            );
          }

          let redirectUrl =
            originalRoute +
            (this.props.location.search
              ? "?" + this.props.location.search.replace("?", "")
              : "");

          redirectUrl = encodeURIComponent(redirectUrl);

          return (
            <Redirect
              to={{
                pathname: "/admin/login",
                search: "?redirect=" + redirectUrl,
                state: { from: props.location },
              }}
            />
          );
        }}
      />
    );
  }
}
const PrivateAdminRoute: any = withRouter(PrivateAdminRouteimpl as any);
export default PrivateAdminRoute;
