import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { SnackbarManager } from "../../../Helpers/SnackbarManager/SnackbarManager";

interface IProps {
  openCropping: (image: any) => void;
  imageData: string;
  imageUrl: string;
  clearImage: () => void;
}

interface IState { }


export class ImageCard extends Component<IProps, IState> {

  fileInput: any;

  constructor(props: any) {
    super(props);

    this.removeImage = this.removeImage.bind(this)
    this.fetchImage = this.fetchImage.bind(this)
  }

  fetchImage(event: any) {
    const file: File = Array.from(event.target.files)[0] as File;

    if (file && file instanceof Blob) {
      var imageReg = /[/.](gif|jpg|jpeg|tiff|png)$/i;
      if (imageReg.test(file.type)) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.props.openCropping(e.target.result);
        }
        reader.readAsDataURL(event.target.files[0]);
      } else {
        SnackbarManager.Instance.addWarning('Please select an image file');
      }
    } else {
      SnackbarManager.Instance.addWarning('Please select an image file');
    }
    event.target.value = null;
  }


  removeImage() {
    this.props.clearImage();
  }

  render() {
    return (
      <div>
        <div className="adminUserAvatar imgCircle">
          <img src={this.props.imageData || this.props.imageUrl} alt="avatar" />
        </div>
        <div className="block padding-16 inputGroup">
          <Button
            disabled={!(this.props.imageData || this.props.imageUrl)}
            className="default icon inputGroup_item"
            variant="text"
            size="medium"
            onClick={this.removeImage}
          >
            <span>
              <i className="fas fa-trash-alt"> </i>
            </span>
          </Button>
          <Button
            className="white inputGroup_item"
            variant="outlined"
            size="medium"
            onClick={() => this.fileInput.click()}
          >
            <span className="btn-icon">
              <i className="fas fa-cog" />
            </span>
            Change
          </Button>

          <input
            accept="image/*"
            style={{ display: 'none' }}
            type="file"
            onChange={this.fetchImage.bind(this)}
            ref={fileInput => this.fileInput = fileInput}
          />
        </div>
      </div>
    );
  }
}
