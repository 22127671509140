import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import "./Register.scss";
import { Grid, Button, } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { getLanguage } from "../../../../Helpers/LanguageHelper";

interface IProps extends RouteComponentProps {

}

interface IState {
  lookingForFreelancersOpen: boolean;
}



export class Register extends Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      lookingForFreelancersOpen: false,
    }

  }

  render() {
    return (
      <div className="signupView">
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="stretch"
          className="vh-100"
        >
          <Grid item xs={12} sm={6} container alignItems="center" alignContent='stretch' className="registerFreelancer">
            <Grid item xs>
              <div className="padding-40 text-center">
                <div className="title">{getLanguage(548, "Looking for work")}</div>
                <p>
                  {getLanguage(552, "If you are looking to use Chabber to find freelance shifts s a waiter, bartender, chef, kitchen hand etc.")}
                </p>
              </div>
              <div className="text-center">
                <NavLink to="registerfreelancer">
                  <Button size="large">
                    {getLanguage(838, "Sign up as freelancer")}
                  </Button>
                </NavLink>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} container alignItems="center" className="registerCompany">
            <Grid item xs>
              <div className="padding-40 text-center">
                <div className="title">{getLanguage(549, "Looking for freelancers")}</div>
                <p>
                  {getLanguage(553, "If you want to use Chabber to find freelancers for a private or corporate event or business.")}
                </p>
              </div>
              <div className="text-center">
                <NavLink to="registercompany">
                  <Button size="large">
                    {getLanguage(630, "Company")}
                  </Button>
                </NavLink>
                <NavLink to="registerprivate">
                  <Button size="large" className="privatSignup">
                    {getLanguage(631, "Private")}
                  </Button>
                </NavLink>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {/* <p className="padding-v-16 text-center">
          {getLanguage(550, "Already have an account?")}
          <NavLink to="/login">
            <strong> {getLanguage(551, "Sign in here")}</strong>
          </NavLink>
        </p> */}
      </div >
    );
  }
}
