import React, { Component } from "react";
import { Tooltip } from "@material-ui/core";
import { FreelancerModel } from "../../../Models/FreelancerModel";
import { CompanyModel } from "../../../Models/CompanyModel";
interface IProps {
  freelancer: FreelancerModel | null;
  company: CompanyModel | null;
}
interface IState {}

export class RatingStars extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  getRatingsAverage() {
    let freelancer = null;
    let company = null;
    if (this.props.freelancer) {
      freelancer = this.props.freelancer;
    } else if (this.props.company) {
      company = this.props.company;
    }

    let ratingValue = freelancer
      ? freelancer.RatingsAverage
      : company
      ? company.RatingsAverage
      : 0;
    let ratingTotal = freelancer
      ? freelancer.CountRatings
      : company
      ? company.CountRatings
      : 0;

    ratingValue = (ratingValue * 5) / 100; // converts the range from 0-100 to 0-5

    let stars: JSX.Element[] = [];
    for (let index = 0; index < Math.floor(ratingValue); index++) {
      stars.push(<i key={index} className="fas fa-star"></i>);
    }
    if (ratingValue - stars.length > 0.2 && ratingValue - stars.length < 0.8) {
      stars.push(<i key="7" className="fas fa-star-half-alt"></i>);
    } else if (ratingValue > 4.75) {
      stars = [];
      for (let index = 0; index < 5; index++) {
        stars.push(<i key={index} className="fas fa-star"></i>);
      }
    }

    for (let index = stars.length; index < 5; index++) {
      stars.push(<i key={"a" + index} className="far fa-star"></i>);
    }

    return (
      <Tooltip
        title={ratingValue + "/5 based on " + ratingTotal + " ratings"}
        placement="right"
      >
        <div className="freelancerProfile_rating">{stars}</div>
      </Tooltip>
    );
  }

  render() {
    let stars = this.getRatingsAverage();
    return <div className="inline-block">{stars}</div>;
  }
}
