import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import {
  TextField,
  InputAdornment,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Grid,
  TablePagination,
  Button,
} from "@material-ui/core";
import { CountrySelector } from "../../Parts/General/CountrySelector";
import { NavLink } from "react-router-dom";
import { VerificationDocumentModel } from "../../../Models/VerificationDocumentModel";
import { AdminUserHelper } from "../../../Helpers/AdminUserHelper";
import Tabs from "../../Parts/General/Tabs";
import TabContent from "../../Parts/General/TabContent";
import { getLanguage } from "../../../Helpers/LanguageHelper";
import { StorageHelper } from "../../../Helpers/StorageHelper";
import { LoginModel } from "../../../Models/LoginModel";
import { CountryModel } from "../../../Models/CountryModel";
import { SnackbarManager } from "../../../Helpers/SnackbarManager/SnackbarManager";
import moment from "moment";

interface IProps extends RouteComponentProps {}

interface IState {
  documents: VerificationDocumentModel[];
  documentsReadyToShow: VerificationDocumentModel[];
  page: number;
  count: number;
  currentTab: number;
  searchPhrase: string;
}

const PAGE_SIZE = 20;

export class VerificationView extends Component<IProps, IState> {
  storageHelper = new StorageHelper();

  constructor(props: IProps) {
    super(props);
    this.state = {
      documents: [],
      documentsReadyToShow: [],
      page: 0,
      currentTab: 0,
      count: 0,
      searchPhrase: "",
    };

    this.getDocsReady = this.getDocsReady.bind(this);
    this.getDocuments = this.getDocuments.bind(this);
    this.changeTab = this.changeTab.bind(this);
  }

  async componentDidMount() {
    await this.getDocuments(0);
  }

  async getDocuments(tab: number) {
    let documents = await new AdminUserHelper().getPendingVerifications(
      tab,
      this.state.page,
      PAGE_SIZE,
      this.state.searchPhrase
    );

    if (documents) {
      await this.setState({
        documents: documents.rows,
        count: documents.count,
      });
      await this.getDocsReady();
    } else {
      SnackbarManager.Instance.addError("Could not load documents");
    }
  }

  getDocsReady() {
    this.setState({ documentsReadyToShow: [] });
    let adminUsers: LoginModel[] | null = this.storageHelper.getAdminUsers();
    let docs = this.state.documents;

    if (docs) {
      for (const i of docs) {
        if (i.AdminUserId) {
          if (adminUsers) {
            for (const u of adminUsers) {
              if (u.Id == i.AdminUserId) {
                i.vmAdminUserName = u.Firstnames + " " + u.Lastname;
              }
            }
          }
        }
      }
      let countries: CountryModel[] | null =
        this.storageHelper.getAllCountriesFromLocal();

      if (countries) {
        for (const i of docs) {
          if (i.CountryId) {
            for (const u of countries) {
              if (u.Id == i.CountryId) {
                i.vmCountryName = u.CountryName;
              }
            }
          }
        }
      }

      for (const i of docs) {
        if (i.Type != null) {
          switch (i.Type) {
            case 0:
              i.vmDocumentType = getLanguage(555, "Work permit");
              break;
            case 1:
              i.vmDocumentType = getLanguage(556, "Picture Id");
              break;
            case 2:
              i.vmDocumentType = getLanguage(557, "National Id");
              break;
            case 3:
              i.vmDocumentType = getLanguage(865, "Residence card (back)");
              break;
          }
        }
      }

      let documentsReadyToShow = this.state.documentsReadyToShow;
      for (const i of docs) {
        let matchFound = false;
        for (const u of documentsReadyToShow) {
          if (i.LoginId == u.LoginId) {
            console.log(u);
            u.vmDocumentType = u.vmDocumentType.concat(" " + i.vmDocumentType);
            matchFound = true;
          }
        }
        if (!matchFound) {
          documentsReadyToShow.push(i);
        }
      }
      this.setState({ documentsReadyToShow });
    }
  }

  showDocs() {
    let docs = this.state.documentsReadyToShow;

    return (
      <TableBody className="tableBody">
        {docs.map((row, index) => (
          <TableRow
            key={index}
            className="tableRow"
            onClick={() => this.openSpecific(row)}
          >
            <TableCell className="tableCell" align="center">
              <Tooltip
                title={
                  row.vmAdminUserName
                    ? "By " + row.vmAdminUserName + " , " + row.LastUpdatedUTC
                    : getLanguage(554, "Not reviewed yet")
                }
              >
                {row.Status != 2 ? (
                  row.Status != 1 ? (
                    <i className="fas fa-circle-notch fa-spin placeholder" />
                  ) : (
                    <i className="fas fa-ban danger" />
                  )
                ) : (
                  <i className="far fa-check-circle success" />
                )}
              </Tooltip>
            </TableCell>
            <TableCell className="tableCell">
              {row.CreatedUTC ? row.CreatedUTC : ""}
            </TableCell>
            <TableCell className="tableCell">
              {row.Login && row.Login.Firstnames && row.Login.Lastname
                ? row.Login.Firstnames + " " + row.Login.Lastname
                : ""}
            </TableCell>
            <TableCell className="tableCell">
              {row.ExpireDateUTC?.length === 19 &&
                moment(row.ExpireDateUTC).format("DD/MM-YYYY")}
            </TableCell>
            <TableCell className="tableCell">{row.vmCountryName}</TableCell>
            <TableCell className="tableCell">{row.vmDocumentType}</TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[PAGE_SIZE]}
            count={this.state.count}
            rowsPerPage={PAGE_SIZE}
            page={this.state.page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onPageChange={(_, page) => this.handleChangePage(page)}
          />
        </TableRow>
      </TableBody>
    );
  }

  async handleChangePage(page: number) {
    await this.setState((previous) => ({ ...previous, page }));
    this.getDocuments(this.state.currentTab);
  }

  async searchChanged(value: string) {
    await this.setState((previous) => ({ ...previous, searchPhrase: value }));
  }

  search() {
    this.getDocuments(this.state.currentTab);
  }

  changeTab(value: number) {
    this.setState((previous) => ({
      ...previous,
      currentTab: value,
      page: 0,
      count: 0,
      searchPhrase: "",
    }));
    this.getDocuments(value);
  }

  openSpecific(row: VerificationDocumentModel) {
    this.props.history.push("/admin/verificationuser", {
      user: row.Login,
    });
  }

  render() {
    let documents = this.showDocs();

    return (
      <div>
        <div className="AdminPageHeader">
          <div className="AdminPageTitle">Verification</div>
          <div className="AdminPageHeader_options">
            <div className="inputGroup">
              {/* freelancer name search */}
              <TextField
                className="inputGroup_item"
                onChange={(event) => this.searchChanged(event.target.value)}
                value={this.state.searchPhrase}
                type="text"
                variant="outlined"
                margin="dense"
                style={{ margin: 0 }}
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className="inputAdornment">
                      <i className="fas fa-search" />
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                style={{ top: "-3px", marginRight: "12px" }}
                variant="contained"
                onClick={() => this.search()}
              >
                Search
              </Button>
              <CountrySelector
                className="inputGroup_item"
                // valueChanged={this.searchChanged}
              />
            </div>
          </div>
        </div>

        <Tabs changeValue={this.changeTab}>
          <TabContent title="Pending">
            <h1>Pending</h1>
            {/* 1 */}
            <div className="adminTable">
              <Table className="table">
                <TableHead className="tableHead">
                  <TableRow className="tableRow">
                    <TableCell align="center">Status</TableCell>
                    <TableCell>Upload time</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Expire</TableCell>
                    <TableCell>Country</TableCell>
                    <TableCell>Type required</TableCell>
                  </TableRow>
                </TableHead>
                {documents}
              </Table>
            </div>
          </TabContent>

          <TabContent title="Declined">
            <h1>Declined</h1>
            <div className="adminTable">
              <Table className="table">
                <TableHead className="tableHead">
                  <TableRow className="tableRow">
                    <TableCell align="center">Status</TableCell>
                    <TableCell>Upload time</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Expire</TableCell>
                    <TableCell>Country</TableCell>
                    <TableCell>Type required</TableCell>
                  </TableRow>
                </TableHead>
                {documents}
              </Table>
            </div>
          </TabContent>
          <TabContent title="Approved">
            <h1>Approved</h1>
            <div className="adminTable">
              <Table className="table">
                <TableHead className="tableHead">
                  <TableRow className="tableRow">
                    <TableCell align="center">Status</TableCell>
                    <TableCell>Upload time</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Expire</TableCell>
                    <TableCell>Country</TableCell>
                    <TableCell>Type required</TableCell>
                  </TableRow>
                </TableHead>
                {documents}
              </Table>
            </div>
          </TabContent>
        </Tabs>
      </div>
    );
  }
}
