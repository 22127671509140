import React, { Component, PureComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import { InputLabel, TextField, Select, MenuItem, FormControl, OutlinedInput } from '@material-ui/core';
import './MainInfo.scss';
import { AdminUserModel as AdminUser } from '../../../Models/AdminUserModel';
import { CountryModel } from '../../../Models/CountryModel';
import { AdminTeam } from '../../../Models/AdminTeam';

interface IProps {
  User: AdminUser;
  UserEdited: (field:string, value: string) => void;
  countries: CountryModel[];
  teams: AdminTeam[];
}

interface IState {
  User: AdminUser;
}

export class MainInfo extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      User: this.props.User,
    }

    this.countryChanged = this.countryChanged.bind(this);
    this.handleChangeClicked = this.handleChangeClicked.bind(this);
  }


  UNSAFE_componentWillReceiveProps(props: IProps) { //FIXME: ComponentWillReceiveProps is deprecated
    let country = '';
    if (props.User.CountryId != this.state.User.CountryId) {
      country = props.User.CountryId;
    }
    this.setState({ User: props.User});
  }

  async countryChanged(event: any) {
    let user = new AdminUser(this.state.User);
    let countryId = event.target.value;
    user.CountryId = countryId;
    await this.props.UserEdited('CountryId', countryId);
      
    await this.setState({User: user });
  }


  handleChangeClicked(event:React.ChangeEvent<HTMLInputElement>, name:string ) {
    let user: AdminUser = new AdminUser(this.state.User);
    user[name] = event.target.value;
    this.setState({User: user });
    
    // Call to parent to set snackbar
    this.props.UserEdited(name, event.target.value);
  }



  getActiveCountries() {
    let countries = this.props.countries || [];
    let countrySelects = [];
    countrySelects.push(<MenuItem key='none' value={""}><em>None</em></MenuItem>)
    for (let index = 0; index < countries.length; index++) {
      const country = countries[index];
      countrySelects.push(
          <MenuItem key={country.Id} value={country.Id}>{country.CountryName}</MenuItem>
        );
    }
    return countrySelects;
  }

  
  render() {
    let countrySelects = this.getActiveCountries();

    return (
      <div className='paper padding-24 radius-6 MainInfoAdmin'>
        <h2>Main info</h2>
        <Grid
          container
          direction='row'
          justify='flex-start'
          alignItems='center'
          spacing={2}
        >
          <Grid item xs={6}>
            <TextField
              type='text'
              label='Full name'
              value={this.state.User.Name}
              variant='outlined'
              fullWidth
              margin='dense'
              onChange={(e:any) => this.handleChangeClicked(e, 'Name')}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type='text'
              label='Phone'
              value={this.state.User.Phone}
              variant='outlined'
              fullWidth
              margin='dense'
              onChange={(e:any) => this.handleChangeClicked(e, 'Phone')}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type='email'
              label='Email'
              value={this.state.User.Email}
              variant='outlined'
              fullWidth
              margin='dense'
              onChange={(e:any) => this.handleChangeClicked(e, 'Email')}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type='text'
              label='Title'
              value={this.state.User.Title}
              variant='outlined'
              fullWidth
              margin='dense'
              onChange={(e:any) => this.handleChangeClicked(e, 'Title')}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl variant='outlined' fullWidth margin='dense'>
              <InputLabel htmlFor='outlined-team-simple'>Team</InputLabel>
              <Select
                value={this.props.User.TeamId}
                // @ts-ignore
                onChange={(event) => this.props.UserEdited('TeamId', event.target.value)}
                input={<OutlinedInput labelWidth={30} name='team' />} >
                {this.props.teams.map(team => {
                  {
                    return <MenuItem key={team.Id} value={team.Id}>{team.Name}</MenuItem>
                }
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant='outlined' fullWidth margin='dense'>
              <InputLabel htmlFor='outlined-country-simple'> Country </InputLabel>
              <Select
                value={this.state.User.CountryId}
                onChange={this.countryChanged}
                input={<OutlinedInput labelWidth={50} name='county' id='outlined-country-simple' />}>
                {countrySelects}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    );
  }
}