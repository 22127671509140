import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import { NavLink } from 'react-router-dom';
import { CoursePartnerModel } from '../../../../Models/CoursePartnerModel';
import { CourseModel } from '../../../../Models/CourseModel';
import { nowAsFormatedString } from '../../../../Helpers/DateTimeHelper';

interface IProps extends RouteComponentProps {
  organizers: CoursePartnerModel[];
  countryId: string;
  searchQuery: string
}

export function OrganizersTab(props: IProps) {


  function getRevenue(organizer: CoursePartnerModel) {
    let total = 0;
    for (const course of organizer.Courses) {
      if (course.CourseDate < nowAsFormatedString()) { //get in the past?
        // course.Registrations;
        // course.WorkingMinutesToTakeAsPayment
        total += course.ValueInLocalCurrency //is this per course, per participant, per minute?
        // need to get local currency object
      }
    }

    return total;
  }

  function renderOrganizations() {
    let filteredOrganizers: CoursePartnerModel[];
    if (props.countryId === '') {
      filteredOrganizers = props.organizers;
    } else {
      filteredOrganizers = props.organizers.filter(x => x.CountryId === props.countryId);
    }

    if (props.searchQuery !== '') {
      filteredOrganizers = filteredOrganizers.filter(x => x.Name.toLowerCase().includes(props.searchQuery.toLowerCase()));
    }

    return filteredOrganizers.map((x: CoursePartnerModel) => {
      return (
        <TableRow key={x.Id} className="tableRow">
          <TableCell className="tableCell">
            <NavLink to={"/admin/organizer?id=" + x.Id} > {x.Name} </NavLink>
          </TableCell>
          <TableCell className="tableCell"> {(x.Courses as CourseModel[]).length}</TableCell>
          <TableCell className="tableCell"> {getRevenue(x)} </TableCell>
          <TableCell className="tableCell tableOptions"><i className="fas fa-trash-alt"></i></TableCell>
        </TableRow>
      )
    })
  }


  return (

    <div className='adminTable'>
      <Table className="table">
        <TableHead className="tableHead">
          <TableRow className="tableRow">
            <TableCell>Name</TableCell>
            <TableCell>Courses</TableCell>
            <TableCell>Revenue</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="tableBody">
          {renderOrganizations()}
        </TableBody>
      </Table>
    </div>
  )
}