// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { Hidden } from "@material-ui/core";
import { FreelancerStepVerification } from "./Parts/FreelancerStepVerification";
import { NavLink } from "react-router-dom";
interface IProps extends RouteComponentProps { }

export function FreelancerSettingsVerification(props: IProps) {

  return (
    <div>
      <div className="padding-v-32 maxW-600">
        <Hidden smUp>
          <NavLink to="/freelancer/settings">
            <i className="fas fa-arrow-left fa-lg margin-right-16"></i>
          </NavLink>
        </Hidden>
        <h1 className="inline-block">Verification</h1>
        <FreelancerStepVerification
          creatingUser={false}
          history={props.history}
          location={props.location}
          match={props.match}
        />
      </div>
    </div>

  );
}
