import React, { PureComponent } from "react";
import { AdminUserModel } from "../../../Models/AdminUserModel";
import "./AdminUsers.scss";
import {
  Table, TableBody, TableCell, TableHead, TableRow, DialogContent, Dialog, MenuItem, Tooltip, TextField, InputAdornment
} from "@material-ui/core";
import {
  DialogTitle, DialogContentText, DialogActions, Button
} from "@material-ui/core";
import { AdminUserHelper } from "../../../Helpers/AdminUserHelper";
import { RouteComponentProps } from "react-router";
import { CountryHelper } from "../../../Helpers/CountryHelper";
import { CountryModel } from "../../../Models/CountryModel";
import { CountrySelector } from "../../Parts/General/CountrySelector";
import { SnackbarManager } from "../../../Helpers/SnackbarManager/SnackbarManager";

interface IProps extends RouteComponentProps {
  classes: any;
  onClose: any;
  selectedValue: any;
}

interface IState {
  countries: CountryModel[];
  country: string;
  dialogueOpen: boolean;
  isDeleting: boolean;
  order: "asc" | "desc";
  rows: {
    rowIndex: number;
    Name: string;
    Email: string;
    Phone: string;
    DbId: string;
    CountryId: string;
  }[];
  searchQuery: string;
  sortedRows: {
    rowIndex: number;
    Name: string;
    Email: string;
    Phone: string;
    DbId: string;
    CountryId: string;
  }[];
  table: JSX.Element | undefined;
  userToDelete: {
    rowIndex: number;
    Name: string;
    Email: string;
    Phone: string;
    DbId: string;
    CountryId: string;
  } | null;
}



export class AdminUsersView extends PureComponent<IProps, IState> {
  adminUserHelper = new AdminUserHelper();
  countryHelper = new CountryHelper();

  constructor(props: any) {
    super(props);
    this.getData();
    this.state = {
      dialogueOpen: false,
      userToDelete: null,
      isDeleting: false,
      countries: [],
      country: "",
      rows: [],
      sortedRows: [],
      table: undefined,
      order: "asc",
      searchQuery: ""
    };

    this.handleClose = this.handleClose.bind(this);
    this.addNewUser = this.addNewUser.bind(this);
    this.searchChanged = this.searchChanged.bind(this);
  }

  async getData() {
    try {
      let adminUsers = await this.adminUserHelper.getAll();
      if (adminUsers) {
        adminUsers = adminUsers.filter(t => !t.IsDeleted)
      }

      let countries = await this.countryHelper.getActiveCountries();
      if (!adminUsers || !countries) {
        SnackbarManager.Instance.addError('Your clearance level is not high enough');
        return;
      }

      if (this.props.location && this.props.location.state) {
        //@ts-ignore
        let changedUser = new AdminUserModel(this.props.location.state.user);
        if (adminUsers.length > 0) {
          let index = adminUsers.findIndex(x => x.Id === changedUser.Id);

          if (index === -1) {
            adminUsers.push(changedUser);
          } else {
            adminUsers[index] = changedUser;
          }
        }
      }

      let rows = [];
      for (let index = 0; index < adminUsers.length; index++) {
        const elem = adminUsers[index];
        // console.log('elem: ' + elem.CountryId);
        // console.log(elem.CountryId === '');
        // let countryId: string = countries.findIndex(x => x.Id === elem.CountryId);
        let countryId = elem.CountryId;
        rows.push({
          rowIndex: index,
          Name: elem.Name,
          Email: elem.Email,
          Phone: elem.Phone,
          DbId: elem.Id as string,
          CountryId: countryId
        });
      }

      let sortedRows = this.sortRows(
        rows,
        this.state.country,
        this.state.order,
        this.state.searchQuery
      );

      this.setState({ rows, countries, sortedRows });
    } catch (error: any) {
      console.log("got error:");
      console.log(error);
    }
  }

  searchChanged(event: any, field: "country" | "search" | "order") {
    let countryId: string = this.state.country;
    let order = this.state.order;
    let query = this.state.searchQuery.toLocaleLowerCase();

    if (field === "country") {
      countryId = event;
    }
    if (field === "search") {
      query = event.target.value.toLocaleLowerCase();
    }
    if (field === "order") {
      order = this.state.order === "asc" ? "desc" : "asc";
    }

    let sortedRows = this.sortRows(this.state.rows, countryId, order, query);
    this.setState({
      country: countryId,
      sortedRows,
      order,
      searchQuery: query
    });
  }

  sortRows(
    rows: {
      rowIndex: number;
      Name: string;
      Email: string;
      Phone: string;
      DbId: string;
      CountryId: string;
    }[],
    country: string,
    order: "asc" | "desc",
    query: string
  ) {
    let sortedRows: {
      rowIndex: number;
      Name: string;
      Email: string;
      Phone: string;
      DbId: string;
      CountryId: string;
    }[] = [];
    for (let index = 0; index < rows.length; index++) {
      const element = rows[index];
      // If country is global or the selected country
      if (country === "" || element.CountryId === country) {
        if (query === "") {
          sortedRows.push(element);
        } else if (element.Name.toLocaleLowerCase().includes(query)) {
          sortedRows.push(element);
        }
      }
    }
    if (order === "asc") {
      sortedRows = sortedRows.sort((a, b) => (a.Name > b.Name ? 1 : -1));
    } else {
      sortedRows = sortedRows.sort((a, b) => (a.Name < b.Name ? 1 : -1));
    }
    return sortedRows;
  }

  prepareCountrySelecters() {
    let countries = this.state.countries;
    let countrySelects = [];

    countrySelects.push(
      <MenuItem key="-1" value="-1">
        Global
      </MenuItem>
    );

    for (let index = 0; index < countries.length; index++) {
      const country = countries[index];
      countrySelects.push(
        <MenuItem key={index} value={index}>
          {country.CountryName}
        </MenuItem>
      );
    }
    return countrySelects;
  }

  deleteUser(user: {
    rowIndex: number;
    Name: string;
    Email: string;
    Phone: string;
    DbId: string;
    CountryId: string;
  }, event: any) {
    event.stopPropagation();
    this.setState(() => ({ dialogueOpen: true, userToDelete: user }));
  }

  async handleClose(doDelete: boolean) {
    console.log(this.state.userToDelete)
    if (doDelete) {
      let result = await this.adminUserHelper.deleteAdminUser(
        this.state.userToDelete!.DbId
      );
      // Display that we are doing stuff
      await this.setState({ isDeleting: true });

      if (result != false) {
        SnackbarManager.Instance.addSuccess('User deleted successfully');
        this.setState({
          rows: this.state.rows.filter(t => t.DbId !== this.state.userToDelete!.DbId),
          dialogueOpen: false,
          userToDelete: null,
          isDeleting: false,
        });
      } else {
        SnackbarManager.Instance.addError('Could not delete user');

        this.setState({
          dialogueOpen: false,
          userToDelete: null,
          isDeleting: false,
        });
      }
    } else {
      this.setState({
        dialogueOpen: false,
        userToDelete: null,
        isDeleting: false
      });
    }
  }

  addNewUser() {
    this.props.history.push("/admin/employee");
  }



  render() {
    return (
      <div>
        <div className="AdminPageHeader">
          <div className="AdminPageTitle">Employees</div>
          <div className="AdminPageHeader_options">
            <div className="inputGroup">
              <TextField
                className="inputGroup_item"
                onChange={event => this.searchChanged(event, "search")}
                type="text"
                variant="outlined"
                margin="dense"
                style={{ margin: 0 }}
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className="inputAdornment">
                      <i className="fas fa-search" />
                    </InputAdornment>
                  )
                }}
              />
              <CountrySelector
                className="inputGroup_item"
                valueChanged={this.searchChanged}
              />

              <Button
                className="success inputGroup_item"
                variant="text"
                onClick={this.addNewUser}
              >
                <span className="btn-icon">
                  <i className="fas fa-plus" />
                </span>
                Add new
              </Button>
            </div>
          </div>
        </div>
        <div className="adminTable">
          <Table className="table">
            <TableHead className="tableHead">
              <TableRow className="tableRow">
                <TableCell
                  onClick={(event: any) => this.searchChanged(event, "order")}
                >
                  Name
                </TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell className="tableOptions" />
              </TableRow>
            </TableHead>
            <TableBody className="tableBody">
              {this.state.sortedRows.map(row => (
                <TableRow
                  key={row.rowIndex}
                  onClick={() => {
                    this.props.history.push("/admin/employee?id=" + row.DbId);
                  }}
                  className="tableRow"
                >
                  <TableCell className="tableCell">{row.Name}</TableCell>
                  <TableCell className="tableCell">{row.Email}</TableCell>
                  <TableCell className="tableCell">{row.Phone}</TableCell>
                  <TableCell
                    className="tableCell tableOptions"
                    onClick={(e: any) => this.deleteUser(row, e)}
                  >
                    <Tooltip title="Delete user" placement="top">
                      <i className="fas fa-trash-alt" />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>

        <Dialog
          open={this.state.dialogueOpen}
          onClose={(e: any) => this.handleClose(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Remove " +
              (this.state.userToDelete ? this.state.userToDelete.Name : "") +
              " as admin user?"}
          </DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Deleting an admin user will remove them from the Chabber
              administration interface completely. This cannot be undone!
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialogActions">
            <Button
              variant="contained"
              size="medium"
              onClick={(e: any) => this.handleClose(false)}
            >
              Cancel
            </Button>
            <Button
              className="danger"
              size="medium"
              variant="contained"
              onClick={(e: any) => this.handleClose(true)}
              disabled={this.state.isDeleting}
            >
              <span className="btn-icon">
                <i className="fas fa-trash-alt" />
              </span>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
