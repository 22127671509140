import { CourseModel } from "./CourseModel";
import { CMSMetaModel } from "./CMSMetaModel";

export class CoursePartnerModel {
  Id: string;
  Name: string;
  Courses: CourseModel[];
  CountryId: string;
  CourseMetaId: string;
  OrganizerCut: number;
  DefaultCoursePriceInclVat: number;
  HoursAsPay: number;
  DefaultCourseTerms: string;
  Email: string;
  CourseMeta: CMSMetaModel | null;
  [key: string]: any // After adding Courses, this breaks many places, but setting it to type 'any' is a small if slightly hacky way to rectify without breaking other things

  constructor(val: Partial<CoursePartnerModel> | undefined | null) {
    val = val || {};
    this.Id = val.Id || '';
    this.Name = val.Name || '';
    this.Courses = val.Courses || [];
    this.CountryId = val.CountryId || '';
    this.CourseMetaId = val.CourseMetaId || '';
    this.OrganizerCut = val.OrganizerCut || 50;
    this.DefaultCoursePriceInclVat = val.DefaultCoursePriceInclVat || 1800;
    this.HoursAsPay = val.HoursAsPay || 10;
    this.DefaultCourseTerms = val.DefaultCourseTerms || '';
    this.Email = val.Email || '';
    this.CourseMeta = val.CourseMeta || null;
  }
}

