import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import {} from "@material-ui/core";
import YouTube from "react-youtube";
import './YouTubeEmbed.scss';
import { string } from "prop-types";
interface IProps {
  videoID: string;
}
interface IState {}
export class YouTubeEmbed extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }
  render() {
    let videoID = this.props.videoID || "";
    const opts = {
      width: '1080',
      height: '607.5'
    };
    return (
      <div className="iframe-container">
        <YouTube videoId={videoID} 
          opts={opts}
        />
      </div>
    );
  }
}
