import React, { Fragment } from 'react';
import { RouteComponentProps } from 'react-router';
import { SearchPostings } from '../../../../../Components/Routing/Facade';


interface IProps extends RouteComponentProps { }

export function SearchPostingsNoLogin(props: IProps) {
    return (
        <Fragment>
            <div className="bodyContent">
                <SearchPostings {...props}></SearchPostings>
            </div>
        </Fragment>)

}

