import React from "react";
import "./ImagePreview.scss";
import Image from "rc-image";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  Close,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapHorizOutlined,
  SwapVertOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@material-ui/icons";

const defaultIcons = {
  rotateLeft: <RotateLeftOutlined />,
  rotateRight: <RotateRightOutlined />,
  zoomIn: <ZoomInOutlined />,
  zoomOut: <ZoomOutOutlined />,
  close: <Close />,
  left: <ArrowLeftOutlined />,
  right: <ArrowRightOutlined />,
  flipX: <SwapHorizOutlined />,
  flipY: <SwapVertOutlined rotate={90} />,
};

export default function ImagePreview({
  src,
  showToolbar,
}: {
  src?: string;
  showToolbar?: boolean;
}) {
  const [open, setOpen] = React.useState(false);

  return (
    <Image
      src={src}
      placeholder
      preview={{
        onVisibleChange: (visible) => {
          setOpen(visible);
        },
        toolbarRender: showToolbar
          ? undefined //default toolbar
          : () => {
              return <></>; //no toolbar
            },
        visible: open,
        movable: false,
        mask: "Click to preview",
        icons: defaultIcons,
      }}
    />
  );
}
