import React, { Component } from "react";
import { Menu, MenuItem, Divider } from '@material-ui/core';

export interface IMenuItem {
  iconCode: string;
  title: string;
  callback: any
}

interface IProps {
  anchorEl: HTMLElement | null;
  title: string;

  menuItems: IMenuItem[][]

  handleClose: () => void
}

interface IState {}


export class TableOptions extends Component<IProps, IState> {

  constructor(props:any) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose = () => {
    this.props.handleClose();
  };

  render() {
    let menuItems = []
    
    for (let index = 0; index < this.props.menuItems.length; index++) {
      const elemArr = this.props.menuItems[index];
      if (index > 0) menuItems.push(<Divider key={Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)} />);
      
      for (let index2 = 0; index2 < elemArr.length; index2++) {
        const element2 = elemArr[index2];
        menuItems.push(
          <MenuItem
            key={Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)}
            className="menuItem"
            onClick={element2.callback}>
            <i className={element2.iconCode}></i>
            {element2.title}
          </MenuItem>
        );
      }
    }

    return (
      <Menu
        id="simple-menu"
        anchorEl={this.props.anchorEl}
        open={this.props.anchorEl != null}
        onClose={this.handleClose}
        disableAutoFocusItem={true}
        className="dropdownMenu"
        classes={{ paper: 'dropdownMenu_content' }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
       >
        <div className="menuTitle">{this.props.title}</div>
        {menuItems}
        </Menu>
      );
  }
}


