// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from "react";
import moment from "moment";
import {
  Grid,
  Button,
  SwipeableDrawer,
  FormControlLabel,
  Checkbox,
  Slider,
  Tooltip
} from "@material-ui/core";
import { PostingModel } from "../../../../../Models/PostingModel";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "../../../../Parts/General/Calendar.scss";
import { CalendarLanguages } from "../../../../../Common/CalendarLanguages";
import { getLanguage } from "../../../../../Helpers/LanguageHelper";
import { StorageHelper } from "../../../../../Helpers/StorageHelper";
import { getCurrentUser } from "../../../../../Helpers/SessionHelper";


interface IProps {
  closeDrawer: () => void;
  filterChange: (name: string, val: any) => void;
  clearAllFilters: () => void;
  changedJobtype: (checked: boolean, title: string) => void;
  filterDrawerOpen: boolean;
  filterObj: {
    date: { isActive: boolean; value: Date | undefined };
    distance: { isActive: boolean; value: number };
    zip: { isActive: boolean; value: string };
  };
  allJobs: PostingModel[];
  allJobsNonFiltered: PostingModel[]
  jobTypes: {
    title: string;
    state: "selected" | "unselected" | "unavailable";
    id: string;
  }[];
}


interface IState {
  calendarLanguage: string;
  maxDistance: number;
}


export class SearchPostingsFilterDrawer extends Component<IProps, IState> {
  storageHelper = new StorageHelper();

  constructor(props: IProps) {
    super(props);
    let calendarLanguage = CalendarLanguages.Instance.getCalendarLanguage();
    let localization = this.storageHelper.getFacadeLocalization();

    if (!localization) {
      this.state = {
        calendarLanguage,
        maxDistance: 500,
      }
      return;
    }
    let country = localization.countries.find(x => x.Id === localization!.country.Id);
    let maxDistance = 500;
    if (country && country.MaxKmRadius != 0) {
      maxDistance = country.MaxKmRadius;
    }

    this.state = {
      calendarLanguage,
      maxDistance
    }
  }

  renderCalendar() {
    let days: Date[] = [];

    let latestDate = moment
      .max(this.props.allJobsNonFiltered.map(x => moment(x.StartAtLocal)))
      .toDate();

    days = this.props.allJobsNonFiltered.map(x => moment(x.StartAtLocal).toDate());
    if (days.length === 0) days.push(new Date());

    let disabledDates: Date[] = [];
    for (
      let currentDate = moment();
      currentDate.isBefore(latestDate, "date");
      currentDate.add(1, "day")
    ) {
      let dateHasJobs =
        days.findIndex(x => currentDate.isSame(x, "date")) != -1;
      if (dateHasJobs === false) {
        disabledDates.push(currentDate.toDate());
      }
    }
    latestDate;

    return (
      <DatePicker
        locale={this.state.calendarLanguage}
        selected={this.props.filterObj.date.value}
        // onChange={_ => { }}
        startDate={new Date()}
        endDate={Date.parse("2024-04-18")}
        // onSelect={date => this.props.filterChange("date", date)}
        inline
        selectsRange
        // minDate={new Date()}
        // maxDate={latestDate}
        // excludeDates={disabledDates}
        calendarClassName="Chabber_Calendar filterCalendar"
      />
    );
  }

  render() {

    return (
      <SwipeableDrawer
        anchor="right"
        open={this.props.filterDrawerOpen}
        onOpen={() => console.log("myellow")}
        onClose={() => this.props.closeDrawer()}
        className="filterDrawerWrapper"
        variant="temporary"
        classes={{ paper: "filterDrawer" }}
      >
        <div className="padding-32">
          <div className="margin-bottom-32">
            <Grid container justify="space-between">
              <Grid item>
                <h1 className="margin-0">{getLanguage(189, "Filter jobs")}</h1>
              </Grid>
              <Grid item>
                <Button
                  variant="text"
                  size="small"
                  onClick={this.props.clearAllFilters}
                >
                  <span className="btn-icon">
                    <i className="fas fa-times" />
                  </span>
                  {getLanguage(288, "Clear filter")}
                </Button>
              </Grid>
            </Grid>
          </div>
          <div className="filterSection">{this.renderCalendar()}</div>
          {/* Hidden because it's not working yet */}
          {/* <TextField
            value={this.props.filterObj.zip.value}
            onChange={(event) => this.props.filterChange('zip', event.target.value)}
            label="Location"
            placeholder="zipcode, city, etc."
            fullWidth
            variant="outlined"
            margin="dense"
            InputLabelProps={{
              shrink: true
            }}
          /> */}


          {this.renderDistanceSlider()}
          <div className="filterSection margin-v-16">
            {this.renderAllJobs()}
          </div>
        </div>
      </SwipeableDrawer>
    );
  }
  renderDistanceSlider() {
    const marks = [
      {
        value: 1,
        label: "1 km"
      },
      {
        value: this.state.maxDistance,
        label: this.state.maxDistance + " km" //TODO: Should be country specific. Miles/Km
      },
    ];

    if (getCurrentUser()) {
      return (
        <div className="filterSection margin-v-16">
          <div className="margin-v-40">
            <p>
              {getLanguage(289, "Radius from your home")} (
              {/* TODO: localize the km */}
              {this.props.filterObj.distance.value + " km"}
              )
              </p>
            <Slider
              min={1}
              value={this.props.filterObj.distance.value}
              max={this.state.maxDistance}
              step={10}
              defaultValue={50}
              marks={marks}
              valueLabelDisplay="auto"
              aria-labelledby="continuous-slider"
              aria-valuetext="Km" //TODO: Should be country based miles/km
              onChange={(event, value) =>
                this.props.filterChange("distance", value)
              }
            />
          </div>
        </div>)
    }
    return '';
  }
  renderAllJobs() {
    let jobArray: JSX.Element[] = [];
    let index = 0;
    for (const jobType of this.props.jobTypes) {
      index++;
      jobArray.push(
        <Tooltip
          key={jobType.title + index}
          title={
            jobType.state === "unavailable"
              ? getLanguage(290, "Job type not chosen in your resume")
              : ""
          }
          placement="left"
        >
          <div>
            <FormControlLabel
              disabled={jobType.state === "unavailable"}
              control={
                <Checkbox
                  checked={jobType.state === "selected"}
                  color="primary"
                  onChange={(event, checked) =>
                    this.props.changedJobtype(checked, jobType.title)
                  }
                />
              }
              label={jobType.title}
            />
          </div>
        </Tooltip>
      );
    }

    return jobArray;
  }
}
