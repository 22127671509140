import { FreelancerModel } from "./FreelancerModel";
import { CompanyModel } from "./CompanyModel";
import { MessageNetworkMemberModel } from "./MessageNetworkMemberModel";

export class LoginModel {
  Id: string;
  Firstnames: string;
  Lastname: string;
  Email: string;
  LoginType: number;
  Created: Date | null;
  Activated: boolean;
  PremiumLevel: number;
  LastLogin: Date | null;
  HeardAbout: string;
  Deleted: boolean;
  AcceptedLatestTOS: boolean;
  ActivationWarningSent: number;
  ParentCompanyId: string;
  Company: CompanyModel | null;
  Freelancer: FreelancerModel | null;
  FacebookId: string;
  AdminGranted: boolean;
  LoginPhone: string;
  PrimaryLanguage: string;
  ProfilePictureId: string;
  ProfilePictureUrl: string;
  CoverPictureId: string;
  CoverPictureUrl: string;
  SignaturePictureId: string;
  SignaturePictureUrl: string;
  TypeOfBusiness: string;
  AdminLevel: number;
  PushUserId: string;
  PushSettings: string;
  PushDisabledTill: string;
  FreelancerProfile: FreelancerModel | null;
  vmStepBasicDone: true | number[] | null;
  vmStepBankDone: true | number[] | null;
  vmStepResumeDone: true | number[] | null;
  MessageNetworkMembers: MessageNetworkMemberModel | null;
  isMaster: boolean;



  constructor(val: Partial<LoginModel>) {
    this.Id = val.Id || '';
    this.Firstnames = val.Firstnames || '';
    this.Lastname = val.Lastname || '';
    this.Email = val.Email || '';
    this.LoginType = val.LoginType || 0;
    this.Created = val.Created || null;
    this.Activated = val.Activated || false;
    this.PremiumLevel = val.PremiumLevel || 0;
    this.LastLogin = val.LastLogin || null;
    this.HeardAbout = val.HeardAbout || '';
    this.Deleted = val.Deleted || false;
    this.AcceptedLatestTOS = val.AcceptedLatestTOS || false;
    this.ActivationWarningSent = val.ActivationWarningSent || 0;
    this.ParentCompanyId = val.ParentCompanyId || '';
    this.FacebookId = val.FacebookId || '';
    this.AdminGranted = val.AdminGranted || false;
    this.LoginPhone = val.LoginPhone || '';
    this.PrimaryLanguage = val.PrimaryLanguage || '';
    this.ProfilePictureId = val.ProfilePictureId || '';
    this.ProfilePictureUrl = val.ProfilePictureUrl || '';
    this.CoverPictureId = val.CoverPictureId || '';
    this.CoverPictureUrl = val.CoverPictureUrl || '';
    this.SignaturePictureId = val.SignaturePictureId || '';
    this.SignaturePictureUrl = val.SignaturePictureUrl || '';
    this.TypeOfBusiness = val.TypeOfBusiness || '';
    this.AdminLevel = val.AdminLevel || 0;
    this.PushUserId = val.PushUserId || '';
    this.PushSettings = val.PushSettings || '';
    this.PushDisabledTill = val.PushDisabledTill || '';
    this.Freelancer = val.Freelancer || null;
    this.FreelancerProfile = val.FreelancerProfile || null;
    this.Company = val.Company || null;
    this.vmStepBasicDone = val.vmStepBasicDone || null;
    this.vmStepBankDone = val.vmStepBankDone || null;
    this.vmStepResumeDone = val.vmStepResumeDone || null;
    this.MessageNetworkMembers = val.MessageNetworkMembers || null;
    this.isMaster = val.isMaster || false;
    // this.CompetenceSelections = val.CompetenceSelections || '';
  }

  getProfileImageUrl() {
    return this.ProfilePictureUrl.replace('http://', 'https://').replace('/upload/', '/upload/w_300/');
  }

  getCoverImageUrl() {
    return this.CoverPictureUrl.replace('http://', 'https://').replace('/upload/', '/upload/w_900/');
  }
}