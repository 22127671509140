// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { Hidden } from "@material-ui/core";
import { SavingSnackbar } from "../../../../Parts/General/SavingSnackbar";
import { UserHelper } from "../../../../../Helpers/UserHelper";
import { getLanguage } from "../../../../../Helpers/LanguageHelper";
import { StorageHelper } from "../../../../../Helpers/StorageHelper";
import { getCurrentUser } from "../../../../../Helpers/SessionHelper";
import { PasswordFields } from "./PasswordFields";
import { NavLink } from "react-router-dom";
import { SnackbarManager } from "../../../../../Helpers/SnackbarManager/SnackbarManager";

interface IProps extends RouteComponentProps {
  location: any;
}

interface IState {
  fieldsHaveChanged: boolean;
}

export class ChangePassword extends Component<IProps, IState> {
  userHelper = new UserHelper();
  storageHelper = new StorageHelper();
  fieldRef = React.createRef<PasswordFields>();

  constructor(props: IProps) {
    super(props);

    this.state = {
      fieldsHaveChanged: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetFields = this.resetFields.bind(this);
    this.saveUser = this.saveUser.bind(this);
    this.fieldChanged = this.fieldChanged.bind(this)
  }

  async handleSubmit(e: any) {
    e.preventDefault();

    let user = getCurrentUser();
    if (user == null || this.fieldRef.current == null) {
      SnackbarManager.Instance.addError(getLanguage(708, 'Could not save password'));
      return;
    }

    let fields = this.fieldRef.current.state;
    let confirmation = await this.userHelper.changeUserPassword(fields.currentPassword,
      fields.newPassword1
    );
    if (confirmation) {
      SnackbarManager.Instance.addSuccess(getLanguage(701, "Password updated"))
      this.setState({
        fieldsHaveChanged: false
      });
    } else {
      await this.resetFields();
      SnackbarManager.Instance.addError(getLanguage(702, "Current password is incorrect"))
      this.setState({
        fieldsHaveChanged: false
      });
    }
    this.resetFields()
  }

  async resetFields() {
    if (this.fieldRef.current) {
      await this.fieldRef.current.resetFields();
    }
  }

  saveUser() {
    if (this.fieldRef.current) {
      let response = this.fieldRef.current.checkIfAllBoxesAreFilled();
      if (response === true) {
        this.handleSubmit(event);
        return;
      }
    }
    SnackbarManager.Instance.addError(getLanguage(575, "New passwords do not match"));
    this.setState({
      fieldsHaveChanged: false
    });
  }

  fieldChanged() {
    this.setState({ fieldsHaveChanged: true })
  }

  render() {
    let savingSnackbar: JSX.Element | undefined = undefined;
    savingSnackbar = (
      <SavingSnackbar
        open={this.state.fieldsHaveChanged}
        resetClicked={this.resetFields}
        saveClicked={this.saveUser}
      />
    );

    return (
      <div>
        <div>
          <div className="padding-v-32 maxW-600">
            <Hidden smUp>
              <NavLink to="/freelancer/settings">
                <i className="fas fa-arrow-left fa-lg margin-right-16"></i>
              </NavLink>
            </Hidden>
            <h1 className="inline-block">
              {getLanguage(57, "Change password")}
            </h1>

            <PasswordFields
              ref={this.fieldRef}
              fieldChanged={this.fieldChanged}
            />

            {savingSnackbar}
          </div>
        </div>
      </div>
    );
  }
}
