import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { UserHelper } from "../../../../../Helpers/UserHelper";
import { logUserOut } from "../../../../../Helpers/SessionHelper";
import { getLanguage } from "../../../../../Helpers/LanguageHelper";
interface IProps extends RouteComponentProps { }
interface IState { }

export class FreelancerMobileSettings extends Component<IProps, IState> {
  userHelper = new UserHelper();

  constructor(props: IProps) {
    super(props);

    this.logOut = this.logOut.bind(this);
  }

  async logOut() {
    let response = await logUserOut();
    if (response === true) {
      this.props.history.push("/");
    } else {
      // FIXME: show snackbar
    }
  }

  render() {
    return (
      <div>
        <div className="padding-v-16">
          <NavLink to="/freelancer/profile">
            <i className="fas fa-arrow-left" />
            <h2 className="inline-block margin-left-16">{getLanguage(54, "Settings")}</h2>
          </NavLink>
        </div>
        <List component="nav" aria-label="settings options" id="mobileSettings_list">
          <NavLink to="/freelancer/favourites">
            <ListItem button>
              <ListItemIcon>
                <i className="fas fa-heart fa-fw" />
              </ListItemIcon>
              <ListItemText primary={getLanguage(95, "Favourites")} />
            </ListItem>
          </NavLink>
          <NavLink to="/freelancer/contract">
            <ListItem button>
              <ListItemIcon>
                <i className="fas fa-file-signature fa-fw" />
              </ListItemIcon>
              <ListItemText primary={getLanguage(53, "Contract")} />
            </ListItem>
          </NavLink>
          <NavLink to="/freelancer/courses">
            <ListItem button>
              <ListItemIcon>
                <i className="fas fa-certificate fa-fw" />
              </ListItemIcon>
              <ListItemText primary={getLanguage(682, "Courses")} />
            </ListItem>
          </NavLink>
          <NavLink to="/freelancer/settings/basic">
            <ListItem button>
              <ListItemIcon>
                <i className="fas fa-user fa-fw" />
              </ListItemIcon>
              <ListItemText primary={getLanguage(55, "Personal data")} />
            </ListItem>
          </NavLink>
          <NavLink to="/freelancer/settings/bank">
            <ListItem button>
              <ListItemIcon>
                <i className="fas fa-credit-card fa-fw" />
              </ListItemIcon>
              <ListItemText primary={getLanguage(49, "Payment details data")} />
            </ListItem>
          </NavLink>
          <NavLink to="/freelancer/settings/resume">
            <ListItem button>
              <ListItemIcon>
                <i className="fas fa-portrait fa-fw" />
              </ListItemIcon>
              <ListItemText primary={getLanguage(56, "My resume")} />
            </ListItem>
          </NavLink>
          <NavLink to="/freelancer/settings/verification">
            <ListItem button>
              <ListItemIcon>
                <i className="fas fa-certificate fa-fw" />
              </ListItemIcon>
              <ListItemText primary={getLanguage(779, "Verification")} />
            </ListItem>
          </NavLink>
          <NavLink to="/freelancer/notifications">
            <ListItem button>
              <ListItemIcon>
                <i className="fas fa-bell fa-fw" />
              </ListItemIcon>
              <ListItemText primary={getLanguage(50, "Notifications")} />
            </ListItem>
          </NavLink>
          <NavLink to="/freelancer/settings/changepassword">
            <ListItem button>
              <ListItemIcon>
                <i className="fas fa-lock fa-fw" />
              </ListItemIcon>
              <ListItemText primary={getLanguage(57, "Change password")} />
            </ListItem>
          </NavLink>

          <ListItem button onClick={this.logOut}>
            <ListItemIcon>
              <i className="fas fa-sign-out-alt fa-fw" />
            </ListItemIcon>
            <ListItemText primary={getLanguage(58, "Log out")} />
          </ListItem>
        </List>
      </div>
    );
  }
}
