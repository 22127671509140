import React, { Component } from "react";
import { MenuItem, Switch, Grid, FormControlLabel, Tooltip } from "@material-ui/core";
import { getLanguage } from "../../../../../Helpers/LanguageHelper";
import { getCurrentUser } from "../../../../../Helpers/SessionHelper";
import { iAdditionalInfo, FormStates } from "./EditPostingTypes";
import { getPriceForProduct, getMoneyOutput } from "../../../../../Helpers/LocalizationHelper";
import { OptimizedTextField } from "../../../../Parts/General/OptimizedTextField";
import { CountryModel } from "../../../../../Models/CountryModel";

interface IProps {
  formState: FormStates;
  additionalInfo: iAdditionalInfo;
  changeValues: (value: any, name: string) => void;
  userIsPrivate?: boolean | null;
}
interface IState {}

export class AdditionalInfo extends Component<IProps, IState> {
  render() {
    let insuranceCostString = getLanguage(455, "Cancellation insurance ({{insuranceCost}} per chabber hired)");
    let moneyString = getMoneyOutput(getPriceForProduct("Cancel_Insurance"), false, null);
    if (moneyString) {
      insuranceCostString = insuranceCostString.replace("{{insuranceCost}}", moneyString);
    } else {
      insuranceCostString = insuranceCostString.replace("{{insuranceCost}}", getLanguage(335, "Error!"));
    }
    return (
      <div>
        <h2 className="brand">{getLanguage(425, "Additional information")}</h2>
        <Grid container spacing={4}>
          {!this.props.userIsPrivate ? (
            <Grid item lg={6}>
              <OptimizedTextField
                type="text"
                label={getLanguage(426, "Internal reference nr.")}
                margin="dense"
                placeholder={getLanguage(427, "Shown on your invoice")}
                value={this.props.additionalInfo.InternalRef || ""}
                onChange={(e) => this.props.changeValues(e.target.value, "InternalRef")}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          ) : null}
          <Grid item lg={6}>
            <OptimizedTextField
              id="transport-inout"
              select
              label={getLanguage(428, "Who's in charge of the job?")}
              margin="dense"
              value={this.props.additionalInfo.selectedJobOwner || ""}
              disabled={this.props.additionalInfo.jobOwners.length <= 1}
              onChange={(e) => this.props.changeValues(e.target.value, "SelectedJobOwner")}
            >
              {this.props.additionalInfo.jobOwners.map((x) => {
                let currentUserId = getCurrentUser()!.Id;
                let name = "";
                if (x.Id === currentUserId) {
                  name = getLanguage(179, "you");
                } else if (x.isMaster && x.Company != null) {
                  name = x.Company.CompanyName;
                } else {
                  name = x.Firstnames;
                }
                name += " - ";
                return (
                  <MenuItem key={x.Id} value={x.Id}>
                    {name} <i>{" " + x.Email}</i>
                  </MenuItem>
                );
              })}
            </OptimizedTextField>
          </Grid>
          {this.renderRecipient()}
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={12}>
            <FormControlLabel
              disabled={this.props.formState !== "newForm" && this.props.formState !== "newTemplate" && this.props.formState !== "editTemplate"}
              checked={this.props.additionalInfo.RequireExperience}
              control={<Switch color="primary" onChange={(e) => this.props.changeValues(e.target.checked, "RequireExperience")} />}
              label={getLanguage(429, "Relevant experience required")}
              labelPlacement="end"
            />
          </Grid>
          <Grid item lg={12} hidden={this.props.formState !== "newForm" && this.props.formState !== "newTemplate"}>
            <Tooltip
              title={getLanguage(
                750,
                "The cancellation insurance costs {{insurancePrice}} per hired chabber. This enables you to cancel a confirmed chabber at no extra charge. The regular cancellation fee is {{cancelPrice}}."
              )
                .replace("{{insurancePrice}}", getMoneyOutput(getPriceForProduct("Cancel_Insurance"), false, null))
                .replace("{{cancelPrice}}", getMoneyOutput(getPriceForProduct("Cancel_Freelancer"), false, null))}
              placement="top"
            >
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={this.props.additionalInfo.CancellationInsurance}
                    onChange={(e) => this.props.changeValues(e.target.checked, "CancellationInsurance")}
                  />
                }
                label={insuranceCostString}
                labelPlacement="end"
              />
            </Tooltip>
          </Grid>
          <Grid item lg={12}>
            <Tooltip title={this.props.additionalInfo.DisableAutohireToggle ? getLanguage(561, "You have not added any favourites yet") : ""} placement="right">
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={this.props.additionalInfo.DisableAutohireToggle ? false : this.props.additionalInfo.AutohireFavourites}
                    onChange={(e) => this.props.changeValues(e.target!.checked, "AutohireFavourites")}
                  />
                }
                label={getLanguage(430, "Auto hire my favourites")}
                labelPlacement="end"
                disabled={this.props.formState !== "newTemplate" && this.props.additionalInfo.DisableAutohireToggle}
              />
            </Tooltip>
          </Grid>
          <Grid item lg={12}>
            <FormControlLabel
              control={
                <Switch color="primary" checked={this.props.additionalInfo.AutoHireOnCancel} onChange={(e) => this.props.changeValues(e.target.checked, "AutoHireOnCancel")} />
              }
              label={getLanguage(456, "Auto hire on cancel")}
              labelPlacement="end"
            />
          </Grid>
          <Grid item lg={12}>
            <FormControlLabel
              disabled={this.props.formState !== "newForm" && this.props.formState !== "newTemplate" && this.props.formState !== "editTemplate"}
              checked={this.props.additionalInfo.SelfPaidBreak}
              control={<Switch color="primary" onChange={(e) => this.props.changeValues(e.target.checked, "SelfPaidBreak")} />}
              label={getLanguage(903, "Self-paid break")}
              labelPlacement="end"
            />
          </Grid>
          <Grid item lg={12}>
            <FormControlLabel
              disabled={this.props.formState !== "newForm" && this.props.formState !== "newTemplate" && this.props.formState !== "editTemplate"}
              checked={this.props.additionalInfo.MealIncluded}
              control={<Switch color="primary" onChange={(e) => this.props.changeValues(e.target.checked, "MealIncluded")} />}
              label={getLanguage(811, "Meal included")}
              labelPlacement="end"
            />
          </Grid>
          {this.props.additionalInfo.country?.Id === "4d8744a7-cf06-426c-95b4-bd24e465bd4f" && (
            <Grid item lg={12}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={this.props.additionalInfo.PaidTransportCollectiveAgreement}
                    onChange={(e) => this.props.changeValues(e.target.checked, "PaidTransportCollectiveAgreement")}
                  />
                }
                label={getLanguage(431, "Paid transport expenses according to collective agreement")}
                labelPlacement="end"
              />
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
  renderRecipient() {
    if (this.props.additionalInfo.invoiceRecipients && this.props.additionalInfo.invoiceRecipients.length > 0) {
      return (
        <Grid item xs={12}>
          <OptimizedTextField
            id="transport-inout"
            select
            label={getLanguage(483, "Recipient of invoice")}
            margin="dense"
            value={this.props.additionalInfo.invoiceRecipientId || "empty"}
            hidden={this.props.additionalInfo.invoiceRecipients.length <= 1}
            onChange={(e) => this.props.changeValues(e.target.value === "empty" ? "" : e.target.value, "InvoiceRecipient")}
          >
            <MenuItem key={"x.Id"} value="empty">
              {getLanguage(484, "default")}
              <i> </i>
            </MenuItem>
            {this.props.additionalInfo.invoiceRecipients.map((x) => {
              let options: JSX.Element[] = [];
              options.push(
                <MenuItem key={x.Id} value={x.Id}>
                  <span>
                    {x.Nickname}
                    <i>{"(" + x.Email + ")"}</i>
                  </span>
                </MenuItem>
              );

              return options;
            })}
          </OptimizedTextField>
        </Grid>
      );
    }
    return "";
  }
}
