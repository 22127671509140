import { AxiosRequestConfig } from 'axios';
import { CountryModel } from '../Models/CountryModel';
import { StorageHelper } from './StorageHelper';
import { CallManager } from './CallManager/CallManager';


export class CountryHelper {
  storageHelper = new StorageHelper();
  clientKey = '04216571796E48ABB8FD781DEA966A4F';



  async getActiveCountries() {
    try {
      let config: AxiosRequestConfig = {
        method: 'get',
        url: '/api/countries/active',
        withCredentials: true
      };
      let response = await CallManager.Instance.makeCall(config, false, false);
      let countries: CountryModel[] = response.data;
      return countries;
    } catch (error: any) {
      console.log(error)
      return undefined;
    }
  }

  async getCountry(countryId: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'get',
        url: '/api/countries/countrybyid?countryid=' + countryId,
        withCredentials: true
      };
      let response = await CallManager.Instance.makeCall(config, false, false);

      return response.data;
    } catch (error: any) {
      return false;
    }
  }


  async getNationalities() {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: 'get',
        url: '/allcountries',
        headers: {
          'Content-Type': 'application/json',
          clientKey: this.clientKey,
          authToken
        }
      };
      let response = await CallManager.Instance.makeCall(config, true);

      return response.data;
    } catch (error: any) {
      return false;
    }
  }
}