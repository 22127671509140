import { AxiosRequestConfig } from 'axios';
import { PostingModel } from '../Models/PostingModel';
import { StorageHelper } from './StorageHelper';
import { CallManager } from './CallManager/CallManager';

export class InvoiceHelper {

    async getAllInvoices() {
        try {
            let config: AxiosRequestConfig = {
                method: 'get',
                url: '/api/company/invoice/all',
                withCredentials: true,
                headers: {
                    "Content-type": "application/json",
                    authToken: new StorageHelper().getAccessToken(),
                },
            }
            let response = await CallManager.Instance.makeCall(config, false);

            if (response.data.length)
                return response.data as PostingModel[];

            return false;
        } catch (error: any) {
            console.log(error);
            return false;
        }
    }

    async downloadInvoicePdf(externalId: string) {
        try {
            let config: AxiosRequestConfig = {
                method: 'get',
                url: '/api/company/invoice/byid/' + externalId + '/pdf',
                withCredentials: true,
                headers: {
                    "Content-type": "application/json",
                    authToken: new StorageHelper().getAccessToken(),
                },
            }
            let response = await CallManager.Instance.makeCall(config, false);

            return response.data;
        } catch (error: any) {
            console.log(error);
            return false;
        }
    }

    async downloadInvoiceCsv(externalIds: string[]) {
        try {
            let config: AxiosRequestConfig = {
                method: 'post',
                url: '/api/company/invoice/byid/csv',
                withCredentials: true,
                headers: {
                    "Content-type": "application/json",
                    authToken: new StorageHelper().getAccessToken(),
                },
                data: { externalIds },
            }
            let response = await CallManager.Instance.makeCall(config, false);

            return response.data;
        } catch (error: any) {
            console.log(error);
            return false;
        }
    }

}