import { SkillModel } from "../../../../../Models/SkillModel";
import { Moment } from "moment";
import { CompanyInvoiceModel } from "../../../../../Models/CompanyInvoiceModel";
import moment from "moment";
import { PostingTemplateModel } from "../../../../../Models/PostingTemplateModel";
import { PostingModel } from "../../../../../Models/PostingModel";
import { FavoriteProfileModel } from "../../../../../Models/FavoriteProfileModel";
import { LoginModel } from "../../../../../Models/LoginModel";
import { EventTypeModel } from "../../../../../Models/EventTypeModel";
import { CountryModel } from "../../../../../Models/CountryModel";

export class JobDetailsHolder {
  skills: SkillModel[];
  selectedJobtype: string;
  amountNeeded: number;
  date: Moment;
  startTime: number;
  endTime: number;
  salaryPerHour: string;
  salaryIntervals: { timeInHalfHours: number; salaryPerHour: string }[]; // object that holds salary values
  selectedOvertime: number;
  expectedOvertime: number[]; //Dropdown
  selectedPaidTransport: number;
  paidTransport: number[];
  defaultSalary: string;
  AutoHireOnCancel: boolean;
  EventTypes: EventTypeModel[];
  SelectedEventType: string;
  StartAtLocal: string;

  constructor(val: Partial<JobDetailsHolder>) {
    let date = moment();
    if (date.get("minutes") === 0 || date.get("minutes") === 30) {
      date.add(1, "minute");
    }

    let halfHours = date.get("minutes");
    halfHours += date.get("hours") * 60;
    halfHours = Math.ceil(halfHours / 30);

    let startTime = halfHours + 4;
    let endTime = halfHours + 18;

    date.set("hours", 0);
    date.set("minutes", 0);

    this.skills = val.skills || [];
    this.selectedJobtype = val.selectedJobtype || "0";
    this.amountNeeded = val.amountNeeded || 1;
    this.date = val.date || date;
    this.startTime = val.startTime || startTime;
    this.endTime = val.endTime || endTime;
    this.salaryPerHour = val.salaryPerHour || "0.00";
    this.salaryIntervals = val.salaryIntervals || [];
    this.selectedOvertime = val.selectedOvertime || 0;
    this.expectedOvertime = val.expectedOvertime || [];
    this.selectedPaidTransport = val.selectedPaidTransport || 0;
    this.paidTransport = val.paidTransport || [];
    this.defaultSalary = val.defaultSalary || "0";
    this.AutoHireOnCancel = val.AutoHireOnCancel || false;
    this.EventTypes = val.EventTypes || [];
    this.SelectedEventType = val.SelectedEventType || "";
    this.StartAtLocal = val.StartAtLocal || "";
  }
}

export interface iInformationForChabber {
  chabberMustBring: string;
  contact: string;
  jobDescription: string;
  address: string;
  zip: string;
  city: string;
}

export interface iAdditionalInfo {
  InternalRef: string;
  selectedJobOwner: string;
  jobOwners: LoginModel[];
  RequireExperience: boolean;
  CancellationInsurance: boolean;
  AutohireFavourites: boolean;
  DisableAutohireToggle: boolean;
  PaidTransportCollectiveAgreement: boolean;
  AutoHireOnCancel: boolean;
  invoiceRecipientId: string;
  invoiceRecipients: CompanyInvoiceModel[];
  SelfPaidBreak: boolean;
  MealIncluded: boolean;
  country: CountryModel | null;
}

export interface iEditPostingState {
  jobDetails: JobDetailsHolder;
  informationForChabber: iInformationForChabber;
  additionalInfo: iAdditionalInfo;

  templateDrawerOpen: boolean;

  Voucher: string;
  AcceptedTerms: boolean;
  errors: { [key: string]: { message: string; isErr: boolean } };
  averageDailySalary: number;

  minimumHourlyWage: number;
  suggestedHourlyRate: number | undefined;
  salaryElement: any;
  salaryBelowAverage: boolean;
  submittingForm: boolean;
  formState: FormStates;
  originalPosting: PostingModel | null;
  saveTemplateDialogueOpen: boolean;
  overbookingDialogueOpen: boolean;
  templateTitle: string;
  originalTemplate: PostingTemplateModel | null;
  overbookAmount: number;
  templateTitleTooShort: boolean;
  templates: PostingTemplateModel[];
  appliedTemplate: PostingTemplateModel | null;
  displayPdf: null | "TermsAndConditions" | "PrivacyPolicy";

  disableContinueButton: boolean;
  disableBufferButton: boolean;
  user: LoginModel | null;
  hasCreditcard: boolean;
}

export enum FormStates {
  preparing = "preparing",
  newForm = "newForm",
  loadForm = "loadForm",
  loadFormWithChabbers = "loadFormWithChabbers",
  loadClosedForm = "loadClosedForm",
  newTemplate = "newTemplate",
  editTemplate = "editTemplate",
  unavailable = "unavailable",
}

export interface iInitialPostingData {
  templates: PostingTemplateModel[];
  favourited: FavoriteProfileModel[];
  jobOwners: LoginModel[];
  salaryData: iAverageSalary;
  invoiceRecipients: CompanyInvoiceModel[];
  creationDate: Moment | null;
}

export interface iAverageSalary {
  avgValue: string;
  maxValue: number;
  neededValue: string;
}
