// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { Grid, Button, Hidden, Dialog, DialogContent } from "@material-ui/core";
import { getLanguage } from "../../../../../Helpers/LanguageHelper";
import "./Segments.scss";
import blobLarge from "../../../../../assets/img/shapes/blobLarge.svg";
import blobMedium from "../../../../../assets/img/shapes/blobMedium.svg";
import blobMediumTwo from "../../../../../assets/img/shapes/blobMedium_2.svg";
import { NavLink } from "react-router-dom";
import { EventCaseVenues } from "../cases/Venues";
import { FeaturePreview } from "../parts/FeaturePreview";
import { Footer } from "../../Navigation/Footer";
import { YouTubeEmbed } from "../../../../Parts/General/YouTubeEmbed";
interface IProps extends RouteComponentProps { }
interface IState {
  dialogOpen: boolean;
}
export class EventsSegment extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      dialogOpen: false,
    }
  }
  render() {
    return (
      <div className="segmentPage">
        <div className="hero">
          <Hidden mdUp>
            <img
              className="heroImage_mobile"
              src="/img/photos/segment_event_mobile.png"
              alt="Product image"
            />
          </Hidden>
          <div className="container">
            <Grid container>
              <Grid item md={6}>
                <h1 className="tagline">{getLanguage(492, "Staffing for events. Big and small.")}</h1>
                <p className="heroContent">
                  {getLanguage(355, "Using Chabber for your events you'll always be guarantied waiting and kitchen freelancers 24/7. That way you'll always be fully staffed and have time for your guests.")}
                </p>
                <div className="inputGroup">
                  <Button
                    className="button-gradient inputGroup_item"
                    variant="contained"
                    size="medium"
                  >
                    <span className="btn-icon">
                      <i className="fas fa-arrow-right" />
                    </span>
                    {getLanguage(351, "Get started!")}
                  </Button>
                  <Button className="inputGroup_item" onClick={() => this.setState({ dialogOpen: true })}>
                    {getLanguage(352, "Watch video")}
                  </Button>
                </div>
              </Grid>
              <Grid item md={6}>
                <Hidden smDown>
                  <img
                    className="heroImage"
                    src="/img/photos/segment_event@2x.png"
                    alt="Product image"
                  />
                </Hidden>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="container">
          <section className="text-center">
            <Hidden smDown>
              <img className="blob blobMedium blobValue" src={blobMediumTwo} alt="svg blob" />
            </Hidden>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={4}>
                <div className="valueProp">
                  <div className="valueProp_Icon">
                    <i className="far fa-clock fa-3x"></i>
                  </div>
                  <div className="valueProp_Title">{getLanguage(493, "Access 24/7")}</div>
                  <div className="valueProp_Content">
                    {getLanguage(494, "With a profile on Chabber you can book freelancer round the clock. You can do this through our website or app.")}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="valueProp">
                  <div className="valueProp_Icon">
                    <i className="far fa-clock fa-3x"></i>
                  </div>
                  <div className="valueProp_Title">{getLanguage(495, "Guaranteed freelancers")}</div>
                  <div className="valueProp_Content">
                    {getLanguage(496, "Chabber community consists of thousands of freelancers, which is why we've always got people ready to help you out")}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="valueProp">
                  <div className="valueProp_Icon">
                    <i className="far fa-clock fa-3x"></i>
                  </div>
                  <div className="valueProp_Title">{getLanguage(497, "Fast respons")}</div>
                  <div className="valueProp_Content">
                    {getLanguage(498, "It takes an average of 11 minutes from you post your job to the first applicants roll in.")}
                  </div>
                </div>
              </Grid>
            </Grid>
          </section>
          <section>
            <img className="blob blobLarge blobCase" src={blobLarge} alt="svg blob" />
            <div className="sectionHeading">{getLanguage(361, "This is what our customers say")}</div>
            <EventCaseVenues />
          </section>
          <section>
            <Hidden smDown>
              <img className="blob blobMedium blobFeatures" src={blobMedium} alt="svg blob" />
            </Hidden>
            <FeaturePreview />
          </section>
        </div>
        <Footer />
        <Dialog open={this.state.dialogOpen} onClose={() => this.setState({ dialogOpen: false })} fullWidth maxWidth="md">
          <DialogContent>
            <YouTubeEmbed videoID="Km7u9dtLnnc" />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
