import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { } from '@material-ui/core';
interface IProps extends RouteComponentProps {}
interface IState {}
export class FeaturesCompany extends Component<IProps, IState> {
constructor(props: IProps) {
super(props);
}
render(){
return(<div>Features</div>)
}
}