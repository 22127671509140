export class EducationModel {
    Id: string;
    FreelancerProfileId: string;
    Title: string;
    SchoolName: string;
    FinishedYear: number;
    Description: string;
    error: string;
    DescriptionNonLocalised: string | null;



    constructor(val: Partial<EducationModel>) {
        this.Id = val.Id || '';
        this.FreelancerProfileId = val.FreelancerProfileId || '';
        this.Title = val.Title || '';
        this.SchoolName = val.SchoolName || '';
        this.FinishedYear = val.FinishedYear || 0;
        this.Description = val.Description || '';
        this.error = val.error || '';
        this.DescriptionNonLocalised = val.DescriptionNonLocalised || '';


    }
}
