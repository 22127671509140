import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import './Login.scss';
import {
  Button,
  TextField,
  Grid,
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { isEmail } from 'validator';
import { logUserOut } from '../../../../Helpers/SessionHelper';
import { getLanguage } from '../../../../Helpers/LanguageHelper';
import { SnackbarManager } from '../../../../Helpers/SnackbarManager/SnackbarManager';
import { RouteHandler } from '../../../../Helpers/RouteHandler';
import { getQuery } from "../../../../Helpers/queryHelper";
import { StorageHelper } from '../../../../Helpers/StorageHelper';

interface IProps extends RouteComponentProps { }

export function MainLogin(props: IProps) {
  let storageHelper = new StorageHelper();
  let routeHandler = new RouteHandler();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [facebookOpen, setFacebookOpen] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [loginCheckComplete, setLoginCheckComplete] = useState(false)

  useEffect(() => {
    didMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function didMount() {
    if (!loginCheckComplete) {
      let accessToken = getQuery('token', props.location.search);
      let existingToken = storageHelper.getAccessToken();
      if (accessToken && existingToken && accessToken !== existingToken) {
        await logUserOut();
      }

      let redirectPath = getQuery('redirect', props.location.search);
      if (accessToken) {
        // @ts-ignore
        await routeHandler.attemptLogIn(props.history, redirectPath, undefined, undefined, accessToken);
      } else {
        // @ts-ignore
        await routeHandler.checkRouting(props.history, undefined, redirectPath)
      }

      setLoginCheckComplete(true)
    }
  }

  async function handleSubmit(e: any) {
    e.preventDefault();

    await verifyFields('email', email);
    await verifyFields('password', password);

    let anyErrors = false;
    for (const error in errors) {
      if (errors[error] !== '') anyErrors = true;
    }

    if (anyErrors) {
      return;
    }

    setDisableSubmit(true);
    // @ts-ignore
    let response = await routeHandler.attemptLogIn(props.history, getQuery('redirect', props.location.search), email, password);
    if (response === false) {
      SnackbarManager.Instance.addError(getLanguage(709, 'Could not log in, check that email and password are correct'));
    }
    setDisableSubmit(false);
  }

  function verifyFields(name: string, value: any) {
    let localErrors = Object.assign({}, errors);
    if (name === 'email') {
      if (typeof value !== 'string' || !isEmail(value)) {
        localErrors[name] = getLanguage(613, 'Not a valid email');
      } else {
        localErrors[name] = '';
      }
    } else if (name === 'password') {
      if (typeof value !== 'string' || value.length === 0) {
        localErrors[name] = getLanguage(614, 'Please fill in your password');
      } else {
        localErrors[name] = '';
      }
    }
    setErrors(localErrors);
  }

  function getLoginForm() {
    const facebookDialogue = (
      <div id="facebookLoginDeprecated" className="padding-32">
        <h2>{getLanguage(176, 'Facebook login de-actived')}</h2>
        <p className="paragraphText">
          {getLanguage(
            177,
            "We've removed the ability to use facebook as a verified login...",
          )}
        </p>
        <div className="padding-v-32">
          <Button href="/forgotpassword" className="button-gradient">
            {getLanguage(178, 'Get new password')}
          </Button>
        </div>
      </div>
    );

    const loginForm = (
      <div className="padding-40">
        <div className="margin-v-24">
          <h1 className="displayFont">{getLanguage(43, 'Login')}</h1>
        </div>
        <TextField
          type="email"
          label={getLanguage(73, 'Email')}
          variant="outlined"
          fullWidth
          onBlur={() => verifyFields('email', email)}
          onFocus={() => {
            let localErrors = Object.assign({}, errors);
            localErrors.email = '';
            setErrors(localErrors);

          }}
          error={
            errors.email !== ''
            && errors.email != null
          }
          helperText={errors.email}
          autoFocus
          margin="normal"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <TextField
          type="password"
          label={getLanguage(170, 'Password')}
          variant="outlined"
          fullWidth
          margin="normal"
          onBlur={() => verifyFields('password', email)}
          onFocus={() => {
            let localErrors = Object.assign({}, errors);
            localErrors.password = '';
            setErrors(localErrors);
          }}
          error={
            errors.password !== ''
            && errors.password != null
          }
          helperText={errors.password}
          value={password}
          onChange={(event) => changePasswordValue(event.target.value)}
        />
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <Grid item>
            <NavLink to="forgotpassword">
              <div>
                <Button size="small" variant="text" >
                  {getLanguage(172, 'Forgot password')}
                </Button>
              </div>
            </NavLink>
          </Grid>
        </Grid>
        <div className="margin-v-40 text-right">
          <Button
            className="button-gradient"
            variant="contained"
            size="large"
            disabled={disableSubmit}
            onClick={handleSubmit}
            type="submit"
          >
            <span className="btn-icon">
              <i className="fas fa-arrow-right" />
            </span>
            {getLanguage(43, 'Login')}
          </Button>
          <p className="padding-v-16">
            {getLanguage(173, 'Havent got an account?')}
            <NavLink to="/register">
              <strong>
                {' '}
                {getLanguage(174, 'Create one now')}
              </strong>
            </NavLink>
          </p>
        </div>

        <div className="margin-v-24 text-center" id="facebookLogin">
          <Button
            size="small"
            className="button-facebook"
            onClick={() => setFacebookOpen(true)}
          >
            <span className="btn-icon">
              <i className="fab fa-facebook-square" />
            </span>
            {getLanguage(175, 'Login with facebook')}
          </Button>
        </div>
      </div>
    );

    if (loginCheckComplete) {
      return (
        <form action="/" method="POST" onSubmit={handleSubmit}>
          <div className="backgroundGradient vh-100">
            <div className="text-center">
              <div className="loginPaper">
                {facebookOpen ? facebookDialogue : loginForm}
              </div>
            </div>
          </div>
        </form>
      );
    }
    else {
      return <div></div>
    }
  }

  async function changePasswordValue(password: string) {
    await verifyFields('password', password);
    setPassword(password);
  }


  return (
    <div>
      {getLoginForm()}
    </div>
  );
}
