import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  useTheme,
  IconButton,
  Button,
  Stepper,
  Step,
  StepLabel,
  Container,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import "./index.scss";
import { AcceptationStep } from "../OnboardingSteps/AcceptationStep";
import { RulesStep } from "../OnboardingSteps/RulesStep";
import "./index.scss";
import { useMutation } from "react-query";
import { useDefaultCollectiveAgreementRules } from "../../Service";
import { checkIfAnyRuleInvalid } from "../../Utils";
import { SnackbarManager } from "../../../../Helpers/SnackbarManager/SnackbarManager";
import { ConfirmationStep } from "../OnboardingSteps/ConfirmationStep";
import axios from "axios";
import { StorageHelper } from "../../../../Helpers/StorageHelper";
import { CollectiveAgreementDto, Rule } from "../../Types";
import { getLanguage } from "../../../../Helpers/LanguageHelper";
import { PageLoader } from "../../../../Components/Parts/General/PageLoader";

type CollectiveAgreementModalProps = {
  skillId: string;
  isOpen: boolean;
  onClose: () => void;
  currentRules?: Rule[];
};

export function CollectiveAgreementOnboardingModal({
  isOpen,
  onClose,
  skillId,
  currentRules,
}: CollectiveAgreementModalProps) {
  const [collectiveAgreementAccepted, setCollectiveAgreementAccepted] =
    React.useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [collectiveAgreementRules, setCollectiveAgreementRules] = useState<
    Rule[]
  >([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [isFirstConfirmed, setIsFirstConfirmed] = React.useState(false);
  const [isSecondConfirmed, setIsSecondConfirmed] = React.useState(false);
  const postCollectiveAgreement = useMutation(
    (payload: CollectiveAgreementDto) => {
      return axios.post(
        `/api/company/collectiveagreementrules/${skillId}`,
        payload,
        {
          headers: {
            "Content-type": "application/json",
            authToken: new StorageHelper().getAccessToken(),
          },
        }
      );
    }
  );

  useEffect(() => setTermsAccepted(false), [collectiveAgreementAccepted]);

  const { data: defaultRules, isLoading } =
    useDefaultCollectiveAgreementRules(skillId);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (defaultRules?.data && !isLoading) {
      setCollectiveAgreementRules(
        currentRules && currentRules.length > 0
          ? currentRules
          : [...defaultRules.data]
      );
    }
  }, [defaultRules]);

  useEffect(() => {
    setCollectiveAgreementAccepted(false);
    setActiveStep(0);
    setIsFirstConfirmed(false);
    setIsSecondConfirmed(false);
  }, [isOpen]);

  const steps = [
    {
      title: getLanguage(868, "Collective agreement"),
      content: (
        <AcceptationStep
          collectiveAgreementAccepted={collectiveAgreementAccepted}
          onCollectiveAgreementAcceptedChanged={setCollectiveAgreementAccepted}
          termsAccepted={termsAccepted}
          onTermsAcceptedChanged={setTermsAccepted}
        />
      ),
    },
    {
      title: getLanguage(869, "Rates"),
      content: (
        <RulesStep
          rules={collectiveAgreementRules}
          onChange={(rules) => {
            setCollectiveAgreementRules(rules);
          }}
        />
      ),
    },
    {
      title: getLanguage(870, "Confirmation"),
      content: (
        <ConfirmationStep
          isFirstConfirmed={isFirstConfirmed}
          onFirstChange={setIsFirstConfirmed}
          isSecondConfirmed={isSecondConfirmed}
          onSecondChange={setIsSecondConfirmed}
        />
      ),
    },
  ];

  const handleNext = () => {
    if (activeStep === 1) {
      if (checkIfAnyRuleInvalid(collectiveAgreementRules)) {
        SnackbarManager.Instance.addWarning(
          "Collective agreement rules are invalid"
        );
        return;
      }
    }

    if (activeStep === 0 && !collectiveAgreementAccepted) {
      setActiveStep((prevActiveStep) => prevActiveStep + 2);
    } else {
      if (termsAccepted) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    if (activeStep === steps.length - 1 && !collectiveAgreementAccepted) {
      setActiveStep(0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleSubmit = async () => {
    if (!isFirstConfirmed || !isSecondConfirmed) {
      return;
    }
    await postCollectiveAgreement.mutateAsync({
      rules: collectiveAgreementAccepted
        ? collectiveAgreementRules.map((x) => ({
            ...x,
            value: Math.round(Number(x.value.replace(",", ".")) * 100),
          }))
        : [],
      isAccepted: collectiveAgreementAccepted,
    });
    SnackbarManager.Instance.addSuccess(getLanguage(697, "Changes saved"));
    onClose();
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
      fullScreen={fullScreen}
    >
      <DialogTitle id="simple-dialog-title">
        {getLanguage(867, "Describe salary rules")}{" "}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            style={{ position: "absolute", right: 0 }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {isLoading ? (
        <PageLoader />
      ) : (
        <DialogContent className="dialog-content">
          <div className="step-indicator-container">
            <Stepper activeStep={activeStep}>
              {steps.map((step) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: { optional?: React.ReactNode } = {};

                return (
                  <Step key={step.title} {...stepProps}>
                    <StepLabel {...labelProps}>{step.title}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
          <Container className="step-content">
            {steps[activeStep].content}
          </Container>
          <Divider />
          <Container className="navigation-container">
            <Button
              className="white"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              {getLanguage(218, "Back")}
            </Button>
            {activeStep !== steps.length - 1 ? (
              <Button
                variant="contained"
                color="primary"
                className="success inputGroup_item"
                onClick={handleNext}
              >
                {getLanguage(794, "Next")}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                className="success inputGroup_item"
                onClick={handleSubmit}
              >
                {getLanguage(663, "Save")}
              </Button>
            )}
          </Container>
        </DialogContent>
      )}
    </Dialog>
  );
}
