
export class CommunicationEventData {
    CommunicationEventId: string;
    MailTitle: string;
    MailContents: string;
    MailExtras: string;
    TextContents: string;
    PushTitle: string;
    PushContents: string;
    PushPayload: string;
    PushCommands: string;
    [key: string]: string;

  
    constructor(val: Partial<CommunicationEventData>) {
      this.CommunicationEventId = val.CommunicationEventId || '';
      this.MailTitle = val.MailTitle || '';
      this.MailContents = val.MailContents || '';
      this.MailExtras = val.MailExtras || '';
      this.TextContents = val.TextContents || '';
      this.PushTitle = val.PushTitle || '';
      this.PushContents = val.PushContents || '';
      this.PushPayload = val.PushPayload || '';
      this.PushCommands = val.PushCommands || '';
    }
  }
  