import React, { Component } from "react";
import { BlogHelper } from "../../../../../../Helpers/BlogHelper";
import { CMSMetaModel } from "../../../../../../Models/CMSMetaModel";
import "./BlogOverview.scss";

import { Grid, Avatar } from "@material-ui/core";
import { CMSRevisionModel } from "../../../../../../Models/CMSRevisionModel";
import { NavLink } from "react-router-dom";
import { configureCloudinaryUrl } from "../../../../../../Helpers/ImageHelper";
interface IProps { }
interface IState {
  posts: CMSMetaModel[];
  primaryPost: CMSMetaModel | undefined;
}

export class BlogOverview extends Component<IProps, IState> {
  blogHelper = new BlogHelper();

  constructor(props: any) {
    super(props);
    this.state = {
      posts: [],
      primaryPost: undefined
    };

    this.fetchPosts = this.fetchPosts.bind(this);
  }
  componentDidMount() {
    this.fetchPosts();
  }

  async fetchPosts() {
    let posts: | CMSMetaModel[] | null = await this.blogHelper.getOverviews();
    console.log('posts', posts);
    if (posts == null || posts.length < 1) return;

    posts = posts.sort((a, b) =>
      (new CMSMetaModel(a).getNewestRevision() as CMSRevisionModel).Created <
        (new CMSMetaModel(b).getNewestRevision() as CMSRevisionModel).Created
        ? 1
        : -1
    );

    let primary = posts.shift();

    this.setState({ posts, primaryPost: primary });
  }

  buildNewestPost() {
    if (this.state.primaryPost == undefined) return <div />;
    let post = new CMSMetaModel(this.state.primaryPost);
    let rev = post.getPrimaryRevision();
    if (rev == null) return <div />;
    return (
      <NavLink to={"/blog/" + post.PublicId}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="stretch"
          spacing={0}
        >
          <Grid item xs={6}>
            <div className="padding-24 blogCard_Content">
              <h1>{post.Title}</h1>
              <p>{post.Description}</p>
              <div className="author margin-v-8">
                <Avatar
                  className="author_Image"
                  src={(post.Author && configureCloudinaryUrl(post.Author!.ProfileImageUrl, ['w_300'])) || ""}
                />
                <div className="author_Text">
                  <p>{(post.Author && post.Author!.Name) || ""}</p>
                  <h6 className="margin-0">{rev.Created}</h6>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <img className="blogCard_image" src={rev.CoverImageUrl} />
          </Grid>
        </Grid>
      </NavLink>
    );
  }

  buildRemainingPosts() {
    if (this.state.posts.length < 1) return <div />;

    let posts: JSX.Element[] = [];

    for (let index = 0; index < this.state.posts.length; index++) {
      const post = new CMSMetaModel(this.state.posts[index]);
      const rev = post.getPrimaryRevision();
      if (!rev) continue;

      let elem = (
        <Grid item xs={4} key={post.PublicId}>
          <NavLink to={"/blog/" + post.PublicId}>
            <div className="borderBox radius-6">
              <img className="blogCard_image" src={rev.CoverImageUrl} />
              <div className="padding-24 blogCard_Content">
                <h1>{post.Title}</h1>
                <p>{post.Description}</p>
                <h6 className="margin-v-8">{rev.Created}</h6>
              </div>
            </div>
          </NavLink>
        </Grid>
      );

      posts.push(elem);
    }

    return posts;
  }

  render() {
    let newestPost = this.buildNewestPost();
    let remainingPosts = this.buildRemainingPosts();

    return (
      <div>
        <div className="container">
          <div className="blogs_hero">
            <div className="borderBox radius-6">{newestPost}</div>
          </div>
          <div className="blogs_recent">
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="stretch"
              spacing={5}
            >
              {remainingPosts}
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}
