import React, { Component } from 'react';
import { MenuItem } from '@material-ui/core';
//@ts-ignore
import { isLength, isEmail } from 'validator';
import { CountryModel } from '../../../../Models/CountryModel';
import { StorageHelper } from '../../../../Helpers/StorageHelper';
import { getLanguage } from '../../../../Helpers/LanguageHelper';
import { OptimizedTextField } from "../../../Parts/General/OptimizedTextField";
import { PhoneField } from "../../../Parts/General/PhoneField";
import { getPhoneCountryCode } from '../../../../Helpers/GenericHelper';
import { CountryHelper } from '../../../../Helpers/CountryHelper';

interface IProps {
  possibleCountries: CountryModel[];
  changeField: (fieldname: string, value: any) => void;
  formsData: any;
}
interface IState {
  errors: { email: boolean, password: boolean, firstNames: boolean, lastName: boolean, phone: boolean, [key: string]: boolean }
  countries: CountryModel[];
}

export class RegisterFreelancer extends Component<IProps, IState> {
  storageHelper = new StorageHelper();
  countryHelper = new CountryHelper();
  phoneRef = React.createRef<PhoneField>();

  constructor(props: IProps) {
    super(props);
    this.state = {
      errors: { email: false, password: false, firstNames: false, lastName: false, phone: false },
      countries: [],
    }
  }

  componentDidMount() {
    let countries = this.storageHelper.getCountriesFromLocal();
    this.setState({ countries });
  }


  async setFieldErrors(name: string, state: boolean) {
    let errs = this.state.errors;
    errs[name] = state;

    let envVar = this.storageHelper.getEnvVar();
    if (name === 'password' && envVar !== 'Production') errs[name] = false;

    await this.setState({ errors: errs });
  }

  public getRawPhoneNumber() {
    return this.phoneRef!.current!.getRawPhoneInput();
  }

  render() {
    return (
      <div>
        <h1>{getLanguage(538, "Register as freelancer")}</h1>
        <OptimizedTextField
          id="outlined-country"
          select
          label={getLanguage(80, "Country")}
          value={this.props.formsData.selectedCountry || ''}
          onChange={(e) => this.props.changeField('selectedCountry', e.target.value)}
        >
          {this.props.possibleCountries.map(x => (<MenuItem value={x.Id} key={x.Id}>{x.LanguageCode ? getLanguage(x.LanguageCode, "Country name") : ""}</MenuItem>))}
        </OptimizedTextField>

        <OptimizedTextField
          label={getLanguage(71, "First name")}
          error={this.state.errors.firstNames}
          value={this.props.formsData.firstNames || ''}
          onBlur={(e) => this.setFieldErrors('firstNames', !isLength(e.target.value, { min: 1, max: 128 }))}
          onFocus={() => this.setFieldErrors('firstNames', false)}
          onChange={(e) => this.props.changeField('firstNames', e.target.value)}
        />

        <OptimizedTextField
          label={getLanguage(72, "Last name")}
          error={this.state.errors.lastName}
          onBlur={(e) => this.setFieldErrors('lastName', !isLength(e.target.value, { min: 1, max: 128 }))}
          onFocus={() => this.setFieldErrors('lastName', false)}
          value={this.props.formsData.lastName || ''}
          onChange={(e) => this.props.changeField('lastName', e.target.value)}
        />


        <div className="phoneInput">
          <label id="phoneInputLabel">{getLanguage(75, "Mobile number")}</label>
          <PhoneField
            ref={this.phoneRef}
            countryCode={getPhoneCountryCode(this.state.countries, this.props.formsData.selectedCountry, this.storageHelper)}
            value={this.props.formsData.phone}
            onChange={(val) => this.props.changeField('phone', val)}
            onFocus={(val) => this.props.changeField('phone', val)}
          />
        </div>

        <OptimizedTextField
          type="email"
          label={getLanguage(73, "Email")}
          error={this.state.errors.email}
          onBlur={(e) => this.setFieldErrors('email', !isEmail(e.target.value))}
          onFocus={() => this.setFieldErrors('email', false)}
          value={this.props.formsData.email || ''}
          onChange={(e) => this.props.changeField('email', e.target.value)}
        />
        <OptimizedTextField
          type='password'
          label={getLanguage(170, "Password")}
          error={this.state.errors.password}
          onBlur={(e) => this.setFieldErrors('password', !isLength(e.target.value, { min: 6, max: 32 }))}
          onFocus={() => this.setFieldErrors('password', false)}
          value={this.props.formsData.password || ''}
          onChange={(e) => this.props.changeField('password', e.target.value)}
        />
      </div>
    )
  }
}