import { AxiosRequestConfig } from 'axios';
import { CallManager } from './CallManager/CallManager';
import { CMSMetaModel } from '../Models/CMSMetaModel';

export class BlogHelper {


  async getOverviews() {
    try {
      let config: AxiosRequestConfig = {
        method: 'get',
        url: '/api/blog/overviewposts',
        withCredentials: true,
      }
      let response = await CallManager.Instance.makeCall(config, false, false);
      return response.data;
    } catch (error: any) {
      return null;
    }
  }

  async getRecentPosts() {
    try {
      let config: AxiosRequestConfig = {
        method: 'get',
        url: '/api/blog/recentposts',
        withCredentials: true,
      }
      let response = await CallManager.Instance.makeCall(config, false);
      let data: CMSMetaModel[] = response.data.map((x: CMSMetaModel) => new CMSMetaModel(x));
      return data
    } catch (error: any) {
      return [];
    }
  }
}