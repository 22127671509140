import { AxiosRequestConfig } from "axios";
import { StorageHelper } from "./StorageHelper";
import { LoginModel } from "../Models/LoginModel";
import { getCurrentUser } from "./SessionHelper";
import { FreelancerModel } from "../Models/FreelancerModel";
import { getLanguage } from "./LanguageHelper";
import { CallManager } from "./CallManager/CallManager";
import { FavoriteProfileModel } from "../Models/FavoriteProfileModel";
import { RequestModel } from "../Models/RequestModel";
import * as Sentry from "@sentry/react";

export class UserHelper {
  storageHelper = new StorageHelper();
  clientKey = "04216571796E48ABB8FD781DEA966A4F";

  async registerFreelancer(formData: any, captcha: string) {
    try {
      let config: AxiosRequestConfig = {
        method: "post",
        url: "/api/registerprofile?type=freelancer",
        headers: {
          "Content-Type": "application/json",
          clientKey: this.clientKey,
        },
        data: {
          data: new UserData(formData),
          captcha,
        },
      };
      let response = await CallManager.Instance.makeCall(config, false, false);
      return response.data;
    } catch (error: any) {
      console.log(JSON.parse(JSON.stringify(error)));

      if (error.response && error.response.status === 409) {
        return getLanguage(618, "Email address is already in use");
      } else if (error.response && error.response.status === 410) {
        return getLanguage(482, "Captcha not accepted");
      }

      if (typeof error.data === "string") {
        return error.data;
      }

      if (error.data && Array.isArray(error.data) && error.data.length > 0) {
        let errors = [];
        for (const err of error.data) {
          errors.push(err);
          console.log(err);
        }
        return errors[0];
      }

      if (!error.response) {
        return "Could not connect to the server";
      }
    }
  }

  async registerCompany(userData: CompanyData, captcha: string) {
    try {
      let data = new CompanyData(userData);
      data.ForceActivationViaEmail = true;

      let config: AxiosRequestConfig = {
        method: "post",
        url: "/api/registerprofile?type=company",
        headers: {
          "Content-Type": "application/json",
          clientKey: this.clientKey,
        },
        data: {
          data,
          captcha,
        },
      };
      let response = await CallManager.Instance.makeCall(config, false, false);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.status === 409) {
        return getLanguage(618, "Email address is already in use");
      } else if (error.response && error.response.status === 410) {
        return getLanguage(482, "Captcha not accepted");
      }

      if (typeof error.data === "string") {
        return error.data;
      }

      if (error.data && Array.isArray(error.data) && error.data.length > 0) {
        let errors = [];
        for (const err of error.data) {
          errors.push(err);
          console.log(err);
        }
        return errors[0];
      }

      if (!error.response || !Array.isArray(error.response)) {
        console.log(JSON.parse(JSON.stringify(error)));
        return "Could not connect to the server";
      }
    }
  }

  async registerPrivate(userData: any, captcha: string) {
    try {
      let data = new CompanyData(userData);
      data.IsPrivate = true;
      let config: AxiosRequestConfig = {
        method: "post",
        url: "/api/registerprofile?type=company",
        headers: {
          "Content-Type": "application/json",
          clientKey: this.clientKey,
        },
        data: {
          data,
          captcha,
        },
      };
      let response = await CallManager.Instance.makeCall(config, false, false);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.status === 409) {
        return getLanguage(618, "Email address is already in use");
      } else if (
        error.response &&
        error.response &&
        error.response.status === 410
      ) {
        return getLanguage(482, "Captcha not accepted");
      }

      if (error.data && Array.isArray(error.data) && error.data.length > 0) {
        let errors = [];
        for (const err of error.data) {
          errors.push(err);
          console.log(err);
        }
        return errors[0];
      }

      if (!error.response || !Array.isArray(error.response)) {
        console.log(JSON.parse(JSON.stringify(error)));
        return "Could not connect to the server";
      }
    }
  }

  async logChabberIn(
    userEmail?: string,
    userPassword?: string,
    token?: string
  ) {
    if (!token) {
      token = this.storageHelper.getAccessToken();
    }

    try {
      let config: AxiosRequestConfig = {
        method: "post",
        url: "/api/login/authenticate",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          Email: userEmail,
          Password: userPassword,
          Token: token,
        },
      };
      let response = await CallManager.Instance.makeCall(config, false, false);
      if (response.data) {
        let data: {
          token: string;
          userData: LoginModel;
          stepBasicComplete: boolean | string;
        } = response.data;
        return data;
      }
      return false;
    } catch (error: any) {
      console.log("error", JSON.parse(JSON.stringify(error)));
      return false;
    }
  }

  async verifyLoggedIn() {
    try {
      let config: AxiosRequestConfig = {
        method: "post",
        url: "/api/login/verifyloggedin",
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };
      let response = await CallManager.Instance.makeCall(config, false, false);
      if (response.data) return response.data;
      return false;
    } catch (error: any) {
      return false;
    }
  }

  async chabberForgotPassword(userEmail: string) {
    try {
      let config: AxiosRequestConfig = {
        method: "post",
        url: "/forgotpassword",
        headers: {
          "Content-Type": "application/json",
          clientKey: this.clientKey,
        },
        data: {
          Email: userEmail,
        },
      };
      let response = await CallManager.Instance.makeCall(config, true, false);
      if (response.data) return response.data;
      return false;
    } catch (error: any) {
      console.log("error", error.response);
      return false;
    }
  }

  async resetForgotPassword(id: string, token: string, newPassword: string) {
    // throw new Error("Method not implemented.");
    console.log("id", id, "newPassword", newPassword, "token", token);
    try {
      let config: AxiosRequestConfig = {
        method: "post",
        url: "/resetpassword",
        headers: {
          "Content-Type": "application/json",
          clientKey: this.clientKey,
        },
        data: {
          Id: id,
          Token: token,
          NewPassword: newPassword,
        },
      };
      let response = await CallManager.Instance.makeCall(config, true, false);
      if (response.statusText === "OK") return true;
      return false;
    } catch (error: any) {
      console.log("error", error.response);
      return false;
    }
  }

  async updatePrimaryLanguage(languageId: string) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: "PUT",
        url: "/Language/" + languageId,
        headers: {
          "Content-Type": "application/json",
          clientKey: this.clientKey,
          authToken,
        },
      };
      let response = await CallManager.Instance.makeCall(config, true, false);

      return response.data;
    } catch (error: any) {
      console.log("error", error?.response);
      return false;
    }
  }

  async updateEmail(email: string) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: "POST",
        url: "/api/login/updateemail",
        headers: {
          "Content-Type": "application/json",
          clientKey: this.clientKey,
          authToken,
        },
        data: {
          email: email,
        },
      };
      let response = await CallManager.Instance.makeCall(config, false, false);

      return response;
    } catch (error: any) {
      console.log("error", error);
      return error;
    }
  }

  async activateWithCode(code: string) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      if (!authToken) {
        return { response: "not logged in", result: false };
      }

      let config: AxiosRequestConfig = {
        method: "GET",
        url: "/activationcode/" + code,
        headers: {
          "Content-Type": "application/json",
          clientKey: this.clientKey,
          authToken,
        },
      };
      let response = await CallManager.Instance.makeCall(config, true, false);
      if (response.data === "OK") {
        return { response: "", result: true };
      }
      return { response: "", result: false };
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        return { response: error.response.data, result: false };
      } else {
        return { response: "Unknown error", result: false };
      }
    }
  }

  async getFreelancerByLoginId(loginId: string) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: "GET",
        url: "/freelancer/getbyloginid/" + loginId,
        headers: {
          "Content-Type": "application/json",
          clientKey: this.clientKey,
          authToken,
        },
      };
      let response = await CallManager.Instance.makeCall(config, true);

      return response.data;
    } catch (error: any) {
      console.log("error", error.response);
      return false;
    }
  }

  async sendActivationMail() {
    try {
      let authToken = this.storageHelper.getAccessToken();
      if (authToken === null || authToken === "") {
        return { response: "not logged in", result: false };
      }

      let config: AxiosRequestConfig = {
        method: "GET",
        url: "/api/login/sendactivationmail",
        headers: {
          "Content-Type": "application/json",
          clientKey: this.clientKey,
          authToken,
        },
      };

      let response = await CallManager.Instance.makeCall(config, false, false);
      return { response: response.data, result: true };
    } catch (error: any) {
      console.log(error);
      if (error.response) {
        return { response: error.response.data, result: false };
      } else {
        return {
          response: "Unknown error with activation email",
          result: false,
        };
      }
    }
  }

  async activateWithEmail(id: string, code: string) {
    try {
      let config: AxiosRequestConfig = {
        method: "POST",
        url: "/api/login/activatebyemail",
        headers: {
          "Content-Type": "application/json",
          clientKey: this.clientKey,
        },
        data: {
          id,
          code,
        },
      };
      let response = await CallManager.Instance.makeCall(config, false, false);
      if (typeof response.data != "boolean") {
        return false;
      } else if (response.data === true) return true;
      return false;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  async changeUserPassword(oldPassword: string, password: string) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: "PUT",
        url: "/updatepassword",
        headers: {
          "Content-Type": "application/json",
          clientKey: this.clientKey,
          authToken,
        },
        data: {
          OldPassword: oldPassword,
          NewPassword: password,
        },
      };
      let response = await CallManager.Instance.makeCall(config, true, false);
      return response.data;
    } catch (error: any) {
      console.log("error", error.response);
      return false;
    }
  }

  async updateFreelancer(login: LoginModel) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: "PUT",
        url: "/freelancerprofile",
        headers: {
          "Content-Type": "application/json",
          clientKey: this.clientKey,
          authToken,
        },
        data: login.Freelancer,
      };

      let response = await CallManager.Instance.makeCall(config, true, false);
      return response.data;
    } catch (error: any) {
      console.log("error", error.response);
      return false;
    }
  }

  async verifyStepBasicComplete(login: LoginModel) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: "POST",
        url: "/api/verifystepbasiccomplete",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          authToken,
        },
        data: login,
      };
      let response = await CallManager.Instance.makeCall(config, false, false);
      return response.data;
    } catch (error: any) {
      return false;
    }
  }

  async verifyStepResumeComplete(login: LoginModel) {
    try {
      let config: AxiosRequestConfig = {
        method: "POST",
        url: "/api/verifystepresumecomplete",
        headers: {
          "Content-Type": "application/json",
          clientKey: this.clientKey,
        },
        data: login,
      };

      let response = await CallManager.Instance.makeCall(config, false, false);
      return response.data;
    } catch (error: any) {
      console.log("error", error.response);
      return false;
    }
  }

  async verifyStepBankComplete(freelancer: FreelancerModel) {
    try {
      let config: AxiosRequestConfig = {
        method: "POST",
        url: "/api/verifystepbankcomplete",
        headers: {
          "Content-Type": "application/json",
          clientKey: this.clientKey,
        },
        data: freelancer,
      };
      let response = await CallManager.Instance.makeCall(config, false, false);
      return response.data;
    } catch (error: any) {
      console.log("error", error.response);
      return false;
    }
  }

  async getFreelancerSkills() {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: "GET",
        url: "/api/skill",
        headers: {
          "Content-Type": "application/json",
          clientKey: this.clientKey,
          authToken,
        },
      };
      let response = await CallManager.Instance.makeCriticalCall(config, false);

      return response.data;
    } catch (error: any) {
      console.log("error", error.response);
      return false;
    }
  }

  async getExperienceCategories() {
    try {
      let config: AxiosRequestConfig = {
        method: "GET",
        url: "/api/experiencecategory",
        headers: {
          "Content-Type": "application/json",
          clientKey: this.clientKey,
        },
      };

      let response = await CallManager.Instance.makeCriticalCall(config, false);

      return response.data;
    } catch (error: any) {
      console.log("error", error.response);
      return false;
    }
  }

  async logOut() {
    try {
      let config: AxiosRequestConfig = {
        method: "GET",
        url: "/api/login/logout",
        withCredentials: true,
      };

      let response = await CallManager.Instance.makeCall(config, false, false);
      return response.data;
    } catch (error: any) {
      console.log("error", error.response);
      return false;
    }
  }

  async getCompetenceQuestions() {
    try {
      let config: AxiosRequestConfig = {
        method: "GET",
        url: "/api/competencequestions",
        withCredentials: true,
      };

      let response = await CallManager.Instance.makeCriticalCall(config, false);
      return response.data;
    } catch (error: any) {
      console.log("error", error.response);
      return false;
    }
  }

  // async getProfileActivity(){
  //   try {
  // //     let userEmail = await sessionStorage.getItem('userEmail');
  // //     let userPass = await sessionStorage.getItem('userPass');

  //     let config: AxiosRequestConfig = {
  //       method: 'GET',
  //       url: '/api/profileactivity',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         clientKey: this.clientKey,
  //         Username: userEmail,
  //         Password: userPass
  //       },
  //       data: {}
  //     }

  //     let response = await CallManager.Instance.makeCall(config);
  //     return response.data;
  //   } catch (error: any) {
  //     console.log('error', error.response);
  //     return false;
  //   }
  // }

  async deleteUser(email: string, password: string) {
    try {
      let user = getCurrentUser();
      if (!user) return false;

      let config: AxiosRequestConfig = {
        method: "POST",
        url: "/api/login/deleteself/",
        data: {
          email,
          password,
        },
      };
      let response = await CallManager.Instance.makeCall(config, false, false);
      return typeof response.data === "boolean" ? response.data : false;
    } catch (error: any) {
      return false;
    }
  }

  async saveNotificationSettings(settings: {
    DisableNotificationsUntil: Date | null;
    SubscribeToUrgentPostingsMails: boolean;
    SubscribeToNewsletters: boolean;
  }) {
    try {
      let config: AxiosRequestConfig = {
        method: "POST",
        url: "/api/login/savenotifications/",
        data: {
          settings,
        },
      };

      let response = await CallManager.Instance.makeCall(config, false, false);
      return typeof response.data === "boolean" ? response.data : false;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  async unsubViaMail(
    id: string,
    settings: { DisableNotificationsUntil: Date }
  ) {
    try {
      let config: AxiosRequestConfig = {
        method: "POST",
        url: "/api/login/unsubviamail/" + id,
        data: {
          settings,
        },
      };

      let response = await CallManager.Instance.makeCall(config, false, false);
      return typeof response.data === "boolean" ? response.data : false;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  async updateUserProfilePicture(image: string) {
    try {
      let user = getCurrentUser();
      if (!user) return false;

      let config: AxiosRequestConfig = {
        method: "POST",
        url: "/api/picture/profilepictureasimage",
        data: { image },
        withCredentials: true,
      };

      let response = await CallManager.Instance.makeCall(config, false);
      if (response) return response.data;
      return false;
    } catch (error: any) {
      return false;
    }
  }

  async attemptLogin(email?: string, password?: string, token?: string) {
    let response: {
      result: boolean;
      token: string;
      userData: LoginModel | undefined;
    } = { result: false, token: "", userData: undefined };

    let result = await this.logChabberIn(email, password, token);
    if (result && result.token && result.userData) {
      if (result.userData.Freelancer || result.userData.Company) {
        response.userData = new LoginModel(result.userData);
        response.token = result.token;
        response.result = true;
      }
    } else {
      response.result = false;
    }
    return response;
  }

  async getFavorites() {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: "GET",
        url: "/api/favouriteprofile",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          authToken,
        },
      };
      let response = await CallManager.Instance.makeCall(config, false);

      if (response) return response.data as FavoriteProfileModel[];

      return [];
    } catch (error: any) {
      console.log(error);
      return [];
    }
  }

  async acceptOrRejectFavorite(id: string, status: number) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: "POST",
        url: "/api/favouriteprofile/" + id,
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          authToken,
        },
        data: { ApprovalStatus: status },
      };
      let response = await CallManager.Instance.makeCall(config, false, false);

      if (response) return response.data;

      return false;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  async getUsersRequests() {
    try {
      let config: AxiosRequestConfig = {
        method: "GET",
        url: "/request",
        headers: {
          "Content-Type": "application/json",
          clientKey: this.clientKey,
        },
      };
      let response = await CallManager.Instance.makeCall(config, true);

      if (response)
        return (
          response.data.map((x: RequestModel) => new RequestModel(x)) || []
        );

      return [];
    } catch (error: any) {
      console.log(error);
      return [];
    }
  }

  async getQuarentine() {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: "GET",
        url: "/quarantine",
        headers: {
          "Content-Type": "application/json",
          clientKey: this.clientKey,
          authToken,
        },
      };
      let response = await CallManager.Instance.makeCall(config, true, false);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  async getVerificationDocumentStatus(
    countryId: string,
    loginId: string,
    freelancerId: string
  ) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: "GET",
        url:
          "/api/verificationdocumentstatus?countryid=" +
          countryId +
          "&loginid=" +
          loginId +
          "&freelancerid=" +
          freelancerId,
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          authToken,
        },
      };

      let response = await CallManager.Instance.makeCall(config, false);
      if (response.status != 200) {
        return false;
      }

      return response.data;
    } catch (error: any) {
      Sentry.captureException(error);
      return false;
    }
  }

  async uploadVerificationDocument(image: string, type: number) {
    try {
      let user = getCurrentUser();
      if (!user) return false;

      let config: AxiosRequestConfig = {
        method: "POST",
        url: "/api/verificationdocument",
        data: {
          image: image,
          type: type,
        },
        withCredentials: true,
      };

      let response = await CallManager.Instance.makeCall(config, false, false);
      if (response) return response.data;
      return false;
    } catch (error: any) {
      return false;
    }
  }

  async confirmFirstJob() {
    try {
      let config: AxiosRequestConfig = {
        method: "POST",
        url: "/api/freelancer/confirmjob",
      };
      let response = await CallManager.Instance.makeCall(config, false);
      if (response.data) {
        return true;
      }
      return false;
    } catch (error: any) {
      return false;
    }
  }

  async stopAccountDelete(id: string) {
    try {
      let config: AxiosRequestConfig = {
        method: "POST",
        url: "/api/freelancer/stopaccountdelete/" + id,
      };
      let response = await CallManager.Instance.makeCall(config, false);
      if (response.data) {
        return true;
      }
      return false;
    } catch (error: any) {
      return false;
    }
  }
}

export class UserData {
  Email: string;
  Firstnames: string;
  Lastname: string;
  Password: string;
  PhoneNumber: string;
  CountryId: string;
  HeardAbout?: Readonly<"Created by web">;
  PrimaryLanguage: string;

  constructor(val: Partial<UserData> | any) {
    this.Email = val.Email || val.email || "";
    this.Firstnames = val.Firstnames || val.firstNames || "";
    this.Lastname = val.Lastname || val.lastName || "";
    this.Password = val.Password || val.password || "";
    this.PhoneNumber = val.PhoneNumber || val.phone || "0";
    this.CountryId = val.CountryId || val.selectedCountry || "";
    this.HeardAbout = "Created by web";
    this.PrimaryLanguage = val.PrimaryLanguage || "";
  }
}

export class CompanyData {
  Email: string;
  Password: string;
  Companyname: string;
  TypeOfBusiness: string;
  CountryId: string;
  IsPrivate: boolean;
  HeardAbout: "Created by web";
  Firstnames: string;
  Lastname: string;
  PhoneNumber: string;
  ForceActivationViaEmail: boolean = false;
  PrimaryLanguage: string;

  constructor(val: Partial<CompanyData> | any) {
    this.Email = val.Email || val.email || "";
    this.Password = val.Password || val.password || "";
    this.Companyname = val.Companyname || val.companyName || "";
    this.TypeOfBusiness = val.TypeOfBusiness || val.selectedBusinessType || "";
    this.CountryId = val.CountryId || val.selectedCountry || "";
    this.IsPrivate = false;
    this.HeardAbout = "Created by web";
    this.Firstnames = val.Firstnames || val.firstNames || "";
    this.Lastname = val.Lastname || val.lastName || "";
    this.PhoneNumber = val.PhoneNumber || val.phone || "";
    this.PrimaryLanguage = val.PrimaryLanguage || "";
  }
}

export function showFullLastNameOfChabber(
  freelancerProfile: FreelancerModel,
  showFullLastName: boolean
) {
  if (freelancerProfile && freelancerProfile.Login) {
    let name = freelancerProfile.Login.Firstnames.split(" ")[0];
    name += " ";

    if (showFullLastName) {
      name += freelancerProfile.Login.Lastname;
    } else {
      name += freelancerProfile.Login.Lastname.substring(0, 1);
    }

    return name;
  }
}
