import { RequestModel } from "./RequestModel";

export class OvertimeRequestModel {
  Id: string;
  RequestId: string;
  Request: RequestModel | null;
  AddMinutes: number;
  Created: string;
  CreatedByFreelancer: boolean | null;
  Executed: boolean | null;
  SalaryPerHour: number;


  constructor(val: Partial<OvertimeRequestModel>) {
    this.Id = val.Id || '';
    this.RequestId = val.RequestId || ''
    this.Request = val.Request || null;
    this.AddMinutes = val.AddMinutes || 0;
    this.Created = val.Created || '';
    this.CreatedByFreelancer = val.CreatedByFreelancer || null;
    this.Executed = val.Executed || null;
    this.SalaryPerHour = val.SalaryPerHour || 0;
  }
}