import { AxiosRequestConfig } from "axios";
import { CallManager } from "./CallManager/CallManager";
import { CoursePartnerModel } from "../Models/CoursePartnerModel";


export class CourseOrganizerHelper {

  async getOrganizers(isAdmin: boolean, countryId?: string): Promise<CoursePartnerModel[]> {
    try {
      let config: AxiosRequestConfig = {
        method: 'get',
        url: '/api/coursepartners',
        withCredentials: true,
        params: {
          countryId
        }
      }

      let response = await CallManager.Instance.makeCall(config, false, isAdmin);
      return response.data.map((x: CoursePartnerModel) => new CoursePartnerModel(x));
    } catch (error: any) {
      console.log(error);
      return [];
    }
  }

  async getOrganizerById(id: string | undefined) {
    try {
      id = typeof id === 'string' ? '?id=' + id : '';

      let config: AxiosRequestConfig = {
        method: 'get',
        url: '/api/coursepartner/byid' + id,
        withCredentials: true
      }
      let response = await CallManager.Instance.makeCall(config, false, true);
      return new CoursePartnerModel(response.data);
    } catch (error: any) {
      console.log(error);
      return null;
    }
  }

  async saveOrganizer(coursePartner: CoursePartnerModel) {
    try {
      let config: AxiosRequestConfig = {
        method: 'post',
        url: '/api/coursepartner',
        withCredentials: true,
        data: {
          coursePartner
        }
      }
      let response = await CallManager.Instance.makeCall(config, false, true);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  async updateOrganizer(coursePartner: CoursePartnerModel) {
    try {
      let config: AxiosRequestConfig = {
        method: 'put',
        url: '/api/coursepartner',
        withCredentials: true,
        data: {
          coursePartner
        }
      }

      let response = await CallManager.Instance.makeCall(config, false, true);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  async getCoursePartnerByCmsMetaId(id: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'get',
        url: '/api/coursepartner/bycmsmetaid',
        params: { id },
        withCredentials: true
      }

      let response = await CallManager.Instance.makeCall(config, false);
      if (response.data) {
        return new CoursePartnerModel(response.data);
      }
      return null;
    } catch (error: any) {
      console.log(error);
      return null;
    }
  }

}