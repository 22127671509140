import React, { Component } from "react";
import { RouteComponentProps } from 'react-router-dom';
import {
    Menu, MenuItem, Divider,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, CircularProgress, TextField
} from "@material-ui/core";

interface IProps extends RouteComponentProps {
    visible: boolean
    contents: string
    close: () => void
}

export function CommunicationMailPreview(props: IProps) {

    return (
        <Dialog
            className="dialog"
            maxWidth="sm"
            fullWidth
            open={props.visible}>
            <DialogTitle id="form-dialog-title">
                Preview e-mail
            </DialogTitle>
            <DialogContent>
                <div className="messageBody" dangerouslySetInnerHTML={{ __html: props.contents }}></div>
            </DialogContent>
            <DialogActions className="dialogActions">
                <Button
                    onClick={props.close}>
                    Close
                </Button>
            </DialogActions>
        </Dialog >
    )
}