import React, { Component } from "react";
import { TextField, Grid, Button, CircularProgress } from "@material-ui/core";
import "./Checkout.scss";
import { PostingModel } from "../../../../Models/PostingModel";
import { getCurrentUser } from "../../../../Helpers/SessionHelper";
import { CountryHelper } from "../../../../Helpers/CountryHelper";
import { CountryModel } from "../../../../Models/CountryModel";
import { CompanyHelper } from "../../../../Helpers/CompanyHelper";
import { PostingHelper } from "../../../../Helpers/PostingHelper";
import { RequestModel } from "../../../../Models/RequestModel";
import { ChatHelper } from "../../../../Helpers/ChatHelper";
import { LoginModel } from "../../../../Models/LoginModel";
import { SnackbarManager } from "../../../../Helpers/SnackbarManager/SnackbarManager";
import { getLanguage } from "../../../../Helpers/LanguageHelper";
import {
  getPriceForProductAsDecimal,
  getMoneyOutput
} from "../../../../Helpers/LocalizationHelper";

interface IProps {
  requestsToHire: {
    Request: RequestModel
  }[];
  activePosting: PostingModel;
  cancelCheckout: () => void;
  requestIds: string[];
  confirmed: (hiringChabbers: boolean) => void;
  loginIdsToConfirm: string[]
}

interface IState {
  hours: number;
  salaryPerHourWithHolidayFee: number;
  chabbersSalaryAndHolidayPay: number;
  hourlyFee: number;
  fullOneTimeFee: number;
  fullUrgentFee: number;
  amountOfUrgentFeeDays: number;
  UrgentJobPerDayFeeRate: number;
  cancelInsuracePerChabber: number;
  cancelInsurancePrice: number;
  fullPrice: number;
  fullFeePerChabber: number;
  buttonsDisabled: boolean;
  voucher: string;
  amountOfChabbersToHire: number;
  amountSaved: number;
  voucherValid: boolean;
  voucherWarning: string;
  user: LoginModel | null;
  country: CountryModel | null;
  fullTax: number;
  fullPriceInclTax: number;
}

export class Checkout extends Component<IProps, IState> {
  countryHelper = new CountryHelper();
  companyHelper = new CompanyHelper();
  postingHelper = new PostingHelper();

  constructor(props: IProps) {
    super(props);

    this.state = {
      hours: 0,
      salaryPerHourWithHolidayFee: 0,
      chabbersSalaryAndHolidayPay: 0,
      hourlyFee: 0,
      fullOneTimeFee: 0,
      fullUrgentFee: 0,
      amountOfUrgentFeeDays: 0,
      UrgentJobPerDayFeeRate: 0,
      cancelInsuracePerChabber: 0,
      cancelInsurancePrice: 0,
      fullPrice: 0,
      fullFeePerChabber: 0,
      buttonsDisabled: true,
      voucher: '',
      amountOfChabbersToHire: 0,
      amountSaved: 0,
      voucherValid: false,
      voucherWarning: '',
      user: null,
      country: null,
      fullTax: 0,
      fullPriceInclTax: 0
    }
    this.cancelInsurance = this.cancelInsurance.bind(this)
    this.urgentFees = this.urgentFees.bind(this)
    this.confirmRequests = this.confirmRequests.bind(this)
    this.setAmountOfChabbersToHire = this.setAmountOfChabbersToHire.bind(this)
    this.getFreeChabbersAvailable = this.getFreeChabbersAvailable.bind(this)
    this.checkVoucher = this.checkVoucher.bind(this)
  }

  async componentDidMount() {
    let user = getCurrentUser();
    this.setState({ user })
    await this.setAmountOfChabbersToHire(null);
    await this.getFreeChabbersAvailable();
    await this.calculatePrice(null);
    await this.setState({ buttonsDisabled: false })
  }

  async calculatePrice(amount: number | null) {
    let user = this.state.user;
    let posting = this.props.activePosting;
    let requests: RequestModel[] = [];
    for (const i of this.props.requestsToHire) {
      let request = new RequestModel(i.Request)
      requests.push(request)
    }


    let loopTimes = amount ? amount : requests.length;

    let fullChabbersSalaryAndHolidayPay = 0;
    let fullCancelInsurancePrice = 0;
    let fullFeePerChabber = 0;
    let fullUrgentFee = 0;
    let fullOneTimeFee = 0;
    let fullPrice = 0;
    let amountOfUrgentFeeDays = 0;

    let hourlyFee = requests[0].HourlyFeeRate / 100;

    let salaryPerHourWithHolidayFee = 0;

    for (let i = 0; i < loopTimes; i++) {
      let hours: number = requests[i].JobLengthInMinutes / 60;
      let SalaryPerHour: number = requests[i].SalaryPerHour / 100;

      let salary = requests[i].FullSalary / 100;

      // Salary supplement
      if (requests[i].SalarySupplementPercentage && requests[i].SalarySupplementPercentage !== 0) {
        const salarySupplementPercentage = requests[i].SalarySupplementPercentage / 100.00
        const salarySupplementPerHour = SalaryPerHour * (salarySupplementPercentage / 100.00);
        const salarySupplementTotal = salary * (salarySupplementPercentage / 100.00);
        SalaryPerHour += salarySupplementPerHour;
        salary += salarySupplementTotal;
      }

      let country: CountryModel = new CountryModel({});
      if (user && user.Company) {
        country = await this.countryHelper.getCountry(user.Company.CountryId)
        this.setState({ country })
      }

      const holidayAllowancePercent = country.HolidayPayPercent / 100.00
      const holidayAllowance = salary * (holidayAllowancePercent / 100.00);
      const hourlyHolidayAllowance = (holidayAllowance / requests[i].JobLengthInMinutes) * 60;

      let pensionPercent = 0;
      if (requests[i]?.FreelancerProfile?.PensionEnabled && user?.Company?.CollectiveAgreement === 1) {
        pensionPercent = country.PensionPercent / 100.00
      } 
      const pensionPay = salary * (pensionPercent / 100.00);
      const hourlyPensionPay = (pensionPay / requests[i].JobLengthInMinutes) * 60;
      const oneTimeFeeBeforeConversion = requests[i].OneTimeFeeRate / 100;

      let urgentFee = (Math.floor(oneTimeFeeBeforeConversion / 50) * 50)

      const oneTimeFee = oneTimeFeeBeforeConversion - urgentFee;

      urgentFee = oneTimeFeeBeforeConversion - oneTimeFee;

      amountOfUrgentFeeDays = (urgentFee) / (posting.UrgentJobPerDayFeeRate / 100);

      salaryPerHourWithHolidayFee += SalaryPerHour + hourlyHolidayAllowance + hourlyPensionPay;

      let cancelInsurancePerChabber = getPriceForProductAsDecimal("Cancel_Insurance");

      fullChabbersSalaryAndHolidayPay += (salary + holidayAllowance + pensionPay);

      fullCancelInsurancePrice += requests[i].CancelInsuranceSelected ? cancelInsurancePerChabber : 0;
      fullFeePerChabber += hours * hourlyFee;
      fullUrgentFee += urgentFee;
      fullOneTimeFee += oneTimeFee;

      if (requests[i].CancelInsuranceSelected) {
        await this.setState({ cancelInsuracePerChabber: cancelInsurancePerChabber, cancelInsurancePrice: fullCancelInsurancePrice })
      }

      if (!amount) {
        await this.setState({
          hours
        })
      }
    }

    if (!amount) {
      fullPrice = +((fullChabbersSalaryAndHolidayPay + fullFeePerChabber + fullUrgentFee + fullCancelInsurancePrice + fullOneTimeFee).toFixed(2));
      let tax = 0
      let fullPriceInclTax = 0
      if (this.state.user && this.state.country) {
        tax = (fullPrice * (this.state.country.TaxPercent / 100)) / 100
        fullPriceInclTax = fullPrice + tax;
      }

      return await this.setState({
        salaryPerHourWithHolidayFee: salaryPerHourWithHolidayFee/loopTimes,
        UrgentJobPerDayFeeRate: (posting.UrgentJobPerDayFeeRate / 100),
        amountOfUrgentFeeDays,
        hourlyFee,
        chabbersSalaryAndHolidayPay: fullChabbersSalaryAndHolidayPay,
        fullOneTimeFee,
        fullUrgentFee,
        fullPrice,
        fullFeePerChabber,
        fullTax: tax,
        fullPriceInclTax
      })

    } else {
      let amountSaved = (fullUrgentFee + fullFeePerChabber + fullOneTimeFee) / (amount !== 0 ? amount : 1)
      return await this.setState({ amountSaved: amountSaved })
    }
  }
  async setAmountOfChabbersToHire(amount: number | null) {
    if (!amount) {
      await this.setState({ amountOfChabbersToHire: this.props.requestsToHire.length })
    } else {
      await this.setState({ amountOfChabbersToHire: amount })
    }
  }

  urgentFees() {
    if (this.state.amountOfUrgentFeeDays && this.state.amountOfUrgentFeeDays !== 0) {
      return (
        <tr>
          <td align="left">{getLanguage(724, 'Urgent fee')}</td>
          <td>{this.state.amountOfChabbersToHire}</td>
          <td>{getMoneyOutput((this.state.UrgentJobPerDayFeeRate * this.state.amountOfUrgentFeeDays).toFixed(2), false, this.props.activePosting ? this.props.activePosting.Currency : null, true)}</td>
          <td>{getMoneyOutput(this.state.fullUrgentFee.toFixed(2), false, this.props.activePosting ? this.props.activePosting.Currency : null, true)}</td>
        </tr>
      )
    } return;
  }

  cancelInsurance() {
    if (this.state.cancelInsuracePerChabber !== 0) {
      return (
        <tr>
          <td align="left">{getLanguage(725, 'Cancel insurance')}</td>
          <td>{this.props.requestsToHire.length}</td>
          <td>{getMoneyOutput(this.state.cancelInsuracePerChabber.toFixed(2), false, this.props.activePosting ? this.props.activePosting.Currency : null, true)}</td>
          <td>{getMoneyOutput(this.state.cancelInsurancePrice.toFixed(2), false, this.props.activePosting ? this.props.activePosting.Currency : null, true)}</td>
        </tr>
      )
    } return;
  }

  startFee() {

    if (this.state.amountOfChabbersToHire !== 0 && this.state.fullOneTimeFee) {
      return (
        <tr>
          <td align="left">{getLanguage(726, 'Start fee')}</td>
          <td>{this.state.amountOfChabbersToHire}</td>
          <td>{getMoneyOutput((this.state.fullOneTimeFee / this.state.amountOfChabbersToHire).toFixed(2), false, this.props.activePosting ? this.props.activePosting.Currency : null, true)}</td>
          <td>{getMoneyOutput(this.state.fullOneTimeFee.toFixed(2), false, this.props.activePosting ? this.props.activePosting.Currency : null, true)}</td>
        </tr>

      )
    } return;
  }

  async confirmRequests() {
    await this.setState({ buttonsDisabled: true })
    let success = await this.companyHelper.hireChabbers(this.props.requestIds);

    if (success === 403) {
      await this.setState({ buttonsDisabled: false })
      SnackbarManager.Instance.addWarning(getLanguage(711, 'must have creditcard to hire chabbers'));
      this.props.cancelCheckout()
    } else if (success === 402) {
      await this.setState({ buttonsDisabled: false })
      SnackbarManager.Instance.addWarning(getLanguage(712, 'problem with registered creditcard '));
      this.props.cancelCheckout()
    } else if (success === false) {
      await this.setState({ buttonsDisabled: false })
      SnackbarManager.Instance.addWarning(getLanguage(469, 'unknown error'));
      this.props.cancelCheckout()
    } else {
      if (this.props.activePosting.Id && this.props.loginIdsToConfirm) {
        new ChatHelper().chatMessageNetworkMembers(this.props.activePosting.Id, this.props.loginIdsToConfirm)
      }
      this.props.confirmed(true);
    }
  }

  async checkVoucher() {
    await this.setState({ buttonsDisabled: true, voucherWarning: '' })

    let voucher = this.state.voucher;
    if (voucher) {
      let voucherValid = await this.postingHelper.voucherValidation(voucher);
      if (voucherValid) {
        await this.getFreeChabbersAvailable();
      } else await this.setState({ buttonsDisabled: false, voucherWarning: 'Not a valid voucher', voucher: '' })
    } else await this.setState({ buttonsDisabled: false })
  }

  async getFreeChabbersAvailable() {
    let chabbersAvailable = await this.postingHelper.freeChabbersAvailable();

    if (chabbersAvailable) {
      await this.calculatePrice(chabbersAvailable)
      await this.setState({ buttonsDisabled: false, voucherValid: true })
    }
  }

  voucher() {
    if (this.state.voucherValid && this.state.amountSaved) {
      return (
        <tr className="tr_voucher">
          <td align="left">{getLanguage(727, 'Voucher discount')}</td>
          <td />
          <td />
          <td>- {this.state.amountSaved}</td>
        </tr>

      )

    } return;
  }

  render() {
    let total = this.state.fullPrice
    if (!isNaN(this.state.amountSaved)) {
      total -= this.state.amountSaved
    }

    return (
      <div className="checkoutView">
        <div className="dialogHeader padding-24">
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <h2 className="no-margin">
                {getLanguage(728, 'Hire {{count}} selected chabber(s)').replace("{{count}}", this.props.requestsToHire.length.toString())}
              </h2>
            </Grid>
            <Grid item>
              <Button onClick={this.props.cancelCheckout} disabled={this.state.buttonsDisabled}>
                {getLanguage(242, 'Cancel')}
                {this.state.buttonsDisabled && <CircularProgress size={20} className="buttonLoader" />}
              </Button>
            </Grid>

          </Grid>
        </div>
        <div className="padding-40">
          <table>
            <thead>
              <tr>
                <td align="left">{getLanguage(70, 'Description')}</td>
                <td>{getLanguage(729, 'Amount')}</td>
                <td>{getLanguage(730, 'Unit price')}</td>
                <td>{getLanguage(731, 'Total')}</td>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td align="left">{this.props.requestsToHire.length} {getLanguage(732, 'Salary incl. holiday pay')}</td>
                <td>{this.state.hours * this.props.requestsToHire.length} {getLanguage(733, 'hours')}</td>
                <td>{getMoneyOutput(this.state.salaryPerHourWithHolidayFee.toFixed(2), false, this.props.activePosting ? this.props.activePosting.Currency : null, true)}</td>
                <td>{getMoneyOutput(this.state.chabbersSalaryAndHolidayPay.toFixed(2), false, this.props.activePosting ? this.props.activePosting.Currency : null, true)}</td>
              </tr>
              <tr>
                <td align="left">{getLanguage(734, 'Hourly fee')} </td>
                <td>{this.props.requestsToHire.length * this.state.hours} {getLanguage(733, 'hours')}</td>
                <td>{getMoneyOutput(this.state.hourlyFee.toFixed(2), false, this.props.activePosting ? this.props.activePosting.Currency : null, true)}</td>
                <td>{getMoneyOutput(this.state.fullFeePerChabber.toFixed(2), false, this.props.activePosting ? this.props.activePosting.Currency : null, true)}</td>
              </tr>
              {this.startFee()}
              {this.urgentFees()}
              {this.cancelInsurance()}
            </tbody>


            {this.voucher()}
            <tbody className="checkoutMiddle">
              <tr>
                <td align="left">{getLanguage(735, 'Total ex. VAT')}</td>
                <td></td>
                <td></td>
                <td>{getMoneyOutput(total.toFixed(2), false, this.props.activePosting ? this.props.activePosting.Currency : null, true)}</td>
              </tr>
            </tbody>
            <tbody className="checkoutMiddle">
              <tr>
                <td align="left">{getLanguage(854, 'Tax')}</td>
                <td></td>
                <td></td>
                <td>{getMoneyOutput(this.state.fullTax.toFixed(2), false, this.props.activePosting ? this.props.activePosting.Currency : null, true)}</td>
              </tr>
            </tbody>
            <tbody className="checkoutTotal">
              <tr>
                <td align="left">{getLanguage(855, 'Total incl. VAT')}</td>
                <td></td>
                <td></td>
                <td>{getMoneyOutput(this.state.fullPriceInclTax.toFixed(2), false, this.props.activePosting ? this.props.activePosting.Currency : null, true)}</td>
              </tr>
            </tbody>
          </table>
          <div className="margin-top-24">
            <Grid
              container
              spacing={8}
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <div className="inputGroup">
                  <TextField
                    className="inputGroup_item"
                    helperText={this.state.voucherWarning}
                    value={this.state.voucher}
                    onChange={(event) => this.setState({ voucher: event.target.value })}
                    type="text"
                    variant="outlined"
                    margin="dense"
                    style={{ margin: 0 }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    placeholder={getLanguage(738, 'Voucher')}
                  />


                  <Button disabled={this.state.buttonsDisabled} className="inputGroup_item" variant="text" size="medium" onClick={this.checkVoucher}>
                    <span className="btn-icon">
                      <i className="fas fa-ticket-alt" />
                    </span>
                    {getLanguage(736, 'hours')}
                    {this.state.buttonsDisabled && <CircularProgress size={20} className="buttonLoader" />}
                  </Button>
                </div>
              </Grid>
              <Grid item>
                <Button disabled={this.state.buttonsDisabled} className="success" variant="text" size="large" onClick={this.confirmRequests}>
                  <span className="btn-icon">
                    <i className="fas fa-check" />
                  </span>
                  {getLanguage(737, 'Confirm')}
                  {this.state.buttonsDisabled && <CircularProgress size={20} className="buttonLoader" />}
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}
