import { FreelancerModel } from "./FreelancerModel";
import { CourseModel } from "./CourseModel";
import { SalaryDeductionModel } from "./SalaryDeductionModel";
import { CourseAttendanceModel } from "./CourseAttendanceModel";

export class CourseRegistrationModel {
  Id: string;
  FreelancerProfileId: string;
  Freelancer: FreelancerModel | null;
  CourseId: string;
  Course: CourseModel | null;
  Created: string;
  WorkingMinutesPaidOff: number;
  SalaryDeductions: SalaryDeductionModel[];
  CourseAttendances: CourseAttendanceModel[];
  Graduated: boolean;

  constructor(val: Partial<CourseRegistrationModel>) {
    this.Id = val.Id || '';
    this.FreelancerProfileId = val.FreelancerProfileId || '';
    this.Freelancer = val.Freelancer || null;
    this.CourseId = val.CourseId || '';
    this.Course = val.Course || null;
    this.Created = val.Created || '';
    this.WorkingMinutesPaidOff = val.WorkingMinutesPaidOff || 0;
    this.SalaryDeductions = val.SalaryDeductions || [];
    this.CourseAttendances = val.CourseAttendances || [];
    this.Graduated = val.Graduated || false;
  }
}