// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { FreelancerStepBank } from "../../Freelancer/FreelancerSettings/Parts/FreelancerStepBank";
import { getLanguage } from "../../../../../Helpers/LanguageHelper";
import { Hidden } from "@material-ui/core";
import { NavLink } from "react-router-dom";
interface IProps extends RouteComponentProps {}
interface IState {}
export class FreelancerSettingsBank extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }
  render() {
    return (
      <div>
        <div>
          <div className="padding-v-32 maxW-600">
          <Hidden smUp> 
              <NavLink to="/freelancer/settings">
                <i className="fas fa-arrow-left fa-lg margin-right-16"></i>
              </NavLink>
            </Hidden> 
            <h1 className="inline-block">{getLanguage(49, "Payment details")}</h1>
            <FreelancerStepBank />
          </div>
        </div>
      </div>
    );
  }
}
