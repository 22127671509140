// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { FreelancerProfile } from "./FreelancerProfile/FreelancerProfile";
import { LoginModel } from "../../../../Models/LoginModel";
import { getCurrentUser } from "../../../../Helpers/SessionHelper";
import { MobileSidebarTrigger } from "../../../Parts/General/MobileSidebarTrigger";
import { Hidden, Button, Grid } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { getLanguage } from "../../../../Helpers/LanguageHelper";
import { VerificationStatus } from "./VerificationStatus";

interface IProps extends RouteComponentProps { }

interface IState {
  user: LoginModel | null;
}

export class FreelancerHome extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      user: null
    };
  }

  componentDidMount() {
    let user = getCurrentUser();
    if (user) {
      this.setState({ user });
    }
  }

  render() {
    return (
      <div>
        <div className="margin-top-24">
          <Grid
            container
            spacing={1}
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <h1 className="inline-block">{getLanguage(56, "My Resume")}</h1>
            </Grid>
            <Grid item>
              <Hidden only="xs">
                <NavLink to="/freelancer/settings/resume">
                  <Button className="white" variant="contained">
                    <span className="btn-icon">
                      <i className="fas fa-cog" />
                    </span>
                    {getLanguage(113, "Edit")}
                  </Button>
                </NavLink>
              </Hidden>
              <Hidden smUp>
                <NavLink to="/freelancer/settings">
                  <Button
                    className="icon"
                  >
                    <span>
                      <i className="fas fa-cog fa-lg"> </i>
                    </span>
                  </Button>
                </NavLink>
              </Hidden>
            </Grid>
          </Grid>
        </div>
        <VerificationStatus
          history={this.props.history}
          location={this.props.location}
          match={this.props.match}
        />
        <div className="margin-bottom-24">
          <FreelancerProfile loginProfile={this.state.user} />
        </div>

      </div>
    );
  }
}
