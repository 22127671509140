import React, { useMemo } from "react";
import { RatingModel } from "../../../Models/RatingModel";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core";
import { RatingStarsV2 } from "./RatingStarsV2";
import "./AvarageRating.scss";

interface IProps {
  ratings: RatingModel[];
}
const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));
export const AvarageRating = ({ ratings }: IProps) => {
  const ratingsSum = useMemo(
    () => ratings.reduce((previous, acc) => acc.RatingValue + previous, 0),
    [ratings]
  );
  const avarageRating = useMemo(() => {
    if (ratingsSum && ratings.length) {
      return ratingsSum / ratings.length;
    }
  }, [ratingsSum, ratings.length]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const classes = useStyles();

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {avarageRating && (
        <p onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
          <RatingStarsV2 ratingValue={avarageRating} />
        </p>
      )}
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div>
          {ratings.map((rating) => (
            <div className="rating__container-inline">
              <span className="stars__container padding-8">
                <RatingStarsV2 ratingValue={rating.RatingValue} />
              </span>
              <span className="padding-8">{rating.Statement}</span>
            </div>
          ))}
        </div>
      </Popover>
    </>
  );
};
