import { CoursePartnerModel } from "./CoursePartnerModel";
import { CourseRegistrationModel } from "./CourseRegistrationModel";
import { CourseDateModel } from "./CourseDateModel";
import { CourseAttendanceModel } from "./CourseAttendanceModel";

export class CourseModel {
  Id: string;
  Title: string;
  /** @deprecated use CourseDates instead */
  CourseDate: string;
  MaxRegistrations: number;
  Deactivated: boolean;
  WorkingMinutesToTakeAsPayment: number;
  CountryId: string;
  ValueInLocalCurrency: number;
  Registrations: CourseRegistrationModel[];
  CoursePartnerId: string;
  CoursePartner: CoursePartnerModel | null;
  Address: string;
  Zip: string;
  City: string;
  CourseDates: CourseDateModel[];
  CustomTerms: string;
  CourseAttendances: CourseAttendanceModel[];


  constructor(val: Partial<CourseModel>) {
    this.Id = val.Id || '';
    this.Title = val.Title || '';
    this.CourseDate = val.CourseDate || '';
    this.MaxRegistrations = val.MaxRegistrations || 0;
    this.Deactivated = val.Deactivated || false;
    this.WorkingMinutesToTakeAsPayment = val.WorkingMinutesToTakeAsPayment || 0;
    this.CountryId = val.CountryId || '';
    this.ValueInLocalCurrency = val.ValueInLocalCurrency || 0;
    this.Registrations = val.Registrations || [];
    this.CoursePartnerId = val.CoursePartnerId || '';
    this.CoursePartner = val.CoursePartner || null;
    this.Address = val.Address || '';
    this.Zip = val.Zip || '';
    this.City = val.City || '';
    this.CourseDates = val.CourseDates || [];
    this.CustomTerms = val.CustomTerms || '';
    this.CourseAttendances = val.CourseAttendances || [];
  }
}