import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { Divider, Grid, Link } from "@material-ui/core";
import "./AboutUs.scss";
import { Footer } from "../../Navigation/Footer";
import { AdminUserHelper } from "../../../../../Helpers/AdminUserHelper";
import { AdminUserModel } from "../../../../../Models/AdminUserModel";
import { CountryModel } from "../../../../../Models/CountryModel";
import { StorageHelper } from "../../../../../Helpers/StorageHelper";
import { StaticDataModel } from "../../../../../Models/StaticDataModel";
import { LocalDataModel } from "../../../../../Models/LocalDataModel";
import { getLanguage } from "../../../../../Helpers/LanguageHelper";
import { configureCloudinaryUrl } from "../../../../../Helpers/ImageHelper";
//@ts-ignore
import { IntercomAPI } from "react-intercom";

interface IProps extends RouteComponentProps {}
interface IState {
  admins: AdminUserModel[];
  countries: CountryModel[];
  activeCountry: string;
}
export class AboutUs extends Component<IProps, IState> {
  adminUserHelper = new AdminUserHelper();
  storageHelper = new StorageHelper();
  constructor(props: IProps) {
    super(props);

    this.state = {
      admins: [],
      countries: [],
      activeCountry: "all",
    };

    this.staticDataReady = this.staticDataReady.bind(this);
  }

  async componentDidMount() {
    this.storageHelper.addToCriticalCallbacks(this.staticDataReady);
    let allAdmins = await this.adminUserHelper.getForPublic();

    this.setState({ admins: allAdmins });
  }

  staticDataReady(staticData: StaticDataModel, localData: LocalDataModel) {
    this.setState({ countries: localData.countries });
  }

  renderEmployees(): React.ReactNode {
    let employeeArray: JSX.Element[] = [];
    let index = 0;

    let allEmployees = this.state.admins;
    if (this.state.activeCountry !== "all") {
      allEmployees = allEmployees.filter(
        (x) => x.CountryId === this.state.activeCountry || !x.CountryId
      );
    }

    for (const employee of allEmployees) {
      index++;

      let imageUrl = configureCloudinaryUrl(employee.ProfileImageUrl, [
        "w_300",
      ]);

      if (imageUrl == "" || imageUrl == null) {
        imageUrl = "/img/photos/employeeNoPhoto.png";
      }

      employeeArray.push(
        <Grid key={index} item xs={6} sm={"auto"} md={"auto"} lg={"auto"}>
          <div
            className="teamPhoto"
            style={{ backgroundImage: "url(" + imageUrl + ")" }}
          ></div>
          <h2 className="margin-bottom-0">
            {employee.Firstnames + " " + employee.Lastname}
          </h2>
          <p className="margin-0"> {employee.Title} </p>
        </Grid>
      );
    }

    return (
      <Grid container justify="flex-start" spacing={4}>
        {employeeArray}
      </Grid>
    );
  }

  renderCountryTabs() {
    let EverybodyClass =
      this.state.activeCountry === "all"
        ? "inline-block team_countrySelect active"
        : "inline-block team_countrySelect";

    let countryTabs: JSX.Element[] = [];
    countryTabs.push(
      <div
        onClick={() => this.setState({ activeCountry: "all" })}
        key="all"
        className={EverybodyClass}
      >
        {getLanguage(666, "Everybody")}
      </div>
    );
    for (const country of this.state.countries) {
      let classes = "inline-block team_countrySelect";
      if (this.state.activeCountry === country.Id) classes += " active";
      countryTabs.push(
        <div
          onClick={() => this.setState({ activeCountry: country.Id })}
          key={country.Id}
          className={classes}
        >
          {country.LanguageCode
            ? getLanguage(country.LanguageCode, "country name")
            : country.CountryName}
        </div>
      );
    }
    return countryTabs;
  }

  chatClicked() {
    //@ts-ignore
    IntercomAPI("show");
  }

  render() {
    let facadeData: LocalDataModel | null =
      new StorageHelper().getFacadeLocalization();

    let supportPhone = "(phone number not found)";
    if (facadeData && facadeData.country) {
      supportPhone = facadeData.country.SupportPhone;
    }

    return (
      <div className="aboutUsView">
        <div className="hero">
          <div className="container">
            <Grid container>
              <Grid item sm={5} xs={12}>
                <h1 className="tagline">{getLanguage(656, "Our goal")}</h1>
                <p className="heroContent">
                  {getLanguage(662, "Our mission statement")}
                </p>
              </Grid>
              <Grid item sm={7} xs={12}>
                <img
                  className="heroImage noDrag"
                  src="/img/photos/community/community_company.jpg"
                  alt="No chat network"
                />
              </Grid>
            </Grid>
            <Divider className="divider" variant="middle" />
          </div>
        </div>
        <div className="hero">
          <div className="container">
            <Grid container>
              <Grid item sm={7} xs={12}>
                <img
                  className="heroImage noDrag"
                  src="/img/photos/interview-chabber-picture.jpg"
                  alt="No chat network"
                />
              </Grid>
              <Grid item sm={5} xs={12} style={{ paddingLeft: "20px" }}>
                <h1 className="tagline">
                  {getLanguage(936, "I feel we are being taken care of")}
                </h1>
                <div>
                  <p className="heroContent">
                    {getLanguage(
                      937,
                      "I feel we are being taken care of statement"
                    )}
                  </p>
                  <p className="heroContent">
                    {getLanguage(
                      938,
                      "I feel we are being taken care of statement paragraph 2"
                    )}
                  </p>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <section className="container whistleblower-section">
          <div className="MuiGrid-root MuiGrid-container paragraphs-container">
            <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6">
              <p className="heroContent" style={{ maxWidth: "100%" }}>
                <span>
                  {getLanguage(
                    939,
                    "I feel we are being taken care of statement paragraph 3"
                  )}
                </span>
                <p>
                  {getLanguage(
                    940,
                    "I feel we are being taken care of statement paragraph 4"
                  )}
                </p>
                <span>
                  {getLanguage(
                    941,
                    "I feel we are being taken care of statement paragraph 5"
                  )}
                </span>
              </p>
            </div>
          </div>
        </section>

        <section className="container whistleblower-section">
          <div className="displayFont text-center">
            {getLanguage(942, "More like a partner than a client")}
          </div>
          <div className="MuiGrid-root MuiGrid-container paragraphs-container">
            <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6">
              <p className="heroContent" style={{ maxWidth: "100%" }}>
                {getLanguage(943, "More like a partner than a client p1")}
              </p>{" "}
              <p className="heroContent" style={{ maxWidth: "100%" }}>
                {getLanguage(944, "More like a partner than a client p2")}
              </p>
              <p className="heroContent" style={{ maxWidth: "100%" }}>
                {getLanguage(945, "More like a partner than a client p2")}
              </p>
            </div>
          </div>
        </section>
        <section className="container whistleblower-section">
          <div className="displayFont text-center">
            {getLanguage(946, "Industry experience is a big plus")}
          </div>
          <div className="MuiGrid-root MuiGrid-container paragraphs-container">
            <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6">
              <p className="heroContent" style={{ maxWidth: "100%" }}>
                {getLanguage(947, "Industry experience is a big plus p1")}
              </p>
            </div>
          </div>
        </section>
        <section className="container whistleblower-section">
          <div className="displayFont text-center">
            {getLanguage(948, "Facts")}
          </div>
          <div className="MuiGrid-root MuiGrid-container paragraphs-container">
            <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6">
              <p className="heroContent" style={{ maxWidth: "100%" }}>
                {getLanguage(949, "Facts p1")}
              </p>
            </div>
          </div>
          <Divider className="divider" variant="middle" />
        </section>

        <section className="container contact-section">
          <div className="displayFont text-center">
            {getLanguage(445, "Contact")}
          </div>
          <div className="MuiGrid-root MuiGrid-container paragraphs-container">
            <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6">
              <p>
                {getLanguage(
                  657,
                  "We’re here to help. If you have any questions feel free to give us a call. If you would rather send a mail you can do that too. The fastest way to get help is by using the chat icon in the bottom right corner."
                )}
              </p>
            </div>
          </div>
        </section>

        <div className="container">
          <section className="padding-bottom-40">
            <Grid container justify="space-between" spacing={4}>
              <Grid item sm={4} xs={12}>
                <div className="text-center">
                  <div>
                    <i className="fas fa-phone fa-5x brand"></i>
                  </div>
                  <div className="contactDetail">{supportPhone}</div>
                </div>
              </Grid>
              <Grid item sm={4} xs={12}>
                <div className="text-center">
                  <div>
                    <i className="fas fa-at fa-5x brand"></i>
                  </div>
                  <div className="contactDetail">info@chabber.com</div>
                </div>
              </Grid>
              <Grid item sm={4} xs={12}>
                <div
                  className="text-center cursorPointer"
                  onClick={this.chatClicked}
                >
                  <div>
                    <i className="fas fa-comments fa-5x brand"></i>
                  </div>
                  <div className="contactDetail">
                    {getLanguage(658, "Chat with us")}
                  </div>
                </div>
              </Grid>
            </Grid>
          </section>
          <Divider className="divider" variant="middle" />
        </div>
        <section className="container whistleblower-section">
          <div className="displayFont text-center">
            {getLanguage(912, "Whistleblower Arrangement")}
          </div>
          <div className="MuiGrid-root MuiGrid-container paragraphs-container">
            <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6">
              <p>
                {getLanguage(913, "Got something you need off your chest?")}
              </p>
              <p>
                {getLanguage(
                  914,
                  "Through Chabber’s whistleblower arrangement, you can safely and anonymously report unethical conditions at Chabber."
                )}
              </p>
              <p>
                {getLanguage(
                  915,
                  "As a starting point, we hope you will have a direct dialogue with your line manager or contact person at Chabber, but if you wish to be completely anonymous, you can make a report through our whistleblower form."
                )}
              </p>
              <p>
                {getLanguage(
                  916,
                  "The whistleblower arrangement is part of our group brand, Edda Group, and delivered by the law firm Lund Elmer Sandager (LES), who ensure complete anonymity and a data-secure process. The report will anonymously proceed to the HR department, who will present the contents to management. They will then work with the contents to initiate necessary actions."
                )}
              </p>
              <p>
                {getLanguage(
                  917,
                  "Note: You can only report conditions about Chabber. Any concerns regarding conditions within the company you temp at are to be reported through that company’s own whistleblower arrangement."
                )}
              </p>
              <Link
                style={{ textAlign: "center" }}
                href="https://whistleblower.les.dk/dk/mainpublic?command=dk.tempusserva.solution.whistleblow.LandingPublicPage&Customer=129"
              >
                {getLanguage(918, "Read more and report here.")}
              </Link>
            </div>
          </div>
        </section>
        <section className="container esg-section">
          <div className="displayFont text-center">
            {getLanguage(919, "ESG and UN GLOBAL COMPACT")}
          </div>
          <div className="MuiGrid-root MuiGrid-container paragraphs-container">
            <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6">
              <p>
                {getLanguage(
                  920,
                  "Whether you are a client, chabber, or employee at Chabber, it is our goal that you feel confident in us taking responsibility for our actions and development – both socially and societally."
                )}
              </p>
              <p>
                {getLanguage(
                  921,
                  "As a part of Edda Group, we are connected to FN’s CSR program, UN Global Compact, an international program for companies worldwide. The membership has meant that we, for many years, have focused on essential areas, which have made a difference for the environment and the well-being of our employees and temps."
                )}
              </p>
              <p>
                {getLanguage(
                  922,
                  "We prepare a report with concrete measures and targets to improve our efforts within the main principles every year."
                )}
              </p>
              <Link
                style={{ textAlign: "center" }}
                href="https://eddagroup.com/wp-content/uploads/2023/08/UN_Global_Compact_Report_2022.pdf"
              >
                {getLanguage(923, "Read the UN Global Compact report here.")}
              </Link>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
