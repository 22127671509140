import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { Button, MenuItem, Grid, TextField } from "@material-ui/core";
import { PostingModel } from "../../../../../Models/PostingModel";
import { CurrencyInput } from "../../../../Parts/General/CurrencyInput";
import { JobDetailsHolder, FormStates } from "./EditPostingTypes";
import { StorageHelper } from "../../../../../Helpers/StorageHelper";
import { CalendarLanguages } from "../../../../../Common/CalendarLanguages";
import { SkillModel } from "../../../../../Models/SkillModel";
import { getLanguage } from "../../../../../Helpers/LanguageHelper";
import { OptimizedTextField } from "../../../../Parts/General/OptimizedTextField";
import { isCollectiveAgreementOnboardingAccepted } from "../../../../../Modules/CollectiveAgreement";
import { PageLoader } from "../../../../Parts/General/PageLoader";

interface IProps {
  formState: FormStates;
  originalPosting: PostingModel | null;
  companyId?: string;

  jobDetails: JobDetailsHolder;
  lastUpdate: Date;

  userIsPrivate?: boolean | null;
  errors: { [key: string]: { message: string; isErr: boolean } };
  clientValidation: (name: string, value: any) => void;
  handleDateChange: (date: Date | null) => void;
  changeStartTime: (value: string, index: number) => void;
  changeEndTime: (value: string, index: number) => void;
  getStartTimes: (index: number, jobDetails: any, formstate: any) => JSX.Element[];
  getEndTimes: (index: number, jobDetails: any, formstate: any) => JSX.Element[];
  getStartValue: (index: number) => number;
  getEndValue: (index: number) => number;
  removeInterval: (index: number) => void;
  addTimeInterval: (event: any) => void;
  changeValues: (value: any, name: string) => void;
  refetch: boolean; //that's hacky way to force child component refetch
}

interface IState {
  calendarLanguage: string;
  skills: SkillModel[];
  hourlyWageEnabled: boolean;
  loading: boolean;
}

export class JobDetails extends Component<IProps, IState> {
  storageHelper = new StorageHelper();

  constructor(props: IProps) {
    super(props);
    let calendarLanguage = CalendarLanguages.Instance.getCalendarLanguage();
    this.state = {
      calendarLanguage,
      skills: this.storageHelper.getSkillsFromLocal() as SkillModel[],
      hourlyWageEnabled: true,
      loading: false,
    };
  }

  componentDidUpdate(prev: IProps) {
    if (prev.refetch !== this.props.refetch || prev.lastUpdate !== this.props.lastUpdate) {
      this.checkCollectiveAgreement(this.props.jobDetails.selectedJobtype, this.props.companyId);
    }
  }

  componentDidMount() {
    if (this.props.jobDetails.selectedJobtype && this.props.companyId) {
      this.checkCollectiveAgreement(this.props.jobDetails.selectedJobtype, this.props.companyId);
    }
  }

  checkCollectiveAgreement(jobType: string, companyId?: string) {
    this.setState((curr) => ({ ...curr, loading: true }));
    isCollectiveAgreementOnboardingAccepted(jobType, companyId)
      .then((accepted) => {
        this.setState((curr) => ({ ...curr, hourlyWageEnabled: !accepted }));
      })
      .finally(() => {
        this.setState((curr) => ({ ...curr, loading: false }));
      });
  }

  render() {
    if (this.state.loading) return <PageLoader />;

    let startTimeItems = this.props.getStartTimes(-1, this.props.jobDetails, this.props.formState);
    let endTimeItems = this.props.getEndTimes(-1, this.props.jobDetails, this.props.formState);
    let firstStartValue = this.props.getStartValue(-1);
    let firstEndValue = this.props.getEndValue(-1);

    return (
      <div>
        <h2 className="brand">{getLanguage(412, "Job details")}</h2>
        <Grid container spacing={4}>
          <Grid item lg={4}>
            <OptimizedTextField
              id="outlined-country-currency"
              select
              label={getLanguage(199, "Job type")}
              margin="dense"
              value={this.props.jobDetails.selectedJobtype || ""}
              onChange={(e) => {
                this.props.changeValues(e.target.value, "selectedJobtype");
                this.checkCollectiveAgreement(e.target.value, this.props.companyId);
              }}
              disabled={
                this.props.formState !== "newForm" &&
                this.props.formState !== "loadForm" &&
                this.props.formState !== "newTemplate" &&
                this.props.formState !== "editTemplate"
              }
            >
              {this.props.jobDetails.skills.map((x) => (
                <MenuItem key={x.Id} value={x.Id}>
                  {this.getJobTitle(x)}
                </MenuItem>
              ))}
            </OptimizedTextField>
          </Grid>
          <Grid item lg={4}>
            <OptimizedTextField
              label={getLanguage(413, "Amount needed")}
              margin="dense"
              value={this.props.jobDetails.amountNeeded || ""}
              disabled={this.props.formState === "loadClosedForm"}
              onChange={(e) => {
                let val = e.target.value;
                let valNumber = Number(val);
                if (val !== "" && val != null) {
                  valNumber = Number(e.target.value.replace(/[^0-9]+/g, ""));
                }
                this.props.changeValues(valNumber, "amountNeeded");
              }}
              onBlur={(e) => {
                this.props.clientValidation("amountNeeded", e.target.value);
              }}
              error={this.props.errors["amountNeeded"] && this.props.errors["amountNeeded"].isErr}
              helperText={this.props.errors["amountNeeded"] && this.props.errors["amountNeeded"].message}
            />
          </Grid>
          <Grid item lg={4}>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              customInput={<TextField variant="outlined" fullWidth label={getLanguage(183, "Date")} margin="dense" />}
              locale={this.state.calendarLanguage}
              selected={this.props.jobDetails.date.toDate()}
              onChange={(date) => this.props.handleDateChange(date)}
              minDate={new Date()}
              calendarClassName="Chabber_Calendar editPostingCalendar"
              withPortal
              disabled={this.props.formState !== "newForm" && this.props.formState !== "loadForm"}
            />
          </Grid>
          <Grid container item spacing={4} alignItems="flex-start">
            <Grid item lg={4}>
              <OptimizedTextField
                type="time"
                label={getLanguage(414, "Start time")}
                select
                margin="dense"
                onChange={(e) => this.props.changeStartTime(e.target.value, -1)}
                value={firstStartValue}
                disabled={
                  this.props.formState !== "newForm" &&
                  this.props.formState !== "loadForm" &&
                  this.props.formState !== "newTemplate" &&
                  this.props.formState !== "editTemplate"
                }
              >
                {startTimeItems}
              </OptimizedTextField>
            </Grid>
            <Grid item lg={4}>
              <OptimizedTextField
                type="time"
                label={getLanguage(415, "End time")}
                select
                margin="dense"
                onChange={(e) => this.props.changeEndTime(e.target.value, -1)}
                value={firstEndValue}
                disabled={
                  this.props.formState !== "newForm" &&
                  this.props.formState !== "loadForm" &&
                  this.props.formState !== "newTemplate" &&
                  this.props.formState !== "editTemplate"
                }
              >
                {endTimeItems}
              </OptimizedTextField>
            </Grid>
            {!this.props.userIsPrivate && this.state.hourlyWageEnabled ? (
              <Grid item lg={4}>
                <CurrencyInput
                  value={this.props.jobDetails.salaryPerHour}
                  onChange={(value: string) => this.props.changeValues(value, "salaryPerHour")}
                  label={getLanguage(416, "Salary/hour")}
                  fullWidth
                  error={this.props.errors["salaryHour"] && this.props.errors["salaryHour"].isErr}
                  helperText={this.props.errors["salaryHour"] && this.props.errors["salaryHour"].message}
                  onFocus={(e) => this.props.changeValues(e.currentTarget, "salaryElement")} // sets the popover target
                  defaultValue={this.props.jobDetails.defaultSalary}
                  onBlur={(event: any) => this.props.clientValidation("salaryHour", this.props.jobDetails.salaryPerHour)}
                />
              </Grid>
            ) : this.state.hourlyWageEnabled ? (
              <Grid item lg={4}>
                <OptimizedTextField
                  select
                  label={getLanguage(632, "Event type")}
                  margin="dense"
                  value={this.props.jobDetails.SelectedEventType || ""}
                  onChange={(e) => {
                    this.props.changeValues(e.target.value, "eventType");
                  }}
                  disabled={
                    this.props.formState !== "newForm" &&
                    this.props.formState !== "loadForm" &&
                    this.props.formState !== "newTemplate" &&
                    this.props.formState !== "editTemplate"
                  }
                >
                  {this.props.jobDetails.EventTypes.map((x) => (
                    <MenuItem key={x.Id} value={x.Id}>
                      {getLanguage(x.LanguageCode)}
                    </MenuItem>
                  ))}
                </OptimizedTextField>
              </Grid>
            ) : (
              <></>
            )}

            {this.props.jobDetails.salaryIntervals.map((object, index) => {
              let start = this.props.getStartTimes(index, this.props.jobDetails, this.props.formState);
              let end = this.props.getEndTimes(index, this.props.jobDetails, this.props.formState);
              let startValue = this.props.getStartValue(index);
              let endValue = this.props.getEndValue(index);

              return (
                <Grid container item spacing={4} key={index} className="salaryIntervalRow no-margin">
                  <div className="removeSalaryInterval" onClick={() => this.props.removeInterval(index)}>
                    <i className="fas fa-times"></i>
                  </div>
                  <Grid item lg={4}>
                    <OptimizedTextField
                      type="time"
                      label={getLanguage(414, "Start time")}
                      select
                      margin="dense"
                      onChange={(e) => this.props.changeStartTime(e.target.value, index)}
                      disabled={
                        this.props.formState !== "newForm" &&
                        this.props.formState !== "loadForm" &&
                        this.props.formState !== "newTemplate" &&
                        this.props.formState !== "editTemplate"
                      }
                      value={startValue}
                    >
                      {start}
                    </OptimizedTextField>
                  </Grid>
                  <Grid item lg={4}>
                    <OptimizedTextField
                      type="time"
                      label={getLanguage(415, "End time")}
                      select
                      margin="dense"
                      onChange={(e) => this.props.changeEndTime(e.target.value, index)}
                      disabled={
                        this.props.formState !== "newForm" &&
                        this.props.formState !== "loadForm" &&
                        this.props.formState !== "newTemplate" &&
                        this.props.formState !== "editTemplate"
                      }
                      value={endValue}
                    >
                      {end}
                    </OptimizedTextField>
                  </Grid>
                  <Grid item lg={4}>
                    <CurrencyInput
                      value={this.props.jobDetails.salaryIntervals[index].salaryPerHour.toString()}
                      onChange={(value) => {
                        let salaryIntervals = this.props.jobDetails.salaryIntervals;
                        salaryIntervals[index].salaryPerHour = value;
                        this.props.changeValues(salaryIntervals, "salaryIntervals");
                      }}
                      label={getLanguage(416, "Salary/hour")}
                      fullWidth
                      margin="normal"
                      error={this.props.errors["salaryHour"] && this.props.errors["salaryHour"].isErr}
                      helperText={this.props.errors["salaryHour"] && this.props.errors["salaryHour"].message}
                      onFocus={(e) => this.props.changeValues(e.currentTarget, "salaryElement")} // sets the popover target
                      defaultValue={this.props.jobDetails.defaultSalary}
                      key={"currency" + index}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          {/* {this.state.hourlyWageEnabled && (
            <Grid item xs={12}>
              <div className="text-center">
                <Button
                  size="small"
                  className="success inputGroup_item"
                  onClick={this.props.addTimeInterval}
                  disabled={this.props.formState !== "newForm" && this.props.formState !== "newTemplate" && this.props.formState !== "editTemplate"}
                >
                  {getLanguage(417, " Add time interval with higher salary")}
                </Button>
              </div>
            </Grid>
          )} */}
          <Grid item lg={4}>
            <OptimizedTextField
              id="overtime-input"
              select
              label={getLanguage(418, "Expected overtime")}
              margin="dense"
              value={this.props.jobDetails.selectedOvertime}
              onChange={(e) => this.props.changeValues(Number(e.target.value), "selectedOvertime")}
              disabled={this.props.formState === "loadClosedForm"}
            >
              <MenuItem
                disabled={
                  this.props.formState === "loadFormWithChabbers" && this.props.originalPosting != null && this.props.originalPosting!.LikelihoodOfOvertime < 0 // or -1 Can't remember... xD
                }
                value="0"
              >
                {getLanguage(419, "None")}
              </MenuItem>
              <MenuItem
                disabled={
                  this.props.formState === "loadFormWithChabbers" && this.props.originalPosting != null && this.props.originalPosting!.LikelihoodOfOvertime < 60
                }
                value="60"
              >
                {getLanguage(420, "1 hour")}
              </MenuItem>
              <MenuItem
                disabled={
                  this.props.formState === "loadFormWithChabbers" && this.props.originalPosting != null && this.props.originalPosting.LikelihoodOfOvertime < 120
                }
                value="120"
              >
                {getLanguage(421, "2 hours")}
              </MenuItem>
              <MenuItem
                disabled={
                  this.props.formState === "loadFormWithChabbers" && this.props.originalPosting != null && this.props.originalPosting.LikelihoodOfOvertime < 180
                }
                value="180"
              >
                {getLanguage(422, "3+ hours")}
              </MenuItem>
            </OptimizedTextField>
          </Grid>
          <Grid item lg={4}>
            <OptimizedTextField
              id="transport-inout"
              select
              label={getLanguage(423, "Paid transport")}
              margin="dense"
              value={this.props.jobDetails.selectedPaidTransport}
              onChange={(e) => this.props.changeValues(Number(e.target.value), "selectedPaidTransport")}
            >
              <MenuItem
                disabled={this.props.formState === "loadFormWithChabbers" && this.props.originalPosting != null && this.props.originalPosting.PaysTransport > 0}
                value="0"
              >
                {getLanguage(419, "None")}
              </MenuItem>
              <MenuItem
                disabled={this.props.formState === "loadFormWithChabbers" && this.props.originalPosting != null && this.props.originalPosting.PaysTransport > 1}
                value="30"
              >
                {getLanguage(424, "30 minutes")}
              </MenuItem>
              <MenuItem
                disabled={this.props.formState === "loadFormWithChabbers" && this.props.originalPosting != null && this.props.originalPosting.PaysTransport > 2}
                value="60"
              >
                {getLanguage(420, "1 hour")}
              </MenuItem>
              <MenuItem
                disabled={this.props.formState === "loadFormWithChabbers" && this.props.originalPosting != null && this.props.originalPosting.PaysTransport > 3}
                value="180"
              >
                {getLanguage(421, "2 hours")}
              </MenuItem>
            </OptimizedTextField>
          </Grid>
        </Grid>
      </div>
    );
  }

  getJobTitle(skillDetails: SkillModel) {
    let skill = this.state.skills.find((x) => x.Id === skillDetails.Id);
    return getLanguage(skill!.getLanguageCode(1, false));
  }
}
