export class UpdatePostingModel {
  Id: string;
  AddressLine1: string;
  AddressLine2: string;
  ZipCode: string;
  City: string;
  SkillId: string;
  SalaryPerHour: number;
  StartAt: string;
  EndAt: string;
  Description: string;
  Contact: string;
  MustBring: string;
  AmountNeeded: number;
  PaysTransport: number;
  AutoHire: number;
  RequiresRelevantExperience: boolean;
  LikelihoodOfOvertime: number;
  AutoHireOnCancel: boolean;
  ReferenceKey: string;
  SalaryIntervals: string;
  OwnerLoginId: string;
  AmountNeededWithBuffer: number;
  AutoHireFavourites: boolean;
  CoveredByCollectiveAgreement: boolean;
  CompanyInvoiceRecipientId: string;
  SelfPaidBreak: boolean;
  MealIncluded: boolean;

  constructor(val: Partial<UpdatePostingModel>) {
    this.Id = val.Id || "";
    this.SkillId = val.SkillId || "";
    this.AddressLine1 = val.AddressLine1 || "";
    this.AddressLine2 = val.AddressLine2 || "";
    this.ZipCode = val.ZipCode || "";
    this.City = val.City || "";
    this.SalaryPerHour = val.SalaryPerHour || 0;
    this.StartAt = val.StartAt || "";
    this.EndAt = val.EndAt || "";
    this.Description = val.Description || "";
    this.Contact = val.Contact || "";
    this.MustBring = val.MustBring || "";
    this.AmountNeeded = val.AmountNeeded || 0;
    this.PaysTransport = val.PaysTransport || 0;
    this.AutoHire = val.AutoHire || 0;
    this.RequiresRelevantExperience = val.RequiresRelevantExperience || false;
    this.LikelihoodOfOvertime = val.LikelihoodOfOvertime || 0;
    this.OwnerLoginId = val.OwnerLoginId || "";
    this.AutoHireOnCancel = val.AutoHireOnCancel || false;
    this.ReferenceKey = val.ReferenceKey || "";
    this.SalaryIntervals = val.SalaryIntervals || "";
    this.AmountNeededWithBuffer = val.AmountNeededWithBuffer || 0;
    this.AutoHireFavourites = val.AutoHireFavourites || false;
    this.CoveredByCollectiveAgreement =
      val.CoveredByCollectiveAgreement || false;
    this.CompanyInvoiceRecipientId = val.CompanyInvoiceRecipientId || "";
    this.SelfPaidBreak = val.SelfPaidBreak || false;
    this.MealIncluded = val.MealIncluded || false;
  }
}
