import { AxiosRequestConfig } from 'axios';

export class Call {
  delayTimer: number;
  retries: number;
  config: AxiosRequestConfig;
  callback: Function;
  isOldApi: true | false | 'set';
  isAdmin: true | false | undefined;

  constructor(config: AxiosRequestConfig, callback: Function, isOldApi: true | false | 'set', isAdmin: true | false | undefined, delay?: number, retries?: number) {
    this.delayTimer = delay || 0;
    this.retries = retries || 0;
    this.config = config;
    this.callback = callback;
    this.isOldApi = isOldApi;
    this.isAdmin = isAdmin;
  }
}