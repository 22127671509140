import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { useMemo, useState } from "react";
import Webcam from "react-webcam";
import "./PhotoCapture.scss";
import CloseIcon from "@material-ui/icons/Close";
import { CameraAltOutlined, FlipCameraIosOutlined } from "@material-ui/icons";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onCapture: (src: string) => void;
}

export const PhotoCapture = ({ isOpen, onClose, onCapture }: IProps) => {
  const [facingMode, setFacingMode] = useState<"environment" | "user">(
    "environment"
  );
  const [isCameraAvailable, setIsCameraAvailable] = useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const webcamRef = React.useRef(null);
  const isIOS = useMemo(
    () =>
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i),
    [navigator.userAgent]
  );

  const isAndroid = useMemo(
    () =>
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i),
    [navigator.userAgent]
  );
  const capture = React.useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = (webcamRef.current as any).getScreenshot();
      onCapture(imageSrc);
    }
  }, [webcamRef]);

  return (
    <div>
      <Dialog onClose={onClose} open={isOpen} fullScreen={fullScreen}>
        <DialogTitle>
          <div className="dialog-title">
            <span>Take a photo of document</span>
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="content-container">
            {!isCameraAvailable && (
              <div>
                <Alert className="camera-unavailable-alert" severity="warning">
                  <AlertTitle>Camera unavailable</AlertTitle>
                  Unable to start camera —{" "}
                  <strong>check your system preferences!</strong>
                </Alert>
                {isIOS && (
                  <video autoPlay playsInline loop muted width="100%">
                    <source
                      src="/videos/ios-camera-guideline.MP4"
                      type="video/mp4"
                    />
                  </video>
                )}
                {isAndroid && (
                  <video autoPlay playsInline loop muted width="100%">
                    <source
                      src="/videos/android-camera-guideline.MP4"
                      type="video/mp4"
                    />
                  </video>
                )}
              </div>
            )}
            <div className="webcam-row">
              <Webcam
                videoConstraints={{
                  facingMode,
                }}
                ref={webcamRef}
                audio={false}
                onUserMedia={() => setIsCameraAvailable(true)}
                onUserMediaError={() => setIsCameraAvailable(false)}
                width={350}
                height={300}
                screenshotFormat="image/jpeg"
              />
            </div>
            <div className="button-row">
              {isCameraAvailable && (
                <>
                  <IconButton
                    onClick={() =>
                      setFacingMode(
                        facingMode === "environment" ? "user" : "environment"
                      )
                    }
                  >
                    <FlipCameraIosOutlined />
                  </IconButton>
                  <IconButton onClick={capture}>
                    <CameraAltOutlined />
                  </IconButton>
                </>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
