import moment from "moment";
import { getDateOfBirthFromSsn } from "norwegian-ssn-validator";

export const isAbove18YearsOld = (ssnNumber: string) => {
    const dateOfBirth = ssnNumber
        ? getDateOfBirthFromSsn(ssnNumber)
        : null;
    return dateOfBirth
        ? moment().diff(moment(dateOfBirth), "years") >= 18
        : false;
}