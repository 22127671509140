import React, { Component } from 'react';
import { TextField, PropTypes, InputProps } from "@material-ui/core";
import { InputLabelProps } from '@material-ui/core/InputLabel';
import equal from "deep-equal";

interface IProps {
  type?: 'text' | 'number' | 'password' | 'tel' | 'email' | 'time';
  classes?: Partial<Record<"root", string>>;
  className?: string;
  value: unknown;
  select?: true;
  label: string
  id?: string;
  rows?: string | number;
  error?: boolean;
  children?: React.ReactNode;
  placeholder?: string;
  multiline?: true;
  required?: boolean;
  InputLabelProps?: Partial<InputLabelProps>;
  margin?: PropTypes.Margin;
  disabled?: boolean;
  hidden?: boolean;
  helperText?: string;
  size?: 'small' | 'medium' | undefined;
  onBlur?: ((e: any) => Promise<void>) | ((e: any) => void);
  onFocus?: ((e: any) => Promise<void>) | ((e: any) => void);
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => void;
}

interface IState { }
export class OptimizedTextField extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  shouldComponentUpdate(nextProps: IProps, nextState: IState) {
    return equal(this.props, nextProps) === false;
  }

  getType() {
    if (this.props.select) {
      return undefined;
    }

    return this.props.type ? this.props.type : 'text';
  }

  render() {
    return (
      <TextField
        {...this.props}
        type={this.getType()}
        variant='outlined'
        fullWidth
        margin={this.props.margin || "normal"}
        InputLabelProps={{
          shrink: true
        }}
      >
        {this.props.children}
      </TextField>
    )
  }
}