import { AxiosRequestConfig } from 'axios';
import { CallManager } from './CallManager/CallManager';

export class FeatureHelper {


  async getAllFeatures(countryId: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/features/getall',
        withCredentials: true,
        data: {
          country: countryId
        }
      }

      let features: any[] = (await CallManager.Instance.makeCall(config, false, true)).data;
      return features;
    } catch (error: any) {
      return false;
    }
  }

  async changeStateOfFeatureForCountry(activated: boolean, featureName: string, countryId: string, ) {
    try {
      let config: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/features/changeStateOfFeatureForCountry',
        withCredentials: true,
        data: {
          activated,
          featureName,
          countryId

        },
      };
      let response = await CallManager.Instance.makeCall(config, false, true);

      return response.status === 200 ? true : false;
    } catch (error: any) {
      return false;
    }
  }

  async createFeature(featureName: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/features/create',
        withCredentials: true,
        data: {
          feature: featureName
        }
      }

      let success = await CallManager.Instance.makeCall(config, false, true);
      if (success)
        return true;
    } catch (error: any) {
      return false;
    }
  }
}