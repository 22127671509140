import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { StorageHelper } from '../../../Helpers/StorageHelper';

const { detect } = require('detect-browser');
const browser = detect();


interface IProps { }
interface IState { hasError: boolean }
export class ErrorBoundary extends Component<IProps, IState> {
  storageHelper = new StorageHelper();
  constructor(props: IProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    console.log('get Derived State From Error');
    console.log('browser', browser); // TODO: Use this once we've added logging
    if ((new StorageHelper()).getEnvVar() !== 'Production') {
      console.log('derived error', error);
    }
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    // FIXME: Report error here
    // sentry.log('ERROR!!')!
    console.log('component did catch');
    console.log('browser', browser);

    if (this.storageHelper.getEnvVar() !== 'Production') {
      console.log('error', error);
      console.log('errorInfo', errorInfo);
    }

  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div className="emptyState_wrapper fullscreen">
        <div className="emptyState_content">
          <img src="/img/illustrations/error.svg" className="noDrag" />
          <h1>
            Fatal error
        </h1>
          <p className="infoParagraph">
            The website has experienced a fatal error. A log has been sent to our tech team, please refresh the page.
              If the error persists feel free to contact our customer support.
        </p>
          <br />
          <Button onClick={() => location.reload()}>Refresh</Button>
        </div>
      </div>
    }

    return this.props.children;
  }
}