import { LanguageHelper } from "./LanguageHelper";
import { LocalDataModel } from "../Models/LocalDataModel";
import { CountryModel } from "../Models/CountryModel";
import { ApplicationProductModel } from "../Models/ApplicationProductModel";
import { EventWithPayload } from "../Common/EventWithPayload";
import moment from "moment";
import { LoginModel } from "../Models/LoginModel";
import { StaticDataModel } from "../Models/StaticDataModel";
import { SkillModel } from "../Models/SkillModel";
import { AxiosRequestConfig } from "axios";
import { CallManager } from "./CallManager/CallManager";
import { iInitialPostingData } from "../Components/Routing/Facade/Company/EditPosting/EditPostingTypes";
import { AdminUserModel } from "../Models/AdminUserModel";

export class StorageHelper {
  static criticalCallbacks: Function[] = [];

  async initializeCaches(forceUpdate: boolean) {
    let isDataStale: boolean = this.isStaticDataStale();
    if (!isDataStale && !forceUpdate) return;
    let countryAndLanguage = this.getCountryAndLanguageCode();

    let data = await this.downloadStaticData(countryAndLanguage);
    if (!data) return;

    this.setLanguageStrings(data.languageStrings);
    this.setStaticData(data.staticData);
    this.setFacadeLocalization(data.facadeLocalization);
    this.setEnvVar(data.envVar);
    this.setApplicationProducts(data.applicationProducts);

    for (const call of StorageHelper.criticalCallbacks) {
      call(data.staticData, data.facadeLocalization);
    }
    StorageHelper.criticalCallbacks = [];
  }

  isStaticDataStale() {
    let envVar = this.getEnvVar();
    let localData = this.getFacadeLocalization();
    let staticData = this.getStaticData();

    if (localData != null && staticData != null && envVar === "Production") {
      let today = moment();
      let facadeDateWithStale = moment(localData.creationDate).add(2, "hours");
      let staticDateWithStale = moment(staticData.creationDate).add(2, "hours");
      // We want to know if localdata is still fresh.
      if (
        today.isBefore(facadeDateWithStale) &&
        today.isBefore(staticDateWithStale)
      ) {
        // it's fresh, so we don't need to fetch a new one
        return false;
      }
    }
    return true;
  }

  async downloadStaticData(val?: {
    countryCode: string;
    languageCode: string;
  }) {
    try {
      let params = "";
      if (val && val.countryCode) {
        params += "?countrycode=" + val.countryCode;
      }
      if (val && val.languageCode) {
        params += params === "" ? "?" : "&";
        params += "languagecode=" + val.languageCode;
      }

      let config: AxiosRequestConfig = {
        url: "/api/clientData" + params,
        method: "GET",
      };
      let response = await CallManager.Instance.makeCriticalCall(config, false);

      if (response.data) {
        return {
          facadeLocalization: new LocalDataModel(
            response.data.facadeLocalization
          ),
          staticData: new StaticDataModel(response.data.staticData),
          envVar: response.data.envVar,
          languageStrings: response.data.languageStrings,
          applicationProducts: response.data.applicationProducts,
        };
      }
    } catch (error: any) {
      return false;
    }
  }

  addToCriticalCallbacks(
    callback: (staticData: StaticDataModel, localData: LocalDataModel) => void
  ) {
    StorageHelper.criticalCallbacks.push(callback);
    let staticData = this.getStaticData();
    let localData = this.getFacadeLocalization();

    if (staticData && localData) {
      for (const call of StorageHelper.criticalCallbacks) {
        call(staticData, localData);
      }
      StorageHelper.criticalCallbacks = [];
    }
  }

  setLocal(name: string, value: string) {
    let str = value;
    if (typeof value != "string") str = JSON.stringify(value);
    localStorage.setItem(name, str);
    this.sendEvent("localStorageUpdated");
  }

  setCountryInLocal(value: string) {
    let name = "country";
    let str = value;
    if (typeof value != "string") str = JSON.stringify(value);
    localStorage.setItem(name, str);
    this.sendEvent("localStorageUpdated");
  }

  getCountryFromLocal() {
    // FIXME: I believe this is legacy code
    let value = localStorage.getItem("country");
    if (value == null) return null;
    let val = value;
    try {
      return JSON.parse(value);
    } catch (error: any) {
      return val;
    }
  }

  getFacadeCountry() {
    let localData = this.getFacadeLocalization();

    if (localData != null) {
      return localData.country;
    }
    return null;
  }

  getCountriesFromLocal() {
    let localData = this.getFacadeLocalization();

    if (localData != null) {
      return localData.countries;
    }
    return [];
  }

  getLanguagesFromLocal() {
    let localData = this.getFacadeLocalization();

    if (localData == null) return [];
    return localData.knownLanguages;
  }

  getLanguageFromLocal(id: string) {
    return this.getLanguagesFromLocal().find((x) => x.Id === id);
  }

  setAdminEditLanguageInLocal(value: string) {
    let name = "adminEditLanguage";
    let str = value;
    if (typeof value != "string") str = JSON.stringify(value);
    localStorage.setItem(name, str);
    this.sendEvent("localStorageUpdated");
  }

  getAdminEditLanguageFromLocal() {
    let value = localStorage.getItem("adminEditLanguage");
    if (value == null) return null;
    let val = value;
    try {
      return JSON.parse(value);
    } catch (error: any) {
      return val;
    }
  }

  setRegionInLocal(value: string) {
    let name = "region";
    let str = value;
    if (typeof value != "string") str = JSON.stringify(value);
    localStorage.setItem(name, str);
    this.sendEvent("localStorageUpdated");
  }

  getRegionFromLocal() {
    let value = localStorage.getItem("region");
    if (value == null) return null;
    let val = value;
    try {
      return JSON.parse(value);
    } catch (error: any) {
      return val;
    }
  }

  setLanguageActiveInLocal(value: string) {
    let name = "languageActive";
    let str = value;
    if (typeof value != "string") str = JSON.stringify(value);
    localStorage.setItem(name, str);
    this.sendEvent("localStorageUpdated");
  }

  getLanguageActiveFromLocal() {
    let value = localStorage.getItem("languageActive");
    if (value == null) return null;
    let val = value;
    try {
      return JSON.parse(value);
    } catch (error: any) {
      return val;
    }
  }

  setAtlasInLocal(value: string) {
    let name = "atlas";
    let str = value;
    if (typeof value != "string") str = JSON.stringify(value);
    localStorage.setItem(name, str);
    this.sendEvent("localStorageUpdated");
  }

  getAtlasFromLocal() {
    let value = localStorage.getItem("atlas");
    if (value == null) return null;
    let val = value;
    try {
      return JSON.parse(value);
    } catch (error: any) {
      return val;
    }
  }

  setAllCountriesInLocal(value: string) {
    let name = "allCountries";
    let str = value;
    if (typeof value != "string") str = JSON.stringify(value);
    localStorage.setItem(name, str);
    this.sendEvent("localStorageUpdated");
  }

  getAllCountriesFromLocal() {
    let value = localStorage.getItem("allCountries");
    if (value == null) return null;
    let val = value;
    try {
      return JSON.parse(value);
    } catch (error: any) {
      return val;
    }
  }

  setAtlasCountryInLocal(value: string) {
    let name = "atlasCountry";
    let str = value;
    if (typeof value != "string") str = JSON.stringify(value);
    localStorage.setItem(name, str);
    this.sendEvent("localStorageUpdated");
    this.sendEvent("atlasCountryUpdated");
  }

  getAtlasCountryFromLocal() {
    let value = localStorage.getItem("atlasCountry");
    if (value == null) return null;
    let val = value;
    try {
      return JSON.parse(value) as string;
    } catch (error: any) {
      return val;
    }
  }

  removeAtlasCountryFromLocal() {
    localStorage.removeItem("atlasCountry");
  }

  setStaticData(value: StaticDataModel) {
    let name = "staticData";
    let str = JSON.stringify(value);
    localStorage.setItem(name, str);
    this.sendEvent("localStorageUpdated");
  }

  getStaticData(): StaticDataModel | null {
    let value = localStorage.getItem("staticData");
    if (value == null) return null;
    //FIXME: is this unused code just forgotten or? #STL
    let val = value;
    try {
      return JSON.parse(value);
    } catch (error: any) {
      console.error(error);
      return null;
    }
  }

  setApplicationProducts(value: ApplicationProductModel[]) {
    let name = "applicationProducts";
    let str = JSON.stringify(value);
    localStorage.setItem(name, str);
    this.sendEvent("localStorageUpdated");
  }

  getApplicationProducts(): ApplicationProductModel[] | null {
    let value = localStorage.getItem("applicationProducts");
    if (value == null) return null;
    try {
      return JSON.parse(value);
    } catch (error: any) {
      console.error(error);
      return null;
    }
  }

  getExperienceCategoriesFromLocal() {
    let staticData = this.getStaticData();
    if (staticData == null) return null;
    return staticData.experienceCategories;
  }

  getSkillsFromLocal() {
    let staticData = this.getStaticData();
    if (staticData == null) return [];

    return staticData.skills.map((x) => new SkillModel(x));
  }

  getCompetenceQuestionsFromLocal() {
    let staticData = this.getStaticData();
    if (staticData == null) return null;
    return staticData.competenceQuestions;
  }

  setAccessToken(value: string) {
    let name = "accessToken";
    let str = value;
    if (typeof value != "string") str = JSON.stringify(value);
    localStorage.setItem(name, str);
    let code = name + "Event";
    this.sendEvent(code);
  }

  removeAccessToken() {
    let name = "accessToken";
    localStorage.removeItem(name);
    let code = name + "Event";
    this.sendEvent(code);
  }

  getAccessToken() {
    //name changed from getAccessTokenFromLocal, may bugger stuff up after merge
    let value = localStorage.getItem("accessToken");
    if (value == null) return null;
    try {
      return JSON.parse(value);
    } catch (error: any) {
      return value;
    }
  }

  setAdminUsers(value: string) {
    let name = "adminUsers";
    let str = value;
    if (typeof value != "string") str = JSON.stringify(value);
    localStorage.setItem(name, str);
    let code = name + "Event";
    this.sendEvent(code);
  }

  getAdminUsers() {
    let value = localStorage.getItem("adminUsers");
    if (value == null) return null;
    try {
      return JSON.parse(value);
    } catch (error: any) {
      return value;
    }
  }

  setAdminToken(value: string) {
    let name = "adminToken";
    localStorage.setItem(name, value);
    let code = "adminLoggedInEvent";
    this.sendEvent(code);
  }

  getAdminToken() {
    let name = "adminToken";
    let value = localStorage.getItem(name);

    try {
      if (value == null) return null;
      let str = JSON.parse(value);
      return str;
    } catch (error: any) {
      return value;
    }
  }

  removeAdminToken() {
    let name = "adminToken";
    localStorage.removeItem(name);
  }

  setAdminUser(value: string) {
    let name = "adminUser";
    let str = value;
    if (typeof value != "string") str = JSON.stringify(value);
    localStorage.setItem(name, str);
    let code = name + "Event";
    this.sendEvent(code);
  }

  getAdminUser(): AdminUserModel | null {
    let value = localStorage.getItem("adminUser");
    if (value == null) return null;
    try {
      return JSON.parse(value);
    } catch (error: any) {
      return null;
    }
  }

  removeAdminUser() {
    localStorage.removeItem("adminUser");
  }

  setEnvVar(value: string) {
    let name = "envVar";
    let str = value;
    if (typeof value != "string") str = JSON.stringify(value);
    localStorage.setItem(name, str);
    let code = name + "Event";
    this.sendEvent(code);
  }

  getEnvVar(): "Local" | "Beta" | "Production" | "LocalBeta" | null {
    let value = localStorage.getItem("envVar");
    if (value == null) return null;
    try {
      return JSON.parse(value);
    } catch (error: any) {
      return value as "Local" | "Beta" | "Production" | null;
    }
  }

  setLanguageStrings(value: string) {
    let name = "facadeLanguage";
    let str = value;
    if (typeof value != "string") str = JSON.stringify(value);
    localStorage.setItem(name, str);
    let code = name + "Event";
    this.sendEvent(code);
  }

  getFacadeLanguage() {
    let value = localStorage.getItem("facadeLanguage");
    if (value == null) return null;
    try {
      return JSON.parse(value);
    } catch (error: any) {
      return value;
    }
  }

  setCurrentUser(value: string) {
    let name = "currentUser";
    let str = value;
    if (typeof value != "string") {
      str = JSON.stringify(value);
    }

    localStorage.setItem(name, str);
    let code = name + "Event";
    this.sendEvent(code);
  }

  getCurrentUser(): LoginModel | null {
    let value;
    value = localStorage.getItem("currentUser");

    if (value == null) return null;
    try {
      return JSON.parse(value);
    } catch (error: any) {
      console.log(error);
      return null;
    }
  }

  removeCurrentUser() {
    localStorage.removeItem("currentUser");
  }

  sendEvent(name: string, payload?: any) {
    let event = new EventWithPayload(name);
    event.payload = payload;
    window.dispatchEvent(event);
  }

  getFacadeLocalization() {
    let value = localStorage.getItem("facadeLocalization");
    if (value == null) return null;
    let localData: LocalDataModel | null;

    try {
      localData = JSON.parse(value);
    } catch (error: any) {
      localData = null;
    }

    if (localData === null || localData === undefined) return null;
    if (localData?.countries != null && localData.country) {
      localData.country = new CountryModel(localData.country);
      let countries: CountryModel[] = [];
      for (const country of localData.countries) {
        countries.push(new CountryModel(country));
      }
      localData.countries = countries;
      return new LocalDataModel(localData);
    } else {
      return null;
    }
  }

  setFacadeLocalization(
    localData: LocalDataModel,
    overrideCountryAndLanguage?: boolean
  ) {
    localData = new LocalDataModel(localData);
    let name = "facadeLocalization";

    let existingStr = localStorage.getItem(name);
    if (existingStr) {
      let existingObj = JSON.parse(existingStr) as LocalDataModel;
      if (existingObj.language && !overrideCountryAndLanguage) {
        localData.language = existingObj.language;
      }
      if (existingObj.country?.Id && !overrideCountryAndLanguage) {
        localData.country = existingObj.country;
      }
    }
    this.setLocal(name, JSON.stringify(localData));
  }

  getCountryAndLanguageCode() {
    let user = this.getCurrentUser();
    let localData = this.getFacadeLocalization();

    let countryCode = "";
    let languageCode = "";

    //If there's a user in storage
    if (user?.Company?.Country || user?.Freelancer?.Country) {
      // if user is company
      if (user.Company && user.Company.Country) {
        countryCode = user.Company.Country.TwoCharacterCountryCode;

        //else if user is freelancer
      } else if (user.Freelancer && user.Freelancer.Country) {
        countryCode = user.Freelancer.Country.TwoCharacterCountryCode;
      }

      // Language comes from same place
      languageCode = user.PrimaryLanguage;
      // if there's no user in storage, but there is localized data to pull from instead
    } else if (localData?.country) {
      countryCode = localData.country.TwoCharacterCountryCode;
      languageCode = localData.language;
    }

    return { countryCode, languageCode };
  }

  async updateFacadeLocalization(name: facadeLocalNames, value: any) {
    let localData: LocalDataModel | null = this.getFacadeLocalization();
    if (localData != null) {
      switch (name) {
        case facadeLocalNames.language:
          localData.language = value;
          await new LanguageHelper().cacheFacadeLanguageStrings(value);
          this.sendEvent("facadeLanguage");
          break;
        case facadeLocalNames.country:
          localData.country = value;
          //TODO: Set language
          break;
        case facadeLocalNames.Id:
          if (localData.country) localData.country.Id = value;
          break;
        case facadeLocalNames.CountryName:
          if (localData.country) localData.country.CountryName = value;
          break;
        case facadeLocalNames.SsnFormat:
          if (localData.country) localData.country.SsnFormat = value;
          break;
        case facadeLocalNames.TwoCharacterCountryCode:
          if (localData.country)
            localData.country.TwoCharacterCountryCode = value;
          break;
      }

      this.setFacadeLocalization(localData, true);

      // if (name === facadeLocalNames.language) {
      //   await (new LanguageHelper()).cacheFacadeLanguageStrings(facadeLocalNames.language);
      //   this.sendEvent('facadeLanguage');
      // }
    }
  }

  getInitialPostingData() {
    let data = localStorage.getItem("initialPostingData");
    if (data == null) return null;
    let initialData = JSON.parse(data) as iInitialPostingData;
    if (!initialData.creationDate) return null;
    let tooOld = moment().subtract(10, "minutes");

    if (tooOld.isAfter(moment(initialData.creationDate))) return null;

    return initialData;
  }

  setInitialPostingData(data: iInitialPostingData) {
    data.creationDate = moment();
    localStorage.setItem("initialPostingData", JSON.stringify(data));
  }

  removeInitialPostingData() {
    localStorage.removeItem("initialPostingData");
  }

  changeCompanyUsers(login: LoginModel) {
    let initialData = this.getInitialPostingData();
    if (initialData == null) return;

    let existingLogin = initialData.jobOwners.find((x) => x.Id === login.Id);
    if (existingLogin != null) {
      existingLogin = new LoginModel(login);
    } else {
      initialData.jobOwners.push(new LoginModel(login));
    }

    this.setInitialPostingData(initialData);
  }
}

export enum facadeLocalNames {
  language,
  country,
  Id,
  CountryName,
  SsnFormat,
  TwoCharacterCountryCode,
}
