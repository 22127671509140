// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from "react";
import { Switch, Grid, Hidden } from "@material-ui/core";
import { getLanguage } from "../../../../Helpers/LanguageHelper";
import { SavingSnackbar } from "../../../Parts/General/SavingSnackbar";
import { getCurrentUser, setUserData } from "../../../../Helpers/SessionHelper";
import { UserHelper } from "../../../../Helpers/UserHelper";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { SnackbarManager } from "../../../../Helpers/SnackbarManager/SnackbarManager";
interface IProps { }
interface IState {
  urgentJobCheck: boolean;
  getJobCheck: boolean;
  receivedNewsletterCheck: boolean;
  valueChanged: boolean;
}

export class Notifications extends Component<IProps, IState> {
  userHelper = new UserHelper();

  constructor(props: IProps) {
    super(props);
    this.state = {
      urgentJobCheck: true,
      getJobCheck: true,
      receivedNewsletterCheck: true,
      valueChanged: false,
    };
  }


  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    let user = getCurrentUser();
    if (user == null || user.Freelancer == null) {
      console.log('error');
      return;
    }

    let disabled = false;
    let now = new Date();
    if (user.Freelancer.DisableNotificationsUntil != null && user.Freelancer.DisableNotificationsUntil > now) {
      disabled = true;
    }
    this.setState({
      getJobCheck: disabled,
      urgentJobCheck: user.Freelancer.SubscribeToUrgentPostingsMails,
      receivedNewsletterCheck: user.Freelancer.SubscribeToNewsletters,
    });
  }

  handleChange = (name: any) => (event: any) => {

    // @ts-ignore
    this.setState({ [name]: event.target.checked, valueChanged: true });
  };

  submitChanges = async () => {
    let user = getCurrentUser();
    if (user == null || user.Freelancer == null) {
      console.log('error');
      return;
    }

    if (this.state.getJobCheck) {
      user.Freelancer.DisableNotificationsUntil = moment().toDate();
    } else {
      user.Freelancer.DisableNotificationsUntil = moment().add(1, 'M').toDate();
    }
    user.Freelancer.SubscribeToUrgentPostingsMails = this.state.urgentJobCheck;
    user.Freelancer.SubscribeToNewsletters = this.state.receivedNewsletterCheck;

    let response = await this.userHelper.saveNotificationSettings({
      DisableNotificationsUntil: user.Freelancer.DisableNotificationsUntil,
      SubscribeToUrgentPostingsMails: user.Freelancer.SubscribeToUrgentPostingsMails,
      SubscribeToNewsletters: user.Freelancer.SubscribeToNewsletters
    });

    if (response) {
      SnackbarManager.Instance.addSuccess(getLanguage(697, 'Changes saved'));
      this.setState({ valueChanged: false });
      setUserData(user);
    } else {
      SnackbarManager.Instance.addError(getLanguage(698, 'save failed'));
    }
  }

  render() {
    return (
      <div>
        <div className="margin-v-24">
          <Hidden smUp>
            <NavLink to="/freelancer/settings">
              <i className="fas fa-arrow-left fa-lg margin-right-16"></i>
            </NavLink>
          </Hidden>
          <h1 className="inline-block">{getLanguage(50, "Notifications")}</h1>
        </div>
        <div className="maxW-600">
          <div className="paper padding-16 radius-6 margin-v-16">
            <Grid container spacing={1} justify="space-between" alignItems="center">
              <Grid item>
                <p>{getLanguage(134, 'Notify me when I get a job')}</p>
              </Grid>
              <Grid item>
                <Switch
                  disabled
                  checked
                  onChange={this.handleChange("getJobCheck")}
                  color="primary"
                />
              </Grid>
            </Grid>
          </div>
          <div className="paper padding-16 radius-6 margin-v-16">
            <Grid container spacing={1} justify="space-between" alignItems="center">
              <Grid item>
                <p>{getLanguage(135, 'Notify me about jobs I match')}</p>
              </Grid>
              <Grid item>
                <Switch
                  checked={this.state.getJobCheck}
                  onChange={this.handleChange("getJobCheck")}
                  color="primary"
                />
              </Grid>
            </Grid>
          </div>
          <div className="paper padding-16 radius-6 margin-v-16">
            <Grid container spacing={1} justify="space-between" alignItems="center">
              <Grid item>
                <p>{getLanguage(136, 'Notify me about urgent jobs')}</p>
              </Grid>
              <Grid item>
                <Switch
                  checked={this.state.urgentJobCheck}
                  onChange={this.handleChange("urgentJobCheck")}
                  color="primary"
                />
              </Grid>
            </Grid>
          </div>
          {/* New notification section  */}
          <div className="paper padding-16 radius-6 margin-v-16">
            <Grid container spacing={1} justify="space-between" alignItems="center">
              <Grid item>
                <p>{getLanguage(843, 'Receive news letters')}</p>
              </Grid>
              <Grid item>
                <Switch
                  checked={this.state.receivedNewsletterCheck}
                  onChange={this.handleChange("receivedNewsletterCheck")}
                  color="primary"
                />
              </Grid>
            </Grid>
          </div>

        </div>
        <SavingSnackbar open={this.state.valueChanged} saveClicked={this.submitChanges} />
      </div>
    );
  }
}
