export class EventTypeModel {
  Id: string;
  Type: string;
  LanguageCode: number;


  constructor(val: Partial<EventTypeModel>) {
    this.Id = val.Id || '';
    this.Type = val.Type || '';
    this.LanguageCode = val.LanguageCode || 469; // unknown error
  }
}
