import { FreelancerModel } from "./FreelancerModel";
import { PostingModel } from './PostingModel';

export class KnownLanguageModel {
  Id: string;
  Title: string;
  Iso6391Code: string;
  LanguageCode: number | null;
  Postings?: PostingModel[];
  Freelancer?: FreelancerModel[];


  constructor(val: Partial<KnownLanguageModel>) {
    this.Id = val.Id || '';
    this.Title = val.Title || '';
    this.Iso6391Code = val.Iso6391Code || '';
    this.Postings = val.Postings || [];
    this.Freelancer = val.Freelancer || [];
    this.LanguageCode = val.LanguageCode || null;
  }
}