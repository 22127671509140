

export class FeedbackModel {
  RequestId: string;
  OverallRating: number;
  OvertimeHours: number | null;
  OvertimeSalaryPerHour: string;
  Rating1: number;
  Rating2: number;;
  Rating3: number;;
  LeftEarlyHours: number | null;
  Released: boolean;
  Comment: string;
  DidntShowUp: number;




  constructor(val: Partial<FeedbackModel>) {
    this.RequestId = val.RequestId || '';
    this.OverallRating = val.OverallRating || -1;
    this.OvertimeHours = val.OvertimeHours || null;
    this.OvertimeSalaryPerHour = val.OvertimeSalaryPerHour || '';
    this.Rating1 = val.Rating1 || -1;
    this.Rating2 = val.Rating2 || -1;
    this.Rating3 = val.Rating3 || -1;
    this.LeftEarlyHours = val.LeftEarlyHours || null;
    this.Released = val.Released || false;
    this.Comment = val.Comment || '';
    this.DidntShowUp = val.DidntShowUp || 0;
  }
}
