import React, { Component } from "react";
import { Button, Grid, Dialog, Slide, CircularProgress, DialogContent } from "@material-ui/core";
import "./ImageCrop.scss";

//@ts-ignore
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';


const Transition = React.forwardRef(function Transition(props, ref) {
  return (<Slide direction="up" ref={ref} children={<>{props.children}</>} /> as any);
});

interface IProps {
  open: boolean;
  close: (image: any) => void;
  image: any;
  cropMode?: number;
  viewMode?: 0 | 1 | 2 | 3;
  zoomable?: boolean;
}

interface IState {
  imgData: any;
  buttonsDisabled: boolean;
}


export class ImageCrop extends Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      imgData: '',
      buttonsDisabled: false,
    }
    this.functionToSaveImage = this.functionToSaveImage.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }


  handleClose() {
    this.setState({ buttonsDisabled: false })
    this.props.close(null);
  };

  functionToSaveImage() {
    this.setState({ buttonsDisabled: true })

    this.props.close(this.state.imgData);
  }

  cropperMoved() {
    this.setState({
      // @ts-ignore
      imgData: this.refs.cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.3)
    });
  }



  render() {
    return (
      <div>
        <Dialog
          fullScreen
          open={this.props.open}
          onClose={this.handleClose}
          // @ts-ignore
          TransitionComponent={Transition}
          classes={{ paperFullScreen: "paperFullScreen" }}
        >
          <DialogContent>
            <div className="dialogHeader padding-24">
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item>
                  <h2 className="no-margin"><i className="fas fa-crop-alt"></i> Crop image</h2>
                </Grid>
                <Grid item>
                  <div className="inputGroup">
                    <Button
                      disabled={this.state.buttonsDisabled}
                      className="inputGroup_item"
                      onClick={this.handleClose}
                      aria-label="Close"
                    >
                      Close
                  </Button>

                    <Button
                      disabled={this.state.buttonsDisabled}
                      className="inputGroup_item success"
                      aria-label="Close"
                      onClick={this.functionToSaveImage}>
                      <span className="btn-icon" ref='savebtn'>
                        <i className="fas fa-crop-alt" />
                      </span>
                      Save
                    {this.state.buttonsDisabled && <CircularProgress size={20} className="buttonLoader" />}
                    </Button>

                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="padding-32 text-center">
              <div className="text-center relative maxW-600">
                <div className="imageCropper">
                  {/* 
                  // @ts-ignore */}
                  <Cropper
                    ref='cropper'
                    src={this.props.image}
                    style={{ height: '100%', width: '100%' }}
                    // Cropper.js options
                    aspectRatio={this.props.cropMode}
                    guides={false}
                    center={false}
                    crop={this.cropperMoved.bind(this)}
                    viewMode={this.props.viewMode ?? 3}
                    zoomable={this.props.zoomable ?? false}
                    zoomOnTouch={this.props.zoomable ?? false}
                    zoomOnWheel={this.props.zoomable ?? false}
                    movable={false}
                    dragMode={'move'}
                  />
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
