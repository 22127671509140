import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { UserHelper } from "../../../../Helpers/UserHelper";
import { VerificationDocumentsStatus } from "../../../../Models/VerificationDocumentStatus";
import { getCurrentUser } from "../../../../Helpers/SessionHelper";
import { Grid, Button, Dialog, DialogTitle } from "@material-ui/core";
import { getLanguage } from "../../../../Helpers/LanguageHelper";
import moment from "moment";



interface IProps extends RouteComponentProps {
    lockButtons?: () => void;
    checkForExpiration: string | null
}

interface IState {
    incompleteUser: boolean;
    documentStatus: 'approved' | 'pending' | 'rejected' | 'awaitingUpload' | '';
}


export class IsProfileIncomplete extends Component<IProps, IState> {

    constructor(props: IProps) {

        super(props);

        this.state = {
            incompleteUser: false,
            documentStatus: 'awaitingUpload',
        }


        this.renderIncompleteProfile = this.renderIncompleteProfile.bind(this)
        this.isUserReadyToApplyForJobs = this.isUserReadyToApplyForJobs.bind(this)
    }



    async componentDidMount() {
        await this.isUserReadyToApplyForJobs();
    }




    async isUserReadyToApplyForJobs() {
        let currentUser = getCurrentUser();

        if (currentUser && currentUser.LoginType === 0) {
            return;
        }

        let incompleteUserOpen = false;

        if (currentUser &&
            (
                currentUser.vmStepBasicDone !== true ||
                currentUser.vmStepResumeDone !== true ||
                currentUser.vmStepBankDone !== true)
        ) {
            incompleteUserOpen = true;
        }

        let verificationDocumentStatus: VerificationDocumentsStatus | boolean = false;

        if (currentUser && currentUser.Freelancer) {
            verificationDocumentStatus = await new UserHelper().getVerificationDocumentStatus(
                currentUser.Freelancer.CountryId,
                currentUser.Id,
                currentUser.Freelancer.Id
            );
        }

        let documentStatus: 'approved' | 'pending' | 'rejected' | 'awaitingUpload' | '' = '';
        if (typeof verificationDocumentStatus != 'boolean') {
            switch (verificationDocumentStatus.WorkPermit) {
                case 1:
                    documentStatus = 'awaitingUpload';
                    break;
                case 2:
                    documentStatus = 'pending';
                    break;
                case 3:
                    documentStatus = 'approved';
                    break;
                case 4:
                    documentStatus = 'rejected';
                    break;
                default:
                    break;
            }

            if (this.props.checkForExpiration && verificationDocumentStatus && documentStatus === "approved") {
                if (moment(verificationDocumentStatus.WorkPermitExpirationUTC) < moment(this.props.checkForExpiration)) {
                    documentStatus = 'awaitingUpload';
                }
            }

            if (documentStatus !== 'approved') {
                switch (verificationDocumentStatus.WorkPermitBack) {
                    case 1:
                        documentStatus = 'awaitingUpload';
                        break;
                    case 2:
                        documentStatus = 'pending';
                        break;
                    case 3:
                        documentStatus = 'approved';
                        break;
                    case 4:
                        documentStatus = 'rejected';
                        break;
                    default:
                        break;
                }
            }

            if (documentStatus !== 'approved') {
                switch (verificationDocumentStatus.PictureId) {
                    case 1:
                        documentStatus = 'awaitingUpload';
                        break;
                    case 2:
                        documentStatus = 'pending';
                        break;
                    case 3:
                        documentStatus = 'approved';
                        break;
                    case 4:
                        documentStatus = 'rejected';
                        break;
                    default:
                        break;
                }
            }

            if (documentStatus != 'approved' && documentStatus != '') {
                incompleteUserOpen = true;
            }


        }

        this.setState({ incompleteUser: incompleteUserOpen, documentStatus })

        if (incompleteUserOpen || documentStatus == 'pending' || documentStatus == 'rejected' || documentStatus == 'awaitingUpload') {
            if (this.props.lockButtons) {
                this.props.lockButtons();
            }
        }
    }


    renderIncompleteProfile() {
        let user = getCurrentUser();
        if (user == null) {
            // this.setState({ snackbar:{message: 'Something went wrong while trying to fetch the user profile', open: true, state: snackbarState.error} });
            return;
        }

        let stepBasic = user.vmStepBasicDone;
        let stepBank = user.vmStepBankDone;
        let stepResume = user.vmStepResumeDone;
        let documentsRejected = this.state.documentStatus;

        let content: JSX.Element[] = [];
        if (stepBasic !== true) {
            content.push(
                <div key={0}>
                    <Grid container justify="space-between" alignItems="center" spacing={4}>
                        <Grid item>
                            <h2>{getLanguage(55, "Personal data")}</h2>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" onClick={() => this.props.history.push('/freelancer/settings/basic')}>{getLanguage(606, "Fix it")}</Button>
                        </Grid>
                    </Grid>
                </div>
            );
        }
        if (stepBank !== true) {
            content.push(
                <div key={1}>
                    <Grid container justify="space-between" alignItems="center" spacing={4}>
                        <Grid item>
                            <h2>{getLanguage(49, "Payment details")}</h2>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" onClick={() => this.props.history.push('/freelancer/settings/bank')}>{getLanguage(606, "Fix it")}</Button>
                        </Grid>
                    </Grid>
                </div>)
        }
        if (stepResume !== true) {
            content.push(
                <div key={2}>
                    <Grid container justify="space-between" alignItems="center" spacing={4}>
                        <Grid item>
                            <h2>{getLanguage(56, "My resume")}</h2>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" onClick={() => this.props.history.push('/freelancer/settings/resume')}>{getLanguage(606, "Fix it")}</Button>
                        </Grid>
                    </Grid>
                </div>)
        }
        if (documentsRejected == 'rejected' || documentsRejected == 'awaitingUpload') {
            content.push(
                <div key={2}>
                    <Grid container justify="space-between" alignItems="center" spacing={4}>
                        <Grid item>
                            <h2>{getLanguage(491, 'Verification documents')}</h2>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" onClick={() => this.props.history.push('/freelancer/settings/verification')}>{getLanguage(606, "Fix it")}</Button>
                        </Grid>
                    </Grid>
                </div>)
        } else if (documentsRejected == 'pending') {
            content.push(
                <div key={2}>
                    <Grid container justify="space-between" alignItems="center" spacing={4}>
                        <Grid item>
                            <h2>{getLanguage(626, 'Verification documents are pending. You are not able to apply for jobs before these are approved.')}</h2>
                        </Grid>
                    </Grid>
                </div>)
        }

        return content;

    }







    render() {
        let content = this.renderIncompleteProfile();


        return (
            <Dialog
                open={this.state.incompleteUser}
                onClose={() => this.setState({ incompleteUser: false })}
                maxWidth="sm"
                fullWidth

            >
                <DialogTitle>
                    {getLanguage(624, "You must complete your profile in order to apply for shifts.")}
                </DialogTitle>

                <div className="padding-24">
                    {content}
                </div>

            </Dialog>
        );
    }
}
