import os from "os";
import { StorageHelper } from "./StorageHelper";

export function getOldApi() {
  let envVar = new StorageHelper().getEnvVar();

  if (envVar === "Production") {
    return "https://legacyapi.chabber.com/api";
  }
  if (envVar === "Beta" || envVar === "LocalBeta") {
    return "https://legacyapi-beta.chabber.com/api";
  } else if (envVar === "Local") {
    let sysVar = os.release();
    if (sysVar.toLowerCase().includes("win")) {
      //return 'http://localhost:57233/api'

      return "https://legacyapi-beta.chabber.com/api";
    } else {
      return "https://legacyapi-beta.chabber.com/api";
    }
  }
  return "";
}

export let englishLanguageId = "0ed20374-a89b-494b-abb3-327e373d6bef";
export let siteKey = "6LexxUMpAAAAADrR6D7FjzHvuykKkajSHISwdmWp";
