import React, { Component } from 'react';
import { TextField } from '@material-ui/core';
import { getLanguage } from '../../../../../Helpers/LanguageHelper';
interface IProps {
  fieldChanged: () => void;
}
interface IState {
  currentPassword: string;
  newPassword1: string;
  newPassword2: string;
  passwordsMatches: boolean;
  [key: string]: string | boolean;
}
export class PasswordFields extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      currentPassword: '',
      newPassword1: '',
      newPassword2: '',
      passwordsMatches: true
    }

    this.resetFields = this.resetFields.bind(this);
    this.checkIfAllBoxesAreFilled = this.checkIfAllBoxesAreFilled.bind(this)
  }

  async resetFields() {
    await this.setState({
      currentPassword: "",
      newPassword1: "",
      newPassword2: "",
    })
  }

  checkIfAllBoxesAreFilled() {
    if (
      this.state.currentPassword != ""
      && this.state.newPassword1 != ""
      && this.state.newPassword2 != ""
    ) {
      if (this.state.newPassword1 === this.state.newPassword2) {
        return true;
      }
      this.setState({ passwordsMatches: false });
      return getLanguage(573, "The fields do not match");
    }
    return getLanguage(574, "Input missing");
  }

  changeValue(name: string, value: string) {
    this.setState({ [name]: value });
    this.props.fieldChanged();
  }

  render() {
    return (
      <div>
        <TextField
          type="password"
          label={getLanguage(118, "Current password")}
          variant="outlined"
          fullWidth
          margin="normal"
          value={this.state.currentPassword}
          onChange={e =>
            this.changeValue('currentPassword', e.target.value)
          }
          InputLabelProps={{
            shrink: true
          }}
        />
        <TextField
          type="password"
          label={getLanguage(119, "New password")}
          variant="outlined"
          fullWidth
          margin="normal"
          value={this.state.newPassword1}
          onChange={e =>
            this.changeValue('newPassword1', e.target.value)
          }
          InputLabelProps={{
            shrink: true
          }}
        />
        <TextField
          error={this.state.passwordsMatches === false}
          id="component-error"
          type="password"
          label={getLanguage(120, "Repeat new password")}
          variant="outlined"
          fullWidth
          margin="normal"
          value={this.state.newPassword2}
          onChange={e =>
            this.changeValue('newPassword2', e.target.value)
          }
          onFocus={_ => this.setState({ passwordsMatches: true })}
          onBlur={this.checkIfAllBoxesAreFilled}
          InputLabelProps={{
            shrink: true
          }}
          aria-describedby="component-error-text"
          helperText={this.state.passwordsMatches === false ? getLanguage(575, "New passwords do not match") : undefined}
        />
        <br />
        <br />
      </div>
    )
  }
}