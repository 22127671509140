import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Button, Avatar } from '@material-ui/core';
import { getLanguage } from "../../../../Helpers/LanguageHelper";
import { UserHelper } from "../../../../Helpers/UserHelper";

import { NavLink } from 'react-router-dom';
import './PrivatNav.scss';
import { getCurrentUser, logUserOut } from '../../../../Helpers/SessionHelper';
import { ProfilePictureViewer } from '../../../Parts/General/ProfilePictureViewer';

interface IProps extends RouteComponentProps { }

export class PrivateNav extends Component<IProps> {
  userHelper = new UserHelper();

  constructor(props: IProps) {
    super(props);



    this.logOut = this.logOut.bind(this)
  }


  async logOut() {
    let response = await logUserOut();
    if (response === true) {
      this.props.history.push('/');
    } else {
      // FIXME: show snackbar
    }
  }




  render() {
    let user = getCurrentUser();
    return (<div>
      <div className="">
        <div className="privateMenu">
          <div className="privateMenu_top">
            <div className="inline-block">
              <ProfilePictureViewer
                login={user}
                company={user!.Company}
                freelancer={null}
                customClass="privateLogo"
                altText=""
              ></ProfilePictureViewer>
            </div>
            <div className="privateName">{user!.Company!.CompanyName}</div>
          </div>
          <div className="privateMenu_wrapper">
            <div className="privateMenu_content">
              {/* <NavLink exact className="privateMenu_link" to="/company/postings">
                <div className="companyMenu_link-icon">
                  <i className="fas fa-home" />
                </div>
                <div className="companyMenu_link-label">{getLanguage(90, "Home")}</div>
              </NavLink> */}
              <div className="privateMenu_linkGroup">
                <div className="privateMenu_linkGroupTitle">{getLanguage(91, "Freelance shifts")}</div>
                <NavLink className="privateMenu_link" to="/company/postings">
                  <div className="privateMenu_link-icon">
                    <i className="fas fa-list fa-fw" />
                  </div>
                  <div className="privateMenu_link-label">{getLanguage(45, "My shifts")}</div>
                </NavLink>
                <NavLink className="privateMenu_link" to="/company/archive">
                  <div className="privateMenu_link-icon">
                    <i className="fas fa-archive fa-fw" />
                  </div>
                  <div className="privateMenu_link-label">{getLanguage(200, "Archive")}</div>
                </NavLink>
                <ExpansionPanel
                  className="expansionPanel privateMenu_expansionPanel"
                  classes={{
                    expanded: "expanded"
                  }}
                >
                  <ExpansionPanelSummary
                    className="privateMenu_expansionPanel_title"
                    classes={{
                      expandIcon: "expandIcon",
                      expanded: "expanded"
                    }}
                    expandIcon={<i className="fas fa-caret-down expansionIcon" />}
                  >
                    <div className="privateMenu_link-icon">
                      <i className="fas fa-cog fa-fw" />
                    </div>
                    <div className="privateMenu_link-label">{getLanguage(54, "Settings")}</div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className="privateMenu_expansionPanel_content">
                    <NavLink to="/company/settings/basic">
                      <div className="privateMenu_expansionLink">
                        {getLanguage(749, "Your personal data")}
                      </div>
                    </NavLink>
                    <NavLink to="/company/settings/payment">
                      <div className="companyMenu_expansionLink">
                        {getLanguage(98, "Billing")}
                      </div>
                    </NavLink>
                    <NavLink to="/company/settings/changepassword">
                      <div className="privateMenu_expansionLink">
                        {getLanguage(57, "Change password")}
                      </div>
                    </NavLink>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>

              <NavLink to="/company/editposting">
                <div className="margin-v-24">
                  <Button
                    className="button-gradient"
                    variant="contained"
                    fullWidth
                  >
                    {getLanguage(100, "New shift")}
                  </Button>
                </div>
              </NavLink>
            </div>
          </div>
          <div className="privateMenu_bottom">
            <div onClick={this.logOut} className="privateMenu_Bottomlink">
              <div className="privateMenu_link-icon">
                <i className="fas fa-sign-out-alt" />
              </div>
              <div className="privateMenu_link-label" >{getLanguage(58, "Log out")}</div>
            </div>
          </div>
        </div>
      </div>
    </div>)
  }
}