import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Link,
} from "@material-ui/core";
import { getLanguage } from "../../../../Helpers/LanguageHelper";
import { CMSHelper, CMSTypes } from "../../../../Helpers/CMSHelper";
import { StorageHelper } from "../../../../Helpers/StorageHelper";

type ConfirmationStepProps = {
  isFirstConfirmed: boolean;
  isSecondConfirmed: boolean;
  onFirstChange: (next: boolean) => void;
  onSecondChange: (next: boolean) => void;
};

export function ConfirmationStep({
  isFirstConfirmed,
  isSecondConfirmed,
  onFirstChange,
  onSecondChange,
}: ConfirmationStepProps) {
  const [termsContent, setTermsContent] = useState("");
  const [termsDialogOpen, setTermsDialogOpen] = useState(false);
  useEffect(() => {
    const fetcher = async () => {
      let country = new StorageHelper().getFacadeCountry();
      if (country == null) {
        return;
      }
      const content = await new CMSHelper().getPrimaryDraftByCountry(
        country.Id,
        CMSTypes.TermsAndConditionsCompany
      );
      if (content) {
        setTermsContent(content.Text);
      }
    };
    fetcher();
  }, []);
  return (
    <div>
      <h2 className="brand">{getLanguage(870, "Confirmation")}</h2>
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={isFirstConfirmed}
            onChange={() => onFirstChange(!isFirstConfirmed)}
          />
        }
        label={getLanguage(
          882,
          "We guarantee that the information provided is correct and complete"
        )}
      />
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={isSecondConfirmed}
            onChange={() => onSecondChange(!isSecondConfirmed)}
          />
        }
        label={
          <div>
            <span
              dangerouslySetInnerHTML={{
                __html: getLanguage(897, "2nd confirmation rule"),
              }}
            ></span>
            <Link
              style={{ paddingLeft: "2px" }}
              onClick={(e) => {
                setTermsDialogOpen(true);
                e.preventDefault();
              }}
            >
              {getLanguage(954, "Rules link")}
            </Link>
          </div>
        }
      />
      <Dialog open={termsDialogOpen} onClose={(_) => setTermsDialogOpen(false)}>
        <DialogContent>
          <DialogContentText
            dangerouslySetInnerHTML={{ __html: termsContent }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
