import React, { Component } from "react";
import "./PageLoader.scss";
import CircularProgress from "@material-ui/core/CircularProgress";

interface IProps {
  releaseNavbar?: true
  navbarSize?: '10' | '20' | '30'
}
interface IState { }

export class PageLoader extends Component<IProps, IState> {
  componentDidMount() {
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflow = '';
  }

  getClassName() {
    return this.props.releaseNavbar === true ? "pageLoader_backdrop releaseNavbar" : "pageLoader_backdrop";
  }

  render() {
    return (
      <div className={this.getClassName()}>
        <div className="pageLoader_wrapper">
          <CircularProgress className="pageLoader_icon" />
        </div>
      </div>
    );
  }
}