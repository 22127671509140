import React, { Component } from 'react';
import {
  Menu,
  MenuItem} from "@material-ui/core";
import { ICompanyFeedback } from './PostingHelper';

interface IProps {
  anchorEl: Element | null;
  feedbackObject: ICompanyFeedback | null;
  handleClose: () => void;
  openFreelancerDialog: () => void;
  openManualModeDialog: () => void;
  openNoShowDialogue: () => void;
  openHireDialogue: () => void;
 }
interface IState { }

export class FreelancerMenu extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    return (
      <Menu
        id="moreOptionsMenu"
        anchorEl={this.props.anchorEl}
        open={this.props.anchorEl != null}
        onClose={this.props.handleClose}
        className="dropdownMenu"
        classes={{ paper: 'dropdownMenu_content' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className="menuTitle">Options</div>
        <MenuItem className="menuItem" onClick={this.props.openFreelancerDialog}><i className="fas fa-user fa-fw"></i> View profile</MenuItem>
        <MenuItem className="menuItem" onClick={this.props.openManualModeDialog}><i className="fas fa-edit fa-fw"></i> Manual mode</MenuItem>
        <MenuItem className="menuItem" onClick={this.props.openNoShowDialogue}><i className="fas fa-ban fa-fw"></i> No-show</MenuItem>
        <MenuItem className="menuItem" disabled={this.props.feedbackObject != null && this.props.feedbackObject.hired} onClick={this.props.openHireDialogue}><i className="fas fa-rocket fa-fw"></i> Hire</MenuItem>
      </Menu>
    )
  }
}