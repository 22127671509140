import React, { Component } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { CMSMetaModel } from '../../../../../../Models/CMSMetaModel';
import { RouteComponentProps } from 'react-router';
import { TableOptions, IMenuItem } from '../../../../../Parts/General/TableOptions';
import { CMSHelper } from '../../../../../../Helpers/CMSHelper';

interface IProps extends RouteComponentProps {
  contracts: CMSMetaModel[];
  termsAndConditions: CMSMetaModel[];
  privacyPolicy: CMSMetaModel[];
  gdpr: CMSMetaModel[];
  markAsPublished: (id: string, revisionId: string) => void;
}

interface IState {
  activeData: null | CMSMetaModel;
  anchorEl: null | HTMLElement;
}

export class ObligatoryPages extends Component<IProps, IState> {
  cmsHelper = new CMSHelper();

  constructor(props: IProps) {
    super(props);
    this.state = {
      activeData: null,
      anchorEl: null,
    }
    this.openBodyOptions = this.openBodyOptions.bind(this);
    this.closeOptions = this.closeOptions.bind(this);
  }

  renderContracts() {
    return this.renderSection('Contract', '/freelancer/contract', this.props.contracts);
  }

  renderTermsAndConditions() {
    return this.renderSection('Terms And Conditions', '/legal/termsandconditions', this.props.termsAndConditions);
  }

  renderGdrp() {
    return this.renderSection('GDPR', '/legal/gdpr', this.props.gdpr);
  }

  renderPrivacyPolicy() {
    return this.renderSection('Privacy Policy', '/legal/privacypolicy', this.props.privacyPolicy);
  }

  renderSection(name: string, link: string, data: CMSMetaModel[]) {
    let headerStatus = 'far fa-check-circle success';

    if (data.length === 0) {
      headerStatus = 'fas fa-ban danger'; //TODO: Should this be something else?
    } else {
      let notPublished = data.findIndex(x => x.PrimaryRevision == null || x.PrimaryRevision === '');
      if (notPublished !== -1) {
        headerStatus = 'fas fa-ban danger'; //TODO: Should this be something else?
      }
    }

    return <>
      {/* Header */}
      <TableBody className="tableBody">
        <TableRow className="tableRow folder">
          <TableCell className="tableCell">Id</TableCell>
          <TableCell className="tableCell">{name}</TableCell>
          <TableCell className="tableCell">{link}</TableCell>
          <TableCell className="tableCell"></TableCell>
          <TableCell className="tableCell" align="center">
            <i className={headerStatus} />
          </TableCell>
          <TableCell className="tableCell" />
        </TableRow>
      </TableBody>
      <TableBody className="tableBody subTable">

        {/* Body */}
        {data.map(x => {
          let currentLink = link + x.PublicId;
          let optionsCell =
            (<TableCell
              className="tableCell tableOptions"
              onClick={(e: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>) => this.openBodyOptions(e, x)}
            >
              <i className="fas fa-ellipsis-v" />
            </TableCell>)

          link = '/admin/cmsedit?q=' + x.Id;
          let contentStatus = 'far fa-check-circle success';

          let isPublished = (x.PrimaryRevision != null && x.PrimaryRevision !== '');

          if (isPublished) {
            optionsCell = <TableCell className="tableCell" />
          }

          if (!isPublished) {
            contentStatus = 'fas fa-ban danger';
          }

          return <TableRow key={x.Id} className="tableRow" onClick={() => this.props.history.push('/admin/cmsedit?q=' + x.Id)}>
            <TableCell className="tableCell">{x.Id}</TableCell>
            <TableCell className="tableCell">{x.Title}</TableCell>
            <TableCell className="tableCell">{currentLink}</TableCell>
            <TableCell className="tableCell"> {x.LanguageActive} </TableCell>
            <TableCell className="tableCell" align="center">
              <i className={contentStatus} />
            </TableCell>
            {optionsCell}
          </TableRow>
        })}
      </TableBody>
    </>
  }


  openBodyOptions(e: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>, dataObj: CMSMetaModel) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      activeData: new CMSMetaModel(dataObj),
      anchorEl: e.currentTarget
    })
  }

  closeOptions() {
    this.setState({
      activeData: null,
      anchorEl: null
    })
  }

  getMenuItems() {
    if (this.state.activeData instanceof CMSMetaModel && this.state.activeData != null) {
      let data = this.state.activeData;
      let arr: IMenuItem[][] = [[{
        title: 'Publish',
        iconCode: '',
        callback: () => this.publishContent(data)
      }]];

      return arr;
    }
    return []
  }

  async publishContent(data: CMSMetaModel) {
    let response = await this.cmsHelper.publishContent(data.Id);

    if (response !== false) {
      //Force update with the new one
      this.props.markAsPublished(data.Id, response);
    }
  }



  render() {
    return (
      <>
        <Table className="table">
          <TableHead className="tableHead">
            <TableRow className="tableRow">
              <TableCell>Page name</TableCell>
              <TableCell>Page link</TableCell>
              <TableCell>Language</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell> </TableCell>
            </TableRow>
          </TableHead>
          {this.renderContracts()}
          {this.renderTermsAndConditions()}
          {this.renderGdrp()}
          {this.renderPrivacyPolicy()}
        </Table>


        <TableOptions
          handleClose={this.closeOptions}
          menuItems={this.getMenuItems()}
          anchorEl={this.state.anchorEl}
          title={"Options"}
        />
      </>
    )
  }
}