// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from "react";
import {
  Button,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import "./FreelancerSteps.scss";
import { UserHelper } from "../../../../../../Helpers/UserHelper";
import { SavingSnackbar } from "../../../../../Parts/General/SavingSnackbar";
import { PageLoader } from "../../../../../Parts/General/PageLoader";
import { ImageHelper } from "../../../../../../Helpers/ImageHelper";
import { ImageCrop } from "../../../../../Parts/General/ImageCrop";
import {
  getCurrentUser,
  setUserData
} from "../../../../../../Helpers/SessionHelper";
import { StorageHelper } from "../../../../../../Helpers/StorageHelper";
import { SkillModel } from "../../../../../../Models/SkillModel";
import { CompetenceQuestionModel } from "../../../../../../Models/CompetenceQuestionModel";
import { CompetenceSelectionsModel } from "../../../../../../Models/CompetenceSelectionsModel";
import { LoginModel } from "../../../../../../Models/LoginModel";
import {
  getLanguage,
  LanguageHelper
} from "../../../../../../Helpers/LanguageHelper";
import { JobType } from "./FreelancerStepResumeParts/JobType";
import { Education } from "./FreelancerStepResumeParts/Education";
import { Experience } from "./FreelancerStepResumeParts/Experience";
import { EducationModel } from "../../../../../../Models/EducationModel";
import { ExperienceModel } from "../../../../../../Models/ExperienceModel";
import { LocalDataModel } from "../../../../../../Models/LocalDataModel";
import uuid from "uuid";
import { ProfileSkillsModel } from "../../../../../../Models/ProfileSkillsModel";
import { SnackbarManager } from "../../../../../../Helpers/SnackbarManager/SnackbarManager";
import { englishLanguageId } from "../../../../../../Helpers/Cfg";

interface IProps {
  creatingUser: boolean;
  saveStep?: (stepBasicComplete: boolean) => void;
}

interface IState {
  user: LoginModel;
  newDescription: string;
  experiences: ExperienceModel[];
  userSkills: SkillModel[];
  editing: boolean;
  showPageLoader: boolean;
  cropOpen: boolean;
  imageToCrop: string;
  profilePicture: string;
  educations: EducationModel[];
  knownLanguages: newKnownLanguage[];
  competenceQuestions: CompetenceQuestionModel[][];
  reset: boolean;
  descriptionError: boolean;
  emailError: boolean;
  educationError: boolean;
  experienceError: boolean;
  knownLanguageError: boolean;
  skillsError: boolean;
  validationProblem: validationProblem | null;
  jobTypeClassName: string;
  reFresh: boolean;
  competenceSelections: CompetenceSelectionsModel[];
}

interface validationProblem {
  type: string;
  index: number;
}

interface newKnownLanguage {
  knownLanguage: {
    FreelancerProfileKnownLanguages: {
      FreelancerProfileId: string;
      KnownLanguageId: string;
    };
    Id: string;
    Iso6391Code: string;
    Title: string;
    LanguageCode: number | null;
  };
  checked: boolean;
}

export class FreelancerStepResume extends Component<IProps, IState> {
  languageHelper = new LanguageHelper();
  userHelper = new UserHelper();
  fileInput: any;
  imageHelper = new ImageHelper();
  storageHelper = new StorageHelper();

  constructor(props: IProps) {
    super(props);

    this.state = {
      user: new LoginModel({}),
      newDescription: "",
      experiences: [],
      userSkills: [],
      editing: false,
      showPageLoader: false,
      cropOpen: false,
      imageToCrop: "",
      profilePicture: "",
      educations: [],
      knownLanguages: [],
      competenceQuestions: [],
      reset: false,
      descriptionError: false,
      emailError: false,
      educationError: false,
      experienceError: false,
      knownLanguageError: false,
      skillsError: false,
      validationProblem: null,
      jobTypeClassName: "competenceWrapper",
      reFresh: false,
      competenceSelections: []
    };

    this.prepareForm = this.prepareForm.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.saveUserResume = this.saveUserResume.bind(this);
    this.fetchImage = this.fetchImage.bind(this);
    this.openCropping = this.openCropping.bind(this);
    this.saveImage = this.saveImage.bind(this);
    this.closeCropping = this.closeCropping.bind(this);
    this.isLanguageChecked = this.isLanguageChecked.bind(this);
    this.updateKnownLanguages = this.updateKnownLanguages.bind(this);
    this.updateSkills = this.updateSkills.bind(this);
    this.updateCompetenceQuestions = this.updateCompetenceQuestions.bind(this);
    this.updateEducation = this.updateEducation.bind(this);
    this.updateWorkExperience = this.updateWorkExperience.bind(this);
    this.initialStartup = this.initialStartup.bind(this);
    this.creatingUser = this.creatingUser.bind(this);
  }

  async UNSAFE_componentWillMount() {
    await this.prepareForm();
  }

  async prepareForm() {
    await this.setState({ showPageLoader: true });
    let user: LoginModel = getCurrentUser()!;

    if (!user || !user.Freelancer) return;

    await this.setState({
      user: user,
      newDescription: user.Freelancer.Description
    });

    let facadeLocalization: LocalDataModel | null = await this.storageHelper.getFacadeLocalization();

    let languages: {
      Id: string;
      Iso6391Code: string;
      Title: string;
      LanguageCode: number | null;
    }[] = [];

    //Gathering which languages are available to this country and get them from the "knownLanguages" which holds all known languages.
    //This has to be done since knownLanguages and languagesAvailableByCountry are not the same model. (Check facadeLocalization in local storage)
    //TODO: When writing the new API - consider making a language object which has all data.
    if (!facadeLocalization) return;

    let facadeKnownLanguages = facadeLocalization.knownLanguages;
    // let languageAvailableByCountry = facadeLocalization.languagesAvailableByCountry;
    let languageAvailableByCountry =
      facadeLocalization.languagesAvailableByCountry;
    let country = facadeLocalization.country;

    if (facadeKnownLanguages && facadeKnownLanguages.length > 0) {
      for (const knownLanguage of facadeKnownLanguages) {
        //For the current country, does the language exist? (by Id)
        if (
          languageAvailableByCountry
            .filter(x => x.CountryId === country.Id)
            .find(x => x.LanguageId === knownLanguage.Id) != null
        ) {
          languages.push(knownLanguage);
        }
      }
    }

    //TODO: forcing English - should maybe be done on API side of things?
    languages.push({
      Id: englishLanguageId,
      Iso6391Code: "en",
      Title: "English",
      LanguageCode: 654
    });

    let languagesKnownByFreelancer: {
      FreelancerProfileKnownLanguages: {
        FreelancerProfileId: string;
        KnownLanguageId: string;
      };
      Id: string;
      Iso6391Code: string;
      Title: string;
    }[] = user.Freelancer.KnownLanguages ? user.Freelancer.KnownLanguages : [];

    let languagesToShow: newKnownLanguage[] = [];

    for (const i of languages) {
      // knownLanguage here is a combination of a language and freelancerProfileId. This is to make it fit into freelancer.KnownLanguages which look like this.
      let knownLanguage: {
        FreelancerProfileKnownLanguages: {
          FreelancerProfileId: string;
          KnownLanguageId: string;
        };
        Id: string;
        Iso6391Code: string;
        Title: string;
        LanguageCode: number | null;
      } = {
        FreelancerProfileKnownLanguages: {
          FreelancerProfileId: user.Freelancer.Id,
          KnownLanguageId: i.Id
        },
        Id: i.Id,
        Iso6391Code: i.Iso6391Code,
        Title: i.Title,
        LanguageCode: i.LanguageCode
      };

      //A new object is created with a boolean to check if a language is checked or not UI-wise. This is then added to an array which we map through in the UI.
      let newKnownLanguage: newKnownLanguage = {
        knownLanguage: knownLanguage,
        checked: false
      };

      for (const u of languagesKnownByFreelancer) {
        if (u.Id === i.Id) {
          newKnownLanguage.checked = true;
        }
      }
      languagesToShow.push(newKnownLanguage);
    }

    await this.setState({
      profilePicture: user.ProfilePictureUrl,
      knownLanguages: languagesToShow,
      reset: false,
      showPageLoader: false,
      experiences: user.Freelancer.Experiences,
      competenceSelections: user.Freelancer.CompetenceSelections,
      educations: user.Freelancer.Educations,
      user
    });
  }

  async resetForm() {
    await this.setState({ editing: false, reset: true });
    await this.prepareForm();
  }

  async saveUserResume() {
    await this.setState({ editing: false, showPageLoader: true });
    let user: LoginModel | null = getCurrentUser();

    if (!user || !user.Freelancer) {
      SnackbarManager.Instance.addError(getLanguage(469, 'Unknown error'));
      return;
    }

    let experiencesStripped: ExperienceModel[] = [];
    for (const experience of this.state.experiences) {
      let ex: ExperienceModel = {
        Id: experience.Id,
        CompanyName: experience.CompanyName,
        Description: experience.Description,
        ExperienceCategoryId: experience.ExperienceCategoryId,
        FreelancerProfileId: experience.FreelancerProfileId,
        FromMonth: +experience.FromMonth,
        FromYear: +experience.FromYear,
        JobTitle: experience.JobTitle,
        ToMonth: +experience.ToMonth,
        ToYear: +experience.ToYear,
        DescriptionNonLocalised: null
      };
      experiencesStripped.push(ex);
    }

    let educationsStripped = this.state.educations;
    for (const i of educationsStripped) {
      delete i.error;
    }

    user.Freelancer.Experiences = experiencesStripped;
    user.Freelancer.KnownLanguages = await this.updateKnownLanguages();
    user.Freelancer.Educations = educationsStripped;
    user.Freelancer.Description = this.state.newDescription;
    user.Freelancer.BirthDate = user.Freelancer.Birthday;

    let profileSkills: ProfileSkillsModel[] = [];

    for (const i of this.state.userSkills) {
      if (i.Activated) {
        let newProfileSkill = new ProfileSkillsModel({
          Id: uuid(),
          FreelancerProfileId: user.Freelancer.Id,
          SalaryPerHour: 0,
          Skill: i,
          SkillId: i.Id
        });
        profileSkills.push(newProfileSkill);
      }
    }

    let newCompSelectionArray = [];
    let competenceQuestions = this.state.competenceQuestions;
    for (const questionArray of competenceQuestions) {
      for (const question of questionArray) {
        if (question.Activated) {
          let newCompSelection: CompetenceSelectionsModel = {
            Id: uuid(),
            CompetenceQuestionId: question.Id,
            FreelancerProfileId: user.Freelancer.Id,
            CompetenceQuestion: question
          };
          newCompSelectionArray.push(newCompSelection);
        }
      }
    }

    user.Freelancer.ProfileSkills = profileSkills;
    user.Freelancer.CompetenceSelections = newCompSelectionArray;

    let success;

    //If we are not creating a user, we are not verifying if the step is complete or not. This can be subject to change.
    // if (this.props.creatingUser) {
    let stepResumeComplete:
      | boolean
      | any[] = await this.userHelper.verifyStepResumeComplete(user); //TODO: Add typing
    if (typeof stepResumeComplete !== "boolean") {
      if (stepResumeComplete[0] !== -1) {
        await this.setState({ validationProblem: stepResumeComplete[0] });
      }
      let failString = stepResumeComplete
        .map(x => getLanguage(x, "step resume fail info"))
        .join(" ");

      switch (stepResumeComplete[1]) {
        case 70:
          await this.setState({ descriptionError: true });
          break;
        case 152:
          await this.setState({ educationError: true });
          break;
        case 167:
          await this.setState({ experienceError: true });
          break;
        case 160:
          await this.setState({ knownLanguageError: true });
          break;
        case 161:
          await this.setState({
            skillsError: true,
            jobTypeClassName: "competenceWrapper error"
          });
          break;
      }
      SnackbarManager.Instance.addWarning(failString);
      return await this.setState({
        showPageLoader: false
      });
    }
    // }

    if (!this.props.creatingUser) {
      await this.setState({ reFresh: true });
    }

    // await this.userHelper.updateUserProfilePicture(user)
    success = await this.userHelper.updateFreelancer(user);
    user.vmStepResumeDone = true;

    if (success) {
      await setUserData(user);
      SnackbarManager.Instance.addSuccess(getLanguage(697, 'Changes saved'));
      await this.setState({
        reset: true
      });
      if (this.props.creatingUser && this.props.saveStep) {
        await this.setState({ showPageLoader: false });
        this.props.saveStep(true);
      } else {
        await this.prepareForm();
      }
    } else {
      SnackbarManager.Instance.addWarning(getLanguage(698, 'Changes not saved'));
      await this.setState({
        reset: true,
        showPageLoader: false
      });
    }
  }

  async updateKnownLanguages() {
    let languages = this.state.knownLanguages;
    let updatedKnownLanguages: {
      FreelancerProfileKnownLanguages: {
        FreelancerProfileId: string;
        KnownLanguageId: string;
      };
      Id: string;
      Iso6391Code: string;
      Title: string;
      LanguageCode: number | null;
    }[] = [];

    for (const lang of languages) {
      if (lang.checked) {
        updatedKnownLanguages.push(lang.knownLanguage);
      }
    }
    return updatedKnownLanguages;
  }

  async isLanguageChecked(index: number) {
    let languages = this.state.knownLanguages;

    languages[index].checked = languages[index].checked ? false : true;

    await this.setState({ knownLanguages: languages, editing: true });
  }

  async fetchImage(event: any) {
    const file: File = Array.from(event.target.files)[0] as File;

    if (file && file instanceof Blob) {
      var imageReg = /[/.](gif|jpg|jpeg|tiff|png)$/i;
      if (imageReg.test(file.type)) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.openCropping(e.target.result);
        };
        reader.readAsDataURL(event.target.files[0]);
      } else {
        SnackbarManager.Instance.addWarning(getLanguage(487, 'Please select an image file'));
      }
    } else {
      SnackbarManager.Instance.addWarning(getLanguage(487, 'Please select an image file'));
    }
    event.target.value = null;
  }

  async openCropping(image: string) {
    let self = this;
    var image2 = new Image();
    image2.src = image;
    image2.onload = function () {
      if (image2.naturalWidth >= 200 || image2.naturalHeight >= 200) {
        self.setState({ cropOpen: true, imageToCrop: image });
      } else {
        SnackbarManager.Instance.addWarning(getLanguage(488, 'Please select an image file'));
        self.setState({
          cropOpen: false,
          imageToCrop: ""
        });
      }
    };
  }

  async saveImage(image: string) {
    await this.setState({ showPageLoader: true });
    let imageSaved = await this.userHelper.updateUserProfilePicture(image);

    if (imageSaved) {
      let updatedUser = this.state.user;

      updatedUser.ProfilePictureUrl = imageSaved;
      let stepResumeComplete = await this.userHelper.verifyStepResumeComplete(
        updatedUser
      );
      if (stepResumeComplete && typeof stepResumeComplete == "boolean") {
        updatedUser.vmStepResumeDone = true;
      }
      setUserData(updatedUser);
      this.storageHelper.sendEvent("userUpdated");
      SnackbarManager.Instance.addSuccess(getLanguage(716, 'Image saved'));
      await this.setState({
        showPageLoader: false,
        profilePicture: image,
        user: updatedUser
      });
    } else {
      SnackbarManager.Instance.addError(getLanguage(698, 'Changes could not be saved'));
    }
  }

  async closeCropping(image: string) {
    if (image != null) {
      await this.saveImage(image);
      await this.setState({ cropOpen: false });
    }
    await this.setState({ cropOpen: false, showPageLoader: false });
  }

  async initialStartup(
    component: "experience" | "education" | "competences" | "skills",
    data: any
  ) {
    switch (component) {
      case "experience":
        await this.setState({ experiences: data });
        break;
      case "education":
        await this.setState({ educations: data });
        break;
      case "competences":
        await this.setState({ competenceQuestions: data });
        break;
      case "skills":
        await this.setState({ userSkills: data });
        break;
    }
  }

  async updateWorkExperience(experiences: ExperienceModel[]) {
    for (const experience of experiences) {
      experience.error = false;
    }

    await this.setState({
      experiences: experiences,
      editing: true,
      validationProblem: null
    });
  }

  async updateEducation(educations: EducationModel[]) {
    let updated: EducationModel[] = educations.map(x => {
      return {
        Description: x.Description,
        FinishedYear: Number(x.FinishedYear),
        FreelancerProfileId: x.FreelancerProfileId,
        Id: x.Id,
        SchoolName: x.SchoolName,
        Title: x.Title,
        error: "freelancerResume_item",
        DescriptionNonLocalised: x.DescriptionNonLocalised
      };
    });
    await this.setState({ educations: updated, editing: true });
  }

  async updateCompetenceQuestions(
    updateCompetenceQuestions: CompetenceQuestionModel[][]
  ) {
    await this.setState({
      competenceQuestions: updateCompetenceQuestions,
      editing: true
    });
  }

  async updateSkills(skills: SkillModel[]) {
    await this.setState({ userSkills: skills, editing: true });
  }

  creatingUser() {
    if (this.props.creatingUser) {
      return (
        <div>
          <div className="text-right margin-v-32">
            <Button
              className="button-gradient"
              onClick={() => this.saveUserResume()}
            >
              Next
            </Button>
          </div>
        </div>
      );
    }
    return <div />;
  }

  render() {
    let savingSnackBar: any;

    if (this.state.editing === true && this.props.creatingUser !== true) {
      savingSnackBar = (
        <SavingSnackbar
          open={this.state.editing}
          resetClicked={this.resetForm}
          saveClicked={this.saveUserResume}
        />
      );
    }

    let nextButton = this.creatingUser();

    return (
      <div className="freelanerResume">
        <h2>{getLanguage(197, "Profile picture")}</h2>
        <div className="text-center">
          <div id="FreelancerPicture">
            <img src={this.state.profilePicture} alt="" />
          </div>
          <div className="inputGroup">
            <Button
              className="default inputGroup_item"
              onClick={() => this.fileInput.click()}
            >
              {getLanguage(114, "Upload")}
            </Button>
          </div>
        </div>
        <hr className="divider-40" />
        <h2>{getLanguage(70, "Description")}</h2>
        <TextField
          type="text"
          label="Personal description"
          multiline
          rows="5"
          variant="outlined"
          fullWidth
          margin="dense"
          error={this.state.descriptionError}
          value={this.state.newDescription}
          onChange={event =>
            this.setState({ newDescription: event.target.value, editing: true })
          }
        />
        <hr className="divider-40" />
        <h2>{getLanguage(68, "Work experience")}</h2>
        <Experience
          creatingUser={this.props.creatingUser}
          updateWorkExperience={this.updateWorkExperience}
          reset={this.state.reset}
          initialStartup={this.initialStartup}
          validationProblem={this.state.validationProblem}
        />

        <hr className="divider-40" />

        <h2>{getLanguage(69, "Education")}</h2>
        <Education
          creatingUser={this.props.creatingUser}
          updateEducation={this.updateEducation}
          reset={this.state.reset}
          initialStartup={this.initialStartup}
          validationProblem={this.state.validationProblem}
        />

        <hr className="divider-40" />
        <h2>{getLanguage(655, "Language skills")}</h2>
        <FormGroup>
          {this.state.knownLanguages.map((row, index) => (
            <FormControlLabel
              checked={row.checked}
              control={
                <Checkbox
                  value={row.knownLanguage.Iso6391Code}
                  color="primary"
                  onClick={() => this.isLanguageChecked(index)}
                />
              }
              label={
                row.knownLanguage.LanguageCode
                  ? getLanguage(row.knownLanguage.LanguageCode, "Language name")
                  : ""
              }
              key={index}
            />
          ))}
        </FormGroup>
        <hr className="divider-40" />

        <h2>{getLanguage(199, "Job type")}</h2>
        <div className={this.state.jobTypeClassName}>
          <JobType
            creatingUser={this.props.creatingUser}
            updateCompetenceQuestions={this.updateCompetenceQuestions}
            updateSkills={this.updateSkills}
            reset={this.state.reset}
            initialStartup={this.initialStartup}
            reFresh={this.state.reFresh}
          />
        </div>

        {this.state.cropOpen ? (
          <ImageCrop
            open={this.state.cropOpen}
            close={this.closeCropping}
            image={this.state.imageToCrop}
            cropMode={1}
          />
        ) : null}
        <input
          accept="image/*"
          style={{ display: "none" }}
          type="file"
          onChange={this.fetchImage.bind(this)}
          ref={fileInput => (this.fileInput = fileInput)}
        />
        {nextButton}
        {savingSnackBar}
        {this.state.showPageLoader ? (
          this.props.creatingUser ? (
            <PageLoader />
          ) : (
              <PageLoader releaseNavbar={true} />
            )
        ) : null}
      </div>
    );
  }
}
