import { AxiosRequestConfig } from "axios";
import { StorageHelper } from './StorageHelper';
import { getCurrentAdminUser } from "../Helpers/SessionHelper";
import { ClearanceModel } from "../Models/ClearanceModel";
import { CallManager } from "./CallManager/CallManager";

export class ClearanceHelper {
  storageHelper = new StorageHelper();
  async getAll() {
    try {
      let config: AxiosRequestConfig = {
        method: 'get',
        url: '/api/adminuser/clearances',
        withCredentials: true
      }

      let clearances = await CallManager.Instance.makeCall(config, false, true);
      return clearances.data;
    } catch (error: any) {
      return [];
    }
  }
}

export function loggedInUserHasClearance(clearenceTitle: string) {
  let adminUser = getCurrentAdminUser()
  if (!adminUser || !adminUser.ClearanceLevels) {
    return
  }

  return adminUser.ClearanceLevels.find((t: ClearanceModel) => t.Name === clearenceTitle) !== undefined
}