import React from "react";
import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { getLanguage } from "../../../../Helpers/LanguageHelper";
import { Alert } from "@material-ui/lab";

type AcceptationStepProps = {
  collectiveAgreementAccepted: boolean;
  onCollectiveAgreementAcceptedChanged: (accepted: boolean) => void;
  termsAccepted: boolean;
  onTermsAcceptedChanged: (accepted: boolean) => void;
};

export function AcceptationStep({
  collectiveAgreementAccepted,
  onCollectiveAgreementAcceptedChanged,
  termsAccepted,
  onTermsAcceptedChanged,
}: AcceptationStepProps) {
  const yes = "yes";
  const no = "no";
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onCollectiveAgreementAcceptedChanged(event.target.value === yes);
  };

  return (
    <div>
      <h2 className="brand">
        {getLanguage(
          871,
          "Do you have a collective agreement covering the work you want done?"
        )}
      </h2>
      <RadioGroup
        row
        aria-label="gender"
        name="collectiveAgreementDeclaration"
        value={collectiveAgreementAccepted ? yes : no}
        onChange={handleChange}
      >
        <FormControlLabel
          value={yes}
          control={<Radio />}
          label={getLanguage(872, yes)}
        />
        <FormControlLabel
          value={no}
          control={<Radio />}
          label={getLanguage(873, no)}
        />
      </RadioGroup>
      {collectiveAgreementAccepted && (
        <Alert severity="warning">
          <p>{getLanguage(951, "Collective agreement terms section 1")}</p>
          <p>{getLanguage(952, "Collective agreement terms section 2")}</p>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={termsAccepted}
                onChange={() => onTermsAcceptedChanged(!termsAccepted)}
              />
            }
            label={getLanguage(
              953,
              "Collective agreement terms confirmation text"
            )}
          />
        </Alert>
      )}
    </div>
  );
}
