// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as historyObj from "history";
import { RouteHandler } from "./RouteHandler";

export class BroadcastHelper {
  private static instance: BroadcastHelper;
  public static get Instance(): BroadcastHelper {
    return BroadcastHelper.instance;
  }
  channel: BroadcastChannel | undefined;
  history: historyObj.History;
  routeHandler = new RouteHandler();

  constructor(history: historyObj.History) {
    this.history = history;
    if (BroadcastHelper.instance) return;
    BroadcastHelper.instance = this;

    try {
      if (BroadcastChannel != null) {
        this.channel = new BroadcastChannel('channel');
        this.channel.onmessage = (msge) => this.incommingMessage(msge)
      }
    } catch (error: any) {
      // The website is probably safari
      if (history.location.pathname.includes('admin')) {
        alert('The website experienced a problem, this is likely caused by using Safari, please use either firefox or chrome for admin work');
      }
    }
  }

  userLoggedOut() {
    if (this.channel)
      this.channel.postMessage('userLoggedOut');
  }

  incommingMessage(msge: MessageEvent) {
    switch (msge.data) {
      case 'userLoggedOut':
        this.routeHandler.checkRouting(this.history);
        return;
      case 'countMe':
        return;
    }
  }

}