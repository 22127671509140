import React, { Component } from "react";
import {
  Grid,
  Input,
  Tooltip,
  Button,
  Menu,
  CircularProgress,
} from "@material-ui/core";
import "./Chat.scss";
import { ChatHelper } from "../../../../Helpers/ChatHelper";
import { MessageModel } from "../../../../Models/MessageModel";
import { getCurrentUser } from "../../../../Helpers/SessionHelper";
import uuid from "uuid";
import { Picker, BaseEmoji } from 'emoji-mart'
import "./EmojiPicker.css"
import moment, { Moment } from "moment";
import { MessageNetworksModel } from "../../../../Models/MessageNetworksModel";
import { getLanguage } from "../../../../Helpers/LanguageHelper";
import { LoginModel } from "../../../../Models/LoginModel";
import { configureCloudinaryUrl } from "../../../../Helpers/ImageHelper";


interface IProps {
  messageNetworkId: string;
}



interface IState {
  messageText: string
  toggleEmoji: boolean;
  anchorEl: HTMLElement | null;
  existingMessages: MessageModel[];
  chatDetails: MessageNetworksModel | null;
  currentUser: LoginModel | null;
  pageState: 'renderLoading' | 'renderChats' | 'renderEmpty';
  chatBoxHeight: number;
  ableToSendMessage: boolean | null;
  awaitingMessages: MessageModel[];
}


export class Chat extends Component<IProps, IState> {
  chatHelper = new ChatHelper();

  constructor(props: IProps) {
    super(props);

    this.state = {
      messageText: '',
      toggleEmoji: false,
      anchorEl: null,
      existingMessages: [],
      chatDetails: null,
      currentUser: getCurrentUser(),
      pageState: 'renderLoading',
      chatBoxHeight: 0,
      ableToSendMessage: null,
      awaitingMessages: [],


    }
    this.sendMessage = this.sendMessage.bind(this)
    this.addEmoji = this.addEmoji.bind(this)
    this.renderChatForm = this.renderChatForm.bind(this)
    this.editMessage = this.editMessage.bind(this)
    this.renderChatInput = this.renderChatInput.bind(this)
    this.handleKeyUp = this.handleKeyUp.bind(this)
  }


  async componentDidMount() {
    let existingMessages = null;
    let chatDetails = null;

    if (this.props.messageNetworkId) {
      existingMessages = await this.chatHelper.getMessages(this.props.messageNetworkId);
      chatDetails = await this.chatHelper.getMessageNetworkDetails(this.props.messageNetworkId);
    } else {
      return;
    }

    if (Array.isArray(chatDetails) && chatDetails.length > 0 && chatDetails[0].ActiveStatus == 1) {
      this.setState({ ableToSendMessage: true })
    } else {
      this.setState({ ableToSendMessage: false })
    }


    if (chatDetails.length === 1) {
      this.setState({
        existingMessages,
        chatDetails: chatDetails[0],
        pageState: existingMessages.length > 0 ? 'renderChats' : 'renderEmpty',
      });
    } else {
      console.error('Issue with fetching chat data');
    }

    this.scrollToBottomFunction()
  }

  componentDidUpdate() {
    let objDiv = document.getElementById('chatBottom');
    if (objDiv)
      if (objDiv.clientHeight != this.state.chatBoxHeight) {
        this.setState({ chatBoxHeight: objDiv.clientHeight })
        this.scrollToBottomFunction()

      }

  }


  async sendMessage() {
    let message = this.state.messageText;
    let success = false;

    let currentUser = getCurrentUser();

    if (!currentUser) return;

    let freelancerProfileId = null;
    let companyProfileId = null;


    if (currentUser.Freelancer) {
      freelancerProfileId = currentUser.Freelancer.Id
    } else if (currentUser.Company) {
      companyProfileId = currentUser.Company.Id;
    } else return;

    if (!this.props.messageNetworkId) return;

    message = message.replace(/(?:\r\n|\r|\n)/g, '<br>');


    if (message && message != '') {
      /**
       * @param SenderId Is actually the LoginId
       */
      let newMessage: MessageModel = {
        DateTime: new Date(),
        FreelancerProfileId: freelancerProfileId,
        CompanyProfileId: companyProfileId,
        Id: uuid(),
        MessageNetworkId: this.props.messageNetworkId,
        SenderId: currentUser.Id,
        Text: message

      }

      let awaitingMessages = this.state.awaitingMessages;
      awaitingMessages.push(newMessage);
      setTimeout(() => {
        this.setState({ awaitingMessages, messageText: '' })
        this.scrollToBottomFunction();

      }, 500);



      success = await this.chatHelper.createMessage(newMessage)

      if (!success) {
        awaitingMessages.splice(awaitingMessages.indexOf(newMessage), 1);

      } else {
        awaitingMessages.splice(awaitingMessages.indexOf(newMessage), 1);

        let existingMessages = this.state.existingMessages;
        existingMessages.push(newMessage)
        await this.setState({ messageText: '', existingMessages, pageState: 'renderChats' })

      }
    }
  }


  addEmoji(emoji: BaseEmoji) {
    let message = this.state.messageText;

    if (!emoji) return message;

    message = message + emoji.native


    this.setState({ messageText: message })
  }

  toggleEmoji() {
    let toggle = this.state.toggleEmoji;

    if (toggle) {
      toggle = false;
    } else {
      toggle = true;
    }
    this.setState({ toggleEmoji: toggle })
  }


  renderMessages() {
    let allMessages: JSX.Element[] = [];
    let currentUser = this.state.currentUser;
    if (!currentUser || !this.state.existingMessages || this.state.existingMessages.length === 0) {
      return;
    }
    let existingMessages = this.state.existingMessages;
    for (let i = 0; i < existingMessages.length; i++) {
      let message = this.dataAsMessage(currentUser, i, existingMessages[i], allMessages, false);


      if (message)
        allMessages.push(message);
    }

    let awaitingMessages = this.state.awaitingMessages;
    if (!awaitingMessages || awaitingMessages.length == 0) return allMessages;

    for (let i = 0; i < awaitingMessages.length; i++) {
      let message = this.dataAsMessage(currentUser, i, awaitingMessages[i], allMessages, true);
      if (message)
        allMessages.push(message)
    }



    return allMessages;

  }



  dataAsMessage(currentUser: LoginModel, index: number, existingMessage: MessageModel, allMessages: JSX.Element[], messagePending: boolean) {
    let user = this.state.chatDetails!.Logins.find(x => x.Id === existingMessage.SenderId);
    if (user == null) return;
    let image = 'url(' + configureCloudinaryUrl(user.ProfilePictureUrl, ['w_70']) + ')';

    let username = '';

    if (existingMessage.SenderId !== currentUser.Id) {
      if (user.LoginType === 0) {
        if (this.state.chatDetails && this.state.chatDetails.Posting && this.state.chatDetails.Posting.CompanyProfile) {
          username = this.state.chatDetails.Posting.CompanyProfile.CompanyName; //FIXME: Don't use CompanyProfile (Fix when switching from .net to node)
        }
      } else {
        username = user.Firstnames;
      }
    }

    let chatpostDate = moment(existingMessage.DateTime || '');
    //Insert timelines
    let timelineDate = this.getTimelineDate(chatpostDate, index > 0 ? moment(this.state.existingMessages[index - 1].DateTime || '') : undefined);
    if (!messagePending) {
      if (timelineDate) {
        allMessages.push(<div className="dayIndicator" key={Math.random().toString(36).substring(2, 15)}>
          <span>{timelineDate}</span>
        </div>)
      }

    }

    let message = (
      <div className={messagePending ? "chatMessage_row messagePending" : "chatMessage_row"} key={existingMessage.Id}>
        <div className={existingMessage.SenderId === currentUser.Id ? 'message_sent' : 'message_received'}>
          <div className="messageBody" dangerouslySetInnerHTML={{ __html: existingMessage.Text }} >
          </div>
          <Tooltip title={username} placement="top">
            <div className="image" style={{ backgroundImage: image }} />
          </Tooltip>
          <div className="timestamp">
            {chatpostDate.format('HH:mm')}
          </div>
        </div>
      </div>)

    return message;
  }





  getTimelineDate(currentPostDate: Moment, lastPostDate?: Moment) {
    let sentence = '';
    if (!lastPostDate || lastPostDate.isBefore(currentPostDate, 'date')) {
      let today = moment();
      if (currentPostDate.isSame(today, 'date')) {
        sentence = getLanguage(314, 'today');
      } else if (currentPostDate.isSame(today.subtract(1, 'day'), 'date')) {
        sentence = getLanguage(313, 'yesterday');
      } else {
        sentence = currentPostDate.format('LL');
      }
    }
    return sentence;
  }


  getChatDescription() {
    if (this.state.currentUser == null) return;

    if (this.state.currentUser.LoginType === 0) {
      return getLanguage(316, 'This group chat is your direct message board to all confirmed chabbers on this shift.');
    } else {
      return getLanguage(317, 'This is a group chat between you, the company, and everyone hired for shift.');
    }
  }



  async scrollToBottomFunction() {
    let objDiv = document.getElementById('chatDiv');
    if (objDiv) {
      objDiv.scrollTop = (objDiv.scrollHeight - objDiv.clientHeight);
    }
  }


  editMessage(message: string) {
    if (message != null) {
      if (message.length >= 1600) {
        return;
      } else {
        return this.setState({ messageText: message });
      }
    }
  }

  handleKeyUp(event: KeyboardEvent) {
    // Bind on CTLR+ENTER and META+ENTER
    if (event && (event.ctrlKey || event.metaKey) && event.key === 'Enter') {
      event.stopPropagation()
      this.sendMessage()
    }
  }

  renderChatInput() {
    if (this.state.ableToSendMessage) {
      return (
        <div className="chatBottom" id='chatBottom'>
          <div className="chatBottom_content">
            <Grid
              container
              spacing={0}
              justify="space-between"
              alignItems="flex-start"
            >
              <Grid item xs={9} sm={9}>
                <Input
                  disabled={!this.state.ableToSendMessage}
                  type="text"
                  placeholder={!this.state.ableToSendMessage ? getLanguage(627, "chat closed") : getLanguage(628, "Send message")}
                  fullWidth
                  disableUnderline
                  multiline
                  rowsMax="10"
                  value={this.state.messageText}
                  onChange={(event: any) => this.editMessage(event.target.value)}
                  onKeyUp={(event: any) => this.handleKeyUp(event)}
                />
              </Grid>
              <Grid item>
                <Button
                  className="icon"
                  variant="text"
                  size="medium"
                  aria-owns='emojiPicker' onClick={(event) => this.setState({ anchorEl: event.currentTarget })}
                >
                  <span>
                    <i className="far fa-smile"> </i>
                  </span>
                </Button>
              </Grid>
              <Grid item>
                <Tooltip title="Ctrl+Enter">
                  <Button
                    className="icon"
                    variant="text"
                    size="medium"
                    onClick={this.sendMessage}
                  >
                    <span>
                      <i className="fas fa-paper-plane info"> </i>
                    </span>
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </div>
        </div>
      )
    }
  }


  renderChatForm() {
    if (this.props.messageNetworkId) {
      return (
        <div className="">
          <div className="chatTop padding-24">
            <h1>{getLanguage(212, 'Chat')}</h1>
            <p className="no-margin-bottom">
              {this.getChatDescription()}
            </p>
          </div>
          <div className="chatWrapper" id='chatDiv' style={{ bottom: this.state.chatBoxHeight }}>
            <div className="chatContent"  >
              {this.renderBody()}
            </div>
          </div>
          {this.renderChatInput()}
        </div>
      )
    } else {
      // NO MESSAGE NETWORK CREATED ERROR
      return (
        <div>
          <div className="chatTop padding-24">
            <h1>{getLanguage(212, 'Chat')}</h1>
          </div>
          <div className="emptyState_wrapper fullView">
            <div className="emptyState_content chatNetworkError">
              <div>
                <img
                  className="noDrag"
                  src="/img/illustrations/chatError.svg"
                  alt="No chat network"
                />
                <h2>{getLanguage(335, "Error")}</h2>
                <p>{getLanguage(336, "Something went wrong when creating the message network. Please contact customer support. ")}</p>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }


  renderBody() {
    switch (this.state.pageState) {
      case 'renderLoading':
        return <CircularProgress className="chatLoader_icon" />
      case 'renderChats':
        return this.renderMessages();
      case 'renderEmpty':
        return (
          <div className="emptyState_wrapper fullView">
            <div className="emptyState_content">
              <img
                className="noDrag"
                src="/img/illustrations/noChats.svg"
                alt="No chats"
              />
              <h3>{getLanguage(331, "There are no messages yet")}</h3>
            </div>
          </div>
        );
    }
  }





  render() {
    return (
      <div className="chatView">
        {this.renderChatForm()}
        <Menu
          id="emojiPicker"
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={() => this.setState({ anchorEl: null })}
          className="dropdownMenu"
        >
          <Picker
            set='emojione'
            title='Pick your emoji…'
            emoji='point_up'
            onSelect={(emoji: BaseEmoji) => this.addEmoji(emoji)}
            color="#f79747"
          />
        </Menu>
      </div>
    );
  }
}