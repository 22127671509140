// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from "react";
import { RouteComponentProps, Redirect } from "react-router";
import { NavLink } from "react-router-dom";
import "./FreelancerNav.scss";
import {
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Hidden,
  Grid,
  BottomNavigation,
  BottomNavigationAction,
  Badge
} from "@material-ui/core";
import { getLanguage } from "../../../../Helpers/LanguageHelper";
import { UserHelper } from "../../../../Helpers/UserHelper";
import { getCurrentUser, logUserOut } from "../../../../Helpers/SessionHelper";
import { FreelancerModel } from "../../../../Models/FreelancerModel";
import { LoginModel } from "../../../../Models/LoginModel";
import { th } from "date-fns/esm/locale";
import { VerificationDocumentsStatus } from "../../../../Models/VerificationDocumentStatus";
import { ProfilePictureViewer } from "../../../Parts/General/ProfilePictureViewer";
//@ts-ignore
import { IntercomAPI } from 'react-intercom';
import { LocalDataModel } from "../../../../Models/LocalDataModel";
import { StorageHelper } from "../../../../Helpers/StorageHelper";

interface IProps extends RouteComponentProps { }

interface IState {
  user: LoginModel | null;
  showVerificationDocs: boolean;
  bottomNav: number;
}

export class FreelancerNav extends Component<IProps, IState> {
  userHelper = new UserHelper();

  constructor(props: IProps) {
    super(props);

    this.state = {
      user: null,
      showVerificationDocs: false,
      bottomNav: 0
    };

    this.logOut = this.logOut.bind(this);
    this.updateUserInNavBar = this.updateUserInNavBar.bind(this);
    this.getVerificationDocumentStatus = this.getVerificationDocumentStatus.bind(
      this
    );
    this.bottomNaviButtonClicked = this.bottomNaviButtonClicked.bind(this);
  }

  async componentDidMount() {
    await this.updateUserInNavBar();
    window.addEventListener("userUpdated", this.updateUserInNavBar);
    await this.getVerificationDocumentStatus();
  }

  async updateUserInNavBar() {
    let user = await getCurrentUser();
    if (user) {
      await this.setState({ user: user });
    }
  }

  async logOut() {
    let response = await logUserOut();
    if (response === true) {
      this.props.history.push("/");
    } else {
      // FIXME: show snackbar
    }
  }

  async getVerificationDocumentStatus() {
    let showVerificationDocs = false;

    if (this.state.user && this.state.user.Freelancer) {
      let verificationDocumentStatus:
        | VerificationDocumentsStatus
        | boolean = await this.userHelper.getVerificationDocumentStatus(
          this.state.user.Freelancer.CountryId,
          this.state.user.Id,
          this.state.user.Freelancer.Id
        );

      if (
        verificationDocumentStatus &&
        typeof verificationDocumentStatus != "boolean"
      ) {
        if (verificationDocumentStatus.NationalID != 0) {
          showVerificationDocs = true;
        }
        if (verificationDocumentStatus.PictureId != 0) {
          showVerificationDocs = true;
        }
        if (verificationDocumentStatus.WorkPermit != 0) {
          showVerificationDocs = true;
        }
        if (verificationDocumentStatus.WorkPermitBack != 0) {
          showVerificationDocs = true;
        }
      }

      this.setState({ showVerificationDocs });
    }
  }

  bottomNaviButtonClicked(newValue: number) {
    this.setState({ bottomNav: newValue });
  }

  chatClicked() {
    //@ts-ignore
    IntercomAPI('show');
  }

  render() {
    let facadeData: LocalDataModel | null = new StorageHelper().getFacadeLocalization()

    let supportPhone = "(phone number not found)"
    if (facadeData && facadeData.country) {
      supportPhone = facadeData.country.SupportPhone
    }
    return (
      <div>
        <Hidden only="xs">
          <div className="freelancerMenu">
            <div className="freelancerMenu_top">
              <ProfilePictureViewer
                login={this.state.user}
                company={null}
                freelancer={this.state.user != null ? this.state.user!.Freelancer : null}
                customClass="freelancerLogo noDrag"
                altText=""
              ></ProfilePictureViewer>
              <div className="freelancerName">
                {this.state.user ? this.state.user.Firstnames : ""}{" "}
                {this.state.user ? this.state.user.Lastname : ""}
              </div>
            </div>
            <div className="freelancerMenu_wrapper">
              <div className="freelancerMenu_content">
                <NavLink
                  exact
                  className="freelancerMenu_link"
                  to="/freelancer/profile"
                >
                  <div className="freelancerMenu_link-icon">
                    <i className="fas fa-home" />
                  </div>
                  <div className="freelancerMenu_link-label">
                    {getLanguage(44, "Profile")}
                  </div>
                </NavLink>
                <div className="freelancerMenu_linkGroup">
                  <div className="freelancerMenu_linkGroupTitle">
                    {getLanguage(91, "Freelancer shifts")}
                  </div>
                  <NavLink
                    className="freelancerMenu_link"
                    to="/freelancer/shifts"
                  >
                    <div className="freelancerMenu_link-icon">
                      <i className="fas fa-tasks fa-fw" />
                    </div>
                    <div className="freelancerMenu_link-label">
                      {getLanguage(45, "My shifts")}
                    </div>
                  </NavLink>
                  <NavLink
                    className="freelancerMenu_link"
                    to="/freelancer/archive"
                  >
                    <div className="freelancerMenu_link-icon">
                      <i className="fas fa-archive fa-fw" />
                    </div>
                    <div className="freelancerMenu_link-label">
                      {getLanguage(200, "Archive")}
                    </div>
                  </NavLink>
                </div>
                <div className="freelancerMenu_linkGroup">
                  <div className="freelancerMenu_linkGroupTitle">
                    {getLanguage(44, "Profile")}
                  </div>
                  <NavLink
                    className="freelancerMenu_link"
                    to="/freelancer/favourites"
                  >
                    <div className="freelancerMenu_link-icon">
                      <i className="fas fa-heart fa-fw" />
                    </div>
                    <div className="freelancerMenu_link-label">
                      {getLanguage(95, "Favourites")}
                    </div>
                  </NavLink>
                  {/* <NavLink className="freelancerMenu_link" to="#">
                <div className="freelancerMenu_link-icon">
                  <i className="fas fa-user-friends fa-fw" />
                </div>
                <div className="freelancerMenu_link-label">{getLanguage(51, "Community")}</div>
              </NavLink> */}
                  <div className="freelancerMenu_link cursorPointer" onClick={this.chatClicked}>
                    <div className="freelancerMenu_link-icon">
                      <i className="fas fa-question-circle fa-fw" />
                    </div>
                    <div className="freelancerMenu_link-label">
                      {getLanguage(52, "Support")}
                    </div>
                  </div>
                  <NavLink
                    className="freelancerMenu_link"
                    to="/freelancer/contract"
                  >
                    <div className="freelancerMenu_link-icon">
                      <i className="fas fa-file-contract fa-fw" />
                    </div>
                    <div className="freelancerMenu_link-label">
                      {getLanguage(53, "Affiliation Agreement")}
                    </div>
                  </NavLink>
                  <NavLink
                    className="freelancerMenu_link"
                    to="/freelancer/courses"
                  >
                    <div className="freelancerMenu_link-icon">
                      <i className="fas fa-certificate fa-fw" />
                    </div>
                    <div className="freelancerMenu_link-label">
                      {getLanguage(682, "Courses")}
                    </div>
                  </NavLink>
                  <ExpansionPanel
                    classes={{
                      expanded: "expanded"
                    }}
                    className="expansionPanel freelancerMenu_expansionPanel"
                  >
                    <ExpansionPanelSummary
                      className="freelancerMenu_expansionPanel_title"
                      classes={{
                        expandIcon: "expandIcon",
                        expanded: "expanded"
                      }}
                      expandIcon={
                        <i className="fas fa-caret-down expansionIcon" />
                      }
                    >
                      <div className="freelancerMenu_link-icon">
                        <i className="fas fa-cog fa-fw" />
                      </div>
                      <div className="freelancerMenu_link-label">
                        {getLanguage(54, "Settings")}
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="freelancerMenu_expansionPanel_content">
                      <NavLink to="/freelancer/settings/basic">
                        <div className="freelancerMenu_expansionLink">
                          {getLanguage(55, "Personal data")}
                        </div>
                      </NavLink>
                      <NavLink to="/freelancer/settings/bank">
                        <div className="freelancerMenu_expansionLink">
                          {getLanguage(49, "Payment details data")}
                        </div>
                      </NavLink>
                      <NavLink to="/freelancer/settings/resume">
                        <div className="freelancerMenu_expansionLink">
                          {getLanguage(56, "My resume")}
                        </div>
                      </NavLink>
                      {this.state.showVerificationDocs ? (
                        <NavLink to="/freelancer/settings/verification">
                          <div className="freelancerMenu_expansionLink">
                            {getLanguage(779, "Verification")}
                          </div>
                        </NavLink>
                      ) : null}

                      <NavLink to="/freelancer/notifications">
                        <div className="freelancerMenu_expansionLink">
                          {getLanguage(50, "Notifications")}
                        </div>
                      </NavLink>
                      <NavLink to="/freelancer/settings/changepassword">
                        <div className="freelancerMenu_expansionLink">
                          {getLanguage(57, "Change password")}
                        </div>
                      </NavLink>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  {/* <a
                    className="freelancerMenu_link"
                    href="http://www.chabber.com"
                  >
                    <div className="freelancerMenu_link-icon">
                      <i className="fas fa-external-link-alt fa-fw" />
                    </div>
                    <div className="freelancerMenu_link-label">
                      Exit Beta
                    </div>
                  </a> */}
                </div>
                <NavLink to="/freelancer/searchpostings">
                  <div className="margin-v-24">
                    <Button
                      className="button-gradient"
                      variant="contained"
                      fullWidth
                    >
                      {getLanguage(59, "Find shifts")}
                    </Button>
                  </div>
                </NavLink>
                <div className="freelancerMenu_support">{getLanguage(52, "Support")}: <a href={`tel:${supportPhone}`}>{supportPhone}</a></div>
              </div>
            </div>
            <div className="freelancerMenu_bottom">
              <div onClick={this.logOut} className="freelancerMenu_Bottomlink">
                <div className="freelancerMenu_link-icon">
                  <i className="fas fa-sign-out-alt" />
                </div>
                <div className="freelancerMenu_link-label">
                  {" "}
                  {getLanguage(58, "Log out")}
                </div>
              </div>
            </div>
          </div>
        </Hidden>

        <Hidden smUp>
          <div id="freelancerNav_mobile">
            <BottomNavigation
              showLabels
              className="bottomNavigation"
              value={this.state.bottomNav}
              onChange={(event, newValue) => {
                this.bottomNaviButtonClicked(newValue);
              }}
            >
              <BottomNavigationAction
                onClick={() => this.props.history.push("/freelancer/profile")}
                label={getLanguage(56, "My resume")}
                icon={<i className="far fa-user fa-2x" />}
              />
              <BottomNavigationAction
                onClick={() =>
                  this.props.history.push("/freelancer/searchpostings")
                }
                label={getLanguage(59, "Find shifts")}
                icon={<i className="fas fa-search fa-2x" />}
              />
              <BottomNavigationAction
                onClick={() => this.props.history.push("/freelancer/shifts")}
                label={getLanguage(45, "My shifts")}
                icon={<i className="fas fa-tasks fa-2x" />}
              />
            </BottomNavigation>
          </div>
        </Hidden>
      </div>
    );
  }
}
