import { AxiosRequestConfig } from "axios";
import { FavoriteProfileModel } from "../Models/FavoriteProfileModel";
import { resizeImage } from "./ImageHelper";
import { FeedbackModel } from "../Models/FeedbackModel";
import { StorageHelper } from "./StorageHelper";
import { CallManager } from "./CallManager/CallManager";



export class CompanyHelper {
  storageHelper = new StorageHelper();
  clientKey = '04216571796E48ABB8FD781DEA966A4F';


  async getCompanyById(companyId: string) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: 'GET',
        url: '/companyprofile/' + companyId,
        headers: {
          'Content-Type': 'application/json',
          clientKey: this.clientKey,
          authToken
        },
      }
      let response = await CallManager.Instance.makeCall(config, true, false);
      return response.data;
    } catch (error: any) {
      console.log('error', error.response);
      return false;
    }
  }


  async getFreelancerById(freelancerProfileId: string) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: 'GET',
        url: '/api/freelancer?Id=' + freelancerProfileId,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          authToken
        },
      }

      let response = await CallManager.Instance.makeCall(config, false, false);
      return response.data;
    } catch (error: any) {
      console.log('error', error.response);
      return false;
    }
  }

  async updateCompany(companyProfile: any) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: 'PUT',
        url: '/companyprofile',
        headers: {
          'Content-Type': 'application/json',
          clientKey: this.clientKey,
          authToken
        },
        data: {
          CompanyName: companyProfile.CompanyName,
          Vat: companyProfile.Vat,
          AddressLine1: companyProfile.AddressLine1,
          ZipCode: companyProfile.ZipCode,
          City: companyProfile.City,
          Phone: companyProfile.Phone,
          MobilePhone: companyProfile.MobilePhone,
          CountryId: companyProfile.CountryId,
          Login: companyProfile.Login,
          InvoiceMail: companyProfile.InvoiceEmail,
          Description: companyProfile.Description,
          Firstnames: companyProfile.Login!.Firstnames,
          Lastname: companyProfile.Login!.Lastname
        }
      }
      let response = await CallManager.Instance.makeCall(config, true, false);
      return response.data;
    } catch (error: any) {
      console.log('error', error.response);
      return false;
    }
  }

  async updateCompanyLogin(login: any) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: 'PUT',
        url: '/login/' + login.Id,
        headers: {
          'Content-Type': 'application/json',
          clientKey: this.clientKey,
          authToken
        },
        data: {
          Firstnames: login.Firstnames,
          Lastname: login.Lastname,
          Email: login.Email,
          Password: login.Password,
          ProfilePictureUrl: login.ProfilePictureUrl,
          ProfilePictureId: login.ProfilePictureId
        }
      }
      let response = await CallManager.Instance.makeCall(config, true, false);
      return response.data;
    } catch (error: any) {
      console.log('error', error.response);
      return false;
    }
  }

  async updateCardDetails(card: any) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/creditcard',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          authToken
        },
        data: {
          cardholder: card.cardholder,
          cardnumber: card.cardnumber,
          cvc: card.cvc,
          expireMonth: card.expireMonth,
          expireYear: card.expireYear,
        }
      }

      let response = await CallManager.Instance.makeCall(config, false, false);
      return response.data;
    } catch (error: any) {
      console.log('error', error.response);
      return false;
    }
  }


  async getCreditCard() {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: 'GET',
        url: '/api/creditcard',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          authToken
        }
      }

      let response = await CallManager.Instance.makeCall(config, false, false);
      if (response.status === 200) {
        return true;
      }
      return false;
    } catch (error: any) {
      console.log('error', error.response);
      return false;
    }
  }

  async getPaymentLink() {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/creditcard',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          authToken
        }
      }

      let response = await CallManager.Instance.makeCall(config, false, false);
      if (response.status === 200) {
        return response.data;
      }
      return "";
    } catch (error: any) {
      console.log('error', error.response);
      return "";
    }
  }

  async requestInvoiceSolution() {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: 'GET',
        url: '/api/creditcard/requestinvoicesolution',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          authToken
        }
      }

      let response = await CallManager.Instance.makeCall(config, false, false);
      if (response.status === 200) {
        return response.data;
      }
      return "";
    } catch (error: any) {
      console.log('error', error.response);
      return "";
    }
  }

  async fetchCoverImages() {
    try {
      let config: AxiosRequestConfig = {
        method: 'GET',
        url: '/api/defaultcoverimage',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        }
      }
      let response = await CallManager.Instance.makeCall(config, false, false);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  async saveDefaultCoverImage(id: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/company/savedefaultcover',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          imageId: id
        }
      }
      let response = await CallManager.Instance.makeCall(config, false, false);
      if (typeof response.data === 'boolean') {
        return response.data;
      }
      return false;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  async saveNewCoverImage(image: string) {
    try {
      let config: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/picture/coverpictureasimage',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          image
        }
      }
      let response = await CallManager.Instance.makeCall(config, false, false);
      if (typeof response.data === 'string') {
        return response.data
      }
      return false;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  async saveCompanyLogo(image: string) {
    try {

      image = await resizeImage(image, 300, 'max largest');

      let config: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/picture/profilepictureasimage',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          image
        }
      }
      let response = await CallManager.Instance.makeCall(config, false, false);
      if (typeof response.data === 'string') {
        return response.data
      }
      return false;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  async getCompanyLogins() {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: 'GET',
        url: '/api/login',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          authToken
        }
      }
      let response = await CallManager.Instance.makeCall(config, false, false);

      if (response)
        return response.data;

      return false;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }



  async createNewCompanyLogin(newUserLogin: any) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/login/postUserData',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          authToken
        },
        data: newUserLogin
      }
      let response = await CallManager.Instance.makeCall(config, false, false);

      if (response)
        return response.data;

      return false;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  async deleteCompanyLogin(id: string) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: 'DELETE',
        url: '/api/login/' + id,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          authToken
        }
      }
      let response = await CallManager.Instance.makeCall(config, false, false);

      if (response)
        return response.data;

      return false;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  async updateAdminsCompanyLogins(newUserLogin: any) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: 'PUT',
        url: '/api/login/' + newUserLogin.Id,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          authToken
        },
        data: newUserLogin
      }
      let response = await CallManager.Instance.makeCall(config, false, false);

      if (response)
        return response.data;

      return false;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }




  async searchPreviouslyHired(name: string) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/searchpreviouslyhired',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          authToken
        },
        data: { search: name }
      }

      let response = await CallManager.Instance.makeCall(config, false, false);

      if (response)
        return response.data;

      return false;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }



  async sendFavoriteInvites(freelancers: any[]) { //FIXME: Should not be any
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/favouriteprofile',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          authToken
        },
        data: freelancers
      }
      let response = await CallManager.Instance.makeCall(config, false, false);

      if (response)
        return response.data;

      return false;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }




  async deleteFavorite(favoriteFreelancer: FavoriteProfileModel) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/favouriteprofile/' + favoriteFreelancer.Id,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          authToken
        },
        data: { ApprovalStatus: 4 }
      }
      let response = await CallManager.Instance.makeCall(config, false, false);

      if (response)
        return response.data;

      return false;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }



  async hireChabbers(requestIds: string[]) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: 'POST',
        url: '/request/confirmmultiple/',
        headers: {
          'Content-Type': 'application/json',
          clientKey: this.clientKey,
          authToken
        },
        data: { RequestIds: requestIds }
      }
      let response = await CallManager.Instance.makeCall(config, true, false);

      if (response) {
        if (response.status === 300) {
          return 403;
        } else if (response.status === 302) {
          return 402
        }

        return true;
      }
      return false;
    } catch (error: any) {
      if (error.response.status === 300) {
        return 403;
      } else if (error.response.status === 302) {
        return 402
      }
      else return false
    }
  }




  async submitFeedback(feedbackArray: FeedbackModel[]) {
    try {
      let data = [];
      for (const i of feedbackArray) {
        data.push(i)
      }

      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: 'POST',
        url: '/feedback',
        headers: {
          'Content-Type': 'application/json',
          clientKey: this.clientKey,
          authToken
        },
        data: { data }
      }

      let response = await CallManager.Instance.makeCall(config, true, false);

      if (response) {
        return true;
      }
      return false;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }



  async deleteRatings(ratingIdsArray: string[]) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: 'DELETE',
        url: '/rating',
        headers: {
          'Content-Type': 'application/json',
          clientKey: this.clientKey,
          authToken
        },
        data: ratingIdsArray
      }
      let response = await CallManager.Instance.makeCall(config, true, false);

      if (response)
        return true;
      return false;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }



  async permaHireChabber(requestId: string) {
    try {
      let authToken = this.storageHelper.getAccessToken();

      let config: AxiosRequestConfig = {
        method: 'POST',
        url: '/PermanentlyHired/' + requestId,
        headers: {
          'Content-Type': 'application/json',
          clientKey: this.clientKey,
          authToken
        }
      }

      let response = await CallManager.Instance.makeCall(config, true, false);

      if (response)
        return true;
      return false;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }

  async getInvoiceRecipients() {
    try {
      let config: AxiosRequestConfig = {
        url: '/api/companyinvoicerecipient',
        method: 'GET'
      }

      let response = await CallManager.Instance.makeCall(config, false);
      return response.data;
    } catch (error: any) {
      console.log(JSON.parse(JSON.stringify(error)));
      return [];
    }
  }

  // async verifyStepProfile(login: LoginModel) {
  //   try {

  //     let config: AxiosRequestConfig = {
  //       method: 'POST',
  //       url: '/api/company/verifyProfile',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         clientKey: this.clientKey,
  //       },
  //       data: login
  //     }

  //     let response = await CallManager.Instance.makeCall(config);
  //     return response.data;
  //   } catch (error: any) {
  //     console.log('error', error.response);
  //     return false;
  //   }
  // }











}