import React, { Component } from "react";
import "./StyleGuide.scss";
import {
  Button,
  TextField,
  InputAdornment,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Menu,
  MenuItem,
  Divider,
  Grid
} from "@material-ui/core";
export class StyleGuideView extends Component {
  copyToClipboard(e: any) {
    var el = document.createElement("textarea");
    el.value = e.target.outerHTML;
    el.setAttribute("readonly", "");
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    alert("Copied to clipboard");
  }
  state = {
    anchorEl: null
  };

  handleClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  render() {
    const { anchorEl } = this.state;
    return (
      <div className="styleGuideView">
        <input type="hidden" id="copyInput" />
        <div className="scrollWrapper">
          {/* ______________________________________ - - - TEXT - - - _____________________________________________________*/}
          <div className="styleGuideSection">
            <div className="styleGuideHeading">Text</div>
            <h6>PROXIMA SOFT BOLD | 1.1 REM - $lightGrey</h6>
            <div className="menuHeader">This is a menu header</div>
            <br />
            <h6>PROXIMA SOFT SEMIBOLD | 1.3 REM - $mediumGrey .menuItem</h6>
            <div className="menuItem">This is a menu item</div>
            <br />
            <h6>PROXIMA SOFT REGULAR | 1.3 REM - $primary</h6>
            <p>This is a regular p-tag text</p>
            <br />
            <h6>PROXIMA SOFT SEMIBOLD | 1.3 REM - $primary .semibold</h6>
            <p className="semiBold">This is semibold p-tag text</p>
            <br />
            <h6>PROXIMA SOFT BOLD | 1.3 REM - $primary .bold</h6>
            <p className="bold">This is bold p-tag text</p>
            <br />
            <h6>PROXIMA SOFT REGULAR | 1.6 REM - $primary .paragraphText</h6>
            <p className="paragraphText">This is a blog paragraph text</p>
            <br />
            <h6>
              PROXIMA SOFT REGULAR ITALIC | 1.6 REM - $primary
              .paraGraphText.italic
            </h6>
            <p className="paragraphText italic">
              This is a blog paragraph quote
            </p>
            <br />
            <h6>PROXIMA SOFT REGULAR | 1.6 REM - $mediumGrey .infoParagraph</h6>
            <p className="infoParagraph">This is some info text</p>
            <br />
            <h6>PROXIMA SOFT BOLD | 2.2 REM - $primary</h6>
            <h1>This is a h1 heading</h1>
            <br />
            <h6>PROXIMA SOFT BOLD | 1.6 REM - $primary</h6>
            <h2>This is a h2 heading</h2>
            <br />
            <h6>PROXIMA SOFT BOLD | 4.7 REM - $primary .presentationText</h6>
            <div className="presentationText">This is presentation text</div>
            <br />
            <h6>PROXIMA SOFT REGULAR | 1 REM - $placeholder</h6>
            <h6>This is a h6 caption</h6>
          </div>

          {/* ______________________________________ - - - SHADES - - - _____________________________________________________*/}
          <div className="styleGuideSection">
            <div className="styleGuideHeading">Shades</div>
            <div className="inline-block">
              <h6>$purewhite</h6>
              <div className="colorBlock purewhite" />
            </div>
            <div className="inline-block">
              <h6>$light</h6>
              <div className="colorBlock light" />
            </div>
            <div className="inline-block">
              <h6>$border</h6>
              <div className="colorBlock border" />
            </div>
            <div className="inline-block">
              <h6>$lightGrey</h6>
              <div className="colorBlock lightGrey" />
            </div>
            <div className="inline-block">
              <h6>$placeholder</h6>
              <div className="colorBlock placeholder" />
            </div>
            <div className="inline-block">
              <h6>$mediumGrey</h6>
              <div className="colorBlock mediumGrey" />
            </div>
            <div className="inline-block">
              <h6>$primary</h6>
              <div className="colorBlock primary" />
            </div>
            <div className="inline-block">
              <h6>$darkBackground</h6>
              <div className="colorBlock darkBackground" />
            </div>
          </div>

          {/* ______________________________________ - - - COLORS - - - _____________________________________________________*/}
          <div className="styleGuideSection">
            <div className="styleGuideHeading">Colors</div>
            <div className="inline-block">
              <h6>$brand</h6>
              <div className="colorBlock brand" />
            </div>
            <div className="inline-block">
              <h6>$success</h6>
              <div className="colorBlock success" />
            </div>
            <div className="inline-block">
              <h6>$warning</h6>
              <div className="colorBlock warning" />
            </div>
            <div className="inline-block">
              <h6>$danger</h6>
              <div className="colorBlock danger" />
            </div>
          </div>

          {/* ______________________________________ - - - GRADIENTS - - - _____________________________________________________*/}
          <div className="styleGuideSection">
            <div className="styleGuideHeading">Gradients</div>
            <div className="inline-block">
              <h6>$brandGradient</h6>
              <div className="colorBlock brandGradient" />
            </div>
          </div>

          {/* ______________________________________ - - - BUTTONS - - - _____________________________________________________*/}

          <div className="styleGuideSection">
            <div className="styleGuideHeading">Buttons</div>
            <div className="inline-block margin-8">
              <h6>.success</h6>
              <Button className="success" variant="contained" size="medium">
                <span className="btn-icon">
                  <i className="fas fa-plus" />
                </span>
                Success
              </Button>
            </div>
            <div className="inline-block margin-8">
              <h6>.danger</h6>
              <Button className="danger" variant="contained" size="medium">
                <span className="btn-icon">
                  <i className="far fa-trash-alt" />
                </span>
                Danger
              </Button>
            </div>
            <div className="inline-block margin-8">
              <h6>.white</h6>
              <Button className="white" variant="contained" size="medium">
                <span className="btn-icon">
                  <i className="fas fa-check" />
                </span>
                White
              </Button>
            </div>
            <div className="inline-block margin-8">
              <h6>.default</h6>
              <Button className="default" variant="contained" size="medium">
                <span className="btn-icon">
                  <i className="far fa-meh" />
                </span>
                Default
              </Button>
            </div>
            <div className="inline-block margin-8">
              <h6>Varient:text</h6>
              <Button className="" variant="text" size="medium">
                <span className="btn-icon">
                  <i className="far fa-smile" />
                </span>
                Plain
              </Button>
            </div>
            <div className="inline-block margin-8">
              <h6>.icon</h6>
              <Button
                className="default icon"
                variant="contained"
                size="medium"                
              >
                <span>
                  <i className="fas fa-undo-alt"> </i>
                </span>
              </Button>
              <pre>
                <code hidden>
                </code>
              </pre>
            </div>
          </div>

          {/* ______________________________________ - - - INPUTS - - - _____________________________________________________*/}

          <div className="styleGuideSection">
            <div className="styleGuideHeading">Inputs</div>
            <TextField
              type="text"
              label="Standard"
              variant="outlined"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true
              }}
            />
            <TextField
              type="text"
              label="With adornment"
              variant="outlined"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className="inputAdornment">
                    <i className="far fa-user" />
                  </InputAdornment>
                )
              }}
            />
            <TextField
              type="text"
              label="Textarea"
              multiline
              rows="5"
              variant="outlined"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>

          {/* ______________________________________ - - - INPUTS GROUPS - - - _____________________________________________________*/}

          <div className="styleGuideSection">
            <div className="styleGuideHeading">Inputgroups</div>
            <div className="inputGroup">
              <input
                className="inputGroup_item"
                type="text"
                placeholder="Search..."
              />
              <Button
                className="success inputGroup_item"
                variant="text"
                size="medium"
              >
                <span className="btn-icon">
                  <i className="fas fa-plus" />
                </span>
                Add page
              </Button>
            </div>
          </div>

          {/* ______________________________________ - - - TABLES - - - _____________________________________________________*/}

          <div className="styleGuideSection">
            <div className="styleGuideHeading">Tables</div>
            <Table className="table">
              <TableHead className="tableHead">
                <TableRow className="tableRow">
                  <TableCell>Page name</TableCell>
                  <TableCell>Page link</TableCell>
                  <TableCell>Language</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell> </TableCell>
                </TableRow>
              </TableHead>

              <TableBody className="tableBody">
                <TableRow className="tableRow folder">
                  <TableCell className="tableCell">About us</TableCell>
                  <TableCell className="tableCell">/about</TableCell>
                  <TableCell className="tableCell"> </TableCell>
                  <TableCell className="tableCell" align="center">
                    <i className="far fa-check-circle success" />
                  </TableCell>
                  <TableCell className="tableCell tableOptions">
                    <i className="fas fa-ellipsis-v" />
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableBody className="tableBody subTable">
                <TableRow className="tableRow">
                  <TableCell className="tableCell">Om os</TableCell>
                  <TableCell className="tableCell">/about/da</TableCell>
                  <TableCell className="tableCell">Danish</TableCell>
                  <TableCell className="tableCell" align="center">
                    <i className="far fa-check-circle success" />
                  </TableCell>
                  <TableCell className="tableCell tableOptions">
                    <i className="fas fa-ellipsis-v" />
                  </TableCell>
                </TableRow>
                <TableRow className="tableRow">
                  <TableCell className="tableCell">About us</TableCell>
                  <TableCell className="tableCell">/about/en</TableCell>
                  <TableCell className="tableCell">English</TableCell>
                  <TableCell className="tableCell" align="center">
                    <i className="far fa-check-circle success" />
                  </TableCell>
                  <TableCell className="tableCell tableOptions">
                    <i className="fas fa-ellipsis-v" />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table className="table">
              <TableHead className="tableHead">
                <TableRow className="tableRow">
                  <TableCell>Name</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Mail</TableCell>
                  <TableCell align="right">Worked off</TableCell>
                  <TableCell className="tableOptions" align="right" />
                </TableRow>
              </TableHead>
              <TableBody className="tableBody">
                <TableRow className="tableRow">
                  <TableCell className="tableCell">Username</TableCell>
                  <TableCell className="tableCell">12 34 67 89</TableCell>
                  <TableCell className="tableCell">some@mail.com</TableCell>
                  <TableCell className="tableCell" align="right">
                    9/10{" "}
                    <span className="padding-8">
                      <i className="fas fa-circle-notch fa-spin warning" />
                    </span>
                  </TableCell>
                  <TableCell
                    className="tableCell tableOptions"
                    align="right"
                    aria-owns={anchorEl ? "export-menu" : undefined}
                    aria-haspopup="true"
                    onClick={this.handleClick}
                  >
                    <i className="fas fa-ellipsis-v" />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Menu
              id="export-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
              className="dropdownMenu"
            >
              <div className="menuTitle">Edit signee</div>
              <MenuItem onClick={this.handleClose} className="menuItem">
                {" "}
                <i className="far fa-edit" />
                Edit hours worked
              </MenuItem>
              <Divider />
              <MenuItem onClick={this.handleClose} className="menuItem">
                {" "}
                <i className="far fa-trash-alt" />
                Delete signup
              </MenuItem>
            </Menu>
          </div>

          {/* ______________________________________ - - - HELPERS - - - _____________________________________________________*/}
          <div className="styleGuideSection">
            <div className="styleGuideHeading">HELPERS</div>
            <div className="inline-block">
              <h6>.no-margin-top</h6>
              <div className="marginBlock type-top" />
            </div>
            <div className="inline-block">
              <h6>.no-margin-right</h6>
              <div className="marginBlock type-right" />
            </div>
            <div className="inline-block">
              <h6>.no-margin-bottom</h6>
              <div className="marginBlock type-bottom" />
            </div>
            <div className="inline-block">
              <h6>.no-margin-left</h6>
              <div className="marginBlock type-left" />
            </div>
            <div className="inline-block">
              <h6>.margin-0</h6>
              <div className="marginBlock type-0" />
            </div>
            <div className="inline-block">
              <h6>.margin-8</h6>
              <div className="marginBlock type-8" />
            </div>
            <div className="inline-block">
              <h6>.margin-16</h6>
              <div className="marginBlock type-16" />
            </div>
            <div className="inline-block">
              <h6>.margin-24</h6>
              <div className="marginBlock type-24" />
            </div>
            <div className="inline-block">
              <h6>.margin-32</h6>
              <div className="marginBlock type-32" />
            </div>
            <div className="inline-block">
              <h6>.margin-40</h6>
              <div className="marginBlock type-40" />
            </div>
            <br/>
            <div className="inline-block">
              <h6>.no-padding-top</h6>
              <div className="paddingBlock type-top" />
            </div>
            <div className="inline-block">
              <h6>.no-padding-right</h6>
              <div className="paddingBlock type-right" />
            </div>
            <div className="inline-block">
              <h6>.no-padding-bottom</h6>
              <div className="paddingBlock type-bottom" />
            </div>
            <div className="inline-block">
              <h6>.no-padding-left</h6>
              <div className="paddingBlock type-left" />
            </div>
            <div className="inline-block">
              <h6>.padding-0</h6>
              <div className="paddingBlock type-0" />
            </div>
            <div className="inline-block">
              <h6>.padding-8</h6>
              <div className="paddingBlock type-8" />
            </div>
            <div className="inline-block">
              <h6>.padding-16</h6>
              <div className="paddingBlock type-16" />
            </div>
            <div className="inline-block">
              <h6>.padding-24</h6>
              <div className="paddingBlock type-24" />
            </div>
            <div className="inline-block">
              <h6>.padding-32</h6>
              <div className="paddingBlock type-32" />
            </div>
            <div className="inline-block">
              <h6>.padding-40</h6>
              <div className="paddingBlock type-40" />
            </div>
            <br/>
            <div className="inline-block">
            <h6>.radius-0</h6>
              <div className="radiusBlock rad-0"></div>
            </div>
            <div className="inline-block">
            <h6>.radius-4</h6>
              <div className="radiusBlock rad-4"></div>
            </div>
            <div className="inline-block">
            <h6>.radius-6</h6>
              <div className="radiusBlock rad-6"></div>
            </div>
            <div className="inline-block">
            <h6>.radius-12</h6>
              <div className="radiusBlock rad-12"></div>
            </div>
            <br/><br/>
            <h6>hr .divider-20</h6> 
            <div className="hr-20"/>  
            <h6>hr .divider-40</h6> 
            <div className="hr-40"/>   
            <div className="inline-block">
              <h6>.dropShadowLarge</h6>
              <div className="shadowBlock shadow-large"></div>
            </div>
            <div className="inline-block">
              <h6>.dropShadowMedium</h6>
              <div className="shadowBlock shadow-medium"></div>
            </div>
            <div className="inline-block">
              <h6>.dropShadowSmall</h6>
              <div className="shadowBlock shadow-small"></div>
            </div> 
            <br/> <br/>    
          </div>

          {/* ______________________________________ - - - IMAGES - - - _____________________________________________________*/}
          <div className="styleGuideSection">
            <div className="styleGuideHeading">IMAGES</div>
            <div className="inline-block">
              <h6>.imgRadius</h6>            
              <div className="AdminPageAvatar"></div>   
            </div>
            <div className="inline-block"> 
              <h6>.imgRounded</h6>
              <div className="AdminPageAvatar rounded"></div>    
            </div>           
          </div>
          {/* ______________________________________ - - - GRIDS - - - _____________________________________________________*/}
          <div className="styleGuideSection">
            <div className="styleGuideHeading">GRIDS</div>
            <h6>For setup and settings see: <a className="brand" target="_blank" href="https://material-ui.com/layout/grid/#interactive">Material UI's demo</a></h6>
            <br/>
            <Grid 
            container 
            className="Grid"
            spacing={2}>
              <Grid item xs={6}>
                <div className="paper padding-40 radius-12">xs-6</div>
              </Grid>
              <Grid item xs={6}>
                <div className="paper padding-40 radius-12">xs-6</div>
              </Grid>
            </Grid>        
          </div>

        </div>
      </div>
    );
  }
}
