import React, { PureComponent } from "react";

interface IProps {
  title: string;
}
interface IState { }

export default class TabContent extends PureComponent<IProps, IState> {
  render() {
    return (
      <>{this.props.children}</>
    )
  }
}