import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import qs from 'query-string';
import { UserHelper } from '../../../../Helpers/UserHelper';
// import { getCurrentUser, setUserData } from '../../../../Helpers/SessionHelper';
import { LanguageHelper } from '../../../../Helpers/LanguageHelper';
import { StorageHelper } from '../../../../Helpers/StorageHelper';
import { logUserOut } from '../../../../Helpers/SessionHelper';

interface IProps extends RouteComponentProps { }
interface IState {
  currentState: 'waiting' | 'success' | 'failure';
}

export class ActivateByMail extends Component<IProps, IState> {
  userHelper = new UserHelper();
  languageHelper = new LanguageHelper();
  storageHelper = new StorageHelper();

  constructor(props: IProps) {
    super(props);

    this.state = {
      currentState: 'waiting'
    }

    let query = qs.parse(this.props.location.search);
    let id: string = (query.i || '') as string;
    let code: string = (query.c || '') as string;

    this.activateAccount = this.activateAccount.bind(this)
    this.activateAccount(id, code)
  }

  async activateAccount(id: string, code: string) {
    let response = await this.userHelper.activateWithEmail(id, code);

    if (response === true) {
      await this.setState({ currentState: 'success' });
      logUserOut();
      
      setTimeout(() => {
        this.goToLogin();
      }, 1000);
    } else {
      this.setState({ currentState: 'failure' });
    }
  }

  goToLogin() {
    this.props.history.push('/login');
  }

  renderIntro() {
    return (
      <div>
        <h1>Activating your account</h1>
      </div>
    );
  }

  renderSuccess() {
    return (
      <div>
        <h1>Account activated</h1>        
      </div>
    );
  }

  renderFailure() {
    return (
      <div>
        <h1>Activation failed, please try again or contact customer support</h1>
      </div>
    );
  }

  render() {
    let content = <div></div>;

    switch (this.state.currentState) {
      case 'waiting':
        content = this.renderIntro();
        break;
      case 'success':
        content = this.renderSuccess();
        break;
      case 'failure':
        content = this.renderFailure();
        break;
    }



    return (
      <div>
        {content}
      </div>
    );
  }
}