import { CountryModel } from './CountryModel';
import { KnownLanguageModel } from './KnownLanguageModel';
import { LanguagesAvailableByCountryModel } from './LanguagesAvailableByCountryModel';
import moment, {Moment} from 'moment';

export class LocalDataModel {
  country: CountryModel;
  language: string;
  countries: CountryModel[];
  knownLanguages: KnownLanguageModel[];
  languagesAvailableByCountry: LanguagesAvailableByCountryModel[];
  creationDate: Moment;

  constructor(val: Partial<LocalDataModel> ) {
    this.country = val.country || new CountryModel({});
    this.language = val.language || '';
    this.countries = val.countries || [];
    this.knownLanguages = val.knownLanguages || [];
    this.languagesAvailableByCountry = val.languagesAvailableByCountry || [];
    this.creationDate = val.creationDate || moment()
  }
}