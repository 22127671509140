import { CountryModel } from "./CountryModel";
import { RequestModel } from "./RequestModel";
import { LoginModel } from "./LoginModel";
import { ExperienceModel } from "./ExperienceModel";
import { CompetenceSelectionsModel } from "./CompetenceSelectionsModel";
import { ProfileSkillsModel } from "./ProfileSkillsModel";
import { EducationModel } from "./EducationModel";

export class FreelancerModel {
  Id: string;
  LoginId: string;
  Login: LoginModel | null;
  AddressLine1: string;
  AddressLine2: string;
  Age: number;
  ZipCode: string;
  City: string;
  Phone: string;
  MobilePhone: string;
  Birthday: string;
  BirthDate: string;
  Description: string;
  LastUpdated: Date | null;
  Gender: 1 | 2;
  SSN: string;
  IsChabsVerified: boolean;
  Latitude: string;
  Longitude: string;
  CountryId: string;
  Country: CountryModel | null;
  LocalBankRegistrationNumber: string;
  LocalBankAccountNumber: string;
  TaxType: number;
  InfoMailAboutCoursesSent: boolean;
  ProfileComplete: boolean;
  RatingsAverage: number;
  CountRatings: number;
  CountHired: number;
  DisableNotificationsUntil: Date | null;
  WelcomeGuideStatus: boolean;
  PayrollUserId: number;
  SubscribeToUrgentPostingsMails: boolean;
  SubscribeToNewsletters: boolean;
  MissingActionOnCompetences: boolean;
  LastCheckedConfirmedRequests: Date | null;
  LastConfirmedSmsNotification: Date | null;
  ConfirmSmsSentDate: Date | null;
  ShouldRecalcNumbers: boolean;
  TotalRequests: number;
  LateCancels: number;
  UrgentLateCancels: number;
  ConfirmedRequests: number;
  UserAdminLogExists: boolean;
  FinancingCustomerId: string;
  CreditcardIdentifier: string;
  Requests: RequestModel[];
  // Favourites: FavouriteProfileModel[];
  // Skills: SkillModel[]
  Experiences: ExperienceModel[];
  Educations: EducationModel[];
  // KnownLanguages: any[];
  KnownLanguages: {
    FreelancerProfileKnownLanguages: {
      FreelancerProfileId: string;
      KnownLanguageId: string;
    };
    Id: string;
    Iso6391Code: string;
    Title: string;
    LanguageCode: number | null;
  }[];
  FullSalary: number;
  CompetenceSelections: CompetenceSelectionsModel[];
  CancellationPercentage: number;
  CountLateCancels: number;
  PreviouslyHired: boolean;
  CountCancels: number;
  CancellationPercentageWithOneExtraCancel: number;
  ProfileSkills: ProfileSkillsModel[];
  AverageMinutesCancellelingBefore: number;
  AcceptedContract: boolean;

  //TODO: attributes do not belong here.
  Firstnames: string;
  Lastname: string;
  Email: string;
  DescriptionNonLocalised: string | null;
  NationalityCountryId: string;
  IsPrivate: boolean;
  PensionEnabled: number;
  CancelationQuarantineRulesAccepted: boolean;

  constructor(val: Partial<FreelancerModel>) {
    this.Id = val.Id || "";
    this.LoginId = val.LoginId || "";
    this.Login = val.Login || null;
    this.AddressLine1 = val.AddressLine1 || "";
    this.AddressLine2 = val.AddressLine2 || "";
    this.Age = val.Age || 0;
    this.ZipCode = val.ZipCode || "";
    this.City = val.City || "";
    this.Phone = val.Phone || "";
    this.MobilePhone = val.MobilePhone || "";
    this.Birthday = val.Birthday || "1800-01-01";
    this.BirthDate = val.BirthDate || "1800-01-01";
    this.Description = val.Description || "";
    this.LastUpdated = val.LastUpdated || new Date();
    this.Gender = val.Gender || 1;
    this.SSN = val.SSN || "";
    this.IsChabsVerified = val.IsChabsVerified || false;
    this.Latitude = val.Latitude || "";
    this.Longitude = val.Longitude || "";
    this.CountryId = val.CountryId || "";
    this.Country = val.Country || null;
    this.LocalBankRegistrationNumber = val.LocalBankRegistrationNumber || "";
    this.LocalBankAccountNumber = val.LocalBankAccountNumber || "";
    this.TaxType = val.TaxType || 0;
    this.InfoMailAboutCoursesSent = val.InfoMailAboutCoursesSent || false;
    this.ProfileComplete = val.ProfileComplete || false;
    this.RatingsAverage = val.RatingsAverage || 0;
    this.CountRatings = val.CountRatings || 0;
    this.CountHired = val.CountHired || 0;
    this.DisableNotificationsUntil = val.DisableNotificationsUntil || null;
    this.WelcomeGuideStatus = val.WelcomeGuideStatus || false;
    this.PayrollUserId = val.PayrollUserId || 0;
    this.SubscribeToUrgentPostingsMails =
      val.SubscribeToUrgentPostingsMails || false;
    this.MissingActionOnCompetences = val.MissingActionOnCompetences || false;
    this.LastCheckedConfirmedRequests =
      val.LastCheckedConfirmedRequests || null;
    this.LastConfirmedSmsNotification =
      val.LastConfirmedSmsNotification || null;
    this.ConfirmSmsSentDate = val.ConfirmSmsSentDate || null;
    this.ShouldRecalcNumbers = val.ShouldRecalcNumbers || false;
    this.TotalRequests = val.TotalRequests || 0;
    this.LateCancels = val.LateCancels || 0;
    this.UrgentLateCancels = val.UrgentLateCancels || 0;
    this.ConfirmedRequests = val.ConfirmedRequests || 0;
    this.UserAdminLogExists = val.UserAdminLogExists || false;
    this.FinancingCustomerId = val.FinancingCustomerId || "";
    this.CreditcardIdentifier = val.CreditcardIdentifier || "";
    this.Requests = val.Requests || [];
    // this.KnownLanguage = val.KnownLanguage || [];
    this.KnownLanguages = val.KnownLanguages || [];

    this.CompetenceSelections = val.CompetenceSelections || [];
    this.CountCancels = val.CountCancels || 0;

    // this.Favourites = val.Favourites || [];
    // this.Skills = val.Skills || [];
    this.Experiences = val.Experiences || [];
    this.Educations = val.Educations || [];

    this.FullSalary = val.FullSalary || 0;
    this.CancellationPercentage = val.CancellationPercentage || 0;
    this.CountLateCancels = val.CountLateCancels || 0;
    this.PreviouslyHired = val.PreviouslyHired || false;
    this.CancellationPercentageWithOneExtraCancel =
      val.CancellationPercentageWithOneExtraCancel || 0;
    this.ProfileSkills = val.ProfileSkills || [];
    this.AverageMinutesCancellelingBefore =
      val.AverageMinutesCancellelingBefore ?? -1;
    this.AcceptedContract = val.AcceptedContract || false;

    this.Firstnames = val.Firstnames || "";
    this.Lastname = val.Lastname || "";
    this.Email = val.Email || "";
    this.DescriptionNonLocalised = val.DescriptionNonLocalised || null;
    this.NationalityCountryId = val.NationalityCountryId || "";
    this.IsPrivate = val.IsPrivate || false;
    this.SubscribeToNewsletters = val.SubscribeToNewsletters || false;
    this.PensionEnabled = val.PensionEnabled || 0;
    this.CancelationQuarantineRulesAccepted =
      val.CancelationQuarantineRulesAccepted || false;
  }
}
