import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Button,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  NewRuleFormBody,
  OperationType,
  PredicateType,
  TimeInterval,
} from "../../Types";
import {
  formatAdornment,
  resolveDefaultInterval,
  validateInterval,
  validateNumber,
  validateRequiredString,
  validateWeekdays,
} from "../../Utils";
import "./index.scss";
import { OffsetPredicateForm } from "../OffsetPredicateForm";
import { TimeOfTheDayPredicateForm } from "../TimeOfTheDayPredicateForm";
import { WeekdaysSelect } from "../WeekdaysSelect";
import { OffsetBetweenPredicateForm } from "../OffsetBetweenPredicateForm";
import { SnackbarManager } from "../../../../Helpers/SnackbarManager/SnackbarManager";
import { getLanguage } from "../../../../Helpers/LanguageHelper";
import { CurrencyInput } from "../../../../Components/Parts/General/CurrencyInput";

type Props = {
  onSubmit: (rule: NewRuleFormBody) => void;
  nextRuleNumber: number;
};

export function CustomRuleForm({ onSubmit, nextRuleNumber }: Props) {
  const [handler, setHandler] = useState<OperationType>("Addition");
  const [predicateType, setPredicateType] =
    useState<PredicateType>("TimeOfTheDay");
  const [timeInterval, setTimeInterval] = useState<TimeInterval>(
    resolveDefaultInterval(predicateType)
  );
  const [value, setValue] = useState<string>("");
  const [selectedWeekdays, setSelectedWeekdays] = useState<string[]>([]);
  const [label, setLabel] = useState(
    getLanguage(887, "Custom supplement {{nextNumber}}").replace(
      "{{nextNumber}}",
      nextRuleNumber.toString()
    )
  );

  const [labelValidation, setLabelValidation] = useState<string | null>();
  const [predicateValidation, setPredicateValidation] = useState<
    string | null
  >();
  const [valueValidation, setValueValidation] = useState<string | null>();
  const [weekdaysValidation, setWeekdaysValidation] = useState<string | null>();

  useEffect(() => {
    setTimeInterval(resolveDefaultInterval(predicateType));
  }, [predicateType]);

  useEffect(() => {
    setPredicateValidation(validateInterval(predicateType, timeInterval));
  }, [timeInterval]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setHandler(event.target.value as OperationType);
  };

  const handlePredicateTypeChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setPredicateType(event.target.value as PredicateType);
  };

  const resolvePredicateForm = (type: PredicateType) => {
    switch (type) {
      case "TimeOfTheDay":
        return (
          <TimeOfTheDayPredicateForm
            interval={timeInterval}
            onChange={setTimeInterval}
          />
        );
      case "ShiftStartOffsetBetween":
        return (
          <OffsetBetweenPredicateForm
            interval={timeInterval}
            onChange={setTimeInterval}
          />
        );
      case "ShiftStartOffsetFrom":
        return (
          <OffsetPredicateForm
            interval={timeInterval}
            onChange={setTimeInterval}
          />
        );
    }
  };

  const resetForm = () => {
    setValue("");
    setSelectedWeekdays([]);
    setPredicateType("TimeOfTheDay");
    setTimeInterval(resolveDefaultInterval(predicateType));
    setLabel(
      getLanguage(887, "Custom supplement {{nextNumber}}").replace(
        "{{nextNumber}}",
        nextRuleNumber.toString()
      )
    );
  };

  const submit = () => {
    const valueValidationNext = validateNumber(value);
    const predicateValidationNext = validateInterval(
      predicateType,
      timeInterval
    );
    const weekDaysValidationNext = validateWeekdays(selectedWeekdays);
    const labelValidationNext = validateRequiredString(label);
    if (
      !!valueValidationNext ||
      !!predicateValidationNext ||
      !!weekDaysValidationNext ||
      !!labelValidation
    ) {
      setValueValidation(valueValidationNext);
      setPredicateValidation(predicateValidationNext);
      setWeekdaysValidation(weekDaysValidationNext);
      setLabelValidation(labelValidationNext);
      SnackbarManager.Instance.addWarning("Form invalid");
      return;
    } else {
      const rule: NewRuleFormBody = {
        predicate: {
          predicateType,
          hoursInterval: timeInterval,
          days: selectedWeekdays,
        },
        value: value,
        handler: handler,
        identifier: "Custom",
        isEditable: true,
        title: label,
      };
      onSubmit(rule);
      resetForm();
    }
  };

  return (
    <div>
      <h2 className="brand">
        {getLanguage(875, "Create your own supplements")}
      </h2>
      <form
        className="custom-supplement-form"
        noValidate
        autoComplete="off"
        onSubmit={(e) => {}}
      >
        <TextField
          margin="normal"
          fullWidth
          size="small"
          label={getLanguage(888, "Supplement name")}
          value={label}
          error={!!labelValidation}
          helperText={labelValidation || <span>&nbsp;&nbsp;</span>}
          onChange={({ target: { value } }) => {
            setLabelValidation(validateRequiredString(value));
            setLabel(value as unknown as string);
          }}
          variant={"outlined"}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <RadioGroup
          row
          className="interval-type"
          value={predicateType}
          onChange={handlePredicateTypeChange}
        >
          <FormControlLabel
            value="TimeOfTheDay"
            control={<Radio />}
            label={getLanguage(876, "During timeslot")}
          />
          <FormControlLabel
            value="ShiftStartOffsetBetween"
            control={<Radio />}
            label={getLanguage(877, "Between hours")}
          />
          <FormControlLabel
            value="ShiftStartOffsetFrom"
            control={<Radio />}
            label={getLanguage(878, "From hour")}
          />
          {resolvePredicateForm(predicateType)}
          {predicateValidation && (
            <Typography color={"error"}>
              {predicateValidation || <span>&nbsp;&nbsp;</span>}
            </Typography>
          )}
        </RadioGroup>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              margin={"normal"}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                {getLanguage(883, "Supplement type")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select"
                value={handler}
                label={getLanguage(883, "Supplement type")}
                onChange={handleChange}
              >
                <MenuItem value={"Addition"}>
                  {getLanguage(884, "Addition to base")}
                </MenuItem>
                <MenuItem value={"Percentage"}>
                  {getLanguage(885, "Extra percent")}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <CurrencyInput
              margin="normal"
              value={value}
              defaultValue={'0'}
              size={'small'}
              onChange={(next:string) => {
                setValueValidation(validateNumber(next));
                setValue(next);
              }}
              label={getLanguage(416, "Salary/hour")}
              fullWidth
              error={!!valueValidation}
              helperText={valueValidation || <span>&nbsp;&nbsp;</span>}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {formatAdornment(handler)}
                  </InputAdornment>
                ),
                inputProps: {
                  style: { textAlign: "right", paddingRight: "2px" },
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <WeekdaysSelect
          selectedWeekdays={selectedWeekdays}
          onChange={(next) => {
            setWeekdaysValidation(validateWeekdays(next));
            setSelectedWeekdays(next);
          }}
        />
        {weekdaysValidation && (
          <Typography color={"error"}>
            {weekdaysValidation || <span>&nbsp;&nbsp;</span>}
          </Typography>
        )}
        <div className="submit-contrainer">
          <Button
            className="success inputGroup_item"
            variant="contained"
            onClick={submit}
          >
            {getLanguage(737, "Confirm")}
          </Button>
        </div>
      </form>
    </div>
  );
}
